(function () {
  'use strict';

  /* @ngdoc object
   * @name myMemberships
   * @description
   *
   */
  angular
    .module('myMemberships', [
      'ui.router',
      'myMemberships.memberships',
      'myMemberships.receivedLeads',
      'myMemberships.joinPod',
      'myMemberships.sendReward',
      'myMemberships.rewardProgram',
      'myMemberships.import'
    ]);
}());
