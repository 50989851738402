(function () {
  'use strict';

  /**
   * @ngdoc factory
   * @name components.factory:ComponentsUtil
   *
   * @description
   *
   */
  angular
    .module('components')
    .factory('ComponentsUtil', ComponentsUtilFactory);

  function ComponentsUtilFactory() {
    return {

      /**
       * Returns the updated tableSubContentVisible object used across the MLP for tables in xs (sm) devices
       * @param {Object} subContent Subcontent
       * @param {int} idx Index
       * @return {Object} Updated content object.
       */
      toggleSubContent: function (subContent, idx) {
        if (!subContent) {
          subContent = {};
          subContent[idx] = true;
        }
        else if (_.contains(_.keys(subContent), idx.toString())) {
          // Check if the incoming idx is already added, Just toggle it.
          subContent[idx] = !subContent[idx];
        }
        else {
          // Delete everything and set as new
          subContent = {};
          subContent[idx] = true;
        }
        return subContent;
      }
    };
  }
}());
