<!--<div class="nc no-select">

  <div ng-if="(notifications.length > 0 || filterActive) && filterCategories && filterCategories.length > 1">
    <div class="result-category-filter no-select">
      <div>Filter:</div>
      <div class="category-filters">
        <div ng-repeat="category in filterCategories"
             ng-class="{'filter-selected': isSelected('{{category.value}}'), 'has-results': category.hasResults}"
             ng-click="changeCategory(category.value, $event, true)"
             data-category-value="category.value">
          <div class="category-badge"></div>{{category.name}}
        </div>
      </div>
      <a ng-click="clearNotifications">Mark as read</a>
    </div>

    <div style="position: relative; width: 100%; overflow: scroll; max-height: 51vh;">
      <div ng-repeat="n in notifications" class="nc-notification no-animate" ng-click="openNotification(n)">
        <div class="nc-icon">
          <span class="fa {{n.iconClass}}"></span>
        </div>
        <div class="nc-container">
          <div class="nc-header">
            <div>
              {{n.title}}
            </div>
            <div class="nc-time">
              {{n.time | date:'d MMM yyyy HH:mm'}}
            </div>
          </div>
          <div class="nc-body">
            <div class="nc-user">
              <span class="nc-user-name">{{n.userName}}</span>
              <span class="nc-user-business" ng-if="n.userBusiness">| {{n.userBusiness}}</span>
            </div>
            <div class="nc-value">
              {{n.value}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div ng-if="notifications.length === 0 && !filterActive">
    You have no new notifications.
  </div>

  <div ng-if="notifications.length === 0 && filterActive">
    You have no notifications in this category.
  </div>

  <button class="btn-sm btn-primary" ng-click="clearNotification()">Clear</button>
  <button class="btn-sm btn-primary" ng-click="raiseNotification()">Raise</button>
</div>-->

<!--<div class="nc2 no-select">
  <div ng-if="(notifications.length > 0 || filterActive) && filterCategories && filterCategories.length > 1">
    <div class="result-category-filter no-select">
      <div>Filter:</div>
      <div class="category-filters">
        <div ng-repeat="category in filterCategories"
             ng-class="{'filter-selected': isSelected('{{category.value}}'), 'has-results': category.hasResults}"
             ng-click="changeCategory(category.value, $event, true)"
             data-category-value="category.value">
          <div class="category-badge"></div>{{category.name}}
        </div>
      </div>
      <a ng-click="clearNotifications">Mark as read</a>
    </div>

    <div style="position: relative; width: 100%; overflow: scroll; max-height: 51vh;">
      <div ng-repeat="n in notifications" class="nc-notification no-animate" ng-click="openNotification(n)">

        <div class="nc-header">
          <div class="nc-icon">
            <span class="fa {{n.iconClass}}"></span>
          </div>
          <div class="nc-title">
            {{n.title}}
          </div>
          <div class="nc-time">
            {{n.time | date:'d MMM yyyy HH:mm'}}
          </div>
        </div>

        <div class="nc-container">
          <div class="nc-body">
            <div class="nc-user">
              <span class="nc-user-name">{{n.userName}}</span>
              <span class="nc-user-business" ng-if="n.userBusiness">| {{n.userBusiness}}</span>
            </div>
            <div class="nc-value">
              {{n.value}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div ng-if="notifications.length === 0 && !filterActive">
    You have no new notifications.
  </div>

  <div ng-if="notifications.length === 0 && filterActive">
    You have no notifications in this category.
  </div>

  <button class="btn-sm btn-primary" ng-click="clearNotification()">Clear</button>
  <button class="btn-sm btn-primary" ng-click="raiseNotification()">Raise</button>
</div>-->

<!--<div class="nc3 no-select">
  <div ng-if="(notifications.length > 0 || filterActive) && filterCategories && filterCategories.length > 1">
    <div class="result-category-filter no-select">
      <div>Filter:</div>
      <div class="category-filters">
        <div ng-repeat="category in filterCategories"
             ng-class="{'filter-selected': isSelected('{{category.value}}'), 'has-results': category.hasResults}"
             ng-click="changeCategory(category.value, $event, true)"
             data-category-value="category.value">
          <div class="category-badge"></div>{{category.name}}
        </div>
      </div>
      <a ng-click="clearNotifications">Mark as read</a>
    </div>

    <div style="position: relative; width: 100%; overflow: scroll; max-height: 51vh;">
      <div ng-repeat="n in notifications" class="nc-notification no-animate" ng-click="openNotification(n)">

        <div class="nc-header">
          <div class="nc-icon">
            <span class="fa {{n.iconClass}}"></span>
          </div>
          <div class="nc-title">
            <div class="nc-user">
              <span class="nc-user-name">{{n.userName}}</span>
              <span class="nc-user-business" ng-if="n.userBusiness"><span class="fa fa-briefcase"></span></span>
              <span class="nc-description"> {{n.description}}</span>
            </div>
          </div>
          <div class="nc-time">
            {{n.time | date:'d MMM yyyy HH:mm'}}
          </div>
        </div>

        <div class="nc-container">
          <div class="nc-body">
            <div class="nc-value no-animate" ng-if="n.value">
              {{n.value}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div ng-if="notifications.length === 0 && !filterActive">
    You have no new notifications.
  </div>

  <div ng-if="notifications.length === 0 && filterActive">
    You have no notifications in this category.
  </div>

  <button class="btn-sm btn-primary" ng-click="clearNotification()">Clear</button>
  <button class="btn-sm btn-primary" ng-click="raiseNotification()">Raise</button>
</div>-->

<div class="nc4 no-select" ng-class="{'help-visible': nc.displayHelp}">
  <div ng-if="(nc.notifications.length > 0 || nc.filterActive) && nc.filterCategories && nc.filterCategories.length > 1">
    <div class="result-category-filter no-select">
      <div>Filter:</div>
      <div class="category-filters">
        <div ng-repeat="category in nc.filterCategories"
             ng-class="{'filter-selected': nc.isSelected('{{category.value}}'), 'has-results': category.hasResults}"
             ng-click="nc.changeCategory(category.value, $event, true)"
             data-category-value="category.value">
          <div class="category-badge"></div>{{category.name}}
        </div>
      </div>
      <a class="cursor-pointer" ng-click="nc.markRead();">Mark as read</a>
    </div>

    <div style="position: relative; width: 100%; overflow-y: auto; overflow-x: hidden; max-height: 51vh;">
      <div ng-repeat="n in nc.notifications" class="nc-notification no-animate">
        <div class="nc-notification-item"
             notification-swipe-item
             data-notification-id="{{n.id}}"
             on-swipe-done="nc.clearNotificationById(id)"
             on-swiping="nc.onSwiping(deltaX)"
             on-swipe-cancel=""
             on-click="nc.openNotificationById(id)">
          <div class="nc-icon">
            <span class="fa {{n.iconClass}}"></span>
          </div>
          <div class="nc-container">
            <div class="nc-body">
              <div class="nc-user">
                <span class="nc-user-name">{{n.userName}}</span>
                <!--<span class="nc-user-business" ng-if="n.userBusiness"><span class="fa fa-briefcase"></span></span>-->
                <span class="nc-description"> {{n.description}}</span>
              </div>
              <div style="display: flex; align-items: center;">
                <div class="nc-value no-animate">
                  {{n.value}}
                </div>
                <div class="nc-time">
                  <!--{{n.time | date:'d MMM yyyy HH:mm'}}-->
                  {{n.timeFormatted}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="nc-delete" ng-if="nc.isSwipeRight">
          <span class="fa fa-trash"></span>
        </div>
        <div class="nc-delete" style="right:0px;" ng-if="!nc.isSwipeRight">
          <span class="fa fa-trash"></span>
        </div>
      </div>
    </div>
  </div>

  <div ng-if="nc.notifications.length === 0 && !nc.filterActive">
    You have no new notifications.
  </div>

  <div ng-if="nc.notifications.length === 0 && nc.filterActive">
    You have no notifications in this category.
  </div>

  <div class="nc-help slide-if" ng-if="nc.displayHelp">
    <div>
      This is your notification center. Click the notification to display the details or swipe left or right to dismiss it!
    </div>
    <button class="btn-sm btn-primary" style="margin-top: 10px; margin-bottom: 5px;" ng-click="nc.dismissHelp()">Got it.</button>
  </div>
</div>
