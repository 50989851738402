(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name user.controller:AboutCtrl
   *
   * @description
   *
   */
  angular
    .module('user')
    .controller('AboutCtrl', AboutCtrl);

  function AboutCtrl($scope) {
    var vm = this;
    vm.userProfile = $scope.user.userProfile;
  }
}());
