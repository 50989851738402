(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name components.provider:GoogleMap
   *
   * @description
   *
   */
  angular
    .module('components')
    .provider('GoogleMap', GoogleMap);

  function GoogleMap() {
    var apiKey,
        libraries,
        isLoaded = false;

    return {
      init: function (key, libs) {
        apiKey = key;
        libraries = libs;
      },
      $get: ['$window', '$document', '$http', '$q', function ($window, $document, $http, $q) {
        var googleMapApi = $q.defer(),
            scriptElement;
        if (!isLoaded) {
          $window.googleMapInitCallback = function () {
            $window.googleMapInitCallback = null;
            isLoaded = true;
            googleMapApi.resolve();
          };

          scriptElement = $document[0].createElement('script');
          scriptElement.src = 'https://maps.googleapis.com/maps/api/js?callback=googleMapInitCallback&key=' + apiKey + '&libraries=' + libraries;
          $document[0].body.appendChild(scriptElement);
        }
        else {
          googleMapApi.resolve();
        }

        /**
         * Special location formatting for automatically detected location
         * @param {Object} address Structured address
         * @param {Boolean} preciseAddress Structured address
         * @returns {string} Formatted address
         */
        googleMapApi.getFormattedAddress = function (address, preciseAddress) {
          var parts = [],
              subParts = [];

          if (preciseAddress && address.streetNumber) {
            parts.push(address.streetNumber);
          }

          if (address.streetAddress) {
            parts.push(address.streetAddress);
          }

          if (address.suburb) {
            subParts.push(address.suburb);
          }

          if (address.state) {
            subParts.push(address.state);
          }

          if (address.postCode) {
            subParts.push(address.postCode);
          }

          if (subParts.length > 0) {
            parts.push(subParts.join(' '));
          }

          if (address.country) {
            parts.push(address.country);
          }

          return parts.join(', ');
        };

        googleMapApi.convertAddress = function (inputAddress) {
          /* eslint camelcase: [2, {properties: "never"}]*/
          var address = {},
              i,
              val,
              addressType,
              mappings,
              typeMappings,
              loopFunc;

          mappings = [
            {
              typeKey: 'street_number',
              targetKey: 'streetNumber',
              sourceKey: 'short_name'
            },
            {
              typeKey: 'route',
              targetKey: 'streetAddress',
              sourceKey: 'long_name'
            },
            {
              typeKey: 'locality',
              targetKey: 'suburb',
              sourceKey: 'long_name'
            },
            {
              typeKey: 'administrative_area_level_1',
              targetKey: 'state',
              sourceKey: 'short_name'
            },
            {
              typeKey: 'country',
              targetKey: 'country',
              sourceKey: 'long_name'
            },
            {
              typeKey: 'country',
              targetKey: 'country_id',
              sourceKey: 'short_name'
            },
            {
              typeKey: 'postal_code',
              targetKey: 'postCode',
              sourceKey: 'short_name'
            }];

          loopFunc = function (mapping) {
            val = inputAddress[i][mapping.sourceKey];
            address[mapping.targetKey] = val;
          };

          for (i = 0; i < inputAddress.length; i++) {
            addressType = inputAddress[i].types[0];
            typeMappings = _.where(mappings, {typeKey: addressType});
            angular.forEach(typeMappings, loopFunc);
          }

          return address;
        };

        googleMapApi.getCurrentLocation = function (preciseAddress) {
          var deferred = $q.defer();
          if ('geolocation' in $window.navigator) {
            $window.navigator.geolocation.getCurrentPosition(function (position) {
              var geocoder = new google.maps.Geocoder(),
                  gcRequest = {
                    location: new google.maps.LatLng(position.coords.latitude, position.coords.longitude)
                  };
              geocoder.geocode(
                gcRequest,
                function (gcResult, gcStatus) {
                  var address;
                  if (gcStatus === google.maps.GeocoderStatus.OK) {
                    address = googleMapApi.convertAddress(gcResult[0].address_components);
                    address.location = googleMapApi.getFormattedAddress(address, preciseAddress);
                    if (gcResult[0].geometry) {
                      address.geometry = {
                        lat: gcResult[0].geometry.location.lat(),
                        lng: gcResult[0].geometry.location.lng()
                      };
                    }
                    deferred.resolve(address);
                  }
                }
              );
            }, function () {
              deferred.reject();
            });
          }
          else {
            deferred.reject();
          }

          return deferred.promise;
        };

        googleMapApi.getStaticMapAsBase64 = function (address, options) {
          // Keep in mind that STATIC MAPS API will be constrain to only 640px if the key is not the business google maps license.
          // Check here https://developers.google.com/maps/documentation/static-maps/usage-limits

          var fileReader,
              deferred = $q.defer();

          if (!angular.isObject(options)) {
            deferred.reject();
          }

          try {
            $http({
              method: 'GET',
              url: 'https://maps.googleapis.com/maps/api/staticmap?format=png32&center=' + address + '&zoom=15&size=' + options.w + 'x' + options.h + '&maptype=roadmap',
              responseType: 'blob'
            }).then(function (response) {
              fileReader = new FileReader();
              fileReader.onload = function () {
                deferred.resolve(fileReader.result);
              };
              fileReader.readAsDataURL(response.data);
            });
          }
          catch (err) {
            deferred.reject();
          }

          return deferred.promise;
        };

        return googleMapApi;
      }]
    };
  }
}());
