(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name components.factory:FormAlerts
   *
   * @description
   *
   */
  angular
    .module('components')
    .factory('FormAlerts', FormAlerts);

  function FormAlerts() {
    function FormAlertsBase() {
      this.alerts = [];
      this.someMethod = function () {
        return 'FormAlerts';
      };
      this.getList = function () {
        return this.alerts;
      };

      this.add = function (type, message) {
        this.alerts.push({type: type, message: message});
      };

      this.remove = function (index) {
        this.alerts.splice(index, 1);
      };

      this.reset = function () {
        this.alerts = [];
      };
    }

    return FormAlertsBase;
  }
}());
