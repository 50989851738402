(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name mlpMarketplace.controller:MarketplaceInfoCtrl
   *
   * @description
   *
   */
  angular
    .module('mlpMarketplace')
    .controller('MarketplaceInfoCtrl', MarketplaceInfoCtrl);

  function MarketplaceInfoCtrl($scope, $sce, $uibModalInstance, marketplaceItem, Restangular, PromptDialog, Marketplace, Notification) {
    var vm = this;
    vm.item = marketplaceItem;
    vm.isLoading = false;
    vm.isUpdating = false;

    if (!marketplaceItem.info) {
      vm.isLoading = true;

      Restangular.one('Marketplace').one('index').get({id: marketplaceItem.id})
        .then(function (result) {
          marketplaceItem.info = $sce.trustAsHtml(result.info);
          vm.isLoading = false;
        }, function () {
          vm.isLoading = false;
        });
    }

    // Listen to the broadcast of 'close-modal-instance'
    $scope.$on('close-modal-instance', function () {
      $uibModalInstance.dismiss();
    });

    vm.close = function () {
      $uibModalInstance.dismiss();
    };

    vm.switchSubscription = function () {
      if (vm.isUpdating) {
        return;
      }
      if (vm.item.isSubscribed) {
        vm.deactivate();
      }
      else {
        vm.activate();
      }
    };

    vm.activate = function () {
      var item = vm.item;
      if (!item.isSubscribed) {
        vm.isUpdating = true;
        Marketplace.initiateActivation(item)
          .then(function (result) {
            if (result.active) {
              item.isSubscribed = true;
            }
            vm.isUpdating = false;
          }, function () {
            vm.isUpdating = false;
          });
      }
    };

    vm.deactivate = function () {
      var item = vm.item;
      if (item.isSubscribed) {
        PromptDialog.open('By clicking Deactivate, you will immediately cancel your subscription', null, 'Deactivate', 'Cancel')
          .then(function () {
            vm.isUpdating = true;
            vm.isProcessing = true;
            Marketplace.deactivate(item)
              .then(function () {
                Notification({message: 'Success'}, 'confirmation');
                item.isSubscribed = false;
                vm.isUpdating = false;
              }, function () {
                Notification({message: 'Unable to unsubscribe.'}, 'warning');
                vm.isUpdating = false;
              });
          });
      }
    };
  }
}());
