(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.directive:nodeRelocator
   * @restrict A
   * @element
   *
   * @description Directive that will handle the relocation of the element into the alternative node if the breakpoint is achieved (in direction from greater to small)
   *
   */
  angular
    .module('components')
    .directive('nodeRelocate', nodeRelocate);

  function nodeRelocate($window, $document) {
    return {
      restrict: 'A',
      scope: true,
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */

        var moveElement,
            isMobile;

        scope.altElementBreakpoint = attrs.altElementBreakpoint ? parseInt(attrs.altElementBreakpoint, 10) : 1024;
        if (!angular.isNumber(scope.altElementBreakpoint)) {
          scope.altElementBreakpoint = 1024;
        }

        moveElement = function (el) {
          // We are moving the target element into 'el' which is either the srcElement or the altElement
          if (!el || !el.append || !scope.srcElement) {
            console.log('The core elements are missing');
            return;
          }
          // Invalidate the new container's display property
          el.css({display: null});
          // Append the target element into the new container
          el.append(element);
          // Make sure the element we are moving the target from is empty
          if (el[0] === scope.srcElement[0]) {
            scope.altElement[0].innerHTML = '';
          }
          else {
            scope.srcElement[0].innerHTML = '';
          }
        };

        // Get the alternative elementi
        if (angular.isDefined(attrs.altElementId)) {
          // Try to find it. Unfortunately, the angular.element($document).find doesn't work
          scope.altElement = angular.element($document[0].getElementById(attrs.altElementId));
          scope.srcElement = element.parent();

          if (scope.altElement) {
            scope.altElement.addClass('alt-node');
            // Ok now we will have to listen to the change events and then move this directive's element into the alt-element
            // Check if it's mobile already. If it is, then move it straightaway
            isMobile = $window.innerWidth < scope.altElementBreakpoint;
            if (isMobile) {
              moveElement(scope.altElement);
              scope.altElement.addClass('node-relocated');
            }
            else {
              scope.altElement.removeClass('node-relocated');
            }

            angular.element($window).on('resize', function () {
              isMobile = $window.innerWidth < scope.altElementBreakpoint;
              if (scope.isMobile !== isMobile && isMobile) {
                moveElement(scope.altElement);
                scope.altElement.addClass('node-relocated');
              }
              else if (scope.isMobile !== isMobile && !isMobile) {
                moveElement(scope.srcElement);
                scope.altElement.removeClass('node-relocated');
              }
              scope.isMobile = isMobile;
            });

            scope.isMobile = isMobile;
          }
        }
      }
    };
  }
}());
