<div class="modal-content-container charity">
  <div class="row">
    <!-- Title -->
    <div class="col-xs-12 modal-content-title">
      <span class="sub-title">Donation</span>
    </div>
    <!-- ENDOF: Title-->

    <div class="divided-form-xs" style="clear:both;">

      <div class="pending-overlay-white pending-overlay-column" ng-if="donation.isLoading" style="position:absolute;">
        <span class="fa fa-circle-o-notch fa-spin-2x"></span>
        <div class="pending-overlay-description">Making Donation..</div>
      </div>

      <div class="charity-input-form">
        <form name="donationForm" class="no-select">

          <!-- Pending overlay -->
          <div class="pending-overlay-white pending-overlay-column opacity-slow-if" ng-if="donation.isCCLoading">
            <span class="fa fa-circle-o-notch fa-spin-2x"></span>
            <div class="pending-overlay-description">Loading your Credit Card</div>
          </div>

            <!-- Charity Select -->
          <div class="control-description-subtle">
            Charity receiving your donation
          </div>
          <div class="form-group">
            <div class="receiving-charity-container" ng-if="donation.charityProfile">
              <div>{{ donation.charityProfile.businessName ? donation.charityProfile.businessName : donation.charityProfile.firstName }}</div>
              <a href ng-click="donation.isSearchVisible = true;" class="cursor-pointer no-animate" role="button" tabindex="0" ng-if="donation.charityProfile && !donation.isSearchVisible">Change</a>
              <a href ng-click="donation.isSearchVisible = false;" class="cursor-pointer no-animate" role="button" tabindex="0" ng-if="donation.charityProfile && donation.isSearchVisible">Done</a>
            </div>
            <div class="slide-css controls-container" ng-class="{ slide: donation.isSearchVisible || !donation.charityProfile}">
              <div class="last-charities" ng-show="donation.lastCharities && donation.lastCharities.length > 0">
                <div class="control-description-subtle">Last donations</div>
                <div style="position: relative; overflow: hidden;">
                  <div class="container-scroll-shadow" scroll-check target-element-id="lastcharities"></div>
                  <div class="last-charities-container" id="lastcharities" drag-scroll="true" axis="x">
                    <div ng-repeat="charity in donation.lastCharities" ng-init="$last && donation.checkScroll()" ng-click="donation.selectCharity(charity);">
                      <table-user-details user="charity" is-link="false" ng-click="donation.selectCharity(charity)" tabindex="-1" />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div class="control-description-subtle">Search within MyLeadPod</div>
                <search-autocomplete
                  control-id="donation-autocomplete"
                  placeholder="Search Charities "
                  minlength="3"
                  clear-selected="false"
                  text-searching="false"
                  text-no-results="false"

                  search-service="Search"
                  search-service-end-point="index"
                  remote-url-response-formatter="donation.searchResponseFormatter"
                  search-categories="donation.searchCategories"
                  selected-object="donation.onSearchItemSelected"
                  focus-in="donation.emptyResults = false;"

                  data-field="content.records"

                  search-fields="search"
                  title-field="value"
                  description-field="categoryDescription"
                  description-field2="timeDescription"
                  category-field="category"

                  input-name="donationUsersInputSearch"
                  input-class="form-control input-lg"
                  match-class="autocomplete-highlight"
                  blur-on-select="true"
                  has-back-button="false"
                  update-input-on-select="true"

                  is-dropdown-relative="false"

                  override-suggestions="true"
                  field-required="false"
                  has-category-filter="false"
                  has-history="true"
                />
              </div>
            </div>
          </div>

            <!-- Payment Select -->
          <div class="form-group">
            <div class="control-description-subtle">
              Payment options
            </div>
            <credit-card cc-data="donation.data.paymentDetails" credit-card="donation.creditCard" ng-if="!donation.isCCLoading"/>
          </div>

          <!-- Donation Select -->
          <div class="form-group">
            <div class="control-description-subtle">
              Please provide the donation amount
            </div>
            <div class="validating-control" ng-class="{'has-error': (donationForm.$submitted || donationForm.amount.$touched) &&
                (donationForm.amount.$error.required || donationForm.amount.$error.number || donationForm.amount.$error.min)}">
              <label class="sr-only" for="amount">Reward value</label>
              <div ng-show="donationForm.$submitted || donationForm.amount.$touched">
                <div class="input-error" ng-show="donationForm.amount.$error.number">Invalid value</div>
                <div class="input-error" ng-show="donationForm.amount.$error.required">Missing value</div>
                <div class="input-error" ng-show="donationForm.amount.$error.min">Donation value must not be negative</div>
              </div>
              <div class="left-inner-addon input-group" style="width: 100%;">
                <i class="fa fa-dollar inner-addon"></i>
                <input class="form-control input-lg"
                       type="number"
                       name="amount"
                       id="amount"
                       placeholder="Donation Amount"
                       ng-required="true"
                       ng-model="donation.data.amount"
                       ng-change="donation.calculate()"
                       ng-keydown="donation.onKeyPress($event)"
                       min="0" />
              </div>
            </div>
            <div class="value-btns">
              <button class="btn-sm btn-default" ng-click="donation.calculateWith(10)">$10</button>
              <button class="btn-sm btn-default" ng-click="donation.calculateWith(20)">$20</button>
              <button class="btn-sm btn-default" ng-click="donation.calculateWith(50)">$50</button>
              <button class="btn-sm btn-default" ng-click="donation.calculateWith(100)">$100</button>
            </div>
          </div>

          <!-- Balance sheet -->
          <div class="slide-if" ng-if="donation.data.amount > 0">
            <div class="control-description-subtle">
              Payment Summary
            </div>
            <div class="controls-container pam" style="position: relative; font-size: 14px;">

              <!-- Pending overlay -->
              <div class="pending-overlay-white pending-overlay-column opacity-slow-if" ng-if="donation.isCalculating">
                <span class="fa fa-circle-o-notch fa-spin-2x"></span>
                <div class="pending-overlay-description">Recalculating</div>
              </div>

              <div class="row">
                <div class="col-xs-6 ">
                  Donation
                </div>
                <div class="col-xs-6 text-right">
                  {{donation.data.amount | currency:"$":2}}
                </div>
              </div>
              <!--<div class="row" ng-if="withdraw.showTotal && !withdraw.calculating && !withdraw.calculationRequired && withdraw.processingFee">-->
              <div class="row" ng-if="donation.summary.fee">
                <div class="col-xs-6 ">
                  Processing fee
                </div>
                <div class="col-xs-6  text-right">
                  {{donation.summary.fee >= 0 ? (donation.summary.fee | currency:"$":2) : "..."}}
                </div>
              </div>
              <div class="row">
                <div class="col-xs-6 3">
                  Tax
                </div>
                <div class="col-xs-6 text-right">
                  {{donation.summary.tax >= 0 ? (donation.summary.tax| currency:"$":2) : "..."}}
                </div>
              </div>
              <div class="row">
                <div class="col-xs-6 ">
                  <strong>Payment Total</strong>
                </div>
                <div class="col-xs-6  text-right">
                  <strong>{{donation.summary.total >= 0 ? (donation.summary.total | currency:"$":2) : "..."}}</strong>
                </div>
              </div>

            </div>
          </div>
        </form>
      </div>

      <div class="chosen-charity-info">
        <div style="text-align:center;">

          <div class="pending-overlay-white pending-overlay-column" ng-if="donation.isLoadingCharity">
            <span class="fa fa-circle-o-notch fa-spin-2x"></span>
            <div class="pending-overlay-description">Loading Charity Info</div>
          </div>

          <div ng-if="donation.charityProfile">
            <user-avatar size="large" is-link="false" user="donation.charityProfile"></user-avatar>
            <div class="charity-name">
              {{ donation.charityProfile.businessName ? donation.charityProfile.businessName : donation.charityProfile.firstName }}
            </div>
          </div>
          <div ng-if="!donation.charityProfile">
            <div class="charity-name">
              No Charity selected
            </div>
          </div>

          <div class="charity-donation">
            <!--<span>Your Donation</span>
            <span>{{donation.data.amount ? (donation.data.amount | currency:'$':0) : '$?'}}</span>-->
            <button ng-if="!donation.isRegister" type="button" class="btn btn-primary"
                    ng-disabled="donationForm.$invalid || donation.isLoading || donation.hasError || donation.isCalculating || !donation.charityProfile" ng-click="donation.donate()">
              {{donation.isLoading ? 'Sending ...' : 'Donate' + (donation.data.amount ? ' ' + (donation.data.amount | currency:'$':0) : '')}}</button>
          </div>
        </div>
    </div>
    </div>
  </div>
  <div class="row modal-content-buttons">
    <div class="col-xs-12 text-right">
      <button type="button" class="btn btn-default"
              ng-disabled="donation.isLoading" ng-click="donation.cancel()">
        Cancel
      </button>
    </div>
  </div>
</div>
