<div class="row" ng-if="!sendLead.selectedUser">
  <div class="col-xs-12">
    <h1 class="page-header">{{sendLead.isRegister ? 'Register Lead' : 'Send Lead'}}</h1>
  </div>
</div>

<div class="row">
  <div class="col-xs-12">
    <div class="page-content page-content-no-tabs">

      <!-- Name, Surname, Email, Phone, Description -->
      <form name="sendLeadForm" class="send-lead-form">
        <ng-include src="'lead/send-lead-form-partial.tpl.html'" />
      </form>

      <div ng-if="!sendLead.selectedUser">
        <div class="row form-section-title">
          <div class="col-xs-12">
            <span class="sub-title">{{sendLead.isRegister ? 'Select sender' : 'Select recipient'}}</span>
          </div>
        </div>

        <div class="row form-section-description">
          <div class="col-xs-12">
            <span ng-if="!sendLead.isRegister" class="sub-title-description">Select businesses
              (<a style="cursor: pointer;" ng-click="sendLead.showInviteNew()">or invite new</a>)
              that will receive your lead:
            </span>
            <span ng-if="sendLead.isRegister" class="sub-title-description">Select user
              (<a style="cursor: pointer;" ng-click="sendLead.showInviteNew()">or invite new</a>)
              who sent you the lead above:
            </span>
          </div>
        </div>

        <div class="row" ng-if="sendLead.validateRecipient && sendLead.isInvalidRecipient()">
          <div class="col-xs-12">
            <div class="input-error">Either select an existing person or invite a new one</div>
          </div>
        </div>

        <!-- Choose existing member -->
        <div class="row">
          <div class="col-xs-12">
            <div class="row slide-if" ng-if="!sendLead.showInviteForm">
              <!-- Select the recipients from the search input -->
              <div class="col-xs-12 col-md-6 form-group" >
                <search-autocomplete
                  control-id="{{sendLead.controlName}}-autocomplete"
                  placeholder="Search Recipient"
                  minlength="3"
                  text-searching="false"
                  text-no-results="false"
                  search-service="Search"
                  search-service-end-point="index"
                  remote-url-response-formatter="sendLead.searchResponseFormatter"
                  search-categories="sendLead.userSearchCategories"
                  selected-object="sendLead.onRecipientItemSelected"
                  focus-in="sendLead.activeSearch='recipients'; sendLead.emptyResults = false;"
                  data-field="content.records"
                  search-fields="search"
                  title-field="value"
                  description-field="categoryDescription"
                  description-field2="timeDescription"
                  category-field="category"
                  input-name="associateUsersInputSearch"
                  input-class="form-control input-lg"
                  match-class="autocomplete-highlight"
                  blur-on-select="true"
                  has-back-button="false"
                  update-input-on-select="true"
                  is-dropdown-relative="false"
                  override-suggestions="true"
                  field-required="false"
                  has-category-filter="false"
                  clear-selected="true"
                  has-history="true"
                />
              </div>
              <!-- Show the prompt to enter the non-existing business details if results not found -->
              <div class="col-xs-12 form-group" ng-if="sendLead.activeSearch=='recipients' && sendLead.emptyResults">
                Seems like the business you want to send a lead to is not yet a member of MyLeadPod. You can provide the details of the business manually
                <a ng-click="sendLead.showInviteForm = true;" style="cursor: pointer;">here</a>.
              </div>
            </div>
          </div>
        </div>
        <!-- Choose existing member : end -->

        <!-- Invite new member -->
        <div class="row form-group slide-if" ng-if="sendLead.showInviteForm">
          <div class="col-xs-12 col-sm-12">
            <div class="form-section-description">
              <span class="sub-title-description ng-scope">Provide the details of the person
                <span ng-if="!sendLead.isRegister">(s)</span> you'd like to invite, or
                <a ng-click="sendLead.showSearchForm()" style="cursor: pointer;">try Search again</a>:
              </span>
            </div>

            <div class="row">

              <div class="col-xs-12 col-sm-6">
                <!-- user name details -->
                <form name="newUserForm">

                  <div class="form-section-description-function">
                    <span>Contact Details:</span>
                  </div>

                  <!-- Name -->
                  <div class="row">
                    <div class="col-xs-12">
                      <div class="form-group" ng-class="{'has-error': (newUserForm.$submitted || newUserForm.inviteFirstName.$touched) && newUserForm.inviteFirstName.$error.required}">
                        <label class="sr-only" for="inviteFirstName">First name</label>
                        <div ng-show="newUserForm.$submitted || newUserForm.inviteFirstName.$touched">
                          <div class="input-error" ng-show="newUserForm.inviteFirstName.$error.required">First name is missing</div>
                        </div>
                        <div class="left-inner-addon">
                          <i class="fa fa-user inner-addon"></i>
                          <input class="form-control input-lg" type="text" name="inviteFirstName" id="inviteFirstName"
                                placeholder="First name" ng-model="sendLead.data.invitation.firstName" ng-required="true" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Email -->
                  <div class="row">
                    <div class="col-xs-12">
                      <div class="form-group" ng-class="{'has-error': (newUserForm.$submitted || newUserForm.inviteEmail.$touched) && (newUserForm.inviteEmail.$error.required || newUserForm.inviteEmail.$error.email)}">
                        <label class="sr-only" for="inviteEmail">Email</label>
                        <div ng-show="newUserForm.$submitted || newUserForm.inviteEmail.$touched">
                          <div class="input-error" ng-show="newUserForm.inviteEmail.$error.required">Email is missing</div>
                          <div class="input-error" ng-show="newUserForm.inviteEmail.$error.email">This is not a valid email</div>
                        </div>
                        <div class="left-inner-addon">
                          <i class="fa fa-envelope-o inner-addon"></i>
                          <input class="form-control input-lg" type="email" name="inviteEmail" id="inviteEmail" placeholder="Email"
                                ng-model="sendLead.data.invitation.email" ng-required="true" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Phone -->
                  <div class="row">
                    <div class="col-xs-12">
                      <div class="form-group" ng-class="{'has-error': (newUserForm.$submitted || newUserForm.phoneMobile.$touched) && (newUserForm.phoneMobile.$error.required || newUserForm.phoneMobile.$error.email)}">
                        <label class="sr-only" for="phoneMobile">Phone</label>
                        <div ng-show="newUserForm.$submitted || newUserForm.phoneMobile.$touched">
                          <div class="input-error" ng-show="newUserForm.phoneMobile.$error.required">Missing phone number.</div>
                        </div>
                        <div class="left-inner-addon">
                          <i class="fa fa-phone inner-addon"></i>
                          <input class="form-control input-lg" type="tel" name="phoneMobile" id="phoneMobile" placeholder="Phone Number (optional)"
                                 ng-required="false" ng-model="sendLead.data.invitation.phoneMobile" />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <!-- end of user name details -->
              </div>

              <div class="col-xs-12 col-sm-6">
                <!-- Rewards program selection -->
                <div class="row">
                  <!-- reward option for register lead -->
                  <div class="col-xs-12" ng-if="sendLead.isRegister">

                    <div class="form-section-description-function">
                      <span class="cursor-pointer no-outline" ng-click="sendLead.toggleMoreDetailsVisibility()">
                        Change reward agreement (more details)
                        <i class="detail-arrow fa fa-chevron-down" ng-class="{'fa-chevron-down': !sendLead.detailsVisible, 'fa-chevron-up': sendLead.detailsVisible}"></i>
                      </span>
                    </div>
                    <div class="slide-css" ng-class="{slide: sendLead.detailsVisible}">
                      <reward-selector user="sendLead.user" has-monthly-subscription="false" on-change="sendLead.onRewardSelectionChange(formData, isFormValid)"></reward-selector>
                    </div>

                  </div>
                </div>
                <!-- Rewards program selection : end -->
              </div>
            </div>
            <button class="btn btn-primary" ng-if="!sendLead.isRegister"
                    ng-disabled="newUserForm.$invalid || (!sendLead.isRewardsFormValid || !sendLead.isValidRecipient())"
                    ng-click="sendLead.addNewRecipient(newUserForm)">Add to Recipients</button>
          </div>
        </div>
        <!-- Invite New Member : end -->

        <!-- Recipients list -->
        <div class="row">
          <div class="col-xs-12">
            <div class="pending-overlay-light" ng-if="sendLead.searchingRecipient">
              <span class="fa fa-2x fa-circle-o-notch fa-spin-2x"></span>
            </div>
            <div class="lead-recipient-list">
              <span ng-hide="sendLead.selectedUsers.length > 0">Recipients</span>
              <div ng-repeat="user in sendLead.selectedUsers" class="lead-recipient">
                {{user.firstName}} {{user.lastName}} <a class="send-lead-remove-user cursor-pointer" ng-click="sendLead.removeUser($index)"><i class="fa fa-times"></i></a>
              </div>
              <div ng-repeat="user in sendLead.invitations" class="lead-recipient">
                {{user.firstName}} <span><i class="fa fa-user"></i> new</span> <a class="send-lead-remove-user cursor-pointer" ng-click="sendLead.removeNewRecipient($index)"><i class="fa fa-times"></i></a>
              </div>
            </div>
          </div>
        </div>


      </div>

      <div class="row form-section-body">
        <div class="col-xs-6 form-group">
          <button type="button" class="btn btn-default btn-lg btn-block"
                  ng-disabled="sendLead.isLoading" ng-click="sendLead.cancel()">
            Cancel
          </button>
        </div>
        <div class="col-xs-6 form-group">
          <button ng-if="!sendLead.isRegister" type="button" class="btn btn-primary btn-lg btn-block"
                  ng-disabled="sendLead.isLoading || sendLeadForm.$invalid || (sendLead.invitations.length === 0 && sendLead.selectedUsers.length === 0) || !sendLead.isValidName() || !sendLead.isValidContact()" ng-click="sendLead.send()">

            {{sendLead.isLoading ? 'Loading ...' : 'Send Lead'}}
          </button>
          <button ng-if="sendLead.isRegister" type="button" class="btn btn-primary btn-lg btn-block"
            ng-disabled="sendLeadForm.$invalid || sendLead.isLoading || !sendLead.isValidRecipient() || !sendLead.isValidName() || !sendLead.isValidContact()" ng-click="sendLead.register()">
            {{sendLead.isLoading ? 'Registering ...' : 'Register Lead'}}
          </button>
        </div>
      </div>

    </div>
  </div>
</div>
