(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name socialNetwork.constant:socialNetworkProvider
   *
   * @description
   *
   */
  angular
    .module('socialNetwork')
    .constant('socialNetworkProvider', {
      google: 'google',
      facebook: 'facebook',
      linkedIn: 'linkedin'
    });
}());
