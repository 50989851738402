<div class="app-store-links" ng-if="isAvailable && !isAuthenticated">
    <span clas="apps-title">Download MyLeadPod app from store</span>
    <div class="apps-container">
        <a href="https://itunes.apple.com/au/app/myleadpod/id1138902261?mt=8"
            style="background:url(//linkmaker.itunes.apple.com/assets/shared/badges/en-us/appstore-lrg.svg) no-repeat; background-size:contain;">
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.myleadpod.android.app&hl=en&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
            style="background:url(./images/google-play-badge.png) no-repeat; background-size:contain;">
        </a>
    </div>
</div>