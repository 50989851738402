(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name api.service:Auth
   *
   * @description
   *
   */
  angular
    .module('api')
    .service('Auth', Auth);

  function Auth($rootScope, $q, $timeout, $httpParamSerializer, $injector, Restangular, configOptions, Cache, Session) {
    var self = this,
        cache = Cache.create('auth');

    self.localSignoutTimeout = 200;

    /**
     * Returns the login token
     *
     * @returns {string} Login token
     */
    function getLoginToken() {
      return cache.get('loginToken');
    }

    /**
     * Saves the login token
     *
     * @param {string} loginToken Login token
     */
    self.saveLoginToken = function (loginToken) {
      cache.put('loginToken', loginToken);
    };

    /**
     * Deletes the login token
     */
    function deleteLoginToken() {
      cache.remove('loginToken');
    }

    function localSignOut() {
      deleteLoginToken();
      Session.destroy();
      cache.removeAll();
      $rootScope.$broadcast('event:auth-logoutSuccess');
    }

    /**
     * Signs user in
     *
     * @param {string} endPoint API end point
     * @param {Object} data Sign In data
     * @returns {Deferred} Resolved value will be either Session in case of success or reason in case of failure
     */
    function signIn(endPoint, data) {
      var deferred = $q.defer();
      $injector.get('OneSignal').getUserId().then(function (playerId) {
        data.oneSignalPlayerId = playerId;
      });
      Restangular.one('Users').post(endPoint, data)
        .then(function (result) {
          self.saveLoginToken(result.authToken);
          Session.create(result.profile);
          $rootScope.$broadcast('event:auth-loginConfirmed');
          deferred.resolve(Session);
        }, function (result) {
          deferred.reject({
            errorMessage: result.data.errorMessage,
            errorCode: result.data.errorCode
          });
        });

      return deferred.promise;
    }

    /**
     * Returns whether the user is authenticated
     *
     * @returns {boolean} True or False
     */
    self.isAuthenticated = function () {
      return Session.isSignedIn();
    };

    /**
     * Signs user out
     *
     * @param {boolean} serverLogout If false server side sign out will not be performed as well, default true
     *
     * @returns {void}
     */
    self.signOut = function (serverLogout) {
      var data = {};
      if (angular.isUndefined(serverLogout)) {
        serverLogout = true;
      }
      if (serverLogout) {
        $injector.get('OneSignal').getUserId().then(function (playerId) {
          data.oneSignalPlayerId = playerId;
        });
        Restangular.one('Users').post('signOut', data);
      }
      $timeout(localSignOut, self.localSignoutTimeout);
    };

    /**
     * Signs user in using username and password
     *
     * @param {string} username Username
     * @param {string} password Password
     * @returns {Deferred} Resolved value will be either Session in case of success or reason in case of failure
     */
    self.passwordSignIn = function (username, password) {
      return signIn('signIn', {
        username: username,
        password: password
      });
    };

    /**
     * Signs user in through social services (OAuth2)
     *
     * @param {string} provider Social service provider
     * @param {string} accessToken OAuth2 Access Token
     * @param {Object} params Additional parameters
     * @returns {Deferred} Resolved value will be either Session in case of success or reason in case of failure
     */
    self.socialSignIn = function (provider, accessToken, params) {
      var deferred = $q.defer();
      if (!angular.isObject(params)) {
        params = {};
      }
      Restangular.one('Users').post('socialNetworkSignIn', angular.extend({}, {
        oauth2: {
          accessToken: accessToken,
          provider: provider
        }
      }, params))
        .then(function (result) {
          if (angular.isDefined(result.activationToken)) {
            deferred.resolve({
              activationToken: result.activationToken
            });
          }
          else {
            self.saveLoginToken(result.authToken);
            Session.create(result.profile);
            $rootScope.$broadcast('event:auth-loginConfirmed');
            deferred.resolve(Session);
          }
        }, function (result) {
          deferred.reject(result.data.errorMessage);
        });

      return deferred.promise;
    };

    /**
     * Activates user account
     *
     * @param {string} token Activation token
     * @param {Object} userProfile User profile data
     * @returns {Deferred} Deferred promise
     */
    self.activateAccount = function (token, userProfile) {
      return signIn('activate', {
        token: token,
        account: userProfile
      });
    };

    /**
     * Returns security params necessary to access an API endpoint via URL
     * @returns {{X-Jwt-Assertion: string, X-Ff3-Api-Key: string}} Object with security params
     */
    self.getSecurityParams = function () {
      var params = {
            'X-Ff3-Api-Key': configOptions.apiKey
          },
          token = getLoginToken();
      if (angular.isDefined(token) && token !== null && token !== '') {
        params['X-Jwt-Assertion'] = token;
      }

      return params;
    };

    /**
     * Fixes API endpoint URL
     * @param {string} apiEndpoint API Endpoint
     * @return {string} Full API endpoint including base url and auth params
     */
    self.fixApiEndpointUrl = function (apiEndpoint) {
      var tempUrl = configOptions.baseUrl,
          paramsStr = $httpParamSerializer(self.getSecurityParams());

      tempUrl += apiEndpoint;
      if (paramsStr !== '') {
        if (tempUrl.indexOf('?') !== -1) {
          tempUrl += '&' + paramsStr;
        }
        else {
          tempUrl += '?' + paramsStr;
        }
      }

      return tempUrl;
    };

    /**
     * Check if user has permission
     *
     * @param {Object} userProfile User's profile
     * @param {string} permission  Name of permission to check
     * @return {boolean} Flag indicating if user has the permission or not
     */
    self.hasUserPermission = function (userProfile, permission) {
      if (angular.isObject(userProfile) && angular.isString(permission)) {
        if (angular.isObject(userProfile.permissions) && angular.isDefined(userProfile.permissions[permission])) {
          return userProfile.permissions[permission] === true;
        }
      }
      return false;
    };
  }
}());
