(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name myMemberships.directive:rewardSelector
   * @restrict EA
   * @element
   */
  angular
    .module('myMemberships')
    .directive('rewardSelector', rewardSelector);

  function rewardSelector() {
    return {
      restrict: 'EA',
      scope: {
        user: '=user',
        change: '&onChange',
        hasMonthlySubscription: '@',
        hasCode: '@',
        hasDefaultProgram: '@',
        hasPayPerLead: '@',
        hasCreditCard: '@',
        hasCancelAgreement: '@',
        clearOnBroadcast: '@',
        default: '@'
      },
      templateUrl: 'my-memberships/reward-selector-directive.tpl.html',
      replace: true,
      transclude: true,
      controllerAs: 'rewards',
      controller: ['$scope', '$filter', '$timeout', '$document', function ($scope, $filter, $timeout, $document) {
        var vm = this;

        vm.hasUser = angular.isDefined($scope.user) ? $scope.user : false;
        vm.hasDefaultProgram = angular.isDefined($scope.hasDefaultProgram) ? $scope.hasDefaultProgram === 'true' : true;
        vm.hasCode = angular.isDefined($scope.hasCode) ? $scope.hasCode === 'true' : true;
        vm.hasPayPerLead = angular.isDefined($scope.hasPayPerLead) ? $scope.hasPayPerLead === 'true' : true;
        vm.hasMonthlySubscription = angular.isDefined($scope.hasMonthlySubscription) ? $scope.hasMonthlySubscription === 'true' : true;
        vm.hasCC = angular.isDefined($scope.hasCreditCard) ? $scope.hasCreditCard === 'true' : true;
        vm.hasCancelAgreement = angular.isDefined($scope.hasCancelAgreement) ? $scope.hasCancelAgreement === 'true' : false;
        vm.clearOnBroadcast = angular.isDefined($scope.clearOnBroadcast) ? $scope.clearOnBroadcast : null;

        // Important. If the selector has default program, but no user is specified (it's most likely someone else's program other than the logged-in user)
        // then lock the default program (don't list the values!)
        if (vm.hasDefaultProgram && !vm.hasUser) {
          vm.hasLockedDefaultProgram = true;
        }

        if (vm.clearOnBroadcast) {
          $scope.$on(vm.clearOnBroadcast, function () {
            // Clear the form
            vm.data = {
              contractType: $scope.default || (vm.hasDefaultProgram ? 'Rewards Program' : null),
              leadPrice: null,
              successfulLeadPrice: null,
              subscriptionPrice: null,
              creditCard: {},
              rewardCode: null
            };
          });
        }

        vm.user = $scope.user;

        vm.isFormValid = false;
        vm.data = {
          contractType: $scope.default || (vm.hasDefaultProgram ? 'Rewards Program' : null),
          leadPrice: null,
          successfulLeadPrice: null,
          subscriptionPrice: null,
          creditCard: {},
          rewardCode: null
        };
        vm.rewardsProgramSetup = [];
        vm.rewardCodes = {};
        vm.selectedRewardCode = '';
        vm.selectedRewardCodeLabel = 'Reward Code';
        vm.isRewardsProgramDetailVisible = false;

        if (vm.hasCC) {
          vm.showCCForm = !vm.user.isCCSet;
        }

        // Setup the Reward selectors id, value, desc
        vm.selectors = {
          default: {
            id: 'rewardsProgram',
            value: 'Rewards Program',
            desc: 'Rewards Program'
          },
          code: {
            id: 'rewardCodeSelector',
            value: 'Reward Code',
            desc: 'Reward Code'
          },
          payPerLead: {
            id: 'payPerLead',
            value: 'Pay Per Lead',
            desc: 'Pay Per Lead'
          },
          subscription: {
            id: 'monthlySubscription',
            value: 'Subscription',
            desc: 'Monthly Subscription'
          },
          cancel: {
            id: 'cancelAgreement',
            value: 'Cancel',
            desc: 'Cancel Agreement'
          }
        };

        // Subscribe to the local update
        $scope.$on('event:user-updateLocalProfile', function () {
          if ($scope.user.rewardCodes) {
            vm.renderRewardCodes($scope.user.rewardCodes);
          }
          if (vm.hasDefaultProgram && !vm.hasLockedDefaultProgram && $scope.user.defaultRewardProgram) {
            vm.renderRewardProgramOptions($scope.user.defaultRewardProgram);
          }
        });

        // Render the reward codes.
        vm.renderRewardCodes = function (rewardCodes) {
          angular.forEach(rewardCodes, function (item) {
            var options = [];
            angular.forEach($filter('orderBy')(item.pricingOptions, 'successfulLeadPrice'), function (option) {
              options.push(option.name + ': ' + $filter('currency')(option.successfulLeadPrice, '$', 0));
            });
            vm.rewardCodes[item.rewardCode] = item.rewardCode + ' - ' + options.join(', ');
          });
        };
        if (vm.hasCode && $scope.user.rewardCodes) {
          vm.renderRewardCodes($scope.user.rewardCodes);
        }

        // Render reward program option
        vm.renderRewardProgramOptions = function (options) {
          if (options && options.length > 0) {
            angular.forEach(options, function (option) {
              vm.rewardsProgramSetup.push(option);
            });
          }
          else {
            vm.isRewardsProgramDetailVisible = true;
          }
        };
        if (vm.hasDefaultProgram && !vm.hasLockedDefaultProgram && $scope.user.defaultRewardProgram) {
          vm.renderRewardProgramOptions($scope.user.defaultRewardProgram);
        }

        vm.onContractTypeChange = function (value, form, evt) {
          var elementToFocus;
          if (vm.data.contractType === value) {
            return;
          }

          vm.data.contractType = value;
          if (vm.data.contractType === 'Pay Per Lead' || vm.data.contractType === 'Reward Code' || vm.data.contractType === 'Rewards Program') {
            delete form.$error.ccExp;
            delete form.$error.ccNumber;
            delete form.$error.ccNumberType;
            delete form.$error.ccExpMonth;
            delete form.$error.ccExpYear;
          }

          switch (vm.data.contractType) {
            case 'Pay Per Lead':
              elementToFocus = 'successfulLeadPrice';
              break;
            case 'Subscription':
              elementToFocus = 'subscriptionPrice';
              break;
            case 'Reward Code':
              elementToFocus = 'rewardCode';
              break;
            default:
              elementToFocus = null;
              break;
          }

          if (elementToFocus !== null) {
            $timeout(function () {
              angular.element($document[0].getElementById(elementToFocus))[0].focus();
            }, 200);
          }

          if (evt) {
            evt.preventDefault();
            evt.stopPropagation();
          }

          vm.onValueChanged();
        };

        vm.toggleRewardsProgramDetailVisibility = function () {
          vm.isRewardsProgramDetailVisible = !vm.isRewardsProgramDetailVisible;
        };

        vm.isValid = function () {
          var isValid = true;

          if (!vm.data.contractType) {
            isValid = false;
            return isValid;
          }

          if (vm.data.contractType === 'Rewards Program' && vm.hasUser && (!vm.rewardsProgramSetup || vm.rewardsProgramSetup.length === 0) ||
              vm.data.contractType === 'Reward Code' && !vm.data.rewardCode ||
              vm.data.contractType === 'Pay Per Lead' && !vm.data.successfulLeadPrice) {
            isValid = false;
          }
          // Do the CC separately
          if (vm.data.contractType === 'Subscription') {
            if (!vm.data.subscriptionPrice) {
              isValid = false;
            }
            else if (vm.showCCForm && (!vm.data.creditCard.holderName || !vm.data.creditCard.number || !vm.data.creditCard.expiryMonth || !vm.data.creditCard.expiryYear)) {
              isValid = false;
            }
          }
          return isValid;
        };

        vm.checkValidity = function () {
          // This is being run by the watcher in the dom (ngClass). It's here to check on the initial state of the form and will notify change callback only if the validity changes.
          var isValid = vm.isValid();
          if (vm.isFormValid !== isValid) {
            $scope.change({formData: vm.data, isFormValid: isValid});
            vm.isFormValid = isValid;
          }
        };

        vm.onValueChanged = function () {
          // This is being triggered by every change of any of the inputs. Will trigger the change callback each time.
          var isValid = vm.isValid();
          $scope.change({formData: vm.data, isFormValid: isValid});
        };
      }]
    };
  }
}());
