(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name components.service:Location
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('Location', Location);

  function Location(Restangular, Cache) {
    var self = this,
        countries = [],
        states = [];

    self.cache = Cache.create('location');

    if (!self.cache.get('countries')) {
      Restangular.one('Countries').getList('index?limit=-1')
        .then(function (result) {
          angular.forEach(result, function (country) {
            countries.push(country.plain());
          });
          self.cache.put('countries', countries);
        });
    }
    else {
      countries = self.cache.get('countries');
    }

    if (!self.cache.get('states')) {
      Restangular.one('States').getList('index?limit=-1')
        .then(function (result) {
          angular.forEach(result, function (state) {
            states.push(state.plain());
          });
          self.cache.put('states', states);
        });
    }
    else {
      states = self.cache.get('states');
    }

    self.getCountries = function () {
      return countries;
    };

    self.getStates = function (idCountry) {
      idCountry = parseInt(idCountry, 10);
      if (idCountry > 0) {
        return _.filter(states, function (item) {
          return item.idCountry === idCountry;
        });
      }

      return states;
    };

    self.getCountryId = function (countryCode) {
      try {
        return _.find(countries, {abbrevation: countryCode}).idCountry;
      }
      catch (ex) {
        return null;
      }
    };

    self.getCountryNameById = function (countryId) {
      try {
        return _.find(countries, {idCountry: Number(countryId)}).name;
      }
      catch (ex) {
        return null;
      }
    };

    self.getStateId = function (countryId, stateCode) {
      var sts;
      try {
        sts = self.getStates(countryId);
        return _.find(sts, {abbrevation: stateCode}).idState;
      }
      catch (ex) {
        return null;
      }
    };

    self.getStateNameById = function (countryId, stateId, shortName) {
      var sts;
      try {
        sts = self .getStates(countryId);
        if (!shortName) {
          return _.find(sts, {idState: Number(stateId)}).name;
        }
        return _.find(sts, {idState: Number(stateId)}).abbrevation;
      }
      catch (ex) {
        return null;
      }
    };
  }
}());
