<div>
  <div class="user-details">
    <div ng-if="user.mlpUserDetails.showAdvanced" style="width: 100%; position: relative; margin-bottom: 5px;">
      <span user-avatar size="small" is-link="isLink" user="user" style="margin: 0 auto; display: block;"></span>
    </div>
    <p ng-if="user.mlpUserDetails.showAdvanced && user.mlpUserDetails.descriptionText">
      <i class="fa fa-briefcase"></i>
      <span>{{user.mlpUserDetails.descriptionText}}</span>
    </p>
    <p ng-if="user.phoneMobile"><i class="fa fa-phone"></i> <a href="tel:{{user.phoneMobile}}">{{user.phoneMobile}}</a></p>
    <p ng-if="user.email"><i class="fa fa-envelope-o"></i> <a href="mailto:{{user.email}}">{{user.email}}</a></p>
  </div>
</div>
