<div class="row">
  <div class="col-md-12 text-center">
    <h1 class="page-header sign-up-page-header">Activate your account</h1>
    <div class="sign-up-page-sub-header">
      Please fill in the form to activate your account
    </div>
  </div>
</div>

<div class="row sign-up-page-container">
  <div class="sign-in-page-content text-center activate-form-content">
    <uib-alert ng-repeat="alert in activate.alerts.getList()" type="{{alert.type}}" close="activate.alerts.remove($index)">{{alert.message}}</uib-alert>
    <form name="activateAccountForm" ng-submit="activate.activate()">

      <div ng-if="activate.passwordVisible" class="form-group">
        <label class="sr-only" for="password">Create Your Password</label>
        <div class="left-inner-addon">
          <i class="fa fa-lock inner-addon"></i>
          <input class="form-control input-lg" type="password" name="password" id="password"
                 placeholder="Create Your Password" ng-required="true" ng-model="activate.data.password" />
        </div>
      </div>

      <div ng-if="activate.emailVisible" class="form-group" ng-class="{'has-error': (activateAccountForm.$submitted || activateAccountForm.email.$touched) && (activateAccountForm.email.$error.required || activateAccountForm.email.$error.email)}">
        <label class="sr-only" for="email">Email</label>
        <div ng-show="activateAccountForm.$submitted || activateAccountForm.email.$touched">
          <div class="input-error" ng-show="activateAccountForm.email.$error.required">Tell us your email.</div>
          <div class="input-error" ng-show="activateAccountForm.email.$error.email">This is not a valid email.</div>
        </div>
        <div class="left-inner-addon">
          <i class="fa fa-envelope-o inner-addon"></i>
          <input class="form-control input-lg" type="email" name="email" id="email"
                 placeholder="Email" ng-required="true" ng-model="activate.data.email" />
        </div>
      </div>

      <div class="form-group" ng-class="{'has-error': (activateAccountForm.$submitted || activateAccountForm.firstName.$touched) && activateAccountForm.firstName.$error.required}">
        <label class="sr-only" for="firstName">First name</label>
        <div ng-show="activateAccountForm.$submitted || activateAccountForm.firstName.$touched">
          <div class="input-error" ng-show="activateAccountForm.firstName.$error.required">Tell us your first name.</div>
        </div>
        <div class="left-inner-addon">
          <i class="fa fa-user inner-addon"></i>
          <input class="form-control input-lg" type="text" name="firstName" id="firstName"
                 placeholder="First name" ng-required="true" ng-model="activate.data.firstName" />
        </div>
      </div>

      <div class="form-group" ng-class="{'has-error': (activateAccountForm.$submitted || activateAccountForm.lastName.$touched) && activateAccountForm.lastName.$error.required}">
        <label class="sr-only" for="lastName">Last name</label>
        <div ng-show="activateAccountForm.$submitted || activateAccountForm.lastName.$touched">
          <div class="input-error" ng-show="activateAccountForm.lastName.$error.required">Tell us your last name.</div>
        </div>
        <div class="left-inner-addon">
          <i class="fa fa-user inner-addon"></i>
          <input class="form-control input-lg" type="text" name="lastName" id="lastName"
                 placeholder="Last name" ng-required="true" ng-model="activate.data.lastName" />
        </div>
      </div>

      <div class="form-group">
        <label class="sr-only" for="location">Location</label>
        <div class="left-inner-addon">
          <i class="fa fa-map-marker inner-addon"></i>
          <input class="form-control input-lg" type="text" name="location" id="location"
                 placeholder="Location" ng-model="activate.data.location"
                 location-search input-id="location" place-changed-callback="activate.placeChangedCallback" />
        </div>
      </div>

      <div class="form-group" ng-class="{'has-error': (activateAccountForm.$submitted || activateAccountForm.phoneMobile.$touched) && activateAccountForm.phoneMobile.$error.required}">
        <label class="sr-only" for="phoneMobile">Mobile</label>
        <div ng-show="activateAccountForm.$submitted || activateAccountForm.phoneMobile.$touched">
          <div class="input-error" ng-show="activateAccountForm.phoneMobile.$error.required">Tell us your mobile phone number.</div>
        </div>
        <div class="left-inner-addon">
          <i class="fa fa-mobile inner-addon"></i>
          <input class="form-control input-lg" type="text" name="phoneMobile" id="phoneMobile"
                 placeholder="Mobile" ng-required="true" ng-model="activate.data.phoneMobile" />
        </div>
      </div>

      <div class="form-group" ng-if="!activate.rewardCodeLabel" ng-class="{'text-left': !activate.rewardCodeLabel}">
        <label for="rewardCode">If you have a Reward Code, type it below or leave the field blank
          <i class="no-select info-help" role="button" tabIndex="0" style="margin-left: 15px;"
             popover-trigger="click, outsideClick"
             popover-append-to-body="true"
             popover-class="help"
             uib-popover="{{activate.rewardCodeHelp}}">?</i>
        </label>
        <div class="left-inner-addon">
          <i class="fa fa-hashtag inner-addon"></i>
          <input class="form-control input-lg" type="text" name="rewardCode" id="rewardCode"
                 placeholder="Reward Code (optional)" ng-model="activate.data.rewardCode" ng-readonly="activate.rewardCodeReadOnly" />
        </div>
      </div>

      <!--<div class="form-group" ng-if="activate.rewardCodeLabel && activate.rewardCodeReadOnly">
        <label for="rewardCode">Reward Code</label>
        <div class="reward-code-label">
          <i class="fa fa-hashtag inner-addon"></i>
          <span>{{activate.data.rewardCode}}</span>
        </div>
      </div>-->

      <div class="form-group checkbox text-left ">
        <label>
          <input type="checkbox" name="newsletter" ng-model="activate.data.newsletter">
          Send me emails with tips and hints to help me turn into a pro
        </label>
      </div>

      <div class="form-group sign-up-page-btn-create">
        <button type="submit" class="btn btn-primary btn-lg btn-block"
                ng-disabled="activate.isLoading">
          {{activate.isLoading ? 'Loading ...' : 'Activate Account'}}
        </button>
      </div>
    </form>
  </div>
</div>
