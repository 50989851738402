<div class="col-xs-12" id="agreements-ctrl-container" ng-controller="AgreementsCtrl as agreements">

  <div ng-if="agreements.userProfile.isBusinessAccountActive"
       id="send-leads-agreements-container"
       class="page-content page-content-with-header"
       ng-class="{'agreement-acceptance-awaiting': !agreements.sendLeads.revision && agreements.sendLeads.idUserSender !== agreements.myProfile.id && agreements.sendLeads && !agreements.sendLeads.isAccepted}">
    <div class="page-content-header">
      <div class="row row-center-items">
        <div class="col-xs-12 text-center">
          <div class="page-header-title">Send leads to {{agreements.userProfile.fullName}}</div>
        </div>
      </div>
    </div>

    <div class="revision-message-detail slide-if" ng-if="agreements.sendLeads && !agreements.sendLeads.revision && agreements.sendLeads.idUserSender !== agreements.myProfile.id && !agreements.sendLeads.isAccepted">
      <div class="row">
        <div class="col-xs-12">
          Accept the suggested reward from {{agreements.userProfile.fullName}} or revise the agreement.
        </div>
      </div>
    </div>
    <div class="revision-message-detail slide-if" ng-if="agreements.sendLeads && (agreements.sendLeads.idUserSender === agreements.myProfile.id || (agreements.sendLeads.revision && agreements.sendLeads.idUserSender !== agreements.myProfile.id)) && !agreements.sendLeads.isAccepted">
      <div class="row">
        <div class="col-xs-12">
          You sent a proposal to {{agreements.userProfile.fullName}} on {{agreements.sendLeads.dateSent | date:'d MMM yyyy'}}
        </div>
      </div>
    </div>

    <div class="page-content-body">
      <!--<div class="row" ng-if="agreements.sendLeads">
        <div class="col-xs-12">
          <div class="revision-message-detail" ng-if="!agreements.sendLeads.revision && agreements.sendLeads.idUserSender !== agreements.myProfile.id && !agreements.sendLeads.isAccepted">
            Accept the suggested reward from {{agreements.userProfile.fullName}} or revise the agreement.
          </div>
          <div class="revision-message-detail" ng-if="(agreements.sendLeads.idUserSender === agreements.myProfile.id || (agreements.sendLeads.revision && agreements.sendLeads.idUserSender !== agreements.myProfile.id)) && !agreements.sendLeads.isAccepted">
            You sent a proposal to {{agreements.userProfile.fullName}} on {{agreements.sendLeads.dateSent | date:'d MMM yyyy'}}
          </div>
        </div>
      </div>-->
      <div class="row" ng-if="agreements.sendLeads">
        <agreement-pricing-detail
          style="position: relative;display: block;"
          class="clearfix form-group"
          agreement="agreements.sendLeads"
          label="'I receive'"></agreement-pricing-detail>

        <div class="hidden-xs col-xs-12 text-center" ng-if="agreements.sendLeads && !agreements.sendLeads.revise">
          <button class="btn btn-default" ng-click="agreements.switchReviseSendLeadsContent()">Revise</button>
          <button
            ng-if="!agreements.sendLeads.revision && agreements.sendLeads.idUserSender !== agreements.myProfile.id && !agreements.sendLeads.isAccepted"
            class="btn btn-success" ng-click="agreements.acceptSendLeads()" ng-disabled="agreements.isLoading">Accept
          </button>
        </div>
      </div>

      <div class="row visible-xs revise-buttons-row" ng-if="agreements.sendLeads && !agreements.sendLeads.revise">
        <div class="text-center visible-xs"
             ng-class="{'col-xs-6': !agreements.sendLeads.revision && agreements.sendLeads.idUserSender !== agreements.myProfile.id && !agreements.sendLeads.isAccepted,
                        'col-xs-12': !(!agreements.sendLeads.revision && agreements.sendLeads.idUserSender !== agreements.myProfile.id && !agreements.sendLeads.isAccepted)}">
          <button class="btn btn-primary" style="margin: 0px 0px 10px 0px;" ng-click="agreements.sendLead()" ng-if="agreements.sendLeads.isAccepted">
            Send Lead
          </button>
          <button class="btn btn-default" ng-click="agreements.switchReviseSendLeadsContent()">
            Revise
          </button>
        </div>
        <div class="col-xs-6 text-center visible-xs">
          <button
            ng-if="!agreements.sendLeads.revision && agreements.sendLeads.idUserSender !== agreements.myProfile.id && !agreements.sendLeads.isAccepted"
            class="btn btn-success" ng-click="agreements.acceptSendLeads()" ng-disabled="agreements.isLoading">Accept
          </button>
        </div>
      </div>

      <div class="row revision-row" ng-if="agreements.sendLeads.revision">
        <div class="col-xs-9">
          You sent a revised proposal to {{agreements.userProfile.fullName}} on
          {{agreements.sendLeads.revision.dateSent | date:'d MMM yyyy'}}
        </div>
        <div class="col-xs-3 text-right">
          <a class="cursor-pointer visible-xs"
             ng-click="agreements.showSendLeadsRevision = !agreements.showSendLeadsRevision">
            <i class="fa fa-ellipsis-h"></i>
          </a>
          <a class="cursor-pointer hidden-xs"
             ng-click="agreements.showSendLeadsRevision = !agreements.showSendLeadsRevision">View details</a>
        </div>
      </div>

      <div class="row revision-row-detail slide-if" ng-if="agreements.showSendLeadsRevision">
        <agreement-pricing-detail agreement="agreements.sendLeads.revision" label="'I receive'"></agreement-pricing-detail>
      </div>

      <div class="row revision-row slide-if" ng-if="agreements.sendLeads.revise">
        <div class="col-xs-12">
          <div class="row">
            <div class="col-xs-12 form-group">
              Revise
            </div>
          </div>
          <div>
            <reward-selector class="form-group"
                             has-default-program="agreements.userProfile.hasRewardsProgram && agreements.sendLeads.contractType !== 'Rewards Program'"
                             has-subscription="true"
                             has-credit-card="false"
                             has-cancel-agreement="true"
                             has-code="false"
                             on-change="agreements.onRewardSelectionChangeSendLeads(formData, isFormValid)">
            </reward-selector>

            <div class="row">
              <div class="col-xs-6">
                <button class="btn btn-default btn-full-width"
                        ng-click="agreements.cancelSendLeadsRevision()">Cancel
                </button>
              </div>
              <div class="col-xs-6">
                <button id="reviseSendLeadsBtn" class="btn btn-primary btn-full-width" ng-disabled="!agreements.isSendLeadsRewardsFormValid || agreements.isLoading" ng-click="agreements.reviseSendLeads()">Send</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" ng-if="!agreements.sendLeads">
        <div class="col-xs-12 text-center form-group">
          Join {{agreements.userProfile.fullName}}'s Rewards Program and receive up to {{agreements.userProfile.highestReward | currency: '$':0}}
        </div>

        <div class="col-xs-12 text-center">
          <span ng-if="agreements.invitePending" style="color: black">
            <i class="fa fa-1x fa-circle-o-notch fa-spin-2x"></i>
          </span>
          <a ng-click="agreements.quickJoin()" class="btn btn-primary" ng-disabled="agreements.invitePending">
            Join
          </a>
        </div>
      </div>
    </div>

  </div>

  <div id="receive-leads-agreements-container"
       class="page-content page-content-with-header"
       ng-class="{'agreement-acceptance-awaiting': !agreements.receiveLeads.revision && agreements.receiveLeads.idUserSender !== agreements.myProfile.id && agreements.receiveLeads && !agreements.receiveLeads.isAccepted}">

    <div class="page-content-header">
      <div class="row row-center-items">
        <div class="col-xs-12 text-center">
          <div class="page-header-title">Receive leads from {{agreements.userProfile.fullName}}</div>
        </div>
      </div>
    </div>

    <div ng-if="!agreements.isBusinessAccountActive()">
      <div class="page-content-body">
        <div class="row">
          <div class="col-xs-12 text-center form-group">
            You need to activate Business Account in order to be able to receive leads.
            <div class="sub-title-description" ng-if="agreements.trialData.isEligibleForTrial" style="font-size: 14px">
              (You are eligible for {{agreements.trialData.expiresIn}}-day Business Account Trial)
            </div>
          </div>
          <div class="col-xs-12 text-center">
            <a ng-click="agreements.activateBusinessAccount()" class="btn btn-primary">
              Activate Business Account <span ng-if="agreements.trialData.isEligibleForTrial"> Trial</span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div ng-if="agreements.isBusinessAccountActive()">
      <div class="revision-message-detail slide-if" ng-if="agreements.receiveLeads && !agreements.receiveLeads.revision && agreements.receiveLeads.idUserSender !== agreements.myProfile.id && !agreements.receiveLeads.isAccepted">
        <div class="row">
          <div class="col-xs-12">
            Accept the suggested reward from {{agreements.userProfile.fullName}} or revise the agreement.
          </div>
        </div>
      </div>
      <div class="revision-message-detail slide-if" ng-if="agreements.receiveLeads && (agreements.receiveLeads.idUserSender === agreements.myProfile.id || (agreements.receiveLeads.revision && agreements.receiveLeads.idUserSender !== agreements.myProfile.id)) && !agreements.receiveLeads.isAccepted">
        <div class="row">
          <div class="col-xs-12">
            You sent a proposal to {{agreements.userProfile.fullName}} on {{agreements.receiveLeads.dateSent | date:'d MMM yyyy'}}
          </div>
        </div>
      </div>

      <div class="page-content-body">

        <div class="row" ng-if="agreements.receiveLeads">
          <agreement-pricing-detail
            style="position: relative;display: block;"
            class="clearfix form-group"
            agreement="agreements.receiveLeads"
            label="'I pay'">
          </agreement-pricing-detail>

          <div class="hidden-xs text-center col-xs-12" ng-if="agreements.receiveLeads && !agreements.receiveLeads.revise">
            <button class="btn btn-default" ng-click="agreements.switchReviseReceiveLeadsContent()">Revise
            </button>
            <button
              ng-if="!agreements.receiveLeads.revision && agreements.receiveLeads.idUserSender !== agreements.myProfile.id && !agreements.receiveLeads.isAccepted"
              class="btn btn-success" ng-click="agreements.acceptReceiveLeads()" ng-disabled="agreements.isLoading">Accept
            </button>
          </div>
        </div>

        <div class="row visible-xs revise-buttons-row" ng-if="agreements.receiveLeads && !agreements.receiveLeads.revise">
          <div class="text-center"
               ng-class="{'col-xs-6': !agreements.receiveLeads.revision && agreements.receiveLeads.idUserSender !== agreements.myProfile.id && !agreements.receiveLeads.isAccepted,
                        'col-xs-12': !(!agreements.receiveLeads.revision && agreements.receiveLeads.idUserSender !== agreements.myProfile.id && !agreements.receiveLeads.isAccepted)}">
            <button class="btn btn-default" ng-click="agreements.switchReviseReceiveLeadsContent()">
              Revise
            </button>
          </div>
          <div class="col-xs-6 text-center">
            <button
              ng-if="!agreements.receiveLeads.revision && agreements.receiveLeads.idUserSender !== agreements.myProfile.id && !agreements.receiveLeads.isAccepted"
              class="btn btn-success" ng-click="agreements.acceptReceiveLeads()" ng-disabled="agreements.isLoading">Accept
            </button>
          </div>
        </div>

        <div class="row revision-row" ng-if="agreements.receiveLeads.revision">
          <div class="col-xs-9">
            You sent a revised proposal to {{agreements.userProfile.fullName}} on
            {{agreements.receiveLeads.revision.dateSent | date:'d MMM yyyy'}}
          </div>
          <div class="col-xs-3 text-right">
            <a class="cursor-pointer visible-xs"
               ng-click="agreements.showReceivedLeadsRevision = !agreements.showReceivedLeadsRevision">
              <i class="fa fa-ellipsis-h"></i>
            </a>
            <a class="cursor-pointer hidden-xs"
               ng-click="agreements.showReceivedLeadsRevision = !agreements.showReceivedLeadsRevision">View details</a>
          </div>
        </div>

        <div class="row revision-row-detail slide-if" ng-if="agreements.showReceivedLeadsRevision">
          <agreement-pricing-detail agreement="agreements.receiveLeads.revision" label="'I pay'"></agreement-pricing-detail>
        </div>

        <div class="row revision-row slide-if" ng-if="agreements.receiveLeads.revise">
          <div class="col-xs-12">
            <div class="row">
              <div class="col-xs-12 form-group">
                Revise
              </div>
            </div>
            <div>
              <reward-selector
                user="agreements.myProfile" class="form-group"
                has-default-program="agreements.receiveLeads.contractType !== 'Rewards Program'"
                has-subscription="true"
                has-cancel-agreement="true"
                on-change="agreements.onRewardSelectionChangeReceiveLeads(formData, isFormValid)">
              </reward-selector>

              <div class="row">
                <div class="col-xs-6">
                  <button class="btn btn-default btn-full-width"
                          ng-click="agreements.cancelReceiveLeadsRevision()">Cancel
                  </button>
                </div>
                <div class="col-xs-6">
                  <button id="reviseReceiveLeadsBtn" class="btn btn-primary btn-full-width" ng-disabled="!agreements.isReceiveLeadsRewardsFormValid || agreements.isLoading"
                          ng-click="agreements.reviseReceiveLeads()">Send</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row" ng-if="!agreements.receiveLeads">
          <div class="col-xs-12 text-center form-group">
            Would you like to receive leads from {{agreements.userProfile.fullName}}?
          </div>
          <div class="col-xs-12 text-center">
            <a ng-click="agreements.openAskForLeads()" class="btn btn-primary">
              Ask for Leads
            </a>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>
