<div class="modal-content-container deactivate-account-modal">
  <div class="row">
    <!-- Title -->
    <div class="col-xs-12 modal-content-title">
      <span class="sub-title" >Account Deactivation</span>
    </div>
    <!-- ENDOF: Title-->

    <!-- Content -->

    <div class="row">
      <div class="col-xs-12">
        <div class="steps">
          <div class="step" data-step-id="0">
            Account to Remove
          </div>
          <div class="step-separator" data-after-step-id="1">
            <span class="fa fa-chevron-right"></span>
          </div>
          <div class="step" data-step-id="1">
            Feedback
          </div>
          <div class="step-separator" data-after-step-id="2">
            <span class="fa fa-chevron-right"></span>
          </div>
          <div class="step" data-step-id="2">
            Deactivate
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12">
        <div class="stepped-wrapper" content-slider>
          <div class="stepped-container">

            <div class="step-content" data-step-id="0">
              <div class="split-content">
                <div class="profile-content-container" ng-if="ctrl.isBusinessAccount">

                  <div class="profile-content-title">
                    Deactivate Business Account
                  </div>

                  <div class="profile-content">
                    By clicking Continue, you will start the process of deactivating your business account and you will no longer need to pay subscription fees.<br>
                    Other MyLeadPod members will no longer be able to locate your business on MyLeadPod app and will not be able to send you leads.<br>
                    <!--<span class="slide-if" ng-if="!ctrl.data.wholeAccount">
                      Your account will be downgraded to the free account.
                    </span>-->
                  </div>

                  <!--<div style="text-align: center; margin-top: 10px; background-color: white;">
                    <input type="checkbox" id="removeFreeAccount" ng-model="ctrl.data.wholeAccount" style="margin: 10px;" />
                    <label for="removeFreeAccount">Close my free account</label>
                  </div>-->

                  <div class="profile-content-buttons">
                    <button class="btn btn-primary" data-step-to="1">Continue</button>
                  </div>

                </div>
                <div class="profile-content-container" ng-if="!ctrl.isBusinessAccount">
                  <div class="profile-content-title">
                    Deactivate my account.
                  </div>
                  <div class="profile-content">
                    By clicking Continue, you will start the process of deactivating your MyLeadPod account and will no longer be able to send the leads and to be rewarded.<br>
                    You will also be unsubscribed from all MyLeadPod marketing communication.
                  </div>
                  <div class="profile-content-buttons">
                    <button class="btn btn-primary" data-step-to="1">Continue</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="step-content" data-step-id="1">
              <div class="profile-content-container">
                  <div class="feedback-prompt profile-content">
                    <span>We are sorry that you are leaving us. We'd love to hear how we could improve MyLeadPod with your valuable feedback below.</span>
                    <span>Thank you.</span>
                  </div>
                  <form name="feedbackForm" style="margin-top: 15px;">
                    <div class="form-group">
                      <label class="sr-only" for="details">Feedback</label>
                      <div class="left-inner-addon">
                        <i class="fa fa-comment-o inner-addon"></i>
                        <textarea class="form-control input-lg" name="details" id="details" placeholder="Feedback"
                                  ng-required="true" ng-model="ctrl.data.feedback"></textarea>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="step-buttons" data-active-step-id="1" style="width:100%;">
                  <button class="btn btn-primary" data-step-to="0">
                    Back
                  </button>
                  <button class="btn btn-primary no-animate" data-step-to="2" ng-click="ctrl.cancelHidden = true;">
                    Next
                  </button>
                </div>
            </div>


            <div class="step-content" data-step-id="2">

                <div class="profile-content-container" style="text-align:center; width: auto;">
                  <div class="profile-content">
                    <span ng-if="ctrl.data.wholeAccount === true">Confirm the deactivation of your MyLeadPod account.</span>
                    <span ng-if="ctrl.data.wholeAccount === false">Confirm the deactivation of your MyLeadPod business account.</span>
                  </div>
                  <div class="profile-content-buttons">
                    <button class="btn btn-danger" ng-disabled="ctrl.isDeactivating" ng-click="ctrl.deactivate();">{{ ctrl.isDeactivating ? 'Deactivating...' : 'Deactivate'}}</button>
                  </div>
                </div>
                <div class="step-buttons" data-active-step-id="2" style="width:100%;">
                  <button class="btn btn-primary" data-step-to="1" ng-click="ctrl.cancelHidden = false" ng-disabled="ctrl.isDeactivating">
                    Back
                  </button>
                  <button
                    class="btn btn-default" ng-disabled="ctrl.isDeactivating"
                    ng-click="ctrl.close()">
                    Cancel
                  </button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ENDOF: Content -->
  </div>
  <div class="row modal-content-buttons slide-if" ng-if="!ctrl.cancelHidden">
    <div class="col-xs-12 text-right">
      <button
              class="btn btn-default" ng-disabled="ctrl.isDeactivating"
              ng-click="ctrl.close()">
        Cancel
      </button>
    </div>
  </div>
</div>
