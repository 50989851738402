(function () {
  'use strict';

  /* @ngdoc object
   * @name toolbar
   * @description
   *
   */
  angular
    .module('toolbar', [
      'ui.router',
      'mlp-notifications'
    ]);
}());
