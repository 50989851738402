(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name socialShare.provider:Facebook
   *
   * @description
   *
   */
  angular
    .module('socialShare.providers')
    .provider('FacebookSocialShare', FacebookSocialShare);

  function FacebookSocialShare() {
    var config = {
      appId: null,
      version: 'v2.3',
      xfbml: true,
      popupHeight: 500,
      popupWidth: 500
    };

    function setConfig(newConfig) {
      if (angular.isObject(newConfig)) {
        angular.forEach(newConfig, function (value, paramName) {
          if (angular.isDefined(config[paramName])) {
            config[paramName] = value;
          }
          else {
            throw new Error('Invalid configuration option');
          }
        });
      }
    }

    return {
      $get: ['$q', '$window', '$rootScope', 'angularLoad', function ($q, $window, $rootScope, angularLoad) {
        /* jshint unused:false */
        /* eslint no-unused-vars: [2, {"args": "none", "varsIgnorePattern": "Handler" }]*/
        var $facebook = $q.defer(),
            fbInitHandler;

        $facebook.init = function (newConfig) {
          setConfig(newConfig);

          if (angular.isUndefined(config.appId) || config.appId === null) {
            throw new Error('Missing APP ID');
          }

          if (!$window.FB) {
            angularLoad.loadScript('//connect.facebook.net/en_US/sdk.js')
              .then(function () {
                $window.FB.init({
                  appId: config.appId,
                  version: config.version,
                  xfbml: config.xfbml
                });
                $rootScope.$broadcast('fb.init');
              });
          }
        };

        fbInitHandler = $rootScope.$on('fb.init', function () {
          $facebook.resolve(true);
        });

        $facebook.share = function (data) {
          var params = {
            method: 'feed',
            link: data.url
          };
          if (data.title) {
            params.caption = data.title;
          }

          if (data.name) {
            params.name = data.name;
          }

          if (data.description) {
            params.description = data.description;
          }

          if (data.picture) {
            params.picture = data.picture;
          }

          $window.FB.ui(params);
        };

        return $facebook;
      }]
    };
  }
}());
