<div class="modal-content-container">
  <div class="row">
    <div class="col-xs-12" ng-form="form">
      <!-- Title -->
      <div class="row">
        <div class="col-xs-12 modal-content-title">
          <span class="sub-title">Ask for Leads</span>
        </div>
      </div>
      <!-- ENDOF: Title-->

      <!-- Content -->
      <div class="row">
        <div class="col-xs-12">

          <div ng-if="!joinPod.targetUser" ng-include="'my-memberships/join-pod/partials/form-user-details.tpl.html'"></div>

          <uib-alert ng-repeat="alert in joinPod.alerts.getList()" type="{{alert.type}}" close="joinPod.alerts.remove($index)">{{alert.message}}</uib-alert>
          <div class="row form-section-body">
            <div class="col-xs-12">
              How would you like to pay for the received leads
              <span ng-if="joinPod.targetUser">from {{joinPod.targetUser.fullName}}</span>?
            </div>
          </div>

          <div class="row form-section-body">
            <div class="col-xs-12">
              <reward-selector user="joinPod.user" has-monthly-subscription="true" on-change="joinPod.onRewardSelectionChange(formData, isFormValid)"></reward-selector>
            </div>
          </div>

        </div>
      </div>
      <!-- ENDOF: Content -->

    </div>
  </div>

  <div class="row modal-content-buttons">
    <div class="col-xs-12 text-right">
      <button type="button" class="btn btn-default" ng-disabled="joinPod.isLoading" ng-click="joinPod.cancel()">
        Cancel
      </button>
      <button type="button" class="btn btn-primary"
              ng-disabled="form.$invalid || !joinPod.isRewardsFormValid || joinPod.isLoading" ng-click="joinPod.join()">
        {{joinPod.isLoading ? 'Loading ...' : 'Send'}}
      </button>
    </div>
  </div>
</div>
