(function () {
  'use strict';

  angular
    .module('user.leads')
    .config(config);

  function config($stateProvider, configOptions) {
    $stateProvider
      .state('user.sentLeads', angular.extend({}, configOptions.defaultStateParams, {
        url: '/sent-leads',
        templateUrl: 'user/user.tpl.html',
        controller: 'UserCtrl',
        controllerAs: 'user',
        onEnter: ['RouterHelper', function (RouterHelper) {
          RouterHelper.activateTab('user', 'sentLeads');
        }]
      }))
      .state('user.receivedLeads', angular.extend({}, configOptions.defaultStateParams, {
        url: '/received-leads',
        templateUrl: 'user/user.tpl.html',
        controller: 'UserCtrl',
        controllerAs: 'user',
        onEnter: ['RouterHelper', function (RouterHelper) {
          RouterHelper.activateTab('user', 'receivedLeads');
        }]
      }));
  }
}());
