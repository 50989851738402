(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name components.factory:QueueManager
   *
   * @description
   *
   */
  angular
    .module('components')
    .factory('QueueManager', QueueManager);

  function QueueManager($timeout, QueueItem) {
    var QueueManagerBase = {};
    /**
     * List of items that needs to be executed
     * @type {QueueItem[]|Array}
     */
    QueueManagerBase.queue = [];

    /**
     * Add new item to the list
     * @param {QueueItem} item Queue Item
     */
    QueueManagerBase.add = function (item) {
      if (!(item instanceof QueueItem)) {
        throw new Error('Only instance of QueueItem is allowed');
      }

      this.queue.push(item);
    };

    /**
     * Resets queue
     */
    QueueManagerBase.reset = function () {
      this.queue = [];
    };

    /**
     * Start processing of items in queue
     */
    QueueManagerBase.start = function () {
      var that = this;

      if (this.queue.length === 0) {
        return;
      }

      function queueLoop(previousResult) {
        /**
         * Item to process
         * @type {QueueItem}
         */
        var item;
        if (that.queue.length === 0) {
          return;
        }

        item = that.queue.shift();
        item.callback(previousResult).then(function (result) {
          var delay = item.options.delay || 0;
          /**
           * Process next item with delay, pass result from current item
           */
          $timeout(function () {
            queueLoop(result);
          }, delay);
        });
      }

      queueLoop();
    };

    return QueueManagerBase;
  }
}());
