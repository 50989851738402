(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name mlpNotifications.directive:notificationSwipeItem
   * @restrict E
   * @element
   */
  angular
    .module('mlp-notifications')
    .directive('notificationSwipeItem', notificationSwipeItem);

  function notificationSwipeItem($swipe) {
    return {
      restrict: 'AE',
      scope: {
        onSwipeDone: '&',
        onSwiping: '&',
        onSwipeCancel: '&',
        onClick: '&'
      },
      link: function (scope, el, attrs) {
        var id = Number(attrs.notificationId),
            startX = 0,
            dx = 0,
            isMoving;

        function reset() {
          // Reset;
          startX = 0;
          dx = 0;
          isMoving = false;
        }

        function onStart(coords, event) {
          startX = coords.x;

          el.css({
            transition: 'none'
          });
          console.log('onStart:', id, isMoving, event.type);
          if (isMoving) {
            onCancel(event);
          }
          else {
            isMoving = true;
          }
        }

        function onMove(coords) {
          dx = coords.x - startX;
          isMoving = true;
          // alter the position now.
          el.css({
            transform: 'translateX(' + dx + 'px)'
          });
          scope.onSwiping({deltaX: dx});
        }

        function onEnd(coords, event) {
          var absDx = Math.abs(coords.x - startX),
              preventMouseUp = false;

          if (!isMoving) {
            event.stopImmediatePropagation();
            event.stopPropagation();
            event.preventDefault();
            return;
          }

          if (absDx >= 0 && absDx < 5) {
            // This is considered as accidental swipe. don't prevent the mouseup. Return the element slowly back
            el.css({
              transform: 'translateX(0px)',
              transition: 'transform 0.2s ease-in-out'
            });
            el.slideUp(200, function () {
              scope.onClick({id: id});
            });
          }
          else if (absDx > 0 && absDx < 50) {
            // This is considered as unfinished swipe. prevent the mouseup but don't clear the notification
            preventMouseUp = true;
            el.css({
              transform: 'translateX(0px)',
              transition: 'transform 0.2s ease-in-out'
            });
            scope.onSwipeCancel({id: id});
          }
          else {
            // This is finished swipe and the notification should be cleared. Prevent mouseup
            preventMouseUp = true;
            el.css({
              transform: 'translateX(' + (coords.x - startX < 0 ? '-1000' : '1000') + 'px)',
              transition: 'transform 0.3s ease-in-out'
            });
            el.slideUp(200);
            scope.onSwipeDone({id: id});
          }
          if (preventMouseUp) {
            event.stopImmediatePropagation();
            event.stopPropagation();
            event.preventDefault();
          }

          reset();
        }

        function onCancel(event) {
          // This is considered as unfinished swipe. prevent the mouseup but don't clear the notification
          event.stopImmediatePropagation();
          event.stopPropagation();
          event.preventDefault();

          el.css({
            transform: 'translateX(0px)',
            transition: 'transform 0.2s ease-in-out'
          });
          scope.onSwipeCancel({id: id});
          reset();
        }

        $swipe.bind(el, {
          start: onStart,
          move: onMove,
          end: onEnd,
          cancel: onCancel
        }, ['mouse', 'touch']);
      }
    };
  }
}());
