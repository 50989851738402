(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name api.service:Session
   *
   * @description
   *
   */
  angular
    .module('api')
    .service('Session', Session);

  function Session($rootScope, $q, Restangular) {
    var self = this;

    self.data = {
      profile: null
    };
    self.loaded = false;
    self.loading = false;
    self.deferred = null;

    /**
     * Returns user profile
     *
     * @returns {Object} User profile
     */
    self.getUserProfile = function () {
      return self.getSession()
        .then(function (session) {
          return session.data.profile;
        });
    };

    /**
     * Updates user's profile
     *
     * @param {Object} profileData New profile data
     * @returns {Deferred} Deferred promise
     */
    self.updateUserProfile = function (profileData) {
      var deferred = $q.defer();
      Restangular.one('Users').customPUT(profileData, 'profile')
        .then(function (profile) {
          self.create(profile.plain());
          $rootScope.$broadcast('event:user-updateProfile', {profile: self.data.profile});
          deferred.resolve(self.data.profile);
        }, function () {
          deferred.reject();
        });

      return deferred.promise;
    };

    /**
     * Update local user's profile with new params
     *
     * @param {Object} params Update parameters
     * @param {boolean} broadcast Whether this new get requests should broadcast the event of the local user profile update
     * @returns {Object} User's profile
     */
    self.updateLocalUserProfile = function (params, broadcast) {
      if (angular.isObject(params)) {
        self.data.profile = angular.extend(self.data.profile, params);
        if (broadcast) {
          $rootScope.$broadcast('event:user-updateLocalProfile', params);
        }
      }

      return self.data.profile;
    };

    /**
     * Populates session from the provided data
     *
     * @param {Object} profile User's profile
     */
    self.create = function (profile) {
      self.destroy();
      if (angular.isObject(profile) && angular.isDefined(profile.idUser)) {
        self.data.profile = profile;
        self.data.profile.fullName = profile.firstName + ' ' + profile.lastName;
      }
      self.loaded = true;
      self.loading = false;
      if (self.deferred) {
        self.deferred.resolve(self);
      }
    };

    /**
     * Destroys the session
     */
    self.destroy = function () {
      self.data = {
        profile: null
      };
    };

    /**
     * Loads the Session
     *
     * @returns {Session} Current Session
     */
    self.loadSession = function () {
      if (!self.isLoading()) {
        self.loading = true;
        self.deferred = $q.defer();
        return Restangular.one('Users').one('check').get()
          .then(function (result) {
            if (result.status === true && angular.isObject(result.profile)) {
              return result.profile;
            }

            return null;
          })
          .then(function (userProfile) {
            self.create(userProfile);

            return self;
          });
      }

      return self.deferred.promise;
    };

    /**
     * Returns the Session
     *
     * @returns {Session} Current Session
     */
    self.getSession = function () {
      if (!self.isLoaded()) {
        return self.loadSession();
      }

      return $q.when(self);
    };

    /**
     * Check if the profile is valid
     *
     * @param {Object} profile Profile data to validate
     * @returns {boolean} Flag if profile is valid
     */
    self.isValidProfile = function (profile) {
      return angular.isObject(profile) && !!profile.idUser;
    };

    /**
     * Returns whether the user is signed in
     *
     * @returns {boolean} True or False
     */
    self.isSignedIn = function () {
      return self.getSession()
        .then(function (session) {
          return self.isValidProfile(session.data.profile);
        });
    };

    /**
     * Returns whether the session is already loaded
     *
     * @returns {boolean} True or False
     */
    self.isLoaded = function () {
      return !!self.loaded;
    };

    /**
     * Returns whether the session is currently loading
     *
     * @returns {boolean} True or False
     */
    self.isLoading = function () {
      return !!self.loading;
    };

    /**
     * Removes user's photo
     * @returns {Deferred} Deferred promise
     */
    self.removePhoto = function () {
      var deferred = $q.defer();
      Restangular.one('Users').one('photo').remove()
        .then(function () {
          self.data.profile.imageToken = null;
          $rootScope.$broadcast('event:user-updateProfile', {profile: self.data.profile});
          deferred.resolve(self.data.profile);
        }, function () {
          deferred.reject();
        });

      return deferred.promise;
    };

    /**
     * Updates user's photo
     * @param {string} dataUri Uri of photo
     * @returns {Deferred} Deferred promise
     */
    self.updatePhoto = function (dataUri) {
      var deferred = $q.defer();
      Restangular.one('Users').customPUT({
        dataUri: dataUri
      }, 'photoBase64')
        .then(function (result) {
          self.data.profile.imageToken = result.imageToken;
          $rootScope.$broadcast('event:user-updateProfile', {profile: self.data.profile});
          deferred.resolve(self.data.profile);
        }, function () {
          deferred.reject();
        });

      return deferred.promise;
    };
  }
}());
