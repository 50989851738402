<div class="col-xs-12" ng-controller="MessagesCtrl as messages">
  <div class="messages page-no-content">


    <div class="messages-list hidden-xs">

      <!-- Pending overlay -->
      <div class="pending-overlay-white pending-overlay-column opacity-slow-if" ng-if="messages.isLoadingLeads">
        <span class="fa fa-circle-o-notch fa-spin-2x"></span>
        <div class="pending-overlay-description">Loading Leads</div>
      </div>

      <div style="height:100%; position: relative;" ng-include="'user/messages/messages-list-partial.tpl.html'"></div>
    </div>

    <div class="general-messages" id="messagesContent">
      <!--<messaging-form class="row" msg-recipient="messages.userProfile"></messaging-form>-->

      <div class="stepped-wrapper" content-slider>
        <div class="stepped-container">


          <div class="step-content messages-panel" data-step-id="0">
            <div class="panel panel-default">
              <div class="panel-heading">
                <div style="display: flex; justify-content: space-between; align-items: center;">
                  <h4>Direct Messages</h4>
                  <button style="position:relative;" title="Open Leads Messages"
                          class="btn-sm btn-primary visible-xs"
                          ng-click="messages.openModal()"
                          ng-disabled="messages.isLoadingLeads">
                    <div class="pending-overlay-white pending-overlay-column opacity-slow-if" ng-if="messages.isLoadingLeads">
                      <span class="fa fa-circle-o-notch fa-spin-2x" style="color: black;"></span>
                    </div>
                    <i class="fa fa-list"></i>
                  </button>
                </div>
              </div>
              <div class="panel-list">
                <scroll-navigator scroll-target-element-id="messages.targetMsgContainer"
                                  scroll-offset-y="-20"
                                  scroll-message="Scroll to type the message (or click)"
                                  scroll-target-change-delay="300"
                                  on-click-callback="messages.scrollTo"></scroll-navigator>
                <messaging-form msg-id="'direct-messages'"
                                msg-recipient="messages.userProfile"
                                msg-load-callback="messages.onDirectMessagesLoad"></messaging-form>
              </div>
            </div>
          </div>


          <div class="step-content messages-panel" data-step-id="1">
            <div class="panel panel-default">
              <div class="panel-heading" style="display: flex; justify-content: space-between; padding: 16px 20px;">
                <div class="visible-xs" style="display: flex; flex-direction: column; width: 100%; text-align: center;">
                  <div style="margin-bottom: 5px;">
                    Lead Messages
                  </div>
                  <div style="display: flex; justify-content: space-between;">
                    <button class="btn-sm btn-primary" ng-click="messages.switchToDirect()" title="To Direct Messages"><i class="fa fa-hashtag"></i></button>
                    <button class="btn-sm btn-default" ng-click="messages.openInfo = !messages.openInfo;" title="Open Lead Details">{{messages.openInfo ? 'Hide' : 'Show'}} details</button>
                    <button class="btn-sm btn-primary" ng-click="messages.openModal();" title="Open Leads Messages"><i class="fa fa-list"></i></button>
                  </div>
                </div>
                <div class="hidden-xs" style="display: flex; width: 100%; align-items: center; justify-content: space-between;">
                  <div style="display: flex; align-items: center;">
                    <h4>Lead Messages</h4>
                    <button title="Show Lead Details" class="btn-sm btn-default" style="margin-left: 10px;" ng-click="messages.openInfo = !messages.openInfo;">{{messages.openInfo ? 'Hide' : 'Show'}} details</button>
                  </div>
                </div>
              </div>
              <div class="panel-list" style="position: relative;">
                <div class="lead-info slide-if" style="z-index: 1; position: absolute; top: 0px; left: 0px; width: 100%; background: white; box-shadow: 0px 2px 5px -2px black;" ng-if="messages.openInfo">

                  <!-- LEAD DETAILS -->
                  <div class="row form-group">
                    <div class="col-xs-12">
                      <div class="tab-content-w-header">
                        <div class="lead-detail lead-detail-split lead-detail-row">
                          <div class="lead-detail-left" >
                            <div class="lead-detail-desc">
                              <!--<i class="fa fa-comment-o"></i>-->
                              <span>Details</span>
                            </div>
                            <div class="lead-detail-val">
                              {{messages.currentLead.details}}
                            </div>
                          </div>

                          <div class="lead-detail-right">
                            <div class="lead-detail-desc">
                              <!--<i class="fa fa-comment-o"></i>-->
                              <span>Contact</span>
                            </div>
                            <div class="lead-detail-val">
                              <div class="lead-detail-row">
                                <div class="lead-detail-desc">
                                  <i class="fa-lead fa fa-user"></i>
                                </div>
                                <div class="lead-detail-val">
                                  <div class="lead-detail-split-top">
                                    {{messages.currentLead.firstName}} {{messages.currentLead.lastName}}
                                  </div>
                                </div>
                              </div>

                              <div class="lead-detail-row" ng-if="messages.currentLead.phone">
                                <div class="lead-detail-desc">
                                  <i class="fa-lead fa fa-phone"></i>
                                </div>
                                <div class="lead-detail-val">
                                  <a href="tel:{{messages.currentLead.phone}}">{{messages.currentLead.phone}}</a>
                                </div>
                              </div>

                              <div class="lead-detail-row" ng-if="messages.currentLead.email">
                                <div class="lead-detail-desc">
                                  <i class="fa-lead fa fa-envelope-o"></i>
                                </div>
                                <div class="lead-detail-val">
                                  <a href="mailto:{{messages.currentLead.email}}">{{messages.currentLead.email}}</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row form-group">
                    <div class="col-xs-12 text-center">
                      <button class="btn-sm btn-primary" ng-click="messages.openDetails()">Open Lead</button>
                    </div>
                  </div>


                </div>
                <messaging-form
                  msg-id="'lead-messages'"
                  msg-request-params="{'idLead': messages.currentLead.id}"
                  msg-watch-params="true"
                  msg-recipient="messages.userProfile"
                  msg-load-callback="messages.onMessagesLoad"></messaging-form>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
