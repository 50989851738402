(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name socialShare.provider:GooglePlusSocialShare
   *
   * @description
   *
   */
  angular
    .module('socialShare.providers')
    .provider('GooglePlusSocialShare', GooglePlusSocialShare);

  function GooglePlusSocialShare() {
    var config = {
      popupHeight: 500,
      popupWidth: 500
    };

    function setConfig(newConfig) {
      if (angular.isObject(newConfig)) {
        angular.forEach(newConfig, function (value, paramName) {
          if (angular.isDefined(config[paramName])) {
            config[paramName] = value;
          }
          else {
            throw new Error('Invalid configuration option');
          }
        });
      }
    }

    return {
      $get: ['$q', '$window', function ($q, $window) {
        var $googlePlus = $q.defer();

        $googlePlus.init = function (newConfig) {
          setConfig(newConfig);
          $googlePlus.resolve(true);
        };

        $googlePlus.share = function (data) {
          $window.open(
            '//plus.google.com/share?' +
            'url=' + encodeURIComponent(data.url)
            , 'sharer', 'toolbar=0,status=0,width=' + config.popupWidth + ',height=' + config.popupHeight);
        };

        return $googlePlus;
      }]
    };
  }
}());
