<div class="panel panel-default">
	<div class="panel-heading">
		<h4>Received leads</h4>
	</div>
	<div class="panel-body">
	<chartist 
			id="received-leads-graph"
			chartist-chart-type="Line" 
			chartist-data="receivedLeadsGraph.chartData" 
			chartist-chart-options="receivedLeadsGraph.chartOptions"
			chartist-events="receivedLeadsGraph.chartEvents"
			chartist-responsive-options="receivedLeadsGraph.responsiveOptions">
		</chartist>
	</div>
</div>