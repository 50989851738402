(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.ui.directive:actionToolbar
   * @restrict A
   * @element
   *
   * @description
   *
   */
  angular
    .module('components.ui')
    .directive('actionToolbar', actionToolbar);

  function actionToolbar($window, $rootScope, $document, $timeout, Raven) {
    var toolbarEl,
        primaryContainer,
        secondaryContainer,
        angularRootScope,
        angularTimeout,
        scrollingElement,
        isPrimaryVisible = false,
        isSecondaryVisible = false,
        checkScrollFn,
        setVisibilityFn,
        checkVisibilityFn,
        checkVisibilityValsFn,
        lastScrollTop = 0,
        stateChanging = false,
        timeoutPromise,
        instances = {
          primary: {},
          secondary: {}
        };

    function getScrollingElement() {
      var d = $document[0];
      return d.documentElement.scrollHeight > d.body.scrollHeight && d.compatMode.indexOf('CSS1') === 0 ? d.documentElement : d.body;
    }

    //
    checkScrollFn = function () {
      var scrollTop,
          diff;

      // Get the document's scrolling element. Sometimes it's HTML and sometime body.
      scrollingElement = scrollingElement || $document[0].scrollingElement || getScrollingElement();

      if (!scrollingElement) {
        // Something is not right.
        Raven.captureMessage('actionToolbar: unable to get the scrolling element.', {
          level: 'warning'
        });
        return;
      }

      scrollTop = scrollingElement.scrollTop;
      diff = Math.abs(lastScrollTop - scrollTop);

      if (scrollTop < 0 || diff < 20) {
        return;
      }

      if (lastScrollTop > scrollTop) {
        setVisibilityFn(true, null, true);
      }
      else {
        setVisibilityFn(false, null, true);
      }

      lastScrollTop = scrollTop;
    };

    //
    checkVisibilityValsFn = function () {
      // If the state is changing then just forget it
      if (stateChanging) {
        return;
      }

      // And finally set the action toolbar's visibility if any of those are visible (primary / secondary)
      if (isPrimaryVisible || isSecondaryVisible) {
        setVisibilityFn(true, null, true);
      }
      else {
        setVisibilityFn(false, null, true);
      }
    };

    //
    setVisibilityFn = function (visible, container, broadcast) {
      var el = container || toolbarEl;
      if (visible) {
        el.addClass('visible');
        if (broadcast) {
          angularTimeout(function () {
            angularRootScope.$broadcast('event:action-toolbar-visible', el[0].clientHeight);
          }, 200);
        }
      }
      else {
        el.removeClass('visible');
        if (broadcast) {
          angularTimeout(function () {
            angularRootScope.$broadcast('event:action-toolbar-hidden');
          }, 200);
        }
      }
    };

    // Check if there is any elements at all.
    checkVisibilityFn = function (isSecondary) {
      var container = isSecondary ? secondaryContainer : primaryContainer,
          transcludedContainer,
          isVisible = false;

      // Check if the container has any nodes.
      // The structure of these has changed since implementing the scroll-check directive. we need to travarse few more nodes.
      transcludedContainer = angular.element('.transcluded-content', container[0]);
      if (container[0] && transcludedContainer[1] && transcludedContainer[1].childElementCount > 0) {
        isVisible = true;
      }

      if (isSecondary && (!isSecondaryVisible && isVisible || isSecondaryVisible && !isVisible)) {
        isSecondaryVisible = isVisible;
        setVisibilityFn(isVisible, container, false);
        checkVisibilityValsFn();
      }
      else if (!isSecondary && (!isPrimaryVisible && isVisible || isPrimaryVisible && !isVisible)) {
        isPrimaryVisible = isVisible;
        setVisibilityFn(isVisible, container, false);
        checkVisibilityValsFn();
      }
    };

    return {
      restrict: 'E',
      scope: true,
      transclude: true,
      replace: true,
      templateUrl: 'components/ui/action-toolbar-directive.tpl.html',
      link: function (scope, element, attrs) {
        var windowScrollHandle,
            stateChangingHandle,
            stateChangedHandle,
            watchHandle,
            transcludedContainer,
            isSecondary = attrs.atTarget && attrs.atTarget === 'secondary',
            id = (new Date()).getTime();

        scope.id = id;

        // Main Element. First instance ever.
        if (!toolbarEl) {
          toolbarEl = angular.element('<div id="action-toolbar" class="action-toolbar-main"></div>');
          $document[0].body.appendChild(toolbarEl[0]);
          // Append to toolbar element
          toolbarEl.append(element);

          // Get the primary and secondary container
          primaryContainer = angular.element('.primary-container', toolbarEl);
          secondaryContainer = angular.element('.secondary-container', toolbarEl);

          // Some references for angular functions
          angularRootScope = $rootScope;
          angularTimeout = $timeout;
        }

        if (!isPrimaryVisible && !isSecondaryVisible) {
          // Subscribe to the scroll event. just once is enough as this widget will never be actually destroyed
          windowScrollHandle = angular.element($window.document).on('scroll', checkScrollFn);

          // Let's try this
          watchHandle = $rootScope.$watch(function () {
            if (timeoutPromise) {
              $window.clearTimeout(timeoutPromise);
            }
            timeoutPromise = $window.setTimeout(function () {
              checkVisibilityFn(true);
              checkVisibilityFn(false);
            }, 150);
          });

          // Subsribe to our state changing events
          stateChangingHandle = $rootScope.$on('event:app-state-changing', function () {
            stateChanging = true;
            setVisibilityFn(false);
          });
          stateChangedHandle = $rootScope.$on('event:app-state-changed', function () {
            stateChanging = false;
            checkVisibilityFn(isSecondary);
          });
        }

        // Get the element references.
        transcludedContainer = angular.element('#at-transcluded', element);

        // Check the attrs for the target and extract and reposition the data and update the instances content
        if (isSecondary) {
          secondaryContainer[0].innerHTML = '';
          secondaryContainer.append(transcludedContainer);
          instances.secondary[id] = transcludedContainer;
        }
        else {
          primaryContainer[0].innerHTML = '';
          primaryContainer.append(transcludedContainer);
          instances.primary[id] = transcludedContainer;
        }
        checkVisibilityFn(isSecondary);

        scope.$on('$destroy', function () {
          // Ok we have to recognize what type of target has just been destroyed. (primary, secondary..?)
          if (isSecondary) {
            instances.secondary[id].remove();
          }
          else {
            instances.primary[id].remove();
          }
          checkVisibilityFn(isSecondary);

          if (!isPrimaryVisible && !isSecondaryVisible) {
            if (angular.isFunction(watchHandle)) {
              watchHandle();
            }
            if (angular.isFunction(windowScrollHandle)) {
              windowScrollHandle();
            }
            if (angular.isFunction(stateChangingHandle)) {
              stateChangingHandle();
            }
            if (angular.isFunction(stateChangedHandle)) {
              stateChangedHandle();
            }
          }
        });
      }
    };
  }
}());
