(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name myMemberships.service:MyMemberships
   *
   * @description
   *
   */
  angular
    .module('myMemberships')
    .service('MyMemberships', MyMemberships);

  function MyMemberships($document, $uibModal) {
    var self = this;

    self.openAskForLeads = function (myProfile, userProfile) {
      var slModalInstance = $uibModal.open({
        animation: false,
        appendTo: angular.element($document[0].body),
        templateUrl: 'my-memberships/join-pod/join-pod-modal.tpl.html',
        controller: 'JoinPodModalCtrl',
        controllerAs: 'joinPod',
        size: 'md',
        resolve: {
          myProfile: function () {
            return myProfile;
          },
          userProfile: function () {
            return userProfile;
          }
        }
      });

      return slModalInstance.result;
    };
  }
}());
