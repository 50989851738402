<div class="col-xs-12" ng-controller="DashboardMembershipsCtrl as memberships">
	<h2 class="hidden-xs">My Business</h2>

	<div class="page-content" ng-show="!memberships.list.records.length">
		<div class="row">
      <div class="col-xs-12">
        <div class="page-empty-section text-center">
          <i class="fa fa-users fa-2x text-light-colour"></i>
          <div class="page-empty-section-text">
            You have not received any leads
          </div>
          <br/>
          Join pods and start receiving leads. Click "Ask for Leads" to grow your network.
          <div class="page-empty-section-button">
            <a class="btn btn-primary" ui-sref="myMembershipsJoinPod">Ask for Leads</a>
          </div>
        </div>
      </div>
    </div>
  </div>

	<div class="row" ng-show="memberships.list.records.length">
		<div class="col-md-5 col-md-push-7">
			<received-leads-list list="memberships.list"></received-leads-list>
		</div>
		<div class="col-md-7 col-md-pull-5 hidden-xs">
			<received-leads-graph list="memberships.dailyStats"></received-leads-graph>
		</div>
	</div>

</div>

