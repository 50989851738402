<div class="col-xs-12" ng-controller="UserMarketplaceCtrl as userMarketplace">
  <div class="page-content form-group">
    <marketplace-listings items="userMarketplace.items" user="userMarketplace.userProfile" has-user-avatar="false"></marketplace-listings>
  </div>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 text-center">
      <span class="sub-title-description" style="margin-left: 12px;">
        <a ng-click="userMarketplace.openMarketplace()" class="cursor-pointer">Open Marketplace</a> for all available subscription packages.
      </span>
    </div>
  </div>
</div>
