(function () {
  'use strict';

  /* @ngdoc object
   * @name components.ui.tabs
   * @description
   *
   */
  angular
    .module('components.ui.tabs', []);
}());
