(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name configuration.constant:configOptions
   *
   * @description
   *
   */
  angular
    .module('configuration')
    .constant('configOptions', {
      appVersion: '%APP_VERSION%',
      appUrl: '%APP_URL%',
      baseUrl: '%APP_BASE_URL%',
      facebookAppId: '%FACEBOOK_APP_ID%',
      facebookScope: 'email,public_profile',
      linkedInClientId: '%LINKEDIN_CLIENT_ID%',
      linkedInProxy: '%APP_BASE_URL%/Users/linkedinOAuthProxy?X-Ff3-Api-Key=%API_KEY%',
      googlePlusClientId: '%GOOGLE_PLUS_CLIENT_ID%',
      googleMapApiKey: '%GOOGLE_MAP_API_KEY%',
      googleMapLibraries: 'places',
      passwordPattern: '%PASSWORD_PATTERN%',
      passwordMessage: '%PASSWORD_MESSAGE%',
      apiKey: '%API_KEY%',
      oneSignalAppId: '%ONE_SIGNAL_APP_ID%',
      oneSignalSafariWebId: '%ONE_SIGNAL_SAFARI_WEB_ID%',
      linkedInScope: 'r_liteprofile,r_emailaddress',
      googlePlusScope: 'https://www.googleapis.com/auth/userinfo.email,' +
      'https://www.googleapis.com/auth/userinfo.profile',
      maxFileUploadSize: 10485760,
      maxFileUploadMessage: 'Max allowed size of file is 10M',
      reCaptchaSiteKey: '%RECAPTCHA_SITE_KEY%',
      defaultStateParams: {
        params: {nextSteps: []},
        authenticate: true
      },
      bitlyGenericAccessToken: '%BITLY_GENERIC_ACCESS_TOKEN%',
      defaultShareName: 'Send leads. Be rewarded.',
      defaultShareMessage: 'MyLeadPod is a professional reward platform for individuals and businesses sending and receiving leads.',
      defaultEmailMessage: 'We are using MyLeadPod to reward successful leads provided by business associates, ' +
                           'friends and family. Click the link below to take the simple steps (a minute or two) to ' +
                           'join our cash rewards program.\r\r\n Visit \r\n {{link}} \r\r\n' +
                           'If you run a business yourself, check out MyLeadPod as your very own simple-to-use cash ' +
                           'reward program to incentivise word-of-mouth referrals. \r\r\n We look forward to rewarding you in cash!',
      maxBusinessSuggestions: 1,
      reportRegistration: function () {
        // %GOOGLE_TAG_MANAGER_CONVERSION_SNIPPET%
      }
    });
}());
