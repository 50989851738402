<div class="panel panel-default">
  <div class="panel-heading">
    <h4>People in my Pod</h4>
  </div>
  <div class="panel-body">
		<div class="panel-list">
		  <table class="table">
		    <tbody>
			    <tr ng-repeat="row in podMembersList.list.records">
			      <td class="col-xs-12">
			        <table class="table-cell-main-content">
			          <tr>
			            <td>
                    <table-user-details user="row.member" />
                  </td>
		            </tr>
		          </table>
			      </td>
			    </tr>
		    </tbody>
	    </table>
		</div>
		<p>
			<a ui-sref="myPod.members" class="btn btn-default btn-block" ng-if="podMembersList.list.recordsInfo.totalRecords > 4">All people</a>
		</p>
	</div>
</div>
