(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name account.controller:BusinessAccountCtrl
   *
   * @description
   *
   */
  angular
    .module('account')
    .controller('BusinessAccountCtrl', BusinessAccountCtrl);

  function BusinessAccountCtrl($window, $scope, $timeout, $uibModalInstance, $state, PromptDialog, BusinessAccount, FormAlerts, userProfile,
                               pricingDetails, Industries, RewardCodes, Location, GoogleMap, Restangular, addressFilter, isTrial) {
    var vm = this;
    vm.alerts = new FormAlerts();
    vm.isLoading = false;
    vm.hasRewardCode = false;
    vm.rewardCodeChecked = false;
    vm.rewardCodeValid = false;
    vm.rewardCodeValidating = false;
    vm.rewardCodeHelp1 = 'Create your first Reward Code to offer to your referral network (one word, alpha numeric). With this step your default Reward Program will be created and used for this Reward Code';
    vm.rewardCodeHelp2 = 'Examples: MYCOMPANYNAME123, CODE123, SUMMERPROMOTION, ...\n\nPlease choose between 5 to 20 characters.';
    vm.getCountries = Location.getCountries;
    vm.pricingDetails = pricingDetails.pricingOptions;
    vm.getStates = function () {
      return Location.getStates(vm.data.addressPostal.idCountryPostal);
    };
    vm.showRewardCard = true;
    vm.data = {
      businessName: userProfile.businessName,
      idClassification: null,
      businessMarketingPayment: vm.pricingDetails && vm.pricingDetails[0] ? vm.pricingDetails[0].id : -1,
      creditCard: {
        method: null
      },
      address: null,
      addressPostal: {
        address: null,
        numberPostal: null,
        addressPostal: userProfile.addressPostal.addressPostal,
        suburbPostal: userProfile.addressPostal.suburbPostal,
        postCodePostal: userProfile.addressPostal.postCodePostal,
        idStatePostal: angular.isDefined(userProfile.addressPostal.idStatePostal) && userProfile.addressPostal.idStatePostal !== null ? userProfile.addressPostal.idStatePostal.toString() : '',
        idCountryPostal: angular.isDefined(userProfile.addressPostal.idCountryPostal) && userProfile.addressPostal.idCountryPostal !== null ? userProfile.addressPostal.idCountryPostal.toString() : '',
        statePostal: userProfile.addressPostal.idCountryPostal && userProfile.addressPostal.idStatePostal ? Location.getStateNameById(userProfile.addressPostal.idCountryPostal, userProfile.addressPostal.idStatePostal) : '',
        statePostalShort: userProfile.addressPostal.idCountryPostal && userProfile.addressPostal.idStatePostal ? Location.getStateNameById(userProfile.addressPostal.idCountryPostal, userProfile.addressPostal.idStatePostal, true) : '',
        countryPostal: userProfile.addressPostal.idCountryPostal ? Location.getCountryNameById(userProfile.addressPostal.idCountryPostal) : ''
      }
    };

    vm.paymentSummary = {
      price: null,
      tax: null,
      total: null
    };

    Restangular.one('Billing').one('creditCard').get()
      .then(function (cc) {
        cc = cc.plain();
        vm.data.creditCard.method = angular.isObject(cc) && angular.isDefined(cc.number) ? 'old' : 'new';
        vm.creditCard = cc;
        return cc;
      }, function () {
        return null;
      });

    vm.formatAddress = function () {
      vm.data.addressPostal.address = addressFilter(vm.data.addressPostal);
    };

    // Get the industry
    if (angular.isObject(userProfile.subIndustry)) {
      vm.data.idClassification = userProfile.subIndustry.id.toString();
      vm.selectedClassification = {id: userProfile.subIndustry.id, name: userProfile.industry.name + ' - ' + userProfile.subIndustry.name};
    }
    else if (angular.isObject(userProfile.industry)) {
      vm.data.idClassification = userProfile.industry.id.toString();
      vm.selectedClassification = {id: userProfile.industry.id, name: userProfile.industry.name};
    }

    // Check if the user already has a reward code so we can skip the reward code step
    if (userProfile && userProfile.rewardCodes && _.where(userProfile.rewardCodes, {isArchived: false}).length > 0) {
      vm.hasRewardCode = true;
    }

    // Get the trial data for the user. We only need the expiry date
    if (userProfile) {
      vm.trialData = BusinessAccount.getTrialData(userProfile);
    }

    Industries.isReady.then(function () {
      vm.industries = Industries.getConsolidatedList();
    });

    vm.onClassificationSelect = function (classification) {
      if (angular.isObject(classification) && angular.isObject(classification.originalObject)) {
        vm.data.idClassification = classification.originalObject.id;
      }
    };

    vm.onRewardCodeChange = function () {
      vm.rewardCodeChecked = false;
    };

    vm.onCountryChange = function (form) {
      vm.data.addressPostal.idStatePostal = '';
      if (form && form.idStatePostal) {
        delete form.idStatePostal.$touched;
        delete form.idStatePostal.$error.required;
      }
    };

    vm.validateRewardCode = function () {
      var code;
      if (vm.data.rewardCode && vm.data.rewardCode.length >= 5 && vm.data.rewardCode.length <= 20) {
        code = vm.data.rewardCode.toUpperCase().replace(/\s+/g, '-');
        vm.data.rewardCode = code.replace(/[-]+/g, '-');
        vm.onDataChange('code');
        vm.rewardCodeValidating = true;
        RewardCodes.isRewardCodeUnique(vm.data.rewardCode)
          .then(function (isUnique) {
            vm.rewardCodeValid = isUnique;
            vm.rewardCodeValidating = false;
            vm.rewardCodeChecked = true;
          });
      }
    };

    vm.isLeadPriceValid = function () {
      return !isNaN(vm.data.successfulLeadPrice) && vm.data.successfulLeadPrice >= 0;
    };

    vm.cancel = function () {
      PromptDialog.open('Are you sure you\'d like to cancel the activation?<br/><span class="prompt-subtext">You can access this activation window again from Profile Menu -> Business Account</span>', null, 'Cancel Activation', 'Go Back')
        .then(function () {
          $uibModalInstance.dismiss();
        });
    };

    vm.onDataChange = function (type) {
      var el,
          value;
      try {
        if (type === 'name') {
          el = angular.element('#RewardCard-front')[0].contentDocument.getElementById('RewardCard-CompanyName');
          value = vm.data.businessName;
          if (value.length > 20) {
            value = value.substr(0, 20) + '..';
          }
        }
        if (type === 'code') {
          el = angular.element('#RewardCard-back')[0].contentDocument.getElementById('RewardCard-Code');
          value = vm.data.rewardCode || '';
          if (value.length > 15) {
            el.style.fontSize = '18px';
          }
          else {
            el.style.fontSize = '20px';
          }
        }
        if (type === 'value') {
          el = angular.element('#RewardCard-front')[0].contentDocument.getElementById('RewardCard-RewardValue').firstElementChild;
          value = vm.data.successfulLeadPrice || 0;
          if (Number(value) > 999) {
            el.style.fontSize = '24px';
          }
          else {
            el.style.fontSize = '36px';
          }
        }

        if (el) {
          el.innerHTML = value;
        }
        vm.showRewardCard = true;
      }
      catch (ex) {
        console.log(ex);
        vm.showRewardCard = false;
      }
    };

    vm.isAddressIncomplete = function () {
      return !vm.data.addressPostal.addressPostal ||
      !vm.data.addressPostal.suburbPostal ||
      !vm.data.addressPostal.postCodePostal ||
      !vm.data.addressPostal.idStatePostal ||
      !vm.data.addressPostal.idCountryPostal;
    };

    vm.getUserLocationAddress = function () {
      // From geolocation
      vm.isGeolocating = true;
      GoogleMap.promise.then(function () {
        GoogleMap.getCurrentLocation(true).then(function (result) {
          // result.location
          vm.data.addressPostal.address = result.location;
          vm.data.addressPostal.numberPostal = result.streetNumber;
          vm.data.addressPostal.addressPostal = result.streetAddress;
          vm.data.addressPostal.suburbPostal = result.suburb;
          vm.data.addressPostal.postCodePostal = result.postCode;
          vm.data.addressPostal.statePostalShort = result.state;
          vm.data.addressPostal.countryPostal = result.country;
          vm.updateStateCountryIds(result);
          vm.formatAddress();
          vm.isGeolocating = false;
          // vm.getLocationScreenshot();
        }, vm.geolocationFailCallback);
      }, vm.geolocationFailCallback);
    };

    vm.geolocationFailCallback = function () {
      // There is some issue with the geolocation. disable the button.
      vm.isGeolocationDisabled = true;
      vm.isGeolocating = false;
      $timeout(function () {
        $scope.$apply();
      }, 0);
    };

    vm.placeChangedCallback = function (data) {
      // From google maps geocoding (autocomplete)
      vm.data.addressPostal.address = data.location;
      vm.data.addressPostal.numberPostal = data.streetNumber;
      vm.data.addressPostal.addressPostal = data.streetAddress;
      vm.data.addressPostal.suburbPostal = data.suburb;
      vm.data.addressPostal.postCodePostal = data.postCode;
      vm.data.addressPostal.statePostalShort = data.state;
      vm.data.addressPostal.countryPostal = data.country;
      vm.updateStateCountryIds(data);
      vm.formatAddress();
      $scope.$apply();
      // vm.getLocationScreenshot();
    };

    vm.updateStateCountryIds = function (result) {
      vm.data.addressPostal.idCountryPostal = Location.getCountryId(result.country_id);
      vm.data.addressPostal.idStatePostal = Location.getStateId(vm.data.addressPostal.idCountryPostal, result.state);
      vm.data.addressPostal.idCountryPostal = vm.data.addressPostal.idCountryPostal ? vm.data.addressPostal.idCountryPostal.toString() : null;
      vm.data.addressPostal.idStatePostal = vm.data.addressPostal.idStatePostal ? vm.data.addressPostal.idStatePostal.toString() : null;
    };

    vm.getLocationScreenshot = function () {
      var el;
      vm.isRenderingLocation = true;
      try {
        el = angular.element('.location-screenshot');
        console.log(el.height(), el.width());
        GoogleMap.getStaticMapAsBase64(vm.data.addressPostal.address, {
          w: el.width(),
          h: el.height()
        }).then(function (base64) {
          angular.element('img', el)[0].src = base64;
          vm.isRenderingLocation = false;
        });
      }
      catch (ex) {
        vm.isRenderingLocation = false;
      }
    };

    vm.updateSummary = function () {
      // Get the active pricing option
      var p = _.find(vm.pricingDetails, {id: vm.data.businessMarketingPayment});
      if (p) {
        vm.paymentSummary.price = p.price;
        vm.paymentSummary.total = p.priceInclGst;
        vm.paymentSummary.tax = vm.paymentSummary.total - vm.paymentSummary.price;
      }
    };

    vm.save = function () {
      vm.alerts.reset();
      vm.isLoading = true;

      if (isTrial) {
        BusinessAccount.activateTrial(vm.data).then(function (profile) {
          $uibModalInstance.close(profile);
          $state.go('myMembershipsRewardProgramsSetup', {openInvite: true});
        }, function (error) {
          vm.isLoading = false;
          if (error.errorCode === 701 || error.errorCode === 702 || error.errorCode === 703) {
            vm.alerts.add('danger', 'Unable to activate the trial: ' + error.errorMessage);
          }
          else {
            vm.alerts.add('danger', 'Unable to activate the trial, try again later please');
          }
        });
      }
      else {
        BusinessAccount.activate(vm.data).then(function (profile) {
          $uibModalInstance.close(profile);
          // Navigate to Rewards Program Page
          $state.go('myMembershipsRewardProgramsSetup', {openInvite: true});
        }, function (error) {
          vm.isLoading = false;
          if (error.errorCode === 701 || error.errorCode === 702 || error.errorCode === 703) {
            vm.alerts.add('danger', 'Unable to activate: ' + error.errorMessage);
          }
          else {
            vm.alerts.add('danger', 'Unable to activate, try again later please');
          }
        });
      }
    };

    vm.formatAddress();
    vm.isMobile = $window.innerWidth < 768;
    angular.element($window).on('resize', function () {
      vm.isMobile = $window.innerWidth < 768;
      $scope.$apply();
    });
  }
}());
