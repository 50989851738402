(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.ui.tabs.directive:tabAdditionalContent
   * @restrict EA
   * @element
   */
  angular
    .module('components.ui.tabs')
    .directive('tabAdditionalContent', tabAdditionalContent);

  function tabAdditionalContent($compile) {
    return {
      restrict: 'EA',
      template: '<span></span>',
      replace: true,
      scope: {
        rawHtml: '=html'
      },
      link: function (scope, elem) {
        scope.$watch('rawHtml', function (value) {
          var newElem;
          if (!value || !angular.isString(value)) {
            return;
          }
          // we want to use the scope OUTSIDE of this directive
          // (which itself is an isolate scope).
          newElem = $compile(value)(scope.$parent);
          elem.contents().remove();
          elem.append(newElem);
        });
      }
    };
  }
}());
