(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name myMemberships.rewardProgram.controller:RewardCodeEmbedCtrl
   *
   * @description
   *
   */
  angular
    .module('myMemberships.rewardProgram')
    .controller('RewardCodeEmbedCtrl', RewardCodeEmbedCtrl);

  function RewardCodeEmbedCtrl($sce, $uibModalInstance, RewardCodes, rewardCode) {
    var vm = this;
    vm.embedCode = $sce.trustAsHtml(RewardCodes.getRegistrationFormCode(rewardCode.rewardCode));
    vm.previewVisible = false;

    vm.close = function () {
      $uibModalInstance.close();
    };
  }
}());
