(function () {
  'use strict';
  /* @ngdoc object
   * @name socialShare.providers
   * @description
   *
   */
  angular
    .module('socialShare.providers', [
      'angularLoad'
    ]);
}());
