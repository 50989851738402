(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name lead.controller:LeadDetailCtrl
   *
   * @description
   *
   */
  angular
    .module('lead')
    .controller('LeadDetailCtrl', LeadDetailCtrl);

  function LeadDetailCtrl($rootScope, $scope, $uibModalInstance, $state, lead, leadType, userProfile, tabToOpen,
                          Leads, Notification, FormAlerts, RewardCodes, Restangular, notificationConst) {
    var vm = this;
    vm.lead = lead;
    vm.leadType = leadType;
    vm.tabName = 'details';
    vm.isSending = false;
    vm.isAcknowledging = false;
    vm.alerts = new FormAlerts();
    vm.closeData = {};
    vm.isSavingUserShare = false;
    vm.userShareEmailChecked = false;
    vm.userShareEmailValid = false;
    vm.userShareEmailValidating = false;
    vm.userShareEmail = null;
    vm.userShareSectionVisible = false;
    vm.userShareEmailHelp = 'Type in the email address of another MyLeadPod user you want to share the lead with.';
    if (angular.isDefined(lead.coOwner) && angular.isObject(lead.coOwner)) {
      vm.userShareEmail = lead.coOwner.email;
      vm.userShareEmailValid = true;
      vm.userShareEmailChecked = true;
    }
    vm.searchCategories = [{
      name: 'Directory',
      value: 'businessUsers',
      stateTarget: 'user',
      iconClass: 'fa-briefcase'
    }];

    // Check tab name
    if (angular.isDefined(tabToOpen)) {
      vm.tabName = tabToOpen.lastIndexOf('detail') > -1 ? 'details' : tabToOpen;
    }

    vm.tabManager = null;
    vm.tabsConfig = [
      {
        label: 'Details',
        value: 'details'
      },
      {
        label: 'Messages',
        value: 'messages'
      }
    ];

    // Listen to the broadcast of 'close-modal-instance'
    $scope.$on('close-modal-instance', function () {
      $uibModalInstance.dismiss();
    });

    vm.close = function () {
      $uibModalInstance.close(vm.closeData);
    };

    vm.getUser = function () {
      if (leadType === 'sent') {
        return lead.recipient;
      }

      return lead.sender;
    };

    vm.showResendButton = function () {
      return leadType === 'sent' && lead.sender.id === userProfile.id && (lead.status === 'open' || lead.status === 'new');
    };

    vm.resend = function () {
      vm.isSending = true;
      vm.alerts.reset();
      Leads.resend(lead.id)
        .then(function () {
          vm.isSending = false;
          Notification({message: 'Success'}, 'confirmation');
        }, function () {
          vm.isSending = false;
          vm.alerts.add('danger', 'An error occurred, try again later please');
        });
    };

    vm.showAcknowledgeButton = function () {
      return leadType === 'received' && lead && lead.recipient && lead.recipient.id === userProfile.id && lead.status === 'new';
    };

    vm.acknowledge = function () {
      vm.isAcknowledging = true;
      vm.alerts.reset();
      Leads.acknowledge(lead.id)
        .then(function (result) {
          vm.lead = result;
          vm.isAcknowledging = false;
          lead = result.plain();
          vm.closeData.lead = lead;
          Notification({message: 'Success'}, 'confirmation');
        }, function () {
          vm.isAcknowledging = false;
          vm.alerts.add('danger', 'An error occurred, try again later please');
        });
    };

    vm.saveUserShare = function () {
      vm.isSavingUserShare = true;
      vm.editUserShareError = false;
      Leads.update(lead.id, {
        userShareEmail: vm.userShareEmail
      })
        .then(function (result) {
          vm.lead = result;
          lead = result;
          vm.closeData.lead = lead;
          vm.editUserShareVisible = false;
          vm.isSavingUserShare = false;
          vm.shareWithProfile = null;
        }, function () {
          vm.isSavingUserShare = false;
          vm.editUserShareError = 'An error occurred';
        });
    };

    vm.removeSharing = function () {
      vm.isRemovingShare = true;
      vm.userShareEmail = null;
      Leads.update(lead.id, {
        userShareEmail: vm.userShareEmail
      })
        .then(function (result) {
          vm.lead = result;
          lead = result;
          vm.closeData.lead = lead;
          vm.editUserShareVisible = false;
          vm.isSavingUserShare = false;
          vm.isRemovingShare = false;
        }, function () {
          vm.isRemovingShare = false;
          vm.isSavingUserShare = false;
          vm.editUserShareError = 'An error occurred';
        });
    };

    vm.onUserShareEmailChange = function () {
      vm.userShareEmailChecked = false;
      vm.userShareEmailValid = false;
      vm.userShareEmailValidating = false;
    };

    vm.validateUserShareEmail = function () {
      if (vm.userShareEmailValidating) {
        // This user email check fn can be triggered by blur and by the 'check' click. To avoid double requests return from here.
        return;
      }
      if (vm.userShareEmail && vm.userShareEmail.length >= 0) {
        vm.userShareEmailValidating = true;
        RewardCodes.isUserShareEmailValid(vm.userShareEmail)
          .then(function (isValid) {
            vm.userShareEmailValid = isValid;
            vm.userShareEmailValidating = false;
            vm.userShareEmailChecked = true;
          });
      }
    };

    vm.isUserShareEmailValid = function () {
      if (vm.userShareEmail !== null && vm.userShareEmail.length === 0) {
        return true;
      }

      return vm.userShareEmailValid;
    };

    vm.forward = function () {
      $uibModalInstance.dismiss();
      $state.go('leadSend', {
        lead: lead
      });
    };

    vm.closeLead = function (status) {
      Leads.closeLead(lead, status)
        .then(function () {
          lead.status = status;
          vm.lead.status = status;
        });
    };

    vm.getUserDetailClass = function () {
      if (vm.tabManager) {
        return vm.tabManager.isOpen('messages') ? 'table-user-detail-opaque' : '';
      }
      return '';
    };

    vm.searchResponseFormatter = function (response) {
      var r,
          records;

      vm.userInvitedProfile = null;

      if (angular.isUndefined(response) || !response) {
        return null;
      }
      vm.emptyResults = false;
      r = response.records;
      if (r.length === 0) {
        vm.emptyResults = true;
      }

      records = _.map(r, function (item) {
        var category = _.find(vm.searchCategories, {value: item.datasource}),
            returnObject = {},
            categoryDescriptionClass,
            value = item.fullname;

        categoryDescriptionClass = category.iconClass || 'fa-user';
        if (item.companyname && item.companyname !== item.fullname) {
          value += ' | ' + item.companyname;
        }

        returnObject = {
          value: value,
          id: item.id,
          category: item.datasource,
          categoryDescription: '<i class="fa ' + categoryDescriptionClass + '"></i>',
          timeDescription: item.datecreatedformatted ? item.datecreatedformatted : '',
          leadtype: item.leadtype
        };

        return returnObject;
      });

      return records;
    };

    vm.onSearchItemSelected = function (item) {
      // Callback on when the user selected the search item.
      // Pull the stateTarget from category
      if (!item) {
        return;
      }
      vm.displayContactDetails = true;
      vm.isLoadingUserData = true;
      Restangular.one('Users').one('profile').get({id: item.originalObject.id})
        .then(function (profile) {
          profile = profile.plain();
          vm.userShareEmail = profile.email;
          vm.shareWithProfile = profile;
          vm.isLoadingUserData = false;
        });
    };

    // Broadcast to NotificationCentre that it can clear notifications related to this lead.
    // Broadcast to the NotificationCentre to check any notifications related to messages for this user.
    vm.onTabChange = function (activeTab) {
      var obj;
      if (activeTab === 'details') {
        obj = {
          userId: vm.getUser().idUser,
          category: notificationConst.categoryLeads,
          type: notificationConst.typeNewLead,
          data: {
            leadId: lead.id
          }
        };
      }
      else {
        obj = {
          userId: vm.getUser().idUser,
          category: notificationConst.categoryMessages,
          type: notificationConst.typeLeadMessage,
          data: {
            leadId: lead.id
          }
        };
      }
      $rootScope.$broadcast('event:notifications-check', obj);
    };
  }
}());
