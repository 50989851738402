<div class="row">
  <div class="col-xs-12">
    <h1 class="page-header">Send Reward</h1>
  </div>
</div>

<div class="row">
  <div class="col-xs-12">
    <div class="page-content page-content-no-tabs">
      <uib-alert ng-repeat="alert in sendReward.alerts.getList()" type="{{alert.type}}" close="sendReward.alerts.remove($index)">{{alert.message}}</uib-alert>
      <div ng-form="sendRewardForm" class="send-reward-form">
        <div class="row">
          <div class="col-xs-12 col-sm-6">
            <span class="sub-title">Add reward</span>
          </div>
        </div>

        <div class="row form-section-body">
          <div class="col-xs-12 col-sm-6">
            <div class="form-group" ng-class="{'has-error': (sendRewardForm.$submitted || sendRewardForm.reward.$touched) &&
            (sendRewardForm.reward.$error.required || sendRewardForm.reward.$error.number || sendRewardForm.reward.$error.min)}">
              <label class="sr-only" for="reward">Reward value</label>
              <div ng-show="sendRewardForm.$submitted || sendRewardForm.reward.$touched">
                <div class="input-error" ng-show="sendRewardForm.reward.$error.number">Invalid reward value
                </div>
                <div class="input-error" ng-show="sendRewardForm.reward.$error.required">Missing reward value
                </div>
                <div class="input-error" ng-show="sendRewardForm.reward.$error.min">Reward value must not be negative
                </div>
              </div>
              <div class="left-inner-addon input-group">
                <i class="fa fa-dollar inner-addon"></i>
                <input class="form-control input-lg" type="number" name="reward" id="reward"
                       placeholder="Reward value" ng-change="sendReward.onRewardChange()"
                       ng-blur="sendReward.calculateTotal()"
                       ng-required="true" ng-model="sendReward.data.reward" min="0"/>
                <span class="input-group-btn">
                  <button class="btn btn-primary btn-md" type="button" ng-click="sendReward.calculateTotal()"
                          ng-disabled="!sendReward.data.reward">Enter</button>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="row" ng-if="sendReward.showTotal">
          <div class="col-xs-6 col-sm-3">
            Reward amount
          </div>
          <div class="col-xs-6 col-sm-3 text-right">
            {{sendReward.data.reward | currency:"$":2}}
          </div>
        </div>
        <div class="row" ng-if="sendReward.showTotal && !sendReward.calculating && !sendReward.calculationRequired">
          <div class="col-xs-6 col-sm-3">
            Processing fee
          </div>
          <div class="col-xs-6 col-sm-3 text-right">
            {{sendReward.processingFee | currency:"$":2}}
          </div>
        </div>
        <div class="row" ng-if="sendReward.showTotal && !sendReward.calculating && !sendReward.calculationRequired">
          <div class="col-xs-6 col-sm-3">
            GST
          </div>
          <div class="col-xs-6 col-sm-3 text-right">
            {{sendReward.tax | currency:"$":2}}
          </div>
        </div>
        <div class="row" ng-if="sendReward.showTotal && !sendReward.calculating && !sendReward.calculationRequired">
          <div class="col-xs-6 col-sm-3">
            <strong>Total charged</strong>
          </div>
          <div class="col-xs-6 col-sm-3 text-right">
            <strong>{{sendReward.total | currency:"$":2}}</strong>
          </div>
        </div>

        <div class="row" ng-if="sendReward.calculating">
          <div class="col-xs-12 col-sm-6 text-center">
            <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>
          </div>
        </div>

        <div class="row form-section-title">
          <div class="col-xs-12 col-sm-6">
            <span class="sub-title">Payment details</span>
          </div>
        </div>

        <div class="row form-section-body">
          <div class="col-xs-12 col-sm-6">
            <credit-card cc-data="sendReward.data.paymentDetails" credit-card="sendReward.creditCard" />
          </div>
        </div>

        <div class="row form-section-title">
          <div class="col-xs-12 col-sm-6">
            <span class="sub-title">Select recipient</span>
          </div>
        </div>

        <div class="row form-section-body" ng-show="sendReward.list.isInitialised() && sendReward.list.recordsExist()">
          <div class="col-xs-12">

            <div class="row">
              <div class="col-xs-12 col-sm-6">
                <div class="form-group page-filter">
                  <label class="sr-only" for="searchName">Search</label>
                  <div class="left-inner-addon">
                    <i class="fa fa-search inner-addon"></i>
                    <input class="form-control input-lg" type="search" name="searchName" id="searchName" ng-model="sendReward.searchName"
                           placeholder="Search"  ng-keyup="$event.keyCode == 13 && sendReward.search($event)" />
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6" ng-if="sendReward.industries.length">

                <div class="form-group page-filter">
                  <label class="sr-only" for="classification">Classification</label>
                  <select class="form-control input-lg" name="classification" id="classification" placeholder="All industries"
                          ng-model="sendReward.idIndustry" ng-change="sendReward.filterByIndustry()">
                    <option value="" selected>All industries</option>
                    <option ng-repeat="option in sendReward.industries" value="{{option.id}}">{{option.name}}</option>
                  </select>
                </div>

              </div>
            </div>

            <div class="row">
              <div class="col-xs-12 table-container">
                <table st-table="sendReward.list.getRecords()" st-pagination-scroll st-pipe="sendReward.list.load" class="table send-lead-users-table">
                  <tbody ng-hide="sendReward.list.isLoading() && !sendReward.list.isLoadingMoreRecords()">
                  <tr class="cursor-pointer" ng-repeat="row in sendReward.list.getRecords()" ng-click="sendReward.selectRecipient($index)">
                    <td class="col-xs-12 col-sm-6 col-md-5">
                      <table>
                        <tr>
                          <td ng-class="{'user-selected': sendReward.isUserSelected($index)}">
                            <table-user-details user="row.owner" is-link="false"></table-user-details>
                          </td>
                        </tr>
                      </table>
                    </td>
                    <td class="col-sm-6 col-md-5 hidden-xs">
                      {{row.owner.industry.name}}
                      <span ng-if="row.owner.subIndustry">
                        <br/>{{row.owner.subIndustry.name}}
                      </span>
                    </td>
                    <td class="col-md-2 hidden-xs hidden-sm">{{row.rewardType}}</td>
                  </tr>
                  </tbody>
                  <tbody ng-if="sendReward.list.isLoading()">
                  <tr>
                    <td colspan="5" class="col-xs-12 text-center">
                      <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="row">
              <div class="col-xs-12">
                <div class="lead-recipient-list">
                  <span ng-hide="sendReward.selectedRecipient">Select above to add recipient</span>
                  <div ng-if="sendReward.selectedRecipient" class="lead-recipient">{{sendReward.selectedRecipient.firstName}} {{sendReward.selectedRecipient.lastName}} <a class="send-lead-remove-user cursor-pointer" ng-click="sendReward.deselectRecipient()"><i class="fa fa-times"></i></a></div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="row form-section-body" ng-if="sendReward.list.isInitialised() && !sendReward.list.recordsExist()">
          <div class="col-xs-12">
            <div class="page-empty-section text-center">
              <i class="fa fa-users fa-2x text-light-colour"></i>
              <div class="page-empty-section-text">
                You don't have any connections
              </div>
              <br/>
              Join pods and start receiving leads.
            </div>
          </div>
        </div>

        <div class="row form-section-title">
          <div class="col-xs-12 col-sm-6">
            <span class="sub-title">Message</span>
          </div>
        </div>

        <div class="row form-section-body">
          <div class="col-xs-12">
            <div class="form-group">
              <label class="sr-only" for="message">Message</label>
              <div class="left-inner-addon">
                <i class="fa fa-comment-o inner-addon"></i>
                <textarea name="message" id="message" placeholder="Message" ng-model="sendReward.data.message"
                          class="form-control input-lg" msd-elastic></textarea>
              </div>
            </div>
          </div>
        </div>

        <div class="row form-section-body">
          <div class="col-xs-6 form-group">
            <button type="button" class="btn btn-default btn-lg btn-block"
                    ng-disabled="sendReward.isLoading" ng-click="sendReward.cancel()">
              Cancel
            </button>
          </div>
          <div class="col-xs-6 form-group">
            <button type="button" class="btn btn-primary btn-lg btn-block"
                    ng-disabled="sendRewardForm.$invalid || !sendReward.selectedRecipient || sendReward.isLoading" ng-click="sendReward.send()">
              {{sendReward.isLoading ? 'Loading ...' : 'Send Reward'}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
