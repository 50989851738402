(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.ui.table.directive:creditCard
   * @restrict E
   * @element
   */
  angular
    .module('components.ui')
    .directive('creditCard', creditCard);

  function creditCard() {
    return {
      restrict: 'E',
      scope: {
        user: '=',
        ccData: '=',
        ccShowForm: '&',
        creditCard: '='
      },

      // creditCard - is the existing credit card info
      // ccData - is the object into which the new credit card info will be stored (two-way)
      // ccShowForm - whether to show the new ccForm or not
      // user - userProfile from which the data will be source. might be duplicated as creditCard is directly belonging to him already.

      replace: true,
      transclude: false,
      templateUrl: 'components/ui/credit-card-directive.tpl.html',
      controllerAs: 'cc',
      controller: ['$scope', 'CreditCardUtil', function ($scope, CreditCardUtil) {
        var vm = this;
        vm.months = CreditCardUtil.getExpiryMonths();
        vm.years = CreditCardUtil.getExpiryYears();
        vm.cards = CreditCardUtil.getAcceptedCards();

        // Scope variables in the controller
        vm.scope = $scope;

        if (vm.scope.ccData) {
          vm.scope.ccData = {
            method: angular.isObject(vm.scope.creditCard) && angular.isDefined(vm.scope.creditCard.number) ? 'old' : 'new'
          };
        }

        //
        vm.showCCForm = function (form) {
          var showForm = false;
          if (angular.isFunction(vm.scope.ccShowForm) && angular.isDefined(vm.scope.ccShowForm())) {
            showForm = vm.newCard || vm.scope.ccShowForm();
          }
          else if (vm.scope.creditCard && vm.scope.creditCard.number && !vm.newCard) {
            showForm = false;
          }
          else if (vm.scope.creditCard && vm.scope.creditCard.number && vm.newCard) {
            showForm = true;
          }
          else if (!vm.scope.creditCard || !vm.scope.creditCard.number) {
            showForm = true;
          }
          if (!showForm && form) {
            vm.clearForm(form);
          }
          return showForm;
        };

        //
        vm.closeCCForm = function (form) {
          vm.scope.ccData = {};
          vm.newCard = false;
          if (form) {
            vm.clearForm(form);
          }
          vm.scope.ccData.method = 'old';
        };

        //
        vm.clearForm = function (form) {
          delete form.$error.ccExp;
          delete form.$error.ccNumber;
          delete form.$error.ccNumberType;
          delete form.$error.ccExpMonth;
          delete form.$error.ccExpYear;

          if (form.$$parentForm) {
            delete form.$$parentForm.$error.ccExp;
            delete form.$$parentForm.$error.ccNumber;
            delete form.$$parentForm.$error.ccNumberType;
            delete form.$$parentForm.$error.ccExpMonth;
            delete form.$$parentForm.$error.ccExpYear;
          }
        };
      }]
    };
  }
}());
