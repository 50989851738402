(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name account.earnings.controller:WithdrawCtrl
   *
   * @description
   *
   */
  angular
    .module('account.earnings')
    .controller('WithdrawCtrl', WithdrawCtrl);

  function WithdrawCtrl($state, Restangular, Notification, FormAlerts, earnings, userProfile, Charities) {
    var vm = this;
    vm.isLoading = false;
    vm.showTotal = false;
    vm.calculating = false;
    vm.calculationRequired = false;
    vm.minLimit = earnings.minimumWithdrawLimit;
    vm.balance = earnings.actualEarnings;
    vm.processingFee = 0;
    vm.remainingBalance = 0;
    vm.receivedAmount = 0;
    vm.alerts = new FormAlerts();
    vm.data = {
      amount: null,
      accountHolder: userProfile.bankAccountName,
      accountBsb: userProfile.bankCodeDomestic,
      accountNumber: userProfile.bankAccountNumberDomestic
    };

    vm.calculate = function () {
      vm.showTotal = true;
    };

    vm.cancel = function () {
      $state.go('account.earnings');
    };

    vm.onAmountChange = function () {
      vm.showTotal = false;
      vm.calculationRequired = true;
    };

    vm.calculate = function () {
      if (vm.data.amount > 0 && vm.data.amount >= earnings.minimumWithdrawLimit && vm.data.amount <= earnings.actualEarnings) {
        vm.calculating = true;
        Restangular.one('Withdrawals').one('calculateWithdrawalFees').get({
          amount: vm.data.amount
        })
        .then(function (result) {
          vm.processingFee = result.fees;
          vm.receivedAmount = result.amountLessFees;
          vm.remainingBalance = result.remainingBalance;
          vm.calculating = false;
          vm.calculationRequired = false;
          vm.showTotal = true;
        });
      }
    };

    vm.withdraw = function () {
      vm.alerts.reset();
      vm.isLoading = true;
      Restangular.one('Withdrawals').post('index', vm.data)
        .then(function () {
          userProfile.bankAccountName = vm.data.accountHolder;
          userProfile.bankCodeDomestic = vm.data.accountBsb;
          userProfile.bankAccountNumberDomestic = vm.data.accountNumber;
          $state.go('account.earnings');
          Notification({message: 'Success'}, 'confirmation');
        }, function () {
          vm.isLoading = false;
          vm.alerts.add('danger', 'Unable to withdraw');
        });
    };

    vm.donate = function () {
      Charities.openDonate();
    };
  }
}());
