(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name myPod.members.controller:MembersCtrl
   *
   * @description
   *
   */
  angular
    .module('myPod.members')
    .controller('MembersCtrl', MembersCtrl);

  function MembersCtrl(DataTable) {
    var vm = this,
        filter;
    vm.statuses = {
      active: 'Active',
      past: 'Past',
      pending: 'Pending invites'
    };
    vm.searchName = '';
    vm.selectedStatus = 'active';
    vm.selectedStatusLabel = 'Active';
    vm.lastSearched = null;

    vm.list = DataTable.create('Members', 'index', {
      status: 'active'
    }, {
      basicParams: {}
    });

    filter = function () {
      vm.list.reload({
        status: vm.selectedStatus,
        search: vm.searchName
      });
    };

    vm.selectStatus = function (status) {
      vm.selectedStatus = status;
      vm.selectedStatusLabel = vm.statuses[status];
      filter();
    };

    vm.search = function () {
      if (vm.lastSearched === vm.selectedStatus + vm.searchName) {
        return;
      }
      filter();
      vm.lastSearched = vm.selectedStatus + vm.searchName;
    };

    vm.list.isReady.then(function () {
      if (vm.list.recordsExist() && vm.list.getRecords() < 1) {
        vm.selectStatus('past');
      }
      else if (!vm.list.recordsExist()) {
        vm.selectStatus('pending');
      }
    });
  }
}());
