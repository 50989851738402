<div class="modal-content-container marketplace">
  <div class="row">
    <!-- Title -->
    <div class="col-xs-12 modal-content-title modal-content-title-w-tools" style="flex-wrap: wrap;">
      <div style="display: flex;flex: 1; justify-content: space-between;">
        <span class="sub-title">{{marketplaceInfo.item.label}} - {{marketplaceInfo.item.isFree ? 'FREE' : ((marketplaceInfo.item.priceMonthly | currency:'$':0))}}</span>
        <div class="preferences-tools">
          <div class="subscription-button" role="button" tabindex="1"
               ng-click="marketplaceInfo.switchSubscription($index)"
               ng-class="{'active': marketplaceInfo.item.isSubscribed, 'disabled': marketplaceInfo.isUpdating}">

            <div ng-if="!marketplaceInfo.item.isSubscribed">
              <span>{{marketplaceInfo.item.isFree ? 'FREE' : (marketplaceInfo.item.priceMonthly | currency:'$':0)}}</span>
            </div>
            <div ng-if="marketplaceInfo.item.isSubscribed">
              <span>Unsubscribe</span>
            </div>
          </div>
          <div class="preferences-content-subtitle" style="text-align:right;">
            <span ng-if="marketplaceInfo.item.isSubscribed">Subscribed </span>
            <span ng-if="!marketplaceInfo.item.isSubscribed">Not subscribed</span>
          </div>
        </div>
      </div>
      <div style="flex: 1 0 100%;">
        <div class="marketplace-beans marketplace-beans-structured">
          <div class="marketplace-owner" ng-if="marketplaceInfo.item.owner">
            <span>Owner</span>
            <span>
              <table-user-details style="display: inline-flex; width: auto;" user="marketplaceInfo.item.owner" name-only="true" add-class="" is-link="true" has-details="true"></table-user-details>
            </span>
          </div>
          <div class="marketplace-price">
            <span>Price</span>
            <span class="marketplace-value">{{marketplaceInfo.item.isFree ? 'FREE' : (marketplaceInfo.item.priceMonthly | currency:'$':0)}}</span>
          </div>
          <!--<div class="marketplace-company" ng-if="marketplaceInfo.item.owner.businessName">
            <span>Company</span>
            <span class="marketplace-value">{{marketplaceInfo.item.owner.fullName + ((marketplaceInfo.item.owner.businessName && marketplaceInfo.item.owner.fullName !== marketplaceInfo.item.owner.businessName) ? ' | ' + marketplaceInfo.item.owner.businessName : '' )}}</span>
          </div>-->
          <div class="marketplace-description">
            <span>Description</span>
            <span class="marketplace-value">{{marketplaceInfo.item.description}}</span>
          </div>

        </div>
      </div>
    </div>
    <!-- ENDOF: Title-->

    <!-- Content -->

    <div class="col-xs-12" ng-if="!marketplaceInfo.isLoading" ng-bind-html="marketplaceInfo.item.info || marketplaceInfo.item.description"></div>
    <div class="col-xs-12 text-center" ng-if="marketplaceInfo.isLoading"><i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i></div>
    <!-- ENDOF: Content -->
  </div>

  <div class="row modal-content-buttons">
    <div class="text-right">
      <button type="button" class="btn btn-default" ng-click="marketplaceInfo.close()">
        Close
      </button>
    </div>
  </div>
</div>


