<div class="row" ng-class="{'has-invitation': signUp.invitationSender, 'reward-notes-content': signUp.invitationSender, 'signup-trial': signUp.trialEligible}" ng-if="!signUp.showMessage">
  <div class="col-md-12 text-center">
    <div class="row" ng-if="signUp.invitationSender && signUp.invitationSender.imageToken">
      <div class="col-xs-12">
        <div class="sign-up-page-header">
          <user-avatar user="signUp.invitationSender" size="large" fixed-size="false" is-link="false"></user-avatar>
        </div>
      </div>
      <!-- Please keep this for the future reference in case we will change the description under profile avatar back in
      <div class="col-xs-12 text-xs-center text-sm-center col-sm-12 col-md-12 col-lg-12">
        <span style="font-size: 14px; font-weight: bold;">{{signUp.invitationSender.fullName}}</span>
        <span style="font-size: 14px; font-weight: normal; color: gray; word-wrap: word-wrap;">  ({{signUp.invitationSender.businessName}})</span>
      </div>-->
    </div>

    <h1 class="page-header" ng-if="!signUp.invitationSender">
      Join in less than a minute. No credit card required.
    </h1>


    <div class="row" ng-if="signUp.invitationSender && !signUp.trialEligible">
      <div class="col-xs-12" ng-class="{'page-header': !signUp.invitationSender.imageToken}" style="text-shadow: none;">
        <h1 class="" ng-if="signUp.invitationType === 'Reward Code'" ng-switch on="signUp.rewardCode">
          <span ng-switch-when="WEB-FREE">
            Join {{signUp.invitationSender.fullName}} and start earning cash for sending leads.
          </span>
          <span ng-switch-default>
            Join {{signUp.invitationSender.fullName}}{{signUp.invitationSender.businessName ? ', ' + signUp.invitationSender.businessName : ''}} Rewards Program.
          </span>
        </h1>
        <h1 class="" ng-if="signUp.invitationType === 'Join Pod'">
          Join {{signUp.invitationSender.fullName}}{{signUp.invitationSender.businessName ? ', ' + signUp.invitationSender.businessName : ''}} Rewards Program.
        </h1>
        <h1 class="" ng-if="signUp.invitationType === 'Invitation'">
          {{signUp.invitationSender.fullName}}{{signUp.invitationSender.businessName ? ', ' + signUp.invitationSender.businessName : ''}} would like to send you business leads.
        </h1>
        <div ng-if="signUp.invitationType !== 'Invitation'" ng-switch on="signUp.rewardCode">
          <div class="reward-code-both" ng-switch-when="WEB-FREE">
            <div class="signup-prompt-earninginfo" ng-if="signUp.invitationSender.highestReward">
              You can now earn up to <span>${{signUp.invitationSender.highestReward}}</span> by sending MyLeadPod a successful lead!
              <br>
              Search and join different businesses and their Rewards Program on MyLeadPod to start earning cash for sending leads.
            </div>
          </div>
          <div ng-switch-default>
            <div class="signup-prompt-earninginfo" ng-if="signUp.invitationSender.highestReward">You can now earn up to <span>${{signUp.invitationSender.highestReward}}</span> by sending just one lead!</div>
          </div>
        </div>
        <div ng-if="signUp.invitationType === 'Invitation'">
          <div class="signup-prompt-earninginfo">Expand your business by registering with MyLeadPod, fast-growing business referral network system.</div>
        </div>
      </div>
    </div>

    <div class="row" ng-if="signUp.invitationSender && signUp.trialEligible && signUp.invitationType === 'Reward Code'">
      <div class="col-xs-12" ng-class="{'page-header': !signUp.invitationSender.imageToken}" style="text-shadow: none;">
        <div ng-switch on="signUp.rewardCode">
          <h2 style="font-weight: 100" ng-switch-when="WEB-TRIAL">
            Sign up for your free trial.
          </h2>
          <h2 style="font-weight: 100" ng-switch-default>
            Join {{signUp.invitationSender.fullName}}{{signUp.invitationSender.businessName ? ', ' + signUp.invitationSender.businessName : ''}} on MyLeadPod. Sign up and..
          </h2>
        </div>
      </div>
      <div class="col-xs-12" ng-switch on="signUp.rewardCode">
        <div class="reward-code-both" ng-switch-when="WEB-TRIAL">
          <div style="text-shadow: none; margin-bottom: 0px;">
            <div class="welcome-title">
              Activate your {{signUp.trialDuration}} free business subscription.
            </div>
            <div class="signup-prompt-earninginfo">
              You are only a few steps away from creating your own cash Reward Program for your business.
            </div>
          </div>
        </div>
        <div class="reward-code-both" ng-switch-default>
          <div>
            <div class="welcome-title">
              .. send your first lead.
            </div>
            <div class="signup-prompt-earninginfo" ng-if="signUp.invitationSender.highestReward">You can now earn up to <span>${{signUp.invitationSender.highestReward}}</span> by sending just one lead!</div>
          </div>
          <div class="" style="text-shadow: none; margin-bottom: 0px;">
            <!--<div ng-if="!signUp.invitationSender || !signUp.invitationSender.imageToken">
              <img src="../images/logo.png">
            </div>-->
            <div class="welcome-title">
              .. receive {{signUp.trialDuration}} free business trial.
            </div>
            <div class="signup-prompt-earninginfo">
              You are only a few steps away from creating your own cash Reward Program and starting receiving leads for your business.
            </div>
            <!--<div class="signup-prompt-earninginfo">*Only for new or eligible businesses</div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row" ng-if="!signUp.invitationSender && !signUp.showMessage">
  <div class="col-xs-12 reward-input-content slide-if-flex" ng-if="!signUp.rewardCardOwner">
    <div class="reward-card-holder" ng-class="{flipped: signUp.flipCard}">
      <img class="front" src="./images/reward-code-card-front.png" />
      <img class="back" src="./images/reward-code-card-back.png" />
    </div>
    <div class="reward-holder">
      <div style="margin-bottom:15px;">
        Do you have a <strong>Reward Code</strong>?
      </div>
      <div ng-class="{'has-error': signUp.isRewardCodeInvalid, slide: signUp.hasRewardCode && !signUp.displayContactDetails}">

        <label class="sr-only" for="rewardCode">Reward Code</label>
        <div ng-show="signUp.isRewardCodeInvalid">
          <div class="input-error" ng-show="signUp.isRewardCodeInvalid">Invalid Reward Code.</div>
        </div>
        <div class="left-inner-addon input-group">
          <i ng-show="!signUp.rewardCodeChecked && !signUp.isCheckingCode && !signUp.isRewardCodeValid" class="fa fa-hashtag inner-addon"></i>
          <i ng-show="signUp.isCheckingCode" class="fa fa-circle-o-notch fa-spin-2x inner-addon"></i>
          <i ng-show="!signUp.isCheckingCode && signUp.rewardCodeChecked && signUp.isRewardCodeValid" class="fa fa-check code-container-valid"></i>
          <i ng-show="!signUp.isCheckingCode && signUp.rewardCodeChecked && !signUp.isRewardCodeValid" class="fa fa-times code-container-invalid"></i>
          <input class="form-control input-lg" type="text" name="rewardCode" id="rewardCode"
                 placeholder="Reward Code"
                 ng-required="false"
                 ng-model="signUp.rewardCode"
                 ng-keydown="signUp.onRewardCodeEnter($event)"
                 ng-focus="signUp.flipCard=true;"
                 ng-blur="signUp.checkRewardCode();signUp.flipCard=false;"/>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xs-12 reward-notes-content"
       ng-class="{'reward-card-note' : !signUp.rewardCodeChecked}"
       ng-if="!signUp.rewardCodeChecked || (signUp.rewardCodeChecked && (signUp.rewardCardOwner || !signUp.isRewardCodeValid))">

    <!-- User Info -->
    <div class="reward-card-found slide-if" ng-if="signUp.rewardCodeChecked && !signUp.isCheckingCode && signUp.isRewardCodeValid && signUp.rewardCardOwner">
      <div ng-if="signUp.rewardCardOwner && signUp.rewardCardOwner.imageToken">
        <user-avatar user="signUp.rewardCardOwner" size="large" fixed-size="false" is-link="false"></user-avatar>
      </div>
      <div>
        <div class="reward-card-user-info">
          <h1>Join {{signUp.rewardCardOwner.fullName}}{{signUp.rewardCardOwner.businessName ? ', ' + signUp.rewardCardOwner.businessName : ''}} Rewards Program.</h1>
        </div>
        <div class="signup-prompt-earninginfo" ng-if="signUp.rewardCardOwner.highestReward">You can now earn up to <span>${{signUp.rewardCardOwner.highestReward}}</span> by sending just one lead!</div>
      </div>
    </div>

    <!-- Card not found -->
    <div class="reward-card-notfound slide-if" ng-if="signUp.rewardCodeChecked && !signUp.isCheckingCode && !signUp.isRewardCodeValid">
      <div>Sorry, Reward Code was not found or is not longer available.</div>
      <div>You can try again, or join and search for the business in the app to send the lead(s)!</div>
    </div>

    <!-- Default message -->
    <div class="reward-card-warning slide-if" ng-if="!signUp.rewardCodeChecked">
      Reward Code is optional and not required for joining.
      <span>Reward Card may differ.</span>
    </div>
  </div>
  <div class="col-xs-12 reward-input-content reward-card-note slide-if" ng-if="signUp.rewardCardOwner">
    <a ng-click="signUp.rewardCardOwner = null;">Try another Reward Code</a>
  </div>
</div>

<div class="row sign-up-page-container" ng-if="!signUp.showMessage">
  <div class="sign-in-page-content">
    <div class="sign-up-page-action-title hidden-xs">
      Join the quick way
    </div>
    <div class="sign-up-page-action-container">
      <div class="btn-social-container">
        <button class="btn btn-social btn-facebook" ng-click="signUp.socialSignIn('facebook')" ng-disabled="signUp.isLoading">
          <i class="btn-social-icon fa fa-facebook"></i>
          <span class="btn-social-text">Join with Facebook</span>
        </button>
      </div>

      <div class="btn-social-container">
        <button class="btn btn-social btn-linkedin" ng-click="signUp.socialSignIn('linkedin')" ng-disabled="signUp.isLoading">
          <i class="btn-social-icon fa fa-linkedin"></i>
          <span class="btn-social-text">Join with Linkedin</span>
        </button>
      </div>

      <div class="btn-social-container">
        <button class="btn btn-social btn-google" ng-click="signUp.socialSignIn('google')" ng-disabled="signUp.isLoading">
          <i class="btn-social-icon fa fa-google-plus"></i>
          <span class="btn-social-text">Join with Google+</span>
        </button>
      </div>
    </div>
  </div>

  <div class="sign-in-page-content separator">
    <div class="btn-social-separator-container">
      <div class="btn-social-separator btn-social-separator-sign-in"></div>
      <div class="btn-social-separator-text">OR</div>
      <div class="btn-social-separator btn-social-separator-sign-in"></div>
    </div>
  </div>

  <div class="sign-in-page-content">
    <div class="sign-up-page-action-title hidden-xs">
      Join with your email
    </div>
    <div class="sign-up-page-action-container sign-up-in-form-container">
      <uib-alert ng-repeat="alert in signUp.alerts.getList()" type="{{alert.type}}" close="signUp.alerts.remove($index)"><span ng-bind-html="alert.message"></span></uib-alert>
      <form name="passwordSignUpForm" ng-submit="passwordSignUpForm.$valid && !signUp.isLoading && signUp.passwordSignUp()">
        <div class="form-group" ng-class="{'has-error': (passwordSignUpForm.$submitted || passwordSignUpForm.username.$touched) && (passwordSignUpForm.username.$error.required || passwordSignUpForm.username.$error.email)}">
          <label class="sr-only" for="email">Email</label>
          <div ng-show="passwordSignUpForm.$submitted || passwordSignUpForm.username.$touched">
            <div class="input-error" ng-show="passwordSignUpForm.username.$error.required">Tell us your email.</div>
            <div class="input-error" ng-show="passwordSignUpForm.username.$error.email">This is not a valid email.</div>
          </div>
          <div class="left-inner-addon">
            <i class="fa fa-envelope-o inner-addon"></i>
            <input class="form-control input-lg" type="email" name="username" id="email" placeholder="Email"
                   ng-required="true" ng-model="signUp.data.email" />
          </div>
        </div>
        <div class="form-group" ng-class="{'has-error': (passwordSignUpForm.$submitted || passwordSignUpForm.password.$touched) && passwordSignUpForm.password.$error.required}">
          <label class="sr-only" for="password">Create Your Password</label>
          <div ng-show="passwordSignUpForm.$submitted || passwordSignUpForm.password.$touched">
            <div class="input-error" ng-show="passwordSignUpForm.password.$error.required">Please type in your new password.</div>
          </div>
          <div class="left-inner-addon">
            <i class="fa fa-lock inner-addon"></i>
            <input class="form-control input-lg" type="password" name="password" id="password" placeholder="Create Your Password"
                   ng-required="true" ng-model="signUp.data.password"/>
          </div>
        </div>

        <div class="form-group sign-up-page-btn-create">
          <button type="submit" class="btn btn-primary btn-lg btn-block"
                  ng-disabled="signUp.isLoading">
            {{signUp.isLoading ? 'Loading ...' : 'Create Account'}}
          </button>
        </div>

      </form>
      <div class="sign-up-terms text-left">
        By clicking 'Create Account' button, you agree to MyLeadPod's <a target="_blank" href="http://blog.myleadpod.com/terms-and-conditions/">Terms & Conditions</a> and <a target="_blank" href="http://blog.myleadpod.com/privacy-policy/">Privacy Policy</a>
      </div>
    </div>
  </div>
</div>

<div class="row success-message-container" ng-if="signUp.showMessage">
  <div class="col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 text-center">
    <div class="panel panel-default">
      <div class="panel-body">
        <h2>Thanks for joining!</h2>
        <p style="margin-bottom: 15px;">
          Please check your email and click Verify Email in the message we just sent to
          <br>
          <span>{{signUp.data.email}}</span>
          <br>
          Once your email has been verified you'll get access to your account.
        </p>
      </div>
    </div>
  </div>
</div>

