(function () {
  'use strict';

  /* @ngdoc object
   * @name socialNetwork
   * @description
   *
   */
  angular
    .module('socialNetwork', [
      'ui.router'
    ]);

  angular
    .module('socialNetwork')
    .config(config);

  function config(configOptions) {
    /* eslint camelcase: [2, {properties: "never"}]*/
    hello.init({
      facebook: configOptions.facebookAppId,
      google: configOptions.googlePlusClientId,
      linkedin: {
        id: configOptions.linkedInClientId,
        scope: {
          basic: 'r_liteprofile',
          email: 'r_emailaddress'
        }
      }
    }, {
      oauth_proxy: configOptions.linkedInProxy
    });
  }
}());
