(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name myMemberships.receivedLeads.controller:ReceivedLeadsCtrl
   *
   * @description
   *
   */
  angular
    .module('myMemberships.receivedLeads')
    .controller('ReceivedLeadsCtrl', ReceivedLeadsCtrl);

  function ReceivedLeadsCtrl($stateParams, DataTable, Leads) {
    var vm = this,
        filter;
    vm.statuses = {
      all: 'All leads',
      open: 'Open',
      new: 'New',
      successful: 'Successful',
      unsuccessful: 'Unsuccessful'
    };
    vm.searchLead = '';
    vm.selectedStatus = 'all';
    vm.selectedStatusLabel = 'All leads';
    vm.lastSearched = null;

    vm.list = DataTable.create('Leads', 'index', {}, {
      basicParams: {
        type: 'received'
      }
    });

    filter = function () {
      vm.list.reload({
        status: vm.selectedStatus,
        search: vm.searchLead
      });
    };

    vm.selectStatus = function (leadStatus) {
      vm.selectedStatus = leadStatus;
      vm.selectedStatusLabel = vm.statuses[leadStatus];
      filter();
    };

    vm.search = function () {
      if (vm.selectedStatus + vm.searchLead === vm.lastSearched) {
        return;
      }
      filter();
      vm.lastSearched = vm.selectedStatus + vm.searchLead;
    };

    vm.openDetail = function ($index) {
      var detailInstance = Leads.openDetail(vm.list.getRecords()[$index], 'received', 'received-leads-ctrl-container');
      if (angular.isDefined(detailInstance)) {
        detailInstance.then(function (result) {
          if (angular.isObject(result) && angular.isObject(result.lead)) {
            vm.list.getRecords()[$index] = result.lead;
          }
        });
      }
    };

    vm.closeLead = function ($index, status) {
      var result = Leads.closeLead(vm.list.getRecords()[$index], status, 'received-leads-ctrl-container');
      if (result) {
        result.then(function () {
          vm.list.getRecords()[$index].status = status;
        });
      }
    };

    if (angular.isObject($stateParams.leadToOpen) && angular.isNumber($stateParams.leadToOpen.id)) {
      Leads.openDetail($stateParams.leadToOpen, 'received', 'received-leads-ctrl-container');
    }
  }
}());
