(function () {
  'use strict';

  angular
    .module('myPod')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('myPod', {
        url: '/my-pod',
        templateUrl: 'my-pod/my-pod.tpl.html',
        controller: 'MyPodCtrl',
        controllerAs: 'myPod',
        authenticate: true
      });
  }
}());
