(function () {
  'use strict';

  angular
    .module('account')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('account', {
        url: '/account',
        templateUrl: 'account/account.tpl.html',
        controller: 'AccountCtrl',
        controllerAs: 'account'
      })
      .state('accountSignInUp', {
        abstract: true,
        template: '<ui-view/>',
        params: {
          invitationToken: null,
          rewardCode: null
        },
        resolve: {
          rewardCode: ['Restangular', '$stateParams', function (Restangular, $stateParams) {
            if ($stateParams.rewardCode && angular.isString($stateParams.rewardCode) && $stateParams.rewardCode.length > 0) {
              return Restangular.one('RewardCodes').one('detail').get({rewardCode: $stateParams.rewardCode})
                .then(function (rewardCode) {
                  return rewardCode.plain();
                }, function () {
                  return false;
                });
            }
            return null;
          }]
        },
        data: {
          classNames: 'no-help'
        }
      })
      .state('accountSignInUp.accountSignIn', {
        url: '/account/signin',
        templateUrl: 'account/sign-in.tpl.html',
        controller: 'SignInCtrl',
        controllerAs: 'signIn',
        params: {
          nextState: {}
        },
        data: {
          classNames: 'no-help requires-login'
        }
      })
      .state('accountSignInUp.accountSignUp', {
        url: '/account/signup',
        templateUrl: 'account/sign-up.tpl.html',
        controller: 'SignUpCtrl',
        controllerAs: 'signUp',
        resolve: {
          invitation: ['Restangular', '$stateParams', function (Restangular, $stateParams) {
            if ($stateParams.invitationToken) {
              return Restangular.one('Invitations').one('detail').get({token: $stateParams.invitationToken})
                .then(function (invitation) {
                  return invitation.plain();
                }, function () {
                  return null;
                });
            }

            return null;
          }]
        },
        data: {
          classNames: 'no-help requires-signup'
        }
      })
      .state('accountActivate', {
        url: '/account/activate/{token}',
        templateUrl: 'account/activate.tpl.html',
        controller: 'ActivateCtrl',
        controllerAs: 'activate',
        params: {
          invitationToken: null
        },
        resolve: {
          userRegistration: ['$stateParams', '$state', 'Restangular', function ($stateParams, $state, Restangular) {
            return Restangular.one('Users').one('activate').get({token: $stateParams.token})
              .then(function (result) {
                return result.plain();
              }, function () {
                $state.go('accountSignInUp.accountSignIn');
              });
          }]
        }
      })
      .state('accountVerifyRegistration', {
        url: '/account/verify/{token}',
        templateUrl: 'account/activate-update-password.tpl.html',
        controller: 'ActivateUpdatePasswordCtrl',
        controllerAs: 'activate',
        params: {
          invitationToken: null
        },
        resolve: {
          userProfile: ['$stateParams', '$state', '$q', 'Auth', 'Session', 'Restangular', function ($stateParams, $state, $q, Auth, Session, Restangular) {
            var userProfileDeferred = $q.defer();
            Auth.signOut(false);
            Restangular.one('Users').one('verify').get({token: $stateParams.token})
              .then(function (result) {
                var userProfile = result.profile,
                    token = result.authToken;

                // If this is not a valid token, then just go to sign in.
                if (!token || !userProfile) {
                  $state.go('accountSignInUp.accountSignIn');
                  return;
                }

                // Save it into the cache
                Auth.saveLoginToken(result.authToken);

                // Create the session
                Session.create(userProfile);

                // If this is the first time and user needs to provide the password, go ahead
                if (!userProfile.isPasswordSet) {
                  userProfileDeferred.resolve(userProfile);
                }
                else {
                  // Forward him to sign in. That should in turn go to dashboard?
                  $state.go('accountSignInUp.accountSignIn');
                }
              }, function () {
                $state.params = angular.extend($state.params, {logout: true});
                $state.go('accountSignInUp.accountSignIn');
              });

            return userProfileDeferred.promise;
          }]
        }
      })
      .state('accountUnsubscribe', {
        url: '/account/mailing-list/unsubscribe/{userId}/{subscriptionId}',
        templateUrl: 'account/preferences/mailing-list-unsubscribe.tpl.html',
        controller: 'MailingListUnsubscribeCtrl',
        controllerAs: 'preferences',
        data: {
          classNames: 'mailing-list-unsubscribe'
        }
      });
  }
}());
