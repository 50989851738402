(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.directive:scrollNavigator
   * @restrict A
   * @element
   *
   * @description Directive that checks if the element is within the viewport and if not, it displays the message to scroll
   *
   */
  angular
    .module('components')
    .directive('scrollNavigator', scrollNavigator);

  function scrollNavigator($window, $rootScope, $document, $timeout, $uibPosition) {
    return {
      restrict: 'AE',
      scope: {
        autoRun: '=',
        onClickCallback: '=',
        scrollTargetElementId: '=',
        scrollTargetChangeDelay: '='
      },
      link: function (scope, element, attrs) {
        var targetElement,
            checkScroll,
            navigatorElement,
            watchHandle,
            watchToolbarHandleVisible,
            watchToolbarHandleHidden,
            windowScrollHandle,
            windowResizeHandle,
            windowFn,
            timeoutPromise,
            navigatorOffsetX,
            navigatorOffsetY,
            actionToolbarOffset = 0,
            scrollingElement;

        function getScrollingElement() {
          var d = $document[0];
          return d.documentElement.scrollHeight > d.body.scrollHeight && d.compatMode.indexOf('CSS1') === 0 ? d.documentElement : d.body;
        }

        // Function
        checkScroll = function () {
          var offset;
          targetElement = $document[0].getElementById(scope.scrollTargetElementId);
          if (!targetElement) {
            return false;
          }
          offset = $uibPosition.viewportOffset(targetElement, true);

          if (offset.bottom < actionToolbarOffset) {
            // Means the target element is completely or partially out of view. Display the message.
            if (offset.bottom < targetElement.clientHeight * -1 + actionToolbarOffset) {
              // It's completely out
              navigatorElement.css({
                top: $window.innerHeight - navigatorElement.outerHeight() + navigatorOffsetY - actionToolbarOffset + 'px',
                left: offset.left + navigatorOffsetX + 'px',
                position: 'fixed'
              });
            }
            /*
            else {
              // Partially invisible. As the user might have been scrolling further from completely invisible to partially invisible (here)
              // the calculation and transition of the element are causing undesireable 'jump' effect. Let's check that
              navigatorElement.css({
                top: $window.innerHeight + (offset.top - $window.innerHeight) - navigatorElement.outerHeight() + $window.scrollY + navigatorOffsetY - actionToolbarOffset + 'px',
                left: offset.left + navigatorOffsetX + 'px',
                position: 'absolute'
              });
            }
            */
            navigatorElement.addClass('visible');
            scope.$apply();
          }
          else if (offset.bottom > 0) {
            navigatorElement.removeClass('visible');
            scope.$apply();
          }
        };

        // Get target element
        if (angular.isDefined(scope.scrollTargetElementId)) {
          // Create the navigator element
          navigatorElement = angular.element('<div class="scroll-navigator no-select" id="' + scope.scrollTargetElementId + '-scroll-navigator"></div>');
          navigatorElement[0].innerHTML = '<span class="indicator"><i class="fa fa-arrow-down"></i></span><span>' + attrs.scrollMessage + '</span>';
          $document[0].body.appendChild(navigatorElement[0]);

          //
          navigatorOffsetX = attrs.scrollOffsetX ? parseInt(attrs.scrollOffsetX, 10) : 0;
          navigatorOffsetY = attrs.scrollOffsetY ? parseInt(attrs.scrollOffsetY, 10) : 0;

          //
          if (scope.onClickCallback) {
            navigatorElement.addClass('cursor-pointer');
            navigatorElement.on('click', function () {
              // Assuming the click will scroll the element in...
              // navigatorElement.removeClass('visible');
              // scope.$apply();
              // Call the callback function
              scope.onClickCallback(scope.scrollTargetElementId);
            });
          }

          //
          windowFn = function () {
            if (timeoutPromise) {
              $timeout.cancel(timeoutPromise);
            }
            scrollingElement = scrollingElement || getScrollingElement();
            if (scrollingElement.scrollTop < 0) {
              // This applies especially to iOS safar's elastic scroll that can go negative. don't do anything then.
              return;
            }
            timeoutPromise = $timeout(function () {
              checkScroll();
            }, 50);
          };

          windowScrollHandle = angular.element($window.document).on('scroll', windowFn);
          windowResizeHandle = angular.element($window.document).on('resize', windowFn);

          if (scope.autoRun) {
            checkScroll();
          }
        }

        //
        scope.$watch('scrollTargetElementId', function (oldVal, newVal) {
          if (oldVal !== newVal) {
            $timeout(function () {
              checkScroll();
            }, scope.scrollTargetChangeDelay ? scope.scrollTargetChangeDelay : 10);
          }
        });

        // Subscribe to the command of checking the scroll from parent (?). We shall be passing around the id of the element but no time for that
        watchHandle = $rootScope.$on('event:check-scroll-navigator', function (evt, id, timeout) {
          if (scope.scrollTargetElementId === id || id === 'all') {
            // Do this in the timeout as there might be multiple triggers for checkScroll and we don't know when and if to run scope.apply,
            // hence timeout to try to avoid the diggest errors,
            $timeout(function () {
              checkScroll();
            }, timeout ? timeout : 10);
          }
          else {
            navigatorElement.removeClass('visible');
          }
        });
        watchToolbarHandleVisible = $rootScope.$on('event:action-toolbar-visible', function (evt, height) {
          actionToolbarOffset = height;
          $timeout(function () {
            checkScroll();
          });
        });
        watchToolbarHandleHidden = $rootScope.$on('event:action-toolbar-hidden', function () {
          actionToolbarOffset = 0;
          $timeout(function () {
            checkScroll();
          });
        });
        scope.$on('$destroy', watchHandle);
        scope.$on('$destroy', watchToolbarHandleVisible);
        scope.$on('$destroy', watchToolbarHandleHidden);
        scope.$on('$destroy', function () {
          windowScrollHandle.off('scroll', windowFn);
          windowResizeHandle.off('resize', windowFn);
          navigatorElement.remove();
        });
      }
    };
  }
}());
