(function () {
  'use strict';

  angular
    .module('lead')
    .config(config);

  function config($stateProvider, configOptions) {
    $stateProvider
      .state('leadSend', {
        url: '/lead/send',
        templateUrl: 'lead/send-lead.tpl.html',
        controller: 'SendLeadCtrl',
        controllerAs: 'sendLead',
        authenticate: true,
        params: {
          user: null,
          lead: null
        },
        resolve: {
          // This the flag that will control whether the templates (and controller) will act as "sending the leads" or "registering the lead"
          isRegister: function () {
            return false;
          },
          // This is required for if the template is being used in the popup. The leads content is being requested via uiRouter, then provide the user details in the "user" param
          // If the isRegister == true, then the userProfile is the user in behalf who we are registering the lead. Otherwise it's the business user that the lead will be sent to.
          userProfile: function () {
            return null;
          },
          onCancelCallback: function () {
            return null;
          },
          onSentCallback: function () {
            return null;
          }
        }
      })
      .state('leadRegister', {
        url: '/lead/register',
        templateUrl: 'lead/send-lead.tpl.html',
        controller: 'SendLeadCtrl',
        controllerAs: 'sendLead',
        authenticate: true,
        params: {
          user: null,
          lead: null
        },
        resolve: {
          isRegister: function () {
            return true;
          },
          // This is required for if the template is being used in the popup. The leads content is being requested via uiRouter, then provide the user details in the "user" param
          userProfile: function () {
            return null;
          },
          onCancelCallback: function () {
            return null;
          },
          onSentCallback: function () {
            return null;
          }
        }
      })
      .state('leadDetail', angular.extend({}, configOptions.defaultStateParams, {
        url: '/lead/{id}/{tabName}',
        templateUrl: 'lead/lead-view.tpl.html',
        controller: 'LeadViewCtrl',
        controllerAs: 'leadView',
        authenticate: true,
        resolve: {
          leadToOpen: ['$stateParams', 'Restangular', 'Auth', function ($stateParams, Restangular, Auth) {
            return Auth.isAuthenticated().then(function (isAuthenticated) {
              if (isAuthenticated) {
                return Restangular.one('Leads').one('index').get({id: $stateParams.id})
                  .then(function (lead) {
                    return lead.plain();
                  }, function () {
                    return null;
                  });
              }
              return null;
            }, function () {
              return null;
            });
          }]
        }
      }));
  }
}());
