(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name components.service:RouterHelper
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('RouterHelper', RouterHelper);

  function RouterHelper($rootScope, $q, $timeout, QueueManager, QueueItem) {
    var self = this;

    self.activateTab = function (id, tabName, params) {
      QueueManager.add(new QueueItem(function (ready) {
        $q.when(ready).then(function () {
          $timeout(function () {
            self.lastEvent = {
              id: 'event:' + id + '-open-tab-' + tabName,
              params: params
            };

            $rootScope.$broadcast('event:' + id + '-open-tab-' + tabName, params);
          });
        });

        return $q.when(true);
      }));
    };
  }
}());
