(function () {
  'use strict';

  angular
    .module('mlp-animations', ['ngAnimate'])
    .animation('.slide', slide)
    .animation('.slide-fast', slideFast)
    .animation('.slide-if', slideIf)
    .animation('.slide-if-reversed', slideIfReversed)
    .animation('.slide-if-flex', slideIfFlex)
    .animation('.slide-left', slideLeft)
    .animation('.slide-right', slideRight)
    .animation('.opacity-if', opacityIf)
    .animation('.opacity-slow-if', opacitySlowIf);

  function slide() {
    return {
      addClass: function (element, className, done) {
        element.css('display', 'none');
        angular.element(element).slideDown('fast', done);
      },
      removeClass: function (element, className, done) {
        angular.element(element).slideUp('fast', done);
      }
    };
  }

  function slideFast() {
    return {
      addClass: function (element, className, done) {
        element.css('display', 'none');
        angular.element(element).slideDown(100, done);
      },
      removeClass: function (element, className, done) {
        angular.element(element).slideUp(100, done);
      }
    };
  }

  function slideIf() {
    // Remember to include the slide-if class in the app.scss with the "display: none" otherwise the slideDown won't be smooth
    // slideUp would not be affected as the element would be already visible.

    return {
      enter: function (element, done) {
        angular.element(element).slideDown('fast', done);
      },
      move: function (element, done) {
        angular.element(element).slideDown('fast', done);
      },
      leave: function (element, done) {
        angular.element(element).slideUp('fast', done);
      }
    };
  }

  function slideIfReversed() {
    return {
      enter: function (element, done) {
        angular.element(element).slideUp('fast', done);
      },
      move: function (element, done) {
        angular.element(element).slideUp('fast', done);
      },
      leave: function (element, done) {
        angular.element(element).slideDown('fast', done);
      }
    };
  }

  function slideIfFlex() {
    // Remember to include the slide-if class in the app.scss with the "display: none" otherwise the slideDown won't be smooth
    // slideUp would not be affected as the element would be already visible.

    return {
      enter: function (element, done) {
        angular.element(element).css('display', 'flex');
        angular.element(element).slideDown('fast', done);
      },
      move: function (element, done) {
        angular.element(element).slideDown('fast', done);
      },
      leave: function (element, done) {
        angular.element(element).slideUp('fast', done);
      }
    };
  }

  function slideLeft() {
    return {
      addClass: function (element, className, done) {
        var el = angular.element(element);
        el.css({
          display: 'block',
          transform: 'translate(-' + (el.offset().left + el.outerWidth()) + 'px, 0px)'
        });
        done();
      }
    };
  }

  function slideRight() {
    return {
      addClass: function (element, className, done) {
        angular.element(element).css({
          display: 'block',
          transform: 'translate(0px, 0px)'
        });
        done();
      }
    };
  }

  function opacityIf() {
    // Remember to include the slide-if class in the app.scss with the "display: none" otherwise the slideDown won't be smooth
    // slideUp would not be affected as the element would be already visible.

    return {
      enter: function (element, done) {
        angular.element(element).fadeIn(100, function (args) {
          element.css('opacity', 1);
          done(args);
        });
      },
      leave: function (element, done) {
        angular.element(element).fadeOut(100, function (args) {
          element.css('opacity', 0);
          done(args);
        });
      }
    };
  }

  function opacitySlowIf() {
    // Remember to include the slide-if class in the app.scss with the "display: none" otherwise the slideDown won't be smooth
    // slideUp would not be affected as the element would be already visible.

    return {
      enter: function (element, done) {
        element.css('display', 'none');
        angular.element(element).fadeIn(500, done);
      },
      leave: function (element, done) {
        angular.element(element).fadeOut(500, done);
      }
    };
  }
}());
