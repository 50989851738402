<span class="avatar-container" ng-class="{'avatar-container-small':userAvatar.isSmall(), 'avatar-container-large':!userAvatar.isSmall(), 'fixed-size': userAvatar.isFixedSize()}">
  <a ng-if="userAvatar.isLink" ui-sref="user.agreements({id: userAvatar.idUser})"
     ng-bind="userAvatar.initials" class="avatar"
     ng-class="{'avatar-picture':userAvatar.isImage(), 'avatar-initials':!userAvatar.isImage(),
     'avatar-small':userAvatar.isSmall(), 'avatar-large':!userAvatar.isSmall()
     }"
     ng-style="userAvatar.getStyle()"></a>
  <span ng-if="!userAvatar.isLink" ng-bind="userAvatar.initials" class="avatar"
        ng-class="{'avatar-picture':userAvatar.isImage(), 'avatar-initials':!userAvatar.isImage(),
        'avatar-small':userAvatar.isSmall(), 'avatar-large':!userAvatar.isSmall()
        }"
        ng-style="userAvatar.getStyle()"></span>
</span>
