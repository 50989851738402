
<div class="col-xs-12" ng-controller="PodCtrl as pod">
  <h2 class="hidden-xs" style="margin-top: 45px;">My Pod</h2>

  <div class="page-content" ng-show="!pod.list.records.length">
    <div class="row">
      <div class="col-xs-12">
        <div class="page-empty-section text-center">
          <i class="fa fa-users fa-2x text-light-colour"></i>
          <div class="page-empty-section-text">
            You have no people in your pod
          </div>
          <br/>
          Send invites to your business partners to join your pod. Click "Invite to my Pod" to start sending invites.
          <div class="page-empty-section-button">
            <a class="btn btn-primary" ui-sref="myPodSendInvite">Invite to my Pod</a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" ng-show="pod.list.records.length"  style="margin-top:30px;">
    <div class="col-md-4">
      <pod-members-list list="pod.list"></pod-members-list>
    </div>
    <div class="col-md-8 hidden-xs">
      <pod-earnings-graph list="pod.dailyStats"></pod-earnings-graph>
    </div>
  </div>

</div>

