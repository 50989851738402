(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name account.feedback.controller:FeedbackCtrl
   *
   * @description
   *
   */
  angular
    .module('account.feedback')
    .controller('FeedbackCtrl', FeedbackCtrl);

  function FeedbackCtrl($window, $uibModalInstance, Notification, Restangular, Session, FormAlerts, userProfile, helpRequired, configOptions) {
    var vm = this;
    vm.alerts = new FormAlerts();
    vm.isSending = false;
    vm.userProfile = userProfile;
    vm.isHelpRequired = helpRequired;
    vm.data = {
      appVersion: configOptions.appVersion,
      isMobileApp: $window.navigator.userAgent.indexOf('gonative') > -1 ? true : false,
      navigatorInfo: $window.navigator.userAgent,
      url: $window.document.location.href,
      feedback: null,
      name: null,
      email: null
    };

    vm.updateUserData = function () {
      // vm.userProfile should be available here at the time this is triggered.
      vm.data.name = vm.userProfile.fullName;
      vm.data.email = vm.userProfile.email;
    };

    vm.cancel = function () {
      $uibModalInstance.dismiss();
    };

    vm.send = function () {
      vm.isSending = true;
      vm.alerts.reset();
      Restangular.one('Feedback').post('index', vm.data)
        .then(function () {
          vm.isSaving = false;
          Notification({message: 'Success'}, 'confirmation');
          $uibModalInstance.close();
        }, function () {
          vm.isSaving = false;
          vm.alerts.add('danger', 'Unable to save the changes.');
        });
    };

    // User Profile can be provided as the uibModal (or route) resolve. If it's missing, we can always try to get the user from the session.
    if (angular.isUndefined(vm.userProfile) || vm.userProfile === null) {
      Session.getUserProfile().then(function (up) {
        vm.userProfile = up;
        if (vm.userProfile) {
          vm.updateUserData();
        }
      });
    }
    else {
      vm.updateUserData();
    }

    // Connect to the event of once the modal instance has rendered and apply focus to the textarea
    $uibModalInstance.rendered.then(function () {
      angular.element('#details')[0].focus();
    });
  }
}());
