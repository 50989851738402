(function () {
  'use strict';

  angular
    .module('myMemberships.receivedLeads')
    .config(config);

  function config($stateProvider, configOptions) {
    $stateProvider
      .state('myMemberships.receivedLeads', angular.extend({}, configOptions.defaultStateParams, {
        url: '/received-leads',
        templateUrl: 'my-memberships/my-memberships.tpl.html',
        controller: 'MyMembershipsCtrl',
        controllerAs: 'myMemberships',
        params: {
          leadToOpen: null
        },
        onEnter: ['RouterHelper', function (RouterHelper) {
          RouterHelper.activateTab('myMemberships', 'receivedLeads');
        }]
      }));
  }
}());
