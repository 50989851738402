<div class="marketplace no-select" ng-class="{'no-border': marketplace.items && marketplace.items.length == 1}">
  <!-- alerts -->
  <uib-alert ng-repeat="alert in marketplace.alerts.getList()" type="{{alert.type}}" close="marketplace.alerts.remove($index)">{{alert.message}}</uib-alert>

  <!-- items -->
  <div class="preferences-content"
       ng-if="marketplace.items.length > 0"
       ng-repeat="item in marketplace.items">
    <div class="preferences-content-title"  ng-click="marketplace.openInfo($index)">
      <div ng-if="marketplace.hasUserAvatar && item.owner" class="preferences-avatar hidden-sm hidden-xs">
        <user-avatar user="item.owner" size="small" fixed-size="false" is-link="false"></user-avatar>
      </div>
      <div ng-if="marketplace.hasUserAvatar && !item.owner" class="preferences-avatar hidden-sm hidden-xs">
        <div class="avatar-container"><img src="../images/user-placeholder.jpg" class="avatar avatar-small"></div>
      </div>
      <div class="marketplace-content cursor-pointer">
        <div>
          <a class="cursor-pointer marketplace-title">
            <div ng-if="marketplace.hasUserAvatar && item.owner" class="preferences-avatar hidden-lg hidden-md">
              <user-avatar user="item.owner" size="small" fixed-size="false" is-link="false"></user-avatar>
            </div>
            <div ng-if="marketplace.hasUserAvatar && !item.owner" class="preferences-avatar hidden-lg hidden-md">
              <div class="avatar-container"><img src="../images/user-placeholder.jpg" class="avatar avatar-small"></div>
            </div>
            <span>{{item.label}}</span>
          </a>
          <div class="marketplace-beans">
            <div class="marketplace-price">
              <span>Price</span>
              <span class="marketplace-value">{{item.isFree ? 'FREE' : (item.priceMonthly | currency:'$':0)}}</span>
            </div>
            <div ng-if="item.owner" class="marketplace-company">
              <span>Company</span>
              <span class="marketplace-value">{{item.owner.fullName + ((item.owner.businessName && item.owner.fullName !== item.owner.businessName) ? ' | ' + item.owner.businessName : '' )}}</span>
            </div>
          </div>
        </div>
        <div class="preferences-content-subtitle hidden-xs hidden-sm">
          <span ng-if="item.description">{{item.description}}</span>
        </div>
      </div>
    </div>
    <div class="preferences-tools">
      <div class="subscription-button" role="button" tabindex="1"
           ng-click="marketplace.switchSubscription($index)"
           ng-class="{'active': item.isSubscribed, 'disabled': marketplace.isLoading[$index]}">

        <div ng-if="!item.isSubscribed">
          <!--<i class="fa fa-plus"></i>-->
          <span>{{item.isFree ? 'FREE' : (item.priceMonthly | currency:'$':0)}}</span>
        </div>
        <div ng-if="item.isSubscribed">
          <!--<i class="fa fa-minus"></i>-->
          <span>Unsubscribe</span>
        </div>
      </div>
    </div>
    <div class="preferences-content-subtitle hidden-md hidden-lg" style="width: 100%;" ng-click="marketplace.openInfo($index)">
      <span ng-if="item.description">{{item.description}}</span>
    </div>

  </div>
</div>
