<div class="modal-content-container">
    <div class="row">
      <div class="col-xs-12">
        <span class="sub-title ng-binding">
          Create Reward Code
        </span>
      </div>
      <div class="col-xs-12">
        <div class="form-section-description">
          <span class="sub-title-description" aria-hidden="false">Provide the details of a Reward Code and share with other member(s) of MyLeadPod</span>
        </div>
      </div>
    </div>
    <div class="row">
      <uib-alert ng-repeat="alert in rewardProgram.alerts.getList()" type="{{alert.type}}" close="rewardProgram.alerts.remove($index)">{{alert.message}}</uib-alert>
      <div class="reward-program-form col-xs-12">
        <!-- Reward Code Details -->
        <div class="row">
          <div class="col-xs-12 form-section-description">
            Provide the Reward Code name and description
          </div>
        </div>
        <form name="rewardProgramForm">
          <div class="row form-group">
            <div class="col-xs-12">
              <div ng-class="{'has-error': (rewardProgramForm.$submitted || rewardProgramForm.rewardCode.$touched) &&
                            (rewardProgramForm.rewardCode.$error.required || rewardProgramForm.rewardCode.$error.maxlength ||
                            rewardProgramForm.rewardCode.$error.minlength || (rewardProgram.rewardCodeChecked && !rewardProgram.rewardCodeValid))}">

                <label class="sr-only" for="rewardCode">Reward Code</label>

                <div>
                  <div class="input-error" ng-show="rewardProgramForm.rewardCode.$error.required">Missing Reward Code</div>
                  <div class="input-error" ng-show="rewardProgramForm.rewardCode.$error.minlength">Reward Code must be at least 5 characters long</div>
                  <div class="input-error" ng-show="rewardProgramForm.rewardCode.$error.maxlength">Reward Code must not be longer than 20 characters</div>
                  <div class="input-error" ng-show="rewardProgram.rewardCodeChecked && !rewardProgram.rewardCodeValid">This Reward Code is not available, try another one</div>
                </div>

                <div class="input-group left-inner-addon">
                  <i ng-show="!rewardProgram.rewardCodeChecked && !rewardProgram.rewardCodeValidating && !rewardProgram.rewardCodeValid" class="fa fa-hashtag"></i>
                  <i ng-show="rewardProgram.rewardCodeValidating" class="fa fa-circle-o-notch fa-spin-2x"></i>
                  <i ng-show="!rewardProgram.rewardCodeValidating && rewardProgram.rewardCodeChecked && rewardProgram.rewardCodeValid" class="fa fa-check code-container-valid"></i>
                  <i ng-show="!rewardProgram.rewardCodeValidating && rewardProgram.rewardCodeChecked && !rewardProgram.rewardCodeValid" class="fa fa-times code-container-invalid"></i>

                  <input class="form-control input-lg" type="text" name="rewardCode" id="rewardCode"
                        placeholder="Reward Code"
                        ng-minlength="5" ng-maxlength="20"
                        size="20"
                        maxlength="20"
                        ng-change="rewardProgram.onRewardCodeChange()"
                        ng-blur="rewardProgram.validateRewardCode()"
                        ng-required="true" ng-model="rewardProgram.data.rewardCode"
                        popover-placement="'top'"
                        popover-trigger="focus"
                        popover-append-to-body="true"
                        popover-class="help"
                        uib-popover="{{rewardProgram.rewardCodeHelp}}" />

                  <div class="input-group-btn">
                    <button class="btn btn-primary btn-md" type="button" data-short-title="Check" data-title="Check Availability" ng-disabled="!rewardProgram.data.rewardCode" ng-click="rewardProgram.validateRewardCode()"></button>
                  </div>
                </div>
              </div>
            </div>
        </div>
          <div class="row">
          <div class="col-xs-12">
            <div class="control-description-subtle">
              Description is for your own reference only and it's not visible to public.
            </div>
            <div class="form-group">
              <label class="sr-only" for="description">Description</label>
              <textarea class="form-control" name="description" id="description" placeholder="Description"
                        ng-model="rewardProgram.data.description"></textarea>
            </div>
          </div>
        </div>
        </form>

        <!-- Reward Code -->
        <div class="sliding-details">
          <div class="sliding-header sliding-header-right">
            <span class="cursor-pointer no-outline" ng-click="rewardProgram.toggleMoreDetailsVisibility()">
              <i class="fa fa-chevron-down detail-arrow" ng-class="{'fa-chevron-down': !rewardProgram.detailsVisible, 'fa-chevron-up': rewardProgram.detailsVisible}"></i>
              Rewards
            </span>
            <i class="no-select info-help" role="button" tabIndex="0" style="margin-left: 15px;"
               popover-trigger="click, outsideClick"
               popover-append-to-body="true"
               popover-class="help"
               uib-popover="{{rewardProgram.rewardsHelp}}">?</i>
          </div>
          <div class="sliding-content slide-css" ng-class="{slide: rewardProgram.detailsVisible}">
            <div class="row">
              <div class="col-xs-12 form-section-description">
                Select reward type for this code
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12">
                <div class="form-group reward-system-selector" ng-class="{active: rewardProgram.data.rewardType === 'rewardsProgram'}" ng-click="rewardProgram.data.rewardType = 'rewardsProgram'">
                  <input type="radio" name="rewardType" id="rewardsProgram" value="rewardsProgram" ng-model="rewardProgram.data.rewardType" ng-required="true" />
                  <label class="radio-label" for="rewardsProgram">
                    Rewards Program <span class="cursor-pointer detail-arrow"
                                          ng-if="rewardProgram.data.rewardType === 'rewardsProgram'"
                                          ng-click="rewardProgram.toggleRewardsProgramDetailVisibility()">
                      <i class="fa fa-chevron-down"
                        ng-class="{'fa-chevron-down': !rewardProgram.isRewardsProgramDetailVisible, 'fa-chevron-up': rewardProgram.isRewardsProgramDetailVisible}"></i>
                    </span>
                    <div class="control-description-subtle pan">(You can modify Rewards Program anytime)</div>
                  </label>
                  <div class="slide-css" ng-class="{slide: rewardProgram.isRewardsProgramDetailVisible}">
                    <div class="reward-system-detail" ng-show="rewardProgram.data.rewardType === 'rewardsProgram'">
                      <span ng-if="rewardProgram.rewardsProgramSetup.length < 1">
                        Your Rewards Program is not set up. <a ui-sref="myMembershipsRewardProgramsSetup" target="_blank">Click here to manage</a>
                      </span>
                      <table class="rewards-program-options" ng-if="rewardProgram.rewardsProgramSetup.length > 0">
                        <tr ng-repeat="option in rewardProgram.rewardsProgramSetup | orderBy:'successfulLeadPrice'">
                          <td>{{option.name}}</td>
                          <td>{{option.successfulLeadPrice | currency:"$":0}}</td>
                        </tr>
                        <tr>
                          <td colspan="2">
                            <a ui-sref="myMembershipsRewardProgramsSetup" target="_blank">Click here to manage</a>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-xs-12">
                <div class="form-group reward-system-selector" ng-class="{active: rewardProgram.data.rewardType === 'customRewards'}" role="button" ng-click="rewardProgram.data.rewardType = 'customRewards'">
                  <input type="radio" name="rewardType" id="customRewards" value="customRewards" ng-model="rewardProgram.data.rewardType" ng-required="true" />

                  <label class="radio-label" for="customRewards">
                    Customise Rewards
                    <div class="control-description-subtle pan">(Once Reward Code is created, you cannot modify these custom payouts)</div>
                  </label>

                  <div class="reward-system-detail slide-css" ng-class="{slide: rewardProgram.data.rewardType === 'customRewards'}">

                    <div class="row form-group row-center-items animate-repeat" ng-repeat="option in rewardProgram.data.options track by option.uiId">

                      <div class="col-xs-12 col-sm-7">
                        <div ng-class="{'has-error': (rewardProgramForm.$submitted || rewardProgramForm.name{{option.uiId}}.$touched) && (rewardProgramForm.name{{option.uiId}}.$error.required)}">
                          <label class="sr-only" for="name{{option.uiId}}">Name</label>
                          <div ng-show="rewardProgramForm.$submitted || rewardProgramForm.name{{option.uiId}}.$touched">
                            <div class="input-error" ng-show="rewardProgramForm.name{{option.uiId}}.$error.required">Missing name</div>
                          </div>
                          <div class="left-inner-addon">
                            <i class="fa fa-pencil inner-addon"></i>
                            <input class="form-control input-lg" type="text" name="name{{option.uiId}}" id="name{{option.uiId}}"
                                  placeholder="Name" ng-required="true"
                                  ng-model="rewardProgram.data.options[$index].name" />
                          </div>
                        </div>
                      </div>

                      <div class="col-xs-10 col-sm-4">
                        <div ng-class="{'has-error': (rewardProgramForm.$submitted || rewardProgramForm.successfulLeadPrice{{option.uiId}}.$touched) && (rewardProgramForm.successfulLeadPrice{{option.uiId}}.$error.required || rewardProgramForm.successfulLeadPrice{{option.uiId}}.$error.number || rewardProgramForm.successfulLeadPrice{{option.uiId}}.$error.min)}">
                          <label class="sr-only" for="successfulLeadPrice{{option.uiId}}">Successful lead value</label>
                          <div ng-show="rewardProgramForm.$submitted || rewardProgramForm.successfulLeadPrice{{option.uiId}}.$touched">
                            <div class="input-error" ng-show="rewardProgramForm.successfulLeadPrice{{option.uiId}}.$error.number">Invalid successful lead value</div>
                            <div class="input-error" ng-show="rewardProgramForm.successfulLeadPrice{{option.uiId}}.$error.required">Missing successful lead value</div>
                            <div class="input-error" ng-show="rewardProgramForm.successfulLeadPrice{{option.uiId}}.$error.min">Successful lead value must not be negative</div>
                          </div>
                          <div class="left-inner-addon">
                            <i class="fa fa-dollar inner-addon"></i>
                            <input class="form-control input-lg" type="number" name="successfulLeadPrice{{option.uiId}}" id="successfulLeadPrice{{option.uiId}}"
                                  placeholder="Successful lead value" ng-required="true" min="0"
                                  ng-model="rewardProgram.data.options[$index].successfulLeadPrice" />
                          </div>
                        </div>
                      </div>

                      <div class="col-xs-2 col-sm-1 text-center">
                          <a class="remove-icon cursor-pointer" role="button" ng-click="rewardProgram.deleteOption($index)" ng-if="rewardProgram.data.options.length > 1"><i class="fa fa-times fa-medium-size"></i></a>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xs-12 text-xs-center">
                        <button type="button" class="btn btn-default" ng-click="rewardProgram.addOption()">
                          <i class="fa fa-plus"></i> Add option
                        </button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <!-- Sharing -->
        <div class="sliding-details" style="margin-top: 10px;">
          <div class="sliding-header sliding-header-right">
            <span class="cursor-pointer no-outline" ng-click="rewardProgram.toggleSharingVisibility()">
              <i class="fa fa-chevron-down detail-arrow" ng-class="{'fa-chevron-down': !rewardProgram.sharingVisible, 'fa-chevron-up': rewardProgram.sharingVisible}"></i>
              Sharing incoming leads
            </span>
            <i class="no-select info-help" role="button" tabIndex="0" style="margin-left: 15px;"
               popover-trigger="click, outsideClick"
               popover-append-to-body="true"
               popover-class="help"
               uib-popover="{{rewardProgram.shareHelp}}">?</i>
          </div>
          <div class="sliding-content slide-css" ng-class="{slide: rewardProgram.sharingVisible}">
            <div class="pending-overlay-light" ng-if="rewardProgram.searchingUser">
              <span class="fa fa-circle-o-notch fa-spin-2x"></span>
            </div>
            <div class="row slide-if" ng-if="rewardProgram.sharedUsers.length == 0">
              <div class="col-xs-12 form-section-description">
                Type in the email of an existing user with business subscription (max.1) (optional)
              </div>
              <div class="col-xs-12">
                <div class="form-group">
                  <search-autocomplete
                    control-id="share-autocomplete"
                    input-name="shareUsersInputSearch"

                    placeholder="Search Business"
                    minlength="3"
                    clear-selected="false"
                    text-searching="false"
                    text-no-results="false"

                    search-service="Search"
                    search-service-end-point="index"
                    remote-url-response-formatter="rewardProgram.searchResponseFormatter"
                    search-categories="rewardProgram.searchCategories"
                    selected-object="rewardProgram.onSearchSharedItemSelected"

                    data-field="content.records"
                    search-fields="search"
                    title-field="value"
                    description-field="categoryDescription"
                    description-field2="timeDescription"
                    category-field="category"

                    input-class="form-control input-lg"
                    match-class="autocomplete-highlight"
                    blur-on-select="true"
                    has-back-button="false"
                    update-input-on-select="false"
                    is-dropdown-relative="true"
                    override-suggestions="false"
                    field-required="false"
                    has-history="true" />
                </div>
              </div>
            </div>
            <div class="row slide-if" ng-if="rewardProgram.sharedUsers.length > 0">
              <div class="col-xs-12 form-group">
                <div class="no-associated-users">
                  This Reward Code will be shared with:
                </div>
                <div class="associated-users">
                  <div class="no-animate no-associated-users" ng-if="!rewardProgram.sharedUsers || rewardProgram.sharedUsers.length === 0">
                    There are no associated users to this Reward Code.
                  </div>
                  <div ng-repeat="user in rewardProgram.sharedUsers">
                    <table-user-details user="user" is-link="false" />
                    <div>
                      <button class="btn-sm btn-danger" ng-click="rewardProgram.removeUserShare()"><i class="fa fa-times"></i></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Associated Users -->
        <div class="sliding-details" style="margin-top: 10px;">
          <div class="sliding-header sliding-header-right">
            <span class="cursor-pointer no-outline" ng-click="rewardProgram.toggleAssociatedUsersVisibility()">
              <i class="fa fa-chevron-down detail-arrow" ng-class="{'fa-chevron-down': !rewardProgram.associatedUsersVisible, 'fa-chevron-up': rewardProgram.associatedUsersVisible}"></i>
              Associated Users
            </span>
            <i class="no-select info-help" role="button" tabIndex="0" style="margin-left: 15px;"
               popover-trigger="click, outsideClick"
               popover-append-to-body="true"
               popover-class="help"
               uib-popover="{{rewardProgram.associatedUsersHelp}}">?</i>
          </div>
          <div class="sliding-content slide-css" ng-class="{slide: rewardProgram.associatedUsersVisible}">
            <div class="row">
              <div class="col-xs-12 form-section-description">
                Search for the business users you'd like to associate this Reward Code with.
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12 form-group">
                <search-autocomplete
                  control-id="associated-autocomplete"
                  placeholder="Search Business"
                  minlength="3"
                  clear-selected="true"
                  text-searching="false"
                  text-no-results="false"

                  search-service="Search"
                  search-service-end-point="index"
                  remote-url-response-formatter="rewardProgram.searchResponseFormatter"

                  data-field="content.records"
                  selected-object="rewardProgram.onSearchItemSelected"

                  search-fields="search"
                  title-field="value"
                  description-field="categoryDescription"
                  description-field2="timeDescription"
                  category-field="category"

                  input-name="associateUsersInputSearch"
                  input-class="form-control input-lg"
                  match-class="autocomplete-highlight"
                  blur-on-select="true"
                  has-back-button="false"
                  update-input-on-select="true"

                  is-dropdown-relative="true"

                  override-suggestions="true"
                  search-categories="rewardProgram.searchCategories"
                  field-required="false"
                  has-history="true" />
                </div>
            </div>
            <div class="row">
              <div class="col-xs-12 form-group">
                <div>
                  Associated Users
                  <span ng-if="rewardProgram.searchingUser" style="display: inline-block; margin-left: 15px;" class="fa fa-circle-o-notch fa-spin-2x"></span>
                </div>
                <div class="associated-users">
                  <div class="no-animate no-associated-users" ng-if="!rewardProgram.associatedUsers || rewardProgram.associatedUsers.length === 0">
                    There are no associated users to this Reward Code.
                  </div>
                  <div ng-repeat="user in rewardProgram.associatedUsers">
                    <table-user-details user="user" is-link="false" />
                    <div class="" data-delete-btn="{{$index}}">
                      <!--<button class="btn-sm btn-primary"
                              ng-click="rewardProgram.associatedUsers.splice($index, 1)"
                              ng-blur="rewardProgram.hideButton($index, this, $event)">See Profile</button>-->
                      <button class="btn-sm btn-danger"
                              ng-click="rewardProgram.associatedUsers.splice($index, 1)"
                              ng-blur="rewardProgram.hideButton($index, this, $event)"><i class="fa fa-times"></i></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="row modal-content-buttons">
      <div class="col-xs-12 text-right">
        <button type="button" class="btn btn-default"
                ng-disabled="rewardProgram.isLoading" ng-click="rewardProgram.closeCreateCodeModal()">
          Cancel
        </button>
        <button type="button" class="btn btn-primary"
                ng-disabled="rewardProgramForm.$invalid || rewardProgram.isLoading || rewardProgram.rewardCodeValidating || !rewardProgram.rewardCodeChecked ||
                              (rewardProgram.rewardCodeChecked && !rewardProgram.rewardCodeValid) ||
                              (rewardProgram.data.rewardType === 'rewardsProgram' && rewardProgram.rewardsProgramSetup.length < 1)"
                ng-click="rewardProgram.create(rewardProgramForm)">
          {{rewardProgram.isLoading ? 'Loading ...' : 'Create'}}
        </button>
      </div>
    </div>
</div>
