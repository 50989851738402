(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name myMemberships.rewardProgram.controller:RewardProgramsSetupCtrl
   *
   * @description
   *
   */
  angular
    .module('myMemberships.rewardProgram')
    .controller('RewardProgramsSetupCtrl', RewardProgramsSetupCtrl);

  function RewardProgramsSetupCtrl($scope, $timeout, $filter, Notification, $state, FormAlerts, defaultOptions, RewardCodes) {
    var vm = this;

    vm.alerts = new FormAlerts();
    vm.isLoading = false;
    vm.rewardProgramHelp = 'This is where you can configure different cash rewards for different products and/or services you can link to your Reward Codes. Examples: Book Keeping $100, Tax Return $200, Financial Planning $300';

    vm.data = {
      options: []
    };

    function getIdForOption() {
      var d = new Date();
      return d.getTime();
    }

    function initData() {
      vm.data.options.push({
        name: null,
        successfulLeadPrice: null,
        uiId: getIdForOption()
      });
    }

    if ($state.params.openInvite) {
      RewardCodes.openWelcome();
    }

    // Subscribe to the update of the user profile.
    $scope.$on('event:user-updateLocalProfile', function () {
      // We cannot use state.reload as that would possibly open a WelcomeScreen again.
      $state.transitionTo('myMembershipsRewardProgramsSetup', {
        openInvite: false
      });
    });

    // Render reward program option
    vm.renderRewardProgramOptions = function (options) {
      if (options && options.length > 0) {
        angular.forEach(options, function (option) {
          var uiId = getIdForOption() * Math.random();
          vm.data.options.push(angular.extend({}, option, {
            uiId: uiId.toString().replace('.', '')
          }));
        });
        vm.data.options = $filter('orderBy')(vm.data.options, 'successfulLeadPrice');
      }
      else {
        initData();
      }
    };
    vm.renderRewardProgramOptions(defaultOptions);

    vm.addOption = function () {
      var optionId,
          idx,
          input;

      optionId = getIdForOption();

      vm.data.options.push({
        name: null,
        successfulLeadPrice: null,
        uiId: getIdForOption()
      });

      idx = vm.data.options.length - 1;

      $timeout(function () {
        // There might be two different inputs available. First try the desktop one. If visible, just focus it.
        input = angular.element('#name' + optionId + ':visible');
        if (input.length === 0) {
          // This must be the input hidden inside the panel (xs,sm devices)
          input = angular.element('#name2' + optionId);
          vm.tableSubContentVisible = {};
          vm.tableSubContentVisible[idx] = true;

          if (input.length === 1) {
            input.closest('.table-cell-sub-content-container').css('display', 'block');
            input[0].focus();
          }
        }
        else {
          input[0].focus();
        }
      }, 200);
    };

    vm.deleteOption = function ($index, form) {
      // This is to fix the shifting index in the xs,sm devices that keeps opening incorrect panels
      vm.tableSubContentVisible = {};
      // Remove the option from the data
      vm.data.options.splice($index, 1);

      if (form) {
        form.$setDirty();
      }
    };

    vm.save = function () {
      vm.isLoading = true;
      vm.alerts.reset();
      RewardCodes.saveDefaultRewardOptions(vm.data.options)
        .then(function () {
          RewardCodes.updateRewardProgramsAndCodes(true, function () {
            vm.isLoading = false;
            Notification({message: 'Success'}, 'confirmation');
          });
        }, function (errorCode) {
          if (errorCode === 609) {
            vm.alerts.add('danger', 'Unable to save: Rewards program is still being updated, please try again later.');
          }
          else {
            vm.alerts.add('danger', 'Unable to save');
          }
          vm.isLoading = false;
        });
    };

    vm.toggleSubContent = function (idx) {
      if (!vm.tableSubContentVisible) {
        vm.tableSubContentVisible = {};
        vm.tableSubContentVisible[idx] = true;
      }
      else if (_.contains(_.keys(vm.tableSubContentVisible), idx.toString())) {
        // Check if the incoming idx is already added, Just toggle it.
        vm.tableSubContentVisible[idx] = !vm.tableSubContentVisible[idx];
      }
      else {
        // Delete everything and set as new
        vm.tableSubContentVisible = {};
        vm.tableSubContentVisible[idx] = true;
      }
    };

    vm.openWelcomeScreen = function () {
      RewardCodes.openWelcome();
    };
  }
}());
