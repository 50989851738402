<div class="tabs-manager">
  <div class="hidden-sm hidden-md hidden-lg" ng-class="{'tab-open': tabsManager.isDropDownOpen}">
    <div ng-class="{'tab-open': tabsManager.isDropDownOpen}" ng-if="!tabsManager.blockXsRelocation">
      <action-toolbar class="visible-xs" at-target="{{tabsManager.target}}">
        <li ng-repeat="tab in tabsManager.tabsConfig"
            ng-class="{active: tabsManager.tabManager.isOpen(tab.value)}"
            ng-if="tab.condition ? tab.condition() : true"
            data-tab-name="{{tabsManager.tabId + '-' + tab.value}}"
            class="no-animate">
          <a class="no-animate"
             ng-if="tab.url"
             ui-sref="{{tab.url}}"
             ng-click="tabsManager.openTab(null, true, $event)" >
            {{tab.label}}
            <span class="no-animate" ng-if="tab.template" tab-additional-content html="tab.template"></span>
          </a>
          <a class="no-animate"
             ng-if="!tab.url"
             ng-click="tabsManager.openTab(tab.value, true, $event)"
             ng-class="{active: tabsManager.tabManager.isOpen(tab.value)}">
            {{tab.label}}
            <span class="no-animate" ng-if="tab.template" tab-additional-content html="tab.template"></span>
          </a>
        </li>
      </action-toolbar>
    </div>
    <div class="no-animate" ng-if="tabsManager.blockXsRelocation">
      <div class="tab-selector" ng-click="tabsManager.isDropDownOpen = !tabsManager.isDropDownOpen">
        <div class="tab-selected-value">
          {{tabsManager.getActiveLabel()}}
        </div>
        <span class="tab-selector-icon">
          <i class="glyphicon glyphicon-menu-hamburger"></i>
        </span>
      </div>
      <div class="tabs-container">
        <ul class="dropdown-menu slide-if" ng-if="tabsManager.isDropDownOpen">
          <li ng-repeat="tab in tabsManager.tabsConfig" ng-if="tab.condition ? tab.condition() : true" ng-click="tabsManager.isDropDownOpen = false">
            <a ng-if="tab.url" ui-sref="{{tab.url}}" ng-class="{active: tabsManager.tabManager.isOpen(tab.value)}">{{tab.label}}
              <span ng-if="tab.template" tab-additional-content html="tab.template"></span>
            </a>
            <a ng-if="!tab.url" ng-click="tabsManager.tabManager.openTab(tab.value)" ng-class="{active: tabsManager.tabManager.isOpen(tab.value)}">{{tab.label}}
              <span ng-if="tab.template" tab-additional-content html="tab.template"></span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <ul class="nav nav-tabs page-nav hidden-xs">
    <li ng-repeat="tab in tabsManager.tabsConfig" ng-if="tab.condition ? tab.condition() : true">
      <a ng-if="tab.url" ui-sref="{{tab.url}}" ng-class="{active: tabsManager.tabManager.isOpen(tab.value)}">{{tab.label}}
        <span ng-if="tab.template" tab-additional-content html="tab.template"></span>
      </a>
      <a ng-if="!tab.url" ng-click="tabsManager.openTab(tab.value)" ng-class="{active: tabsManager.tabManager.isOpen(tab.value)}">{{tab.label}}
        <span ng-if="tab.template" tab-additional-content html="tab.template"></span>
      </a>
    </li>
  </ul>
</div>
