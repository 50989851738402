(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name mlpMarketplace.service:Marketplace
   * @requires $document
   * @requires $uibModal
   * @requires Restangular
   * @requires Notification
   * @requires Session
   * @description
   *
   */
  angular
    .module('mlpMarketplace')
    .service('Marketplace', Marketplace);

  function Marketplace($rootScope, $document, $state, $uibModal, Restangular, Notification, Session) {
    var self = this;

    /**
     * Opens the modal dialog for the preferences settings
     *
     * @param {object} userProfile object User profile for which the preferences will be loaded
     */
    self.open = function () {
      $state.go('marketplace');
    };

    self.openInfo = function (item) {
      return $uibModal.open({
        animation: false,
        templateUrl: 'mlp-marketplace/marketplace-info-modal.tpl.html',
        controller: 'MarketplaceInfoCtrl',
        controllerAs: 'marketplaceInfo',
        size: 'lg',
        resolve: {
          marketplaceItem: function () {
            return item;
          }
        },
        appendTo: angular.element($document[0].body)
      }).result;
    };

    self.deactivate = function (item) {
      return Restangular.one('Marketplace').customDELETE('deactivate', {
        id: item.id
      });
    };

    /**
     * @ngdoc function
     * @name initiateActivation
     * @methodOf mlpMarketplace.service:Marketplace
     * @param {Object} item Marketplace item
     *
     * @returns {Promise} Modal dialog result
     */
    self.initiateActivation = function (item) {
      var modalInstance = $uibModal.open({
        animation: false,
        appendTo: angular.element($document[0].body),
        templateUrl: 'mlp-marketplace/marketplace-activation-modal.tpl.html',
        controller: 'MarketplaceActivationCtrl',
        controllerAs: 'marketplaceActivation',
        size: 'lg',
        backdrop: 'static',
        resolve: {
          marketplaceItem: function () {
            return item;
          }
        }
      });

      return modalInstance.result;
    };

    /**
     * @ngdoc function
     * @name activate
     * @methodOf mlpMarketplace.service:Marketplace
     * @param {Object} item Marketplace item
     * @param {Object} creditCard CC data
     * @returns {Promise} Restangular promise
     */
    self.activate = function (item, creditCard) {
      return Restangular.one('Marketplace').customPOST({
        id: item.id,
        creditCard: creditCard
      }, 'activate')
        .then(function (result) {
          var profile = Session.updateLocalUserProfile({
            marketplace: result.plain().marketplace
          }, true);
          Notification({message: 'Success'}, 'success');
          $rootScope.$broadcast('event:user-updateProfile', {profile: profile});
        });
    };
  }
}());
