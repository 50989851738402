<div class="col-xs-12" ng-controller="MembershipsCtrl as memberships">
  <div class="page-content">

    <div class="row" ng-hide="memberships.list.recordsExist() || !memberships.list.isInitialised()">
      <div class="col-xs-12">
        <div class="page-empty-section text-center">
          <i class="fa fa-users fa-2x text-light-colour"></i>
          <div class="page-empty-section-text">
            You don't have any connections
          </div>
          <br/>
          Join pods and start receiving leads. Click "Ask for Leads" to add your first connection.
        </div>
      </div>
    </div>

    <div class="row" ng-show="memberships.list.recordsExist() && memberships.list.isInitialised()">
      <div class="col-xs-12 col-sm-5 col-md-4">
        <div class="form-group page-filter">
          <label class="sr-only" for="searchName">Search</label>
          <div class="left-inner-addon">
            <i class="fa fa-search inner-addon"></i>
            <form name="membershipSearchForm" ng-submit="memberships.search()">
              <input class="form-control input-lg" type="text" name="searchName" id="searchName"
                     placeholder="Search" ng-model="memberships.searchName" ng-blur="memberships.search();" />
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive table-container" ng-show="memberships.list.recordsExist() && memberships.list.isInitialised()">
      <table st-table="memberships.list.getRecords()" st-pagination-scroll st-pipe="memberships.list.load" class="table page-content-table allow-wrap">
        <thead class="hidden-xs">
        <tr>
          <th class="col-sm-3">Pod owner</th>
          <th class="col-sm-5">Industry</th>
          <th class="col-sm-2">Join date</th>
          <th class="text-right col-sm-2">Reward type</th>
          <!--<th class="text-right col-sm-2">Leads received</th>-->
        </tr>
        </thead>
        <tbody ng-hide="memberships.list.isLoading() && !memberships.list.isLoadingMoreRecords()">
        <tr ng-repeat="row in memberships.list.getRecords()">
          <td class="col-xs-12 col-sm-3">
            <table class="table-cell-main-content">
              <tr class="row-center-items row-space-between">
                <td class="visible-xs" single-expand sub-content="memberships.tableSubContentVisible" sub-content-idx="$index"></td>
                <td>
                  <table-user-details user="row.owner"></table-user-details>
                </td>
                <td class="visible-xs text-right">
                  <span class="cursor-pointer">
                    <i class="fa fa-ellipsis-h"></i>
                  </span>
                </td>
              </tr>
            </table>
            <div class="visible-xs">
              <div class="table-cell-sub-content-container slide-if" ng-if="memberships.tableSubContentVisible[$index]">
                <div class="table-cell-sub-content">
                  <span class="text-muted">Industry</span><br/>
                  <span class="text-normal">{{row.owner.industry.name}}</span>
                  <span class="text-normal" ng-if="row.owner.subIndustry"><br/>{{row.owner.subIndustry.name}}</span>
                </div>
                <div class="table-cell-sub-content">
                  <span class="text-muted">Join date</span><br/>
                  <span class="text-normal">{{row.startDate | date:'d MMM yyyy'}}</span>
                </div>
                <div class="table-cell-sub-content">
                  <span class="text-muted">Reward type</span><br/>
                  <span class="text-normal">{{row.contractType}}</span>
                </div>
                <!--<div class="table-cell-sub-content visible-xs">-->
                  <!--<span class="text-muted">Leads received</span><br/>-->
                  <!--<span class="text-normal">{{row.leadsReceived}}</span>-->
                <!--</div>-->
              </div>
            </div>
          </td>
          <td class="col-sm-5 hidden-xs">
            {{row.owner.industry.name}}
            <span ng-if="row.owner.subIndustry">
              <br/>{{row.owner.subIndustry.name}}
            </span>
          </td>
          <td class="col-sm-2 hidden-xs">{{row.startDate | date:'d MMM yyyy'}}</td>
          <td class="col-sm-2 hidden-xs text-right">{{row.contractType}}</td>
          <!--<td class="col-sm-2 hidden-xs text-right">{{row.leadsReceived}}</td>-->
        </tr>
        </tbody>
        <tbody ng-if="memberships.list.isLoading()">
        <tr>
          <td colspan="5" class="col-xs-12 text-center">
            <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="row" ng-show="!memberships.list.isInitialised()">
      <div class="col-xs-12 text-center">
        <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>
      </div>
    </div>
  </div>
</div>

