(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name account.service:BusinessAccount
   *
   * @description
   *
   */
  angular
    .module('account')
    .service('BusinessAccount', BusinessAccount);

  function BusinessAccount($rootScope, $document, $q, $uibModal, Restangular, Auth, Session, Cache, authEvents) {
    var self = this,
        userProfile = Session.getUserProfile(),
        loginWatchHandle = null;

    // Get the cache that keeps the businessSuggestion records at the init of this service.
    self.suggestionCache = Cache.create('businessSuggestion');

    // Subscribe to the update of the user profile.
    loginWatchHandle = $rootScope.$on(authEvents.loginSuccess, function () {
      userProfile = Session.getUserProfile();
    });

    // Remove the handle once it's all destroyed
    $rootScope.$on('$destroy', loginWatchHandle);

    function openBusinessAccountPopup(domNode, isTrial) {
      var modalInstance = $uibModal.open({
        animation: false,
        appendTo: domNode,
        templateUrl: isTrial ? 'account/business-account-trial.tpl.html' : 'account/business-account.tpl.html',
        controller: 'BusinessAccountCtrl',
        controllerAs: 'businessAccount',
        size: 'md',
        windowClass: 'modal-business-account',
        backdrop: 'static',
        resolve: {
          userProfile: function () {
            return Session.getUserProfile();
          },
          pricingDetails: function () {
            return Restangular.one('BusinessAccount').one('pricingDetails').get();
          },
          isTrial: function () {
            return isTrial;
          }
        }
      });

      return modalInstance;
    }

    function getDomNode(n) {
      var dn = null;
      if (angular.isUndefined(n)) {
        console.error('The target domNode is not specified.');
        return null;
      }

      if (angular.isString(n)) {
        dn = angular.element($document[0].getElementById(n));
      }
      else if (n.nodeName) {
        dn = angular.element(n);
      }
      else {
        console.error('Unsupported type for the target domNode. Please provide the ID or the HTMLElement reference');
        return null;
      }
      return dn;
    }

    /**
     * Activates user's business account
     *
     * @param {Object} data        New profile data
     * @param {Bool}   skipPayment Flag indicating if payment can be skipped
     * @return {Deferred} Deferred promise
     */
    self.activate = function (data, skipPayment) {
      var deferred = $q.defer(),
          action = 'activate';

      if (!angular.isObject(data)) {
        data = {};
      }

      if (angular.isUndefined(skipPayment) && skipPayment !== true && skipPayment !== false) {
        skipPayment = false;
      }

      if (skipPayment === true) {
        action = 'activateWithoutPayment';
      }

      Restangular.one('BusinessAccount').customPUT(data, action)
        .then(function (result) {
          var profile = result.profile;
          profile.isBusinessAccountActive = result.isBusinessAccountActive;
          Session.create(profile);
          $rootScope.$broadcast('event:user-updateProfile', {profile: profile});
          deferred.resolve(profile);
        }, function (responseData) {
          deferred.reject({
            errorCode: responseData.data.errorCode,
            errorMessage: responseData.data.errorMessage
          });
        });

      return deferred.promise;
    };

    self.activateTrial = function (data) {
      var deferred = $q.defer(),
          action = 'activateTrial';

      if (!angular.isObject(data)) {
        data = {};
      }

      Restangular.one('BusinessAccount').customPUT(data, action)
        .then(function (result) {
          var profile = result.profile;
          profile.isBusinessAccountActive = result.isBusinessAccountActive;
          Session.create(profile);
          $rootScope.$broadcast('event:user-updateProfile', {profile: profile});
          deferred.resolve(profile);
        }, function (responseData) {
          deferred.reject({
            errorCode: responseData.data.errorCode,
            errorMessage: responseData.data.errorMessage
          });
        });

      return deferred.promise;
    };

    /**
     * Initiate activation
     *
     * @param {string | HTMLElement} domNode Id of the dom node or the HTML Element directly into which the popup will be populated
     * @param {bool} isTrial If the template to be loaded should be the one for the TRIAL activation (evaluation subscription)
     * @return {IPromise<T>} Deferred promise
     */
    self.initiateActivation = function (domNode, isTrial) {
      var deferred = $q.defer(),
          dn = getDomNode(domNode);

      if (!dn) {
        throw new Error('The target domNode for the popup is not specified or is unrecognized.');
      }

      userProfile.then(function (profile) {
        if (Auth.hasUserPermission(profile, 'activateBusinessAccountWithoutPayment')) {
          self.activate({}, true)
            .then(function (result) {
              deferred.resolve(result);
            }, function () {
              deferred.reject();
            });
        }
        else {
          openBusinessAccountPopup(dn, isTrial).result.then(function (result) {
            if (result) {
              deferred.resolve(result);
            }
            else {
              deferred.reject();
            }
          }, function () {
            deferred.reject();
          });
        }
      });

      return deferred.promise;
    };

    /**
     * Returns the suggestions count
     * @returns {number} Suggestions count
     */
    self.getSuggestionsCount = function () {
      var count = 0;
      if (angular.isDefined(self.suggestionCache) && self.suggestionCache.get('count')) {
        count = self.suggestionCache.get('count');
      }
      return count;
    };

    /**
     * Returns trial suggestions count
     * @returns {number} Trial suggestions count
     */
    self.getTrialSuggestionsCount = function () {
      var count = 0;
      if (angular.isDefined(self.suggestionCache) && self.suggestionCache.get('trialCount')) {
        count = self.suggestionCache.get('trialCount');
      }
      return count;
    };

    /**
     * Updates the suggestion count in the cache.
     * @param {number} count Initial count
     */
    self.updateSuggestionCount = function (count) {
      if (angular.isDefined(self.suggestionCache)) {
        self.suggestionCache.put('count', count + 1);
        self.suggestionCache.put('trialCount', count + 1);
      }
    };

    /**
     * Updates trial suggestions count
     * @param {number} count Initial count
     */
    self.updateTrialSuggestionCount = function (count) {
      if (angular.isDefined(self.suggestionCache)) {
        self.suggestionCache.put('trialCount', count + 1);
      }
    };

    /**
     * Returns the trial data
     * @param {Object} profile User's profile
     * @returns {Object} Trial data
     */
    self.getTrialData = function (profile) {
      var data;

      if (!profile) {
        return {};
      }

      data = {
        isEligibleForTrial: profile.businessAccount.isEligibleForTrial,
        isInTrial: profile.businessAccount.isInTrial,
        expiresIn: profile.businessAccount.trialExpiresInDays,
        trialWarning: profile.businessAccount.trialExpiresInDays <= 7,
        trialExpiresIn: null,
        trialExpiryDate: new Date(profile.businessAccount.trialExpiryDate)
      };

      if (data.isInTrial) {
        if (data.expiresIn === 0) {
          data.trialExpiresIn = ' expires today';
        }
        else if (data.expiresIn === 1) {
          data.trialExpiresIn = ' will expire in 1 day';
        }
        else {
          data.trialExpiresIn = ' will expire in ' + data.expiresIn + ' days';
        }
      }

      return data;
    };
  }
}());
