<div class="row" ng-if="!resetCtrl.showMessage">
  <div class="col-md-12 text-center">
    <h1 class="page-header sign-up-page-header">Set New Password</h1>
  </div>
</div>

<div class="row sign-up-page-container" ng-if="!resetCtrl.showMessage">
  <div class="sign-in-page-content text-center activate-form-content">
    <uib-alert ng-repeat="alert in resetCtrl.alerts.getList()" type="{{alert.type}}" close="resetCtrl.alerts.remove($index)">{{alert.message}}</uib-alert>
    <form name="passwordResetForm">
      <div class="form-group" ng-class="{'has-error': (passwordResetForm.$submitted || passwordResetForm.password.$touched) && passwordResetForm.password.$error.required}">
        <label class="sr-only" for="password">New Password</label>
        <div ng-show="passwordResetForm.$submitted || passwordResetForm.password.$touched">
          <div class="input-error" ng-show="passwordResetForm.password.$error.required">Missing password.</div>
        </div>
        <div class="left-inner-addon">
          <i class="fa fa-lock inner-addon"></i>
          <input class="form-control input-lg" type="password" name="password" id="password"
                 placeholder="New password" ng-required="true" ng-model="resetCtrl.data.password" />
        </div>
      </div>

      <div class="form-group" ng-class="{'has-error': (passwordResetForm.$submitted || passwordResetForm.confirmPassword.$touched) && (passwordResetForm.confirmPassword.$error.required || passwordResetForm.confirmPassword.$error.match)}">
        <label class="sr-only" for="confirmPassword">Confirm new password</label>
        <div ng-show="passwordResetForm.$submitted || passwordResetForm.confirmPassword.$touched">
          <div class="input-error" ng-show="passwordResetForm.confirmPassword.$error.required">Missing password.</div>
          <div class="input-error" ng-show="passwordResetForm.confirmPassword.$error.match">Passwords do not match.</div>
        </div>
        <div class="left-inner-addon">
          <i class="fa fa-lock inner-addon"></i>
          <input class="form-control input-lg" type="password" name="confirmPassword" id="confirmPassword"
                 placeholder="Confirm new password" ng-required="true" ng-model="resetCtrl.data.confirmPassword"
                 match="resetCtrl.data.password" />
        </div>
      </div>

      <div class="form-group sign-up-page-btn-create">
        <button type="button" class="btn btn-primary btn-lg btn-block"
                ng-disabled="passwordResetForm.$invalid || resetCtrl.isLoading" ng-click="resetCtrl.reset()">
          {{resetCtrl.isLoading ? 'Loading ...' : 'Set Password'}}
        </button>
      </div>
    </form>
  </div>
</div>

<div class="row success-message-container" ng-if="resetCtrl.showMessage">
  <div class="col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 text-center">
    <div class="panel panel-default">
      <div class="panel-body">
        <h2>Password Reset</h2>
        <div class="panel-body-subtitle">
          Your password has been successfully updated!
        </div>
        <div class="sign-up-page-btn-create">
          <a ui-sref="accountSignInUp.accountSignIn" type="button" class="btn btn-primary btn-lg btn-block">Login</a>
        </div>
      </div>
    </div>
  </div>
</div>

