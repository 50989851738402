(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name account.profile.controller:DeactivateModalCtrl
   *
   * @description
   *
   */
  angular
    .module('account.preferences')
    .controller('DeactivateModalCtrl', DeactivateModalCtrl);

  function DeactivateModalCtrl($rootScope, $state, $timeout, $uibModalInstance, Restangular, Notification, Auth, userProfile, Session) {
    var vm = this;
    vm.isDeactivating = false;
    vm.isBusinessAccount = false;
    vm.timeOut = Auth.localSignoutTimeout + 10;
    vm.user = angular.copy(userProfile);

    if (vm.user && vm.user.isBusinessAccountActive) {
      vm.isBusinessAccount = true;
    }

    vm.data = {
      wholeAccount: !vm.isBusinessAccount,
      message: ''
    };

    vm.deactivate = function () {
      vm.isDeactivating = true;

      if (vm.data.wholeAccount) {
        Restangular.one('Users').customPUT({message: vm.data.feedback}, 'deactivate').then(function () {
          Notification({message: 'Deactivation was successful'}, 'confirmation');
          signOutAndRedirect('accountSignInUp.accountSignIn');
        }).catch(function () {
          Notification({message: 'Unable to deactivate your account, please try again or contact us.'}, 'error');
          $uibModalInstance.close();
        });
      }
      else {
        // Use the business deactivation api
        Restangular.one('BusinessAccount').customPUT({message: vm.data.feedback}, 'deactivate').then(function (result) {
          var profile = result.profile;
          profile.isBusinessAccountActive = result.isBusinessAccountActive;
          Session.create(profile);
          $rootScope.$broadcast('event:user-updateProfile', {profile: profile});
          Notification({message: 'Deactivation was successful'}, 'confirmation');
          $state.go('dashboard');
          $uibModalInstance.close();
        }).catch(function () {
          Notification({message: 'Unable to deactivate your business account account, please try again or contact us.'}, 'error');
          $uibModalInstance.close();
        });
      }
    };

    function signOutAndRedirect(redirectTo) {
      Auth.signOut(false);

      // We have to do this in the timeout as the AUTH service uses timeout as well due to some rendering issues.
      $timeout(function () {
        $state.go(redirectTo);
        $uibModalInstance.close();
      }, vm.timeOut);
    }

    vm.close = function () {
      $uibModalInstance.close();
    };
  }
}());
