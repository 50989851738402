<div class="row">
  <div class="col-xs-12 col-sm-6">
    <h1 class="page-header">Dashboard</h1>
  </div>
  <div class="col-sm-6 hidden-xs">
    <div class="page-header-buttons">
      <a ui-sref="myPodSendInvite" class="btn btn-primary btn-shadow btn-lg-lg">Invite to my Pod</a>
      <a ui-sref="leadSend" class="btn btn-success btn-shadow btn-lg-lg">Send Lead</a>
    </div>
  </div>
</div>

<action-toolbar class="visible-xs" at-target="secondary">
  <a class="no-animate fa fa-envelope" label="Invite to my Pod" ui-sref="myPodSendInvite"><span class="tool-label">Invite to my Pod</span></a>
  <a class="no-animate fa fa-paper-plane" label="Send Lead" ui-sref="leadSend"><span class="tool-label">Send Lead</span></a>
</action-toolbar>

<div class="dashboard-highlight" ng-include="'dashboard/stats/stats.tpl.html'"></div>

<div ng-if="dashboard.isBusinessAccountActive()" class="dashboard-memberships page-section row" ng-include="'dashboard/memberships/memberships.tpl.html'"></div>

<div class="dashboard-pod page-section row" ng-include="'dashboard/pod/pod.tpl.html'"></div>





