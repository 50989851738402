(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name lead.service:Leads
   *
   * @description
   *
   */
  angular
    .module('mlp-messaging')
    .service('Messaging', Messaging);

  function Messaging($rootScope, $document, $state, $q, Notification, $uibModal, Restangular) {
    var self = this;

    self.getMessages = function (params) {
      return Restangular.one('Messages').one('index').get(params)
        .then(function (result) {
          return result.plain();
        }, function (error) {
          return {
            error: error
          };
        });
    };

    self.postMessage = function (idUser, params) {
      return Restangular.one('Messages').post('index', params, {idUser: idUser})
        .then(function (result) {
          return result.plain();
        }, function (error) {
          return {
            error: error
          };
        });
    };
  }
}());
