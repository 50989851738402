(function () {
  'use strict';

  angular
    .module('myMemberships.rewardProgram')
    .config(config);

  function config($stateProvider, configOptions) {
    $stateProvider
      .state('myMemberships.rewardPrograms', angular.extend({}, configOptions.defaultStateParams, {
        url: '/reward-programs',
        templateUrl: 'my-memberships/my-memberships.tpl.html',
        controller: 'MyMembershipsCtrl',
        controllerAs: 'myMemberships',
        onEnter: ['RouterHelper', function (RouterHelper) {
          RouterHelper.activateTab('myMemberships', 'rewardPrograms');
        }]
      }))
      .state('myMembershipsRewardProgramsSetup', angular.extend({}, configOptions.defaultStateParams, {
        url: '/my-memberships/reward-programs/setup',
        templateUrl: 'my-memberships/reward-program/setup.tpl.html',
        controller: 'RewardProgramsSetupCtrl',
        controllerAs: 'rewardProgramsSetup',
        params: {
          openInvite: {
            value: false,
            dynamic: true
          }
        },
        resolve: {
          defaultOptions: ['Session', function (Session) {
            return Session.getUserProfile().then(function (user) {
              return user.defaultRewardProgram;
            });
          }]
        }
      }))
      .state('myMembershipsRewardProgramCode', {
        url: '/reward-program/join/{code}',
        resolve: {
          rewardCode: ['$state', '$stateParams', 'Restangular', 'Notification', 'Auth', function ($state, $stateParams, Restangular, Notification, Auth) {
            Restangular.one('RewardCodes').one('viewsCounter').get({rewardCode: $stateParams.code});
            Auth.isAuthenticated()
              .then(function (isAuthenticated) {
                if (isAuthenticated) {
                  Restangular.one('RewardCodes').post('activate', {
                    rewardCode: $stateParams.code
                  })
                    .then(function (result) {
                      if (angular.isObject(result) && angular.isDefined(result.idUser) && result.idUser > 0) {
                        Notification({message: 'You are now joined with this business!'}, 'success');
                        $state.go('user.agreements', {id: result.idUser});
                      }
                      else {
                        Notification({message: 'The Reward Code does not exist or is no longer valid.'}, 'warning');
                        $state.go('dashboard');
                      }
                    }, function () {
                      $state.go('dashboard');
                    });
                }
                else {
                  $state.go('accountSignInUp.accountSignUp', {rewardCode: $stateParams.code});
                }
              }, function () {
                $state.go('accountSignInUp.accountSignUp', {rewardCode: $stateParams.code});
              });
          }]
        }
      });
  }
}());
