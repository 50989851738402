<form name="joinPodForm">
  <div class="row">
    <div class="col-xs-12">
      Please enter the details of the person you are asking for the leads
    </div>
  </div>

  <div class="row form-section-body">
    <div class="col-xs-12">
      <div class="form-group" ng-class="{'has-error': (joinPodForm.$submitted || joinPodForm.firstName.$touched) && joinPodForm.firstName.$error.required}">
        <label class="sr-only" for="firstName">First name</label>
        <div ng-show="joinPodForm.$submitted || joinPodForm.firstName.$touched">
          <div class="input-error" ng-show="joinPodForm.firstName.$error.required">Missing first name.</div>
        </div>
        <div class="left-inner-addon">
          <i class="fa fa-user inner-addon"></i>
          <input class="form-control input-lg" type="text" name="firstName" id="firstName"
                  placeholder="First name" ng-required="true" ng-model="joinPod.data.firstName" />
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12">
      <div class="form-group" ng-class="{'has-error': (joinPodForm.$submitted || joinPodForm.email.$touched) && (joinPodForm.email.$error.required || joinPodForm.email.$error.email)}">
        <label class="sr-only" for="email">Email</label>
        <div ng-show="joinPodForm.$submitted || joinPodForm.email.$touched">
          <div class="input-error" ng-show="joinPodForm.email.$error.required">Missing email.</div>
          <div class="input-error" ng-show="joinPodForm.email.$error.email">This is not a valid email.</div>
        </div>
        <div class="left-inner-addon">
          <i class="fa fa-envelope-o inner-addon"></i>
          <input class="form-control input-lg" type="email" name="email" id="email" placeholder="Email"
                 ng-required="true" ng-model="joinPod.data.email" />
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12">
      <div class="form-group" ng-class="{'has-error': (joinPodForm.$submitted || joinPodForm.phoneMobile.$touched) && (joinPodForm.phoneMobile.$error.required || joinPodForm.phoneMobile.$error.email)}">
        <label class="sr-only" for="email">Phone</label>
        <div ng-show="joinPodForm.$submitted || joinPodForm.phoneMobile.$touched">
          <div class="input-error" ng-show="joinPodForm.phoneMobile.$error.required">Missing phone number.</div>
        </div>
        <div class="left-inner-addon">
          <i class="fa fa-phone inner-addon"></i>
          <input class="form-control input-lg" type="tel" name="phoneMobile" id="phoneMobile" placeholder="Phone Number (optional)"
                 ng-required="false" ng-model="joinPod.data.phoneMobile" />
        </div>
      </div>
    </div>
  </div>

</form>
