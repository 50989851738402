(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name account.service:AccountService
   *
   * @description
   *
   */
  angular
    .module('account')
    .service('AccountService', AccountService);

  function AccountService($uibModal, $document, Session) {
    var scope = this;

    /**
     * Opens the modal dialog for the preferences settings
     *
     * @param {object} userProfile object User profile for which the preferences will be loaded
     * @returns {object} UibModalInstance result
     */
    scope.openPreferences = function (userProfile) {
      var modalInstance,
          params = {
            animation: false,
            templateUrl: 'account/preferences/preferences-modal.tpl.html',
            controller: 'PreferencesCtrl',
            controllerAs: 'preferences',
            size: 'lg',
            resolve: {
              userProfile: function () {
                return userProfile;
              }
            },
            appendTo: angular.element($document[0].body)
          };

      modalInstance = $uibModal.open(params);
      return modalInstance.result;
    };

    scope.openFeedback = function (userProfile, helpRequired) {
      var modalInstance,
          params = {
            animation: false,
            templateUrl: 'account/feedback/feedback-modal.tpl.html',
            controller: 'FeedbackCtrl',
            controllerAs: 'feedback',
            size: 'lg',
            resolve: {
              userProfile: function () {
                if (userProfile) {
                  return userProfile;
                }
                return Session.getUserProfile();
              },
              helpRequired: function () {
                return helpRequired;
              }
            },
            appendTo: angular.element($document[0].body)
          };

      modalInstance = $uibModal.open(params);
      return modalInstance.result;
    };
  }
}());
