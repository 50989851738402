<div class="modal-content-container">
  <div class="row">
    <div class="col-xs-12">
      <div class="row">
        <div class="col-xs-2 text-right">
          <span ng-if="rewardCodeDetail.rewardCode.isArchived" class="description-tag">Archived</span>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 lead-detail-row reward-code-title">
          <div>
            <i class="fa fa-hashtag" title="Reward Code Name"></i>
            <div>
              {{rewardCodeDetail.rewardCode.rewardCode}}
            </div>
          </div>
          <div>
            <div>
              <i class="fa fa-eye" title="Number of views"></i>
              <div>{{rewardCodeDetail.rewardCode.viewsCounter}}</div>
            </div>

            <div>
              <i class="fa fa-refresh" title="Number of registrations through this Reward Code"></i>
              <div>{{rewardCodeDetail.rewardCode.registrationsCounter}}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 rcd-row">
          <!-- Title -->
          <div class="rc-title">Share on Social Media</div>

          <!-- Primary Row -->
          <div class="rc-value flex-container">
            <div class="social-links">
              <a class="reward-share-link" ng-click="rewardCodeDetail.share('facebook')"><i class="fa fa-facebook-square fa-3x"></i></a>
              <a class="reward-share-link" ng-click="rewardCodeDetail.share('linkedin')"><i class="fa fa-linkedin-square fa-3x"></i></a>
              <a class="reward-share-link" ng-click="rewardCodeDetail.share('twitter')"><i class="fa fa-twitter-square fa-3x"></i></a>
              <a class="reward-share-link" ng-click="rewardCodeDetail.share('google')"><i class="fa fa-google-plus-square fa-3x"></i></a>
              <a class="reward-share-link" ng-click="rewardCodeDetail.embed()" title="Embed"><i class="fa fa-code fa-3x"></i></a>
              <a class="reward-share-link-fake" ng-if="rewardCodeDetail.shortUrl" href="mailto:?subject=Join us at MyLeadPod&body={{rewardCodeDetail.emailBody}}" title="Embed"><i class="fa fa-envelope fa-2x"></i></a>
            </div>
            <div>
              <a ng-click="rewardCodeDetail.linksVisible = !rewardCodeDetail.linksVisible;" class="btn-sm btn-primary cursor-pointer">
                <i class="fa fa-chevron-down" ng-class="{'rotate-180': rewardCodeDetail.linksVisible}" style="transition: transform 0.2s ease-in-out;"></i>
                <span>Get Links</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="row slide-if" ng-if="rewardCodeDetail.linksVisible" >
        <div class="col-xs-12 rcd-row">
          <div style="width: 100%;">
            <div class="rcd-inner-row">
              <div class="rc-title">Link</div>
              <div class="rc-value flex-container">
                <span style="flex:1;">{{rewardCodeDetail.getUrl()}}</span>
                <a class="action-icon-link cursor-pointer" ngclipboard data-clipboard-text="{{rewardCodeDetail.getUrl()}}"
                   uib-tooltip="Click to copy" tooltip-placement="left" ngclipboard-success="rewardCodeDetail.onSuccess(e);">
                  <i class="fa fa-clipboard"></i>
                </a>
              </div>
            </div>

            <div class="rcd-inner-row">
              <div class="rc-title">Link (short)</div>
              <div class="rc-value flex-container">
                <span style="flex:1;">{{rewardCodeDetail.shortUrl}}</span>
                <a class="action-icon-link cursor-pointer" ngclipboard data-clipboard-text="{{rewardCodeDetail.shortUrl}}"
                   uib-tooltip="Click to copy" tooltip-placement="left">
                  <i class="fa fa-clipboard"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 rcd-row">
          <div class="rc-title">Available Rewards</div>
          <div class="rc-value" style="align-self: center;">
            <div class="rewards-program-card" ng-repeat="option in rewardCodeDetail.rewardCode.pricingOptions | orderBy:'successfulLeadPrice'">
              <!--<br ng-if="$index > 0" />-->
              <div class="card-desc">{{option.name}}</div>
              <div class="card-separator">..............................................................................................................................</div>
              <div class="card-value">{{option.successfulLeadPrice | currency:"$":0}}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 rcd-row">

          <!-- Pending overlay-->
          <div class="pending-overlay-white pending-overlay-column" ng-if="rewardCodeDetail.isSavingDescription">
            <span class="fa fa-circle-o-notch fa-spin-2x"></span>
            <div class="pending-overlay-description">Saving description...</div>
          </div>

          <!-- Title -->
          <div class="rc-title">Description</div>

          <!-- Value -->
          <div class="rc-value">
            <!-- Primary Row -->
            <div style="position: relative; display: flex; align-items: center; justify-content: space-between; flex-wrap: nowrap;">
              <div style="align-items: center; flex-grow: 1;">
                <span ng-if="!rewardCodeDetail.rewardCode.description" class="text-muted">No description</span>
                <span ng-if="rewardCodeDetail.rewardCode.description" ng-bind-html="rewardCodeDetail.rewardCode.description | nl2br"></span>
              </div>
              <div>
                <a class="action-icon-link cursor-pointer no-animate"
                   ng-click="rewardCodeDetail.editDescriptionVisible = true;"
                   ng-if="!rewardCodeDetail.editDescriptionVisible"
                   uib-tooltip="Click to edit"
                   tooltip-placement="left"><i class="fa fa-pencil"></i></a>

                <button type="button"
                        class="btn-sm btn-primary"
                        style="margin: 1px 0px;"
                        ng-disabled="rewardCodeDetail.isSavingDescription"
                        ng-if="rewardCodeDetail.rewardCode.originalDescription != rewardCodeDetail.data.description"
                        ng-click="rewardCodeDetail.saveDescription()">Save</button>
                <button type="button"
                        class="btn-sm btn-primary"
                        style="margin: 1px 0px;"
                        ng-if="rewardCodeDetail.editDescriptionVisible"
                        ng-click="rewardCodeDetail.editDescriptionVisible = false">Cancel</button>
              </div>
            </div>

            <!-- Edit Row -->
            <div class="slide-if" ng-if="rewardCodeDetail.editDescriptionVisible">
              <div style="position: relative; display: flex; align-items: center; justify-content: space-between;">
                <i class="col fa"></i>
                <div class="" style="flex-grow: 1; margin-top: 10px;">

                  <div ng-if="rewardCodeDetail.editDescriptionError">
                    <div class="input-error">An error occurred, try again please.</div>
                  </div>
                  <label class="sr-only" for="description">Description</label>
                  <textarea class="form-control" name="description" id="description" placeholder="Description"
                            ng-model="rewardCodeDetail.data.description" msd-elastic></textarea>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <!--<div class="row" ng-if="rewardCodeDetail.shortUrl">
        <div class="col-xs-12 rcd-row">
          <div class="flex-container">
            <i class="col fa fa-link" title="Reward Code URL link (short)"></i>
            <span style="flex:1;">{{rewardCodeDetail.shortUrl}}</span>
            <a class="action-icon-link cursor-pointer" ngclipboard data-clipboard-text="{{rewardCodeDetail.shortUrl}}"
               uib-tooltip="Click to copy" tooltip-placement="bottom">
              <i class="fa fa-clipboard"></i>
            </a>
          </div>
        </div>
      </div>-->

      <div class="row">
        <div class="col-xs-12 rcd-row">
          <!-- Pending overlay-->
          <div class="pending-overlay-white pending-overlay-column" ng-if="rewardCodeDetail.searchingUser">
            <span class="fa fa-circle-o-notch fa-spin-2x"></span>
            <div class="pending-overlay-description">{{rewardCodeDetail.searchingUser}}</div>
          </div>

          <!-- Title -->
          <div class="rc-title">Shared with</div>

          <div class="rc-value">
            <!-- Primary Row -->
            <div class="flex-container">
              <div class="associated-users" style="align-items: center; flex-grow: 1;">
                <div ng-if="rewardCodeDetail.rewardCode.userShare">
                  <table-user-details user="rewardCodeDetail.rewardCode.userShare" />
                  <div>
                    <button class="btn-sm btn-danger" ng-click="rewardCodeDetail.removeUserShare()"><i class="fa fa-times"></i></button>
                  </div>
                </div>
                <span ng-if="!rewardCodeDetail.rewardCode.userShare" class="text-muted">This Reward Code is not shared with another user</span>
              </div>

              <div style="flex-shrink:0;">
                <a ng-if="!rewardCodeDetail.editUserShareVisible"
                   class="action-icon-link cursor-pointer no-animate"
                   ng-click="rewardCodeDetail.openUserShare()"
                   uib-tooltip="Click to edit"
                   tooltip-placement="left">
                  <i class="fa fa-pencil"></i></a>
                <button type="button"
                        class="btn-sm btn-primary"
                        ng-if="rewardCodeDetail.editUserShareVisible"
                        ng-click="rewardCodeDetail.editUserShareVisible = false">Cancel</button>

              </div>

            </div>
            <!--- Edit Row -->
            <div class="slide-if" ng-if="rewardCodeDetail.editUserShareVisible">
              <div class="edit-container">
                <i class="col fa" title="Reward Code association with other users"></i>
                <div class="" style="flex-grow: 1; margin-top: 10px;">
                  <search-autocomplete
                    control-id="share-autocomplete"
                    input-name="shareUsersInputSearch"

                    placeholder="Search Business"
                    minlength="3"
                    clear-selected="false"
                    text-searching="false"
                    text-no-results="false"

                    search-service="Search"
                    search-service-end-point="index"
                    remote-url-response-formatter="rewardCodeDetail.searchResponseFormatter"
                    search-categories="rewardCodeDetail.searchCategories"
                    selected-object="rewardCodeDetail.onSearchSharedItemSelected"

                    data-field="content.records"
                    search-fields="search"
                    title-field="value"
                    description-field="categoryDescription"
                    description-field2="timeDescription"
                    category-field="category"

                    input-class="form-control input-lg"
                    match-class="autocomplete-highlight"
                    blur-on-select="true"
                    has-back-button="false"
                    update-input-on-select="false"
                    is-dropdown-relative="true"
                    override-suggestions="false"
                    field-required="false"
                    has-history="true" />
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 rcd-row">
          <!-- Pending overlay-->
          <div class="pending-overlay-white pending-overlay-column" ng-if="rewardCodeDetail.searchingAssociatedUser">
            <span class="fa fa-circle-o-notch fa-spin-2x"></span>
            <div class="pending-overlay-description">{{rewardCodeDetail.searchingAssociatedUser}}</div>
          </div>

          <!-- Title -->
          <div class="rc-title">Associated with</div>

          <!-- Primary Row -->
          <div class="rc-value">
            <div style="position: relative; display: flex; align-items: center; justify-content: space-between; flex-wrap: nowrap;">
              <div style="align-items: center; flex-grow: 1;">
                <div class="associated-users short-width" style="max-height: 150px; overflow-x: hidden; overflow-y: scroll;">
                  <div ng-repeat="user in rewardCodeDetail.associatedUsers" ng-if="rewardCodeDetail.associatedUsers && rewardCodeDetail.associatedUsers.length > 0">
                    <table-user-details user="user" is-link="false" />
                    <div>
                      <button class="btn-sm btn-danger" ng-click="rewardCodeDetail.removeAssociatedUser($index)"><i class="fa fa-times"></i></button>
                    </div>
                  </div>
                </div>
                <span ng-if="!rewardCodeDetail.associatedUsers || rewardCodeDetail.associatedUsers.length === 0" class="text-muted">
                  This Reward Code is not associated with another users
                </span>
              </div>

              <div style="flex-shrink:0;">
                <a ng-if="!rewardCodeDetail.editAssociatedUsersVisible"
                   class="action-icon-link cursor-pointer no-animate"
                   ng-click="rewardCodeDetail.openAssociatedUser()"
                   uib-tooltip="Click to edit"
                   tooltip-placement="left">
                  <i class="fa fa-pencil"></i></a>
                <button type="button"
                        class="btn-sm btn-primary btn-block"
                        style="margin-bottom: 3px"
                        ng-if="rewardCodeDetail.hasEditsAssociatedUsers()"
                        ng-click="rewardCodeDetail.saveAssociatedUsers()">Save</button>
                <button type="button"
                        class="btn-sm btn-primary"
                        ng-if="rewardCodeDetail.editAssociatedUsersVisible"
                        ng-click="rewardCodeDetail.onAssociatedUsersCancel()">Cancel</button>

              </div>

            </div>
            <!--- Edit Row -->
            <div class="slide-if" ng-if="rewardCodeDetail.editAssociatedUsersVisible">
              <div style="position: relative; display: flex; align-items: center; justify-content: space-between;">
                <i class="col fa"></i>
                <div class="" style="flex-grow: 1; margin-top: 10px;">
                  <search-autocomplete
                    control-id="associated-autocomplete"
                    input-name="associatedUsersInputSearch"

                    placeholder="Search Business"
                    minlength="3"
                    clear-selected="false"
                    text-searching="false"
                    text-no-results="false"

                    search-service="Search"
                    search-service-end-point="index"
                    remote-url-response-formatter="rewardCodeDetail.searchResponseFormatter"
                    search-categories="rewardCodeDetail.searchCategories"
                    selected-object="rewardCodeDetail.onAssociatedItemSelected"

                    data-field="content.records"
                    search-fields="search"
                    title-field="value"
                    description-field="categoryDescription"
                    description-field2="timeDescription"
                    category-field="category"

                    input-class="form-control input-lg"
                    match-class="autocomplete-highlight"
                    blur-on-select="true"
                    has-back-button="false"
                    update-input-on-select="false"
                    is-dropdown-relative="true"
                    override-suggestions="false"
                    field-required="false"
                    has-history="true" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--<script type="text/ng-template" id="social-tpl.html"><span></span>
        <div>
          <a class="reward-share-link" ng-click="rewardCodeDetail.share('facebook')"><i class="fa fa-facebook-square fa-3x"></i></a>
          <a class="reward-share-link" ng-click="rewardCodeDetail.share('linkedin')"><i class="fa fa-linkedin-square fa-3x"></i></a>
          <a class="reward-share-link" ng-click="rewardCodeDetail.share('twitter')"><i class="fa fa-twitter-square fa-3x"></i></a>
          <a class="reward-share-link" ng-click="rewardCodeDetail.share('google')"><i class="fa fa-google-plus-square fa-3x"></i></a>
          <a class="reward-share-link" ng-click="rewardCodeDetail.embed()" title="Embed"><i class="fa fa-code fa-3x"></i></a>
        </div>
      </script>

      <i class="no-select info-help" role="button" tabIndex="0" style="margin-left: 15px;"
         popover-trigger="click, outsideClick"
         popover-append-to-body="true"
         uib-popover-template="'social-tpl.html'">Share</i>-->

      <!--<div class="row" ng-if="rewardCodeDetail.shortUrl">
        <div class="col-xs-12 rcd-row">
          <a class="reward-share-link" ng-click="rewardCodeDetail.share('facebook')"><i class="fa fa-facebook-square fa-3x"></i></a>
          <a class="reward-share-link" ng-click="rewardCodeDetail.share('linkedin')"><i class="fa fa-linkedin-square fa-3x"></i></a>
          <a class="reward-share-link" ng-click="rewardCodeDetail.share('twitter')"><i class="fa fa-twitter-square fa-3x"></i></a>
          <a class="reward-share-link" ng-click="rewardCodeDetail.share('google')"><i class="fa fa-google-plus-square fa-3x"></i></a>
          <a class="reward-share-link" ng-click="rewardCodeDetail.embed()" title="Embed"><i class="fa fa-code fa-3x"></i></a>
        </div>
      </div>-->

    </div>
  </div>
  <div class="row modal-content-buttons">
    <div class="col-xs-12">
      <button ng-if="!rewardCodeDetail.rewardCode.isArchived" class="btn btn-default" ng-click="rewardCodeDetail.archive()" ng-disabled="rewardCodeDetail.isArchiving">
        {{rewardCodeDetail.isArchiving ? 'Archiving ...' : 'Archive'}}
      </button>
      <button class="btn btn-primary pull-right" ng-click="rewardCodeDetail.close()">
        Close
      </button>
    </div>
  </div>
</div>
