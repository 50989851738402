(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.directive:stPaginationScroll
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="components">
       <file name="index.html">
        <st-pagination-scroll></st-pagination-scroll>
       </file>
     </example>
   *
   */
  angular
    .module('components')
    .directive('stPaginationScroll', stPaginationScroll);

  function stPaginationScroll($timeout) {
    return {
      require: 'stTable',
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */

        var itemByPage = 10,
            pagination = ctrl.tableState().pagination,
            lengthThreshold = 50,
            timeThreshold = 400,
            promise = null,
            lastRemaining = 9999,
            container;

        if (element.closest('.fixed-header').length > 0) {
          container = element.find('> tbody');
        }
        else {
          container = element.parent();
        }

        function handler() {
          ctrl.slice(pagination.start + itemByPage, itemByPage);
        }

        container.bind('scroll', function () {
          var remaining = container[0].scrollHeight - (container[0].clientHeight + container[0].scrollTop);
          // if we have reached the threshold and we scroll down
          if (remaining < lengthThreshold && remaining - lastRemaining < 0) {
            // if there is already a timer running which has no expired yet we have to cancel it and restart the timer
            if (promise !== null) {
              $timeout.cancel(promise);
            }
            promise = $timeout(function () {
              handler();
              promise = null;
            }, timeThreshold);
          }
          lastRemaining = remaining;
        });
      }
    };
  }
}());
