(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name components.factory:RecordsSet
   *
   * @description
   *
   */
  angular
    .module('components')
    .factory('RecordsSet', RecordsSet);

  function RecordsSet($q, $httpParamSerializer, Restangular) {
    var RecordsSetBase = {},
        defaultQueryParams = {
          limit: 10
        };

    /**
     * Records set manager
     *
     * @callback itemCallback
     *
     * @param {string} entity Name of the entity
     * @param {string} endPoint Endpoint to be called, default index
     * @param {Object} queryParams Additional parameters
     * @param {Object} options Additional params to configure record set
     * @constructor
     */
    function RecordsSetManager(entity, endPoint, queryParams, options) {
      var self = this,
          loadingEnabled = true,
          totalLoaded = 0,
          initialise,
          deferredInit = $q.defer();

      options = angular.isObject(options) ? options : {};
      self.records = [];
      self.isLoading = false;
      self.isInitialised = false;
      self.numberOfSets = 0;
      self.recordsExist = null;
      self.totalRecords = null;

      if (angular.isObject(queryParams)) {
        queryParams = angular.extend({}, defaultQueryParams, queryParams);
      }
      else {
        queryParams = angular.extend({}, defaultQueryParams);
      }

      if (angular.isDefined(options.basicParams) && angular.isObject(options.basicParams)) {
        queryParams = angular.extend(queryParams, options.basicParams);
      }

      if (angular.isUndefined(endPoint) || endPoint.trim() === '') {
        endPoint = 'index';
      }

      /**
       * Load records, support for loading additional records
       * @param {Boolean} isReload Forces reload if set to true
       *
       * @returns {Deferred} Deferred promise
       */
      self.load = function (isReload) {
        var deferred = $q.defer();
        if (loadingEnabled) {
          self.isLoading = true;
          Restangular.one(entity).getList(endPoint + '?' + $httpParamSerializer(angular.extend(queryParams, {
            offset: totalLoaded
          }))).then(function (result) {
            angular.forEach(result, function (value) {
              var item = value.plain();
              if (angular.isDefined(options.itemCallback) && angular.isFunction(options.itemCallback)) {
                item = options.itemCallback(item);
              }
              self.records.push(item);
            });
            totalLoaded += result.meta.selectedRecords;
            if (result.meta.totalRecords <= totalLoaded) {
              loadingEnabled = false;
            }

            // MGA: there was a reason MSU was setting the totalRecords to 0 and later on keep comparing against null...
            // Let's still support it, but if it's the reload, then just force the update.
            if (!isReload) {
              if (self.totalRecords === null) {
                self.totalRecords = result.meta.totalRecords;
              }
              if (self.recordsExist === null) {
                self.recordsExist = self.records.length > 0;
              }
            }
            else {
              self.totalRecords = result.meta.totalRecords;
              // self.recordsExist = self.records.length > 0;
            }

            self.isLoading = false;
            self.isInitialised = true;

            if (angular.isDefined(queryParams.limit) && parseInt(queryParams.limit, 10) > 0) {
              self.numberOfSets = Math.ceil(result.meta.totalRecords / queryParams.limit);
            }
            deferred.resolve(self.records);
          }, function (result) {
            deferred.reject(result.data.errorMessage);
          });
        }
        else {
          deferred.resolve([]);
        }

        return deferred.promise;
      };

      initialise = function () {
        var deferred = $q.defer(),
            keys,
            extraParams,
            performInitQuery = false;

        if (!self.isInitialised) {
          if (angular.isDefined(options.basicParams) && angular.isObject(options.basicParams)) {
            keys = _.keys(options.basicParams);
            keys.push('limit');
            keys.push('offset');
            extraParams = _.omit(queryParams, keys);
            performInitQuery = _.keys(extraParams).length > 0;
          }

          if (performInitQuery) {
            self.isLoading = true;
            Restangular.one(entity).getList(endPoint + '?' + $httpParamSerializer(options.basicParams))
              .then(function (result) {
                self.recordsExist = result.meta.totalRecords > 0;
                self.totalRecords = result.meta.totalRecords;
                if (self.recordsExist) {
                  self.load()
                    .then(function () {
                      self.isInitialised = true;
                      deferred.resolve(true);
                    });
                }
                else {
                  self.isInitialised = true;
                  self.isLoading = false;
                  deferred.resolve(true);
                }
              });
          }
          else {
            self.load()
              .then(function () {
                self.isInitialised = true;
                deferred.resolve(true);
              });
          }
        }
        else {
          return deferred.resolve(true);
        }

        return deferred.promise;
      };

      /**
       *
       * Creates a new record
       *
       * @param {Object} data Data to be sent to server
       * @param {Object} queryParamsOverride Additional query params, if not provided then params used to initialise loader will be used
       * @param {string} appendTo One of 'beginning' or 'end', indicates the position of new record within existing records, if not provided then the record won't be inserted into the list
       * @returns {Deferred} Deferred promise
       */
      self.post = function (data, queryParamsOverride, appendTo) {
        var deferred = $q.defer(),
            qParams = queryParams;
        if (angular.isDefined(queryParamsOverride) && angular.isObject(queryParamsOverride)) {
          qParams = queryParamsOverride;
        }

        Restangular.one(entity).customPOST(data, endPoint, qParams)
          .then(function (result) {
            if (appendTo === 'beginning') {
              self.records.unshift(result.plain());
              totalLoaded += 1;
            }
            else if (appendTo === 'end') {
              self.records.push(result.plain());
              totalLoaded += 1;
            }
            deferred.resolve(result.plain());
          }, function (result) {
            deferred.reject(result.data.errorMessage);
          });

        return deferred.promise;
      };

      /**
       * Reload with new parameters
       * @param {Object} queryParamsOverride Overriding params
       * @param {Boolean} initialReset If true resets also flag indicating existence of records
       * @returns {Deferred} Deferred promise
       */
      self.reload = function (queryParamsOverride, initialReset) {
        self.reset(queryParamsOverride, initialReset);

        return self.load(true);
      };

      /**
       *
       * @param {string} key Key to sort by
       * @param {string} direction Direction to sort in
       * @returns {Deferred} Deferred promise
       */
      self.sort = function (key, direction) {
        self.records = [];
        totalLoaded = 0;
        loadingEnabled = true;
        queryParams = angular.extend(queryParams, {orderby: key + ' ' + direction});

        return self.load();
      };

      /**
       * Checks whether there is more records to load
       *
       * @returns {boolean} True or False whether loading is enabled
       */
      self.hasMore = function () {
        return loadingEnabled;
      };

      /**
       * Resets the loader, removes already loaded records from the list, resets counter
       *
       * @param {object} queryParamsOverride New params for the loader, overrides initial params from loader initialisation
       * @param {Boolean} initialReset If true resets also flag indicating existence of records
       */
      self.reset = function (queryParamsOverride, initialReset) {
        self.records = [];
        totalLoaded = 0;
        loadingEnabled = true;
        if (angular.isUndefined(initialReset) || initialReset !== true) {
          initialReset = false;
        }

        if (initialReset === true) {
          self.recordsExist = null;
        }
        if (angular.isObject(queryParamsOverride)) {
          queryParams = angular.extend({}, defaultQueryParams, queryParamsOverride);
        }
        else {
          queryParams = angular.extend({}, defaultQueryParams);
        }

        if (angular.isDefined(options.basicParams) && angular.isObject(options.basicParams)) {
          queryParams = angular.extend(queryParams, options.basicParams);
        }
      };

      self.isReady = deferredInit.promise;

      initialise().then(function () {
        deferredInit.resolve();
      }, function () {
        deferredInit.reject();
      });
    }

    /**
     * Returns instance of records set manager
     *
     * @param {string} entity Name of the entity
     * @param {string} endPoint Endpoint to be called, default index
     * @param {Object} params Additional parameters
     * @param {Object} options Additional params to configure record set
     * @returns {RecordsSetManager} Instance of records set manager
     */
    RecordsSetBase.createLoader = function (entity, endPoint, params, options) {
      return new RecordsSetManager(entity, endPoint, params, options);
    };

    return RecordsSetBase;
  }
}());
