(function () {
  'use strict';

  angular
    .module('myMemberships.joinPod')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('myMembershipsJoinPod', {
        url: '/my-memberships/join-pod',
        template: '<ui-view />',
        controller: 'JoinPodCtrl',
        controllerAs: 'joinPod',
        authenticate: true,
        params: {
          user: null,
          nextState: null
        },
        resolve: {
          userProfile: ['Session', function (Session) {
            return Session.getUserProfile();
          }]
        }
      });
  }
}());
