<div class="modal-content-container send-invite">
  <div class="row">
    <div class="col-xs-12">
      <!-- Title -->
      <div class="row">
        <div class="col-xs-12 modal-content-title">
          <span class="sub-title">Send Invite</span>
        </div>
      </div>
      <!-- ENDOF: Title-->

      <!-- Content -->
      <div class="row">
        <div class="col-xs-12">


          <form name="sendInviteForm" ng-if="!sendInvite.targetUser">

            <div class="row">
              <div class="col-xs-12">
                Invite the vendor into your pod, so you can start sending the leads and claim the rewards.
              </div>
            </div>

            <div class="row form-section-body">
              <div class="col-xs-12 reward-input-content">
                <div class="reward-card-holder" ng-class="{flipped: sendInvite.onlyRewards}">
                  <!--<img class="front" src="./images/reward-code2-blur.jpg" />
                  <img class="back" src="./images/reward-code2-blur-back.jpg" />-->
                  <img class="front" src="./images/reward-code-card-front.png" />
                  <img class="back" src="./images/reward-code-card-back.png" />
                </div>
                <div style="padding-left: 10px;">
                  <div style="margin-bottom:15px;">
                    If you have the <strong>Reward Code</strong>, enter it
                    <span class="no-animate" ng-if="sendInvite.hasRewardCode && !sendInvite.displayContactDetails"> here</span>
                    <a class="no-animate"
                       ng-if="!sendInvite.hasRewardCode || sendInvite.displayContactDetails"
                       ng-click="sendInvite.openRewardCode(); ">here</a>
                  </div>
                  <div class="form-group slide-css" style="display: block;" ng-class="{'has-error': sendInvite.isRewardCodeInvalid, slide: sendInvite.hasRewardCode && !sendInvite.displayContactDetails}">

                    <!--<div style="margin-bottom: 15px;">
                      <a style="cursor:pointer;" ng-click="sendInvite.hasRewardCode = false;">Go Back</a>
                    </div>-->

                    <label class="sr-only" for="rewardCode">Reward Code</label>
                    <div ng-show="sendInvite.isRewardCodeInvalid">
                      <div class="input-error" ng-show="sendInvite.isRewardCodeInvalid">Invalid Reward Code.</div>
                    </div>
                    <div class="left-inner-addon input-group">
                      <i class="fa fa-hashtag inner-addon"></i>
                      <input class="form-control input-lg" type="text" name="rewardCode" id="rewardCode"
                             placeholder="Reward Code"
                             ng-required="false" ng-model="sendInvite.rewardCode"
                             ng-keydown="sendInvite.onRewardCodeEnter($event)"
                             ng-focus="sendInvite.onlyRewards=true; sendInvite.onlySearch=false;"
                             />
                      <span class="input-group-btn">
                        <button class="btn btn-primary btn-md" type="button" ng-click="sendInvite.checkRewardCode()"
                                ng-disabled="!sendInvite.rewardCode || sendInvite.isCheckingCode">
                          {{sendInvite.isCheckingCode ? 'Validating ...' : sendInvite.isRewardCodeValid ? 'Clear' : 'Enter'}}
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div class="row" ng-if="!sendInvite.isRewardCodeValid">
              <div class="col-xs-12 text-center form-group">
                <div>
                  <div class="btn-social-separator-text">OR</div>
                </div>
              </div>
            </div>

            <div class="">
              <div class="row slide-css" ng-class="{slide: !sendInvite.displayContactDetails || sendInvite.only}" style="display:block;">
                <div class="col-xs-12">
                  <div style="margin-bottom: 15px;">
                    Try to search for the business in our directory.
                    <a ng-if="sendInvite.onlyRewards"
                      ng-click="sendInvite.openSearch()"> Use Search</a>
                  </div>
                </div>
              </div>

              <div class="row slide-css" ng-class="{slide: !sendInvite.displayContactDetails && (!sendInvite.onlyRewards || sendInvite.onlySearch)}" style="display:block;">
                <div class="col-xs-12 form-group">
                  <search-autocomplete
                    control-id="send-invite-autocomplete"
                    placeholder="Search Business"
                    minlength="3"
                    clear-selected="false"
                    text-searching="false"
                    text-no-results="false"

                    search-service="Search"
                    search-service-end-point="index"
                    remote-url-response-formatter="sendInvite.searchResponseFormatter"

                    data-field="content.records"
                    selected-object="sendInvite.onSearchItemSelected"

                    search-fields="search"
                    title-field="value"
                    description-field="categoryDescription"
                    description-field2="timeDescription"
                    category-field="category"

                    input-name="{{sendInvite.searchControlName}}"
                    input-class="form-control input-lg"
                    match-class="autocomplete-highlight"
                    blur-on-select="true"
                    has-back-button="false"
                    update-input-on-select="true"

                    focus-in="sendInvite.focusIn()"
                    is-dropdown-relative="true"

                    override-suggestions="true"
                    search-categories="sendInvite.searchCategories"
                    field-required="false"
                    has-history="true" />
                </div>
              </div>

              <div class="slide-if" ng-if="!sendInvite.onlyRewards && (sendInvite.emptyResults || sendInvite.displayContactDetails)" style="position:relative;">
                <div class="row" ng-if="sendInvite.displayContactDetails">
                  <div class="col-xs-12" style="margin-bottom: 15px;">
                    <a ng-click="sendInvite.clearContactData(true);">Try Search Again</a>
                  </div>
                </div>
                <div ng-if="sendInvite.emptyResults && !sendInvite.displayContactDetails" class="row">
                  <div class="col-xs-12" style="margin-bottom: 15px;">
                    Seems like the business you want to send a lead to is not yet registered. You can provide the details of the business manually
                    <a ng-click="sendInvite.displayContactDetails = true;" > here</a>.
                  </div>
                </div>
              </div>
              <div class="slide-if" ng-if="sendInvite.displayContactDetails" style="position:relative;">
                <div class="pending-overlay-light" ng-if="sendInvite.isLoadingUserData">
                  <span class="fa fa-2x fa-circle-o-notch fa-spin-2x"></span>
                </div>

                <div ng-if="!sendInvite.isLoadingUserData">
                  <!-- Name -->
                  <div ng-if="!sendInvite.isRewardCodeValid" class="row">
                    <div class="col-xs-12" style="margin-bottom: 15px;">
                      Business Contact Details:
                    </div>

                    <div class="col-xs-12" ng-if="sendInvite.userInvitedProfile">
                      <div>
                        <table-user-details user="sendInvite.userInvitedProfile"
                                            is-link="false"
                                            has-details="true"
                                            add-class="'full-width-user-details'">
                        </table-user-details>
                      </div>
                    </div>

                    <div ng-show="!sendInvite.userInvitedProfile">
                      <div class="col-xs-6">
                        <div class="form-group" ng-class="{'has-error': (sendInviteForm.$submitted || sendInviteForm.firstName.$touched) && sendInviteForm.firstName.$error.required}">
                          <label class="sr-only" for="firstName">First name</label>
                          <div ng-show="sendInviteForm.$submitted || sendInviteForm.firstName.$touched">
                            <div class="input-error" ng-show="sendInviteForm.firstName.$error.required">Missing first name.</div>
                          </div>
                          <div class="left-inner-addon">
                            <i class="fa fa-user inner-addon"></i>
                            <input class="form-control input-lg" type="text" name="firstName" id="firstName"
                                   placeholder="First name" ng-required="true" ng-model="sendInvite.data.firstName" />
                          </div>
                        </div>
                      </div>
                      <div class="col-xs-6">
                        <div class="" ng-class="{'has-error': (sendInviteForm.$submitted || sendInviteForm.phoneMobile.$touched) && (sendInviteForm.phoneMobile.$error.required || sendInviteForm.phoneMobile.$error.email)}">
                          <label class="sr-only" for="email">Phone</label>
                          <div ng-show="sendInviteForm.$submitted || sendInviteForm.phoneMobile.$touched">
                            <div class="input-error" ng-show="sendInviteForm.phoneMobile.$error.required">Missing phone number.</div>
                          </div>
                          <div class="left-inner-addon">
                            <i class="fa fa-phone inner-addon"></i>
                            <input class="form-control input-lg" type="tel" name="phoneMobile" id="phoneMobile" placeholder="Phone Number (optional)"
                                   ng-required="false" ng-model="sendInvite.data.phoneMobile" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Email -->
                  <div ng-show="!sendInvite.isRewardCodeValid && !sendInvite.userInvitedProfile" class="row">
                    <div class="col-xs-12">
                      <div class="" ng-class="{'has-error': (sendInviteForm.$submitted || sendInviteForm.email.$touched) && (sendInviteForm.email.$error.required || sendInviteForm.email.$error.email)}">
                        <label class="sr-only" for="email">Email</label>
                        <div ng-show="sendInviteForm.$submitted || sendInviteForm.email.$touched">
                          <div class="input-error" ng-show="sendInviteForm.email.$error.required">Missing email.</div>
                          <div class="input-error" ng-show="sendInviteForm.email.$error.email">This is not a valid email.</div>
                        </div>
                        <div class="left-inner-addon">
                          <i class="fa fa-envelope-o inner-addon"></i>
                          <input class="form-control input-lg" type="email" name="email" id="email" placeholder="Email"
                                 ng-required="true" ng-model="sendInvite.data.email" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <uib-alert ng-repeat="alert in sendInvite.alerts.getList()" type="{{alert.type}}" close="sendInvite.alerts.remove($index)">{{alert.message}}</uib-alert>

        </div>
      </div>
      <!-- ENDOF: Content -->

    </div>
  </div>

  <div class="row modal-content-buttons">
    <div class="col-xs-12 text-right">
      <button type="button" class="btn btn-default"
              ng-disabled="sendInvite.isLoading" ng-click="sendInvite.cancel()">
        Cancel
      </button>
      <button type="button"
              class="btn btn-primary"
              ng-class="{loading: sendInvite.isLoading}"
              ng-disabled="!sendInvite.checkContactData() ||
                          sendInvite.isLoading" ng-click="sendInvite.send()">
        {{sendInvite.isLoading ? 'Sending ...' : 'Send'}}
      </button>
    </div>
  </div>
</div>
