<div class="preferences">
  <div class="row">
    <div class="col-xs-12 col-sm-4">
      <h1 class="page-header">Marketplace</h1>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12">
      <div class="page-content page-content-no-tabs">

        <div class="row">
          <div class="col-xs-12 form-group">
            Sign up to deals and services advertised by MyLeadPod business subscribers. (To advertise, please
            <a href="mailto:cs@myleadpod.com?subject=Marketplace advertising interest">contact us</a>
            to set you up for MyLeadPod Marketplace.)
          </div>
        </div>

        <div class="row form-group">
          <div class="col-xs-12 text-center">
            <div class="preferences-content hidden-xs">
              <div class="compound-buttons" style="margin:0 auto;">
                <div role="button" tabindex="1"
                     ng-click="marketplace.isGrid = false;"
                     ng-class="{'active': !marketplace.isGrid}"><i class="fa fa-bars"></i>
                </div>
                <div role="button" tabindex="1"
                     ng-click="marketplace.isGrid = true;"
                     ng-class="{'active': marketplace.isGrid}"><i class="fa fa-th"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div ng-if="marketplace.mlpItems && marketplace.mlpItems.length > 0">
          <div class="row marketplace-section">
            <div class="col-xs-12 mp-section-title text-center cursor-pointer" ng-click="marketplace.viewMLP = !marketplace.viewMLP;">
              <i class="fa fa-chevron-down" ng-class="{'rotate-180': marketplace.viewMLP}" style="transition: transform 0.2s ease-in-out;"></i>
              MyLeadPod Deals and Services
            </div>
            <div class="col-xs-12 preferences-section mp-section slide-if" ng-class="{'grid': marketplace.isGrid}" ng-if="marketplace.viewMLP">
              <marketplace-listings items="marketplace.mlpItems" has-user-avatar="true"></marketplace-listings>
            </div>
          </div>
        </div>


        <div class="row marketplace-section">
          <div class="col-xs-12 mp-section-title text-center">
            Marketplace
          </div>
          <div class="col-xs-12 preferences-section mp-section" ng-class="{'grid': marketplace.isGrid}">
            <marketplace-listings items="marketplace.items" has-user-avatar="true"></marketplace-listings>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

