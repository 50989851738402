<div class="col-xs-12" ng-controller="ProfileCtrl as profile" id="profile-ctrl-container"
     ng-class="{'image-edit-mode': profile.imageEditMode, 'image-upload-mode': profile.imageUploadMode, 'pointer-events-none': profile.isDragging}">

  <div class="row user-profile-container hidden-xs">
    <div class="col-xs-12">

      <div style="position: relative;">
        <div class="page-content user-profile-lg" ng-style="profile.getProfileStyle()">
          <div class="profile-bg-edit">
            <button class="btn-sm btn-primary" ng-click="profile.imageEditMode = !profile.imageEditMode">
              Change Background
            </button>
            <!--<button class="btn-sm btn-primary"
                    ng-disabled="profile.isLoading"
                    ngf-change="profile.uploadBackgroundPhoto()"
                    ngf-select ng-model="profile.backgroundFile" accept="image/*">
              {{profile.isLoading ? 'Loading ...' : 'Change Background'}}
            </button>-->
          </div>
          <a class="crediting-link" ng-if="profile.bkgData.keepPattern && !profile.bkgData.backgroundFile" href="https://www.toptal.com/designers/subtlepatterns/gplay/" target="_blank"><i class="fa fa-copyright"></i>Design by Hoekstra</a>
          <div class="user-profile-container-lg">
            <div class="user-avatar-part">
              <!-- User Avatar -->
              <div user-avatar size="large" is-link="false" user="profile.userProfile"></div>
              <!-- Edit Profile Image Button -->
              <div uib-dropdown class="profile-img-edit" ng-if="profile.hasPhoto()">
                <button uib-dropdown-toggle class="btn-sm btn-primary" ng-disabled="profile.isLoading">
                  Edit Photo
                </button>
                <ul uib-dropdown-menu role="menu" class="dropdown-menu">
                  <li>
                    <a ng-if="profile.hasPhoto()"
                       ng-disabled="profile.isLoading" ng-click="profile.removePhoto()">
                      {{profile.isLoading ? 'Loading ...' : 'Remove Photo'}}
                    </a>
                  </li>
                  <li>
                    <a ng-if="profile.hasPhoto()"
                       ng-disabled="profile.isLoading" ngf-change="profile.uploadPhoto()"
                       ngf-select ng-model="profile.photoFile" accept="image/*">
                      {{profile.isLoading ? 'Loading ...' : 'Change Photo'}}
                    </a>
                  </li>
                </ul>
              </div>
              <div class="profile-img-edit" ng-if="!profile.hasPhoto()">
                <button class="btn-sm btn-primary"
                        ng-disabled="profile.isLoading"
                        ngf-change="profile.uploadPhoto()"
                        ngf-select ng-model="profile.photoFile" accept="image/*">
                  {{profile.isLoading ? 'Loading ...' : 'Upload Photo'}}
                </button>
              </div>
            </div>
            <div class="user-info-part">
              <div>
                <div class="user-name-and-company mbs" ng-class="{'mbm': profile.userProfile.businessName}" ng-style="profile.getHeadlineStyle()">
                  <!-- headline -->
                  <span class="sub-title user-name-container">{{profile.userProfile.fullName}}</span>
                  <span class="sub-title sub-title-regular sub-title-separator hidden-xs" ng-if="profile.userProfile.businessName">|</span>
                  <span class="sub-title sub-title-regular user-company-container">{{profile.userProfile.businessName}}</span>
                  <!-- Industry Info -->
                  <div class="sub-sub-title" ng-if="profile.userProfile.industry">{{profile.userProfile.industry.name}}{{profile.userProfile.subIndustry ? ' - ' + profile.userProfile.subIndustry.name : ''}}</div>
                </div>
                <!-- User Details -->
                <div class="user-details-container">
                  <div ng-if="profile.getAddress()"><i class="fa fa-map-marker"></i><a href="http://www.google.com/maps?q={{profile.addressLink}}" target="_blank">{{profile.getAddress()}}</a></div>
                  <div ng-if="profile.userProfile.phoneMobile"><i class="fa fa-phone"></i> <a href="tel:{{profile.userProfile.phoneMobile}}">{{profile.userProfile.phoneMobile}}</a></div>
                  <div ng-if="profile.userProfile.email"><i class="fa fa-envelope-o"></i> <a href="mailto:{{profile.userProfile.email}}">{{profile.userProfile.email}}</a></div>
                  <div ng-if="profile.userProfile.website"><i class="fa fa-desktop"></i> <a href="{{profile.formatWebsiteUrl()}}" target="_blank">{{profile.userProfile.website}}</a></div>
                </div>
                <!-- Commands -->
                <div class="user-details-buttons-container no-select no-animate">
                  <div class="sample-warning"><span>Only for illustration</span></div>
                  <a class="btn btn-primary">Send Message</a>
                  <a class="btn btn-primary">Send Reward</a>
                  <a class="btn btn-primary" ng-if="profile.userProfile.isBusinessAccountActive">Send Lead</a>
                  <a class="btn btn-primary" ng-if="profile.userProfile.isBusinessAccountActive">Register Lead</a>
                  <a class="btn btn-success" ng-if="profile.userProfile.isCharityAccountActive">Donate</a>
                </div>
              </div>
            </div>
          </div>

          <!-- Commands -->
          <div class="user-details-buttons-container no-select no-animate">
            <a class="btn btn-primary">Send Message</a>
            <a class="btn btn-primary">Send Reward</a>
            <a class="btn btn-primary" ng-if="profile.userProfile.isBusinessAccountActive">Send Lead</a>
            <a class="btn btn-primary" ng-if="profile.userProfile.isBusinessAccountActive">Register Lead</a>
            <a class="btn btn-success" ng-if="profile.userProfile.isCharityAccountActive">Donate</a>
          </div>
        </div>
        <div class="image-blocker-container"></div>
      </div>

    </div>


    <div class="col-xs-12 form-group image-control-panel slide-if" ng-if="profile.imageEditMode">
      <div class="page-content">

        <div class="pending-overlay-white pending-overlay-column opacity-slow-if" ng-if="profile.isSavingBackgound">
          <span class="fa fa-circle-o-notch fa-spin-2x"></span>
          <div class="pending-overlay-description">Updating your profile...</div>
        </div>

        <div class="cancel-section">
          <button class="btn-sm btn-primary" ng-click="profile.close();">
            Cancel
          </button>
          <button class="btn-sm btn-primary" ng-click="profile.clearData();" ng-if="profile.hasEdits() && !profile.resetRequested">
            Clear
          </button>
          <button class="btn-sm btn-primary" ng-click="profile.saveBackgroundEdits()" ng-if="profile.hasEdits() && !profile.resetRequested">
            Save
          </button>
          <div ng-if="profile.hasEdits()" class="edit-instructions" style="position:absolute;font-size: 10px;margin-top: 5px;">'Cancel' will clear all your edits.</div>
        </div>
        <div class="options-section">
          <!-- Step container -->

          <div class="top-section">
            <div ng-form="fileUploadForm">
                  <span class="error-instructions slide-if"
                        ng-if="fileUploadForm.file.$error.minWidth"
                        style="font-size:14px;display:block; margin-bottom:5px; color:salmon;font-weight: bold;">The image is too small (min. width required is 500px)
                  </span>
              <button class="btn-sm btn-primary"
                      ng-if="profile.backgroundStyleBkp"
                      ng-click="profile.resetToDefault()">
                Reset to Default
              </button>
              <button class="btn-sm btn-primary" ng-click="profile.changingColor = true;profile.resetRequested = false;">
                Change Colour
              </button>
              <button name="file"
                      ng-if="!profile.bkgData.backgroundFile"
                      class="btn-sm btn-primary"
                      ngf-select
                      ngf-min-width="500"
                      live-view="{block: false}"
                      ngf-multiple="false"
                      ngf-change="profile.uploadBackgroundPhoto()"
                      ng-model="profile.backgroundFile"
                      accept="image/*">
                Upload File
              </button>
              <button class="btn-sm btn-primary" ng-if="profile.bkgData.backgroundFile" ng-click="profile.removeBkgPhoto()">
                Remove Photo
              </button>
              <span class="edit-instructions" style="font-size:12px;display:block; margin-top:5px;">Uploaded images must be at least 500px wide. The dimensions of the area for the cover picture are 1140x215px.</span>
            </div>
          </div>

          <div class="slide-if" ng-if="profile.bkgData.backgroundFile || profile.changingColor" style="position: relative; width: 100%;">
            <div class="bottom-section">
              <div style="max-width: 250px;white-space: normal;font-size: 12px;" ng-if="profile.bkgData.backgroundFile">
                Move around the crop area in the content below. change the background colour to add some interesting effects.
              </div>
              <div>
                <span class="edit-instructions">Headline colour</span>
                <button colorpicker type="button"
                        ng-style="{'background-color': profile.bkgData.headlineColor || '#3b374a'}"
                        style="text-shadow: 1px 1px 3px black; display: block; margin: 0 auto; background-color: #3b374a;"
                        class="btn-sm btn-success"
                        colorpicker-position="bottom"
                        ng-model="profile.bkgData.headlineColor"><i class="fa fa-pencil"></i></button>
              </div>
              <div ng-if="!profile.bkgData.backgroundFile">
                <span class="edit-instructions">Keep pattern</span>
                <div class="compound-buttons">
                  <div role="button" tabindex="1"
                       ng-click="profile.bkgData.keepPattern = true; profile.edits.patternEdited = true;"
                       ng-class="{'active': profile.bkgData.keepPattern}">Yes
                  </div>
                  <div role="button" tabindex="1"
                       ng-click="profile.bkgData.keepPattern = false; profile.edits.patternEdited = true;"
                       ng-class="{'active': !profile.bkgData.keepPattern}">No
                  </div>
                </div>
              </div>
              <div>
                <span class="edit-instructions">Pick background color from palette</span>
                <div style="display: flex; align-items: center;">
                  <button colorpicker type="button"
                          ng-style="{'background-color': profile.bkgData.selectedColor || '#fffffa'}"
                          style="text-shadow: 1px 1px 3px black; display: inline-block; margin-right: 5px;"
                          class="btn-sm btn-success"
                          colorpicker-position="bottom"
                          ng-model="profile.bkgData.selectedColor"><i class="fa fa-pencil"></i></button>
                  <ngjs-color-picker style="display: inline-block;" options="profile.customOptions" custom-colors="profile.customColors" selected-color="profile.bkgData.selectedColor"></ngjs-color-picker>
                </div>
              </div>
              <div>
                <button ng-click="profile.clearColourSettings()"
                        class="btn-sm btn-primary"
                        style="color: black; margin-left: 5px; color: white;"
                        alt="Clear both colour settings" title="Clear both colour settings">Reset all colours</button>
              </div>
            </div>
          </div>

          <div class="slide-if" ng-if="profile.resetRequested" style="position: relative; width: 100%;">
            <div class="bottom-section" style="align-items: center;justify-content: center; height: 60px;">
              <span class="edit-instructions">This will remove all the current settings. Do you wish to proceed?</span>
              <button class="btn-sm btn-primary" ng-click="profile.resetBackgroundData()" style="margin-left: 10px;">
                Reset
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="col-xs-12" ng-if="profile.backgroundFile" style="z-index: 2;">
      <div class="image-cropper-container" style="position:relative; height: 300px; background-color:white; border-radius: 5px;">
        <ui-cropper image="profile.backgroundFile  | ngfDataUrl"
                    change-on-fly="true"
                    result-image="profile.bkgData.backgroundFile"
                    area-min-size="{w:30, h:30}"
                    init-max-area="true"
                    area-type="rectangle"
                    aspect-ratio="1140/215"
                    result-image-size="{w: 1140,h: 215}"></ui-cropper>
      </div>
    </div>

  </div>


  <div class="page-content info-content">
    <div class="row visible-xs">
      <div class="col-xs-4 col-sm-3 col-md-2 col-lg-2">
        <div user-avatar size="large" is-link="false" user="profile.userProfile"></div>
      </div>
      <div class="col-xs-8 col-sm-9 col-md-10 col-lg-10">
        <span class="sub-title">Upload your photo</span>
        <p class="upload-photo-sub-title">Photo should be at least 300 x 300px</p>
      </div>
      <div class="col-xs-12 col-sm-9 col-md-10 col-lg-10">
        <button class="btn btn-default upload-photo-btn" ng-if="profile.hasPhoto()"
                ng-disabled="profile.isLoading" ng-click="profile.removePhoto()">
          {{profile.isLoading ? 'Loading ...' : 'Remove Photo'}}
        </button>
        <button class="btn btn-primary upload-photo-btn" ng-if="profile.hasPhoto()"
                ng-disabled="profile.isLoading" ngf-change="profile.uploadPhoto()"
                ngf-select ng-model="profile.photoFile" accept="image/*">
          {{profile.isLoading ? 'Loading ...' : 'Change Photo'}}
        </button>
        <button class="btn btn-primary upload-photo-btn" ng-if="!profile.hasPhoto()"
                ng-disabled="profile.isLoading" ngf-change="profile.uploadPhoto()"
                ngf-select ng-model="profile.photoFile" accept="image/*">
          {{profile.isLoading ? 'Loading ...' : 'Upload Photo'}}
        </button>
      </div>
    </div>

    <form name="userProfileForm" class="user-profile-form" ng-submit="userProfileForm.$valid && !profile.isLoading && profile.save()">
      <div class="row form-section-title">
        <div class="col-xs-12">
          <span class="sub-title">Basic information</span>
        </div>
      </div>

      <div class="row form-section-body">
        <div class="col-xs-12 col-sm-6">
          <div class="form-group" ng-class="{'has-error': (userProfileForm.$submitted || userProfileForm.firstName.$touched) && userProfileForm.firstName.$error.required}">
            <label class="sr-only" for="firstName">First name</label>
            <div ng-show="userProfileForm.$submitted || userProfileForm.firstName.$touched">
              <div class="input-error" ng-show="userProfileForm.firstName.$error.required">Tell us your first name.</div>
            </div>
            <div class="left-inner-addon">
              <i class="fa fa-user inner-addon"></i>
              <input class="form-control input-lg" type="text" name="firstName" id="firstName"
                     placeholder="First name" ng-required="true" ng-model="profile.data.firstName" />
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6">
          <div class="form-group" ng-class="{'has-error': (userProfileForm.$submitted || userProfileForm.lastName.$touched) && userProfileForm.lastName.$error.required}">
            <label class="sr-only" for="lastName">Last name</label>
            <div ng-show="userProfileForm.$submitted || userProfileForm.lastName.$touched">
              <div class="input-error" ng-show="userProfileForm.lastName.$error.required">Tell us your last name.</div>
            </div>
            <div class="left-inner-addon">
              <i class="fa fa-user inner-addon"></i>
              <input class="form-control input-lg" type="text" name="lastName" id="lastName"
                     placeholder="Last name" ng-required="true" ng-model="profile.data.lastName" />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-sm-6">
          <div class="form-group" ng-class="{'has-error': (userProfileForm.$submitted || userProfileForm.email.$touched) && (userProfileForm.email.$error.required || userProfileForm.email.$error.email)}">
            <label class="sr-only" for="email">Email</label>
            <div ng-show="userProfileForm.$submitted || userProfileForm.email.$touched">
              <div class="input-error" ng-show="userProfileForm.email.$error.required">Tell us your email.</div>
              <div class="input-error" ng-show="userProfileForm.email.$error.email">This is not a valid email.</div>
            </div>
            <div class="left-inner-addon">
              <i class="fa fa-envelope-o inner-addon"></i>
              <input class="form-control input-lg" type="email" name="email" id="email" placeholder="Email"
                     ng-required="true" ng-model="profile.data.email" />
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6">
          <div class="form-group" ng-class="{'has-error': (userProfileForm.$submitted || userProfileForm.phoneMobile.$touched) && userProfileForm.phoneMobile.$error.required}">
            <label class="sr-only" for="phoneMobile">Mobile</label>
            <div ng-show="userProfileForm.$submitted || userProfileForm.phoneMobile.$touched">
              <div class="input-error" ng-show="userProfileForm.phoneMobile.$error.required">Tell us your mobile.</div>
            </div>
            <div class="left-inner-addon">
              <i class="fa fa-mobile inner-addon"></i>
              <input class="form-control input-lg" type="text" name="phoneMobile" id="phoneMobile"
                     placeholder="Mobile" ng-required="true" ng-model="profile.data.phoneMobile" />
            </div>
          </div>
        </div>
      </div>

      <div class="row form-section-title">
        <div class="col-xs-12">
          <span class="sub-title">Change password</span>
        </div>
      </div>

      <div class="row form-section-body">
        <div class="col-xs-12 col-sm-6">
          <div class="form-group">
            <label class="sr-only" for="password">New Password</label>
            <div class="left-inner-addon">
              <i class="fa fa-lock inner-addon"></i>
              <input class="form-control input-lg" type="password" name="password" id="password"
                     placeholder="New password" ng-required="false" ng-model="profile.data.password" />
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6">
          <div class="form-group" ng-class="{'has-error': (userProfileForm.$submitted || userProfileForm.confirmPassword.$touched) && userProfileForm.confirmPassword.$error.match}">
            <label class="sr-only" for="confirmPassword">Confirm new password</label>
            <div ng-show="userProfileForm.$submitted || userProfileForm.confirmPassword.$touched">
              <div class="input-error" ng-show="userProfileForm.confirmPassword.$error.match">Passwords do not match.</div>
            </div>
            <div class="left-inner-addon">
              <i class="fa fa-lock inner-addon"></i>
              <input class="form-control input-lg" type="password" name="confirmPassword" id="confirmPassword"
                     placeholder="Confirm new password" ng-required="false" ng-model="profile.data.confirmPassword"
                     match="profile.data.password" />
            </div>
          </div>
        </div>
      </div>

      <div class="row form-section-title">
        <div class="col-xs-12">
          <span class="sub-title">Business information</span>
        </div>
      </div>

      <div class="row form-section-body">
        <div class="col-xs-12 col-sm-6">
          <div class="form-group">
            <label class="sr-only" for="businessName">Company / Organisation</label>
            <div class="left-inner-addon">
              <i class="fa fa-briefcase inner-addon"></i>
              <input class="form-control input-lg" type="text" name="businessName" id="businessName"
                     placeholder="Company / Organisation" ng-required="false" ng-model="profile.data.businessName" />
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6">
          <div class="form-group" ng-class="{'has-error': (userProfileForm.$submitted || userProfileForm.location.$touched) && userProfileForm.location.$error.required}">
            <label class="sr-only" for="location">Location</label>
            <div ng-show="userProfileForm.$submitted || userProfileForm.location.$touched">
              <div class="input-error" ng-show="userProfileForm.location.$error.required">Tell us your location.</div>
            </div>
            <div class="left-inner-addon">
              <i class="fa fa-map-marker inner-addon"></i>
              <input class="form-control input-lg" type="text" name="location" id="location"
                     placeholder="Location" ng-required="false" ng-model="profile.data.location"
                     location-search input-id="location" place-changed-callback="profile.placeChangedCallback" />
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-6">
          <div class="form-group">
            <label class="sr-only" for="industry">Classification</label>
            <div class="left-inner-addon">
              <i class="fa fa-folder-o inner-addon"></i>
              <angucomplete-alt id="industry"
                                placeholder="Classification"
                                pause="100"
                                selected-object="profile.onClassificationSelect"
                                local-data="profile.industries"
                                search-fields="name"
                                title-field="name"
                                minlength="2"
                                match-class="autocomplete-highlight"
                                input-class="form-control input-lg"
                                input-name="industry"
                                field-required="false"
                                initial-value="profile.selectedClassification" />
            </div>
          </div>
        </div>

        <div class="col-xs-12 col-sm-6">
          <div class="form-group">
            <label class="sr-only" for="website">Website</label>
            <div class="left-inner-addon">
              <i class="fa fa-desktop inner-addon"></i>
              <input class="form-control input-lg" type="text" name="website" id="website"
                     placeholder="Website" ng-required="false" ng-model="profile.data.website" />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <div class="form-group">
            <label class="sr-only" for="info">Describe your business and services</label>
            <textarea class="form-control" name="info" id="info" placeholder="Describe your business and services"
                      ng-model="profile.data.description" msd-elastic></textarea>
          </div>
        </div>
      </div>

      <div class="row form-section-title">
        <div class="col-xs-12">
          <span class="sub-title">Billing details</span>
        </div>
      </div>

      <div class="row form-section-body">
        <div class="col-xs-12 col-sm-6">
          <div class="form-group">
            <label class="sr-only" for="idCountryPostal">Country</label>
            <div class="left-inner-addon">
              <i class="fa fa-map-marker"></i>
              <select class="form-control input-lg" name="idCountryPostal" id="idCountryPostal" placeholder="Country"
                      ng-required="false" ng-model="profile.data.addressPostal.idCountryPostal" ng-change="profile.onCountryChange()">
                <option value="" disabled selected>Country</option>
                <option ng-repeat="country in profile.getCountries()" value="{{country.id}}">{{country.name}}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="col-xs-12 col-sm-6" ng-if="profile.data.addressPostal.idCountryPostal && profile.getStates().length">
          <div class="form-group">
            <label class="sr-only" for="idStatePostal">State</label>
            <div class="left-inner-addon">
              <i class="fa fa-map-marker"></i>
              <select class="form-control input-lg" name="idStatePostal" id="idStatePostal" placeholder="State"
                      ng-model="profile.data.addressPostal.idStatePostal" ng-required="false" ng-disabled="!profile.data.addressPostal.idCountryPostal">
                <option value="" disabled selected>State</option>
                <option ng-repeat="state in profile.getStates()" value="{{state.id}}">{{state.name}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-6">
          <div class="form-group">
            <label class="sr-only" for="addressPostal">Street address</label>
            <div class="left-inner-addon">
              <i class="fa fa-map-marker inner-addon"></i>
              <input class="form-control input-lg" type="text" name="addressPostal" id="addressPostal"
                     placeholder="Street address" ng-required="false" ng-model="profile.data.addressPostal.addressPostal" />
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6">
          <div class="form-group">
            <label class="sr-only" for="suburbPostal">Suburb</label>
            <div class="left-inner-addon">
              <i class="fa fa-map-marker inner-addon"></i>
              <input class="form-control input-lg" type="text" name="suburbPostal" id="suburbPostal"
                     placeholder="Suburb" ng-required="false" ng-model="profile.data.addressPostal.suburbPostal" />
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6">
          <div class="form-group">
            <label class="sr-only" for="postCodePostal">Post code</label>
            <div class="left-inner-addon">
              <i class="fa fa-map-marker inner-addon"></i>
              <input class="form-control input-lg" type="text" name="postCodePostal" id="postCodePostal"
                     placeholder="Post code" ng-required="false" ng-model="profile.data.addressPostal.postCodePostal" />
            </div>
          </div>
        </div>
      </div>

      <div class="row form-section-title">
        <div class="col-xs-12">
          <span class="sub-title">Deactivate Account</span>
        </div>
      </div>

      <div class="row form-section-body">
        <div class="col-xs-12 col-sm-6 form-group">
          Follow the link to <a ng-click="profile.deactivateAccount()" style="cursor: pointer;">deactive your account</a>.
        </div>
      </div>

      <div class="row form-section-body">
        <div class="col-xs-12 form-group">
          <button type="submit" class="btn btn-primary btn-lg btn-block"
                  ng-disabled="profile.isLoading">
            {{profile.isLoading ? 'Loading ...' : 'Update Profile'}}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

