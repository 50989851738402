(function () {
  'use strict';

  /* @ngdoc object
   * @name user
   * @description
   *
   */
  angular
    .module('user', [
      'ui.router',
      'user.leads',
      'user.agreements',
      'user.connections',
      'user.marketplace',
      'user.messages'
    ]);
}());
