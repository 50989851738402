(function () {
  'use strict';

  angular
    .module('myPod.members')
    .config(config);

  function config($stateProvider, configOptions) {
    $stateProvider
      .state('myPod.members', angular.extend({}, configOptions.defaultStateParams, {
        url: '/members',
        templateUrl: 'my-pod/my-pod.tpl.html',
        controller: 'MyPodCtrl',
        controllerAs: 'myPod',
        onEnter: ['RouterHelper', function (RouterHelper) {
          RouterHelper.activateTab('myPod', 'members');
        }]
      }));
  }
}());
