<div class="business-suggestion" ng-hide="suggestion.isClosed">
  <div class="container">

    <div ng-if="!suggestion.trialData.isEligibleForTrial && !suggestion.trialData.isInTrial" class="suggestion-confirm">
      <span> Do you want to receive leads for your own business?</span>
      <button class="btn-sm btn-primary" ng-click="suggestion.registerBusiness()" ng-disabled="suggestion.isActivating">
        {{suggestion.isActivating ? 'Activating ...' : 'Register my business'}}
      </button>
    </div>

    <div ng-if="suggestion.trialData.isEligibleForTrial" class="suggestion-confirm">
      <span>You are eligible for a trial business subscription, subscribe now and start receiving leads!</span>
      <button class="btn-sm btn-primary" ng-click="suggestion.registerBusiness()" ng-disabled="suggestion.isActivating">
        {{suggestion.isActivating ? 'Activating ...' : 'Register for trial business account'}}
      </button>
    </div>

    <div ng-if="suggestion.trialData.isInTrial && suggestion.trialData.trialWarning" class="suggestion-confirm">
      <span>Your trial business account subscription {{suggestion.trialData.trialExpiresIn}}</span>
      <button class="btn-sm btn-primary" ng-click="suggestion.registerBusiness()" ng-disabled="suggestion.isActivating">
        {{suggestion.isActivating ? 'Activating ...' : 'Buy subscription'}}
      </button>
    </div>

    <div class="suggestion-cancel" ng-if="!suggestion.trialData.isInTrial || !suggestion.trialData.trialWarning">
      <button class="btn-sm btn-primary" style="background:transparent; color: inherit;" ng-click="suggestion.closeSuggestion()">x</button>
    </div>
  </div>
</div>
