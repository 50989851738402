(function () {
  'use strict';

  angular
    .module('myPod.sendInvite')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('myPodSendInvite', {
        url: '/my-pod/send-invite',
        template: '<ui-view />',
        controller: 'SendInviteCtrl',
        controllerAs: 'sendInvite',
        authenticate: true,
        params: {
          user: null
        }
      });
  }
}());
