(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name user.connections.controller:ConnectionsCtrl
   *
   * @description
   *
   */
  angular
    .module('user.connections')
    .controller('ConnectionsCtrl', ConnectionsCtrl);

  function ConnectionsCtrl($scope, Notification, MyPod) {
    var vm = this;

    vm.userProfile = $scope.user.userProfile;
    vm.searchName = '';
    vm.invitePendingFor = -1;
    vm.invitePending = false;
    vm.lastSearched = null;

    // use the data table initialised in the parent scope
    vm.list = $scope.user.connectionsList;

    // Search/Filter function for the search/filter form
    vm.search = function () {
      if (vm.searchName === vm.lastSearched) {
        return;
      }
      vm.lastSearched = vm.searchName;
      vm.list.reload({
        search: vm.searchName
      });
    };

    // Will directly join the other user's pod (using the default rewards program)
    vm.quickJoin = function ($index) {
      var user = vm.list.getRecords()[$index].member;

      // Let's start pending...
      vm.invitePendingFor = $index;
      vm.invitePending = true;

      MyPod.quickJoin(user.idUser).then(function () {
        user.acceptedSendLeadsContractExists = true;
        vm.invitePendingFor = -1;
        vm.invitePending = false;
        Notification({message: 'Success'}, 'confirmation');
      }, function (err) {
        vm.invitePendingFor = -1;
        vm.invitePending = false;
        Notification({message: 'The registration was unsuccessful.'}, 'error');
        throw new Error(err);
      });
    };
  }
}());
