(function () {
  'use strict';

  /* @ngdoc object
   * @name socialShare
   *
   * @description
   *
   */
  angular
    .module('socialShare', [
      'socialShare.providers',
      'urlShorteners'
    ]);

  angular
    .module('socialShare')
    .config(config)
    .run(run);

  function config() {
  }

  function run(SocialShare) {
    SocialShare.init();
  }
}());
