(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.ui.table.directive:headerCheck
   * @restrict A
   * @element
   * @description After the last ng-repeat is rendered, the directive checks for the scrollbar. if present, it adds the padding to the header.
   */
  angular
    .module('components.ui.table')
    .directive('headerCheck', headerCheck);

  function headerCheck($timeout) {
    return {
      restrict: 'A',
      link: function (scope, element) {
        var tbody,
            thead,
            scrollBarWidth;

        if (scope.$last === true) {
          $timeout(function () {
            // Grab the element (which should be the row), its parent (thead), check for the scroll.
            // If scroll exists, do the padding (if thead is visible)
            if (!tbody) {
              tbody = element.parent();
              thead = tbody.parent().find('thead');
            }

            if (tbody[0].scrollHeight > tbody.height()) {
              if (!scrollBarWidth) {
                scrollBarWidth = tbody.width() - tbody[0].scrollWidth;
                thead.css({'padding-right': scrollBarWidth + 'px'});
              }
            }
            else {
              thead.css({'padding-right': null});
            }
          });
        }
      }
    };
  }
}());
