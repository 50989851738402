// jscs:disable requireCamelCaseOrUpperCaseIdentifiers
/* eslint-disable camelcase */
(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name mlpNotifications.provider:OneSignal
   *
   * @description
   *
   */
  angular
    .module('mlp-notifications')
    .provider('OneSignal', OneSignalProvider);

  function OneSignalProvider() {
    var appId,
        safariWebId,
        isLoaded = false,
        OneSignal,
        userIdPromise,
        userId,
        isApp;

    return {
      init: function (applicationId, webId) {
        appId = applicationId;
        safariWebId = webId;
      },
      $get: ['$window', '$document', '$http', '$q', 'Restangular', 'Auth', function ($window, $document, $http, $q, Restangular, Auth) {
        var oneSignalApi = $q.defer(),
            scriptElement;

        function init() {
          OneSignal = $window.OneSignal;
          if (!OneSignal) {
            OneSignal = $window.OneSignal = [];
          }

          // Initialise
          OneSignal.push(['init', {
            appId: appId,
            safari_web_id: safariWebId,
            autoRegister: true,
            notificationClickHandlerMatch: 'origin',
            notifyButton: {
              enable: false
            }
          }]);

          // Occurs when the user's subscription changes to a new value.
          OneSignal.push(function () {
            OneSignal.on('subscriptionChange', function (isSubscribed) {
              if (isSubscribed) {
                OneSignal.getUserId().then(function (playerId) {
                  userId = playerId;
                  if (userIdPromise) {
                    userIdPromise.resolve(userId);
                  }
                  Auth.isAuthenticated().then(function (isAuthenticated) {
                    if (isAuthenticated) {
                      Restangular.one('Users').customPOST(null, 'pushNotifications', {
                        oneSignalPlayerId: playerId
                      });
                    }
                  });
                });
              }
              else {
                userId = null;
              }
            });
          });

          // Get UserId
          OneSignal.push(function () {
            OneSignal.getUserId().then(function (osUserId) {
              userId = osUserId;
              if (userIdPromise) {
                userIdPromise.resolve(userId);
              }
            });
          });

          // Resolve the api (everything is ready and initialized)
          oneSignalApi.resolve();
        }

        isApp = $window.navigator.userAgent.indexOf('gonative') > -1;
        userIdPromise = $q.defer();

        if (isApp) {
          $window.gonative_onesignal_info = function (info) {
            userIdPromise.resolve(info.oneSignalUserId);
            userId = info.oneSignalUserId;
          };
          oneSignalApi.resolve();
          isLoaded = true;
        }
        else if (!isLoaded) {
          scriptElement = $document[0].createElement('script');
          scriptElement.setAttribute('async', 'async');
          scriptElement.onload = init;
          $document[0].body.appendChild(scriptElement);
          scriptElement.src = 'https://cdn.onesignal.com/sdks/OneSignalSDK.js';
          isLoaded = true;
        }
        else {
          oneSignalApi.resolve();
        }

        oneSignalApi.isLoaded = function () {
          return isLoaded;
        };

        oneSignalApi.getUserId = function () {
          return userIdPromise.promise;
        };

        return oneSignalApi;
      }]
    };
  }
}());
