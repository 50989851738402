(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name myMemberships.rewardProgram.controller:WelcomeModalCtrl
   *
   * @description
   *
   */
  angular
    .module('myMemberships.rewardProgram')
    .controller('WelcomeModalCtrl', WelcomeModalCtrl);

  function WelcomeModalCtrl($window, $scope, $uibModalInstance) {
    var vm = this;

    // Listen to the broadcast of 'close-modal-instance';
    $scope.$on('close-modal-instance', function () {
      $uibModalInstance.dismiss();
    });

    vm.close = function () {
      $uibModalInstance.close();
    };

    vm.openLink = function (linkUrl) {
      $window.open(linkUrl, '_blank');
    };
  }
}());
