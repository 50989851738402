(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name account.controller:ActivateCtrl
   *
   * @description
   *
   */
  angular
    .module('account')
    .controller('ActivateCtrl', ActivateCtrl);

  function ActivateCtrl($document, $state, $stateParams, FormAlerts, Auth, BusinessAccount, userRegistration, GoogleMap, configOptions) {
    var vm = this;

    vm.data = userRegistration;
    vm.alerts = new FormAlerts();
    vm.isLoading = false;
    vm.data.newsletter = true;
    vm.data.rewardCode = userRegistration.rewardCode;
    vm.rewardCodeReadOnly = !!userRegistration.rewardCode;
    vm.rewardCodeLabel = userRegistration.rewardCode;
    vm.emailVisible = !userRegistration.email;
    vm.passwordVisible = !userRegistration.isPasswordSet;

    // Help texts
    vm.rewardCodeHelp = 'If you have a Reward Code from an existing MyLeadPod business, enter it below to directly connect with this business after activation. (Optional)';

    vm.data.address = {
      streetAddress: userRegistration.streetAddress,
      suburb: userRegistration.suburb,
      state: userRegistration.state,
      postCode: userRegistration.postCode,
      country: userRegistration.country
    };

    vm.activate = function () {
      vm.isLoading = true;
      vm.alerts.reset();
      Auth.activateAccount($stateParams.token, {
        firstName: vm.data.firstName,
        lastName: vm.data.lastName,
        businessName: vm.data.businessName,
        address: vm.data.address,
        phoneMobile: vm.data.phoneMobile,
        newsletter: vm.data.newsletter,
        rewardCode: vm.data.rewardCode,
        email: vm.data.email,
        password: vm.data.password
      })
        .then(function (newSession) {
          var userProfile = newSession.data.profile;

          configOptions.reportRegistration();

          if (angular.isDefined($stateParams.invitationToken) && angular.isString($stateParams.invitationToken)) {
            $state.go('userInvitation', {
              token: $stateParams.invitationToken
            });
          }
          else if (angular.isDefined(userRegistration.idUserRewardCodeOwner) && userRegistration.idUserRewardCodeOwner !== null && angular.isDefined(userRegistration.redirectToUserProfile) && userRegistration.redirectToUserProfile === true) {
            $state.go('user', {id: userRegistration.idUserRewardCodeOwner, isInvite: true});
          }
          else {
            $state.go('dashboard');

            if (angular.isObject(userProfile.businessAccount) && userProfile.businessAccount.isEligibleForTrial === true) {
              BusinessAccount.initiateActivation(angular.element($document[0].body)[0], true);
            }
          }
        }, function (error) {
          vm.isLoading = false;
          if (angular.isObject(error) && error.errorCode === 602) {
            vm.alerts.add('danger', 'Invalid Reward Code');
          }
          else {
            vm.alerts.add('danger', 'Unable to activate');
          }
        });
    };

    vm.placeChangedCallback = function (address) {
      vm.data.address = address;
    };

    if (!vm.data.address.streetAddress) {
      GoogleMap.promise.then(function () {
        GoogleMap.getCurrentLocation().then(function (result) {
          vm.data.location = result.location;
          vm.data.address = result;
        });
      });
    }
  }
}());
