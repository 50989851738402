(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name myPod.sentLeads.controller:SentLeadsCtrl
   *
   * @description
   *
   */
  angular
    .module('myPod.sentLeads')
    .controller('SentLeadsCtrl', SentLeadsCtrl);

  function SentLeadsCtrl(DataTable, Leads) {
    var vm = this,
        filter;
    vm.statuses = {
      all: 'All leads',
      open: 'Delivered',
      new: 'Notified',
      successful: 'Successful',
      unsuccessful: 'Unsuccessful'
    };
    vm.searchLead = '';
    vm.selectedStatus = 'all';
    vm.selectedStatusLabel = 'All leads';
    vm.lastSearched = null;

    vm.list = DataTable.create('Leads', 'index', {}, {
      basicParams: {
        type: 'sent'
      }
    });

    filter = function () {
      vm.list.reload({
        status: vm.selectedStatus,
        search: vm.searchLead
      });
    };

    vm.selectStatus = function (leadStatus) {
      vm.selectedStatus = leadStatus;
      vm.selectedStatusLabel = vm.statuses[leadStatus];
      filter();
    };

    vm.search = function () {
      if (vm.selectedStatus + vm.searchLead === vm.lastSearched) {
        return;
      }
      filter();
      vm.lastSearched = vm.selectedStatus + vm.searchLead;
    };

    vm.openDetail = function ($index) {
      Leads.openDetail(vm.list.getRecords()[$index], 'sent', 'sent-leads-ctrl-container');
    };
  }
}());
