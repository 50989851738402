(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.ui.table.directive:tableUserDetails
   * @restrict E
   * @element
   */
  angular
    .module('components.ui.table')
    .directive('tableUserDetails', tableUserDetails);

  function tableUserDetails($timeout, $templateRequest, $compile, $sce) {
    return {
      restrict: 'E',
      scope: {
        user: '=',
        isLink: '=?',
        descriptionKey: '=?',
        stopPropagation: '=?',
        hasDetails: '=?',
        nameOnly: '=?',
        addClass: '='
      },
      replace: true,
      transclude: false,
      templateUrl: 'components/ui/table/table-user-details-directive.tpl.html',
      compile: function (element, attrs) {
        return {
          pre: function preLink(scope) {
            if (!attrs.isLink) {
              scope.isLink = true;
            }
            if (!attrs.hasDetails) {
              scope.hasDetails = false;
            }
            if (!attrs.nameOnly) {
              scope.nameOnly = false;
            }
            if (!attrs.descriptionKey) {
              scope.descriptionKey = 'businessName';
            }
            if (!attrs.stopPropagation) {
              scope.stopPropagation = true;
            }

            if (scope.user) {
              scope.user.mlpUserDetails = {
                showAdvanced: scope.nameOnly,
                descriptionText: scope.user[scope.descriptionKey]
              };
            }
            // Pre-assign the detailsVisible property to false
            scope.detailsVisible = false;
          },
          post: function postLink(scope, iElement) {
            var templateUrl;
            if (iElement.parent()) {
              iElement.parent().addClass('table-user-detail-holder');
            }
            if (scope.stopPropagation) {
              iElement.bind('click', function (evt) {
                evt.stopPropagation();
              });
            }
            templateUrl = $sce.getTrustedResourceUrl('components/ui/table/table-user-popover.tpl.html');
            if (scope.hasDetails) {
              $templateRequest(templateUrl).then(function (template) {
                var el = angular.element(template)[0],
                    linked = $compile(el)(scope);
                $timeout(function () {
                  scope.$apply();
                  scope.htmlPopover = $sce.trustAsHtml(linked.html());
                }, 100, false);
              }, function () {
                // An error has occurred here
              });
            }
          }
        };
      }
    };
  }
}());
