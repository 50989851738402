(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name account.password.controller:RecoverCtrl
   *
   * @description
   *
   */
  angular
    .module('account.password')
    .controller('RecoverCtrl', RecoverCtrl);

  function RecoverCtrl(Restangular, vcRecaptchaService, FormAlerts, configOptions) {
    var vm = this;
    vm.data = {};
    vm.alerts = new FormAlerts();
    vm.reCaptchaSiteKey = configOptions.reCaptchaSiteKey;
    vm.showMessage = false;
    vm.isLoading = false;

    vm.recover = function () {
      var reCaptchaResponse;
      vm.alerts.reset();
      try {
        reCaptchaResponse = vcRecaptchaService.getResponse();
      }
      catch (err) {
        vcRecaptchaService.reload();
        vm.alerts.add('danger', 'Unable to verify that you are not a robot. Please try again');
        return;
      }

      vm.isLoading = true;
      Restangular.one('Users').post('lostPassword', {
        email: vm.data.email,
        reCaptchaResponse: reCaptchaResponse
      })
        .then(function () {
          vm.showMessage = true;
        }, function () {
          vm.isLoading = false;
          vcRecaptchaService.reload();
          vm.alerts.add('danger', 'Unable to recover');
        });
    };
  }
}());
