<div class="modal-content-container close-lead">
    <div class="row">
      <div ng-form="closeLeadForm" style="position:relative;">

        <!-- Successful Lead -->
        <div class="col-xs-12" ng-if="closeLead.isSuccessful()">
          <div class="row">
            <div class="col-xs-12 lead-detail-header modal-content-title">
              <span class="sub-title">
                Successful lead reward payment
              </span>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12">
              <uib-alert ng-repeat="alert in closeLead.alerts.getList()" type="{{alert.type}}" close="closeLead.alerts.remove($index)">{{alert.message}}</uib-alert>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12">
              <div class="steps">
                <div class="step" data-step-id="0">
                  Select Reward
                </div>
                <div class="step-separator" data-after-step-id="1">
                  <span class="fa fa-chevron-right"></span>
                </div>

                <div class="step" data-step-id="1">
                  Additional Reward
                </div>

                <div class="step-separator no-animate"
                     data-after-step-id="2"
                     ng-show="closeLead.contract.pricingOptions.length >= 1 || closeLead.data.bonusReward">
                  <span class="fa fa-chevron-right"></span>
                </div>
                <div class="step no-animate"
                     data-step-id="2"
                     ng-show="closeLead.contract.pricingOptions.length >= 1 || closeLead.data.bonusReward">
                  Review and Payment
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12">
              <div class="stepped-wrapper" content-slider>
                <div class="stepped-container">
                  <div class="step-content" data-step-id="0">

                    <!-- Reward Program type of Rewards -->
                    <div ng-if="closeLead.contract.pricingOptions.length >= 1">
                      <div class="row">
                        <div class="col-xs-12 form-group">
                          Select a reward for this lead:
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-xs-12">
                          <div class="reward-system-selector form-group"
                               ng-repeat="option in closeLead.contract.pricingOptions"
                               ng-class="{active: closeLead.selectedPricingOption === option}"
                               ng-click="closeLead.onPricingOptionChange(closeLeadForm, $index)">

                              <input type="radio"
                                     name="idPricingOption"
                                     id="idPricingOption_{{$index}}"
                                     value="{{option.id}}"
                                     ng-model="closeLead.data.idPricingOption"
                                     ng-required="true" />

                              <label class="radio-label" for="idPricingOption_{{$index}}">
                                {{option.name}} - {{option.successfulLeadPrice | currency:'$':0}}
                              </label>
                          </div>
                        </div>
                      </div>
                      <div class="step-buttons" data-active-step-id="1">
                        <button class="btn btn-primary" data-step-to="1" ng-disabled="closeLead.isLoading || closeLead.calculating || !closeLead.data.idPricingOption">
                          Next
                        </button>
                      </div>
                    </div>

                    <!-- Subscription-based Rewards -->
                    <div ng-if="closeLead.contract.contractType.toLowerCase() === 'subscription'">
                      <div class="row">
                        <div class="col-xs-12 control-description">
                          The reward contract between you and <strong>{{closeLead.lead.sender.fullName}}</strong> is based on SUBSCRIPTION model.
                        </div>
                        <div class="col-xs-12 control-description">
                          This user is rewarded periodically an amount of:
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-xs-12">
                          <div class="reward-system-selector form-group active">
                            <div class="col-xs-12">
                              <input type="radio" id="subscriptionValue" name="subscriptionValue" checked />
                              <label class="radio-label" for="subscriptionValue">
                                {{closeLead.contract.subscriptionPrice | currency:'$':0}}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="step-buttons" data-active-step-id="1">
                        <button class="btn btn-primary" data-step-to="1">
                          Next
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="step-content" data-step-id="1">
                    <div>
                      <div class="row form-group">
                        <div class="col-xs-12 control-description">
                          If you'd like to pay the extra reward, enter the amount below:
                        </div>
                        <div class="col-xs-12" ng-class="{'has-error': (closeLeadForm.$submitted || closeLeadForm.bonusReward.$touched) &&
                        (closeLeadForm.bonusReward.$error.number || closeLeadForm.bonusReward.$error.min)}">
                          <label class="sr-only" for="bonusReward">Additional reward</label>
                          <div ng-show="closeLeadForm.$submitted || closeLeadForm.bonusReward.$touched">
                            <div class="input-error" ng-show="closeLeadForm.bonusReward.$error.number">Invalid optional reward
                            </div>
                            <div class="input-error" ng-show="closeLeadForm.bonusReward.$error.min">Optional reward must not be negative
                            </div>
                          </div>
                          <div class="left-inner-addon input-group" style="width: 100%">
                            <i class="fa fa-dollar inner-addon"></i>
                            <input class="form-control input-lg" type="number" name="bonusReward" id="bonusReward"
                                   placeholder="Additional reward (optional)"
                                   ng-required="false" ng-model="closeLead.data.bonusReward" min="0"
                                   ng-change="closeLead.onBonusRewardChange(closeLeadForm)"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row form-group">
                        <div class="col-xs-12 control-description">
                          Provide any additional feedback / comment / appraisal to {{closeLead.lead.sender.fullName}}:
                        </div>
                        <div class="col-xs-12" ng-class="{'has-error': (closeLeadForm.$submitted || closeLeadForm.feedback.$touched) && closeLeadForm.feedback.$error.required}">
                          <label class="sr-only" for="feedback">Please provide feedback for this lead</label>
                          <div ng-show="closeLeadForm.$submitted || closeLeadForm.feedback.$touched">
                            <div class="input-error" ng-show="closeLeadForm.feedback.$error.required">Missing feedback</div>
                          </div>
                          <div class="left-inner-addon">
                            <i class="fa fa-comment-o inner-addon"></i>
                            <textarea class="form-control input-lg lead-note-input" name="feedback" id="feedback" placeholder="Please provide feedback for this lead"
                                      ng-required="true" ng-model="closeLead.data.feedback"></textarea>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- buttons if the rewards are based on reward options -->
                    <div class="step-buttons"
                         data-active-step-id="2"
                         ng-if="closeLead.contract.pricingOptions.length >= 1">
                      <button class="btn btn-primary" data-step-to="0">
                        Back
                      </button>
                      <button class="btn btn-primary no-animate"
                              data-step-to="2"
                              ng-disabled="closeLead.isLoading || closeLead.calculating"
                              ng-click="closeLead.calculateTotal($event)">
                        Next
                      </button>
                    </div>

                    <!-- buttons if the rewards are based on subscription model -->
                    <div class="step-buttons no-animate"
                         data-active-step-id="2"
                         ng-if="closeLead.contract.contractType.toLowerCase() === 'subscription'">
                      <button class="btn btn-primary" data-step-to="0">
                        Back
                      </button>
                      <button class="btn btn-primary no-animate"
                              data-step-to="2"
                              ng-disabled="closeLead.isLoading || closeLead.calculating"
                              ng-click="closeLead.calculateTotal($event)"
                              ng-show="closeLead.data.bonusReward">
                        Next
                      </button>
                      <button ng-show="!closeLead.data.bonusReward"
                              class="btn btn-primary no-animate"
                              ng-disabled="closeLeadForm.$invalid || closeLead.isLoading"
                              ng-click="closeLead.close()">
                        {{closeLead.isLoading ? 'Loading ...' : 'Close and Pay'}}
                      </button>
                    </div>
                  </div>

                  <div class="step-content" data-step-id="2">
                    <div>
                      <div class="form-group" style="min-height: 150px; position: relative;">
                        <div class="pending-overlay-light" ng-if="closeLead.calculating">
                          <span class="fa fa-2x fa-circle-o-notch fa-spin-2x"></span>
                        </div>
                        <div class="close-lead-invoice">
                          <div class="row">
                            <div class="col-xs-12 form-group">
                              Payment Review:
                            </div>
                          </div>
                          <div class="row" ng-if="closeLead.showPriceBreakdown() && closeLead.chargeForSuccessfulLead()">
                            <div class="col-xs-6">
                              Successful lead reward
                            </div>
                            <div class="col-xs-6 text-right">
                              {{closeLead.getSuccessfulLeadPrice() | currency:'$':2}}
                            </div>
                          </div>
                          <div class="row" ng-if="closeLead.showPriceBreakdown() && !!closeLead.data.bonusReward">
                            <div class="col-xs-6">
                              Bonus reward
                            </div>
                            <div class="col-xs-6 text-right">
                              {{closeLead.data.bonusReward | currency:"$":2}}
                            </div>
                          </div>
                          <div class="row" ng-if="closeLead.showPriceBreakdown() && !closeLead.calculating && !closeLead.calculationRequired">
                            <div class="col-xs-6">
                              Processing fee
                            </div>
                            <div class="col-xs-6 text-right">
                              {{closeLead.processingFee | currency:"$":2}}
                            </div>
                          </div>
                          <div class="row" ng-if="closeLead.showPriceBreakdown() && !closeLead.calculating && !closeLead.calculationRequired">
                            <div class="col-xs-6">
                              GST
                            </div>
                            <div class="col-xs-6 text-right">
                              {{closeLead.tax | currency:"$":2}}
                            </div>
                          </div>
                          <div class="row" style="border-top: 1px lightgray dotted; padding-top: 5px; margin-top: 3px;"
                               ng-if="closeLead.showPriceBreakdown() && !closeLead.calculating && !closeLead.calculationRequired">
                            <div class="col-xs-6">
                              <strong>Total charged</strong>
                            </div>
                            <div class="col-xs-6 text-right">
                              <strong>{{closeLead.total | currency:"$":2}}</strong>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div>
                        <div class="row">
                          <div class="col-xs-12 form-group">
                            Payment Details:
                          </div>
                        </div>
                        <credit-card user="closeLead.user" credit-card="closeLead.creditCard" cc-data="closeLead.data.creditCard" cc-show-form="closeLead.showCCForm()"></credit-card>
                      </div>

                    </div>

                    <div class="step-buttons">
                      <button class="btn btn-primary" data-step-to="1">
                        Back
                      </button>
                      <button class="btn btn-primary" ng-disabled="closeLeadForm.$invalid || closeLead.isLoading" ng-click="closeLead.close()">
                        {{closeLead.isLoading ? 'Loading ...' : 'Close and Pay'}}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <!-- Unsuccessful Lead -->
        <div class="col-xs-12" ng-if="!closeLead.isSuccessful()">
          <div class="row">
            <div class="col-xs-12 lead-detail-header modal-content-title">
              <span class="sub-title">
                Unsuccessful Lead
              </span>
            </div>
          </div>
          <div class="form-group">
            Are you sure you want to close this lead as <strong>'UNSUCCESSFUL'</strong>?
          </div>
          <div class="row form-group">
            <div class="col-xs-12 control-description">
              Please provide feedback for this lead
            </div>
            <div class="col-xs-12" ng-class="{'has-error': (closeLeadForm.$submitted || closeLeadForm.feedback.$touched) && closeLeadForm.feedback.$error.required}">
              <label class="sr-only" for="feedback">Please provide feedback for this lead</label>
              <div ng-show="closeLeadForm.$submitted || closeLeadForm.feedback.$touched">
                <div class="input-error" ng-show="closeLeadForm.feedback.$error.required">Missing feedback</div>
              </div>
              <div class="left-inner-addon">
                <i class="fa fa-comment-o inner-addon"></i>
                <textarea class="form-control input-lg lead-note-input" name="feedback" id="feedback" placeholder="Please provide feedback for this lead"
                          ng-required="true" ng-model="closeLead.data.feedback"></textarea>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="row modal-content-buttons">
      <div class="col-xs-12 text-right">
        <button class="btn btn-default" ng-disabled="closeLead.isLoading" ng-click="closeLead.cancel()">
          Cancel
        </button>
        <button ng-if="!closeLead.isSuccessful()"
                class="btn btn-primary"
                ng-class="{loading: closeLead.isLoading}"
                ng-disabled="closeLead.isLoading || closeLeadForm.$invalid"
                ng-click="closeLead.close()">
          Close Lead
        </button>
      </div>
    </div>
</div>
