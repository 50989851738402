<div class="modal-content-container preferences" id="preferences-container">
  <div class="row">
    <!-- Title -->
    <div class="col-xs-12 modal-content-title">
      <span class="sub-title">Preferences</span>
    </div>
    <!-- ENDOF: Title-->

    <!-- alerts -->
    <uib-alert ng-repeat="alert in preferences.alerts.getList()" type="{{alert.type}}" close="preferences.alerts.remove($index)">{{alert.message}}</uib-alert>

    <!-- Content -->
    <div class="col-xs-12 preferences-section">
      <div class="preferences-title">
        Mailing Lists
      </div>

      <div ng-repeat="(listName, list) in preferences.data.mailingLists" class="preferences-content">

          <div class="preferences-content-title">
            <div>
              {{list.label}}
            </div>
            <div class="preferences-content-subtitle">
              <div ng-if="list.description">{{list.description}}</div>
              You are currently
              <span ng-if="list.isSubscribed">subscribed to the mailing list</span>
              <span ng-if="!list.isSubscribed">not subscribed to the mailing list</span>
            </div>
          </div>
          <div class="preferences-tools">
            <div class="compound-buttons">
              <div role="button" tabindex="1"
                   ng-click="preferences.changeSettings('mailingLists.'+listName+'.isSubscribed', true)"
                   ng-class="{'active': list.isSubscribed}">ON
              </div>
              <div role="button" tabindex="1"
                   ng-click="preferences.changeSettings('mailingLists.' + listName+'.isSubscribed', false)"
                   ng-class="{'active': !list.isSubscribed}">OFF
              </div>
            </div>
          </div>

      </div>
    </div>
      <!-- ENDOF: Content -->
  </div>

  <div class="row modal-content-buttons">
    <div class="text-right">
      <button type="button" class="btn btn-default" ng-disabled="preferences.isSaving" ng-click="preferences.cancel()">
        Cancel
      </button>
      <button type="button" class="btn btn-primary"
              ng-disabled="!preferences.hasChanges || preferences.isSaving" ng-click="preferences.saveChanges()">
        {{preferences.isSaving ? 'Saving ...' : 'Save'}}
      </button>
    </div>
  </div>
</div>


