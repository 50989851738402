(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name myPod.sendInvite.controller:SendInviteModalCtrl
   *
   * @description
   *
   */
  angular
    .module('myPod.sendInvite')
    .controller('SendInviteModalCtrl', SendInviteModalCtrl);

  function SendInviteModalCtrl($state, $timeout, Restangular, Notification, FormAlerts, $uibModalInstance, userProfile) {
    var vm = this;
    vm.isLoading = false;
    vm.isLoadingUserData = false;
    vm.searchControlName = 'sendInviteSearchControl';
    vm.data = {
      firstName: null,
      email: null,
      phoneMobile: null
    };
    vm.alerts = new FormAlerts();
    vm.displayContactDetails = false;
    vm.hasRewardCode = true;
    vm.isRewardCodeValid = false;
    vm.isRewardCodeInvalid = false;
    vm.targetUser = userProfile;
    vm.emptyResults = false;
    vm.searchCategories = [{
      name: 'Directory',
      value: 'businessUsers',
      stateTarget: 'user',
      iconClass: 'fa-briefcase'
    }];

    if (angular.isDefined(userProfile) && angular.isObject(userProfile)) {
      vm.data.firstName = userProfile.firstName;
      vm.data.email = userProfile.email;
      vm.data.phoneMobile = userProfile.phoneMobile;
    }

    vm.cancel = function () {
      $uibModalInstance.dismiss();
    };

    vm.send = function () {
      vm.isLoading = true;
      vm.alerts.reset();

      Restangular.one('Members').post('index', vm.data)
        .then(function (response) {
          vm.isLoading = false;
          $uibModalInstance.close(response.sendLeads);
          Notification({message: 'Success'}, 'confirmation');
        }, function () {
          vm.isLoading = false;
          vm.alerts.add('danger', 'Unable to send');
          $uibModalInstance.dismiss();
        });
    };

    vm.checkRewardCode = function () {
      if (vm.isRewardCodeValid) {
        vm.rewardCode = null;
        vm.isRewardCodeInvalid = false;
        vm.isRewardCodeValid = false;
      }
      else {
        vm.rewardCode = vm.rewardCode.toUpperCase().replace(/\s+/g, '-');
        vm.isCheckingCode = true;
        Restangular.one('RewardCodes').one('validate').get({rewardCode: vm.rewardCode})
          .then(function (result) {
            vm.isRewardCodeInvalid = !result.valid;
            vm.isRewardCodeValid = result.valid;
            Restangular.one('RewardCodes').post('activate', {
              rewardCode: vm.rewardCode
            })
              .then(function (activationResult) {
                if (angular.isObject(activationResult) && angular.isDefined(activationResult.idUser) && activationResult.idUser > 0) {
                  $uibModalInstance.close({isRedirecting: true});
                  Notification({message: 'Success'}, 'confirmation');
                  $state.go('user.agreements', {id: activationResult.idUser});
                }
                else {
                  vm.isLoading = false;
                  vm.isRewardCodeInvalid = true;
                  vm.isRewardCodeValid = false;
                  vm.isCheckingCode = false;
                }
              }, function () {
                vm.isLoading = false;
                vm.isRewardCodeInvalid = true;
                vm.isRewardCodeValid = false;
                vm.isCheckingCode = false;
              });
          }, function () {
            vm.isRewardCodeInvalid = true;
            vm.isRewardCodeValid = false;
            vm.isCheckingCode = false;
          });
      }
    };

    vm.onRewardCodeEnter = function ($event) {
      if ($event.key === 'Enter') {
        $event.stopImmediatePropagation();
        vm.checkRewardCode();
      }
    };

    vm.searchResponseFormatter = function (response) {
      var r,
          records;

      vm.userInvitedProfile = null;

      if (angular.isUndefined(response) || !response) {
        return null;
      }
      vm.emptyResults = false;
      r = response.records;
      if (r.length === 0) {
        vm.emptyResults = true;
      }

      records = _.map(r, function (item) {
        var category = _.find(vm.searchCategories, {value: item.datasource}),
            returnObject = {},
            categoryDescriptionClass,
            value = item.fullname;

        categoryDescriptionClass = category.iconClass || 'fa-user';
        if (item.companyname && item.companyname !== item.fullname) {
          value += ' | ' + item.companyname;
        }

        returnObject = {
          value: value,
          id: item.id,
          category: item.datasource,
          categoryDescription: '<i class="fa ' + categoryDescriptionClass + '"></i>',
          timeDescription: item.datecreatedformatted ? item.datecreatedformatted : '',
          leadtype: item.leadtype
        };

        return returnObject;
      });

      return records;
    };

    vm.onSearchItemSelected = function (item) {
      // Callback on when the user selected the search item.
      // Pull the stateTarget from category
      if (!item) {
        return;
      }
      vm.displayContactDetails = true;
      vm.isLoadingUserData = true;
      Restangular.one('Users').one('profile').get({id: item.originalObject.id})
        .then(function (profile) {
          profile = profile.plain();
          vm.data.phoneMobile = profile.phoneMobile;
          vm.data.firstName = profile.firstName;
          vm.data.email = profile.email;
          vm.userInvitedProfile = profile;
          vm.isLoadingUserData = false;
        });
    };

    vm.clearContactData = function (focus) {
      vm.displayContactDetails = false;
      vm.data.email = null;
      vm.data.phoneMobile = null;
      vm.data.firstName = null;
      if (focus) {
        $timeout(function () {
          angular.element('[name=\'' + vm.searchControlName + '\']').focus();
        }, 200);
      }
    };

    vm.checkContactData = function () {
      if (vm.displayContactDetails === true) {
        return vm.data.email && vm.data.firstName;
      }

      return true;
    };

    vm.openSearch = function () {
      vm.onlySearch = true;
      vm.onlyRewards = false;

      $timeout(function () {
        angular.element('[name=\'' + vm.searchControlName + '\']').focus();
      }, 200);
    };

    vm.focusIn = function () {
      vm.hasRewardCode = false;
      vm.onlyRewards = false;
      vm.onlySearch = true;
    };

    vm.openRewardCode = function () {
      vm.hasRewardCode = true;
      vm.clearContactData(false);
      $timeout(function () {
        angular.element('#rewardCode').focus();
      }, 200);
    };
  }
}());
