(function () {
  'use strict';

  angular
    .module('account.billing')
    .config(config);

  function config($stateProvider, configOptions) {
    $stateProvider
      .state('account.billing', angular.extend({}, configOptions.defaultStateParams, {
        url: '/billing',
        templateUrl: 'account/account.tpl.html',
        controller: 'AccountCtrl',
        controllerAs: 'account',
        onEnter: ['RouterHelper', function (RouterHelper) {
          RouterHelper.activateTab('account', 'billing');
        }]
      }));
  }
}());
