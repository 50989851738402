(function () {
  'use strict';

  /* @ngdoc object
   * @name api
   * @description
   *
   */
  angular
    .module('api', [
      'cache'
    ]);
}());
