(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name myMemberships.controller:MyMembershipsCtrl
   *
   * @description
   *
   */
  angular
    .module('myMemberships')
    .controller('MyMembershipsCtrl', MyMembershipsCtrl);

  function MyMembershipsCtrl(Notification, userProfile, BusinessAccount, Auth, MyMemberships) {
    var vm = this;
    vm.isActivating = false;

    vm.tabManager = null;
    vm.tabsConfig = [
      {
        label: 'Connections',
        value: 'memberships',
        url: 'myMemberships.memberships'
      },
      {
        label: 'Received Leads',
        value: 'receivedLeads',
        url: 'myMemberships.receivedLeads'
      },
      {
        label: 'Reward Codes',
        value: 'rewardPrograms',
        url: 'myMemberships.rewardPrograms'
      },
      {
        label: 'Import',
        value: 'import',
        url: 'myMemberships.import',
        condition: function () {
          return vm.isImportAllowed();
        }
      }
    ];

    // Get the trial subscription data (if any)
    vm.trialData = BusinessAccount.getTrialData(userProfile);

    vm.isBusinessAccountActive = function () {
      return angular.isObject(userProfile) && angular.isDefined(userProfile.isBusinessAccountActive) && userProfile.isBusinessAccountActive === true;
    };

    vm.isImportAllowed = function () {
      return Auth.hasUserPermission(userProfile, 'importMemberships');
    };

    vm.activateBusinessAccount = function () {
      vm.isActivating = true;
      BusinessAccount.initiateActivation('my-memberships-ctrl-container', vm.trialData.isEligibleForTrial)
        .then(function (newUserProfile) {
          Notification({message: 'Your business account has been successfully activated.'}, 'confirmation');
          vm.isActivating = false;
          userProfile = newUserProfile;
        }, function () {
          vm.isActivating = false;
        });
    };

    vm.openAskForLeads = function () {
      var modalInstance = MyMemberships.openAskForLeads(userProfile, null);
      modalInstance.then(function (receiveLeads) {
        vm.receiveLeads = receiveLeads;
      });
    };
  }
}());
