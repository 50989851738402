(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name myMemberships.rewardProgram.service:RewardCodes
   *
   * @description
   *
   */
  angular
    .module('myMemberships.rewardProgram')
    .service('RewardCodes', RewardCodes);

  function RewardCodes($document, $q, $state, $uibModal, Session, Restangular, configOptions, $httpParamSerializer) {
    var self = this;

    self.openDetail = function (rewardCode, parentElementId) {
      var modalInstance,
          params = {
            animation: false,
            templateUrl: 'my-memberships/reward-program/reward-code-detail.tpl.html',
            controller: 'RewardCodeDetailCtrl',
            controllerAs: 'rewardCodeDetail',
            size: 'md',
            resolve: {
              rewardCode: function () {
                return rewardCode;
              }
            }
          };
      if (angular.isDefined(parentElementId) && !!parentElementId) {
        params.appendTo = angular.element($document[0].getElementById(parentElementId));
      }
      modalInstance = $uibModal.open(params);

      return modalInstance.result;
    };

    self.openWelcome = function () {
      var params = {
        animation: true,
        templateUrl: 'my-memberships/reward-program/welcome-modal-template.tpl.html',
        controller: 'WelcomeModalCtrl',
        controllerAs: 'welcome',
        size: 'lg',
        backdrop: 'static',
        keyboard: false
      };
      $uibModal.open(params);
    };

    self.openEmbed = function (rewardCode, parentElementId) {
      var modalInstance,
          params = {
            animation: false,
            templateUrl: 'my-memberships/reward-program/reward-code-embed.tpl.html',
            controller: 'RewardCodeEmbedCtrl',
            controllerAs: 'rewardCodeEmbed',
            size: 'lg',
            resolve: {
              rewardCode: function () {
                return rewardCode;
              }
            }
          };
      if (angular.isDefined(parentElementId) && !!parentElementId) {
        params.appendTo = angular.element($document[0].getElementById(parentElementId));
      }
      modalInstance = $uibModal.open(params);

      return modalInstance.result;
    };

    self.create = function (rewardCode, rewardType, options, description, userShareEmail, associatedUserIds) {
      var deferred = $q.defer();
      Restangular.one('RewardCodes').post('index', {
        rewardCode: rewardCode,
        rewardType: rewardType,
        options: options,
        description: description,
        userShareEmail: userShareEmail,
        associatedUsers: associatedUserIds
      })
        .then(function (result) {
          deferred.resolve(result.plain());
        }, function (error) {
          deferred.reject(error.data.errorCode);
        });

      return deferred.promise;
    };

    self.update = function (idRewardCode, data) {
      var deferred = $q.defer();
      Restangular.one('RewardCodes').customPUT(data, 'index?id=' + idRewardCode)
        .then(function (result) {
          deferred.resolve(result.plain());
        }, function (error) {
          deferred.reject(error.data.errorCode);
        });

      return deferred.promise;
    };

    self.getRewardCodeUrl = function (rewardCode) {
      return $state.href('myMembershipsRewardProgramCode', {code: rewardCode}, {absolute: true});
    };

    self.isRewardCodeUnique = function (rewardCode) {
      return Restangular.one('RewardCodes').one('unique').get({rewardCode: rewardCode})
        .then(function (result) {
          return result.unique;
        }, function () {
          return false;
        });
    };

    self.isUserShareEmailValid = function (userShareEmail) {
      return Restangular.one('RewardCodes').one('checkUserShareEmail').get({email: userShareEmail})
        .then(function (result) {
          return result.isValid;
        }, function () {
          return false;
        });
    };

    self.getDefaultRewardOptions = function () {
      return Restangular.one('RewardCodes').getList('pricingOptionsTemplate')
        .then(function (result) {
          var options = [];
          angular.forEach(result, function (value) {
            options.push(value.plain());
          });
          return options;
        }, function () {
          return [];
        });
    };

    self.getRewardCodes = function () {
      return Restangular.one('RewardCodes').getList('index?' + $httpParamSerializer({
        limit: -1,
        orderby: 'rewardCode'
      })).then(function (result) {
        var options = [];
        angular.forEach(result, function (value) {
          options.push(value.plain());
        });
        return options;
      });
    };

    self.saveDefaultRewardOptions = function (options) {
      var deferred = $q.defer();
      Restangular.one('RewardCodes').customPUT({
        options: options
      }, 'pricingOptionsTemplate')
        .then(function (result) {
          deferred.resolve(result.plain());
        }, function (error) {
          deferred.reject(error.data.errorCode);
        });

      return deferred.promise;
    };

    self.getRegistrationFormCode = function (rewardCode) {
      return '<iframe frameborder="0" width="440" height="440" src="' + configOptions.appUrl + '/widgets/register/?rewardCode=' + rewardCode + '"></iframe>';
    };

    self.openRewardCreateModal = function () {
      var modalInstance = $uibModal.open({
        animation: false,
        appendTo: angular.element($document[0].body),
        templateUrl: 'my-memberships/reward-program/reward-code-create-modal.tpl.html',
        controller: 'RewardCodeCreateCtrl',
        controllerAs: 'rewardProgram',
        size: 'md'
      });

      return modalInstance.result;
    };

    self.archive = function (idRewardCode) {
      var deferred = $q.defer();
      Restangular.one('RewardCodes').customPUT({}, 'archive?id=' + idRewardCode)
        .then(function (result) {
          deferred.resolve(result.plain());
        }, function (error) {
          deferred.reject(error.data.errorCode);
        });

      return deferred.promise;
    };

    self.updateRewardProgramsAndCodes = function (broadcast, callback) {
      var programDeferred = $q.defer(),
          codesDeferred = $q.defer();

      // Get the default reward program
      self.getDefaultRewardOptions().then(function (response) {
        programDeferred.resolve(response);
      });

      // Get the reward codes.
      self.getRewardCodes().then(function (response) {
        codesDeferred.resolve(response);
      });

      $q.all({program: programDeferred.promise, codes: codesDeferred.promise})
        .then(function (result) {
          Session.updateLocalUserProfile({
            defaultRewardProgram: result.program,
            rewardCodes: result.codes
          }, broadcast);
          if (angular.isDefined(callback) && angular.isFunction(callback)) {
            callback.apply();
          }
        });
    };
  }
}());
