(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name account.billing.controller:BillingCtrl
   *
   * @description
   *
   */
  angular
    .module('account.billing')
    .controller('BillingCtrl', BillingCtrl);

  function BillingCtrl($document, Restangular, $uibModal, Notification, Session, DataTable) {
    var vm = this;
    vm.initialisingCard = true;
    vm.cardLoading = false;
    vm.invoicesExist = true;
    vm.creditCard = null;
    vm.invoices = DataTable.create('Invoices', 'index', {}, {
      itemCallback: function (item) {
        item.downloadUrl = '/Invoices/download?id=' + item.idInvoice;
        return item;
      }
    });

    Restangular.one('Billing').one('creditCard').get()
      .then(function (creditCard) {
        if (angular.isObject(creditCard) && angular.isDefined(creditCard.number)) {
          vm.creditCard = creditCard.plain();
        }
        vm.initialisingCard = false;
      }, function () {
        vm.initialisingCard = false;
      });

    vm.manageCreditCard = function (operation) {
      var modalInstance = $uibModal.open({
        animation: false,
        appendTo: angular.element($document[0].getElementById('billing-ctrl-container')),
        templateUrl: 'account/billing/credit-card.tpl.html',
        controller: 'CreditCardCtrl',
        controllerAs: 'creditCard',
        size: 'lg',
        windowClass: 'modal-credit-card',
        resolve: {
          operation: function () {
            return operation;
          }
        }
      });

      modalInstance.result.then(function (result) {
        if (angular.isObject(result) && angular.isObject(result.creditCard) && angular.isDefined(result.creditCard.number)) {
          vm.creditCard = result.creditCard;
          Session.updateLocalUserProfile({isCCSet: true});
          Notification({message: 'Your credit card details have been successfully updated'}, 'confirmation');
        }
      });
    };

    vm.removeCreditCard = function () {
      vm.cardLoading = true;
      Restangular.one('Billing').one('creditCard').remove()
        .then(function () {
          vm.creditCard = null;
          vm.cardLoading = false;
          Session.updateLocalUserProfile({isCCSet: false});
          Notification({message: 'Your credit card details have been removed'}, 'confirmation');
        }, function () {
          vm.cardLoading = false;
        });
    };
  }
}());
