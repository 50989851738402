(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name mlpNotifications.constant:notificationConst
   *
   * @description
   *
   */
  angular
    .module('mlp-notifications')
    .constant('notificationConst', {
      categoryMessages: 'messages',
      categoryLeads: 'leads',
      typeDirectMessage: 'direct message',
      typeLeadMessage: 'lead message',
      typeNewLead: 'new lead'
    });
}());
