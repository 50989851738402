<div class="col-xs-12" ng-controller="EarningsCtrl as earnings" id="earnings-ctrl-container">
  <div class="page-content">
    <div class="earnings-cells">
      <div class="col-xs-6 col-sm-6 col-md-3 earnings-cell">
        <div class="earnings-cell-content">
          <div class="earnings-cell-value earnings-cell-value-link" ng-click="earnings.viewLastMonthEarnings()">
            <span ng-show="earnings.isLoadingSummaries"><i class="fa fa-circle-o-notch fa-spin-2x"></i></span>
            <span ng-hide="earnings.isLoadingSummaries">{{earnings.summaries.lastMonthEarnings | currency:'$':0}}</span>
          </div>
          <div class="earnings-cell-info">
            last month
          </div>
          <div class="earnings-cell-action hidden-xs">
            <button class="btn btn-default" ng-click="earnings.viewLastMonthEarnings()">View All</button>
          </div>
        </div>
      </div>
      <div class="col-xs-6 col-sm-6 col-md-3 earnings-cell">
        <div class="earnings-cell-content">
          <div class="earnings-cell-value earnings-cell-value-link" ng-click="earnings.viewAllEarnings()">
            <span ng-show="earnings.isLoadingSummaries"><i class="fa fa-circle-o-notch fa-spin-2x"></i></span>
            <span ng-hide="earnings.isLoadingSummaries">{{earnings.summaries.totalEarnings | currency:'$':0}}</span>
          </div>
          <div class="earnings-cell-info">
            total earnings
          </div>
          <div class="earnings-cell-action hidden-xs">
            <button class="btn btn-default" ng-click="earnings.viewAllEarnings()">View All</button>
          </div>
        </div>
      </div>
      <div class="col-xs-6 col-sm-6 col-md-3 earnings-cell">
        <div class="earnings-cell-content">
          <div class="earnings-cell-value earnings-cell-value-link" ng-click="earnings.tabManager.openTab('withdrawals')">
            <span ng-show="earnings.isLoadingSummaries"><i class="fa fa-circle-o-notch fa-spin-2x"></i></span>
            <span ng-hide="earnings.isLoadingSummaries">{{earnings.summaries.withdrawals | currency:'$':0}}</span>
          </div>
          <div class="earnings-cell-info">
            withdrawn
          </div>
          <div class="earnings-cell-action hidden-xs">
            <button class="btn btn-default" ng-click="earnings.tabManager.openTab('withdrawals')">View Details</button>
          </div>
        </div>
      </div>
      <div class="col-xs-6 col-sm-6 col-md-3 earnings-cell">
        <div class="earnings-cell-content">
          <div class="earnings-cell-value">
            <span ng-show="earnings.isLoadingSummaries"><i class="fa fa-circle-o-notch fa-spin-2x"></i></span>
            <span ng-hide="earnings.isLoadingSummaries">{{earnings.summaries.actualEarnings | currency:'$':0}}</span>
          </div>
          <div class="earnings-cell-info">
            current balance
          </div>
          <div class="earnings-cell-action hidden-xs">
            <button class="btn btn-default" ng-click="earnings.withdraw()">Withdraw</button>
          </div>
        </div>
      </div>
    </div>

    <div class="row earnings-withdraw-button-row">
      <div class="col-xs-12 visible-xs">
        <button class="btn btn-default" ng-click="earnings.withdraw()">Withdraw</button>
      </div>
    </div>

    <div class="row earnings-tabs">
      <div class="col-xs-12">
        <tabs-manager tab-id="earnings" target="secondary" tabs-config="earnings.tabsConfig" default-tab="earnings" tab-manager="earnings.tabManager"></tabs-manager>
      </div>
    </div>

    <div class="row" ng-show="earnings.tabManager.isOpen('earnings')">
      <div class="col-xs-12">

        <div class="row" ng-hide="earnings.list.recordsExist() || !earnings.list.isInitialised()">
          <div class="col-xs-12">
            <div class="page-empty-section text-center">
              <i class="fa fa-money fa-2x text-light-colour"></i>
              <div class="page-empty-section-text">
                There's nothing to show, your wallet is empty!
              </div>
            </div>
          </div>
        </div>

        <div class="row" ng-show="earnings.list.recordsExist() && earnings.list.isInitialised()">
          <div class="col-xs-12 col-sm-5 col-md-4 col-lg-3">
            <div class="form-group btn-group page-filter dropdown-large" uib-dropdown>
              <button id="earnings-range" type="button" class="btn" uib-dropdown-toggle>
                {{earnings.selectedRangeLabel}} <span class="caret"></span>
              </button>
              <ul class="dropdown-menu" uib-dropdown-menu role="menu" aria-labelledby="earnings-range">
                <li role="menuitem" ng-repeat="(value, label) in earnings.ranges">
                  <a ng-click="earnings.selectRange(value)">{{label}}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="table-container">
        <table st-table="earnings.list.getRecords()" st-pagination-scroll st-pipe="earnings.list.load" class="table" ng-show="earnings.list.recordsExist() && earnings.list.isInitialised()">
          <thead class="hidden-xs">
          <tr>
            <th class="col-sm-5">Received from</th>
            <th class="col-sm-3">Date</th>
            <th class="col-sm-2 text-right">Amount</th>
            <th class="col-sm-2 text-right">Details</th>
          </tr>
          </thead>
          <tbody ng-hide="earnings.list.isLoading() && !earnings.list.isLoadingMoreRecords()">
          <tr ng-repeat="row in earnings.list.getRecords()">
            <td class="col-xs-12 col-sm-5">
              <table class="table-cell-main-content">
                <tr>
                  <td>
                    <table-user-details user="row.user"></table-user-details>
                  </td>
                  <td class="visible-xs text-right">
                  <span class="cursor-pointer" ng-click="earnings.tableEarningsSubContentVisible[$index] = !earnings.tableEarningsSubContentVisible[$index]">
                    <i class="fa fa-ellipsis-h"></i>
                  </span>
                  </td>
                </tr>
              </table>
              <div class="table-cell-sub-content-container" ng-show="earnings.tableEarningsSubContentVisible[$index]">
                <div class="table-cell-sub-content visible-xs">
                  <span class="text-muted">Date</span><br/>
                  <span class="text-normal">{{row.date | date:'d MMM yyyy'}}</span>
                </div>
                <div class="table-cell-sub-content visible-xs">
                  <span class="text-muted">Amount</span><br/>
                  <span class="text-normal">{{row.amount | currency:'$':0}}</span>
                </div>
                <div class="table-cell-sub-content visible-xs" ng-if="row.idLead">
                  <span class="text-muted">Details</span><br/>
                  <span class="text-normal"><a class="cursor-pointer" ng-click="earnings.openLead($index)">View lead</a></span>
                </div>
              </div>
            </td>
            <td class="col-sm-3 hidden-xs">{{row.date | date:'d MMM yyyy'}}</td>
            <td class="col-sm-2 hidden-xs text-right">{{row.amount | currency:'$':0}}</td>
            <td class="col-sm-2 hidden-xs text-right"><a class="cursor-pointer" ng-if="row.idLead" ng-click="earnings.openLead($index)">View lead</a></td>
          </tr>
          </tbody>
          <tbody ng-if="earnings.list.isLoading()">
          <tr>
            <td colspan="5" class="col-xs-12 text-center">
              <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>
            </td>
          </tr>
          </tbody>
        </table>
        </div>

        <div class="row" ng-show="!earnings.list.isInitialised()">
          <div class="col-xs-12 text-center">
            <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>
          </div>
        </div>

      </div>
    </div>

    <div class="row" ng-show="earnings.tabManager.isOpen('withdrawals')">
      <div class="col-xs-12 table-container table-container-withdrawals">

        <div class="row" ng-hide="earnings.withdrawalsList.recordsExist() || !earnings.withdrawalsList.isInitialised()">
          <div class="col-xs-12">
            <div class="page-empty-section text-center">
              <i class="fa fa-money fa-2x text-light-colour"></i>
              <div class="page-empty-section-text">
                You haven't made any withdrawals
              </div>
              <div class="page-empty-section-text">
                <button class="btn btn-primary" ng-click="earnings.withdraw()">Withdraw</button>
              </div>
            </div>
          </div>
        </div>

        <table st-table="earnings.withdrawalsList.getRecords()" st-pagination-scroll st-pipe="earnings.withdrawalsList.load" class="table" ng-show="earnings.withdrawalsList.recordsExist() && earnings.withdrawalsList.isInitialised()">
          <thead class="hidden-xs">
          <tr>
            <th class="col-sm-6">Date</th>
            <th class="col-sm-6 text-right">Amount</th>
            <!--<th class="col-sm-4 text-right">Details</th>-->
          </tr>
          </thead>
          <tbody ng-hide="earnings.withdrawalsList.isLoading() && !earnings.withdrawalsList.isLoadingMoreRecords()">
          <tr ng-repeat="row in earnings.withdrawalsList.getRecords()">
            <td class="hidden-xs col-sm-6">{{row.date | date:'d MMM yyyy'}}</td>
            <td class="col-xs-12 col-sm-6 text-sm-right">
              <span class="text-muted visible-xs">{{row.date | date:'d MMM yyyy'}}<br/></span>
              {{row.amount | currency:'$':0}}
            </td>
            <!--<td class="col-sm-4 hidden-xs text-right">Detail</td>-->
          </tr>
          </tbody>
          <tbody ng-if="earnings.withdrawalsList.isLoading()">
          <tr>
            <td colspan="5" class="col-xs-12 text-center">
              <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>
            </td>
          </tr>
          </tbody>
        </table>

        <div class="row" ng-show="!earnings.withdrawalsList.isInitialised()">
          <div class="col-xs-12 text-center">
            <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>
          </div>
        </div>

      </div>
    </div>

  </div>
</div>


