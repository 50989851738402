(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name account.controller:SignInCtrl
   *
   * @description
   *
   */
  angular
    .module('account')
    .controller('SignInCtrl', SignInCtrl);

  function SignInCtrl($state, $stateParams, FormAlerts, Auth, SocialAccount) {
    var vm = this,
        afterSignInCallback;
    vm.data = {};
    vm.alerts = new FormAlerts();
    vm.isLoading = false;

    afterSignInCallback = function () {
      if (angular.isDefined($stateParams.invitationToken) && angular.isString($stateParams.invitationToken)) {
        $state.go('userInvitation', {token: $stateParams.invitationToken});
      }
      else if (angular.isDefined($stateParams.rewardCode) && angular.isString($stateParams.rewardCode)) {
        $state.go('myMembershipsRewardProgramCode', {code: $stateParams.rewardCode});
      }
      else if (angular.isDefined($stateParams.nextState) && angular.isObject($stateParams.nextState) && angular.isDefined($stateParams.nextState.name)) {
        $state.go($stateParams.nextState.name, $stateParams.nextState.params || {});
      }
      else {
        $state.go('dashboard');
      }
    };

    vm.passwordSignIn = function () {
      vm.isLoading = true;
      vm.alerts.reset();
      Auth.passwordSignIn(vm.data.username, vm.data.password)
        .then(afterSignInCallback, function () {
          vm.isLoading = false;
          vm.alerts.add('danger', 'Unable to login');
        });
    };

    vm.socialSignIn = function (provider) {
      vm.isLoading = true;
      vm.alerts.reset();
      SocialAccount.signIn(provider)
        .then(function (result) {
          if (angular.isDefined(result.activationToken)) {
            $state.go('accountActivate', {
              token: result.activationToken,
              nextState: $stateParams.nextState,
              invitationToken: $stateParams.invitationToken
            });
          }
          else {
            afterSignInCallback();
          }
        }, function () {
          vm.isLoading = false;
          vm.alerts.add('danger', 'Unable to login');
        });
    };
  }
}());
