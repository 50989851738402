(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name account.password.controller:ResetCtrl
   *
   * @description
   *
   */
  angular
    .module('account.password')
    .controller('ResetCtrl', ResetCtrl);

  function ResetCtrl($stateParams, Restangular, FormAlerts) {
    var vm = this;
    vm.data = {};
    vm.alerts = new FormAlerts();
    vm.showMessage = false;
    vm.isLoading = false;

    vm.reset = function () {
      vm.isLoading = true;
      vm.alerts.reset();
      Restangular.one('Users').customPUT({
        token: $stateParams.token,
        password: vm.data.password
      }, 'lostPassword')
        .then(function () {
          vm.showMessage = true;
        }, function () {
          vm.isLoading = false;
          vm.alerts.add('danger', 'Unable to reset');
        });
    };
  }
}());
