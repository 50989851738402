(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name mlpMarketplace.controller:MarketplaceInfoCtrl
   * @requires $uibModelInstance
   * @requires FormAlerts
   * @requires marketplaceItem
   * @requires Restangular
   * @requires mlpMarketplace.Marketplace
   * @description
   *
   */
  angular
    .module('mlpMarketplace')
    .controller('MarketplaceActivationCtrl', MarketplaceActivationCtrl);

  function MarketplaceActivationCtrl($timeout, $uibModalInstance, FormAlerts, marketplaceItem, Restangular, Marketplace) {
    var vm = this;
    vm.alerts = new FormAlerts();
    vm.item = marketplaceItem;
    vm.isPaymentRequired = !vm.item.isFree;
    vm.isLoading = false;
    vm.isCCLoading = false;
    vm.creditCard = null;
    vm.data = {
      creditCard: {}
    };

    vm.paymentSummary = {
      price: vm.item.priceMonthly,
      tax: vm.item.priceMonthlyInclGst - vm.item.priceMonthly,
      total: vm.item.priceMonthlyInclGst
    };

    if (vm.isPaymentRequired) {
      vm.isCCLoading = true;
      Restangular.one('Billing').one('creditCard').get()
        .then(function (cc) {
          cc = cc.plain();
          vm.data.creditCard.method = angular.isObject(cc) && angular.isDefined(cc.number) ? 'old' : 'new';
          vm.creditCard = cc;
          $timeout(function () {
            vm.isCCLoading = false;
          }, 300);
        }, function () {
          vm.isCCLoading = false;
        });
    }

    vm.cancel = function () {
      $uibModalInstance.dismiss();
    };

    vm.activate = function () {
      vm.isLoading = true;
      vm.alerts.reset();
      Marketplace.activate(vm.item, vm.data.creditCard)
        .then(function () {
          vm.isLoading = false;
          $uibModalInstance.close({active: true});
        }, function () {
          vm.alerts.add('danger', 'Unable to activate subscription.');
          vm.isLoading = false;
        });
    };
  }
}());
