(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name lead.controller:SendLeadCtrl
   *
   * @description
   *
   */
  angular
    .module('lead')
    .controller('SendLeadCtrl', SendLeadCtrl);

  function SendLeadCtrl($rootScope, $scope, $document, $timeout, $state, $stateParams, Session, Restangular, Notification,
                        userProfile, isRegister, onCancelCallback, onSentCallback) {
    var vm = this;

    vm.isLoading = false;
    vm.validateRecipient = false;
    vm.searchName = '';
    vm.idIndustry = '';
    vm.selectedUsers = [];
    vm.isRegister = isRegister;
    vm.detailsVisible = false;
    vm.controlName = vm.isRegister ? 'register-lead' : 'send-lead';
    if (!vm.isRegister) {
      vm.isRewardsFormValid = true;
    }

    vm.emptyResults = false;
    vm.showPrompt = false;
    vm.searchCategories = [
      {name: 'All', value: 'all'},
      {name: 'My Pod', value: 'myPod', iconClass: 'fa-user'},
      {name: 'Directory', value: 'businessUsers', iconClass: 'fa-briefcase'},
      {name: 'Charities', value: 'charities', stateTarget: 'user', iconClass: 'fa-heart-o'},
      {name: 'Leads', value: 'leads', iconClass: 'fa-paper-plane'}
    ];
    vm.userSearchCategories = [];

    if (isRegister) {
      vm.userSearchCategories = [
        {name: 'My Connections', value: 'myConnections', iconClass: 'fa-user-o'}
      ];
    }
    else {
      vm.userSearchCategories = [
        {name: 'My Pod', value: 'myPod', iconClass: 'fa-user'}
      ];
    }

    vm.selectors = {
      payPerLead: {
        id: 'payPerLead',
        value: 'Pay Per Lead',
        desc: 'Pay Per Lead'
      },
      subscription: {
        id: 'monthlySubscription',
        value: 'Subscription',
        desc: 'Monthly Subscription'
      }
    };

    Session.getUserProfile().then(function (profile) {
      if (profile) {
        vm.user = profile;
        if (vm.user.isBusinessAccountActive) {
          vm.searchCategories.push({name: 'My Connections', value: 'myConnections', iconClass: 'fa-user-o'});
        }
      }
    });

    vm.data = {
      invitation: {}
    };
    vm.invitations = [];

    // If the userProfile is not undefined, this is coming from the send-lead-modal where the user to which the lead is about to be sent is already known.
    if (angular.isDefined(userProfile)) {
      vm.selectedUser = userProfile;
    }
    else if (angular.isDefined($stateParams.user) && angular.isObject($stateParams.user)) {
      vm.selectedUsers.push($stateParams.user);
    }

    if (angular.isDefined($stateParams.lead) && angular.isObject($stateParams.lead)) {
      vm.data.firstName = $stateParams.lead.firstName;
      vm.data.lastName = $stateParams.lead.lastName;
      vm.data.email = $stateParams.lead.email;
      vm.data.phone = $stateParams.lead.phone;
      vm.data.details = $stateParams.lead.details;
    }

    vm.isValidName = function () {
      return !!vm.data.firstName || !!vm.data.lastName;
    };

    vm.isValidContact = function () {
      return !!vm.data.email || !!vm.data.phone;
    };

    vm.removeUser = function ($index) {
      vm.selectedUsers.splice($index, 1);
    };

    vm.isEmptyNewRecipient = function () {
      var isEmpty = true;

      if (angular.isDefined(vm.data.invitation)) {
        if (angular.isDefined(vm.data.invitation.firstName) && !!vm.data.invitation.firstName) {
          isEmpty = false;
        }
        if (angular.isDefined(vm.data.invitation.email) && !!vm.data.invitation.email) {
          isEmpty = false;
        }
        if (angular.isDefined(vm.data.invitation.contractType) && !!vm.data.invitation.contractType) {
          isEmpty = false;
        }
        if (vm.data.invitation.contractType === 'Pay Per Lead') {
          if (angular.isDefined(vm.data.invitation.successfulLeadPrice) && !isNaN(parseFloat(vm.data.invitation.successfulLeadPrice)) && parseFloat(vm.data.invitation.successfulLeadPrice) >= 0) {
            isEmpty = false;
          }
        }
        if (vm.data.invitation.contractType === 'Subscription') {
          if (angular.isDefined(vm.data.invitation.subscriptionPrice) && !isNaN(parseFloat(vm.data.invitation.subscriptionPrice)) && parseFloat(vm.data.invitation.subscriptionPrice) >= 0) {
            isEmpty = false;
          }
        }
      }

      return isEmpty;
    };

    vm.isValidNewRecipient = function () {
      var isValid = true;

      if (!vm.isEmptyNewRecipient()) {
        if (angular.isUndefined(vm.data.invitation.firstName) || !vm.data.invitation.firstName) {
          isValid = false;
        }
        if (angular.isUndefined(vm.data.invitation.email) || !vm.data.invitation.email) {
          isValid = false;
        }
        if (vm.isRegister && (angular.isUndefined(vm.data.invitation.contractType) || !vm.data.invitation.contractType)) {
          isValid = false;
        }

        if (vm.data.invitation.contractType === 'Pay Per Lead') {
          if (angular.isUndefined(vm.data.invitation.successfulLeadPrice) || isNaN(parseFloat(vm.data.invitation.successfulLeadPrice)) || parseFloat(vm.data.invitation.successfulLeadPrice) < 0) {
            isValid = false;
          }
        }
        if (vm.data.invitation.contractType === 'Subscription') {
          if (angular.isUndefined(vm.data.invitation.subscriptionPrice) || isNaN(parseFloat(vm.data.invitation.subscriptionPrice)) || parseFloat(vm.data.invitation.subscriptionPrice) < 0) {
            isValid = false;
          }
        }
      }

      return isValid;
    };

    vm.isValidRecipient = function () {
      return !vm.isEmptyNewRecipient() && vm.isValidNewRecipient() || (vm.selectedUser || vm.selectedUsers.length > 0);
    };

    vm.send = function () {
      var data,
          recipientUserIds = [];
      vm.validateRecipient = true;

      if (vm.selectedUser) {
        vm.selectedUsers = [vm.selectedUser];
      }

      // Extract the userids from the selected users
      angular.forEach(vm.selectedUsers, function (user) {
        recipientUserIds.push(user.id);
      });

      // Get the new invitees array into the 'invitation' parameter
      if (!vm.invitations || vm.invitations.length === 0) {
        delete vm.data.invitation;
      }
      else {
        // Bastardise the original 'invitation' parameter to include the array of new invitations.
        vm.data.invitation = _.clone(vm.invitations);
      }

      // Finally get the post data object
      data = angular.extend({}, vm.data, {recipientUserIds: recipientUserIds});

      vm.isLoading = true;
      Restangular.one('Leads').post('index', data)
        .then(function (result) {
          var failedUsers = [],
              failedUsersIds = [];
          vm.data.invitation = {};
          if (!result.success) {
            angular.forEach(result.failedUsers, function (user) {
              failedUsersIds.push(user.id);
              Notification({message: 'Unable to send lead to ' + user.fullName}, 'error');
            });
            failedUsers = _.remove(vm.selectedUsers, function (u) {
              return failedUsersIds.indexOf(u.id) > -1;
            });
            vm.selectedUsers = failedUsers;
            vm.isLoading = false;
          }
          else {
            $rootScope.$broadcast('event:lead-sent');
            if (vm.selectedUser && onSentCallback) {
              onSentCallback.apply();
            }
            else {
              $state.go('myPod.sentLeads');
            }
            Notification({message: 'Success'}, 'success');
          }
        }, function () {
          vm.isLoading = false;
          Notification({message: 'Unable to send'}, 'error');
        });
    };

    vm.register = function () {
      var data;
      vm.validateRecipient = true;
      if (vm.isValidRecipient()) {
        if (vm.selectedUser) {
          vm.selectedUsers = [vm.selectedUser];
        }
        data = angular.extend({}, vm.data, {
          senderUserId: vm.selectedUsers.length === 1 ? vm.selectedUsers[0].id : 0
        });
        if (vm.isEmptyNewRecipient()) {
          delete vm.data.invitation;
        }
        vm.isLoading = true;
        Restangular.one('Leads').post('register', data)
          .then(function (result) {
            vm.data.invitation = {};
            if (!result.success) {
              Notification({message: 'Unable to register the lead for ' + vm.selectedUsers[0].fullName}, 'error');
              vm.isLoading = false;
            }
            else {
              if (vm.selectedUser && onSentCallback) {
                onSentCallback.apply();
              }
              else if (vm.isRegister) {
                $state.go('myMemberships.receivedLeads');
              }
              else {
                $state.go('myPod.sentLeads');
              }

              Notification({message: 'Success'}, 'confirmation');
            }
          }, function () {
            vm.isLoading = false;
            Notification({message: 'Unable to register the lead'}, 'error');
          });
      }
    };

    vm.cancel = function () {
      // MGA - if there is a single user provided (very likely "Send Lead" clicked directly from the user's profile), then on cancel don't go anywhere (we should do the update though)
      if (vm.selectedUser && onCancelCallback) {
        onCancelCallback.apply();
      }
      else if (vm.isRegister) {
        $state.go('myMemberships.receivedLeads');
      }
      else {
        $state.go('myPod.sentLeads');
      }
    };

    vm.onContractTypeChange = function () {
      if (vm.data.invitation.contractType === 'Pay Per Lead') {
        $timeout(function () {
          angular.element($document[0].getElementById('inviteSuccessfulLeadPrice'))[0].focus();
        }, 200);
      }
      else if (vm.data.invitation.contractType === 'Subscription') {
        $timeout(function () {
          angular.element($document[0].getElementById('inviteSubscriptionPrice'))[0].focus();
        }, 200);
      }
    };

    vm.changeContractType = function (value, evt) {
      vm.data.invitation.contractType = value;
      vm.onContractTypeChange(evt);
      evt.stopPropagation();
      evt.preventDefault();
    };

    vm.toggleMoreDetailsVisibility = function () {
      vm.detailsVisible = !vm.detailsVisible;
    };

    vm.onRewardSelectionChange = function (data, isFormValid) {
      _.extend(vm.data.invitation, data);
      vm.isRewardsFormValid = isFormValid;
    };

    /**
     * Adds the to-be-invited recipient to the recipients list. Uses the current form values that are used to create an object and clears the form in the end.
     * @param {Object} form New user form that will have to have the errors cleared
     */
    vm.addNewRecipient = function (form) {
      if (!_.find(vm.invitations, {email: vm.data.invitation.email})) {
        vm.invitations.push(_.clone(vm.data.invitation));
        vm.data.invitation = {};
        if (form) {
          form.$setPristine();
          form.$setUntouched();
        }
        // We need to broadcast the clear to the reward selector as well...
        $scope.$broadcast('clearRewardSelector');
      }
    };

    /**
     * Removes the recipient from the list of new invitees that will recieve the lead.
     * @param {int} $index List index.
     */
    vm.removeNewRecipient = function ($index) {
      vm.invitations.splice($index, 1);
    };

    /**
     * Pre-fills the lead detail using search
     * @param {Object} item Item to pre-fill with
     */
    vm.onSearchItemSelected = function (item) {
      // Callback on when the user selected the search item.
      // Pull the stateTarget from category
      if (!item || !item.originalObject || angular.isString(item.originalObject)) {
        return;
      }
      vm.searchingUser = true;
      Restangular.one('Users').one('profile').get({id: item.originalObject.id})
        .then(function (profile) {
          vm.searchingUser = false;
          profile = profile.plain();
          // Pre-fill the data now.
          vm.data.firstName = profile.firstName;
          vm.data.lastName = profile.lastName;
          vm.data.email = profile.email;
          vm.data.phone = profile.phoneMobile;
          angular.element('#details').focus();
        });
    };

    /**
     * OnSelect event of the recipient search autocomplete control
     * @param {Object} item Item to pre-fill with
     */
    vm.onRecipientItemSelected = function (item) {
      if (!item || !item.originalObject || angular.isString(item.originalObject)) {
        return;
      }
      vm.searchingRecipient = true;
      Restangular.one('Users').one('profile').get({id: item.originalObject.id})
        .then(function (profile) {
          vm.searchingRecipient = false;
          profile = profile.plain();
          // Add to selected users
          // If this isRegister and there is already someone selected, we have to clear it.
          if (isRegister && vm.selectedUsers.length > 0) {
            vm.selectedUsers = [];
          }
          if (!_.find(vm.selectedUsers, {id: profile.id})) {
            vm.selectedUsers.push(profile);
          }
        });
    };

    /**
     * Formats the search response
     *
     * @param {Object} response Response object
     * @returns {Object} Formatted response
     */
    vm.searchResponseFormatter = function (response) {
      var r,
          records;

      if (angular.isUndefined(response) || !response) {
        return null;
      }
      vm.emptyResults = false;
      r = response.records;
      if (r.length === 0) {
        vm.emptyResults = true;
        if (vm.activeSearch === 'clients') {
          $timeout(function () {
            vm.emptyResults = false;
          }, 5000);
        }
      }

      records = _.map(r, function (item) {
        var category = _.find(vm.searchCategories, {value: item.datasource}),
            returnObject,
            categoryDescriptionClass,
            value = item.fullname;

        categoryDescriptionClass = category.iconClass || 'fa-user';
        if (item.companyname && item.companyname !== item.fullname) {
          value += ' | ' + item.companyname;
        }

        returnObject = {
          value: value,
          id: item.id,
          category: item.datasource,
          categoryDescription: '<i class="fa ' + categoryDescriptionClass + '"></i>',
          timeDescription: item.datecreatedformatted ? item.datecreatedformatted : '',
          leadtype: item.leadtype
        };

        return returnObject;
      });

      return records;
    };

    vm.showInviteNew = function () {
      if (isRegister) {
        vm.selectedUsers = [];
      }
      vm.showInviteForm = true;
      vm.activeSearch = 'recipients';
      vm.emptyResults = true;
    };

    vm.showSearchForm = function () {
      vm.showInviteForm = false;
      vm.emptyResults = false;
      if (isRegister) {
        vm.data.invitation = {};
      }
    };
  }
}());
