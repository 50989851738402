(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name socialShare.provider:LinkedinSocialShare
   *
   * @description
   *
   */
  angular
    .module('socialShare.providers')
    .provider('LinkedinSocialShare', LinkedinSocialShare);

  function LinkedinSocialShare() {
    var config = {
      popupHeight: 500,
      popupWidth: 500
    };

    function setConfig(newConfig) {
      if (angular.isObject(newConfig)) {
        angular.forEach(newConfig, function (value, paramName) {
          if (angular.isDefined(config[paramName])) {
            config[paramName] = value;
          }
          else {
            throw new Error('Invalid configuration option');
          }
        });
      }
    }

    return {
      $get: ['$q', '$window', function ($q, $window) {
        var $linkedin = $q.defer();

        $linkedin.init = function (newConfig) {
          setConfig(newConfig);
          $linkedin.resolve(true);
        };

        $linkedin.share = function (data) {
          var url = '//www.linkedin.com/shareArticle?mini=true&url=' + encodeURIComponent(data.url);

          if (data.name) {
            url += '&title=' + encodeURI(data.name);
          }

          if (data.description) {
            url += '&summary=' + encodeURI(data.description);
          }

          if (data.source) {
            url += '&source=' + encodeURI(data.source);
          }
          $window.open(url
            , 'sharer', 'toolbar=0,status=0,width=' + config.popupWidth + ',height=' + config.popupHeight);
        };

        return $linkedin;
      }]
    };
  }
}());
