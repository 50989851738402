(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name mlpMessaging.directive:messagingFormDirective
   * @restrict EA
   * @element
   *
   */
  angular
    .module('mlp-messaging')
    .directive('messagingForm', messagingForm);

  function messagingForm($timeout) {
    return {
      restrict: 'EA',
      scope: {
        msgId: '=?',
        msgRequestParams: '=?',
        msgRecipient: '=?',
        msgGrouping: '=?',
        msgWatchParams: '=?',
        msgLoadCallback: '='
      },
      replace: true,
      templateUrl: 'messaging/messaging-form-directive.tpl.html',
      controllerAs: 'msg',
      link: {
        post: function (scope, elem, attrs, ctrl) {
          // Check the scroll of the element containing the message so we could pull more.
          $timeout(function () {
            var messagesContainer = elem.find('.messages-container.has-data')[0];
            messagesContainer.onscroll = function () {
              if (messagesContainer.scrollTop === 0 && !ctrl.loading && ctrl.lastDataCount >= ctrl.limit) {
                // get new data
                ctrl.loadMore();
              }
            };
          }, 10);
        }
      },
      controller: ['$scope', '$document', '$interval', 'Session', 'Messaging', 'configOptions', function ($scope, $document, $interval, Session, Messaging) {
        var vm = this,
            messageIntervalUpdate = null;

        vm.sender = null;
        vm.recipient = $scope.msgRecipient;
        vm.grouping = $scope.msgGrouping;
        vm.watchParams = $scope.msgWatchParams;
        vm.onLoadCallback = $scope.msgLoadCallback;
        vm.id = $scope.msgId ? $scope.msgId : 'msg';

        vm.messageStr = null;
        vm.messages = [];
        vm.loading = false;
        vm.hasError = false;
        vm.requestParams = $scope.msgRequestParams ? angular.copy($scope.msgRequestParams) : {};
        vm.postParams = {
          message: null
        };
        vm.notification = null;
        vm.lastDataCount = 0;
        vm.limit = 20;
        vm.offset = 0;
        vm.offsetIncrement = 20;
        vm.appendMessages = false;
        vm.savingMessages = {};

        if (!vm.recipient) {
          vm.hasError = true;
          vm.notification = 'Unknown recipient';
          return;
        }

        // Check the scroll of the element containing the message so we could pull more.
        // console.log(vm.element);

        // Mixin the value from requestParam into the postParam so we have the correct object to send.
        if (vm.requestParams) {
          angular.forEach(vm.requestParams, function (value, key) {
            vm.postParams[key] = value;
          });
        }

        vm.loadMessages = function (append) {
          // For UI
          vm.loading = true;
          vm.hasError = false;
          vm.appendMessages = append || false;

          // Update the request param object
          vm.requestParams.limit = vm.limit;
          vm.requestParams.offset = vm.offset;

          // Get the messages
          Messaging.getMessages(vm.requestParams).then(vm.formatMessages);
        };

        vm.formatMessages = function (data) {
          $timeout(function () {
            vm.loading = false;
            vm.appendMessages = false;
            if (vm.onLoadCallback) {
              vm.onLoadCallback.apply();
            }
          }, 100);

          if (data && data.error) {
            vm.hasError = true;
            return;
          }
          if (data && data.records && data.records.length > 0) {
            // Try to reformat the messages
            angular.forEach(data.records, function (record) {
              record.message = record.message.replace(/(\r)?\n/g, '<br/>');
            });

            vm.lastDataCount = data.records.length;
            if (vm.appendMessages) {
              vm.messages = vm.messages.concat(data.records);
            }
            else {
              vm.messages = data.records;
            }
          }
          else if (data && data.records && data.records.length === 0) {
            vm.messages = [];
            vm.lastDataCount = 0;
          }
          else {
            vm.lastDataCount = 0;
          }
        };

        vm.addMessage = function (message, isTemp) {
          vm.saving = false;
          if (message.error) {
            vm.notification = 'Unable to send the message. Try again.';
            return;
          }
          if (isTemp) {
            vm.savingMessages[message.dateCreated] = message;
          }
          // Sending the message was successful. Clear the textbox
          vm.messageStr = null;
          message.created = vm.getFormattedTime((new Date()).getTime() - (new Date(message.dateCreated)).getTime());
          message.message = message.message.replace(/(\r)?\n/g, '<br/>');
          vm.messages.unshift(message);
        };

        vm.messageSaved = function (message, timeStamp) {
          delete vm.savingMessages[timeStamp];
        };

        vm.reload = function () {
          vm.offset = 0;
          vm.loadMessages();
        };

        vm.loadMore = function () {
          vm.offset += vm.offsetIncrement;
          vm.loadMessages(true);
        };

        vm.send = function () {
          var timeStamp = (new Date()).getTime();
          if (vm.messageStr) {
            vm.saving = true;
            vm.notification = null;
            vm.postParams.message = vm.messageStr;
            vm.addMessage({
              message: vm.messageStr,
              dateCreated: timeStamp
            }, true);
            Messaging.postMessage(vm.recipient.idUser, vm.postParams).then(function (message) {
              vm.messageSaved(message, timeStamp);
            });
          }
        };

        vm.updateMessageTimes = function () {
          var now = (new Date()).getTime();
          angular.forEach(vm.messages, function (message) {
            message.created = vm.getFormattedTime(now - (new Date(message.dateCreated)).getTime());
          });
        };

        vm.getFormattedTime = function (timegap) {
          var interval = Math.abs(timegap) / 1000,
              granularity = 1,
              zeroInterval = 'now',
              suffix = '',
              short = false,
              units = null,
              output = '';

          units = [
            {seconds: 31536000, singular: 'year', plural: 'years', short: 'y'},
            {seconds: 2592000, singular: 'month', plural: 'months', short: 'm'},
            {seconds: 604800, singular: 'week', plural: 'weeks', short: 'w'},
            {seconds: 86400, singular: 'day', plural: 'days', short: 'd'},
            {seconds: 3600, singular: 'hour', plural: 'hours', short: 'h'},
            {seconds: 60, singular: 'min', plural: 'min', short: 'm'}
          ];

          angular.forEach(units, function (unit) {
            var count = null;
            if (granularity === 0) {
              return;
            }
            if (interval >= unit.seconds) {
              count = Math.floor(interval / unit.seconds);
              output += (output ? ' ' : '') + count;

              if (short) {
                output += unit.short;
              }
              else {
                output += ' ' + (count > 1 ? unit.plural : unit.singular);
              }

              interval %= unit.seconds;
              granularity--;
            }
          });

          return output ? output + (suffix === '' ? '' : ' ' + suffix) : zeroInterval;
        };

        vm.itemRender = function (item, index) {
          var currentGroup,
              lastGroup,
              nextGroup;

          if (!vm.grouping || !item) {
            return;
          }

          currentGroup = item.groupId;
          lastGroup = vm.messages[index - 1] ? vm.messages[index - 1] : null;
          nextGroup = vm.messages[index + 1] ? vm.messages[index + 1] : null;

          item.opensGroup = currentGroup !== lastGroup;
          item.closesGroup = currentGroup !== nextGroup;
          item.noGroup = currentGroup === null;
        };

        Session.getUserProfile().then(function (user) {
          vm.user = user;
          vm.requestParams.idUser = vm.recipient.idUser;
          if (angular.isDefined(vm.recipient)) {
            vm.loadMessages();
          }
        });

        // we are now all set. Now watch for the msgParameter change so we can update the content.
        if (vm.watchParams) {
          $scope.$watch('msgRequestParams', function (oldVal, newVal) {
            if (oldVal !== newVal) {
              vm.requestParams = $scope.msgRequestParams ? angular.copy($scope.msgRequestParams) : {};
              vm.requestParams.idUser = vm.recipient.idUser;
              if (vm.requestParams) {
                angular.forEach(vm.requestParams, function (value, key) {
                  vm.postParams[key] = value;
                });
              }
              vm.loadMessages();
            }
            else if (vm.onLoadCallback) {
              vm.onLoadCallback.apply();
            }
          });
        }

        // Setup time formatting interval
        messageIntervalUpdate = $interval(vm.updateMessageTimes, 60000);
        // Clear the message interval upon destroy
        $scope.$on('$destroy', function () {
          $interval.cancel(messageIntervalUpdate);
        });
      }]
    };
  }
}());
