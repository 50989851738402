<div class="col-xs-12" ng-controller="UserReceivedLeadsCtrl as userReceivedLeads" id="user-received-leads-ctrl-container">
  <div class="page-no-content">
    <div class="row" ng-hide="userReceivedLeads.list.recordsExist() || !userReceivedLeads.list.isInitialised()">
      <div class="col-xs-12">
        <div class="page-empty-section text-center">
          <i class="fa fa-paper-plane fa-2x text-light-colour"></i>
          <div class="page-empty-section-text">
            You have no leads from {{userReceivedLeads.userProfile.fullName}}
            <div style="margin-top: 15px;" ng-if="!userReceivedLeads.receiveLeads">
              <button class="btn btn-primary" ng-click="userReceivedLeads.openAskForLeads()">Ask for Leads</button>
            </div>
            <div style="margin-top: 15px; font-weight: normal" class="sub-title-description"
                 ng-if="userReceivedLeads.receiveLeads &&
                 (userReceivedLeads.receiveLeads.idUserSender === userReceivedLeads.myProfile.id || (userReceivedLeads.receiveLeads.revision && userReceivedLeads.receiveLeads.idUserSender !== userReceivedLeads.myProfile.id))
                 && !userReceivedLeads.receiveLeads.isAccepted">
              Waiting for user to accept the <a ui-sref="user.agreements">agreement proposal</a>.
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="table-responsive" ng-show="userReceivedLeads.list.recordsExist() && userReceivedLeads.list.isInitialised()">
      <table st-table="userReceivedLeads.list.getRecords()" st-pipe="userReceivedLeads.list.load" class="table page-content-table allow-wrap">
        <thead class="hidden-xs">
        <tr>
          <th class="col-sm-3">Date received</th>
          <th class="col-sm-4">Name</th>
          <th class="col-sm-3">Status</th>
          <th class="col-sm-2 text-right">Details</th>
        </tr>
        </thead>
        <tbody ng-hide="userReceivedLeads.list.isLoading()">
        <tr ng-repeat="row in userReceivedLeads.list.getRecords()">
          <td class="col-xs-12 col-sm-3">
            <table class="table-cell-main-content">
              <tr class="row-center-items row-space-between">
                <td class="visible-xs" single-expand sub-content="userReceivedLeads.tableSubContentVisible" sub-content-idx="$index"></td>
                <td>
                  <span class="hidden-xs">{{row.dateSent | date:'d MMM yyyy'}}</span>
                  <span class="visible-xs text-muted">{{row.dateSent | date:'d MMM yyyy'}}<br/></span>
                  <span class="visible-xs">{{row.firstName}} {{row.lastName}}</span>
                </td>
                <td class="visible-xs text-right">
                  <span class="cursor-pointer" ng-click="userReceivedLeads.tableSubContentVisible[$index] = !userReceivedLeads.tableSubContentVisible[$index]">
                    <i class="fa fa-ellipsis-h"></i>
                  </span>
                </td>
              </tr>
            </table>
            <div class="table-cell-sub-content-container slide-if" ng-if="userReceivedLeads.tableSubContentVisible[$index]">
              <div class="table-cell-sub-content visible-xs">
                <span class="text-muted">Status</span><br/>
                <span class="text-normal" ng-if="(row.status !== 'open' && row.status !== 'new') || row.isCoOwner">
                  {{userReceivedLeads.statuses[row.status]}}<span ng-if="row.isCoOwner" class="text-muted"> (shared)</span>
                </span>
                <div ng-if="(row.status === 'open' || row.status === 'new') && !row.isCoOwner" uib-dropdown class="btn-group page-filter dropdown-large" uib-dropdown dropdown-append-to-body="true" >
                  <button id="lead-status-{{$index}}" type="button" class="btn" uib-dropdown-toggle>
                    {{userReceivedLeads.statuses[row.status]}} <span class="caret"></span>
                  </button>
                  <ul class="dropdown-menu" uib-dropdown-menu role="menu" aria-labelledby="lead-status-{{$index}}">
                    <li role="menuitem"><a>{{row.status === 'open' ? 'Open' : 'New'}}</a></li>
                    <li role="menuitem"><a ng-click="userReceivedLeads.closeLead($index, 'successful')">Successful</a></li>
                    <li role="menuitem"><a ng-click="userReceivedLeads.closeLead($index, 'unsuccessful')">Unsuccessful</a></li>
                  </ul>
                </div>
              </div>
              <div class="table-cell-sub-content visible-xs">
                <span class="text-muted">Details</span><br/>
                <span class="text-normal"><a class="cursor-pointer" ng-click="userReceivedLeads.openDetail($index)">View lead</a></span>
              </div>
            </div>
          </td>
          <td class="col-sm-4 hidden-xs">{{row.firstName}} {{row.lastName}}</td>
          <td class="col-sm-3 hidden-xs prevent-wrap" style="min-width: 130px;">
            <span ng-if="(row.status !== 'open' && row.status !== 'new') || row.isCoOwner">
              {{userReceivedLeads.statuses[row.status]}}
            </span>
            <div ng-if="(row.status === 'open' || row.status === 'new') && !row.isCoOwner" class="btn-group page-filter" uib-dropdown dropdown-append-to-body="true">
              <button id="lead-status-{{$index}}" type="button" class="btn" uib-dropdown-toggle>
                {{userReceivedLeads.statuses[row.status]}} <span class="caret"></span>
              </button>
              <ul class="dropdown-menu" uib-dropdown-menu role="menu" aria-labelledby="lead-status-{{$index}}">
                <li role="menuitem"><a>{{row.status === 'open' ? 'Open' : 'New'}}</a></li>
                <li role="menuitem"><a ng-click="userReceivedLeads.closeLead($index, 'successful')">Successful</a></li>
                <li role="menuitem"><a ng-click="userReceivedLeads.closeLead($index, 'unsuccessful')">Unsuccessful</a></li>
              </ul>
            </div>
          </td>
          <td class="col-sm-2 hidden-xs text-right prevent-wrap"><a class="cursor-pointer" ng-click="userReceivedLeads.openDetail($index)">View lead</a></td>
        </tr>
        </tbody>

        <tbody ng-if="userReceivedLeads.list.isLoading()">
        <tr>
          <td colspan="4" class="col-xs-12 text-center">
            <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="row" ng-show="!userReceivedLeads.list.isInitialised()">
      <div class="col-xs-12 text-center">
        <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>
      </div>
    </div>
  </div>
</div>

