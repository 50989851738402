(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name user.controller:UserCtrl
   *
   * @description
   *
   */
  angular
    .module('user')
    .controller('UserCtrl', UserCtrl);

  function UserCtrl($window, $rootScope, $scope, $stateParams, configOptions, Restangular, userProfile, agreementsDetails, myProfile, DataTable, Leads, MyPod, Charities) {
    var vm = this,
        tabs = ['sentLeads', 'receivedLeads', 'agreements', 'connections', 'marketplace', 'messages'];

    vm.isMobile = $window.innerWidth < 768;
    vm.detailsVisible = false;
    vm.userProfile = userProfile;
    vm.myProfile = myProfile;
    vm.agreementsDetails = agreementsDetails;
    vm.eventHandler = null;

    // Set the tabs. The About tab's visibility is dynamic and depends on its content
    if (vm.userProfile.description) {
      tabs.unshift('about');
    }

    vm.isBusinessAccountActive = function () {
      return angular.isDefined(myProfile.isBusinessAccountActive) && myProfile.isBusinessAccountActive === true;
    };

    // Check if this user is the business entity (is registered as the business in MLP)
    // FYI: connectionsList == directoryList, we are keeping the same name in the code (connections) as it was originally planned, but all the UI will display "Directory" instead
    if (vm.userProfile.isBusinessAccountActive === true) {
      vm.connectionsList = DataTable.create('Members', 'index', {}, {
        basicParams: {
          idUser: vm.userProfile.id
        }
      });
    }

    vm.tabManager = null;
    vm.tabsConfig = [
      {
        label: 'About',
        value: 'about',
        url: 'user.about',
        condition: function () {
          return vm.userProfile.description;
        }
      },
      {
        label: 'Sent Leads',
        value: 'sentLeads',
        url: 'user.sentLeads',
        condition: function () {
          return vm.userProfile.isBusinessAccountActive;
        }
      },
      {
        label: 'Received Leads',
        value: 'receivedLeads',
        url: 'user.receivedLeads',
        condition: function () {
          return vm.isBusinessAccountActive();
        }
      },
      {
        label: 'Agreements',
        value: 'agreements',
        url: 'user.agreements',
        condition: function () {
          return true;
        }
      },
      {
        label: 'Messages',
        value: 'messages',
        url: 'user.messages',
        condition: function () {
          return vm.userProfile.acceptedReceiveLeadsContractExists || vm.userProfile.acceptedSendLeadsContractExists;
        }
      },
      {
        label: vm.userProfile.firstName + '\'s Directory',
        value: 'connections',
        url: 'user.connections',
        condition: function () {
          return vm.userProfile.isBusinessAccountActive;
        },
        template: '<span>(<span ng-show="!tab.data.connectionsList.isInitialised() && tab.data.connectionsList.isLoading()"><i class="fa fa-1x fa-circle-o-notch fa-spin-2x"></i></span>{{tab.data.connectionsList.totalRecords()}})</span>',
        data: {
          userProfile: vm.userProfile,
          connectionsList: vm.connectionsList
        }
      },
      {
        label: 'Marketplace',
        value: 'marketplace',
        url: 'user.marketplace',
        condition: function () {
          return vm.userProfile.marketplace && vm.userProfile.marketplace.length;
        },
        template: '<span>({{tab.data.marketplace.length}})</span>',
        data: {
          userProfile: vm.userProfile,
          marketplace: vm.userProfile.marketplace
        }
      }
    ];

    vm.toggleDetails = function () {
      vm.detailsVisible = !vm.detailsVisible;
    };

    vm.moreDetailsExist = function () {
      var nonEmptyDetails = 0;
      if (vm.getAddress()) {
        nonEmptyDetails++;
      }
      if (vm.userProfile.phoneMobile) {
        nonEmptyDetails++;
      }
      if (vm.userProfile.email) {
        nonEmptyDetails++;
      }
      if (vm.userProfile.website) {
        nonEmptyDetails++;
      }

      return nonEmptyDetails > 1;
    };

    vm.getAddress = function () {
      var addressParts = [];
      if (vm.userProfile.suburb) {
        addressParts.push(vm.userProfile.suburb);
      }
      if (vm.userProfile.state) {
        addressParts.push(vm.userProfile.state);
      }
      if (vm.userProfile.country) {
        addressParts.push(vm.userProfile.country);
      }

      vm.addressLink = vm.userProfile.addressPostal && vm.userProfile.addressPostal.addressPostal ? vm.userProfile.addressPostal.addressPostal + ', ' + addressParts.join(', ') : addressParts.join(', ');
      return addressParts.join(', ');
    };

    vm.formatWebsiteUrl = function () {
      var url = '';
      if (angular.isDefined(vm.userProfile) && angular.isString(vm.userProfile.website)) {
        url = vm.userProfile.website;
        if (url !== '') {
          if ((url.slice(0, 4)).toLowerCase() !== 'http') {
            url = 'http://' + vm.userProfile.website;
          }
        }
      }

      return url;
    };

    vm.sendLead = function () {
      Leads.openSendLead(vm.userProfile, false);
    };

    vm.registerLead = function () {
      Leads.openSendLead(vm.userProfile, true);
    };

    vm.sendInvite = function () {
      var uibModal = MyPod.openSendInvite(vm.myProfile, vm.userProfile);
      uibModal.then(function (sendLeads) {
        vm.agreementsDetails.sendLeads = sendLeads;
      });
    };

    vm.donate = function () {
      if (vm.userProfile.isCharityAccountActive) {
        Charities.openDonate(vm.userProfile);
      }
    };

    vm.reloadProfile = function () {
      Restangular.one('Users').one('profile').get({id: $stateParams.id})
        .then(function (profile) {
          vm.userProfile = profile.plain();
        });
    };

    vm.getCoverStyle = function () {
      var coverCss = {};
      // Set the user profile style from style config
      if (vm.userProfile.profileConfiguration && vm.userProfile.profileConfiguration.coverBackground) {
        // Check if the user opted for his own background image or wants to use provided pattern or plain color. Color can be combined with both
        // so it's in separate 'if' statement.
        if (vm.userProfile.profileConfiguration.coverBackground.imageToken) {
          coverCss.backgroundImage = 'url("' + configOptions.baseUrl + '/UserConfiguration/coverPicture/' + vm.userProfile.profileConfiguration.coverBackground.imageToken + '")';
          coverCss.backgroundPosition = 'center top';
          coverCss.backgroundSize = '1140px 215px';
        }
        else if (vm.userProfile.profileConfiguration.coverBackground.config && vm.userProfile.profileConfiguration.coverBackground.config.keepPattern) {
          coverCss.backgroundImage = 'url("/images/user-profile-pattern.png")';
        }
        else {
          coverCss.backgroundImage = 'none';
        }

        if (vm.userProfile.profileConfiguration.coverBackground.config) {
          if (vm.userProfile.profileConfiguration.coverBackground.config.backgroundColor) {
            coverCss.backgroundColor = vm.userProfile.profileConfiguration.coverBackground.config.backgroundColor;
            coverCss.backgroundBlendMode = 'luminosity';
          }
        }
        return coverCss;
      }
    };

    vm.getHeadlineStyle = function () {
      var textCss = {};
      // Set the user profile style from style config
      if (vm.userProfile.profileConfiguration && vm.userProfile.profileConfiguration.coverBackground && vm.userProfile.profileConfiguration.coverBackground.config) {
        textCss.color = vm.userProfile.profileConfiguration.coverBackground.config.textColor || 'inherit';
      }
      return textCss;
    };

    vm.isCustomBackground = function () {
      return vm.userProfile.profileConfiguration && vm.userProfile.profileConfiguration.coverBackground && vm.userProfile.profileConfiguration.coverBackground.imageToken ||
        vm.userProfile.profileConfiguration && vm.userProfile.profileConfiguration.coverBackground && vm.userProfile.profileConfiguration.coverBackground.config && !vm.userProfile.profileConfiguration.coverBackground.config.keepPattern;
    };

    vm.eventHandler = $rootScope.$on('event:user-agreements-updated', function () {
      vm.reloadProfile();
    });
    vm.stateHandler = $rootScope.$on('close-modal-instance', function () {
      vm.detailsVisible = false;
    });
    $rootScope.$on('$destroy', vm.eventHandler);
    $rootScope.$on('$destroy', vm.stateHandler);

    angular.element($window).on('resize', function () {
      vm.isMobile = $window.innerWidth < 768;
      $scope.$apply();
    });
  }
}());
