(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name user.leads.controller:UserReceivedLeadsCtrl
   *
   * @description
   *
   */
  angular
    .module('user.leads')
    .controller('UserReceivedLeadsCtrl', UserReceivedLeadsCtrl);

  function UserReceivedLeadsCtrl($scope, $state, Session, DataTable, MyMemberships, Leads) {
    var vm = this;
    vm.userProfile = $scope.user.userProfile;
    vm.myProfile = null;
    vm.receiveLeads = $scope.user.agreementsDetails.receiveLeads;

    // Watch the property
    $scope.$watch('user.agreementsDetails.receiveLeads', function (receiveLeads) {
      vm.receiveLeads = receiveLeads;
    });

    Session.getUserProfile().then(function (profile) {
      vm.myProfile = profile;
    });

    vm.statuses = {
      all: 'All leads',
      open: 'Open',
      new: 'New',
      successful: 'Successful',
      unsuccessful: 'Unsuccessful'
    };
    vm.list = DataTable.create('Leads', 'index', {}, {
      basicParams: {
        type: 'received',
        idUser: vm.userProfile.id
      }
    });

    vm.openDetail = function ($index) {
      var detailInstance = Leads.openDetail(vm.list.getRecords()[$index], 'received', 'user-received-leads-ctrl-container');
      if (angular.isDefined(detailInstance)) {
        detailInstance.then(function (result) {
          if (angular.isObject(result) && angular.isObject(result.lead)) {
            vm.list.getRecords()[$index] = result.lead;
          }
        });
      }
    };

    vm.closeLead = function ($index, status) {
      Leads.closeLead(vm.list.getRecords()[$index], status, 'user-received-leads-ctrl-container')
        .then(function () {
          vm.list.getRecords()[$index].status = status;
        });
    };

    vm.openAskForLeads = function () {
      var modalInstance = MyMemberships.openAskForLeads(vm.myProfile, vm.userProfile);
      modalInstance.then(function (receiveLeads) {
        $scope.user.agreementsDetails.receiveLeads = receiveLeads;
        vm.receiveLeads = receiveLeads;
      });
    };

    $scope.$on('event:receive-leads-contract-accepted', function (event, data) {
      angular.forEach(vm.list.getRecords(), function (record) {
        if (record.sender.idUser === data.idUser) {
          record.acceptedReceiveLeadsContractExists = true;
        }
      });
    });
  }
}());
