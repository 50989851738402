(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name components.filter:nl2br
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('components')
    .filter('nl2br', nl2br);

  function nl2br() {
    return function (input) {
      if (input) {
        return (input + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + '<br/>' + '$2');
      }

      return '';
    };
  }
}());
