<div class="row">
  <div class="col-xs-12">
    <span class="sub-title">Lead details</span>
  </div>
</div>

<div class="row form-section-description">
  <div ng-if="!sendLead.isRegister" class="col-xs-12">
    <span ng-show="sendLead.selectedUser || (sendLead.selectedUsers && sendLead.selectedUsers.length == 1)" class="sub-title-description">Provide the details of the person that this business should get in touch with. </span>
    <span ng-show="!sendLead.selectedUser && (sendLead.selectedUsers && sendLead.selectedUsers.length > 1)" class="sub-title-description">Provide the details of the person that these businesses should get in touch with. </span>
    <span ng-show="!sendLead.selectedUser && (!sendLead.selectedUsers || (sendLead.selectedUsers && sendLead.selectedUsers.length == 0))" class="sub-title-description">Provide the details of the person that the businesses should get in touch with. </span>
    <span>Try to search for the person's contact details to pre-fill the form automatically, or use the form to manually enter the required information.</span>
  </div>
  <div ng-if="sendLead.isRegister" class="col-xs-12">
    <span class="sub-title-description">Provide the details of the customer this lead was related to:</span>
  </div>
</div>


<div class="row">
  <div class="col-xs-12 col-sm-12 col-md-6 form-group form-section-description">
    <search-autocomplete
      control-id="end-user-autocomplete"
      placeholder="Search"
      minlength="3"
      clear-selected="false"
      text-searching="false"
      text-no-results="false"

      search-service="Search"
      search-service-end-point="index"
      remote-url-response-formatter="sendLead.searchResponseFormatter"
      search-categories="sendLead.searchCategories"
      selected-object="sendLead.onSearchItemSelected"
      focus-in="sendLead.activeSearch='clients'; sendLead.emptyResults = false;"

      data-field="content.records"

      search-fields="search"
      title-field="value"
      description-field="categoryDescription"
      description-field2="timeDescription"
      category-field="category"

      input-name="associateUsersInputSearch"
      input-class="form-control input-lg"
      match-class="autocomplete-highlight"
      blur-on-select="true"
      has-back-button="false"
      update-input-on-select="true"

      is-dropdown-relative="false"

      override-suggestions="true"
      field-required="false"
      has-category-filter="false"
      has-history="true"
    />
    <div class="input-error slide-if" ng-if="sendLead.activeSearch=='clients' && sendLead.emptyResults">No results were returned, please enter the person's details manually</div>
  </div>
</div>

<div class="row form-section-body">

  <div class="pending-overlay-light" ng-if="sendLead.searchingUser">
    <span class="fa fa-2x fa-circle-o-notch fa-spin-2x"></span>
  </div>

  <div class="col-xs-12 col-sm-6">
    <div class="form-group" ng-class="{'has-error': (sendLeadForm.$submitted || (sendLeadForm.firstName.$touched && sendLeadForm.lastName.$touched)) && !sendLead.isValidName()}">
      <label class="sr-only" for="firstName">First name</label>
      <div ng-show="sendLeadForm.$submitted || (sendLeadForm.firstName.$touched && sendLeadForm.lastName.$touched)">
        <div class="input-error" ng-show="!sendLead.isValidName()">At least one of first name or last name is required</div>
      </div>
      <div class="left-inner-addon">
        <i class="fa fa-user inner-addon"></i>
        <input class="form-control input-lg" type="text" name="firstName" id="firstName"
               placeholder="First name" ng-model="sendLead.data.firstName" />
      </div>
    </div>
  </div>
  <div class="col-xs-12 col-sm-6">
    <div class="form-group" ng-class="{'has-error': (sendLeadForm.$submitted || (sendLeadForm.firstName.$touched && sendLeadForm.lastName.$touched)) && !sendLead.isValidName()}">
      <label class="sr-only" for="lastName">Last name</label>
      <div class="left-inner-addon">
        <i class="fa fa-user inner-addon"></i>
        <input class="form-control input-lg" type="text" name="lastName" id="lastName"
               placeholder="Last name" ng-model="sendLead.data.lastName" />
      </div>
    </div>
  </div>
  <div class="col-xs-12 col-sm-6">
    <div class="form-group" ng-class="{'has-error': (sendLeadForm.$submitted || (sendLeadForm.email.$touched && sendLeadForm.phone.$touched)) && (!sendLead.isValidContact() || sendLeadForm.email.$error.email)}">
      <label class="sr-only" for="email">Email</label>
      <div ng-show="sendLeadForm.$submitted || (sendLeadForm.email.$touched && sendLeadForm.phone.$touched)">
        <div class="input-error" ng-show="!sendLead.isValidContact() && !sendLeadForm.email.$error.email">At least one of phone or valid email address is required</div>
        <div class="input-error" ng-show="sendLeadForm.email.$error.email">Invalid email address</div>
      </div>
      <div class="left-inner-addon">
        <i class="fa fa-envelope-o inner-addon"></i>
        <input class="form-control input-lg" type="email" name="email" id="email" placeholder="Email"
               ng-model="sendLead.data.email" />
      </div>
    </div>
  </div>
  <div class="col-xs-12 col-sm-6">
    <div class="form-group" ng-class="{'has-error': (sendLeadForm.$submitted || (sendLeadForm.email.$touched && sendLeadForm.phone.$touched)) && !sendLead.isValidContact()}">
      <label class="sr-only" for="phone">Phone</label>
      <div class="left-inner-addon">
        <i class="fa fa-phone inner-addon"></i>
        <input class="form-control input-lg" type="text" name="phone" id="phone"
               placeholder="Phone" ng-model="sendLead.data.phone" />
      </div>
    </div>
  </div>
  <div class="col-xs-12">
    <div class="" ng-class="{'has-error': (sendLeadForm.$submitted || sendLeadForm.details.$touched) && sendLeadForm.details.$error.required}">
      <label class="sr-only" for="details">Note</label>
      <div ng-show="sendLeadForm.$submitted || sendLeadForm.details.$touched">
        <div class="input-error" ng-show="sendLeadForm.details.$error.required">Missing note</div>
      </div>
      <div class="left-inner-addon">
        <i class="fa fa-comment-o inner-addon"></i>
        <textarea class="form-control input-lg lead-note-input" name="details" id="details" placeholder="Note"
                  ng-required="true" ng-model="sendLead.data.details" msd-elastic></textarea>
      </div>
    </div>
  </div>
</div>
