(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name toolbar.controller:ToolbarCtrl
   *
   * @description
   *
   */
  angular
    .module('toolbar')
    .controller('ToolbarCtrl', ToolbarCtrl);

  function ToolbarCtrl($rootScope, $scope, $window, $state, $document, $timeout, Marketplace, AccountService, BusinessAccount, Auth, Session, authEvents) {
    var vm = this,
        loginSuccessHandler,
        updateProfileHandler,
        notificationHandler,
        watchHandle;

    // Default state for the navbar-collapse
    vm.isCollapsed = true;
    vm.hasNotifications = false;
    vm.notificationsCount = ' ';

    notificationHandler = $rootScope.$on('event:notifications-status', function (event, data) {
      vm.hasNotifications = data.hasNotifications;
      if (vm.hasNotifications) {
        vm.notificationsCount = data.count;
      }
      else {
        vm.notificationsCount = ' ';
        if (vm.isMobile) {
          vm.isNCopen = false;
        }
      }
    });

    function updateUserData() {
      Session.getUserProfile()
        .then(function (profile) {
          if (profile) {
            vm.userProfile = profile;
            vm.trialData = BusinessAccount.getTrialData(vm.userProfile);
          }
        });
    }

    function checkForMobile() {
      // This is a simple workaround to find out if we are in the the mobile devices (tablet/phone) according the bootstrap's breakpoints without having to hardcode the breakpoint values,
      // simply use the toggle button (the one with the .navbar-toggle) object and check if it's visible.
      vm.isMobile = angular.element('.navbar-toggle:visible').length === 1;
      vm.isDesktop = !vm.isMobile;
    }

    updateUserData();

    vm.isLogin = function () {
      return $state.is('accountSignInUp.accountSignIn');
    };

    vm.isSignUp = function () {
      return $state.is('accountSignInUp.accountSignUp');
    };

    vm.showLoginLink = function () {
      return $state.is('accountSignInUp.accountSignUp') || $state.includes('accountPassword');
    };

    vm.isAuthenticated = function () {
      return $rootScope.isAuthenticated;
    };

    vm.toggleMenu = function () {
      vm.isCollapsed = !vm.isCollapsed;
    };

    vm.closeMenu = function () {
      vm.isCollapsed = true;
    };

    vm.signOut = function () {
      vm.closeMenu();
      Auth.signOut();
      $state.params = angular.extend($state.params, {logout: true});
      $state.go('accountSignInUp.accountSignIn');
    };

    vm.isBusinessAccountActive = function () {
      return angular.isObject(vm.userProfile) && angular.isDefined(vm.userProfile.isBusinessAccountActive) && vm.userProfile.isBusinessAccountActive === true;
    };

    vm.getUserProfile = function () {
      return vm.userProfile;
    };

    vm.openFeedback = function (helpNeeded) {
      AccountService.openFeedback(vm.userProfile, helpNeeded);
      vm.closeMenu();
    };

    vm.openPreferences = function () {
      AccountService.openPreferences(vm.userProfile);
      vm.closeMenu();
    };

    vm.openMarketplace = function () {
      Marketplace.open();
      vm.closeMenu();
    };

    vm.toggleNotificationCenter = function (isOpen) {
      vm.isNCopen = isOpen;
    };

    vm.toggleProfile = function (isOpen) {
      vm.isProfileOpen = isOpen;
    };

    /* eslint no-unused-vars: [2, {"args": "none", "varsIgnorePattern": "Handler" }]*/
    loginSuccessHandler = $rootScope.$on(authEvents.loginSuccess, function () {
      checkForMobile();
      updateUserData();
    });
    updateProfileHandler = $rootScope.$on('event:user-updateProfile', function (event, data) {
      vm.userProfile = data.profile;
    });

    // We have to run the mobile-check after user logs is, as if he is a visitor, we are hiding the toggle button
    watchHandle = $rootScope.$watch('isAuthenticated', function () {
      if ($rootScope.isAuthenticated) {
        vm.isDesktop = false;
        vm.isMobile = false;
        $timeout(function () {
          checkForMobile();
        }, 50);
      }
      else {
        vm.userProfile = undefined;
      }
    });
    $rootScope.$on('$destroy', function () {
      watchHandle();
      notificationHandler();
    });

    // Subscribe to the element resize
    angular.element($window).on('resize', function () {
      checkForMobile();
      vm.closeMenu();
      $scope.$apply();
    });

    // Do the check for the initial state.
    $document.ready(checkForMobile);
  }
}());
