<div class="col-xs-12" ng-controller="SentLeadsCtrl as sentLeads" id="sent-leads-ctrl-container">
  <div class="page-content">
    <div class="row" ng-hide="sentLeads.list.recordsExist() || !sentLeads.list.isInitialised()">
      <div class="col-xs-12">
        <div class="page-empty-section text-center">
          <i class="fa fa-paper-plane fa-2x text-light-colour"></i>
          <div class="page-empty-section-text">
            You haven't sent any leads
          </div>
        </div>
      </div>
    </div>
    <div class="row" ng-show="sentLeads.list.recordsExist() && sentLeads.list.isInitialised()">
      <div class="col-sm-5 col-md-4">
        <div class="form-group page-filter">
          <label class="sr-only" for="searchLead">Search lead</label>
          <div class="left-inner-addon">
            <i class="fa fa-search inner-addon"></i>
            <form name="searchLeadForm" ng-submit="sentLeads.search()">
              <input class="form-control input-lg" type="search" name="searchLead" id="searchLead"
                     placeholder="Search lead" ng-model="sentLeads.searchLead" ng-blur="sentLeads.search()" />
            </form>
          </div>
        </div>
      </div>
      <div class="col-sm-4 col-md-3">
        <div class="form-group btn-group page-filter dropdown-large" uib-dropdown>
          <button id="lead-status" type="button" class="btn" uib-dropdown-toggle>
            {{sentLeads.selectedStatusLabel}} <span class="caret"></span>
          </button>
          <ul class="dropdown-menu" uib-dropdown-menu role="menu" aria-labelledby="lead-status">
            <li role="menuitem" ng-repeat="(value, label) in sentLeads.statuses">
              <a ng-click="sentLeads.selectStatus(value)">{{label}}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="table-responsive table-container" ng-show="sentLeads.list.recordsExist() && sentLeads.list.isInitialised()">
      <table st-table="sentLeads.list.getRecords()" st-pagination-scroll st-pipe="sentLeads.list.load" class="table page-content-table">
        <thead class="hidden-xs">
        <tr>
          <th class="col-sm-3">Sent to</th>
          <th class="col-sm-2">Date sent</th>
          <th class="col-sm-3">Name</th>
          <th class="col-sm-2">Status</th>
          <th class="col-sm-2 text-right">Details</th>
        </tr>
        </thead>
        <tbody ng-hide="sentLeads.list.isLoading() && !sentLeads.list.isLoadingMoreRecords()">
        <tr ng-repeat="row in sentLeads.list.getRecords()">
          <td class="col-xs-12 col-sm-3">
            <table class="table-cell-main-content">
              <tr class="row-center-items row-space-between">
                <td class="visible-xs" single-expand sub-content="sentLeads.tableSubContentVisible" sub-content-idx="$index"></td>
                <td>
                  <table-user-details user="row.recipient"></table-user-details>
                </td>
                <td class="visible-xs text-right">
                  <span class="cursor-pointer">
                    <i class="fa fa-ellipsis-h"></i>
                  </span>
                </td>
              </tr>
            </table>
            <div class="table-cell-sub-content-container slide-if" ng-if="sentLeads.tableSubContentVisible[$index]">
              <div class="table-cell-sub-content visible-xs">
                <span class="text-muted">Date sent</span><br/>
                <span class="text-normal">{{row.dateSent | date:'d MMM yyyy'}}</span>
              </div>
              <div class="table-cell-sub-content visible-xs">
                <span class="text-muted">Name</span><br/>
                <span class="text-normal">{{row.firstName}} {{row.lastName}}</span>
              </div>
              <div class="table-cell-sub-content visible-xs">
                <span class="text-muted">Status</span><br/>
                <span class="text-normal">{{sentLeads.statuses[row.status]}}</span>
              </div>
              <div class="table-cell-sub-content visible-xs">
                <span class="text-muted">Details</span><br/>
                <span class="text-normal"><a class="cursor-pointer" ng-click="sentLeads.openDetail($index)">View lead</a></span>
              </div>
            </div>
          </td>
          <td class="col-sm-2 hidden-xs">{{row.dateSent | date:'d MMM yyyy'}}</td>
          <td class="col-sm-3 hidden-xs">{{row.firstName}} {{row.lastName}}</td>
          <td class="col-sm-2 hidden-xs">{{sentLeads.statuses[row.status]}}</td>
          <td class="col-sm-2 hidden-xs text-right">
            <a class="cursor-pointer" ng-click="sentLeads.openDetail($index)">View lead</a>
          </td>
        </tr>
        </tbody>
        <tbody ng-if="sentLeads.list.isLoading()">
        <tr>
          <td colspan="5" class="col-xs-12 text-center">
            <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="row" ng-show="!sentLeads.list.isInitialised()">
      <div class="col-xs-12 text-center">
        <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>
      </div>
    </div>
  </div>
</div>

