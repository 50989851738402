(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.directive:locationSearch
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="components">
       <file name="index.html">
        <location-search></location-search>
       </file>
     </example>
   *
   */
  angular
    .module('components')
    .directive('locationSearch', locationSearch);

  function locationSearch($q, GoogleMap) {
    return {
      restrict: 'A',
      scope: {
        inputId: '=',
        placeChangedCallback: '=',
        fullAddress: '='
      },
      replace: false,
      link: function (scope, element) {
        var autocomplete,
            placeSelected;

        // This is the place_changed handler which is the google event for the autocomplete input
        placeSelected = function () {
          var place = autocomplete.getPlace(),
              address;

          address = GoogleMap.convertAddress(place.address_components);
          address.location = GoogleMap.getFormattedAddress(address, scope.fullAddress);
          scope.placeChangedCallback(address);
        };

        GoogleMap.promise.then(function () {
          // Prevent "Enter" click to submit the form
          google.maps.event.addDomListener(element[0], 'keydown', function (event) {
            if (event.keyCode === 13) {
              event.preventDefault();
            }
          });

          autocomplete = new google.maps.places.Autocomplete(element[0], {
            types: ['geocode']
          });
          autocomplete.addListener('place_changed', placeSelected);
        });
      }
    };
  }
}());
