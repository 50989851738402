<div class="modal-content-container">
  <div class="row">
    <!-- Title -->
    <div class="col-xs-12 modal-content-title">
      <span class="sub-title">{{marketplaceActivation.item.label}}</span>
    </div>
    <!-- ENDOF: Title-->

    <!-- Content -->
    <div class="col-xs-12" ng-bind-html="marketplaceActivation.item.description"></div>

    <div class="col-xs-12">
      <form name="marketplaceActivationForm">
        <div ng-if="marketplaceActivation.isPaymentRequired" class="close-lead-invoice form-group">
          <div class="row">
            <div class="col-xs-12">
              <h4>Payment Summary</h4>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-6">
              Subscription fee
            </div>
            <div class="col-xs-6 text-right">
              {{marketplaceActivation.paymentSummary.price | currency:'$':2}}
            </div>
          </div>


          <div class="row">
            <div class="col-xs-6">
              GST
            </div>
            <div class="col-xs-6 text-right">
              {{marketplaceActivation.paymentSummary.tax | currency:"$":2}}
            </div>
          </div>
          <div class="row" style="border-top: 1px lightgray dotted; padding-top: 5px; margin-top: 3px;">
            <div class="col-xs-6">
              <strong>Total</strong>
            </div>
            <div class="col-xs-6 text-right">
              <strong>{{marketplaceActivation.paymentSummary.total | currency:"$":2}}</strong>
            </div>
          </div>
          <!--<div class="col-xs-12"
               style="color: lightgray;margin: 15px 0px;font-size: 12px;color: #676767;white-space: normal;">
            * You will be periodically charged this amount every month
          </div>-->
        </div>

        <div class="row" ng-if="marketplaceActivation.isPaymentRequired">
          <div class="col-xs-12">
            <h4>Payment Options</h4>
          </div>
        </div>

        <div class="row" ng-if="marketplaceActivation.isPaymentRequired">
          <div class="col-xs-12" ng-hide="marketplaceActivation.isCCLoading">
            <credit-card credit-card="marketplaceActivation.creditCard"
                         cc-data="marketplaceActivation.data.creditCard" />
          </div>
          <div class="col-xs-12 text-center" ng-if="marketplaceActivation.isCCLoading">
            <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>
          </div>
        </div>
      </form>
    </div>

    <!-- ENDOF: Content -->
  </div>

  <div class="row modal-content-buttons">
    <div class="col-xs-12 text-right">
      <button class="btn btn-default" ng-disabled="marketplaceActivation.isLoading"
              ng-click="marketplaceActivation.cancel()">
        Cancel
      </button>
      <button class="btn btn-primary"
              ng-disabled="marketplaceActivation.isLoading || marketplaceActivation.isCCLoading || marketplaceActivationForm.$invalid"
              ng-click="marketplaceActivation.activate()">
        {{marketplaceActivation.isLoading ? 'Activating...' : (marketplaceActivation.isPaymentRequired ? 'Pay & Activate' : 'Activate')}}
      </button>
    </div>
  </div>
</div>


