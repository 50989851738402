(function () {
  'use strict';

  /* @ngdoc object
   * @name components.ui.table
   * @description
   *
   */
  angular
    .module('components.ui.table', []);
}());
