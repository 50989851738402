<div class="row">
  <div class="col-md-12 text-center">
    <h1 class="page-header sign-up-page-header">Login</h1>
  </div>
</div>

<div class="row sign-in-page-container">

  <div class="sign-in-page-content">
    <div class="btn-social-container">
      <button class="btn btn-social btn-facebook" ng-click="signIn.socialSignIn('facebook')" ng-disabled="signIn.isLoading">
        <i class="btn-social-icon fa fa-facebook"></i>
        <span class="btn-social-text">Login with Facebook</span>
      </button>
    </div>

    <div class="btn-social-container">
      <button class="btn btn-social btn-linkedin" ng-click="signIn.socialSignIn('linkedin')" ng-disabled="signIn.isLoading">
        <i class="btn-social-icon fa fa-linkedin"></i>
        <span class="btn-social-text">Login with Linkedin</span>
      </button>
    </div>

    <div class="btn-social-container">
      <button class="btn btn-social btn-google" ng-click="signIn.socialSignIn('google')" ng-disabled="signIn.isLoading">
        <i class="btn-social-icon fa fa-google-plus"></i>
        <span class="btn-social-text">Login with Google+</span>
      </button>
    </div>
  </div>

  <div class="sign-in-page-content separator">
    <div class="btn-social-separator-container">
      <div class="btn-social-separator btn-social-separator-sign-in"></div>
      <div class="btn-social-separator-text">OR</div>
      <div class="btn-social-separator btn-social-separator-sign-in"></div>
    </div>
  </div>

  <div class="sign-in-page-content">
    <div class="sign-up-in-form-container">
      <uib-alert ng-repeat="alert in signIn.alerts.getList()" type="{{alert.type}}" close="signIn.alerts.remove($index)">{{alert.message}}</uib-alert>
      <form name="passwordSignInForm" ng-submit="passwordSignInForm.$valid && !signIn.isLoading && signIn.passwordSignIn()">
        <div class="form-group" ng-class="{'has-error': (passwordSignInForm.$submitted || passwordSignInForm.username.$touched) && (passwordSignInForm.username.$error.required || passwordSignInForm.username.$error.email)}">
          <label class="sr-only" for="email">Email</label>
          <div ng-show="passwordSignInForm.$submitted || passwordSignInForm.username.$touched">
            <div class="input-error" ng-show="passwordSignInForm.username.$error.required">Tell us your email.</div>
            <div class="input-error" ng-show="passwordSignInForm.username.$error.email">This is not a valid email.</div>
          </div>
          <div class="left-inner-addon">
            <i class="fa fa-envelope-o inner-addon"></i>
            <input class="form-control input-lg" type="email" name="username" id="email" placeholder="Email"
                   ng-required="true" ng-model="signIn.data.username" />
          </div>
        </div>
        <div class="form-group" ng-class="{'has-error': (passwordSignInForm.$submitted || passwordSignInForm.password.$touched) && passwordSignInForm.password.$error.required}">
          <label class="sr-only" for="password">Password</label>
          <div ng-show="passwordSignInForm.$submitted || passwordSignInForm.password.$touched">
            <div class="input-error" ng-show="passwordSignInForm.password.$error.required">Missing password.</div>
          </div>
          <div class="left-inner-addon forgot-link-addon">
            <i class="fa fa-lock inner-addon"></i>
            <input class="form-control input-lg" type="password" name="password" id="password" placeholder="Password"
                   ng-required="true" ng-model="signIn.data.password"/>
            <a class="forgot-password-link" ui-sref="accountPassword.recover">Forgot?</a>
          </div>
        </div>
        <div class="sign-up-page-btn-create">
          <button type="submit" class="btn btn-primary btn-lg btn-block"
                  ng-disabled='signIn.isLoading'>
            {{signIn.isLoading ? 'Loading ...' : 'Login'}}
          </button>
          <div class="sign-up-terms">
            Don't have an account? <a ui-sref="accountSignInUp.accountSignUp">Sign up</a>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
