(function () {
  'use strict';

  angular
    .module('account.earnings')
    .config(config);

  function config($stateProvider, configOptions) {
    $stateProvider
      .state('account.earnings', angular.extend({}, configOptions.defaultStateParams, {
        url: '/earnings',
        templateUrl: 'account/account.tpl.html',
        controller: 'AccountCtrl',
        controllerAs: 'account',
        onEnter: ['RouterHelper', function (RouterHelper) {
          RouterHelper.activateTab('account', 'earnings');
        }]
      }))
      .state('accountEarningsWithdraw', angular.extend({}, configOptions.defaultStateParams, {
        url: '/account/earnings/withdraw',
        templateUrl: 'account/earnings/withdraw.tpl.html',
        controller: 'WithdrawCtrl',
        controllerAs: 'withdraw',
        resolve: {
          earnings: ['Restangular', function (Restangular) {
            return Restangular.one('Earnings').one('summaries').get()
              .then(function (result) {
                return result.plain();
              });
          }],
          userProfile: ['Session', function (Session) {
            return Session.getUserProfile();
          }]
        }
      }));
  }
}());
