(function () {
  'use strict';

  /* @ngdoc object
   * @name components.ui.search
   * @description
   *
   */
  angular
    .module('mlp-search', []);
}());
