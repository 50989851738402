<div class="panel panel-default">
  <div class="panel-heading">
    <div style="display: flex; justify-content: space-between; align-items: center;">
      <h4>Messages List</h4>
    </div>
  </div>

  <div class="lead-item lead-item-major cursor-pointer"
       ng-click="messages.switchToDirect();"
       ng-class="{'selected' : !messages.currentLead}">
    <div>
      # Direct Messages
    </div>
    <div class="hover-content">
      <div class="lead-chat">
        <a class="cursor-pointer"><i class="fa fa-chevron-right"></i></a>
      </div>
    </div>
  </div>

  <div class="lead-item lead-item-major">
    <div>
      # Lead Messages
    </div>
  </div>

  <div class="panel-list">
    <div class="pending-overlay-white pending-overlay-column opacity-slow-if" ng-if="messages.isLoadingMsg" style="opacity: 0.5;"></div>

    <div class="lead-item" ng-if="!messages.isLoadingLeads && (!messages.leads || messages.leads.length === 0)">
      You have no leads (sent or received).
    </div>

    <div class="lead-item lead-item-sub cursor-pointer"
         ng-repeat="lead in messages.leads"
         ng-click="messages.navigateTo('messagesContent', {offset: 150}, lead)"
         ng-class="{'selected': messages.currentLead.id == lead.id}">
      <div class="lead-type">
        <i class="fa" ng-class="{'fa-paper-plane': lead.leadType === 'sent', 'fa-paper-plane-o': lead.leadType === 'received'}"></i>
      </div>
      <div class="lead-content">
        <div>{{lead.firstName}} {{lead.lastName}}</div>
        <div class="lead-date">{{lead.dateSent | date:'d MMM yyyy'}}</div>
      </div>
      <div class="hidden-xs hover-content" style="display: flex; align-items: center;">
        <div class="lead-chat">
          <a class="cursor-pointer"><i class="fa fa-chevron-right"></i></a>
        </div>
      </div>
    </div>
  </div>
</div>
