(function () {
  'use strict';

  angular
    .module('myMemberships.memberships')
    .config(config);

  function config($stateProvider, configOptions) {
    $stateProvider
      .state('myMemberships.memberships', angular.extend({}, configOptions.defaultStateParams, {
        url: '/memberships',
        templateUrl: 'my-memberships/my-memberships.tpl.html',
        controller: 'MyMembershipsCtrl',
        controllerAs: 'myMemberships',
        onEnter: ['RouterHelper', function (RouterHelper) {
          RouterHelper.activateTab('myMemberships', 'memberships');
        }]
      }));
  }
}());
