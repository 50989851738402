<div class="col-xs-12" ng-controller="MembersCtrl as members">
  <div class="page-content">
    <div class="row" ng-show="members.list.isInitialised() && !members.list.recordsExist()">
      <div class="col-xs-12">
        <div class="page-empty-section text-center">
          <i class="fa fa-users fa-2x text-light-colour"></i>
          <div class="page-empty-section-text">
            You have no people in your pod
          </div>
          <br/>
          Send invites to your business partners to join your pod. Click "Invite to my Pod" to start sending invites.
        </div>
      </div>
    </div>

    <div class="row" ng-show="members.list.recordsExist() && members.list.isInitialised()">
      <div class="col-xs-12 col-sm-5 col-md-4">
        <div class="form-group page-filter">
          <label class="sr-only" for="searchName">Search name</label>
          <div class="left-inner-addon">
            <i class="fa fa-search inner-addon"></i>
            <form name="memberSearchForm" ng-submit="members.search()">
              <input class="form-control input-lg" type="search" name="searchName" id="searchName"
                     placeholder="Search" ng-model="members.searchName" ng-blur="members.search()" />
            </form>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-4 col-md-3">
        <div class="form-group page-filter dropdown-large" uib-dropdown>
          <button id="members-type" type="button" class="btn" uib-dropdown-toggle>
            {{members.selectedStatusLabel}} <span class="caret"></span>
          </button>
          <ul class="dropdown-menu" uib-dropdown-menu role="menu" aria-labelledby="members-type">
            <li role="menuitem" ng-repeat="(value, label) in members.statuses">
              <a ng-click="members.selectStatus(value)">{{label}}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="table-responsive table-container" ng-show="members.list.recordsExist() && members.list.isInitialised()">
      <table st-table="members.list.getRecords()" st-pagination-scroll st-pipe="members.list.load" class="table page-content-table allow-wrap">
        <thead class="hidden-xs">
        <tr>
          <th class="col-sm-3">Name</th>
          <th class="col-sm-5">Industry</th>
          <th class="col-sm-2">Reward (up to)</th>
          <th class="text-right col-sm-2">Reward type</th>
          <!--<th class="text-right col-sm-2">Earnings</th>-->
        </tr>
        </thead>
        <tbody ng-hide="members.list.isLoading() && !members.list.isLoadingMoreRecords()">
        <tr ng-repeat="row in members.list.getRecords()">
          <td class="col-xs-12 col-sm-3">
            <table class="table-cell-main-content">
              <tr class="row-center-items row-space-between" >
                <td class="visible-xs" ng-if="members.selectedStatus !== 'pending'" single-expand sub-content="members.tableSubContentVisible" sub-content-idx="$index"></td>
                <td ng-if="members.selectedStatus !== 'pending'">
                  <table-user-details user="row.member"></table-user-details>
                </td>
                <td ng-if="members.selectedStatus == 'pending'">
                  <table-user-details user="row.member" is-link="false" description-key="'email'"></table-user-details>
                </td>
                <td class="visible-xs text-right">
                  <span class="cursor-pointer" ng-if="members.selectedStatus !== 'pending'">
                    <i class="fa fa-ellipsis-h"></i>
                  </span>
                </td>
              </tr>
            </table>
            <div class="table-cell-sub-content-container slide-if" ng-if="members.tableSubContentVisible[$index] && members.selectedStatus !== 'pending'">
              <div class="table-cell-sub-content visible-xs">
                <span class="text-muted">Industry</span><br/>
                <span class="text-normal">{{row.member.industry.name}}</span>
                <span class="text-normal" ng-if="row.member.subIndustry">
                  <br/>{{row.member.subIndustry.name}}
                </span>
              </div>
              <div class="table-cell-sub-content visible-xs">
                <span class="text-muted">Reward (up to)</span><br/>
                <span class="text-normal">{{row.highestContractReward | currency:'$':0}}</span>
              </div>
              <div class="table-cell-sub-content visible-xs">
                <span class="text-muted">Reward type</span><br/>
                <span class="text-normal">{{row.contractType}}</span>
              </div>
              <!--<div class="table-cell-sub-content visible-xs">-->
                <!--<span class="text-muted">Earnings</span><br/>-->
                <!--<span class="text-normal">{{row.earnings | currency:'$':0}}</span>-->
              <!--</div>-->
            </div>
          </td>
          <td class="col-sm-5 hidden-xs">
            {{row.member.industry.name}}
            <span ng-if="row.member.subIndustry">
              <br/>{{row.member.subIndustry.name}}
            </span>
          </td>
          <td class="col-sm-2 hidden-xs">{{row.highestContractReward | currency:'$':0}}</td>
          <td class="col-sm-2 hidden-xs text-right">{{row.contractType}}</td>
          <!--<td class="col-sm-2 hidden-xs text-right">{{row.earnings | currency:'$':0}}</td>-->
        </tr>
        </tbody>
        <tbody ng-if="members.list.isLoading()">
        <tr>
          <td colspan="5" class="col-xs-12 text-center">
            <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="row" ng-show="!members.list.isInitialised()">
      <div class="col-xs-12 text-center">
        <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>
      </div>
    </div>
  </div>
</div>

