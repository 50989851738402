(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name mlp-charity.service:Charities
   *
   * @description
   *
   */
  angular
    .module('mlp-charity')
    .service('Charities', Charities);

  function Charities($rootScope, $document, $state, $q, Notification, $uibModal) {
    var self = this;

    /**
     * Opens the Modal dialog for donation to charity
     * @param {int} charityId Can be a charity Id (number) or directly a userProfile
     * @returns {*} Modal instance result
     */
    self.openDonate = function (charityId) {
      var modalInstance,
          params = {
            animation: false,
            templateUrl: 'mlp-charity/donate-modal.tpl.html',
            controller: 'DonateCtrl',
            controllerAs: 'donation',
            size: 'lg',
            resolve: {
              userProfile: ['Session', function (Session) {
                return Session.getUserProfile();
              }],
              charityProfile: ['Restangular', function (Restangular) {
                if (angular.isObject(charityId) && charityId.isCharityAccountActive) {
                  return charityId;
                }

                if (angular.isNumber(charityId)) {
                  return Restangular.one('Users').one('profile').get({id: charityId})
                    .then(function (profile) {
                      return profile;
                    });
                }

                return null;
              }],
              donationsHistory: ['Restangular', function (Restangular) {
                return Restangular.one('Donations').getList('lastRecipients')
                  .then(function (lastRecipients) {
                    return lastRecipients.plain();
                  });
              }]
            }
          };

      params.appendTo = angular.element($document[0].body);
      modalInstance = $uibModal.open(params);
      return modalInstance.result;
    };
  }
}());
