<div class="col-xs-12"
     ng-if="agreementPricingDetail.agreement.contractType === 'Pay Per Lead' || agreementPricingDetail.agreement.contractType === 'Rewards Program' || agreementPricingDetail.agreement.contractType === 'Custom Rewards'">
  <table class="agreement-pricing-detail">
    <tr>
      <td class="col-xs-7 col-sm-8 col-md-8 col-lg-8 phn"><span class="text-muted">Reward</span></td>
      <td class="col-xs-5 col-sm-3 col-md-3 col-lg-3 phn text-right"><span class="text-muted">{{agreementPricingDetail.label}}</span></td>
    </tr>
    <tr ng-repeat="option in agreementPricingDetail.agreement.pricingOptions | orderBy:'successfulLeadPrice'">
      <td class="col-xs-7 col-sm-8 col-md-8 col-lg-8 phn"><strong class="capitalize">{{option.name}}</strong></td>
      <td class="col-xs-5 col-sm-3 col-md-3 col-lg-3 phn text-right" valign="top"><strong>{{option.successfulLeadPrice | currency:'$':0}}</strong></td>
    </tr>
  </table>
</div>

<div class="col-xs-7 col-sm-5 col-md-5 col-lg-5" ng-if="agreementPricingDetail.agreement.contractType === 'Subscription'">
  <span class="text-muted">Reward</span>
  <br/>
  <strong>{{agreementPricingDetail.agreement.contractType}}</strong>
</div>

<div class="col-xs-5 col-sm-3 col-md-3 col-lg-3 text-right"
     ng-if="agreementPricingDetail.agreement.contractType === 'Subscription'">
  <span class="text-muted">{{agreementPricingDetail.label}}</span>
  <br/>
  <strong>{{agreementPricingDetail.agreement.subscriptionPrice | currency:'$':0}}</strong>
</div>
