<div class="modal-content-container">
  <div class="row">
      <!-- Title -->
      <div class="col-xs-12 modal-content-title">
        <span class="sub-title" ng-if="!feedback.isHelpRequired">Feedback</span>
        <span class="sub-title" ng-if="feedback.isHelpRequired">Need Help?</span>
      </div>
      <!-- ENDOF: Title-->

      <!-- alerts -->
      <uib-alert ng-repeat="alert in preferences.alerts.getList()" type="{{alert.type}}" close="feedback.alerts.remove($index)">{{alert.message}}</uib-alert>

      <!-- Content -->
      <div class="col-xs-12 feedback">
        <div class="feedback-prompt">
          <span ng-if="!feedback.isHelpRequired">We would like to hear from you! Please let us know how we can improve MyLeadPod</span>
          <span ng-if="feedback.isHelpRequired">Please provide your name, email and the issue description</span>
        </div>
        <form name="feedbackForm">
          <div class="row" ng-if="feedback.isHelpRequired">
            <div class="col-xs-12 col-sm-6">
              <div class="form-group" ng-class="{'has-error': (feedbackForm.$submitted || feedbackForm.name.$touched) && feedbackForm.name.$invalid}">
                <label class="sr-only" for="name">Name</label>
                <div ng-show="feedbackForm.$submitted || feedbackForm.name.$touched">
                  <div class="input-error" ng-show="!feedbackForm.name.value">Your name is required</div>
                </div>
                <div class="left-inner-addon">
                  <i class="fa fa-user inner-addon"></i>
                  <input type="text" class="form-control input-lg" name="name" id="name" placeholder="Your Name" ng-model="feedback.data.name" ng-required="true" />
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6">
              <div class="form-group" ng-class="{'has-error': (feedbackForm.$submitted || feedbackForm.email.$touched) && feedbackForm.email.$invalid}">
                <label class="sr-only" for="email">Email</label>
                <div ng-show="feedbackForm.$submitted || feedbackForm.email.$touched">
                  <div class="input-error" ng-show="!feedbackForm.email.$error.email">Email address is required</div>
                  <div class="input-error" ng-show="feedbackForm.email.$error.email">Invalid email address</div>
                </div>
                <div class="left-inner-addon">
                  <i class="fa fa-envelope-o inner-addon"></i>
                  <input class="form-control input-lg" type="email" name="email" id="email" ng-required="true" placeholder="Email"
                         ng-model="feedback.data.email" />
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" ng-class="{'has-error': (feedbackForm.$submitted || feedbackForm.details.$touched) && feedbackForm.details.$error.required}">
            <label class="sr-only" for="details">Feedback</label>
            <div ng-show="feedbackForm.$submitted || feedbackForm.details.$touched">
              <div class="input-error" ng-show="feedbackForm.details.$error.required" ng-if="!feedback.isHelpRequired">Missing feedback</div>
              <div class="input-error" ng-show="feedbackForm.details.$error.required" ng-if="feedback.isHelpRequired">Missing issue description</div>
            </div>
            <div class="left-inner-addon">
              <i class="fa fa-comment-o inner-addon"></i>
              <textarea class="form-control input-lg" name="details" id="details" placeholder="Feedback"
                        ng-required="true" ng-model="feedback.data.feedback" msd-elastic></textarea>
            </div>
          </div>

        </form>
      </div>
      <!-- ENDOF: Content -->
  </div>

  <div class="modal-content-buttons">
    <div class="text-right">
      <button type="button" class="btn btn-default" ng-click="feedback.cancel()">
        Cancel
      </button>
      <button type="button" class="btn btn-primary"
              ng-disabled="!feedbackForm.$valid || feedback.isSending" ng-click="feedback.send()">
        {{feedback.isSending ? 'Sending ...' : 'Send'}}
      </button>
    </div>
  </div>
</div>


