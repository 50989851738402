(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.directive:scrollCheck
   * @restrict A
   * @element
   *
   * @description Directive that checks the scroll and provides the css classes based on the location of the scroll
   *
   */
  angular
    .module('components')
    .directive('scrollCheck', scrollCheck);

  function scrollCheck($rootScope, $document, $timeout) {
    return {
      restrict: 'A',
      scope: true,
      link: function (scope, element, attrs) {
        var targetElement,
            checkScroll,
            hasScrollbar,
            watchHandle,
            init;

        scope.altElementBreakpoint = attrs.altElementBreakpoint ? parseInt(attrs.altElementBreakpoint, 10) : 1024;
        if (!angular.isNumber(scope.altElementBreakpoint)) {
          scope.altElementBreakpoint = 1024;
        }

        // Function
        checkScroll = function () {
          if (!targetElement || !targetElement[0]) {
            hasScrollbar = false;
            return false;
          }
          hasScrollbar = targetElement[0].scrollWidth > targetElement[0].clientWidth;

          // Now apply classes
          if (hasScrollbar) {
            element.removeClass('scroll-start');
            element.removeClass('scroll-between');
            element.removeClass('scroll-end');
            // Check the scrollbar position
            if (targetElement[0].scrollLeft === 0) {
              element.addClass('scroll-start');
            }
            else if (targetElement[0].scrollWidth - targetElement[0].scrollLeft === targetElement[0].clientWidth) {
              element.addClass('scroll-end');
            }
            else {
              element.addClass('scroll-between');
            }
          }
        };

        init = function () {
          targetElement = angular.element($document[0].getElementById(attrs.targetElementId));
          // Bind to the on scroll event
          targetElement.bind('scroll', function () {
            checkScroll();
          });
          checkScroll();
        };

        // Get target element
        if (angular.isDefined(attrs.targetElementId)) {
          if (attrs.withDelay) {
            $timeout(function () {
              init();
            }, Number(attrs.withDelay));
          }
          else {
            init();
          }
        }

        // Subscribe to the command of checking the scroll from parent (?). We shall be passing around the id of the element but no time for that
        watchHandle = $rootScope.$on('event:check-scroll', function () {
          checkScroll();
        });
        scope.$on('$destroy', watchHandle);
      }
    };
  }
}());
