<div>
  <form id="rewardsForm" name="rewardsForm" ng-class="{'is-invalid': rewards.checkValidity()}">
    <div class="row" ng-if="rewards.hasDefaultProgram">
      <div class="col-xs-12 col-sm-12">
        <div class="form-group reward-system-selector"
              ng-class="{active: rewards.data.contractType === rewards.selectors.default.value}"
              ng-click="rewards.onContractTypeChange(rewards.selectors.default.value, rewardsForm, $event)">

          <input type="radio" name="contractType" id="{{rewards.selectors.default.id}}" value="{{rewards.selectors.default.value}}"
                  ng-model="rewards.data.contractType" ng-required="true" />
          <label class="radio-label" for="{{rewards.selectors.default.id}}">
            {{rewards.selectors.default.desc}}
            <span class="cursor-pointer detail-arrow"
                  ng-if="rewards.data.contractType === rewards.selectors.default.value && !rewards.hasLockedDefaultProgram"
                  ng-click="rewards.toggleRewardsProgramDetailVisibility()">
              <i class="fa fa-chevron-down" ng-class="{'fa-chevron-down': !rewards.isRewardsProgramDetailVisible, 'fa-chevron-up': rewards.isRewardsProgramDetailVisible}"></i>
            </span>
          </label>

          <div class="reward-system-detail slide-if" ng-if="rewards.data.contractType === rewards.selectors.default.value && rewards.isRewardsProgramDetailVisible">
                <span ng-if="rewards.rewardsProgramSetup.length < 1">
                  Your Rewards Program is not set up. Set up your Rewards Program <a ui-sref="myMembershipsRewardProgramsSetup" target="_blank">here</a> to be able to ask for the leads with this option.
                </span>
            <table class="rewards-program-options" ng-if="rewards.rewardsProgramSetup.length > 0">
              <tr ng-repeat="option in rewards.rewardsProgramSetup | orderBy:'successfulLeadPrice'">
                <td>{{option.name}}</td>
                <td>{{option.successfulLeadPrice | currency:"$":0}}</td>
              </tr>
              <tr>
                <td colspan="2">
                  <a ui-sref="myMembershipsRewardProgramsSetup" target="_blank">Click here to manage</a>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="row" ng-if="rewards.hasCode">
      <div class="col-xs-12 col-sm-12">
        <div class="form-group reward-system-selector" ng-class="{active: rewards.data.contractType === rewards.selectors.code.value}" ng-click="rewards.onContractTypeChange(rewards.selectors.code.value, rewardsForm, $event)">
          <input type="radio" name="contractType" id="{{rewards.selectors.code.id}}" value="{{rewards.selectors.code.value}}" ng-model="rewards.data.contractType" ng-required="true" />
          <label class="radio-label" for="{{rewards.selectors.code.id}}">
            {{rewards.selectors.code.desc}}
          </label>

          <div class="reward-system-detail slide-if" ng-if="rewards.data.contractType === rewards.selectors.code.value">
            <div class="form-group" ng-class="{'has-error': (rewardsForm.$submitted || rewardsForm.rewardCode.$touched) && rewardsForm.rewardCode.$error.required}">

              <label class="sr-only" for="rewardCode">Select Reward Code</label>
              <div>
                <div ng-show="rewardsForm.$submitted || rewardsForm.rewardCode.$touched">
                  <div class="input-error" ng-show="rewardsForm.rewardCode.$error.required">Select Reward Code</div>
                </div>
              </div>
              <div class="left-inner-addon">
                <i class="fa fa-hashtag inner-addon"></i>
                <select class="form-control input-lg" name="rewardCode" id="rewardCode" placeholder="Reward Code"
                        ng-required="true" ng-model="rewards.data.rewardCode" ng-change="rewards.onValueChanged()">
                    <option value="" disabled selected>Select Reward Code</option>
                    <option ng-repeat="(value, label) in rewards.rewardCodes" value="{{value}}">{{label}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" ng-if="rewards.hasPayPerLead">
      <div class="col-xs-12 col-sm-12">
        <div class="form-group reward-system-selector" ng-class="{active: rewards.data.contractType === rewards.selectors.payPerLead.value}" ng-click="rewards.onContractTypeChange(rewards.selectors.payPerLead.value, rewardsForm, $event)">
          <input type="radio" name="contractType" id="{{rewards.selectors.payPerLead.id}}" value="{{rewards.selectors.payPerLead.value}}" ng-model="rewards.data.contractType" ng-required="true" />
          <label class="radio-label" for="{{rewards.selectors.payPerLead.id}}">
            {{rewards.selectors.payPerLead.desc}}
          </label>
          <div class="reward-system-detail slide-if" ng-if="rewards.data.contractType === rewards.selectors.payPerLead.value">
            <div class="form-group" ng-class="{'has-error': (rewardsForm.$submitted || rewardsForm.successfulLeadPrice.$touched) && (rewardsForm.successfulLeadPrice.$error.required || rewardsForm.successfulLeadPrice.$error.number || rewardsForm.successfulLeadPrice.$error.min)}">

              <label class="sr-only" for="successfulLeadPrice">Successful lead value</label>
              <div>
                <div ng-show="rewardsForm.$submitted || rewardsForm.successfulLeadPrice.$touched">
                  <div class="input-error" ng-show="rewardsForm.successfulLeadPrice.$error.number">Invalid successful lead value</div>
                  <div class="input-error" ng-show="rewardsForm.successfulLeadPrice.$error.required">Missing successful lead value</div>
                  <div class="input-error" ng-show="rewardsForm.successfulLeadPrice.$error.min">Successful lead value must not be negative</div>
                </div>
              </div>
              <div class="left-inner-addon">
                <i class="fa fa-dollar inner-addon"></i>
                <input class="form-control input-lg" type="number" name="successfulLeadPrice" id="successfulLeadPrice" placeholder="Successful lead value"
                        ng-required="true" ng-model="rewards.data.successfulLeadPrice" min="0" ng-change="rewards.onValueChanged()" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" ng-if="rewards.hasMonthlySubscription">
      <div class="col-xs-12 col-sm-12">
        <div class="form-group reward-system-selector" ng-class="{active: rewards.data.contractType === rewards.selectors.subscription.value}" ng-click="rewards.onContractTypeChange(rewards.selectors.subscription.value, rewardsForm, $event)">
          <input type="radio" name="contractType" id="{{rewards.selectors.subscription.id}}" value="{{rewards.selectors.subscription.value}}" ng-model="rewards.data.contractType" ng-required="true" />
          <label class="radio-label" for="{{rewards.selectors.subscription.id}}">
            {{rewards.selectors.subscription.desc}}
          </label>
          <div class="reward-system-detail slide-if" ng-if="rewards.data.contractType === rewards.selectors.subscription.value">
            <div ng-class="{'has-error': (rewardsForm.$submitted || rewardsForm.subscriptionPrice.$touched) && (rewardsForm.subscriptionPrice.$error.required || rewardsForm.subscriptionPrice.$error.number || rewardsForm.subscriptionPrice.$error.min)}">

              <label class="sr-only" for="subscriptionPrice">Subscription value</label>
              <div>
                <div ng-show="rewardsForm.$submitted || rewardsForm.subscriptionPrice.$touched">
                  <div class="input-error" ng-show="rewardsForm.subscriptionPrice.$error.number">Invalid subscription value</div>
                  <div class="input-error" ng-show="rewardsForm.subscriptionPrice.$error.required">Missing subscription value</div>
                  <div class="input-error" ng-show="rewardsForm.subscriptionPrice.$error.min">Subscription value must not be negative</div>
                </div>
              </div>
              <div class="left-inner-addon">
                <i class="fa fa-dollar inner-addon"></i>
                <input class="form-control input-lg" type="number" name="subscriptionPrice" id="subscriptionPrice" placeholder="Subscription value"
                        ng-required="true" ng-model="rewards.data.subscriptionPrice" min="0" ng-change="rewards.onValueChanged()" />
              </div>
            </div>
            <div class="row" ng-if="rewards.showCCForm">
              <div class="col-xs-12">
                <credit-card cc-data="rewards.data.creditCard" cc-show-form="rewards.showCCForm"></credit-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" ng-if="rewards.hasCancelAgreement">
      <div class="col-xs-12 col-sm-12">
        <div class="form-group reward-system-selector" ng-class="{'active warning': rewards.data.contractType === rewards.selectors.cancel.value}" ng-click="rewards.onContractTypeChange(rewards.selectors.cancel.value, rewardsForm, $event)">
            <input type="radio" name="contractType" id="{{rewards.selectors.cancel.id}}" value="{{rewards.selectors.cancel.value}}" ng-model="rewards.data.contractType" ng-required="true" />
            <label class="radio-label" for="{{rewards.selectors.cancel.id}}">
              {{rewards.selectors.cancel.desc}}
            </label>
        </div>
      </div>
    </div>
  </form>
</div>
