(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name dashboard.pod.directive:podMembersList
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="dashboard.pod">
       <file name="index.html">
        <pod-members-list></pod-members-list>
       </file>
     </example>
   *
   */
  angular
    .module('dashboard.pod')
    .directive('podMembersList', podMembersList);

  function podMembersList() {
    return {
      restrict: 'EA',
      scope: {
        list: '=list'
      },
      templateUrl: 'dashboard/pod/pod-members-list-directive.tpl.html',
      replace: false,
      controllerAs: 'podMembersList',
      controller: function ($scope) {
        var vm = this;
        vm.list = $scope.list;
      }
    };
  }
}());
