(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name account.earnings.controller:EarningsCtrl
   *
   * @description
   *
   */
  angular
    .module('account.earnings')
    .controller('EarningsCtrl', EarningsCtrl);

  function EarningsCtrl($state, Restangular, DataTable, Leads) {
    var vm = this;
    vm.summaries = {};
    vm.ranges = {
      all: 'Show all',
      lastMonth: 'Last month',
      lastYear: 'Last year'
    };
    vm.selectedRange = 'all';
    vm.selectedRangeLabel = 'Show all';
    vm.list = DataTable.create('Earnings', 'index');
    vm.withdrawalsList = DataTable.create('Withdrawals', 'index');
    vm.isLoadingSummaries = true;

    vm.tabManager = null;
    vm.tabsConfig = [
      {
        label: 'Earnings',
        value: 'earnings'
      },
      {
        label: 'Withdrawals',
        value: 'withdrawals'
      }
    ];

    Restangular.one('Earnings').one('summaries').get()
      .then(function (result) {
        vm.summaries = result.plain();
        vm.isLoadingSummaries = false;
      }, function () {
        vm.isLoadingSummaries = false;
      });

    vm.selectRange = function (range) {
      vm.selectedRange = range;
      vm.selectedRangeLabel = vm.ranges[range];
      vm.list.reload({
        dateRange: range
      });
    };

    vm.viewLastMonthEarnings = function () {
      vm.tabManager.openTab('earnings');
      vm.selectRange('lastMonth');
    };

    vm.viewAllEarnings = function () {
      vm.tabManager.openTab('earnings');
      vm.selectRange('all');
    };

    vm.withdraw = function () {
      $state.go('accountEarningsWithdraw');
    };

    vm.openLead = function ($index) {
      var earning = vm.list.getRecords()[$index];
      if (!isNaN(earning.idLead) && parseInt(earning.idLead, 10) > 0) {
        Leads.openDetailById(earning.idLead, 'sent');
      }
    };
  }
}());
