(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name components.service:CreditCardUtil
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('CreditCardUtil', CreditCardUtil);

  function CreditCardUtil() {
    var self = this,
        acceptedCreditCards = ['Visa', 'MasterCard', 'American Express'],
        expiryMonths = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        currentYear = new Date().getFullYear(),
        expiryYears = _.range(currentYear, currentYear + 20, 1);

    /**
     * Returns list of accepted credit cards
     *
     * @returns {string[]} Array of accepted credit cards
     */
    self.getAcceptedCards = function () {
      return acceptedCreditCards;
    };

    /**
     * Returns expiry months
     *
     * @returns {string[]} Array of months
     */
    self.getExpiryMonths = function () {
      return expiryMonths;
    };

    /**
     * Returns expiry years
     *
     * @returns {integer[]} Array of valid years
     */
    self.getExpiryYears = function () {
      return expiryYears;
    };
  }
}());
