(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name socialNetwork.service:SocialAccount
   *
   * @description
   *
   */
  angular
    .module('socialNetwork')
    .service('SocialAccount', SocialAccount);

  function SocialAccount($q, $state, configOptions, socialNetworkProvider, Auth) {
    var self = this;

    /**
     * Signs user in
     *
     * @param {string} provider Social network name
     * @param {Object} options  Additional options
     * @param {Object} params Additional parameters
     * @returns {Deferred} Deferred promise
     */
    function signIn(provider, options, params) {
      var deferred = $q.defer();
      /* eslint camelcase: [2, {properties: "never"}]*/
      if (!angular.isObject(options)) {
        options = {};
      }

      options.redirect_uri = $state.href($state.current, $state.params, {absolute: true});
      hello.login(provider, options, function () {
        var result = hello(provider).getAuthResponse();
        if (angular.isObject(result) && angular.isDefined(result.access_token)) {
          Auth.socialSignIn(provider, result.access_token, params)
            .then(function (signInResult) {
              deferred.resolve(signInResult);
            }, function (reason) {
              deferred.reject(reason);
            });
        }
        else {
          deferred.reject('An error occurred');
        }
      });

      return deferred.promise;
    }

    /**
     * Google Sign In
     *
     * @param {Object} params Additional parameters
     *
     * @returns {Deferred} Deferred promise
     */
    function googleSignIn(params) {
      return signIn(socialNetworkProvider.google, {
        scope: configOptions.googlePlusScope
      }, params);
    }

    /**
     * Facebook Sign In
     *
     * @param {Object} params Additional parameters
     *
     * @returns {Deferred} Deferred promise
     */
    function facebookSignIn(params) {
      return signIn(socialNetworkProvider.facebook, {
        scope: configOptions.facebookScope
      }, params);
    }

    /**
     * LinkedIn Sign In
     *
     * @param {Object} params Additional parameters
     *
     * @returns {Deferred} Deferred promise
     */
    function linkedInSignIn(params) {
      return signIn(socialNetworkProvider.linkedIn, {
        scope: configOptions.linkedInScope
      }, params);
    }

    /**
     * Signs user in
     *
     * @param {string} provider Social network name
     * @param {Object} params Additional parameters
     *
     * @returns {Deferred} Deferred promise
     */
    self.signIn = function (provider, params) {
      switch (provider) {
        case socialNetworkProvider.google:
          return googleSignIn(params);
        case socialNetworkProvider.facebook:
          return facebookSignIn(params);
        case socialNetworkProvider.linkedIn:
          return linkedInSignIn(params);
        default:
          throw new Error('Unknown social network provider');
      }
    };
  }
}());
