(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name api.constant:authEvents
   *
   * @description
   *
   */
  angular
    .module('api')
    .constant('authEvents', {
      loginSuccess: 'event:auth-loginConfirmed',
      loginFailed: 'event:auth-loginFailed',
      loginCancelled: 'event:auth-loginCancelled',
      logoutSuccess: 'event:auth-logoutSuccess',
      sessionTimeout: 'event:auth-sessionTimeout',
      sessionLoaded: 'event:auth-sessionLoaded',
      notAuthenticated: 'event:auth-loginRequired',
      notAuthorised: 'event:auth-notAuthorised'
    });
}());
