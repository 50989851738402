<div class="col-xs-12" ng-controller="UserSentLeadsCtrl as userSentLeads" id="user-sent-leads-ctrl-container">
  <div class="page-no-content">
    <div class="row" ng-hide="userSentLeads.list.recordsExist() || !userSentLeads.list.isInitialised()">
      <div class="col-xs-12">
        <div class="page-empty-section text-center">
          <i class="fa fa-paper-plane fa-2x text-light-colour"></i>
          <div class="page-empty-section-text">
            You haven't sent any leads to {{userSentLeads.userProfile.fullName}}
          </div>
        </div>
      </div>
    </div>

    <div class="table-responsive" ng-show="userSentLeads.list.recordsExist() && userSentLeads.list.isInitialised()">
      <table st-table="userSentLeads.list.getRecords()" st-pipe="userSentLeads.list.load" class="table page-content-table allow-wrap">
        <thead class="hidden-xs">
        <tr>
          <th class="col-sm-3">Date sent</th>
          <th class="col-sm-4">Name</th>
          <th class="col-sm-3">Status</th>
          <th class="col-sm-2 text-right">Details</th>
        </tr>
        </thead>
        <tbody ng-hide="userSentLeads.list.isLoading()">
        <tr ng-repeat="row in userSentLeads.list.getRecords()">
          <td class="col-xs-12 col-sm-3">
            <table class="table-cell-main-content">
              <tr class="row-center-items row-space-between">
                <td class="visible-xs" single-expand sub-content="userSentLeads.tableSubContentVisible" sub-content-idx="$index"></td>
                <td>
                  <span class="hidden-xs">{{row.dateSent | date:'d MMM yyyy'}}</span>
                  <span class="visible-xs text-muted">{{row.dateSent | date:'d MMM yyyy'}}<br/></span>
                  <span class="visible-xs">{{row.firstName}} {{row.lastName}}</span>
                </td>
                <td class="visible-xs text-right">
                  <span class="cursor-pointer" ng-click="userSentLeads.tableSubContentVisible[$index] = !userSentLeads.tableSubContentVisible[$index]">
                    <i class="fa fa-ellipsis-h"></i>
                  </span>
                </td>
              </tr>
            </table>
            <div class="table-cell-sub-content-container slide-if" ng-if="userSentLeads.tableSubContentVisible[$index]">
              <div class="table-cell-sub-content visible-xs">
                <span class="text-muted">Status</span><br/>
                <span class="text-normal">{{userSentLeads.statuses[row.status]}}</span>
              </div>
              <div class="table-cell-sub-content visible-xs">
                <span class="text-muted">Details</span><br/>
                <span class="text-normal">
                  <a class="cursor-pointer" ng-click="userSentLeads.openDetail($index)">View lead</a>
                </span>
              </div>
            </div>
          </td>
          <td class="col-sm-4 hidden-xs">{{row.firstName}} {{row.lastName}}</td>
          <td class="col-sm-3 hidden-xs">{{userSentLeads.statuses[row.status]}}</td>
          <td class="col-sm-2 hidden-xs text-right">
            <a class="cursor-pointer" ng-click="userSentLeads.openDetail($index)">View lead</a>
          </td>
        </tr>
        </tbody>

        <tbody ng-if="userSentLeads.list.isLoading()">
        <tr>
          <td colspan="4" class="col-xs-12 text-center">
            <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="row" ng-show="!userSentLeads.list.isInitialised()">
      <div class="col-xs-12 text-center">
        <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>
      </div>
    </div>
  </div>
</div>

