<div class="modal-content-container">
  <div class="row">
    <div class="col-xs-12">
      <div class="row">
        <div class="col-xs-12 lead-detail-header">
          <span class="sub-title">
            Embed Reward Code
          </span>
        </div>
      </div>

      <div class="row form-section-body">
        <div class="col-xs-10">
          <textarea class="form-control input-lg" type="text" ng-model="rewardCodeEmbed.embedCode"></textarea>
        </div>
        <div class="col-xs-2">
          <a class="action-icon-link cursor-pointer" ngclipboard data-clipboard-text="{{rewardCodeEmbed.embedCode}}"
             uib-tooltip="Click to copy" tooltip-placement="bottom">
            <i class="fa fa-clipboard fa-2x"></i>
          </a>
        </div>
      </div>

      <div class="row form-section-body">
        <div class="col-xs-12">
          <a ng-show="!rewardCodeEmbed.previewVisible" class="cursor-pointer" ng-click="rewardCodeEmbed.previewVisible = true;">Show Preview</a>
          <a ng-show="rewardCodeEmbed.previewVisible" class="cursor-pointer" ng-click="rewardCodeEmbed.previewVisible = false;">Hide Preview</a>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12" ng-if="rewardCodeEmbed.previewVisible">
          <div class="text-center" ng-bind-html="rewardCodeEmbed.embedCode">
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="row modal-content-buttons">
    <div class="col-xs-12 text-right">
      <button class="btn btn-default" ng-click="rewardCodeEmbed.close()">
        Close
      </button>
    </div>
  </div>
</div>
