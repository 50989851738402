(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name account.controller:SignUpCtrl
   *
   * @description
   *
   */
  angular
    .module('account')
    .controller('SignUpCtrl', SignUpCtrl);

  function SignUpCtrl($state, $stateParams, Restangular, FormAlerts, SocialAccount, invitation, rewardCode) {
    var vm = this;
    vm.data = {};
    vm.alerts = new FormAlerts();
    vm.showMessage = false;
    vm.isLoading = false;
    vm.invitationSender = null;
    vm.invitationType = null;

    // For trial content to display.
    vm.trialEligible = false;
    vm.trialDuration = null;

    // Reward Code
    vm.rewardCode = null;
    vm.rewardCodeChecked = false;
    vm.isRewardCodeValid = false;

    // If it's coming as BOOL false val, then we tried to retrieve it in reward-program-routes but was not found
    if (rewardCode === false) {
      vm.rewardCodeChecked = true;
    }

    if (angular.isObject(invitation) && angular.isDefined(invitation.email)) {
      vm.invitationSender = invitation.userSender;
      vm.invitationType = invitation.invitationType;
      vm.data.email = invitation.email;
    }
    else if (angular.isObject(rewardCode) && angular.isDefined(rewardCode.owner)) {
      vm.invitationSender = rewardCode.owner;
      vm.invitationType = 'Reward Code';
      vm.rewardCode = $stateParams.rewardCode;
      if (rewardCode.rewardCode) {
        vm.trialEligible = rewardCode.rewardCode.isEligibleForBusinessAccountTrial;
        vm.trialDuration = rewardCode.rewardCode.businessAccountTrialDuration;
      }
    }

    vm.passwordSignUp = function () {
      vm.alerts.reset();
      vm.isLoading = true;
      Restangular.one('Users').post('signUp', {
        email: vm.data.email,
        password: vm.data.password,
        invitationToken: $stateParams.invitationToken,
        rewardCode: $stateParams.rewardCode || vm.rewardCode
      })
        .then(function (result) {
          if (angular.isDefined(result.activationToken)) {
            $state.go('accountActivate', {
              token: result.activationToken,
              invitationToken: $stateParams.invitationToken
            });
          }
          else {
            vm.showMessage = true;
          }
        }, function (error) {
          vm.isLoading = false;
          if (error.data && error.data.errorCode === 107) {
            vm.alerts.add('danger', 'This email is already registered. You can <a href="/account/signin">login here</a>.');
          }
          else {
            vm.alerts.add('danger', 'Unable to sign up');
          }
          console.log(error);
        });
    };

    vm.socialSignIn = function (provider) {
      vm.isLoading = true;
      vm.alerts.reset();
      SocialAccount.signIn(provider, {
        invitationToken: $stateParams.invitationToken,
        rewardCode: $stateParams.rewardCode
      })
        .then(function (result) {
          if (angular.isDefined(result.activationToken)) {
            $state.go('accountActivate', {
              token: result.activationToken,
              invitationToken: $stateParams.invitationToken
            });
          }
          else if (angular.isDefined($stateParams.invitationToken) && angular.isString($stateParams.invitationToken)) {
            $state.go('userInvitation', {token: $stateParams.invitationToken});
          }
          else {
            $state.go('dashboard');
          }
        }, function () {
          vm.isLoading = false;
          vm.alerts.add('danger', 'Unable to sign up');
        });
    };

    vm.checkRewardCode = function () {
      if (!vm.rewardCode) {
        vm.isRewardCodeValid = false;
        vm.rewardCodeChecked = false;
        return;
      }
      if (vm.rewardCode === vm.lastChecked) {
        return;
      }
      vm.rewardCardOwner = null;
      vm.lastChecked = vm.rewardCode;
      vm.rewardCode = vm.rewardCode.toUpperCase().replace(/\s+/g, '-');
      vm.isCheckingCode = true;
      Restangular.one('RewardCodes').one('detail').get({rewardCode: vm.rewardCode})
        .then(function (result) {
          vm.rewardCardOwner = result.owner;
          vm.rewardCodeChecked = true;
          vm.isCheckingCode = false;
          vm.isRewardCodeValid = true;
        }, function () {
          vm.isRewardCodeValid = false;
          vm.isCheckingCode = false;
          vm.rewardCodeChecked = true;
        });
    };

    vm.onRewardCodeEnter = function ($event) {
      if ($event.key === 'Enter') {
        vm.lastChecked = null;
        vm.checkRewardCode();
      }
    };
  }
}());
