(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name dashboard.pod.directive:podEarningsGraph
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="dashboard.pod">
       <file name="index.html">
        <pod-earnings-graph></pod-earnings-graph>
       </file>
     </example>
   *
   */
  angular
    .module('dashboard.pod')
    .directive('podEarningsGraph', podEarningsGraph);

  function podEarningsGraph() {
    return {
      restrict: 'EA',
      scope: {
        list: '=list'
      },
      templateUrl: 'dashboard/pod/pod-earnings-graph-directive.tpl.html',
      replace: false,
      controllerAs: 'podEarningsGraph',
      controller: function ($scope, $filter) {
        var vm = this;

        vm.chartData = {
          labels: _.map($scope.list, function (day) {
            return $filter('date')(day.date, 'd/M');
          }),
          series: [_.map($scope.list, function (day) {
            return day.cumulativeEarnings;
          })]
        };

        vm.chartOptions = {
          axisX: {
            showGrid: false,
            showLabel: false
          },
          axisY: {
            showGrid: true,
            showLabel: true,
            low: 0,
            onlyInteger: true
          },
          lineSmooth: false,
          scaleMinSpace: 10,
          showArea: true,
          height: 400,
          stretch: true
        };

        vm.chartEvents = {
          draw: function (data) {
            var circle;
            if (data.type === 'point') {
              circle = new Chartist.Svg('circle', {
                cx: [data.x],
                cy: [data.y],
                r: [5]
              }, 'ct-circle');
              data.element.replace(circle);
            }
          }
        };

        vm.responsiveOptions = [
          ['screen and (min-width: 768px) and (max-width: 991px)', {
            width: 646
          }],
          ['screen and (min-width: 992px) and (max-width: 1199px)', {
            width: 570
          }],
          ['screen and (min-width: 1200px)', {
            width: 688
          }]
        ];
      },
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
