(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.ui.directive:appsStoreLinks
   * @restrict EA
   * @element
   *
   */
  angular
    .module('components.ui')
    .directive('appsStoreLinks', appsStoreLinks);

  function appsStoreLinks($window, $document, $rootScope) {
    return {
      restrict: 'EA',
      scope: {},
      replace: true,
      templateUrl: 'components/ui/apps-store-links-directive.tpl.html',
      link: function (scope) {
        var watchHandle;
        scope.isAvailable = true;
        scope.isAuthenticated = $rootScope.isAuthenticated;

        // Check if the navigator has the "gonative" userAgent to determine whether it's the "app" or the "web"
        if ($window.navigator.userAgent.indexOf('gonative') > -1) {
          scope.isAvailable = false;
        }
        else if (!scope.isAuthenticated) {
          // Add the css class to adjust the app content a little
          angular.element($document[0].body).addClass('has-appsdownload-links');
        }
        watchHandle = $rootScope.$watch('isAuthenticated', function () {
          scope.isAuthenticated = $rootScope.isAuthenticated;
          if (scope.isAvailable) {
            if (scope.isAuthenticated) {
              angular.element($document[0].body).removeClass('has-appsdownload-links');
            }
            else {
              angular.element($document[0].body).addClass('has-appsdownload-links');
            }
          }
        });
        $rootScope.$on('$destroy', watchHandle);
      }
    };
  }
}());
