(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name account.profile.controller:ProfileCtrl
   *
   * @description
   *
   */
  angular
    .module('account.profile')
    .controller('ProfileCtrl', ProfileCtrl);

  function ProfileCtrl($document, $rootScope, $scope, $timeout, $state, $uibModal, Notification, configOptions, Restangular, Location, Session, FormAlerts, Industries) {
    var vm = this,
        configDefaults;
    vm.alerts = new FormAlerts();
    vm.isBusinessAccount = false;
    vm.isLoading = true;
    vm.isLoadingBackgroundImg = false;
    vm.isSavingBackgound = false;
    vm.photoFile = null;
    vm.backgroundFile = null;
    vm.imageEditMode = false;
    vm.isDragging = false;
    vm.backgroundStyleBkp = null;
    vm.resetRequested = false;

    // options for color picker
    vm.customOptions = {
      size: 28,
      roundCorners: true
    };
    vm.customColors = [null, '#000000', '#ffffff', '#ffc0cb', '#008080', '#ffe4e1', '#ff0000', '#ffd700', '#d3ffce', '#00ffff', '#ff7373', '#40d0e0', '#e6e6fa'];
    vm.customHeadlineColors = ['#3b374b', '#ffffff'];

    configDefaults = {
      selectedColor: '#fffffa',
      headlineColor: '#3b374a',
      keepPattern: true,
      backgroundFile: null
    };

    vm.defaults = _.clone(configDefaults);

    // Background Edits
    vm.edits = {
      patternEdited: false,
      colorEdited: false,
      headlineEdited: false,
      imageEdited: false
    };

    vm.data = {
      address: {},
      addressPostal: {}
    };
    Industries.isReady.then(function () {
      vm.industries = Industries.getConsolidatedList();
    });
    vm.getCountries = Location.getCountries;
    vm.getStates = function () {
      return Location.getStates(vm.data.addressPostal.idCountryPostal);
    };

    Session.getUserProfile()
      .then(function (userProfile) {
        vm.userProfile = userProfile;
        vm.data.firstName = vm.userProfile.firstName;
        vm.data.lastName = vm.userProfile.lastName;
        vm.data.email = vm.userProfile.email;
        vm.data.phoneMobile = vm.userProfile.phoneMobile;
        vm.data.businessName = vm.userProfile.businessName;
        vm.data.description = vm.userProfile.description;
        vm.data.website = vm.userProfile.website;
        vm.data.location = vm.userProfile.location;
        vm.data.address.streetAddress = vm.userProfile.streetAddress;
        vm.data.address.suburb = vm.userProfile.suburb;
        vm.data.address.state = vm.userProfile.state;
        vm.data.address.postCode = vm.userProfile.postCode;
        vm.data.address.country = vm.userProfile.country;
        vm.data.addressPostal = vm.userProfile.addressPostal;
        if (angular.isObject(vm.userProfile.addressPostal) && angular.isDefined(vm.userProfile.addressPostal.idCountryPostal) && !!vm.userProfile.addressPostal.idCountryPostal) {
          vm.data.addressPostal.idCountryPostal = vm.userProfile.addressPostal.idCountryPostal.toString();
        }
        if (angular.isObject(vm.userProfile.addressPostal) && angular.isDefined(vm.userProfile.addressPostal.idStatePostal) && !!vm.userProfile.addressPostal.idStatePostal) {
          vm.data.addressPostal.idStatePostal = vm.userProfile.addressPostal.idStatePostal.toString();
        }

        if (vm.userProfile && vm.userProfile.isBusinessAccountActive) {
          vm.isBusinessAccount = true;
        }

        vm.selectedClassification = {};

        if (angular.isObject(vm.userProfile.industry)) {
          vm.data.idClassification = vm.userProfile.industry.id.toString();
          vm.selectedClassification = {id: vm.userProfile.industry.id, name: vm.userProfile.industry.name};
        }
        if (angular.isObject(vm.userProfile.subIndustry)) {
          vm.data.idClassification = vm.userProfile.subIndustry.id.toString();
          vm.selectedClassification = {id: vm.userProfile.subIndustry.id, name: vm.userProfile.industry.name + ' - ' + vm.userProfile.subIndustry.name};
        }
        else {
          vm.data.idClassification = null;
        }

        // We need to hold the background style object as the backup.
        vm.backgroundStyleBkp = _.clone(vm.getCoverStyle(vm.userProfile));
        vm.clearData();

        vm.isLoading = false;
      });

    vm.save = function () {
      vm.alerts.reset();
      vm.isLoading = true;
      Session.updateUserProfile(vm.data)
        .then(function (profile) {
          vm.userProfile = profile;
          $state.reload();
          Notification({message: 'Your profile has been successfully updated'}, 'confirmation');
        }, function () {
          vm.isLoading = false;
          vm.alerts.add('danger', 'Unable to save');
        });
    };

    vm.hasPhoto = function () {
      return !!vm.userProfile.imageToken;
    };

    vm.removePhoto = function () {
      vm.isLoading = true;
      Session.removePhoto()
        .then(function (profile) {
          vm.userProfile = profile;
          $state.reload();
          Notification({message: 'Your photo has been removed'}, 'confirmation');
        }, function () {
          vm.isLoading = false;
        });
    };

    vm.uploadPhoto = function () {
      var modalInstance;
      if (vm.photoFile !== null) {
        modalInstance = $uibModal.open({
          animation: false,
          appendTo: angular.element($document[0].getElementById('profile-ctrl-container')),
          templateUrl: 'account/profile/photo-upload.tpl.html',
          controller: 'PhotoUploadCtrl',
          controllerAs: 'photoUpload',
          size: 'lg',
          resolve: {
            photoFile: vm.photoFile
          }
        });

        modalInstance.result.then(function (result) {
          if (angular.isDefined(result.userProfile)) {
            Notification({message: 'Your photo has been successfully updated'}, 'confirmation');
            vm.userProfile = result.userProfile;
          }
        });
      }
    };

    vm.placeChangedCallback = function (address) {
      vm.data.address = address;
    };

    vm.onCountryChange = function () {
      vm.data.addressPostal.idStatePostal = '';
    };

    vm.onClassificationSelect = function (classification) {
      if (angular.isObject(classification) && angular.isObject(classification.originalObject)) {
        vm.data.idClassification = classification.originalObject.id;
      }
    };

    vm.getAddress = function () {
      var addressParts = [];
      if (vm.userProfile.suburb) {
        addressParts.push(vm.userProfile.suburb);
      }
      if (vm.userProfile.state) {
        addressParts.push(vm.userProfile.state);
      }
      if (vm.userProfile.country) {
        addressParts.push(vm.userProfile.country);
      }

      vm.addressLink = vm.userProfile.addressPostal && vm.userProfile.addressPostal.addressPostal ? vm.userProfile.addressPostal.addressPostal + ', ' + addressParts.join(', ') : addressParts.join(', ');
      return addressParts.join(', ');
    };

    vm.formatWebsiteUrl = function () {
      var url = '';
      if (angular.isDefined(vm.userProfile) && angular.isString(vm.userProfile.website)) {
        url = vm.userProfile.website;
        if (url !== '') {
          if ((url.slice(0, 4)).toLowerCase() !== 'http') {
            url = 'http://' + vm.userProfile.website;
          }
        }
      }

      return url;
    };

    vm.deactivateAccount = function () {
      // Let's open the modal.
      $uibModal.open({
        animation: false,
        templateUrl: 'account/profile/deactivate-modal.tpl.html',
        controller: 'DeactivateModalCtrl',
        controllerAs: 'ctrl',
        size: 'md',
        resolve: {
          userProfile: vm.userProfile
        }
      });
    };

    /*
    Background Editing
    */

    vm.resetToDefault = function () {
      vm.resetRequested = true;
      vm.changingColor = false;
      vm.bkgData.backgroundFile = null;
      vm.backgroundFile = null;
    };

    vm.uploadBackgroundPhoto = function () {
      if (vm.backgroundFile) {
        vm.imageUploadMode = true;
        vm.edits.imageEdited = true;
        // We need to clear the color
        if (vm.bkgData.selectedColor === vm.defaults.selectedColor) {
          vm.edits.colorEdited = true;
        }
        vm.bkgData.selectedColor = null;
      }
      else {
        vm.imageUploadMode = false;
      }
    };

    vm.removeBkgPhoto = function () {
      if (vm.bkgData.backgroundFile === vm.defaults.backgroundFile) {
        vm.edits.imageEdited = true;
      }

      vm.backgroundFile = null;
      vm.bkgData.backgroundFile = null;
      vm.imageUploadMode = false;
    };

    vm.getProfileStyle = function () {
      var obj = _.clone(vm.backgroundStyleBkp) || {
        'background-color': 'none',
        'background-blend-mode': 'inherit'
      };

      if (vm.resetRequested) {
        return {};
      }

      if (vm.imageEditMode) {
        obj = {
          'background-color': vm.bkgData.selectedColor
        };

        if (vm.bkgData.selectedColor !== vm.defaults.selectedColor) {
          vm.edits.colorEdited = true;
        }
      }

      if (!vm.imageEditMode || !vm.edits.patternEdited && !vm.edits.colorEdited && !vm.edits.imageEdited) {
        return vm.backgroundStyleBkp;
      }

      if (vm.bkgData.keepPattern || vm.edits.colorEdited && vm.bkgData.selectedColor !== vm.defaults.selectedColor) {
        if (vm.bkgData.keepPattern) {
          obj.backgroundImage = 'url("/images/user-profile-pattern.png")';
          obj.backgroundBlendMode = 'luminosity';
          obj.backgroundPosition = 'center center';
        }
        else {
          obj.backgroundImage = 'none';
        }
      }
      else {
        obj.backgroundImage = 'none';
      }

      if (vm.bkgData.backgroundFile) {
        obj.backgroundImage = 'url("' + vm.bkgData.backgroundFile + '")';
        obj.backgroundBlendMode = 'luminosity';
        obj.backgroundPosition = 'center center';
      }

      return obj;
    };

    vm.getHeadlineStyle = function () {
      var obj = {
        color: vm.bkgData.headlineColor
      };

      if (vm.resetRequested) {
        return {};
      }

      if (vm.bkgData.headlineColor !== vm.defaults.headlineColor) {
        vm.edits.headlineEdited = true;
        return obj;
      }
      return obj;
    };

    vm.clearColourSettings = function () {
      // vm.bkgData.selectedColor = vm.defaults.selectedColor;
      // vm.bkgData.headlineColor = vm.defaults.headlineColor;
      if (vm.bkgData.selectedColor === vm.defaults.selectedColor) {
        vm.edits.colorEdited = true;
      }

      if (vm.bkgData.headlineColor === vm.defaults.headlineColor) {
        vm.edits.headlineColorEdited = true;
      }

      vm.bkgData.selectedColor = null;
      vm.bkgData.headlineColor = null;
    };

    vm.clearData = function () {
      vm.bkgData = {
        selectedColor: vm.defaults.selectedColor,
        keepPattern: vm.defaults.keepPattern,
        headlineColor: vm.defaults.headlineColor,
        backgroundFile: vm.defaults.backgroundFile
      };

      vm.edits = {
        patternEdited: false,
        colorEdited: false,
        headlineColorEdited: false,
        imageEdited: false
      };

      vm.backgroundFile = null;
      vm.imageUploadMode = false;
      vm.changingColor = false;
      vm.resetRequested = false;
    };

    vm.close = function () {
      vm.clearData();
      vm.imageEditMode = false;
      vm.imageUploadMode = false;
      vm.backgroundFile = null;
    };

    vm.hasEdits = function () {
      var hasEdits = false;
      angular.forEach(Object.keys(vm.edits), function (key) {
        if (!hasEdits) {
          hasEdits = vm.edits[key];
        }
      });
      return hasEdits;
    };

    /*
    This will revert any changes and remove any records for the user in the backend so it will display the default image only
     */
    vm.resetBackgroundData = function () {
      vm.isSavingBackgound = true;
      Restangular.one('UserConfiguration').customDELETE('cover').then(function () {
        Session.updateLocalUserProfile({
          profileConfiguration: {
            coverBackground: null
          }
        }, true);
        Notification({message: 'Success'}, 'success');
        vm.userProfile.profileConfiguration.coverBackground = null;
        // Store the backgup
        vm.backgroundStyleBkp = _.clone(vm.getCoverStyle(vm.userProfile));
        // Close and clear data.
        vm.close();
      }, function () {
        Notification({message: 'Failed to reset the profile'}, 'error');
      }).finally(function () {
        vm.isSavingBackgound = false;
      });
    };

    vm.saveBackgroundEdits = function () {
      var data;
      vm.isSavingBackgound = true;

      // Get the data
      data = {
        config: {
          keepPattern: vm.bkgData.keepPattern,
          backgroundColor: vm.bkgData.selectedColor !== configDefaults.selectedColor ? vm.bkgData.selectedColor : null,
          textColor: vm.bkgData.headlineColor !== configDefaults.headlineColor ? vm.bkgData.headlineColor : null
        }
      };

      if (vm.bkgData.backgroundFile && vm.edits.imageEdited) {
        data.image = vm.bkgData.backgroundFile;
      }
      else if (!vm.bkgData.backgroundFile && !vm.bkgData.keepPattern) {
        data.image = null;
      }
      else if (!vm.bkgData.backgroundFile && vm.edits.imageEdited) {
        data.image = null;
      }

      Restangular.one('UserConfiguration').customPUT(data, 'cover')
        .then(function (result) {
          // Update the global profile object
          Session.updateLocalUserProfile({
            profileConfiguration: {
              coverBackground: {
                imageToken: result.plain().imageToken,
                config: result.plain().config
              }
            }
          }, true);
          Notification({message: 'Success'}, 'success');
          vm.userProfile.profileConfiguration.coverBackground.imageToken = result.plain().imageToken;
          vm.userProfile.profileConfiguration.coverBackground.config = result.plain().config;
          // Store the backgup
          vm.backgroundStyleBkp = _.clone(vm.getCoverStyle(vm.userProfile));
          // Close and clear data.
          vm.close();
        }, function () {
          Notification({message: 'Failed to update the profile'}, 'error');
        }).finally(function () {
          vm.isSavingBackgound = false;
        });
    };

    vm.getCoverStyle = function (profile) {
      var coverCss = {};
      // Reset vm defaults
      vm.defaults = {
        selectedColor: null,
        headlineColor: null,
        keepPattern: null,
        backgroundFile: null
      };
      // Set the user profile style from style config
      if (profile.profileConfiguration && profile.profileConfiguration.coverBackground) {
        // Start with the pattern as that has lower priority than the background file.
        if (profile.profileConfiguration.coverBackground.config && profile.profileConfiguration.coverBackground.config.keepPattern) {
          coverCss.backgroundImage = 'url("/images/user-profile-pattern.png")';
          vm.defaults.keepPattern = true;
        }
        else {
          coverCss.backgroundImage = 'none';
          vm.defaults.keepPattern = false;
        }

        // Check if the user opted for his own background image or wants to use provided pattern or plain color. Color can be combined with both
        // so it's in separate 'if' statement.
        if (profile.profileConfiguration.coverBackground.imageToken) {
          coverCss.backgroundImage = 'url("' + configOptions.baseUrl + '/UserConfiguration/coverPicture/' + profile.profileConfiguration.coverBackground.imageToken + '")';
          coverCss.backgroundPosition = 'center top';
          coverCss.backgroundSize = '1140px 215px';
          vm.defaults.backgroundFile = configOptions.baseUrl + '/UserConfiguration/coverPicture/' + profile.profileConfiguration.coverBackground.imageToken;
        }

        if (profile.profileConfiguration.coverBackground.config) {
          if (profile.profileConfiguration.coverBackground.config.backgroundColor) {
            coverCss.backgroundColor = profile.profileConfiguration.coverBackground.config.backgroundColor;
            coverCss.backgroundBlendMode = 'luminosity';
            vm.defaults.selectedColor = profile.profileConfiguration.coverBackground.config.backgroundColor;
          }
          if (profile.profileConfiguration.coverBackground.config.textColor) {
            vm.defaults.headlineColor = profile.profileConfiguration.coverBackground.config.textColor;
          }
        }
      }
      else {
        vm.defaults = _.clone(configDefaults);
      }
      return coverCss;
    };
  }
}());
