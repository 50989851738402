(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name components.factory:DataTable
   *
   * @description
   *
   */
  angular
    .module('components')
    .factory('DataTable', DataTable);

  function DataTable($q, RecordsSet) {
    var DataTableBase = {};

    /**
     *
     * @param {string} entity Name of the entity
     * @param {string} endPoint Endpoint to be called, default index
     * @param {Object} params Additional parameters
     * @param {Object} options Additional params to configure record set
     * @constructor
     */
    function DataTableManager(entity, endPoint, params, options) {
      var self = this,
          dataLoader = RecordsSet.createLoader(entity, endPoint, params, options),
          lastTableState = {},
          loadingMoreRecords = false,
          deferredInit = $q.defer();

      self.isReady = deferredInit.promise;

      // Wait for the data loader to initialise. Then the promise will be fulfilled and the self.isReady by be set.
      dataLoader.isReady.then(function () {
        deferredInit.resolve();
      }, function () {
        deferredInit.reject();
      });

      /**
       * Loads the data
       * @param {Object} tableState Table state from the Smart Table
       */
      self.load = function (tableState) {
        // var key, direction;
        if (!self.isLoading()) {
          loadingMoreRecords = true;
          dataLoader.load()
            .then(function () {
              tableState.pagination.numberOfPages = dataLoader.numberOfSets;
              loadingMoreRecords = false;
            }, function () {
              loadingMoreRecords = false;
            });

          // if (angular.isObject(tableState.sort) && angular.isDefined(tableState.sort.predicate)) {
          //   if (angular.isUndefined(lastTableState.sort) || !angular.equals(tableState.sort, lastTableState.sort)) {
          //     key = tableState.sort.predicate;
          //     direction = tableState.sort.reverse ? 'DESC' : 'ASC';
          //     dataLoader.sort(key, direction)
          //       .then(function () {
          //         tableState.pagination.numberOfPages = dataLoader.numberOfSets;
          //       });
          //   }
          // }
          // else {
          //   dataLoader.load()
          //     .then(function () {
          //       tableState.pagination.numberOfPages = dataLoader.numberOfSets;
          //     });
          // }

          lastTableState = tableState;
        }
      };

      /**
       * Reloads the records with new parameters
       * @param {Object} reloadParams New parameters
       * @param {Boolean} initialReset If true resets also flag indicating existence of records
       *
       * @returns {Object} Result
       */
      self.reload = function (reloadParams, initialReset) {
        if (!self.isLoading()) {
          if (angular.isObject(lastTableState) && angular.isObject(lastTableState.sort) && angular.isDefined(lastTableState.sort.predicate)) {
            reloadParams.orderby = lastTableState.sort.predicate + ' ' + (lastTableState.sort.reverse ? 'DESC' : 'ASC');
          }
          return dataLoader.reload(reloadParams, initialReset)
            .then(function (result) {
              if (angular.isObject(lastTableState) && angular.isObject(lastTableState.pagination)) {
                lastTableState.pagination.numberOfPages = dataLoader.numberOfSets;

                return $q.when(result);
              }
            });
        }

        return $q.when(false);
      };

      /**
       * Returns the records
       * @returns {Array} Records
       */
      self.getRecords = function () {
        return dataLoader.records;
      };

      /**
       * Checks if the data table is loading the data
       * @returns {boolean} Flag if records are loading
       */
      self.isLoading = function () {
        return dataLoader.isLoading;
      };

      /**
       * Checks if loading more records - pagination
       *
       * @returns {boolean} Flag if loading more records
       */
      self.isLoadingMoreRecords = function () {
        return loadingMoreRecords;
      };

      /**
       * Checks if the data table is initialised
       * @returns {boolean} Flag if records are initialised
       */
      self.isInitialised = function () {
        return dataLoader.isInitialised;
      };

      /**
       * Checks if records exist
       *
       * @returns {boolean} Flag if records exist
       */
      self.recordsExist = function () {
        return dataLoader.recordsExist;
      };

      /**
       * Returns number of total records
       * @returns {integer} Number of total records
       */
      self.totalRecords = function () {
        return dataLoader.totalRecords;
      };
    }

    /**
     * Returns instance of data table manager
     *
     * @param {string} entity Name of the entity
     * @param {string} endPoint Endpoint to be called, default index
     * @param {Object} params Additional parameters
     * @param {Object} options Additional params to configure record set
     * @returns {DataTableManager} Instance of data table manager
     */
    DataTableBase.create = function (entity, endPoint, params, options) {
      return new DataTableManager(entity, endPoint, params, options);
    };

    return DataTableBase;
  }
}());
