(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name mlp-search.directive:searchBar
   * @restrict EA
   * @element
   *
   * @description This is the directory search bar that searches across the user's content
   *
   */
  angular
    .module('mlp-search')
    .directive('searchBar', searchBar);

  function searchBar() {
    return {
      restrict: 'E',
      scope: true,
      templateUrl: 'search/search-bar-directive.tpl.html',
      replace: true,
      controllerAs: 'searchbar',
      controller: ['$scope', '$document', '$state', 'Session', 'Leads', function ($scope, $document, $state, Session, Leads) {
        var vm = this,
            userProfileDeferred = Session.getUserProfile();

        function handleLeads(item) {
          Leads.openDetail(item.id, item.leadtype);
        }

        //
        vm.searchCategories = [
          {name: 'All', value: 'all'},
          {name: 'My Pod', value: 'myPod', stateTarget: 'user', iconClass: 'fa-user'},
          {name: 'Directory', value: 'businessUsers', stateTarget: 'user.about', iconClass: 'fa-briefcase'},
          {name: 'Leads', value: 'leads', callbackHandler: handleLeads, iconClass: 'fa-paper-plane'},
          {name: 'Charities', value: 'charities', stateTarget: 'user', iconClass: 'fa-heart-o'}
        ];

        // Get the user data from session.
        function updateUserData() {
          userProfileDeferred.then(function (profile) {
            if (profile) {
              vm.userProfile = profile;
              if (vm.userProfile.isBusinessAccountActive) {
                vm.searchCategories.push({name: 'My Connections', value: 'myConnections', stateTarget: 'user', iconClass: 'fa-user-o'});
              }
            }
          });
        }
        updateUserData();

        // For the autocomplete (angucomplete)
        vm.searchbarId = 'directory-searchbar';
        // vm.searchUrl = 'https://admin-pilot.myleadpod.com/dataapi/myleadpod/v1/Members/index?limit=10&offset=0&status=active&search=';
        vm.searchUrl = 'https://admin-pilot.myleadpod.com/dataapi/myleadpod/v1/Search/index?keyword=';
        vm.searchService = 'Search';
        vm.searchServiceEndPoint = 'index';

        vm.onSearchItemSelected = function (item) {
          var stateTarget,
              callbackHandler;

          // Callback on when the user selected the search item.
          // Pull the stateTarget from category
          if (!item) {
            return;
          }

          stateTarget = _.find(vm.searchCategories, {value: item.originalObject.category}).stateTarget;
          callbackHandler = _.find(vm.searchCategories, {value: item.originalObject.category}).callbackHandler;
          if (stateTarget && item.originalObject.id) {
            $state.go(stateTarget, {id: item.originalObject.id});
          }
          else if (callbackHandler) {
            callbackHandler(item.originalObject);
          }
        };

        /**
         * Format the reponse coming from the server, channeled via search-autocomplete-directive
         *
         * @param {object} response Raw response object coming from Search REST Endpoint
         *
         * @returns {object} Formatted result item
         */
        vm.searchResponseFormatter = function (response) {
          var r,
              records;

          if (angular.isUndefined(response) || !response) {
            return null;
          }

          r = response.records;
          records = _.map(r, function (item) {
            var category = _.find(vm.searchCategories, {value: item.datasource}),
                returnObject = {},
                categoryDescriptionClass,
                value = item.fullname;

            categoryDescriptionClass = category.iconClass || 'fa-user';
            if (item.leadtype) {
              categoryDescriptionClass += item.leadtype === 'received' ? ' fa-paper-plane-o' : '';
              value += ' | ' + item.leadfullname;
            }
            else if (item.companyname && item.companyname !== item.fullname) {
              value += ' | ' + item.companyname;
            }

            returnObject = {
              value: value,
              id: item.id,
              category: item.datasource,
              categoryDescription: '<i class="fa ' + categoryDescriptionClass + '"></i>',
              timeDescription: item.datecreatedformatted ? item.datecreatedformatted : '',
              leadtype: item.leadtype
            };

            return returnObject;
          });

          return records;
        };

        vm.focusIn = function () {
          $document.find('.navbar-header').addClass('search-focused');
        };
        vm.focusOut = function () {
          $document.find('.navbar-header').removeClass('search-focused');
        };
      }],

      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
