(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name dashboard.stats.controller:StatsCtrl
   *
   * @description
   *
   */
  angular
    .module('dashboard.stats')
    .controller('StatsCtrl', StatsCtrl);

  function StatsCtrl($scope, $filter, Charities) {
    var vm = this;

    vm.membersCount = $scope.dashboard.membersCount;
    vm.membershipsCount = $scope.dashboard.membershipsCount;
    vm.sentLeadsCount = $scope.dashboard.sentLeadsCount;
    vm.donations = $filter('currency')($scope.dashboard.donations, '$', 0);
    vm.earnings = $filter('currency')($scope.dashboard.earnings.lastMonthEarnings, '$', 0);
    vm.balance = $filter('currency')($scope.dashboard.earnings.actualEarnings, '$', 0);
    vm.isBusinessAccountActive = $scope.dashboard.isBusinessAccountActive;

    vm.donationsValue = $scope.dashboard.donations;
    vm.earningsValue = $scope.dashboard.earnings.lastMonthEarnings;

    vm.getLatestPods = function () {
      return [];
    };

    vm.getMembers = function () {
      return [];
    };

    vm.getEarnings = function () {
      return 0;
    };

    vm.getBalance = function () {
      return 0;
    };

    vm.getDonations = function () {
      return 0;
    };

    vm.openDonate = function () {
      Charities.openDonate();
    };
  }
}());
