(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name user.leads.controller:UserReceivedLeadsCtrl
   *
   * @description
   *
   */
  angular
    .module('user.messages')
    .controller('MessagesCtrl', MessagesCtrl);

  function MessagesCtrl($rootScope, $scope, $document, $window, $q, $timeout, $uibModal, Restangular, Leads, smoothScroll) {
    var vm = this,
        promises = [];

    vm.isLoadingLeads = false;
    vm.isLoadingMsg = false;
    vm.userProfile = $scope.user.userProfile;
    vm.openInfo = false;
    vm.currentLead = null;
    vm.modalInstance = null;
    vm.targetMsgContainer = 'direct-messages-text-input';

    vm.loadLeads = function () {
      // Get the leads
      vm.isLoadingLeads = true;

      promises.push(Restangular.one('Leads').getList('index', {
        type: 'sent',
        idUser: vm.userProfile.id,
        status: 'open',
        limit: -1
      }));

      promises.push(Restangular.one('Leads').getList('index', {
        type: 'received',
        idUser: vm.userProfile.id,
        status: 'open',
        limit: -1
      }));

      $q.all(promises).then(function (resultsArray) {
        vm.sentLeads = resultsArray[0].plain();
        _.each(vm.sentLeads, function (lead) {
          lead.leadType = 'sent';
        });

        vm.receivedLeads = resultsArray[1].plain();
        _.each(vm.receivedLeads, function (lead) {
          lead.leadType = 'received';
        });

        vm.leads = _.union(vm.sentLeads, vm.receivedLeads);
        vm.leads = _.sortBy(vm.leads, 'dateSent').reverse();

        vm.isLoadingLeads = false;
      });
    };

    vm.openDetails = function (lead, type) {
      if (!lead && !type) {
        lead = vm.currentLead;
      }
      Leads.openDetail(lead, lead.leadType, null);
    };

    vm.navigateTo = function (hash, options, lead) {
      if (vm.isLoadingMsg || vm.currentLead === lead) {
        // Come back later. Otherwise the messages directive will load the latest incoming request which might be not right.
        return;
      }
      vm.isLoadingMsg = true;
      vm.currentLead = lead;
      vm.openInfo = false;
      if (angular.isDefined(vm.stepTo)) {
        vm.stepTo({data: 1});
      }
      if (vm.modalInstance) {
        vm.modalInstance.close();
        vm.modalInstance = null;
      }
      vm.targetMsgContainer = 'lead-messages-text-input';
    };

    vm.switchToDirect = function () {
      vm.currentLead = null;
      vm.openInfo = false;
      if (angular.isDefined(vm.stepTo)) {
        vm.stepTo({data: 0});
      }
      if (vm.modalInstance) {
        vm.modalInstance.close();
        vm.modalInstance = null;
      }
      vm.targetMsgContainer = 'direct-messages-text-input';
    };

    vm.openModal = function () {
      vm.modalInstance = $uibModal.open({
        animation: true,
        appendTo: angular.element($document[0].body),
        templateUrl: 'user/messages/messages-list-modal.tpl.html',
        scope: $scope,
        size: 'lg',
        windowClass: 'from-center'
      });
    };

    vm.scrollTo = function (elementId) {
      var el = $document[0].getElementById(elementId);
      if (el) {
        smoothScroll(el, {
          callbackAfter: function () {
            if (el && el.focus) {
              el.focus();
            }
          }
        });
      }
    };

    vm.onMessagesLoad = function () {
      promises = [];
      vm.isLoadingMsg = false;
    };

    vm.onDirectMessagesLoad = function () {
      if (!vm.checkedScroll) {
        $rootScope.$broadcast('event:check-scroll-navigator', 'all');
        vm.checkedScroll = true;
      }
    };

    // Load leads
    vm.loadLeads();

    // Subscribe on events
    vm.watchHandle1 = $rootScope.$on('event:lead-sent', function () {
      vm.loadLeads();
    });
    vm.watchHandle2 = $rootScope.$on('event:lead-closed', function () {
      vm.loadLeads();
    });
    $scope.$on('$destroy', function () {
      vm.watchHandle1();
      vm.watchHandle2();
    });

    // Unfortunately we have to use the timeout here
    $timeout(function () {
      vm.stepTo = $scope.stepTo;
    }, 100);
  }
}());
