(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name components.ui.service:PromptDialog
   *
   * @description
   *
   */
  angular
    .module('components.ui')
    .service('PromptDialog', PromptDialog);

  function PromptDialog($document, $uibModal) {
    var self = this;

    /**
     * Opens up a modal dialog
     * @param {string} content Content message
     * @param {string} onConfirmMessage Confirmation message
     * @param {string} confirmButtonLabel Label to display on confirm button
     * @param {string} cancelButtonLabel Label to display on cancel button
     * @returns {*} Modal instance
     */
    self.open = function (content, onConfirmMessage, confirmButtonLabel, cancelButtonLabel) {
      var instance;

      instance = $uibModal.open({
        animation: false,
        appendTo: angular.element($document[0].body),
        templateUrl: 'components/ui/prompt-dialog.tpl.html',
        controller: 'PromptDialogCtrl',
        controllerAs: 'promptDialog',
        size: 'md',
        resolve: {
          content: function () {
            return content;
          },
          onConfirmMessage: function () {
            return onConfirmMessage;
          },
          cancelButtonLabel: function () {
            return cancelButtonLabel;
          },
          confirmButtonLabel: function () {
            return confirmButtonLabel;
          }
        }
      });

      return instance.result;
    };
  }
}());
