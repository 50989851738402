(function () {
  'use strict';

  /* @ngdoc object
   * @name myLeadPod
   * @description
   *
   */
  angular
    .module('myLeadPod', [
      'ngAria',
      'ngAnimate',
      'ngTouch',
      'angular-loading-bar',
      'ngSanitize',
      'ui.router',
      'ui.bootstrap',
      'restangular',
      'ngRaven',
      'monospaced.elastic',
      'smart-table',
      'ui-notification',
      'credit-cards',
      'smoothScroll',
      'angulartics',
      'angulartics.google.analytics',
      'angucomplete-alt',
      'configuration',
      'components',
      'api',
      'cache',
      'account',
      'socialNetwork',
      'dashboard',
      'myPod',
      'myMemberships',
      'toolbar',
      'user',
      'lead',
      'urlShorteners',
      'socialShare',
      'mlp-animations',
      'mlp-search',
      'mlp-messaging',
      'mlpMarketplace',
      'mlp-charity',
      'mlp-notifications'
    ]);

  angular
    .module('myLeadPod')
    .config(config)
    .run(run);

  function config($locationProvider, $urlRouterProvider, RestangularProvider, NotificationProvider,
                  $compileProvider, $analyticsProvider, PreloadedUserProfile, GoogleMapProvider, configOptions,
                  BitlyUrlShortenerProvider, SocialShareProvider, cfpLoadingBarProvider, OneSignalProvider) {
    $locationProvider.html5Mode(!'%IS_DEVELOPMENT%');
    $compileProvider.debugInfoEnabled('%IS_DEVELOPMENT%');
    cfpLoadingBarProvider.includeSpinner = false;
    RestangularProvider.setBaseUrl(configOptions.baseUrl);
    RestangularProvider.addResponseInterceptor(function (data, operation) {
      var extractedData;
      if (operation === 'getList') {
        extractedData = data.content.records;
        extractedData.meta = data.content.recordsInfo;
      }
      else {
        extractedData = angular.extend({}, data.content);
      }
      extractedData.contentEtag = data.contentEtag;
      extractedData.responseStatus = {
        code: data.statusCode,
        text: data.statusText
      };

      return extractedData;
    });
    RestangularProvider.setDefaultHeaders({Accept: 'application/json'});
    GoogleMapProvider.init(configOptions.googleMapApiKey, configOptions.googleMapLibraries);
    OneSignalProvider.init(configOptions.oneSignalAppId, configOptions.oneSignalSafariWebId);
    BitlyUrlShortenerProvider.setAccessToken(configOptions.bitlyGenericAccessToken);
    SocialShareProvider.set('facebook', 'appId', configOptions.facebookAppId);
    NotificationProvider.setOptions({
      delay: 5000,
      startTop: 70,
      startRight: 10,
      verticalSpacing: 20,
      horizontalSpacing: 20,
      maxCount: 1
    });

    $analyticsProvider.firstPageview(true);
    $analyticsProvider.withAutoBase(true);

    if (angular.isObject(PreloadedUserProfile) && !!PreloadedUserProfile.idUser) {
      $urlRouterProvider.otherwise('/');
    }
    else {
      $urlRouterProvider.otherwise('/account/signin');
    }
  }

  function run($rootScope, $state, $timeout, $window, $document, Restangular, Auth, Session, QueueManager, PreloadedUserProfile, Industries, Location, RewardCodes, OneSignal, $anchorScroll) {
    /* jshint unused:false */
    /* eslint no-unused-vars: [2, {"args": "none", "varsIgnorePattern": "Handler" }]*/
    var stateChangeStartHandler,
        loginRequiredHandler,
        loginSuccessHandler,
        logoutSuccessHandler,
        stateChangeSuccessHandler,
        stateChangeTimeout,
        stateChangeErrorHandler,
        uiView;

    // Force the address bar to close when the app is run first time
    $window.scrollTo(0, 1);

    Session.create(PreloadedUserProfile);
    $rootScope.isAuthenticated = Session.isValidProfile(PreloadedUserProfile);
    if ($rootScope.isAuthenticated) {
      OneSignal.getUserId().then(function (playerId) {
        Restangular.one('Users').customPOST(null, 'pushNotifications', {
          oneSignalPlayerId: playerId
        });
      });
    }

    /**
     * Bind state change start event
     */
    stateChangeStartHandler = $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
      if (!(angular.isDefined(fromParams.logout) && fromParams.logout === true)) {
        Auth.isAuthenticated().then(function (result) {
          // Close any modal instances that might be there.
          $rootScope.$broadcast('close-modal-instance', {});

          if (!result) {
            if (toState.authenticate) {
              event.preventDefault();
              QueueManager.reset();
              $state.go('accountSignInUp.accountSignIn', {
                nextState: {
                  name: toState.name,
                  params: toParams
                }
              });
            }
          }
          else if (toState.name === 'accountSignInUp.accountSignIn' || toState.name === 'accountSignInUp.accountSignUp') {
            event.preventDefault();
            $state.go('dashboard');
          }
          else {
            // Now try to apply the loading class but do it in the accepteble timeout so we don't show the pending
            stateChangeTimeout = $timeout(function () {
              uiView = uiView || $document.find('#app-container-data[ui-view]').parent();
              uiView.addClass('state-changing');
              $rootScope.$broadcast('event:app-state-changing');
            }, 300);
          }
        }, function () {
          if (toState.authenticate) {
            event.preventDefault();
            uiView = uiView || $document.find('#app-container-data[ui-view]').parent();
            if (uiView) {
              uiView.removeClass('state-changing');
              $rootScope.$broadcast('event:app-state-changed');
            }
            $state.transitionTo('accountSignInUp.accountSignIn', {}, {
              notify: false
            });
          }
        });
      }
    });

    /**
     * Bind state change success
     */
    stateChangeSuccessHandler = $rootScope.$on('$stateChangeSuccess', function () {
      // Cancel the state changing class
      if (stateChangeTimeout) {
        $timeout.cancel(stateChangeTimeout);
      }

      uiView = uiView || $document.find('#app-container-data[ui-view]').parent();
      uiView.removeClass('state-changing');
      $rootScope.$broadcast('event:app-state-changed');

      if (QueueManager.queue.length > 0) {
        QueueManager.start();
      }
      $anchorScroll();
    });

    /**
     * State change error handler.
     */
    stateChangeErrorHandler = $rootScope.$on('$stateChangeError', function (toState, toParams, fromState, fromParams, error) {
      uiView = uiView || $document.find('#app-container-data[ui-view]').parent();
      if (uiView) {
        uiView.removeClass('state-changing');
        $rootScope.$broadcast('event:app-state-changed');
      }
    });

    /**
     * Bind sign in page to event
     */
    loginRequiredHandler = $rootScope.$on('event:auth-loginRequired', function () {
      $rootScope.isAuthenticated = false;
      Auth.signOut(false);
      $state.go('accountSignInUp.accountSignIn');
      uiView = uiView || $document.find('#app-container-data[ui-view]').parent();
      if (uiView) {
        uiView.removeClass('state-changing');
        $rootScope.$broadcast('event:app-state-changed');
      }
    });

    /**
     * Bind login confirmation event
     */
    loginSuccessHandler = $rootScope.$on('event:auth-loginConfirmed', function () {
      $rootScope.isAuthenticated = true;
      Industries.reload();
    });

    /**
     * Bind logout event
     */
    logoutSuccessHandler = $rootScope.$on('event:auth-logoutSuccess', function () {
      $rootScope.isAuthenticated = false;
    });
  }
}());
