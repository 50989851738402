<div class="col-xs-12" ng-controller="ReceivedLeadsCtrl as receivedLeads" id="received-leads-ctrl-container">
  <div class="page-content">
    <div class="row" ng-hide="receivedLeads.list.recordsExist() || !receivedLeads.list.isInitialised()">
      <div class="col-xs-12">
        <div class="page-empty-section text-center">
          <i class="fa fa-paper-plane fa-2x text-light-colour"></i>
          <div class="page-empty-section-text">
            You haven't received any leads
          </div>
        </div>
      </div>
    </div>

    <div class="row" ng-show="receivedLeads.list.recordsExist() && receivedLeads.list.isInitialised()">
      <div class="col-sm-5 col-md-4">
        <div class="form-group page-filter">
          <label class="sr-only" for="searchLead">Search lead</label>
          <div class="left-inner-addon">
            <i class="fa fa-search inner-addon"></i>
            <form name="searchLeadForm" ng-submit="receivedLeads.search()">
              <input class="form-control input-lg" type="search" name="searchLead" id="searchLead"
                     placeholder="Search lead" ng-model="receivedLeads.searchLead" ng-blur="receivedLeads.search();"/>
            </form>
          </div>
        </div>
      </div>
      <div class="col-sm-4 col-md-3">
        <div class="form-group btn-group page-filter dropdown-large" uib-dropdown>
          <button id="lead-status" type="button" class="btn" uib-dropdown-toggle>
            {{receivedLeads.selectedStatusLabel}} <span class="caret"></span>
          </button>
          <ul class="dropdown-menu" uib-dropdown-menu role="menu" aria-labelledby="lead-status">
            <li role="menuitem" ng-repeat="(value, label) in receivedLeads.statuses"><a ng-click="receivedLeads.selectStatus(value)">{{label}}</a></li>
          </ul>
        </div>
      </div>
    </div>

    <div class="table-responsive table-container wrap-allowed" ng-show="receivedLeads.list.recordsExist() && receivedLeads.list.isInitialised()">
      <table st-table="receivedLeads.list.getRecords()" st-pagination-scroll st-pipe="receivedLeads.list.load" class="table page-content-table allow-wrap">
        <thead class="hidden-xs">
        <tr>
          <th class="col-sm-3">Received from</th>
          <th class="col-sm-2">Date received</th>
          <th class="col-sm-3">Name</th>
          <th class="col-sm-2">Status</th>
          <th class="col-sm-2 text-right">Details</th>
        </tr>
        </thead>
        <tbody ng-hide="receivedLeads.list.isLoading() && !receivedLeads.list.isLoadingMoreRecords()">
        <tr ng-repeat="row in receivedLeads.list.getRecords()">
          <td class="col-xs-12 col-sm-3">
            <table class="table-cell-main-content">
              <tr class="row-center-items row-space-between">
                <td class="visible-xs" single-expand sub-content="receivedLeads.tableSubContentVisible" sub-content-idx="$index"></td>
                <td ng-click="$event.stopPropagation()">
                  <table-user-details user="row.sender"></table-user-details>
                </td>
                <td class="visible-xs text-right" style="white-space: nowrap !important;">
                  <span class="lead-status-tag description-tag" ng-class="row.status"></span>
                  <span class="cursor-pointer">
                    <i class="fa fa-ellipsis-h"></i>
                  </span>
                </td>
              </tr>
            </table>
            <div class="table-cell-sub-content-container slide-if" ng-if="receivedLeads.tableSubContentVisible[$index]">
              <div class="table-cell-sub-content visible-xs">
                <span class="text-muted">Date received</span><br/>
                <span class="text-normal">{{row.dateSent | date:'d MMM yyyy'}}</span>
              </div>
              <div class="table-cell-sub-content visible-xs">
                <span class="text-muted">Name</span><br/>
                <span class="text-normal">{{row.firstName}} {{row.lastName}}</span>
              </div>
              <div class="table-cell-sub-content visible-xs">
                <span class="text-muted">Status</span><br/>
                <span class="text-normal" ng-if="(row.status !== 'open' && row.status !== 'new') || row.isCoOwner">
                  {{receivedLeads.statuses[row.status]}}
                </span>
                <div ng-if="(row.status === 'open' || row.status === 'new') && !row.isCoOwner" class="btn-group page-filter dropdown-large" uib-dropdown>
                  <button id="lead-status-{{$index}}" type="button" class="btn" uib-dropdown-toggle>
                    {{receivedLeads.statuses[row.status]}} <span class="caret"></span>
                  </button>
                  <ul class="dropdown-menu" uib-dropdown-menu role="menu" aria-labelledby="lead-status-{{$index}}">
                    <li role="menuitem"><a>{{row.status === 'open' ? 'Open' : 'New'}}</a></li>
                    <li role="menuitem"><a ng-click="receivedLeads.closeLead($index, 'successful')">Successful</a></li>
                    <li role="menuitem"><a ng-click="receivedLeads.closeLead($index, 'unsuccessful')">Unsuccessful</a></li>
                  </ul>
                </div>
              </div>
              <div class="table-cell-sub-content visible-xs">
                <span class="text-muted">Details</span><br/>
                <span class="text-normal"><a class="cursor-pointer" ng-click="receivedLeads.openDetail($index)">View lead</a></span>
              </div>
            </div>
          </td>
          <td class="col-sm-2 hidden-xs">{{row.dateSent | date:'d MMM yyyy'}}</td>
          <td class="col-sm-3 hidden-xs">{{row.firstName}} {{row.lastName}}</td>
          <td class="col-sm-2 hidden-xs prevent-wrap" style="min-width:130px;">
            <span ng-if="(row.status !== 'open' && row.status !== 'new') || row.isCoOwner">
              {{receivedLeads.statuses[row.status]}}<span ng-if="row.isCoOwner" class="text-muted"> (shared)</span>
            </span>
            <div ng-if="(row.status === 'open' || row.status === 'new') && !row.isCoOwner" class="btn-group page-filter" uib-dropdown dropdown-append-to-body="true">
              <button id="lead-status-{{$index}}" type="button" class="btn" uib-dropdown-toggle>
                {{receivedLeads.statuses[row.status]}} <span class="caret"></span>
              </button>
              <ul class="dropdown-menu" uib-dropdown-menu role="menu" aria-labelledby="lead-status-{{$index}}">
                <li role="menuitem"><a>{{row.status === 'open' ? 'Open' : 'New'}}</a></li>
                <li role="menuitem"><a ng-click="receivedLeads.closeLead($index, 'successful')">Successful</a></li>
                <li role="menuitem"><a ng-click="receivedLeads.closeLead($index, 'unsuccessful')">Unsuccessful</a></li>
              </ul>
            </div>
          </td>
          <td class="col-sm-2 hidden-xs text-right prevent-wrap"><a class="cursor-pointer" ng-click="receivedLeads.openDetail($index)">View lead</a></td>
        </tr>
        </tbody>

        <tbody ng-if="receivedLeads.list.isLoading()">
        <tr>
          <td colspan="5" class="col-xs-12 text-center">
            <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="row" ng-show="!receivedLeads.list.isInitialised()">
      <div class="col-xs-12 text-center">
        <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>
      </div>
    </div>
  </div>
</div>

