(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name user.agreements.controller:AgreementsCtrl
   *
   * @description
   *
   */
  angular
    .module('user.agreements')
    .controller('AgreementsCtrl', AgreementsCtrl);

  function AgreementsCtrl($rootScope, $scope, $timeout, $state, Restangular, $uibModal,
                          Notification, MyMemberships, MyPod, Leads, $document, BusinessAccount) {
    var vm = this,
        body;

    vm.userProfile = $scope.user.userProfile;
    vm.myProfile = $scope.user.myProfile;
    vm.receiveLeads = $scope.user.agreementsDetails.receiveLeads;
    vm.sendLeads = $scope.user.agreementsDetails.sendLeads;
    vm.isLoading = false;
    vm.trialData = null;

    // Watch the sendLeads property of the scope's parent 'user' property (in user controller) as the change in the user controller sendLeads is not propagated to the child controller.
    $scope.$watch('user.agreementsDetails.sendLeads', function (newSendLeads) {
      vm.sendLeads = newSendLeads;
    });

    // Watch the property
    $scope.$watch('user.agreementsDetails.receiveLeads', function (receiveLeads) {
      vm.receiveLeads = receiveLeads;
    });

    vm.data = {
      receiveLeads: {},
      sendLeads: {}
    };

    function getScrollingElement() {
      var d = $document[0];
      return d.documentElement.scrollHeight > d.body.scrollHeight && d.compatMode.indexOf('CSS1') === 0 ? d.documentElement : d.body;
    }

    function reviseAgreement(data) {
      data.idUser = vm.userProfile.id;
      vm.isLoading = true;
      Restangular.one('Contracts').customPUT(data, 'revise')
        .then(function (agreements) {
          vm.receiveLeads = agreements.receiveLeads;
          vm.sendLeads = agreements.sendLeads;
          $scope.user.agreementsDetails.receiveLeads = agreements.receiveLeads;
          $scope.user.agreementsDetails.sendLeads = agreements.sendLeads;
          $rootScope.$broadcast('event:user-agreements-updated');
          vm.isLoading = false;
          vm.data = {
            receiveLeads: {},
            sendLeads: {}
          };
          Notification({message: 'Success'}, 'confirmation');
        }, function () {
          vm.isLoading = false;
        });
    }

    function accept(contractPurpose) {
      var data = {
        idUser: vm.userProfile.id,
        contractPurpose: contractPurpose
      };
      vm.isLoading = true;
      Restangular.one('Contracts').customPUT(data, 'accept')
        .then(function (agreements) {
          vm.receiveLeads = agreements.receiveLeads;
          vm.sendLeads = agreements.sendLeads;
          $scope.user.agreementsDetails.receiveLeads = agreements.receiveLeads;
          $scope.user.agreementsDetails.sendLeads = agreements.sendLeads;
          $rootScope.$broadcast('event:user-agreements-updated');
          vm.isLoading = false;
          vm.data = {
            receiveLeads: {},
            sendLeads: {}
          };

          Notification({message: 'Success'}, 'confirmation');

          if (contractPurpose === 'receiveLeads') {
            $rootScope.$broadcast('event:receive-leads-contract-accepted', {
              idUser: vm.userProfile.id
            });
          }

          if (angular.isDefined(agreements.idLead) && angular.isNumber(agreements.idLead)) {
            $state.go('leadDetail', {id: agreements.idLead});
          }
        }, function () {
          vm.isLoading = false;
        });
    }

    function acceptAgreement(contractPurpose) {
      var modalInstance;
      if (contractPurpose === 'receiveLeads' && vm.receiveLeads.contractType === 'Subscription' && !vm.myProfile.isCCSet) {
        modalInstance = $uibModal.open({
          animation: false,
          templateUrl: 'account/billing/credit-card.tpl.html',
          controller: 'CreditCardCtrl',
          controllerAs: 'creditCard',
          size: 'lg',
          windowClass: 'modal-credit-card',
          resolve: {
            operation: function () {
              return 'add';
            }
          }
        });

        modalInstance.result.then(function (result) {
          if (angular.isObject(result) && angular.isObject(result.creditCard) && angular.isDefined(result.creditCard.number)) {
            accept(contractPurpose);
          }
        });
      }
      else {
        accept(contractPurpose);
      }
    }

    function scrollTo(btnId) {
      var btn;
      body = body || $document[0].scrollingElement || getScrollingElement();

      if (!btnId) {
        return;
      }

      btn = angular.element('#' + btnId);
      if (btn && btn.offset().top > $document[0].documentElement.clientHeight + body.scrollTop) {
        angular.element(body).animate({
          scrollTop: body.scrollTop + btn.offset().top + btn.outerHeight() + 10 - $document[0].documentElement.clientHeight
        }, 200);
      }
    }

    vm.reviseReceiveLeads = function () {
      var data = angular.extend({}, vm.data.receiveLeads, {
        contractPurpose: 'receiveLeads'
      });
      reviseAgreement(data);
    };

    vm.reviseSendLeads = function () {
      var data = angular.extend({}, vm.data.sendLeads, {
        contractPurpose: 'sendLeads'
      });
      reviseAgreement(data);
    };

    vm.acceptReceiveLeads = function () {
      acceptAgreement('receiveLeads');
    };

    vm.acceptSendLeads = function () {
      acceptAgreement('sendLeads');
    };

    vm.isBusinessAccountActive = function () {
      return angular.isDefined(vm.myProfile.isBusinessAccountActive) && vm.myProfile.isBusinessAccountActive === true;
    };

    vm.cancelReceiveLeadsRevision = function () {
      vm.receiveLeads.revise = false;
      vm.data.receiveLeads = {};
    };

    vm.cancelSendLeadsRevision = function () {
      vm.sendLeads.revise = false;
      vm.data.sendLeads = {};
    };

    vm.switchReviseSendLeadsContent = function () {
      vm.sendLeads.revise = !vm.sendLeads.revise;
      if (vm.sendLeads.revise) {
        $timeout(function () {
          scrollTo('reviseSendLeadsBtn');
        }, 200);
      }
    };

    vm.switchReviseReceiveLeadsContent = function () {
      vm.receiveLeads.revise = !vm.receiveLeads.revise;
      if (vm.receiveLeads.revise) {
        $timeout(function () {
          scrollTo('reviseReceiveLeadsBtn');
        }, 200);
      }
    };

    vm.openAskForLeads = function () {
      var modalInstance = MyMemberships.openAskForLeads(vm.myProfile, vm.userProfile);
      modalInstance.then(function (receiveLeads) {
        $scope.user.agreementsDetails.receiveLeads = receiveLeads;
        vm.receiveLeads = receiveLeads;
      });
    };

    vm.openSendInvite = function () {
      var modalInstance = MyPod.openSendInvite(vm.myProfile, vm.userProfile);
      modalInstance.then(function (sendLeads) {
        vm.sendLeads = sendLeads;
      });
    };

    vm.onRewardSelectionChangeReceiveLeads = function (data, isFormValid) {
      _.extend(vm.data.receiveLeads, data);
      vm.isReceiveLeadsRewardsFormValid = isFormValid;
    };

    vm.onRewardSelectionChangeSendLeads = function (data, isFormValid) {
      _.extend(vm.data.sendLeads, data);
      vm.isSendLeadsRewardsFormValid = isFormValid;
    };

    // Will directly join the other user's pod (using the default rewards program)
    vm.quickJoin = function () {
      // Let's start pending...
      vm.invitePending = true;

      MyPod.quickJoin(vm.userProfile.idUser).then(function (response) {
        vm.sendLeads = response.sendLeads;
        $scope.user.agreementsDetails.sendLeads = response.sendLeads;
        $rootScope.$broadcast('event:user-agreements-updated');
        vm.invitePending = false;
        Notification({message: 'Success'}, 'confirmation');
      }, function (err) {
        vm.invitePending = false;
        Notification({message: 'Joining the Rewards Program was unsuccessful.' + (err.data && err.data.errorMessage ? '<br/>' + err.data.errorMessage : '')}, 'error');
        throw new Error(err);
      });
    };

    vm.activateBusinessAccount = function () {
      BusinessAccount.initiateActivation($document[0].body, vm.trialData.isEligibleForTrial);
    };

    // Send Lead
    vm.sendLead = function () {
      Leads.openSendLead(vm.userProfile, false);
    };

    //
    if (!vm.isBusinessAccountActive()) {
      vm.trialData = BusinessAccount.getTrialData(vm.myProfile);
    }
  }
}());
