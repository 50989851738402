(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.directive:statBlock
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="components">
       <file name="index.html">
        <stat-block></stat-block>
       </file>
     </example>
   *
   */
  angular
    .module('components')
    .directive('statBlock', statBlock);

  function statBlock() {
    return {
      restrict: 'EA',
      scope: {
        stat: '@',
        subtext: '@?',
        buttonText: '@?',
        buttonLink: '@?'
      },
      templateUrl: 'components/stat-block-directive.tpl.html',
      replace: false,
      controllerAs: 'statBlock',
      controller: function () {
        var vm = this;
        vm.stat = 0;
        vm.subtext = '-';
        vm.buttonText = 'View All';
        vm.buttonLink = '#';
      },
      link: function (scope, element, attrs, ctrl) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
        ctrl.stat = attrs.stat;
        ctrl.subtext = attrs.subtext;
        ctrl.buttonText = attrs.buttonText;
        ctrl.buttonLink = attrs.buttonLink;
      }
    };
  }
}());
