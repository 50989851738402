(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name user.agreements.directive:agreementPricingDetail
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="user.agreements">
       <file name="index.html">
        <agreement-pricing-detail></agreement-pricing-detail>
       </file>
     </example>
   *
   */
  angular
    .module('user.agreements')
    .directive('agreementPricingDetail', agreementPricingDetail);

  function agreementPricingDetail() {
    return {
      restrict: 'EA',
      scope: {
        agreement: '=',
        label: '='
      },
      templateUrl: 'user/agreements/agreement-pricing-detail-directive.tpl.html',
      replace: false,
      controllerAs: 'agreementPricingDetail',
      controller: function ($scope) {
        var vm = this;
        vm.agreement = $scope.agreement;
        vm.label = $scope.label;
      }
    };
  }
}());
