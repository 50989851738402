(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.directive:fileDownload
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="components">
       <file name="index.html">
        <file-download></file-download>
       </file>
     </example>
   *
   */
  angular
    .module('components')
    .directive('fileDownload', fileDownload);

  function fileDownload($window, $compile, Auth) {
    return {
      restrict: 'EA',
      scope: {
        url: '=',
        label: '='
      },
      replace: true,
      link: function (scope, element, attrs) {
        var template = '<a ng-click="download()" class="{{class}}" title="{{title}}">{{label}}</a>',
            e;
        scope.class = attrs.class;
        scope.title = attrs.title || 'Click to download';
        scope.download = function () {
          $window.open(Auth.fixApiEndpointUrl(scope.url));
        };

        e = $compile(template)(scope);
        element.replaceWith(e);
      }
    };
  }
}());
