(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name dashboard.memberships.controller:DashboardMembershipsCtrl
   *
   * @description
   *
   */
  angular
    .module('dashboard.memberships')
    .controller('DashboardMembershipsCtrl', DashboardMembershipsCtrl);

  function DashboardMembershipsCtrl($scope) {
    var vm = this;
    vm.dailyStats = $scope.dashboard.leadsDailyStats;
    vm.list = $scope.dashboard.receivedLeads;
  }
}());
