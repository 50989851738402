(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name charity.controller:DonateCtrl
   *
   * @description
   *
   */
  angular
    .module('mlp-charity')
    .controller('DonateCtrl', DonateCtrl);

  function DonateCtrl($rootScope, $timeout, $q, $uibModalInstance, Notification, Restangular, userProfile, charityProfile, donationsHistory) {
    var vm = this;

    vm.isLoading = false;
    vm.isCCLoading = true;
    vm.isLoadingCharity = false;
    vm.isCalculating = false;
    vm.hasError = false;
    vm.userProfile = userProfile;
    vm.charityProfile = charityProfile;
    vm.creditCard = null;
    vm.isSearchVisible = !charityProfile;
    vm.canceler = null;

    vm.lastCharities = donationsHistory;

    // For Search Autocomplete
    vm.searchCategories = [
      {name: 'Charities', value: 'charities', stateTarget: 'user', iconClass: 'fa-heart-o'}
    ];

    // For posting the data
    vm.data = {
      paymentDetails: {
        method: null
      },
      idUserRecipient: charityProfile ? charityProfile.idUser : 0,
      amount: null
    };

    // For payment summary
    vm.summary = {
      fee: null,
      tax: null,
      total: null
    };

    // Load the Credit Card data
    Restangular.one('Billing').one('creditCard').get()
      .then(function (cc) {
        cc = cc.plain();
        vm.data.paymentDetails.method = angular.isObject(cc) && angular.isDefined(cc.number) ? 'old' : 'new';
        vm.creditCard = cc;
        $timeout(function () {
          vm.isCCLoading = false;
        }, 300);
      }, function () {
        vm.isCCLoading = false;
      });

    /**
     * Dismisses the modal dialog
     */
    vm.cancel = function () {
      $uibModalInstance.dismiss();
    };

    /**
     * Sets the charityProfile and populates the title and image in the chosen charity container
     * @param {Object} item Item to pre-fill with
     */
    vm.onSearchItemSelected = function (item) {
      // Callback on when the user selected the search item.
      if (!item || !item.originalObject || angular.isString(item.originalObject)) {
        return;
      }
      vm.isLoadingCharity = true;
      Restangular.one('Users').one('profile').get({id: item.originalObject.id})
        .then(function (profile) {
          vm.isLoadingCharity = false;
          profile = profile.plain();
          // Pre-fill the data now.
          vm.charityProfile = profile;
          vm.isSearchVisible = false;
        });
    };

    /**
     * Formats the search response
     *
     * @param {Object} response Response object
     * @returns {Object} Formatted response
     */
    vm.searchResponseFormatter = function (response) {
      var r,
          records;

      if (angular.isUndefined(response) || !response) {
        return null;
      }

      r = response.records;
      records = _.map(r, function (item) {
        var category = _.find(vm.searchCategories, {value: item.datasource}),
            returnObject = null,
            categoryDescriptionClass,
            value = item.fullname;

        categoryDescriptionClass = category.iconClass || 'fa-user';
        if (item.leadtype) {
          categoryDescriptionClass += item.leadtype === 'received' ? ' rotate-90' : '';
          value += ' | ' + item.leadfullname;
        }
        else if (item.companyname && item.companyname !== item.fullname) {
          value += ' | ' + item.companyname;
        }

        returnObject = {
          value: value,
          id: item.id,
          category: item.datasource,
          categoryDescription: '<i class="fa ' + categoryDescriptionClass + '"></i>',
          timeDescription: item.datecreatedformatted ? item.datecreatedformatted : '',
          leadtype: item.leadtype
        };

        return returnObject;
      });

      return records;
    };

    /**
     * Make the donation payment
     */
    vm.donate = function () {
      // Make sure the user recipient is correct
      vm.data.idUserRecipient = vm.charityProfile.idUser;
      vm.isLoading = true;

      // Do the request
      Restangular.one('Donations').post('donate', vm.data)
        .then(function () {
          $uibModalInstance.close();
          vm.isLoading = false;
          Notification({message: 'Success'}, 'confirmation');
        }, function (responseData) {
          vm.isLoading = false;
          Notification({message: 'Donation Payment Failed'}, 'error');
          if (responseData.data.errorCode === 701 || responseData.data.errorCode === 702 || responseData.data.errorCode === 703) {
            console.error('Unable to send: ' + responseData.data.errorMessage);
          }
          else {
            console.error('danger', 'Unable to send, try again later please');
          }
        });
    };

    /**
     * Sets the incoming profile as the destination / target charity profile
     * @param {object} profile Charity profile receiving the donation
     */
    vm.selectCharity = function (profile) {
      if (vm.charityProfile === profile) {
        return;
      }
      vm.charityProfile = profile;
      vm.isSearchVisible = false;
    };

    /**
     * Sets the timer for the search to avoid the unnecessary requests. Once given a go it sends the request to server.
     * @param {boolean} noTimer Indicates whether the trigger of the search should wait or fire immediately.
     */
    vm.calculate = function (noTimer) {
      if (!vm.data.amount) {
        return;
      }

      if (noTimer === true) {
        vm.sendCalculate(vm.data.amount);
        return;
      }

      if (vm.searchTimer) {
        $timeout.cancel(vm.searchTimer);
      }

      vm.isCalculating = true;
      vm.searchTimer = $timeout(function () {
        vm.sendCalculate(vm.data.amount);
      }, 500);
    };

    /**
     * Send the request to get the payment summary
     * @param {int} value Value to get the summary calculated for
     */
    vm.sendCalculate = function (value) {
      if (vm.isCalculating && vm.canceler) {
        vm.canceler.resolve();
      }

      vm.hasError = false;
      vm.isCalculating = true;

      vm.canceler = $q.defer();
      vm.canceler.value = value;
      Restangular.one('Donations').withHttpConfig({timeout: vm.canceler.promise}).one('calculateDonationTotal').get({
        amount: vm.data.amount
      }).then(function (result) {
        var data = result.plain();

        if (vm.data.amount !== value) {
          // This will prevent the undesired effect of quickly changing the text in the summary
          // since the canceller doesn't work
          return;
        }
        vm.isCalculating = false;
        try {
          vm.summary.total = Number(data.total);
          vm.summary.fee = Number(data.fees);
          vm.summary.tax = Number(data.tax);
          vm.hasError = false;
        }
        catch (err) {
          vm.hasError = true;
        }
      }, function () {
        vm.isCalculating = false;
        vm.hasError = true;
      });
    };

    /**
     * Immediately sends the summary request for the provided value
     * @param {int} value Value to calculate the summary for.
     */
    vm.calculateWith = function (value) {
      if (value && angular.isNumber(value)) {
        vm.data.amount = value;
        vm.calculate(true);
      }
    };

    /**
     * On ngKeyPress event handler for the donation amount input.
     * @param {object} $event ngKeyPress event
     */
    vm.onKeyPress = function ($event) {
      if ($event.key === 'Enter') {
        vm.calculate(true);
      }
    };

    vm.openSelector = function () {
      vm.isSearchVisible = true;
    };

    vm.checkScroll = function () {
      $timeout(function () {
        $rootScope.$broadcast('event:check-scroll');
      }, 100);
    };
  }
}());
