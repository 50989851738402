(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name myMemberships.rewardProgram.controller:RewardProgramCtrl
   *
   * @description
   *
   */
  angular
    .module('myMemberships.rewardProgram')
    .controller('RewardProgramCtrl', RewardProgramCtrl);

  function RewardProgramCtrl($interpolate, DataTable, RewardCodes, configOptions, SocialShare, BitlyUrlShortener) {
    var vm = this,
        filter;

    vm.selectedStatus = 'active';
    vm.selectedStatusLabel = 'Active';
    vm.rewardCodeHelp = 'In this screen you can set up new Reward Codes and apply the Reward Program payouts, or alternatively select \'Customise Rewards\' to set up a totally new cash reward offering';
    vm.urlToShare = null;
    vm.emailBody = null;

    vm.statuses = {
      active: 'Active',
      archived: 'Archived',
      all: 'All'
    };

    filter = function () {
      vm.list.reload({
        status: vm.selectedStatus,
        limit: 15
      });
    };

    vm.selectStatus = function (status, forceReload) {
      if (status !== vm.selectedStatus || forceReload === true) {
        vm.selectedStatus = status;
        vm.selectedStatusLabel = vm.statuses[status];
        filter();
      }
    };

    vm.list = DataTable.create('RewardCodes', 'index', {
      status: vm.selectedStatus,
      limit: 15
    }, {
      basicParams: {}
    });

    vm.openDetail = function ($index) {
      RewardCodes.openDetail(vm.list.getRecords()[$index])
        .then(function (result) {
          if (angular.isObject(result) && angular.isDefined(result.updatedRecord) && angular.isObject(result.updatedRecord)) {
            if (vm.selectedStatus === 'active' && result.updatedRecord.isArchived) {
              vm.list.getRecords().splice($index, 1);
            }
            else {
              angular.extend(vm.list.getRecords()[$index], result.updatedRecord);
            }
          }
        });
    };

    vm.openCreateCodeModal = function () {
      var result = RewardCodes.openRewardCreateModal();

      // The following will run only if the modal instance is closed (not dismissed).
      result.then(function (response) {
        var newSelectedStatus = vm.selectedStatus;
        if (angular.isDefined(response) && response === 'success') {
          if (vm.selectedStatus === 'archived') {
            newSelectedStatus = 'active';
          }
          vm.selectStatus(newSelectedStatus, true);
        }
      });
    };

    vm.list.isReady.then(function () {
      if (vm.list.recordsExist() && vm.list.getRecords() < 1) {
        vm.selectStatus('archived');
      }
    });

    vm.setUrl = function (rewardCode) {
      vm.isLoadingLink = true;
      BitlyUrlShortener.shorten(RewardCodes.getRewardCodeUrl(rewardCode))
        .then(function (result) {
          var exp = $interpolate(configOptions.defaultEmailMessage);
          vm.urlToShare = result;
          vm.emailBody = encodeURIComponent(exp({link: vm.urlToShare}));
          vm.isLoadingLink = false;
        });
    };

    vm.share = function (socialService) {
      SocialShare.share(socialService, {
        url: vm.urlToShare,
        name: configOptions.defaultShareName,
        description: configOptions.defaultShareMessage,
        source: 'MyLeadPod',
        picture: configOptions.appUrl + '/images/touch-icon-192x192.png'
      });
    };
  }
}());
