(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.ui.table.directive:singleExpand
   * @restrict A
   * @element
   */
  angular
    .module('components.ui.table')
    .directive('singleExpand', singleExpand);

  function singleExpand(ComponentsUtil) {
    return {
      restrict: 'A',
      scope: {
        subContent: '=',
        subContentIdx: '='
      },
      link: function (scope, element) {
        element.addClass('single-expand');
        element.bind('click', function () {
          scope.subContent = ComponentsUtil.toggleSubContent(scope.subContent, scope.subContentIdx);
          scope.$apply();
        });
      }
    };
  }
}());
