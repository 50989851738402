<div class="modal-content-container">
  <div class="row">
    <div class="col-xs-12 photo-upload-crop-container">
      <div ngf-drop ng-model="photoUpload.photoFile" ngf-pattern="image/*" class="cropArea">
        <ui-cropper image="photoUpload.photoFile  | ngfDataUrl" result-image="photoUpload.croppedDataUrl"></ui-cropper>
      </div>
    </div>
  </div>
  <div class="row modal-content-buttons">
    <div class="col-xs-12 text-right">
      <button class="btn btn-default" ng-disabled="photoUpload.isLoading" ng-click="photoUpload.cancel()">
        Cancel
      </button>
      <button class="btn btn-primary" ng-disabled="photoUpload.isLoading" ng-click="photoUpload.ok()">
        {{photoUpload.isLoading ? 'Loading ...' : 'Add Photo'}}
      </button>
    </div>
  </div>
</div>
