(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name myPod.sendInvite.controller:SendInviteCtrl
   *
   * @description
   *
   */
  angular
    .module('myPod.sendInvite')
    .controller('SendInviteCtrl', SendInviteCtrl);

  function SendInviteCtrl($state, $stateParams, MyPod) {
    MyPod.openSendInvite().then(function (result) {
      if (result && !result.isRedirecting) {
        $state.go('myPod');
      }
    },
    function () {
      // When this is an error or just simply closed, redirect to my-pod.
      $state.go('myPod');
    });
  }
}());
