(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name urlShorteners.provider:BitlyUrlShortener
   *
   * @description
   *
   */
  angular
    .module('urlShorteners')
    .provider('BitlyUrlShortener', BitlyUrlShortener);

  function BitlyUrlShortener() {
    var config = {
      accessToken: null
    };

    return {
      setAccessToken: function (accessToken) {
        config.accessToken = accessToken;
      },
      $get: ['$http', '$httpParamSerializer', 'configOptions', '$q', function ($http, $httpParamSerializer, configOptions, $q) {
        var cache = {};
        return {
          shorten: function (longUrl) {
            var deferred = $q.defer();
            /* eslint camelcase: [2, {properties: "never"}]*/
            if (angular.isDefined(cache[longUrl])) {
              deferred.resolve(cache[longUrl]);
            }
            else {
              $http({
                method: 'POST',
                headers: {
                  "Authorization": "Bearer " + configOptions.bitlyGenericAccessToken,
                  "Content-Type": "application/json"
                },
                url: 'https://api-ssl.bitly.com/v4/shorten',
                data:{
                  long_url: longUrl
                }
              })
                .then(function (result) {
                  cache[longUrl] = result.data.link;
                  deferred.resolve(result.data.link);
                });
            }

            return deferred.promise;
          }
        };
      }]
    };
  }
}());
