<div id="my-memberships-ctrl-container">
  <div class="row">
    <div class="col-xs-12 text-center trial-top-notification" ng-if="myMemberships.trialData.isInTrial">
      <div>You are currently evaluating the business account. This trial subscription will expire on <span style="white-space:nowrap;">{{myMemberships.trialData.trialExpiryDate | date:'d MMM yyyy'}}</span></div>
      <div ng-if="!myMemberships.trialData.trialWarning">
        <div>To purchase the full subscription now, click the button below</div>
        <button class="btn-primary btn-sm" ng-click="myMemberships.activateBusinessAccount()">Buy Subscription</button>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12 col-sm-4">
      <h1 class="page-header">My Business</h1>
    </div>
    <div class="col-sm-8 hidden-xs">
      <div class="page-header-buttons">
        <div ng-if="!myMemberships.isBusinessAccountActive()">
          <span class="trial-notification no-select" ng-if="myMemberships.trialData.isEligibleForTrial && !myMemberships.trialData.isInTrial">You are eligible for the trial business subscription</span>
          <button class="btn btn-success btn-shadow btn-lg-lg"
                  ng-click="myMemberships.activateBusinessAccount()"
                  ng-disabled="myMemberships.isActivating">{{myMemberships.isActivating ? 'Activating ...' : 'Activate'}}
        </button>
        </div>
        <a ng-if="myMemberships.isBusinessAccountActive()" ng-click="myMemberships.openAskForLeads();"
           class="btn btn-primary btn-shadow btn-lg-lg">Ask for Leads</a>
        <a ng-if="myMemberships.isBusinessAccountActive()" ui-sref="leadRegister"
           class="btn btn-success btn-shadow btn-lg-lg">Register Lead</a>
      </div>
    </div>
  </div>

  <div class="row" ng-if="!myMemberships.isBusinessAccountActive()">
    <div class="col-xs-12">
      <div class="page-empty-section text-center">
        <i class="fa fa-users fa-3x text-light-colour"></i>
        <div class="page-empty-section-text">
          Need leads for your business?
        </div>
        <br/>
        Invite people to recommend your business. You can join their pod and grow your connections. Click "Activate" to
        start receiving leads.
        <div class="page-empty-section-button visible-xs">
          <div ng-if="!myMemberships.isBusinessAccountActive()">
            <div class="trial-notification">You are eligible for the trial business subscription</div>
            <button class="btn btn-success btn-shadow btn-lg-lg"
                    ng-click="myMemberships.activateBusinessAccount()"
                    ng-disabled="myMemberships.isActivating">{{myMemberships.isActivating ? 'Activating ...' : 'Activate'}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" ng-if="myMemberships.isBusinessAccountActive()">
    <div class="col-xs-12">
      <tabs-manager tab-id="myMemberships" tabs-config="myMemberships.tabsConfig" default-tab="memberships" tab-manager="myMemberships.tabManager"></tabs-manager>
    </div>
  </div>

  <div ng-if="myMemberships.isBusinessAccountActive()" class="row"
       ng-show="myMemberships.tabManager.isOpen('memberships')"
       ng-include="'my-memberships/memberships/memberships.tpl.html'">
  </div>

  <div ng-if="myMemberships.isBusinessAccountActive()" class="row"
       ng-show="myMemberships.tabManager.isOpen('receivedLeads')"
       ng-include="'my-memberships/received-leads/received-leads.tpl.html'">
  </div>

  <div ng-if="myMemberships.isBusinessAccountActive()" class="row"
       ng-show="myMemberships.tabManager.isOpen('rewardPrograms')"
       ng-include="'my-memberships/reward-program/reward-program.tpl.html'">
  </div>

  <div ng-if="myMemberships.isBusinessAccountActive() && myMemberships.isImportAllowed()" class="row"
       ng-show="myMemberships.tabManager.isOpen('import')"
       ng-include="'my-memberships/import/import.tpl.html'">
  </div>

  <action-toolbar class="visible-xs" at-target="secondary">
    <a ng-if="!myMemberships.isBusinessAccountActive()" class="no-animate fa fa-play" label="Activate"
       ng-click="myMembership.activateBusinessAccount()"
       ng-disabled="myMemberships.isActivating"><span class="tool-label">Activate</span></a>
    <a ng-if="myMemberships.isBusinessAccountActive()" class="no-animate fa fa-user-plus" label="Ask for Leads"
       ng-click="myMemberships.openAskForLeads();"><span class="tool-label">Ask for Leads</span></a>
    <a ng-if="myMemberships.isBusinessAccountActive()" class="no-animate fa fa-paper-plane" label="Register Lead"
       ui-sref="leadRegister"><span class="tool-label">Register Lead</span></a>
  </action-toolbar>



</div>
