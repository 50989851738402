(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name components.filter:address
   *
   * @description
   *
   * @param {Object} input The address object
   * @returns {String} Formatted Address
   *
   */
  angular
    .module('components')
    .filter('address', addressFilter);

  function addressFilter() {
    return function (input, locale) {
      var address = input,
          formats = {
            au: [['numberPostal', 'addressPostal'], ['suburbPostal', 'statePostalShort', 'postCodePostal'], 'countryPostal'],
            us: [['numberPostal', 'addressPostal'], 'suburbPostal', 'statePostalShort', 'postCodePostal', 'countryPostal']
          },
          format,
          output,
          innerOutput;

      format = formats[locale];
      if (!format) {
        format = formats.au;
      }

      if (address) {
        angular.forEach(format, function (key) {
          if (angular.isArray(key)) {
            // Keys in array are not separated by divider
            angular.forEach(key, function (k) {
              innerOutput = (innerOutput ? innerOutput + ' ' : '') + (address[k] ? address[k] : '');
            });
            if (innerOutput) {
              output = (output ? output + ', ' : '') + innerOutput;
            }
            innerOutput = '';
          }
          else if (address[key]) {
            output = (output ? output + ', ' : '') + address[key];
          }
        });
        return output;
      }

      return '';
    };
  }
}());
