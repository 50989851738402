(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name myMemberships.joinPod.controller:JoinPodModalCtrl
   *
   * @description
   *
   */
  angular
    .module('myMemberships.joinPod')
    .controller('JoinPodModalCtrl', JoinPodModalCtrl);

  function JoinPodModalCtrl(Restangular, Notification,
                       FormAlerts, $uibModalInstance, myProfile, userProfile) {
    var vm = this;
    vm.isLoading = false;

    vm.rewardCodes = {};
    vm.selectedRewardCode = '';
    vm.selectedRewardCodeLabel = 'Reward Code';
    vm.alerts = new FormAlerts();
    vm.user = myProfile;
    vm.targetUser = userProfile;
    vm.isRewardsFormValid = false;

    vm.data = {
      firstName: vm.targetUser ? vm.targetUser.firstName : null,
      email: vm.targetUser ? vm.targetUser.email : null,
      creditCard: {},
      phoneMobile: vm.targetUser ? vm.targetUser.phoneMobile : null
    };

    vm.cancel = function () {
      $uibModalInstance.dismiss();
    };

    vm.join = function () {
      vm.isLoading = true;
      vm.alerts.reset();
      Restangular.one('Memberships').post('index', vm.data)
        .then(function (response) {
          $uibModalInstance.close(response.receiveLeads);
          Notification({message: 'Success'}, 'confirmation');
        }, function () {
          vm.isLoading = false;
          vm.alerts.add('danger', 'Unable to send');
        });
    };

    vm.onRewardSelectionChange = function (data, isFormValid) {
      _.extend(vm.data, data);
      vm.isRewardsFormValid = isFormValid;
    };
  }
}());
