<div class="user-details-directive no-select {{addClass}}" ng-class="{'no-link': !isLink}">
  <div class="details-container">
    <div class="user-pages">
      <div class="user-page-1 table-user-detail">
        <a ng-if="!nameOnly" class="images-content" ui-sref="user.agreements({id: user.id})" tabindex="{{isLink ? 0 : -1}}">
          <div class="link">
            <span class="glyphicon glyphicon-link"></span>
          </div>
          <div class="selected-user">
            <i class="fa fa-check"></i>
          </div>
          <span user-avatar size="small" is-link="isLink" user="user"></span>
        </a>
        <a ui-sref="user.agreements({id: user.id})" class="details-content" tabindex="{{isLink ? 0 : -1}}">
          <span class="table-user-detail-name">
            {{user.firstName}} {{user.lastName}}
          </span>
          <span ng-if="user[descriptionKey] && !nameOnly" class="table-user-detail-company-name">
            {{user[descriptionKey]}}
          </span>
        </a>
      </div>
    </div>
  </div>
  <div class="toggle-btn"
       ng-if="hasDetails && (user.phoneMobile || user.email)"
       popover-placement="bottom"
       popover-trigger="outsideClick"
       popover-append-to-body="true"
       uib-popover-html="htmlPopover">
    <i class="fa fa-ellipsis-h"></i>
  </div>
</div>
