(function () {
  'use strict';

  angular
    .module('myMemberships')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('myMemberships', {
        url: '/my-memberships',
        templateUrl: 'my-memberships/my-memberships.tpl.html',
        controller: 'MyMembershipsCtrl',
        controllerAs: 'myMemberships',
        authenticate: true,
        resolve: {
          userProfile: ['Session', function (Session) {
            return Session.getUserProfile();
          }]
        }
      });
  }
}());
