(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name account.profile.controller:PhotoUploadCtrl
   *
   * @description
   *
   */
  angular
    .module('account.profile')
    .controller('PhotoUploadCtrl', PhotoUploadCtrl);

  function PhotoUploadCtrl($uibModalInstance, Session, photoFile) {
    var vm = this;
    vm.photoFile = photoFile || '';
    vm.croppedDataUrl = '';
    vm.isLoading = false;

    vm.ok = function () {
      vm.isLoading = true;
      Session.updatePhoto(vm.croppedDataUrl)
        .then(function (userProfile) {
          $uibModalInstance.close({userProfile: userProfile});
        }, function () {
          vm.isLoading = true;
        });
    };

    vm.cancel = function () {
      $uibModalInstance.dismiss();
    };
  }
}());
