(function () {
  'use strict';

  /* @ngdoc object
   * @name myPod
   * @description
   *
   */
  angular
    .module('myPod', [
      'ui.router',
      'myPod.members',
      'myPod.sentLeads',
      'myPod.sendInvite'
    ]);
}());
