<div class="modal-content-container">
  <div class="row">
    <div class="col-xs-12">
      <div class="row">
        <div class="col-xs-12">
          <span class="sub-title">
            {{creditCard.isAdd() ? 'Add Credit Card' : 'Change Credit Card'}}
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-7 accepted-cards-container">
          <i class="fa fa-cc-visa fa-2x"></i> <i class="fa fa-cc-mastercard fa-2x"></i> <i class="fa fa-cc-amex fa-2x"></i>
        </div>
        <div class="col-xs-5 accepted-cards-container">
          <!-- Begin eWAY Linking Code -->
          <div id="eWAYBlock">
            <div style="text-align:right;">
              <a href="https://www.eway.com.au/secure-site-seal?i=13&s=7&pid=880d42e7-6908-4374-8cfe-19357ae619da&theme=0" title="eWAY Payment Gateway" target="_blank" rel="nofollow">
                <img alt="eWAY Payment Gateway" src="https://www.eway.com.au/developer/payment-code/verified-seal.ashx?img=13&size=7&pid=880d42e7-6908-4374-8cfe-19357ae619da&theme=0" />
              </a>
            </div>
          </div>
          <!-- End eWAY Linking Code -->
        </div>
      </div>
      <form name="creditCardForm">
        <div class="row">
          <div class="col-xs-12">
            <div class="form-group" ng-class="{'has-error': (creditCardForm.$submitted || creditCardForm.holderName.$touched) && creditCardForm.holderName.$error.required}">
              <label class="sr-only" for="holderName">Name on card</label>
              <div ng-show="creditCardForm.$submitted || creditCardForm.holderName.$touched">
                <div class="input-error" ng-show="creditCardForm.holderName.$error.required">Missing name on card.</div>
              </div>
              <div class="left-inner-addon">
                <i class="fa fa-user inner-addon"></i>
                <input class="form-control input-lg" type="text" name="holderName" id="holderName"
                       placeholder="Name on card" ng-required="true" ng-model="creditCard.data.holderName" />
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <div class="form-group" ng-class="{'has-error': (creditCardForm.$submitted || creditCardForm.number.$touched) && (creditCardForm.number.$error.required || creditCardForm.number.$error.ccNumber)}">
              <label class="sr-only" for="number">Card number</label>
              <div ng-show="creditCardForm.$submitted || creditCardForm.number.$touched">
                <div class="input-error" ng-show="creditCardForm.number.$error.required">Missing card number.</div>
                <div class="input-error" ng-show="!creditCardForm.number.$error.required && creditCardForm.number.$error.ccNumber">Invalid card number.</div>
              </div>
              <div class="left-inner-addon">
                <i class="fa fa-credit-card inner-addon"></i>
                <input class="form-control input-lg" type="text" name="number" id="number"
                       placeholder="Card number" ng-required="true" ng-model="creditCard.data.number"
                       cc-number cc-type="creditCard.cards" />
              </div>
            </div>
          </div>
        </div>

        <div class="row" cc-exp>
          <div class="col-xs-12">
            <div ng-show="creditCardForm.$submitted || (creditCardForm.expiryMonth.$touched && creditCardForm.expiryYear.$touched)">
              <div class="input-error" ng-show="creditCardForm.$error.ccExp">Invalid expiry date.</div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6">
            <div class="form-group" ng-class="{'has-error': (creditCardForm.$submitted || creditCardForm.expiryMonth.$touched) && creditCardForm.expiryMonth.$error.required}">
              <label class="sr-only" for="expiryMonth">Expiry month</label>
              <div ng-show="creditCardForm.$submitted || creditCardForm.expiryMonth.$touched">
                <div class="input-error" ng-show="creditCardForm.expiryMonth.$error.required">Missing Expiry month.</div>
              </div>
              <div class="left-inner-addon">
                <i class="fa fa-calendar inner-addon"></i>
                <select class="form-control input-lg" name="expiryMonth" id="expiryMonth" placeholder="Expiry month" ng-required="true"
                        ng-model="creditCard.data.expiryMonth" cc-exp-month>
                  <option value="" disabled selected>Expiry month</option>
                  <option ng-repeat="month in creditCard.months" value="{{month}}">{{month}}</option>
                </select>
              </div>
            </div>

          </div>
          <div class="col-xs-12 col-sm-6">
            <div class="form-group" ng-class="{'has-error': (creditCardForm.$submitted || creditCardForm.expiryYear.$touched) && creditCardForm.expiryYear.$error.required}">
              <label class="sr-only" for="expiryYear">Expiry year</label>
              <div ng-show="creditCardForm.$submitted || creditCardForm.expiryYear.$touched">
                <div class="input-error" ng-show="creditCardForm.expiryYear.$error.required">Missing Expiry year.</div>
              </div>
              <div class="left-inner-addon">
                <i class="fa fa-calendar inner-addon"></i>
                <select class="form-control input-lg" name="expiryYear" id="expiryYear" placeholder="Expiry year" ng-required="true"
                        ng-model="creditCard.data.expiryYear" cc-exp-year full-year>
                  <option value="" disabled selected>Expiry year</option>
                  <option ng-repeat="year in creditCard.years" value="{{year}}">{{year}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="row modal-content-buttons">
    <div class="col-xs-12 text-right">
      <button class="btn btn-default" ng-disabled="creditCard.isLoading" ng-click="creditCard.cancel()">
        Cancel
      </button>
      <button class="btn btn-primary" ng-disabled="creditCard.isLoading || creditCardForm.$invalid" ng-click="creditCard.ok()">
        {{creditCard.isLoading ? 'Loading ...' : (creditCard.isAdd() ? 'Add Card' : 'Change Card') }}
      </button>
    </div>
  </div>
</div>
