(function () {
  'use strict';

  /* @ngdoc object
   * @name cache
   * @description
   *
   */
  angular
    .module('cache', [
      'angular-cache'
    ]);

  angular
    .module('cache')
    .config(config);

  function config(CacheFactoryProvider) {
    angular.extend(CacheFactoryProvider.defaults, {
      storagePrefix: 'myleadpod.',
      maxAge: 864000000
    });
  }
}());
