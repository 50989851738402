(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.directive:staticInclude
   * @restrict EA
   * @element
   *
   */
  angular
    .module('components')
    .directive('staticInclude', staticInclude);

  function staticInclude($templateRequest, $compile) {
    return {
      restrict: 'A',
      transclude: true,
      replace: true,
      scope: false,
      link: function ($scope, element, attrs) {
        var templatePath = attrs.staticInclude;

        $templateRequest(templatePath)
          .then(function (response) {
            var contents = element.html(response).contents();
            $compile(contents)($scope.$new(false, $scope.$parent));
          });
      }
    };
  }
}());
