(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.directive:stateClass
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="components">
       <file name="index.html">
        <state-class></state-class>
       </file>
     </example>
   *
   */
  angular
    .module('components')
    .directive('stateClass', stateClass);

  function stateClass($rootScope) {
    return {
      restrict: 'A',
      scope: {},
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint no-unused-vars: [2, {"args": "none", "varsIgnorePattern": "Handler" }]*/
        var stateChangeStartHandler = $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
          var fromClassNames = angular.isDefined(fromState.data) && angular.isDefined(fromState.data.classNames) ? fromState.data.classNames : null,
              toClassNames = angular.isDefined(toState.data) && angular.isDefined(toState.data.classNames) ? toState.data.classNames : null;

          // don't do anything if they are the same
          if (fromClassNames !== toClassNames) {
            if (fromClassNames) {
              element.removeClass(fromClassNames);
            }

            if (toClassNames) {
              element.addClass(toClassNames);
            }
          }
        });
      }
    };
  }
}());
