(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name mlpNotifications.directive:notificationCenter
   * @restrict E
   * @element
   */
  angular
    .module('mlp-notifications')
    .directive('notificationCenter', notificationCenter);

  function notificationCenter($rootScope, $timeout, $sce, $state, Cache, Restangular, notificationConst, Leads) {
    return {
      restrict: 'E',
      scope: {
        user: '=',
        isLink: '=?',
        descriptionKey: '=?',
        stopPropagation: '=?',
        hasDetails: '=?',
        nameOnly: '=?',
        addClass: '='
      },
      replace: true,
      transclude: false,
      templateUrl: 'notifications/notification-center-directive.tpl.html',
      controllerAs: 'nc',
      controller: ['$scope', function ($scope) {
        var watchHandeNewNotification,
            watchHandleClearNotification,
            scope = this,
            cache = Cache.create('notification-centre-help');

        scope.notificationsBkp = [];
        scope.notifications = [];
        scope.selectedCategories = ['all'];
        scope.filterActive = false;
        scope.displayHelp = false;

        scope.filterCategories = [
          {name: 'All', value: 'all'},
          {name: 'Leads', value: 'leads', iconClass: 'fa-paper-plane'},
          {name: 'Messages', value: 'messages', iconClass: 'fa-envelope'}
        ];

        // Check if the help was dismissed
        if (!cache.get('helpDismissed')) {
          scope.displayHelp = true;
        }

        // to be replaced with data from api
        scope.notificationsData = [];

        function generateNotifications(data) {
          var foundCategories = [],
              now = (new Date()).getTime(),
              hasMore = false;

          // Alter data
          _.each(data, function (item) {
            // First. Insert the icon-class of the category.
            if (item && item.category && _.find(scope.filterCategories, {value: item.category})) {
              // Icon class
              item.iconClass = _.find(scope.filterCategories, {value: item.category}).iconClass;
            }
            else {
              item.iconClass = 'fa-stop';
            }

            // Check if the category is lead. Then change the value to lead url
            if (item && item.category && item.category === 'leads') {
              // item.value = $sce.trustAsHtml('<a>View Lead</a>');
              item.description = 'sent you new lead';
            }

            if (item && item.category && item.category === 'messages') {
              hasMore = item && item.count && item.count > 1;
              item.description = hasMore ? 'sent you new ' : 'sent you a new ';
              if (item.data && item.data.leadId) {
                item.description += 'lead ';
              }
              item.description += hasMore ? ' messages [' + item.count + ']' : 'message';
            }

            // Format time
            item.timeFormatted = scope.getFormattedTime(now - (new Date(item.time)).getTime());

            // Add the category from the item
            foundCategories.push(item.category);
          });

          // Clear all categories first
          _.each(scope.filterCategories, function (category) {
            category.hasResults = false;
          });

          // Now check the categories
          if (scope.filterCategories && scope.filterCategories.length > 0) {
            // Make the values unique
            foundCategories = _.uniq(foundCategories);
            // Now loop through each category and apply the badge
            angular.forEach(foundCategories, function (category) {
              _.find(scope.filterCategories, {value: category}).hasResults = true;
            });
          }

          scope.notificationsBkp = _.clone(data);
          filterNotifications();
          scope.notify();

          // Sometimes we have problem with refreshing the DOM.
          $timeout(function () {
            $scope.$apply();
          }, 100);
        }

        function filterNotifications(category) {
          var fresults = [];

          if (!category && scope.selectedCategories.lastIndexOf('all') > -1 || category === 'all') {
            scope.notifications = _.clone(scope.notificationsBkp);
            return;
          }

          if (!category) {
            return;
          }

          angular.forEach(scope.notificationsBkp, function (n) {
            if (_.contains(scope.selectedCategories, n.category)) {
              fresults.push(n);
            }
          });
          scope.notifications = fresults;
        }

        /**
         * Mark all notifications as read using API
         */
        scope.markRead = function () {
          scope.notifications = [];
          scope.notificationsBkp = [];
          scope.selectedCategories = ['all'];
          scope.filterActive = false;
          scope.notify();
        };

        scope.notify = function () {
          $rootScope.$broadcast('event:notifications-status', {
            hasNotifications: scope.notifications.length > 0,
            count: scope.notifications.length > 10 ? '+' : scope.notifications.length
          });
        };

        /**
         * Clear all notifications
         */
        scope.clearNotifications = function () {
          return;
        };

        scope.openNotification = function (item) {
          // Based on the category do what is required
          if (!item) {
            return;
          }

          // This is the new lead notification. Only received leads will be visible in NC
          if (item.category && item.category === notificationConst.categoryLeads) {
            Leads.openDetail(item.data.leadId, 'received');
          }

          // This is the message within the lead as it contains the lead id in data property
          if (item.category && item.category === notificationConst.categoryMessages && item.data && item.data.leadId) {
            Leads.openDetail(item.data.leadId, 'received', null, 'messages');
          }

          // This is the direct message. Open the user profile at the messages tab
          if (item.category && item.category === notificationConst.categoryMessages && item.userId && !item.data) {
            $state.go('user.messages', {id: item.userId}, {reload: true});
          }

          scope.clearNotification(item);
          generateNotifications(scope.notificationsData);
        };

        scope.openNotificationById = function (id) {
          var item = _.find(scope.notifications, {id: id});
          if (item) {
            scope.openNotification(item);
          }
        };

        scope.clearNotification = function (item) {
          var category,
              userId,
              type,
              data,
              filtered;

          // Clear the items from the same user within the same category ...
          if (!item || !item.category || !item.userId || !item.type) {
            return;
          }
          category = item.category;
          userId = item.userId;
          type = item.type;
          data = item.data;

          if (data) {
            filtered = _.where(scope.notificationsData, {
              category: category,
              userId: userId,
              type: type,
              data: data
            });
          }
          else {
            filtered = _.where(scope.notificationsData, {
              category: category,
              userId: userId,
              type: type
            });
          }

          // Use API to remove the notification

          scope.notificationsData = _.difference(scope.notificationsData, filtered);
        };

        scope.clearNotificationById = function (id) {
          var item = _.find(scope.notifications, {id: id});
          if (item) {
            $timeout(function () {
              scope.clearNotification(item);
              generateNotifications(scope.notificationsData);
            }, 150);
          }
        };

        scope.isSelected = function (category) {
          return _.contains(scope.selectedCategories, category);
        };

        scope.changeCategory = function (category, evt, isSingleFilter) {
          if (!category) {
            return;
          }
          scope.notifications = [];

          if (category === 'all') {
            scope.selectedCategories = ['all'];
            scope.filterActive = false;
          }
          else if (isSingleFilter && category) {
            scope.selectedCategories = [category];
            scope.filterActive = true;
          }
          filterNotifications(category);
        };

        scope.getFormattedTime = function (timegap) {
          var interval = Math.abs(timegap) / 1000,
              granularity = 1,
              zeroInterval = 'now',
              suffix = '',
              short = false,
              units = null,
              output = '';

          units = [
            {seconds: 31536000, singular: 'year', plural: 'years', short: 'y'},
            {seconds: 2592000, singular: 'month', plural: 'months', short: 'm'},
            {seconds: 604800, singular: 'week', plural: 'weeks', short: 'w'},
            {seconds: 86400, singular: 'day', plural: 'days', short: 'd'},
            {seconds: 3600, singular: 'hour', plural: 'hours', short: 'h'},
            {seconds: 60, singular: 'min', plural: 'min', short: 'm'}
          ];

          angular.forEach(units, function (unit) {
            var count = null;
            if (granularity === 0) {
              return;
            }
            if (interval >= unit.seconds) {
              count = Math.floor(interval / unit.seconds);
              output += (output ? ' ' : '') + count;

              if (short) {
                output += unit.short;
              }
              else {
                output += ' ' + (count > 1 ? unit.plural : unit.singular);
              }

              interval %= unit.seconds;
              granularity--;
            }
          });

          return output ? output + (suffix === '' ? '' : ' ' + suffix) : zeroInterval;
        };

        scope.onSwiping = function (deltaX) {
          scope.isSwipeRight = deltaX > 1;
          $scope.$apply();
        };

        scope.dismissHelp = function () {
          cache.put('helpDismissed', 1);
          scope.displayHelp = false;
        };

        // Subscribe to the event of the new notification event that this directive will be notified about.
        watchHandeNewNotification = $rootScope.$on('event:notifications-raised', function (event, data) {
          if (data.notifications) {
            scope.notifications = data.notifications;
          }
        });

        // Subscribe to the events raised by different routes that will effectively get this directive to check and clear
        // any notifications that might be related to those routes (/messages, /lead/{}/messages etc)
        watchHandleClearNotification = $rootScope.$on('event:notifications-check', function (event, data) {
          scope.clearNotification(data);
          generateNotifications(scope.notificationsData);
        });

        $rootScope.$on('$destroy', function () {
          watchHandeNewNotification();
          watchHandleClearNotification();
        });

        // INIT
        generateNotifications(scope.notificationsData);
      }]
    };
  }
}());
