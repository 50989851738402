(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name account.preferences.controller:PreferencesCtrl
   *
   * @description
   *
   */
  angular
    .module('account.preferences')
    .controller('MailingListUnsubscribeCtrl', MailingListUnsubscribeCtrl);

  function MailingListUnsubscribeCtrl($scope, $document, AccountService, Restangular, $stateParams) {
    var vm = this;
    vm.isSaving = false;
    vm.isError = false;
    vm.isContactOpen = false;
    vm.data = {
      userId: $stateParams.userId,
      subscriptionId: $stateParams.subscriptionId
    };

    vm.saveChanges = function () {
      vm.isSaving = true;

      Restangular.one('Preferences').one('mailingListSubscription').remove(vm.data)
        .then(function () {
          vm.isSaving = false;
        }, function () {
          vm.isSaving = false;
          vm.isError = true;
        });
    };

    vm.openFeedback = function () {
      AccountService.openFeedback(null, true);
    };

    // init
    vm.saveChanges();
  }
}());
