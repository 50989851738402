(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name dashboard.pod.controller:PodCtrl
   *
   * @description
   *
   */
  angular
    .module('dashboard.pod')
    .controller('PodCtrl', PodCtrl);

  function PodCtrl($scope) {
    var vm = this;
    vm.dailyStats = $scope.dashboard.earningsDailyStats;
    vm.list = $scope.dashboard.members;
  }
}());
