<div class="no-select">
    <label class="sr-only" for="searchbar">Search</label>

    <search-autocomplete
        control-id="{{searchbar.searchbarId}}"
        placeholder="Search"
        minlength="3"
        clear-selected="false"

        search-service="{{searchbar.searchService}}"
        search-service-end-point="{{searchbar.searchServiceEndPoint}}"
        remote-url-response-formatter="searchbar.searchResponseFormatter"

        data-field="content.records"
        selected-object="searchbar.onSearchItemSelected"

        search-fields="search"
        title-field="value"
        description-field="categoryDescription"
        description-field2="timeDescription"
        category-field="category"

        input-name="searchbarControl"
        input-class="form-control input-lg"
        match-class="autocomplete-highlight"
        blur-on-select="true"
        has-back-button="true"
        update-input-on-select="true"

        search-categories="searchbar.searchCategories"
        focus-in="searchbar.focusIn()"
        focus-out="searchbar.focusOut()"

        has-history="true"
        field-required="false" />
</div>
