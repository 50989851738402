(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name user.directive:userAvatar
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="user">
       <file name="index.html">
        <user-avatar></user-avatar>
       </file>
     </example>
   *
   */
  angular
    .module('user')
    .directive('userAvatar', userAvatar);

  function userAvatar() {
    return {
      restrict: 'EA',
      scope: {
        user: '=user',
        size: '@',
        isLink: '@',
        fixedSize: '@'
      },
      templateUrl: 'user/user-avatar-directive.tpl.html',
      replace: true,
      controllerAs: 'userAvatar',
      controller: ['$scope', 'configOptions', function ($scope, configOptions) {
        var vm = this;
        if ($scope.isLink !== 'true') {
          vm.isLink = false;
        }
        else {
          vm.isLink = true;
        }
        if ($scope.size !== 'small' && $scope.size !== 'large') {
          $scope.size = 'small';
        }

        vm.isSmall = function () {
          return $scope.size === 'small';
        };

        if ($scope.user) {
          vm.idUser = $scope.user.id;
          vm.initials = $scope.user.firstName.trim().charAt(0);
        }
        else {
          return;
        }

        vm.isImage = function () {
          return !!$scope.user.imageToken;
        };

        vm.isFixedSize = function () {
          return $scope.fixedSize === 'true';
        };

        vm.getStyle = function () {
          var size = vm.isSmall() ? 44 : 120,
              avatarUrl;
          if ($scope.user.imageToken) {
            avatarUrl = configOptions.baseUrl + '/Users/photo/' + $scope.user.imageToken + '?w=' + size + '&h=' + size;
            return {
              'background-image': 'url(' + avatarUrl + ')'
            };
          }

          return {
            'background-color': $scope.user.color
          };
        };
      }]
    };
  }
}());
