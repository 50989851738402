(function () {
  'use strict';

  /* @ngdoc object
   * @name user.messages
   * @description
   *
   */
  angular
    .module('user.messages', [
      'ui.router',
      'mlp-messaging'
    ]);
}());
