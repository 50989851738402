(function () {
  'use strict';

  angular
    .module('myPod.sentLeads')
    .config(config);

  function config($stateProvider, configOptions) {
    $stateProvider
      .state('myPod.sentLeads', angular.extend({}, configOptions.defaultStateParams, {
        url: '/sent-leads',
        templateUrl: 'my-pod/my-pod.tpl.html',
        controller: 'MyPodCtrl',
        controllerAs: 'myPod',
        onEnter: ['RouterHelper', function (RouterHelper) {
          RouterHelper.activateTab('myPod', 'sentLeads');
        }]
      }));
  }
}());
