(function () {
  'use strict';

  /* @ngdoc object
   * @name dashboard.pod
   * @description
   *
   */
  angular
    .module('dashboard.pod', [
      'ui.router',
      'angular-chartist'
    ]);
}());
