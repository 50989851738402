(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name dashboard.controller:DashboardCtrl
   *
   * @description
   *
   */
  angular
    .module('dashboard')
    .controller('DashboardCtrl', DashboardCtrl);

  function DashboardCtrl(userProfile, dashboardData) {
    var vm = this;
    vm.earnings = dashboardData.earnings;
    vm.donations = dashboardData.donations;
    vm.membersCount = dashboardData.membersCount;
    vm.membershipsCount = dashboardData.membershipsCount;
    vm.leadsDailyStats = dashboardData.leadsDailyStats;
    vm.earningsDailyStats = dashboardData.earningsDailyStats;
    vm.sentLeadsCount = dashboardData.sentLeadsCount;
    vm.members = dashboardData.members;
    vm.receivedLeads = dashboardData.receivedLeads;
    vm.userProfile = userProfile;

    vm.isBusinessAccountActive = function () {
      return angular.isObject(userProfile) && angular.isDefined(userProfile.isBusinessAccountActive) && userProfile.isBusinessAccountActive === true;
    };
  }
}());
