<div ng-controller="ToolbarCtrl as toolbar">
  <nav id="main-navbar" class="navbar"
       ng-class="{'navbar-default': toolbar.isAuthenticated(), 'navbar-visitor': !toolbar.isAuthenticated(), 'is-mobile': toolbar.isMobile}">

    <div class="container fill-height" ng-if="!toolbar.isAuthenticated()">
      <div class="row">
        <div class="col-xs-4">
          <a href="https://www.myleadpod.com" class="has-image">
            <img class="nav-no-login-logo" src="images/logo-original-centered.svg" alt="MyLeadPod">
          </a>
        </div>
        <div class="col-xs-8 text-right">
          <a ng-click="toolbar.openFeedback(true)" class="navbar-link nav-no-login-link" style="margin-right: 20px; cursor: pointer;">Need Help?</a>
          <a ui-sref="accountSignInUp.accountSignUp" class="navbar-link nav-no-login-link no-animate" ng-if="toolbar.isLogin()">Sign up</a>
          <a ui-sref="accountSignInUp.accountSignIn" class="navbar-link nav-no-login-link no-animate" ng-if="toolbar.showLoginLink()">Login</a>
        </div>
      </div>
    </div>

    <div class="container" ng-if="toolbar.isAuthenticated()" style="pointer-events: none;">

      <div class="navbar-header">

        <a class="navbar-toggle collapsed" ng-click="toolbar.toggleMenu()">
          <span class="sr-only">Toggle navigation</span>
          <i class="glyphicon glyphicon-menu-hamburger"></i>
        </a>

       <div id="searchbar-alt" class="searchbar-container"></div>


        <a ng-if="toolbar.isDesktop" class="navbar-brand navbar-brand-desktop" ui-sref="dashboard">
          <img src="images/logo-normal-fullalpha.svg"/>
        </a>

        <a ng-if="toolbar.isMobile" class="navbar-brand navbar-brand-mobile" ui-sref="dashboard">
          <img src="images/logo-mobile-opaque.svg"/>
        </a>

        <!--<div id="notification-bell-alt" ng-class="{'has-notifications': toolbar.hasNotifications}"></div>-->
      </div>

      <!-- Navbar-collapse -->
      <div id="navbar" class="navbar-collapse collapse"
           ng-class="{open: !toolbar.isCollapsed}">

        <div ng-if="!toolbar.isCollapsed" style="position: absolute; width: 100vw; height: 100%; top: 0px; left: 0px; background: transparent; z-index: -1;" ng-click="toolbar.isCollapsed = true;"></div>

        <ul class="nav navbar-nav main-menu">
          <li ng-if="toolbar.isMobile" style="text-align: center">
            <a ui-sref="account.profile" ng-click="toolbar.closeMenu()" data-toggle="dropdown" role="button" style="height: auto;position:relative; text-align:center;">
              <div user-avatar user="toolbar.getUserProfile()" size="large" is-link="false"></div>
              <div>{{toolbar.userProfile.fullName}}</div>
            </a>
          </li>
          <li ui-sref-active="active"><a class="icon-only-lg icon-only-md" ui-sref="dashboard" ng-click="toolbar.closeMenu()"><i class="fa fa-home"></i><span>Dashboard</span></a></li>
          <li ui-sref-active="active"><a ui-sref="myPod.members" ng-click="toolbar.closeMenu()"><i class="fa fa-paper-plane"></i>My Pod</a></li>
          <li ui-sref-active="active" ng-show="toolbar.isBusinessAccountActive()"><a ui-sref="myMemberships.memberships" ng-click="toolbar.closeMenu()"><i class="fa fa-briefcase"></i>My Business</a></li>
          <li ng-if="toolbar.isMobile" class="separator"><span></span></li>
          <li ui-sref-active="active"><a ui-sref="marketplace" ng-click="toolbar.closeMenu()"><i class="fa fa-shopping-bag"></i>Marketplace</a></li>

          <li ng-if="toolbar.isMobile" class="separator"><span></span></li>
          <li ng-if="toolbar.isMobile"><a ui-sref="account.profile" ng-click="toolbar.closeMenu()"><i class="fa fa-info"></i>Account Details</a></li>
          <li ng-if="toolbar.isMobile && toolbar.isBusinessAccountActive()""><a ui-sref="account.billing" ng-click="toolbar.closeMenu()"><i class="fa fa-credit-card"></i>Billing Information</a></li>
          <li ng-if="toolbar.isMobile"><a ui-sref="account.earnings" ng-click="toolbar.closeMenu()"><i class="fa fa-money"></i>Earnings</a></li>
          <li ng-if="toolbar.isMobile && !toolbar.isBusinessAccountActive()">
            <a ui-sref="myMemberships" ng-click="toolbar.closeMenu()">
              <i class="fa fa-briefcase"></i>
            Business Account
            <span ng-if="toolbar.trialData.isEligibleForTrial" class="evaluation-notification">Evaluate</span>
            </a>
          </li>
          <li ng-if="toolbar.isMobile && toolbar.isBusinessAccountActive()"><a ui-sref="myMembershipsRewardProgramsSetup" ng-click="toolbar.closeMenu()"><i class="fa fa-book"></i>Rewards Program</a></li>
          <li ng-if="toolbar.isMobile" class="separator"><span></span></li>
          <li ng-if="toolbar.isMobile">
            <a class="cursor-pointer" ng-click="toolbar.openPreferences();"><i class="fa fa-sliders"></i>Preferences</a>
          </li>
          <li ng-if="toolbar.isMobile">
            <a class="cursor-pointer" ng-click="toolbar.openFeedback();"><i class="fa fa-commenting"></i>Send Feedback</a>
          </li>
          <li ng-if="toolbar.isMobile">
            <a class="cursor-pointer" target="_blank" href="http://www.myleadpod.com/faq"><i class="fa fa-question"></i>FAQs</a>
          </li>
          <li ng-if="toolbar.isMobile" class="separator"><span></span></li>
          <li ng-if="toolbar.isMobile">
            <a class="cursor-pointer" ng-click="toolbar.signOut()"><i class="fa fa-power-off"></i>Logout</a>
          </li>
        </ul>

        <div class="searchbar-container">
          <search-bar node-relocate alt-element-id="searchbar-alt" alt-element-breakpoint="1024"></search-bar>
        </div>


        <!--<ul class="nav navbar-nav notifications-nav">-->
          <!--<li class="dropdown" node-relocate alt-element-id="notification-bell-alt" alt-element-breakpoint="1024"-->
              <!--uib-dropdown-->
              <!--auto-close="outsideClick"-->
              <!--on-toggle="toolbar.toggleNotificationCenter(open)">-->
            <!--<a href="#" class="cursor-pointer dropdown-toggle notifications-bell"-->
               <!--ng-class="{'has-notifications': toolbar.hasNotifications}"-->
               <!--data-toggle="dropdown"-->
               <!--role="button"-->
               <!--aria-haspopup="true"-->
               <!--aria-expanded="false" uib-dropdown-toggle>-->
              <!--<span class="fa fa-bell" data-n-count="{{toolbar.notificationsCount}}"></span>-->
            <!--</a>-->
            <!--<ul uib-dropdown-menu role="menu" class="dropdown-menu slide-css" ng-class="{'slide': toolbar.isNCopen}">-->
              <!--<notification-center></notification-center>-->
            <!--</ul>-->
          <!--</li>-->
        <!--</ul>-->

        <ul ng-if="toolbar.isDesktop" class="nav navbar-nav navbar-right">
          <li class="dropdown" uib-dropdown on-toggle="toolbar.toggleProfile(open)">
            <a href="#" class="navbar-user-container dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
               aria-expanded="false" uib-dropdown-toggle>
              <div class="no-select" style="position: relative; margin-top: 3px;">
                <span user-avatar size="small" user="toolbar.getUserProfile()" is-link="false"></span>
                <span class="navbar-user-container-name">{{toolbar.userProfile.fullName}}</span>
              </div>
              <span class="caret"></span>
            </a>
            <ul uib-dropdown-menu role="menu" class="dropdown-menu slide-css" ng-class="{'slide': toolbar.isProfileOpen}">
              <li><a ui-sref="account.profile" ng-click="toolbar.closeMenu()">Account Details</a></li>
              <li><a ng-show="toolbar.isBusinessAccountActive()" ui-sref="account.billing" ng-click="toolbar.closeMenu()">Billing Information</a></li>
              <li><a ui-sref="account.earnings" ng-click="toolbar.closeMenu()">Earnings</a></li>
              <li>
                <a ng-show="!toolbar.isBusinessAccountActive()" ui-sref="myMemberships.memberships" ng-click="toolbar.closeMenu()">Business Account
                  <span ng-if="toolbar.trialData.isEligibleForTrial" class="evaluation-notification">Evaluate</span>
                </a>
              </li>
              <li><a ng-show="toolbar.isBusinessAccountActive()" ui-sref="myMembershipsRewardProgramsSetup" ng-click="toolbar.closeMenu()">Rewards Program</a></li>
              <li role="separator" class="divider"></li>
              <li><a class="cursor-pointer" ng-click="toolbar.openPreferences()">Preferences</a></li>
              <li><a class="cursor-pointer" ng-click="toolbar.openFeedback()">Send Feedback</a></li>
              <li><a class="cursor-pointer" target="_blank" href="http://www.myleadpod.com/faq">FAQs</a></li>
              <li role="separator" class="divider"></li>
              <li><a class="cursor-pointer" ng-click="toolbar.signOut()">Logout</a></li>
            </ul>
          </li>
        </ul>
      </div>
      <!-- End navbar-collapse -->

    </div>
  </nav>

  <!-- Display the "register business suggestion" in case he is not a business. The directive handles the check with the local storage -->
    <business-suggestion ng-if="toolbar.isAuthenticated() && (!toolbar.isBusinessAccountActive() || (toolbar.isBusinessAccountActive() && toolbar.trialData.isInTrial && toolbar.trialData.trialWarning))"></business-suggestion>
</div>
