(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name account.controller:AccountCtrl
   *
   * @description
   *
   */
  angular
    .module('account')
    .controller('AccountCtrl', AccountCtrl);

  function AccountCtrl(Session) {
    var vm = this;

    Session.getUserProfile()
      .then(function (profile) {
        if (profile) {
          vm.userProfile = profile;
        }
      });

    vm.tabManager = null;
    vm.tabsConfig = [
      {
        label: 'Profile',
        value: 'profile',
        url: 'account.profile'
      },
      {
        label: 'Billing',
        value: 'billing',
        url: 'account.billing',
        condition: function () {
          return vm.userProfile.isBusinessAccountActive;
        }
      },
      {
        label: 'Earnings',
        value: 'earnings',
        url: 'account.earnings'
      }
    ];
  }
}());
