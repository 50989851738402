(function () {
  'use strict';

  /* @ngdoc object
   * @name mlp-notifications
   * @description
   *
   */
  angular
    .module('mlp-notifications', []);
}());
