(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name components.factory:Tabs
   *
   * @description
   *
   */
  angular
    .module('components')
    .factory('Tabs', Tabs);

  function Tabs() {
    var TabsBase = {};

    function TabsManager(id, tabNames, activeTab) {
      var self = this;
      self.activeTab = activeTab;
      self.tabNames = tabNames;
      self.id = id;

      self.openTab = function (tabName) {
        if (self.activeTab !== tabName) {
          self.activeTab = tabName;
        }
      };

      self.isOpen = function (tabName) {
        return self.activeTab === tabName;
      };
    }

    TabsBase.create = function (id, tabNames, activeTab, $scope) {
      if (!angular.isArray(tabNames) || tabNames.length < 1) {
        throw new Error('List of names must not be empty');
      }

      if (angular.isUndefined(activeTab) && angular.isDefined($scope.tab)) {
        activeTab = $scope.tab;
      }

      return new TabsManager(id, tabNames, activeTab);
    };

    return TabsBase;
  }
}());
