(function () {
  'use strict';

  angular
    .module('dashboard.pod')
    .config(config);

  function config() {
  }
}());
