<div class="row">
  <div class="col-xs-12">
    <h1 class="page-header">My Account</h1>
  </div>
</div>

<div class="row">
  <div class="col-md-12">
    <tabs-manager tab-id="account" tabs-config="account.tabsConfig" default-tab="profile" tab-manager="account.tabManager"></tabs-manager>
  </div>
</div>

<div class="row" ng-if="account.tabManager.isOpen('profile')" ng-include="'account/profile/profile.tpl.html'">
</div>

<div class="row" ng-if="account.tabManager.isOpen('earnings')" ng-include="'account/earnings/earnings.tpl.html'">
</div>

<div class="row" ng-if="account.tabManager.isOpen('billing')" ng-include="'account/billing/billing.tpl.html'">
</div>
