<div id="reward-program-setup-ctrl-container">
  <div class="row">
    <div class="col-xs-12">
      <h1 class="page-header">Rewards Program</h1>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12">
      <div class="page-content page-content-no-tabs form-group">
        <uib-alert ng-repeat="alert in rewardProgramsSetup.alerts.getList()" type="{{alert.type}}" close="rewardProgramsSetup.alerts.remove($index)">{{alert.message}}</uib-alert>
        <form name="rewardProgramSetupForm" class="reward-program-form">
          <div class="row">
            <div class="col-xs-12 form-group">
              Add new, modify or remove rewards.
              <i class="no-select info-help" role="button" tabIndex="0" style="margin-left: 15px;"
                 popover-trigger="click, outsideClick"
                 popover-append-to-body="true"
                 popover-class="help"
                 uib-popover="{{rewardProgramsSetup.rewardProgramHelp}}">?</i>
            </div>
          </div>



          <div class="row" ng-if="rewardProgramsSetup.data.options.length < 1">
            <div class="col-xs-12">
              <div class="page-empty-section text-center">
                <i class="fa fa-users fa-3x text-light-colour"></i>
                <div class="page-empty-section-text">
                  No rewards.
                </div>
                <div class="page-empty-section-button">
                  <button type="button" class="btn btn-default" ng-click="rewardProgramsSetup.addOption()">
                    <i class="fa fa-plus"></i> Add reward
                  </button>
                </div>
              </div>
            </div>
          </div>

<div class="rewards-table-container">
        <div class="rewards-table table-responsive table-container">
          <table class="table">
            <thead class="hidden-xs">
            <tr>
              <th class="col-sm-8">Title</th>
              <th class="col-sm-3">Successful Lead Reward</th>
              <th class="col-sm-1"></th>
            </tr>
            </thead>
            <tbody>
              <tr class="animate-repeat" ng-repeat="option in rewardProgramsSetup.data.options track by option.uiId"
                  ng-class="{'has-error': (rewardProgramSetupForm.$submitted ||
                                           rewardProgramSetupForm.name{{option.uiId}}.$touched ||
                                           rewardProgramSetupForm.price{{option.uiId}}.$touched ||
                                           rewardProgramSetupForm.name2{{option.uiId}}.$touched ||
                                           rewardProgramSetupForm.price2{{option.uiId}}.$touched) &&
                                          (rewardProgramSetupForm.name{{option.uiId}}.$error.required ||
                                           rewardProgramSetupForm.name2{{option.uiId}}.$error.required ||
                                           rewardProgramSetupForm.price{{option.uiId}}.$error.required ||
                                           rewardProgramSetupForm.price2{{option.uiId}}.$error.required),
                             'has-edits': !rewardProgramSetupForm.name{{option.uiId}}.$pristine ||
                                       !rewardProgramSetupForm.price{{option.uiId}}.$pristine ||
                                       !rewardProgramSetupForm.name2{{option.uiId}}.$pristine ||
                                       !rewardProgramSetupForm.price2{{option.uiId}}.$pristine}">



                <td class="visible-xs reward-xs-content no-select"
                    ng-class="{'open':rewardProgramsSetup.tableSubContentVisible[$index]}">

                  <!-- xs content header -->
                  <div class="table-cell-main-content" ng-click="rewardProgramsSetup.toggleSubContent($index);">
                    <div class="reward-xs-title">
                      <div class="rewards-xs-warning"
                          ng-show="!rewardProgramsSetup.data.options[$index].name || !rewardProgramsSetup.data.options[$index].successfulLeadPrice">
                        Needs more details ...
                      </div>
                      <div class="reward-xs-name">{{rewardProgramsSetup.data.options[$index].name}}</div>
                      <div class="reward-xs-price"> $ {{rewardProgramsSetup.data.options[$index].successfulLeadPrice}}</div>
                    </div>
                    <div class="reward-action-content">
                      <div class="reward-action-field">
                        <a class="remove-icon cursor-pointer" ng-click="rewardProgramsSetup.deleteOption($index, rewardProgramSetupForm)" ng-if="rewardProgramsSetup.data.options.length > 1"><i class="fa fa-times"></i></a>
                      </div>
                      <!--<div class="reward-action-field">
                        <span class="cursor-pointer" >
                          <i class="fa fa-ellipsis-h"></i>
                        </span>
                      </div>-->
                    </div>
                  </div>

                  <!-- xs content container -->
                  <div class="table-cell-sub-content-container slide-css" ng-class="{slide: rewardProgramsSetup.tableSubContentVisible[$index]}">

                    <div class="table-cell-sub-content"
                         ng-class="{'has-error': (rewardProgramSetupForm.$submitted ||
                                    rewardProgramSetupForm.name2{{option.uiId}}.$touched) &&
                                    rewardProgramSetupForm.name2{{option.uiId}}.$error.required}">

                      <div ng-show="rewardProgramSetupForm.$submitted || rewardProgramSetupForm.name2{{option.uiId}}.$touched">
                        <div class="input-error" ng-show="rewardProgramSetupForm.name2{{option.uiId}}.$error.required">Missing name</div>
                      </div>
                      <div class="left-inner-addon">
                        <i class="fa fa-pencil inner-addon"></i>
                        <input class="form-control input-lg" type="text" name="name2{{option.uiId}}" id="name2{{option.uiId}}"
                              placeholder="Name" ng-required="true"
                              ng-model="rewardProgramsSetup.data.options[$index].name" />
                      </div>
                    </div>


                    <div class="table-cell-sub-content"
                         ng-class="{'has-error': (rewardProgramSetupForm.$submitted ||
                                    rewardProgramSetupForm.price2{{option.uiId}}.$touched) &&
                                    rewardProgramSetupForm.price2{{option.uiId}}.$error.required}">

                      <div ng-show="rewardProgramSetupForm.$submitted || rewardProgramSetupForm.price2{{option.uiId}}.$touched">
                        <div class="input-error" ng-show="rewardProgramSetupForm.price{{option.uiId}}.$error.number">Invalid lead reward value</div>
                        <div class="input-error" ng-show="rewardProgramSetupForm.price{{option.uiId}}.$error.required">Missing lead reward value</div>
                        <div class="input-error" ng-show="rewardProgramSetupForm.price{{option.uiId}}.$error.min">Successful lead reward value must not be negative</div>
                      </div>
                      <div class="left-inner-addon">
                        <i class="fa fa-dollar inner-addon"></i>
                        <input class="form-control input-lg" type="number" name="price2{{option.uiId}}" id="price2{{option.uiId}}"
                            placeholder="Successful lead reward" ng-required="true" min="0"
                            ng-model="rewardProgramsSetup.data.options[$index].successfulLeadPrice" />
                      </div>
                    </div>
                  </div>
                </td>

                <td class="hidden-xs table-cell primary-cell" ng-class="{'has-error': (rewardProgramSetupForm.$submitted || rewardProgramSetupForm.name{{option.uiId}}.$touched) && (rewardProgramSetupForm.name{{option.uiId}}.$error.required)}">
                  <div ng-show="rewardProgramSetupForm.$submitted || rewardProgramSetupForm.name{{option.uiId}}.$touched">
                    <div class="input-error" ng-show="rewardProgramSetupForm.name{{option.uiId}}.$error.required">Missing name</div>
                  </div>
                  <div class="left-inner-addon">
                    <i class="fa fa-pencil inner-addon"></i>
                    <input class="form-control input-lg" type="text" name="name{{option.uiId}}" id="name{{option.uiId}}"
                          placeholder="Name" ng-required="true"
                          ng-model="rewardProgramsSetup.data.options[$index].name" />
                  </div>
                </td>


                <td class="hidden-xs table-cell" ng-class="{'has-error': (rewardProgramSetupForm.$submitted || rewardProgramSetupForm.price{{option.uiId}}.$touched) && (rewardProgramSetupForm.price{{option.uiId}}.$error.required || rewardProgramSetupForm.price{{option.uiId}}.$error.number || rewardProgramSetupForm.price{{option.uiId}}.$error.min)}">
                  <div ng-show="rewardProgramSetupForm.$submitted || rewardProgramSetupForm.price{{option.uiId}}.$touched">
                    <div class="input-error" ng-show="rewardProgramSetupForm.price{{option.uiId}}.$error.number">Invalid lead reward value</div>
                    <div class="input-error" ng-show="rewardProgramSetupForm.price{{option.uiId}}.$error.required">Missing lead reward value</div>
                    <div class="input-error" ng-show="rewardProgramSetupForm.price{{option.uiId}}.$error.min">Successful lead reward value must not be negative</div>
                  </div>
                  <div class="left-inner-addon">
                    <i class="fa fa-dollar inner-addon"></i>
                    <input class="form-control input-lg" type="number" name="price{{option.uiId}}" id="price{{option.uiId}}"
                         placeholder="Successful lead reward" ng-required="true" min="0"
                         ng-model="rewardProgramsSetup.data.options[$index].successfulLeadPrice" />
                  </div>
                </td>

                <td class="hidden-xs table-cell">
                  <a class="remove-icon cursor-pointer" ng-click="rewardProgramsSetup.deleteOption($index, rewardProgramSetupForm)" ng-if="rewardProgramsSetup.data.options.length > 1"><i class="fa fa-times"></i></a>
                </td>

              </tr>
            </tbody>
          </table>
        </div>
</div>

          <div class="row">
            <div class="col-xs-12 text-xs-center buttons-apart" ng-if="rewardProgramsSetup.data.options.length > 0">
              <button type="button" class="btn btn-success" ng-click="rewardProgramsSetup.addOption()">
                <i class="fa fa-plus"></i> Add reward
              </button>
              <button type="button" class="btn btn-primary"
                      ng-disabled="rewardProgramSetupForm.$pristine || rewardProgramSetupForm.$invalid || rewardProgramsSetup.isLoading" ng-click="rewardProgramsSetup.save()">
                {{rewardProgramsSetup.isLoading ? 'Saving ...' : 'Save'}}
              </button>
            </div>
          </div>

        </form>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 text-center">
            <span class="sub-title-description" style="margin-left: 12px;">
              To create or view your Reward Codes, go to <a ui-sref="myMemberships.rewardPrograms" class="cursor-pointer">Reward Codes</a> page.
            </span>
        </div>
      </div>
    </div>
  </div>
</div>
