(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name account.preferences.controller:PreferencesCtrl
   *
   * @description
   *
   */
  angular
    .module('account.preferences')
    .controller('PreferencesCtrl', PreferencesCtrl);

  function PreferencesCtrl($uibModalInstance, Session, Notification, Restangular, FormAlerts, userProfile) {
    var vm = this;
    vm.alerts = new FormAlerts();
    vm.isLoading = true;
    vm.isSaving = false;
    vm.userProfile = userProfile;
    vm.data = {
      mailingLists: {},
      marketplace: {}
    };

    vm.updatePreferences = function () {
      if (!vm.userProfile) {
        return;
      }
      if (vm.userProfile.preferences && vm.userProfile.preferences.mailingLists) {
        vm.data.mailingLists = vm.userProfile.preferences.mailingLists;
      }

      if (vm.userProfile.preferences && vm.userProfile.preferences.marketplace) {
        vm.data.marketplace = vm.userProfile.preferences.marketplace;
      }
      vm.dataBkp = angular.copy(vm.data);
    };

    vm.changeSettings = function (key, value) {
      var lastProperty = null;
      key = key.split('.');
      if (key.length === 1) {
        vm.data[key[0]] = value;
      }
      else {
        lastProperty = vm.data;
        angular.forEach(key, function (keyName, index, array) {
          if (index === array.length - 1) {
            // This is the last key in the list.
            lastProperty[keyName] = value;
          }
          else if (_.has(lastProperty, keyName)) {
            // Key already exists in the data
            lastProperty = lastProperty[keyName];
          }
          else {
            // There is no such key, create one.
            lastProperty = lastProperty[keyName] = {};
          }
        });
      }
      vm.hasChanges = !_.isEqual(vm.data, vm.dataBkp);
    };

    vm.cancel = function () {
      $uibModalInstance.dismiss();
    };

    vm.saveChanges = function () {
      vm.isSaving = true;
      vm.alerts.reset();
      Restangular.one('Preferences').customPUT(vm.data, 'index')
        .then(function (preferences) {
          if (preferences) {
            vm.userProfile.preferences = preferences.plain();
            Session.updateLocalUserProfile(vm.userProfile, false);
          }
          vm.isSaving = false;
          Notification({message: 'Success'}, 'confirmation');
          $uibModalInstance.close();
        }, function () {
          vm.isSaving = false;
          vm.alerts.add('danger', 'Unable to save the changes.');
        });
    };

    // init
    if (!vm.userProfile) {
      Session.getUserProfile().then(function (up) {
        vm.userProfile = up;
        vm.updatePreferences(vm.userProfile);
      });
    }
    else {
      vm.updatePreferences(vm.userProfile);
    }
  }
}());
