(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name myMemberships.rewardProgram.controller:RewardCodeDetailCtrl
   *
   * @description
   *
   */
  angular
    .module('myMemberships.rewardProgram')
    .controller('RewardCodeDetailCtrl', RewardCodeDetailCtrl);

  function RewardCodeDetailCtrl($timeout, $interpolate, $uibModalInstance, SocialShare, BitlyUrlShortener, Restangular, Notification, configOptions, RewardCodes, rewardCode) {
    var vm = this;
    vm.rewardCode = _.clone(rewardCode);
    // vm.rewardCode.originalUserShare = rewardCode.userShare;
    vm.rewardCode.originalDescription = rewardCode.description;
    vm.data = {
      description: vm.rewardCode.description,
      userShareEmail: ''
    };

    // Associated Users
    vm.associatedUsers = vm.rewardCode.associatedUsers || [];
    vm.associatedUsersClone = _.clone(rewardCode.associatedUsers || []);

    vm.userShareEmailHelp = 'Type in the email address of another MyLeadPod user you want to share the Reward Code with.';
    vm.isCopyToClipboardSuccessful = false;
    vm.editDescriptionVisible = false;
    vm.editUserShareVisible = false;
    vm.editAssociatedUsersVisible = false;
    vm.isSavingDescription = false;
    vm.isSavingUserShare = false;
    vm.userShareEmailChecked = false;
    vm.userShareEmailValid = false;
    vm.userShareEmailValidating = false;
    vm.isArchiving = false;
    vm.shortUrl = null;
    vm.emailBody = null;

    vm.searchCategories = [{
      name: 'Directory',
      value: 'businessUsers',
      stateTarget: 'user',
      iconClass: 'fa-briefcase'
    }];

    if (angular.isDefined(rewardCode.userShare) && angular.isObject(rewardCode.userShare)) {
      vm.data.userShareEmail = rewardCode.userShare.email;
      vm.userShareEmailValid = true;
      vm.userShareEmailChecked = true;
    }

    vm.close = function () {
      $uibModalInstance.close({
        updatedRecord: vm.rewardCode
      });
    };

    vm.archive = function () {
      vm.isArchiving = true;
      RewardCodes.archive(rewardCode.id)
        .then(function (result) {
          vm.rewardCode = result;
          rewardCode = result;
          vm.isArchiving = false;
        }, function () {
          vm.isArchiving = false;
        });
    };

    vm.getUrl = function () {
      return RewardCodes.getRewardCodeUrl(rewardCode.rewardCode);
    };

    vm.share = function (socialService) {
      SocialShare.share(socialService, {
        url: vm.shortUrl,
        name: configOptions.defaultShareName,
        description: configOptions.defaultShareMessage,
        source: 'MyLeadPod',
        picture: configOptions.appUrl + '/images/touch-icon-192x192.png'
      });
    };

    vm.saveDescription = function () {
      vm.isSavingDescription = true;
      vm.editDescriptionError = false;
      RewardCodes.update(rewardCode.id, {
        description: vm.data.description
      })
        .then(function (result) {
          vm.rewardCode = result;
          rewardCode = result;
          vm.isSavingDescription = false;
          vm.editDescriptionVisible = false;
          vm.rewardCode.originalDescription = rewardCode.description;
        }, function () {
          vm.isSavingDescription = false;
          vm.editDescriptionError = 'An error occurred';
        });
    };

    vm.openUserShare = function () {
      vm.editUserShareVisible = true;
      console.log(angular.element('#share-autocomplete_value'));
      $timeout(function () {
        angular.element('#share-autocomplete_value')[0].focus();
      }, 200);
    };

    vm.saveUserShare = function (userMsg) {
      vm.isSavingUserShare = true;
      vm.editUserShareError = false;

      vm.searchingUser = userMsg || 'Saving the changes...';
      return RewardCodes.update(rewardCode.id, {
        userShareEmail: vm.data.userShareEmail
      })
        .then(function (result) {
          vm.rewardCode = result;
          rewardCode = result;
          vm.editUserShareVisible = false;
          vm.isSavingUserShare = false;
          vm.rewardCode.originalDescription = rewardCode.description;

          // MGA - #37545
          vm.searchingUser = false;
          vm.editUserShareVisible = false;
        }, function () {
          vm.isSavingUserShare = false;
          vm.editUserShareError = 'An error occurred';

          // MGA - #37545
          vm.searchingUser = false;
          vm.editUserShareVisible = false;
        });
    };

    vm.removeUserShare = function () {
      vm.data.userShareEmail = '';
      vm.saveUserShare('Removing sharing with the user..').then(function () {
        vm.rewardCode.userShare = null;
      }, function () {
        // Do what?
        vm.data.userShareEmail = vm.rewardCode.userShare.email;
      });
    };

    vm.isUserShareEmailValid = function () {
      if (vm.data.userShareEmail.length === 0) {
        return true;
      }

      return vm.userShareEmailValid;
    };

    vm.onSearchSharedItemSelected = function (item) {
      if (!item) {
        return;
      }
      vm.searchingUser = 'Retrieving the user profile';
      Restangular.one('Users').one('profile').get({id: item.originalObject.id})
        .then(function (profile) {
          if (!profile) {
            return;
          }
          profile = profile.plain();
          vm.data.userShareEmail = profile.email;
          vm.saveUserShare().then(function () {
            vm.rewardCode.userShare = profile;
          });
        }, function () {
          vm.searchingUser = false;
        });
    };

    vm.searchResponseFormatter = function (response) {
      var r,
          records;

      if (angular.isUndefined(response) || !response) {
        return null;
      }
      vm.emptyResults = false;
      r = response.records;
      if (r.length === 0) {
        vm.emptyResults = true;
      }

      records = _.map(r, function (item) {
        var category = _.find(vm.searchCategories, {value: item.datasource}),
            returnObject = {},
            categoryDescriptionClass,
            value = item.fullname;

        categoryDescriptionClass = category.iconClass || 'fa-user';
        if (item.companyname && item.companyname !== item.fullname) {
          value += ' | ' + item.companyname;
        }

        returnObject = {
          value: value,
          id: item.id,
          category: item.datasource,
          categoryDescription: '<i class="fa ' + categoryDescriptionClass + '"></i>',
          timeDescription: item.datecreatedformatted ? item.datecreatedformatted : '',
          leadtype: item.leadtype
        };

        return returnObject;
      });

      return records;
    };

    /**
     * Copy success callback
     */
    vm.onSuccess = function () {
      Notification({message: 'Copied into clipboard!'}, 'success');
    };

    vm.hasEditsAssociatedUsers = function () {
      var diff1 = _.difference(vm.associatedUsersClone, vm.associatedUsers),
          diff2 = _.difference(vm.associatedUsers, vm.associatedUsersClone);
      return diff1.length > 0 || diff2.length > 0 ? true : false;
    };

    vm.openAssociatedUser = function () {
      vm.editAssociatedUsersVisible = true;
      $timeout(function () {
        angular.element('#associated-autocomplete_value')[0].focus();
      }, 200);
    };

    vm.addAssociatedUser = function (userProfile) {
      if (_.where(vm.associatedUsers, {idUser: userProfile.idUser}).length === 0) {
        vm.associatedUsers.push(userProfile);
      }
    };

    vm.onAssociatedItemSelected = function (item) {
      if (!item) {
        return;
      }
      vm.searchingAssociatedUser = 'Retrieving the user profile';
      Restangular.one('Users').one('profile').get({id: item.originalObject.id})
        .then(function (profile) {
          if (!profile) {
            return;
          }
          profile = profile.plain();
          vm.addAssociatedUser(profile);
          vm.searchingAssociatedUser = false;
        }, function () {
          vm.searchingAssociatedUser = false;
        });
    };

    vm.onAssociatedUsersCancel = function () {
      vm.editAssociatedUsersVisible = false;
      vm.associatedUsers = _.clone(vm.associatedUsersClone);
    };

    vm.saveAssociatedUsers = function () {
      vm.isSavingAssociatedUsers = true;
      vm.editAssociatedUsersError = false;

      vm.searchingAssociatedUser = 'Saving the changes...';
      return RewardCodes.update(rewardCode.id, {
        associatedUsers: _.map(vm.associatedUsers, function (user) {
          return user.id;
        })
      })
        .then(function (result) {
          vm.rewardCode = result;
          rewardCode = result;
          vm.associatedUsers = vm.rewardCode.associatedUsers || [];
          vm.associatedUsersClone = _.clone(rewardCode.associatedUsers || []);
          vm.editAssociatedUsersVisible = false;
          vm.isSavingAssociatedUsers = false;
          vm.rewardCode.originalDescription = rewardCode.description;

          // MGA - #37545
          vm.searchingAssociatedUser = false;
          vm.editAssociatedUsersVisible = false;
        }, function () {
          vm.editAssociatedUsersError = false;
          vm.editUserShareError = 'An error occurred';

          // MGA - #37545
          vm.searchingAssociatedUser = false;
          vm.editAssociatedUsersVisible = false;
        });
    };

    vm.removeAssociatedUser = function ($index) {
      vm.associatedUsers.splice($index, 1);
      vm.editAssociatedUsersVisible = true;
    };

    vm.embed = function () {
      RewardCodes.openEmbed(rewardCode);
    };

    BitlyUrlShortener.shorten(vm.getUrl())
      .then(function (result) {
        var exp = $interpolate(configOptions.defaultEmailMessage);
        vm.shortUrl = result;
        vm.emailBody = encodeURIComponent(exp({link: vm.shortUrl}));
      });
  }
}());
