(function () {
  'use strict';

  angular
    .module('user.messages')
    .config(config);

  function config($stateProvider, configOptions) {
    $stateProvider
      .state('user.messages', angular.extend({}, configOptions.defaultStateParams, {
        url: '/messages',
        templateUrl: 'user/messages/messages.tpl.html',
        controller: 'MessagesCtrl',
        controllerAs: 'msg',
        onEnter: ['$rootScope', '$timeout', '$window', '$document', 'RouterHelper', 'notificationConst', 'userProfile', function ($rootScope, $timeout, $window, $document, RouterHelper, notificationConst, userProfile) {
          RouterHelper.activateTab('user', 'messages');
          // $rootScope.$broadcast('event:slider-do-layout', {delay: 100, stepTo: 0});
          $rootScope.$broadcast('event:slider-do-layout', {delay: 100});

          // Broadcast to all scroll-navigators to do the check. Not ideal but I don't know how to target only those on this relevant page.
          $rootScope.$broadcast('event:check-scroll-navigator', 'all', 1000);

          // Broadcast to the NotificationCentre to check any notifications related to messages for this user.
          $rootScope.$broadcast('event:notifications-check', {
            userId: userProfile.idUser,
            category: notificationConst.categoryMessages,
            type: notificationConst.typeDirectMessage
          });
        }],
        onExit: ['$rootScope', function ($rootScope) {
          $rootScope.$broadcast('event:check-scroll-navigator', 'all', 200);
        }]
      }));
  }
}());
