<div class="modal-content-container business-account">
  <div class="row">
    <div class="col-xs-12">
      <div class="row">
        <div class="col-xs-12  modal-content-title">
          <span class="sub-title">Activate Business Account</span>
        </div>
      </div>

      <uib-alert ng-repeat="alert in businessAccount.alerts.getList()" type="{{alert.type}}" close="businessAccount.alerts.remove($index)">{{alert.message}}</uib-alert>

      <div class="row">
        <div class="col-xs-12">
          <div class="steps">

            <div class="step" data-step-id="0">
              Company Details
            </div>
            <div class="step-separator no-animate"
                 data-after-step-id="0">
              <span class="fa fa-chevron-right"></span>
            </div>

            <div class="step" data-step-id="1">
              Subscription Type
            </div>
            <div class="step-separator" data-after-step-id="1">
              <span class="fa fa-chevron-right"></span>
            </div>

            <div class="step" data-step-id="2" ng-if="!businessAccount.hasRewardCode">
              Reward Code
            </div>
            <div class="step-separator no-animate" data-after-step-id="2" ng-if="!businessAccount.hasRewardCode">
              <span class="fa fa-chevron-right"></span>
            </div>

            <div class="step no-animate"
                 data-step-id="3">
              Payment Details
            </div>
          </div>
        </div>
      </div>


      <form name="businessAccountForm">

      <!-- Step container -->
      <div class="stepped-wrapper" content-slider>
        <div class="stepped-container">

          <!-- Business Details -->
          <div class="step-content" data-step-id="0">
            <div class="row">
              <div class="col-xs-12">
                <h4>Company Details</h4>
              </div>
            </div>
            <div class="row form-section-body">
              <div class="col-xs-12 col-sm-12">
                <div class="form-group"
                     ng-class="{'has-error': (businessAccountForm.$submitted || businessAccountForm.businessName.$touched) && businessAccountForm.businessName.$error.required}">
                  <label class="sr-only" for="businessName">Company / Organisation</label>
                  <div ng-show="businessAccountForm.$submitted || businessAccountForm.businessName.$touched">
                    <div class="input-error" ng-show="businessAccountForm.businessName.$error.required">Company /
                      Organisation is a required field.
                    </div>
                  </div>
                  <div class="left-inner-addon">
                    <i class="fa fa-briefcase inner-addon"></i>
                    <input class="form-control input-lg" type="text" name="businessName" id="businessName"
                           placeholder="Company / Organisation Name"
                           ng-required="true"
                           ng-model="businessAccount.data.businessName" />
                  </div>
                </div>
              </div>

              <div class="col-xs-12 col-sm-12">
                <div class="form-group" ng-class="{'has-error': (businessAccountForm.$submitted || businessAccountForm.industry.$touched) && businessAccountForm.industry.$error['autocomplete-required']}">
                  <label class="sr-only" for="industry">Classification</label>
                  <div ng-show="businessAccountForm.$submitted || businessAccountForm.industry.$touched">
                    <div class="input-error" ng-show="businessAccountForm.industry.$error['autocomplete-required']">Classification is a required field.
                    </div>
                  </div>
                  <div class="left-inner-addon">
                    <i class="fa fa-folder-o inner-addon"></i>
                    <angucomplete-alt id="industry"
                                      placeholder="Classification"
                                      pause="100"
                                      selected-object="businessAccount.onClassificationSelect"
                                      local-data="businessAccount.industries"
                                      search-fields="name"
                                      title-field="name"
                                      minlength="2"
                                      match-class="autocomplete-highlight"
                                      input-class="form-control input-lg"
                                      input-name="industry"
                                      field-required="true"
                                      initial-value="businessAccount.selectedClassification"
                    />
                  </div>
                </div>
              </div>
            </div>


            <!-- Geolocation details -->
            <div class="row">
              <div class="col-xs-12">
                <label class="sr-only" for="location">Location</label>
                <div class="left-inner-addon location-input">
                  <i class="fa fa-map-marker inner-addon"></i>
                  <input class="form-control input-lg"
                         type="text"
                         name="location"
                         id="location"
                         placeholder="Address (type in or use Geolocation)"
                         ng-model="businessAccount.data.addressPostal.address"
                         ng-required="false"
                         location-search
                         input-id="location"
                         full-address="true"
                         place-changed-callback="businessAccount.placeChangedCallback" />
                  <a id="geolocateBtn" style="cursor: pointer;position: absolute;top: 3px;right: 3px;"
                     ng-disabled="businessAccount.isGeolocationDisabled"
                     ng-click="businessAccount.getUserLocationAddress()"
                     class="btn btn-primary">
                    <span class="fa fa-location-arrow no-animate" ng-if="!businessAccount.isGeolocating"></span>
                    <span class="fa fa-circle-o-notch fa-spin-2x no-animate" ng-if="businessAccount.isGeolocating"></span>
                  </a>
                  <div class="geolocation-disabled-msg slide-if" ng-if="businessAccount.isGeolocationDisabled">
                    There was an issue while trying to find your location. <br />
                    Make sure the Location is allowed on your device and try again, or type in your address.
                  </div>
                </div>
              </div>
            </div>

            <!-- Address static image
            <div class="row">
              <div class="col-xs-12 ">
                <div class="location-screenshot">
                  <div class="pending-overlay" ng-if="businessAccount.isRenderingLocation">
                    <span class="fa fa-circle-o-notch fa-spin-2x no-animate"></span>
                  </div>
                  <img src="" />
                </div>
              </div>
            </div> -->

            <!-- Address Details -->
            <div class="row location-details slide-if" ng-if="businessAccount.data.addressPostal.addressPostal ||
                                                             businessAccount.data.addressPostal.suburbPostal ||
                                                             businessAccount.data.addressPostal.postCodePostal ||
                                                             businessAccount.data.addressPostal.idStatePostal ||
                                                             businessAccount.data.addressPostal.idCountryPostal">
              <div class="col-xs-12">
                <div class="address-container">
                  <div class="address-description">
                    Your current address is set to:
                  </div>
                  <div class="address-value">
                    {{businessAccount.data.addressPostal | address:'au'}}
                  </div>
                </div>
              </div>
            </div>

            <div class="row location-details missing-fields slide-if" ng-if="businessAccount.isAddressIncomplete()">
              <div class="col-xs-12">
                <div class="address-container missing-fields">
                  <div class="address-description">
                    <span ng-if="!businessAccount.data.addressPostal.addressPostal &&
                                 !businessAccount.data.addressPostal.suburbPostal &&
                                 !businessAccount.data.addressPostal.postCodePostal &&
                                 !businessAccount.data.addressPostal.idStatePostal &&
                                 !businessAccount.data.addressPostal.idCountryPostal">
                      We don't have any of your address information on file yet, please type in the address or use the Geolocation button and provide us with the following information
                    </span>
                    <span ng-if="businessAccount.data.addressPostal.addressPostal ||
                                 businessAccount.data.addressPostal.suburbPostal ||
                                 businessAccount.data.addressPostal.postCodePostal ||
                                 businessAccount.data.addressPostal.idStatePostal ||
                                 businessAccount.data.addressPostal.idCountryPostal">
                      Even though you have provided some address information, we still need you to provide us with the additional info
                    </span>
                  </div>
                  <div class="address-value">
                    <div class="missing-val slide-if" ng-if="!businessAccount.data.addressPostal.numberPostal">
                      Street Number
                    </div>
                    <div class="missing-val slide-if" ng-if="!businessAccount.data.addressPostal.addressPostal">
                      Street Name
                    </div>
                    <div class="missing-val slide-if" ng-if="!businessAccount.data.addressPostal.suburbPostal">
                      Suburb
                    </div>
                    <div class="missing-val slide-if" ng-if="!businessAccount.data.addressPostal.postCodePostal">
                      Postcode
                    </div>
                    <div class="missing-val slide-if" ng-if="!businessAccount.data.addressPostal.idStatePostal">
                      State
                    </div>
                    <div class="missing-val slide-if" ng-if="!businessAccount.data.addressPostal.idCountryPostal">
                      Country
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div class="step-buttons"
                 data-active-step-id="2">

              <button class="btn btn-primary no-animate"
                      data-step-to="1"
                      ng-click="businessAccount.onDataChange('name');"
                      ng-disabled="!businessAccount.data.businessName ||
                                   !businessAccount.data.idClassification ||
                                  !businessAccount.data.addressPostal.addressPostal ||
                                  !businessAccount.data.addressPostal.suburbPostal ||
                                  !businessAccount.data.addressPostal.postCodePostal ||
                                  !businessAccount.data.addressPostal.idStatePostal ||
                                  !businessAccount.data.addressPostal.idCountryPostal">
                Next
              </button>
            </div>
          </div>


          <!-- Subscription -->
          <div class="step-content" data-step-id="1">
            <div>
              <div class="row">
                <div class="col-xs-12">
                  <h4>Subscription</h4>
                </div>
              </div>

              <div class="row" style="white-space: normal;">
                <div class="col-xs-12">
                  <div class="form-group">
                    <span ng-if="businessAccount.pricingDetails && businessAccount.pricingDetails.length == 1">
                      Currently there is only one plan to choose from. Click 'Next' to continue.
                    </span>
                    <span ng-if="businessAccount.pricingDetails && businessAccount.pricingDetails.length > 1">
                      Select (click on) the subscription type:
                    </span>
                  </div>
                </div>

                <div class="col-xs-12 no-select subscription-selection form-group">
                  <label class="radio-inline" ng-repeat="p in businessAccount.pricingDetails" for="{{p.id}}" ng-class="{'active': businessAccount.data.businessMarketingPayment == p.id}">
                    <input type="radio" name="businessMarketingPayment"
                           id="{{p.id}}"
                           value="{{p.id}}"
                           ng-required="true"
                           ng-model="businessAccount.data.businessMarketingPayment"
                           ng-change="businessAccount.updateSummary()"/>
                    <div class="subscr-type">{{p.label}}</div>
                    <div class="subscr-desc" style="color:transparent;">(no description)</div>
                    <div class="subscr-value">{{p.priceInclGst | currency:'$':0}}</div>
                    <div class="subscr-desc" style="margin-top: -5px;">(GST incls.)</div>
                    <div class="subscr-check fa fa-check"></div>
                  </label>
                </div>
              </div>
            </div>

            <div class="step-buttons" data-active-step-id="1">
              <button class="btn btn-primary" data-step-to="0">
                Back
              </button>
              <button class="btn btn-primary" data-step-to="{{businessAccount.hasRewardCode? '3' : '2'}}"
                      ng-disabled="!businessAccount.data.businessMarketingPayment"
                      ng-click="businessAccount.onDataChange('name');businessAccount.updateSummary();">
                Next
              </button>
            </div>
          </div>


          <!-- Subscription - Reward Code / Lead Reward -->
          <div class="step-content" data-step-id="2">
            <div ng-if="!businessAccount.hasRewardCode">
              <div class="row form-group reward-selection">
                <div>
                    <div class="control-description">
                      Set up your own Reward Code:
                    </div>
                    <div class="form-group" ng-class="{'has-error': (businessAccountForm.$submitted || businessAccountForm.rewardCode.$touched) &&
                (businessAccountForm.rewardCode.$error.required || businessAccountForm.rewardCode.$error.maxlength || businessAccountForm.rewardCode.$error.minlength || (businessAccount.rewardCodeChecked && !businessAccount.rewardCodeValid))}">
                      <label class="sr-only" for="rewardCode">Reward Code</label>
                      <div ng-show="businessAccountForm.$submitted || businessAccountForm.rewardCode.$touched">
                        <div class="input-error" ng-show="businessAccountForm.rewardCode.$error.required">Missing Reward Code</div>
                        <div class="input-error" ng-show="businessAccountForm.rewardCode.$error.minlength">Reward Code must be at least 5 characters long</div>
                        <div class="input-error" ng-show="businessAccountForm.rewardCode.$error.maxlength">Reward Code must not be longer than 20 characters</div>
                        <div class="input-error" ng-show="businessAccount.rewardCodeChecked && !businessAccount.rewardCodeValid">This Reward Code is not available, try another one</div>
                      </div>
                      <div class="left-inner-addon">
                        <i ng-show="!businessAccount.rewardCodeChecked && !businessAccount.rewardCodeValidating && !businessAccount.rewardCodeValid" class="fa fa-hashtag"></i>
                        <i ng-show="businessAccount.rewardCodeValidating" class="fa fa-circle-o-notch fa-spin-2x"></i>
                        <i ng-show="!businessAccount.rewardCodeValidating && businessAccount.rewardCodeChecked && businessAccount.rewardCodeValid" class="fa fa-check code-container-valid"></i>
                        <i ng-show="!businessAccount.rewardCodeValidating && businessAccount.rewardCodeChecked && !businessAccount.rewardCodeValid" class="fa fa-times code-container-invalid"></i>
                        <input class="form-control input-lg" type="text" name="rewardCode" id="rewardCode"
                               placeholder="Your own Reward Code"
                               ng-minlength="5" ng-maxlength="20"
                               size="20"
                               maxlength="20"
                               ng-change="businessAccount.onRewardCodeChange(); businessAccount.onDataChange('code');"
                               ng-blur="businessAccount.validateRewardCode()"
                               ng-required="true"
                               ng-model="businessAccount.data.rewardCode"
                               popover-placement="'top'"
                               popover-trigger="focus"
                               popover-append-to-body="true"
                               popover-class="help"
                               uib-popover="{{businessAccount.rewardCodeHelp}}" />
                      </div>
                    </div>
                </div>

                <div>
                  <div class="control-description">
                    Set the Reward Code value (for successful lead):
                  </div>
                  <div class="form-group" ng-class="{'has-error': (businessAccountForm.$submitted || businessAccountForm.successfulLeadPrice.$touched) && (businessAccountForm.successfulLeadPrice.$error.required || businessAccountForm.successfulLeadPrice.$error.number || businessAccountForm.successfulLeadPrice.$error.min)}">
                    <label class="sr-only" for="successfulLeadPrice">Successful Lead Reward</label>
                    <div ng-show="businessAccountForm.$submitted || businessAccountForm.successfulLeadPrice.$touched">
                      <div class="input-error" ng-show="businessAccountForm.successfulLeadPrice.$error.number">Invalid successful lead value</div>
                      <div class="input-error" ng-show="businessAccountForm.successfulLeadPrice.$error.required">Missing successful lead value</div>
                      <div class="input-error" ng-show="businessAccountForm.successfulLeadPrice.$error.min">Successful lead value must not be negative</div>
                    </div>
                    <div class="left-inner-addon">
                      <i class="fa fa-dollar inner-addon"></i>
                      <input class="form-control input-lg"
                             type="number"
                             name="successfulLeadPrice"
                             id="successfulLeadPrice"
                             placeholder="Successful lead reward"
                             ng-required="true"
                             min="0"
                             ng-change="businessAccount.onDataChange('value');"
                             ng-model="businessAccount.data.successfulLeadPrice" />
                    </div>
                  </div>
                </div>
              </div>

              <div ng-show="businessAccount.showRewardCard">
                <div class="reward-selection" style="flex-wrap: nowrap;">
                  <div class="reward-card-image">
                    <object type="image/svg+xml" id="RewardCard-back" data="../images/reward-card-back.svg" tabindex="-1">
                    </object>
                  </div>
                  <div class="reward-card-image">
                    <object type="image/svg+xml" id="RewardCard-front" data="../images/reward-card-front.svg" tabindex="-1">
                    </object>
                  </div>
                </div>
                <div class="reward-card-warning">
                  * Reward cards are purchased separately. <br/>
                  For more information please contact the <a href="mailto:sales@myleadpod.com">sales support</a>.
                </div>
              </div>

              <div class="step-buttons"
                   data-active-step-id="1">
                <button class="btn btn-primary" data-step-to="1">
                  Back
                </button>
                <button class="btn btn-primary no-animate"
                        data-step-to="3"
                        ng-click="businessAccount.updateSummary();"
                        ng-disabled="businessAccount.rewardCodeValidating || !businessAccount.rewardCodeChecked || !businessAccount.data.rewardCode || !businessAccount.isLeadPriceValid()">
                  Next
                </button>
              </div>
            </div>
          </div>


          <!-- Payment details -->
          <div class="step-content" data-step-id="3">

            <div class="close-lead-invoice form-group">
              <div class="row">
                <div class="col-xs-12">
                  <h4>Payment Summary</h4>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-6">
                  Subscription fee
                </div>
                <div class="col-xs-6 text-right">
                  {{businessAccount.paymentSummary.price | currency:'$':2}}
                </div>
              </div>


              <div class="row">
                <div class="col-xs-6">
                  GST
                </div>
                <div class="col-xs-6 text-right">
                  {{businessAccount.paymentSummary.tax | currency:"$":2}}
                </div>
              </div>
              <div class="row" style="border-top: 1px lightgray dotted; padding-top: 5px; margin-top: 3px;">
                <div class="col-xs-6">
                  <strong>Total*</strong>
                </div>
                <div class="col-xs-6 text-right">
                  <strong>{{businessAccount.paymentSummary.total | currency:"$":2}}</strong>
                </div>
              </div>
              <div class="col-xs-12" style="color: lightgray;margin: 15px 0px;font-size: 12px;color: #676767;white-space: normal;" >
                * You will be periodically charged this amount based on the chosen subscription type
              </div>
            </div>

            <div class="row">
              <div class="col-xs-12">
                <h4>Payment Options</h4>
              </div>
            </div>

            <div class="row">
              <div class="col-xs-12">
                <credit-card credit-card="businessAccount.creditCard" cc-data="businessAccount.data.creditCard" />
              </div>
            </div>

            <div class="step-buttons"
                 data-active-step-id="2">
              <button class="btn btn-primary" data-step-to="{{businessAccount.hasRewardCode? '1' : '2'}}">
                Back
              </button>
              <button class="btn btn-primary"
                      ng-class="{loading: businessAccount.isLoading}"
                      ng-disabled="businessAccount.isLoading || businessAccountForm.$invalid || (!businessAccount.hasRewardCode && businessAccount.rewardCodeChecked && !businessAccount.rewardCodeValid)"
                      ng-click="businessAccount.save()">
                {{businessAccount.isLoading ? 'Loading ...' : 'Pay & Activate' }}
              </button>
            </div>

          </div>

          </div>
        </div>

      </form>
    </div>
  </div>
  <div class="row modal-content-buttons">
    <div class="col-xs-12 text-right">
      <button class="btn btn-default" ng-disabled="businessAccount.isLoading" ng-click="businessAccount.cancel()">
        Cancel
      </button>
    </div>
  </div>
</div>
