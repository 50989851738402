(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name dashboard.directive:businessSuggestion
   * @restrict EA
   * @element
   *
   * @description
   */
  angular
    .module('toolbar')
    .directive('businessSuggestion', businessSuggestion);

  function businessSuggestion() {
    return {
      restrict: 'EA',
      scope: {
        user: '=user'
      },
      replace: true,
      templateUrl: 'toolbar/business-suggestion-directive.tpl.html',
      controllerAs: 'suggestion',
      controller: ['$rootScope', '$scope', 'configOptions', 'BusinessAccount', '$document', 'Notification', 'Session', function ($rootScope, $scope, configOptions, BusinessAccount, $document, Notification, Session) {
        var vm = this,
            count = 0,
            trialCount = 0,
            body = angular.element($document[0].body),
            userProfileDeferred = Session.getUserProfile(),
            logoutSuccessHandler;

        // Default state of the visibility of this directive's content
        vm.isClosed = true;

        logoutSuccessHandler = $rootScope.$on('event:auth-logoutSuccess', function () {
          body.removeClass('has-suggestion');
        });

        // Remove the handle once it's all destroyed
        $rootScope.$on('$destroy', logoutSuccessHandler);

        // Get the user data from session.
        function updateUserData() {
          userProfileDeferred.then(function (profile) {
            if (profile) {
              vm.userProfile = profile;
              vm.trialData = BusinessAccount.getTrialData(vm.userProfile);
              // Trigger the check count as there might be some trial data available
              checkCount();
              // If there is a warning, we will show the directive no matter what.
              if (vm.trialData.isInTrial && vm.trialData.trialWarning) {
                vm.setIsClosed(false);
              }
            }
          });
        }

        /**
        * @ngdoc function
        * @name closeSuggestion
        * @methodOf registerSuggestion.controller:suggestion
        * @description Closes the suggestion. At the same time it increases the count in the local storage or sets the new timestamp (if user never signs out)
        */
        vm.closeSuggestion = function () {
          vm.setIsClosed(true);
          BusinessAccount.updateSuggestionCount(count);
          if (vm.trialData && vm.trialData.isEligibleForTrial) {
            BusinessAccount.updateTrialSuggestionCount(trialCount);
          }
        };

        /**
        * @ngdoc function
        * @name checkCount
        * @methodOf registerSuggestion.controller:suggestion
        * @description Checks the number of how many times have we bothered the user with the suggestions already. If we have bothered him => than the number in the settings, then keep it closed. If it's less, open the suggestion.
        * @private
        */
        function checkCount() {
          var closeIt = false;

          // Get the cached count value for business suggestion and trialSuggestions.
          // Trial suggestions are overriding the business ones.
          count = BusinessAccount.getSuggestionsCount();
          trialCount = BusinessAccount.getTrialSuggestionsCount();
          closeIt = count < configOptions.maxBusinessSuggestions ? false : true;
          if (vm.trialData && vm.trialData.isEligibleForTrial) {
            closeIt = trialCount < configOptions.maxBusinessSuggestions ? false : true;
          }
          vm.setIsClosed(closeIt);
        }

        /**
        * Redirects to Business registration
        */
        vm.registerBusiness = function () {
          // Open the popup dialog
          vm.isActivating = true;
          BusinessAccount.initiateActivation(body[0], vm.trialData.isEligibleForTrial)
            .then(function () {
              Notification({message: 'Your business account has been successfully activated.'}, 'confirmation');
              vm.isActivating = false;
              vm.setIsClosed(true);
            }, function () {
              vm.isActivating = false;
              vm.setIsClosed(true);
            });
        };

        /**
        * Sets the isClosed model variable to the incoming parameter
        * @param {Boolean} isClosed Whether the suggestion is to be closed now.
        */
        vm.setIsClosed = function (isClosed) {
          if (vm.isClosed !== isClosed) {
            // Do the CSS change on the body so the content knows it needs to shift a little
            if (isClosed) {
              body.removeClass('has-suggestion');
            }
            else {
              body.addClass('has-suggestion');
            }
          }
          vm.isClosed = isClosed;
        };

        //
        updateUserData();

        // Check the count from cache. This will also reset the visibility status from visible if this has been shown before.
        checkCount();
      }],
      link: function (scope, element, attrs, ctrl) {
        ctrl.element = element;
      }
    };
  }
}());
