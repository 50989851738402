<div class="row">
  <div class="col-xs-12">
    <h1 class="page-header">Withdraw Earnings</h1>
  </div>
</div>

<div class="row">
  <div class="col-xs-12">
    <div class="page-content page-content-no-tabs">

      <div class="row">
        <div class="col-xs-12">
          <div class="form-notification">
            <div class="form-notification-desc">
              At MyLeadPod we encourage people to support local communities, sports clubs and charities and we offer the option of donating to a charity.
            </div>
            <div class="form-notification-prompt hidden-xs hidden-sm">
              Would you like to donate now?
            </div>
            <div class="form-notification-content">
              <button class="btn btn-primary" ng-click="withdraw.donate();">Donate</button>
            </div>
          </div>
        </div>
      </div>

      <uib-alert ng-repeat="alert in withdraw.alerts.getList()" type="{{alert.type}}" close="withdraw.alerts.remove($index)">{{alert.message}}</uib-alert>
      <form name="withdrawEarningsForm" class="withdraw-earnings-form row">

        <div class="col-xs-12 col-sm-6">
          <!-- Withdraw Amount -->
          <div class="row">
            <div class="col-xs-12 form-section-title">
              <span class="sub-title">Withdraw value</span>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 form-section-description">
              The minimum withdrawal limit is {{withdraw.minLimit | currency:"$":2}}
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12">
              <div class="form-group validating-control" ng-class="{'has-error': (withdrawEarningsForm.$submitted || withdrawEarningsForm.amount.$touched) &&
            (withdrawEarningsForm.amount.$error.required || withdrawEarningsForm.amount.$error.number || withdrawEarningsForm.amount.$error.min || withdrawEarningsForm.amount.$error.max)}">
                <label class="sr-only" for="amount">Enter amount</label>
                <div ng-show="withdrawEarningsForm.$submitted || withdrawEarningsForm.amount.$touched">
                  <div class="input-error" ng-show="withdrawEarningsForm.amount.$error.number">Invalid amount
                  </div>
                  <div class="input-error" ng-show="withdrawEarningsForm.amount.$error.required">Missing amount
                  </div>
                  <div class="input-error" ng-show="withdrawEarningsForm.amount.$error.max">You can not withdraw more than your balance
                  </div>
                  <div class="input-error" ng-show="withdrawEarningsForm.amount.$error.min">You have to withdraw more than minimum withdraw limit
                  </div>
                </div>
                <div class="left-inner-addon input-group">
                  <i class="fa fa-dollar inner-addon"></i>
                  <input class="form-control input-lg" type="number" name="amount" id="amount"
                         placeholder="Enter amount" ng-change="withdraw.onAmountChange()"
                         ng-blur="withdraw.calculate()"
                         ng-required="true" ng-model="withdraw.data.amount" min="{{withdraw.minLimit}}" max="{{withdraw.balance}}"/>
                  <span class="input-group-btn">
                  <button class="btn btn-primary btn-md" type="button" ng-click="withdraw.calculate()"
                          ng-disabled="!withdraw.data.amount">Enter</button>
                </span>
                </div>
              </div>
            </div>
          </div>

          <!-- Balance sheet -->
          <div class="controls-container form-group pam" style="position: relative; font-size: 14px;">

            <!-- Pending overlay -->
            <div class="pending-overlay-white pending-overlay-column" ng-if="withdraw.calculating">
              <span class="fa fa-circle-o-notch fa-spin-2x"></span>
              <div class="pending-overlay-description">Recalculating</div>
            </div>

            <div class="row">
              <div class="col-xs-6 ">
                Current balance
              </div>
              <div class="col-xs-6 text-right">
                {{withdraw.balance | currency:"$":2}}
              </div>
            </div>
            <!--<div class="row" ng-if="withdraw.showTotal && !withdraw.calculating && !withdraw.calculationRequired && withdraw.processingFee">-->
            <div class="row">
              <div class="col-xs-6 ">
                Processing fee
              </div>
              <div class="col-xs-6  text-right">
                {{withdraw.processingFee ? (withdraw.processingFee | currency:"$":2) : "..."}}
              </div>
            </div>
            <div class="row">
              <div class="col-xs-6 3">
                Withdrawal amount
              </div>
              <div class="col-xs-6 text-right">
                {{withdraw.receivedAmount ? (withdraw.receivedAmount| currency:"$":2) : "..."}}
              </div>
            </div>
            <div class="row" ng-if="withdraw.showTotal && !withdraw.calculating && !withdraw.calculationRequired">
              <div class="col-xs-6 ">
                <strong>Remaining balance</strong>
              </div>
              <div class="col-xs-6  text-right">
                <strong>{{withdraw.remainingBalance ? (withdraw.remainingBalance | currency:"$":2) : "..."}}</strong>
              </div>
            </div>

          </div>


        </div>

        <div class="col-xs-12 col-sm-6">

          <!-- Payment Details -->
          <div class="row">
            <div class="col-xs-12 form-section-title">
              <span class="sub-title">Payment details</span>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12 form-section-description">
              Provide recipient's bank account details
            </div>

            <div class="col-xs-12">
              <div class="form-group validating-control"
                   ng-class="{'has-error': (withdrawEarningsForm.$submitted || withdrawEarningsForm.accountHolder.$touched) && withdrawEarningsForm.accountHolder.$error.required}">
                <label class="sr-only" for="accountHolder">Account holder</label>
                <div ng-show="withdrawEarningsForm.$submitted || withdrawEarningsForm.accountHolder.$touched">
                  <div class="input-error" ng-show="withdrawEarningsForm.accountHolder.$error.required">Account holder.
                  </div>
                </div>
                <div class="left-inner-addon">
                  <i class="fa fa-user inner-addon"></i>
                  <input class="form-control input-lg" type="text" name="accountHolder" id="accountHolder"
                         placeholder="Account holder" ng-required="true" ng-model="withdraw.data.accountHolder"/>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12">
              <div class="form-group"
                   ng-class="{'has-error': (withdrawEarningsForm.$submitted || withdrawEarningsForm.accountBsb.$touched) && withdrawEarningsForm.accountBsb.$error.required}">
                <label class="sr-only" for="accountBsb">BSB</label>
                <div ng-show="withdrawEarningsForm.$submitted || withdrawEarningsForm.accountBsb.$touched">
                  <div class="input-error" ng-show="withdrawEarningsForm.accountBsb.$error.required">Account BSB.</div>
                </div>
                <div class="left-inner-addon">
                  <i class="fa fa-bank inner-addon"></i>
                  <input class="form-control input-lg" type="text" name="accountBsb" id="accountBsb"
                         placeholder="BSB" ng-required="true" ng-model="withdraw.data.accountBsb"/>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12">
              <div class="form-group"
                   ng-class="{'has-error': (withdrawEarningsForm.$submitted || withdrawEarningsForm.accountNumber.$touched) && withdrawEarningsForm.accountNumber.$error.required}">
                <label class="sr-only" for="accountNumber">Account</label>
                <div ng-show="withdrawEarningsForm.$submitted || withdrawEarningsForm.accountNumber.$touched">
                  <div class="input-error" ng-show="withdrawEarningsForm.accountNumber.$error.required">Account</div>
                </div>
                <div class="left-inner-addon">
                  <i class="fa fa-bank inner-addon"></i>
                  <input class="form-control input-lg" type="text" name="accountNumber" id="accountNumber"
                         placeholder="Account" ng-required="true" ng-model="withdraw.data.accountNumber"/>
                </div>
              </div>
            </div>
          </div>

          <div class="row form-section-body">
            <div class="col-xs-6">
              <button type="button" class="btn btn-default btn-lg btn-block"
                      ng-disabled="withdraw.isLoading" ng-click="withdraw.cancel()">
                Cancel
              </button>
            </div>
            <div class="col-xs-6">
              <button type="button" class="btn btn-primary btn-lg btn-block"
                      ng-disabled="withdrawEarningsForm.$invalid || withdraw.isLoading" ng-click="withdraw.withdraw()">
                {{withdraw.isLoading ? 'Loading ...' : 'Withdraw'}}
              </button>
            </div>
          </div>

        </div>
      </form>
    </div>
  </div>
</div>
