(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name components.service:Industries
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('Industries', Industries);

  function Industries($q, Restangular, Cache) {
    var self = this,
        industries = {},
        subIndustries = [],
        deferred = $q.defer(),
        cache = Cache.create('industries', {
          maxAge: 1800000
        });

    self.isReady = deferred.promise;

    self.init = function () {
      if (!cache.get('industries') || !cache.get('subIndustries')) {
        Restangular.one('Industries').getList('index?limit=-1&includeAll=true')
          .then(function (result) {
            // Reset subIndustries as it might enter here couple of times within the lifecycle of the app and for example
            // login / logout always triggers the init (as the cache of industries is cleared) and hence adding more
            // and more subindustries
            subIndustries = [];

            angular.forEach(result, function (industry) {
              industry = industry.plain();
              if (!isNaN(industry.idIndustryParent) && industry.idIndustryParent > 0) {
                subIndustries.push(industry);
              }
              else {
                industry.options = [];
                industries[industry.id] = industry;
              }
            });

            angular.forEach(subIndustries, function (subIndustry) {
              if (angular.isDefined(industries[subIndustry.idIndustryParent])) {
                industries[subIndustry.idIndustryParent].options.push(subIndustry);
              }
            });
            cache.put('industries', industries);
            cache.put('subIndustries', subIndustries);
            deferred.resolve();
          });
      }
      else {
        industries = cache.get('industries');
        subIndustries = cache.get('subIndustries');
        deferred.resolve();
      }
    };

    self.init();

    self.getMainList = function () {
      return industries;
    };

    self.getSubList = function (idParent) {
      idParent = parseInt(idParent, 10);
      if (idParent > 0) {
        return _.filter(subIndustries, function (item) {
          return item.idIndustryParent === idParent;
        });
      }

      return subIndustries;
    };

    self.getFilteredList = function (subIndustriesFilter) {
      var filteredList = {};
      if (angular.isArray(subIndustriesFilter) && subIndustriesFilter.length > 0) {
        angular.forEach(subIndustriesFilter, function (item) {
          if (angular.isUndefined(filteredList[item.idIndustryParent])) {
            filteredList[item.idIndustryParent] = angular.extend({}, industries[item.idIndustryParent], {options: []});
          }
          filteredList[item.idIndustryParent].options.push(item);
        });
      }

      return _.values(filteredList);
    };

    self.getConsolidatedList = function () {
      var list = [];
      angular.forEach(industries, function (industry) {
        if (industry.options.length > 0) {
          angular.forEach(industry.options, function (subIndustry) {
            list.push({
              id: subIndustry.id,
              name: industry.name + ' - ' + subIndustry.name
            });
          });
        }
        else {
          list.push({
            id: industry.id,
            name: industry.name
          });
        }
      });

      return list;
    };

    self.getConsolidatedFilteredList = function (subIndustriesFilter) {
      var filteredList = {};

      if (angular.isArray(subIndustriesFilter) && subIndustriesFilter.length > 0) {
        filteredList = _.filter(self.getConsolidatedList(), function (item) {
          return _.findIndex(subIndustriesFilter, {id: item.id}) > -1;
        });
      }

      return filteredList;
    };

    self.reload = function () {
      cache.removeAll();
      self.init();
    };
  }
}());
