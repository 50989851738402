(function () {
  'use strict';

  angular
    .module('account.password')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('accountPassword', {
        abstract: true,
        url: '/account/password',
        template: '<ui-view/>',
        data: {
          classNames: 'no-help'
        }
      })
      .state('accountPassword.recover', {
        url: '/recover',
        templateUrl: 'account/password/recover.tpl.html',
        controller: 'RecoverCtrl',
        controllerAs: 'recoverCtrl'
      })
      .state('accountPassword.reset', {
        url: '/reset/{token}',
        templateUrl: 'account/password/reset.tpl.html',
        controller: 'ResetCtrl',
        controllerAs: 'resetCtrl'
      });
  }
}());
