(function () {
  'use strict';

  angular
    .module('dashboard')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('dashboard', {
        url: '/?imTopic',
        templateUrl: 'dashboard/dashboard.tpl.html',
        controller: 'DashboardCtrl',
        controllerAs: 'dashboard',
        authenticate: true,
        resolve: {
          userProfile: ['Session', function (Session) {
            return Session.getUserProfile();
          }],
          dashboardData: ['Restangular', 'Auth', function (Restangular, Auth) {
            return Auth.isAuthenticated().then(function (isAuthenticated) {
              if (isAuthenticated) {
                return Restangular.one('Dashboard').one('index').get()
                  .then(function (result) {
                    return result.plain();
                  });
              }

              return null;
            }, function () {
              return null;
            });
          }]
        }
      });
  }
}());
