(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name account.billing.controller:CreditCardCtrl
   *
   * @description
   *
   */
  angular
    .module('account.billing')
    .controller('CreditCardCtrl', CreditCardCtrl);

  function CreditCardCtrl(Restangular, $uibModalInstance, CreditCardUtil, Session, operation) {
    var
      vm = this;
    vm.isLoading = false;
    vm.months = CreditCardUtil.getExpiryMonths();
    vm.years = CreditCardUtil.getExpiryYears();
    vm.cards = CreditCardUtil.getAcceptedCards();
    vm.data = {};

    vm.isAdd = function () {
      return operation === 'add';
    };

    vm.ok = function () {
      vm.isLoading = true;
      Restangular.one('Billing').customPUT(vm.data, 'creditCard')
        .then(function (result) {
          Session.updateLocalUserProfile({isCCSet: true});
          $uibModalInstance.close({creditCard: result.plain()});
        }, function () {
          vm.isLoading = false;
        });
    };

    vm.cancel = function () {
      $uibModalInstance.dismiss();
    };
  }
}());
