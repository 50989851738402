var $windowInstance = angular.injector(['ng']).get('$window');

Raven.addPlugin(Raven.Plugins.Angular);

$windowInstance.deferredBootstrapper.bootstrap({
  element: $windowInstance.document,
  module: 'myLeadPod',
  bootstrapConfig: {
    strictDi: !'%IS_DEVELOPMENT%'
  },
  injectorModules: ['restangular', 'api', 'configuration'],
  resolve: {
    PreloadedUserProfile: ['$q', 'Restangular', 'configOptions', 'Session', 'Auth',
      function ($q, Restangular, configOptions, Session, Auth) {
        'use strict';
        var deferred;
        Restangular.setBaseUrl(configOptions.baseUrl);
        Restangular.addResponseInterceptor(function (data, operation) {
          var extractedData;
          if (operation === 'getList') {
            extractedData = data.content.records;
            extractedData.meta = data.content.recordsInfo;
          }
          else {
            extractedData = angular.extend({}, data.content);
          }
          extractedData.contentEtag = data.contentEtag;
          extractedData.responseStatus = {
            code: data.content.statusCode,
            text: data.content.statusText
          };

          return extractedData;
        });
        Restangular.setDefaultHeaders({Accept: 'application/json'});
        deferred = $q.defer();
        Session.getUserProfile().then(function (userProfile) {
          if (angular.isObject(userProfile) && !!userProfile.idUser) {
            deferred.resolve(userProfile);
          }
          else {
            Auth.signOut(false);
            deferred.resolve(null);
          }
        }, function () {
          Auth.signOut(false);
          deferred.resolve(null);
        });

        return deferred.promise;
      }]
  }
});
