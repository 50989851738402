(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name components.ui.controller:PromptDialogCtrl
   *
   * @description
   *
   */
  angular
    .module('components.ui')
    .controller('PromptDialogCtrl', PromptDialogCtrl);

  function PromptDialogCtrl($timeout, $uibModalInstance, content, onConfirmMessage, cancelButtonLabel, confirmButtonLabel) {
    var vm = this;

    vm.content = content;
    vm.onConfirmMessage = onConfirmMessage;
    vm.isConfirmed = false;
    vm.cancelButtonLabel = cancelButtonLabel ? cancelButtonLabel : 'No';
    vm.confirmButtonLabel = confirmButtonLabel ? confirmButtonLabel : 'Yes';

    vm.confirm = function () {
      vm.isConfirmed = true;
      if (vm.onConfirmMessage) {
        $timeout(function () {
          $uibModalInstance.close();
        }, 3000);
      }
      else {
        $uibModalInstance.close();
      }
    };

    vm.cancel = function () {
      $uibModalInstance.dismiss();
    };
  }
}());
