(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name myPod.controller:MyPodCtrl
   *
   * @description
   *
   */
  angular
    .module('myPod')
    .controller('MyPodCtrl', MyPodCtrl);

  function MyPodCtrl(Session, MyPod) {
    var vm = this;

    vm.tabManager = null;
    vm.tabsConfig = [
      {
        label: 'People in my Pod',
        value: 'members',
        url: 'myPod.members'
      },
      {
        label: 'Sent Leads',
        value: 'sentLeads',
        url: 'myPod.sentLeads'
      }
    ];

    Session.getUserProfile().then(function (userProfile) {
      vm.userProfile = userProfile;
    });

    vm.sendInvite = function () {
      MyPod.openSendInvite(vm.userProfile);
    };
  }
}());
