(function () {
  'use strict';

  /* @ngdoc object
   * @name account.profile
   * @description
   *
   */
  angular
    .module('account.profile', [
      'ui.router',
      'ngFileUpload',
      'uiCropper',
      'ngjsColorPicker',
      'colorpicker.module'
    ]);
}());
