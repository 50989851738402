(function () {
  'use strict';

  angular
    .module('myMemberships.sendReward')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('myMembershipsSendReward', {
        url: '/my-memberships/send-reward',
        templateUrl: 'my-memberships/send-reward/send-reward.tpl.html',
        controller: 'SendRewardCtrl',
        controllerAs: 'sendReward',
        authenticate: true,
        resolve: {
          creditCard: ['Restangular', function (Restangular) {
            return Restangular.one('Billing').one('creditCard').get()
              .then(function (creditCard) {
                if (!creditCard.number || !creditCard.expiryMonth || !creditCard.expiryYear) {
                  return null;
                }
                return creditCard.plain();
              }, function () {
                return null;
              });
          }],
          membershipsSubIndustries: ['Restangular', function (Restangular) {
            return Restangular.one('Industries').getList('getMembershipsSubIndustries')
              .then(function (result) {
                var subIndustries = [];
                angular.forEach(result, function (value) {
                  subIndustries.push(value);
                });
                return subIndustries;
              });
          }]
        },
        params: {
          user: null
        }
      });
  }
}());
