(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name user.marketplace.controller:UserMarketplaceCtrl
   *
   * @description
   *
   */
  angular
    .module('user.marketplace')
    .controller('UserMarketplaceCtrl', UserMarketplaceCtrl);

  function UserMarketplaceCtrl($scope, Marketplace) {
    var vm = this;

    vm.userProfile = $scope.user.userProfile;
    vm.items = $scope.user.userProfile.marketplace;

    vm.openMarketplace = function () {
      Marketplace.open();
    };
  }
}());
