<div class="row">
  <div class="col-md-12 text-center">
    <h1 class="page-header sign-up-page-header">Set Password</h1>
    <div class="sign-up-page-sub-header">
      Welcome to MyLeadPod!
      <br>
      In order to continue to use this account, we need you to setup the password...
    </div>
  </div>
</div>

<div class="row sign-up-page-container">
  <div class="sign-in-page-content text-center activate-form-content">
    <form name="activateAccountForm" ng-submit="activate.activate()">

      <div class="form-group">
        <label class="sr-only" for="password">New Password</label>
        <div class="left-inner-addon">
          <i class="fa fa-lock inner-addon"></i>
          <input class="form-control input-lg" type="password" name="password" id="password"
                 placeholder="Password" ng-required="true" ng-model="activate.data.password" />
        </div>
      </div>


      <div class="form-group" ng-class="{'has-error': (activateAccountForm.$submitted || activateAccountForm.confirmPassword.$touched) && activateAccountForm.confirmPassword.$error.match}">
        <label class="sr-only" for="confirmPassword">Confirm new password</label>
        <div ng-show="activateAccountForm.$submitted || activateAccountForm.confirmPassword.$touched">
          <div class="input-error" ng-show="activateAccountForm.confirmPassword.$error.match">Passwords do not match.</div>
        </div>
        <div class="left-inner-addon">
          <i class="fa fa-lock inner-addon"></i>
          <input class="form-control input-lg" type="password" name="confirmPassword" id="confirmPassword"
                 placeholder="Confirm password" ng-required="true" ng-model="activate.data.confirmPassword"
                 match="activate.data.password" />
        </div>
      </div>

      <div class="form-group sign-up-page-btn-create">
        <button type="submit" class="btn btn-primary btn-lg btn-block"
                ng-disabled="activate.isLoading || activateAccountForm.$invalid">
          {{activate.isLoading ? 'Loading ...' : 'Update Password'}}
        </button>
      </div>

    </form>
  </div>
</div>
