(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name myMemberships.sendReward.controller:SendRewardCtrl
   *
   * @description
   *
   */
  angular
    .module('myMemberships.sendReward')
    .controller('SendRewardCtrl', SendRewardCtrl);

  function SendRewardCtrl($state, $stateParams, Restangular, Notification, DataTable, FormAlerts, creditCard,
                          Industries, membershipsSubIndustries) {
    var vm = this,
        filter;

    vm.isLoading = false;
    vm.alerts = new FormAlerts();
    vm.processingFee = 0;
    vm.total = 0;
    vm.tax = 0;
    vm.showTotal = false;
    vm.calculating = false;
    vm.calculationRequired = false;
    vm.list = DataTable.create('Memberships', 'index');
    vm.selectedRecipient = null;
    vm.searchName = '';
    vm.idIndustry = '';
    vm.creditCard = creditCard;

    if (angular.isDefined($stateParams.user) && angular.isObject($stateParams.user)) {
      vm.selectedRecipient = $stateParams.user;
    }

    vm.data = {
      paymentDetails: {
        method: angular.isObject(creditCard) && angular.isDefined(creditCard.number) ? null : 'new'
      },
      idUserRecipient: 0,
      message: 'Thank you for your leads!'
    };
    Industries.isReady.then(function () {
      vm.industries = Industries.getConsolidatedFilteredList(membershipsSubIndustries);
    });

    filter = function () {
      var idIndustry = parseInt(vm.idIndustry, 10);
      if (idIndustry <= 0 || isNaN(idIndustry)) {
        idIndustry = 0;
      }

      vm.list.reload({
        search: vm.searchName,
        idIndustry: idIndustry
      });
    };

    vm.search = function ($event) {
      $event.stopImmediatePropagation();
      filter();
    };

    vm.filterByIndustry = function () {
      filter();
    };

    vm.cancel = function () {
      $state.go('myMemberships.memberships');
    };

    vm.selectRecipient = function ($index) {
      vm.selectedRecipient = vm.list.getRecords()[$index].owner;
    };

    vm.deselectRecipient = function () {
      vm.selectedRecipient = null;
    };

    vm.isUserSelected = function ($index) {
      var user = vm.list.getRecords()[$index].owner;
      return angular.isObject(vm.selectedRecipient) && vm.selectedRecipient.id === user.id;
    };

    vm.send = function () {
      vm.isLoading = true;
      vm.alerts.reset();
      Restangular.one('Earnings').post('sendReward', angular.extend({}, vm.data, {
        idUserRecipient: vm.selectedRecipient.id
      }))
        .then(function () {
          $state.go('myMemberships.memberships');
          Notification({message: 'Success'}, 'confirmation');
        }, function (responseData) {
          vm.isLoading = false;
          if (responseData.data.errorCode === 701 || responseData.data.errorCode === 702 || responseData.data.errorCode === 703) {
            vm.alerts.add('danger', 'Unable to send: ' + responseData.data.errorMessage);
          }
          else {
            vm.alerts.add('danger', 'Unable to send, try again later please');
          }
        });
    };

    vm.onRewardChange = function () {
      vm.showTotal = false;
      vm.calculationRequired = true;
    };

    vm.calculateTotal = function () {
      if (vm.data.reward > 0) {
        vm.calculating = true;
        Restangular.one('Earnings').one('calculateRewardTotal').get({
          reward: vm.data.reward
        })
          .then(function (result) {
            vm.processingFee = result.fees;
            vm.total = result.total;
            vm.tax = result.tax;
            vm.calculating = false;
            vm.calculationRequired = false;
            vm.showTotal = true;
          });
      }
    };
  }
}());
