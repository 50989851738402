(function () {
  'use strict';

  /* @ngdoc object
   * @name components.ui
   * @description
   *
   */
  angular
    .module('components.ui', [
      'components.ui.table',
      'components.ui.tabs'
    ]);
}());
