(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name lead.controller:LeadViewCtrl
   *
   * @description This is just a dummy view whoe only purpose is to provide background for the modal dialog for the incoming lead
   *
   */
  angular
    .module('lead')
    .controller('LeadViewCtrl', LeadViewCtrl);

  function LeadViewCtrl($state, $stateParams, Session, Leads, leadToOpen) {
    var vm = this,
        modalInstance;

    vm.lead = leadToOpen;
    vm.leadType = null;
    vm.tabToOpen = $stateParams.tabName;

    Session.getUserProfile().then(function (user) {
      vm.user = user;
      if (vm.lead.sender.fullName === vm.user.fullName) {
        vm.leadType = 'sent';
      }
      else {
        vm.leadType = 'received';
      }

      // We have a lead. Now open the modal dialog.
      // Once the modal is open, then handle the close and re-direct to the relevant section (my-pod, myBusiness) depending on the lead type
      if (angular.isDefined(vm.lead)) {
        modalInstance = Leads.openDetail(vm.lead, vm.leadType, null, vm.tabToOpen);
        if (angular.isDefined(modalInstance)) {
          modalInstance.then(function () {
            // We don't care much about the result. Just redirect to where it's required to go.
            if (vm.leadType === 'sent') {
              $state.go('myPod.sentLeads');
            }
            else {
              $state.go('myMemberships.receivedLeads');
            }
          });
        }
      }
    });
  }
}());
