<div class="col-xs-12" ng-controller="RewardProgramCtrl as rewardProgram" id="reward-program-ctrl-container">
  <div class="page-content form-group">

    <div ng-show="rewardProgram.list.isInitialised()">

      <div class="row">
        <div class="col-xs-12 form-group">
          Add new or view your Reward Codes. Use Reward Codes for promoting your rewards in digital advertising and for tracking your leads.
          <i class="no-select info-help" role="button" tabIndex="0" style="margin-left: 15px;"
             popover-trigger="click, outsideClick"
             popover-append-to-body="true"
             popover-class="help"
             uib-popover="{{rewardProgram.rewardCodeHelp}}">?</i>
        </div>
      </div>

      <div class="form-group reward-code-tools">
        <div class="reward-code-table-filter">
          <div ng-show="rewardProgram.list.isInitialised() && rewardProgram.list.recordsExist()">
            <div class="btn-group page-filter dropdown-large" uib-dropdown>
              <button id="reward-types" type="button" class="btn" uib-dropdown-toggle>
                {{rewardProgram.selectedStatusLabel}} <span class="caret"></span>
              </button>
              <ul class="dropdown-menu" uib-dropdown-menu role="menu" aria-labelledby="reward-types">
                <li role="menuitem" ng-repeat="(value, label) in rewardProgram.statuses">
                  <a ng-click="rewardProgram.selectStatus(value)">{{label}}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="reward-code-create">
          <button class="btn btn-primary reward-code-create-btn" ng-click="rewardProgram.openCreateCodeModal()">Create Reward Code</button>
        </div>
      </div>

      <div class="page-empty-section page-table-section text-center" ng-show="rewardProgram.list.isInitialised() && !rewardProgram.list.recordsExist()">
        <i class="fa fa-hashtag fa-2x text-light-colour"></i>
        <div class="page-empty-section-text">
          You have not created any Reward Codes
        </div>
      </div>

      <div class="table-responsive table-container" ng-show="rewardProgram.list.recordsExist() && rewardProgram.list.isInitialised()">
        <table st-table="rewardProgram.list.getRecords()" st-pagination-scroll st-pipe="rewardProgram.list.load" class="table rewards-code-table">
          <thead class="hidden-xs">
          <tr>
            <th class="col-sm-3">Reward Code</th>
            <th class="col-sm-3">Shared with</th>
            <th class="col-sm-2">Clicks</th>
            <th class="col-sm-2">Registrations</th>
            <th class="col-sm-2 text-center">Details</th>
          </tr>
          </thead>
          <tbody ng-hide="(rewardProgram.list.isLoading() && !rewardProgram.list.isLoadingMoreRecords())">
            <tr ng-repeat="row in rewardProgram.list.getRecords()">
              <td class="col-xs-12 col-sm-3">
                <table class="table-cell-main-content">
                  <tr class="row-center-items row-space-between">
                    <td class="visible-xs" single-expand sub-content="rewardProgram.tableSubContentVisible" sub-content-idx="$index"></td>
                    <td>{{row.rewardCode}}</td>
                    <td class="visible-xs text-right">
                      <span ng-if="row.isArchived" class="description-tag">Archived</span>
                      <span class="cursor-pointer">
                        <i class="fa fa-ellipsis-h"></i>
                      </span>
                    </td>
                  </tr>
                </table>

                <div class="table-cell-sub-content-container slide-if" ng-if="rewardProgram.tableSubContentVisible[$index]">
                  <div>
                    <div class="table-cell-sub-content visible-xs">
                      <span class="text-muted">Clicks</span>
                      <span class="text-normal">{{row.viewsCounter}}</span>
                    </div>
                    <div class="table-cell-sub-content visible-xs">
                      <span class="text-muted">Registrations</span>
                      <span class="text-normal">{{row.registrationsCounter}}</span>
                    </div>
                    <div class="table-cell-sub-content visible-xs">
                      <span class="text-normal">
                        <a ng-click="rewardProgram.setUrl(row.rewardCode);" class="btn-sm btn-primary cursor-pointer no-select"
                           popover-trigger="click, outsideClick"
                           popover-append-to-body="true"
                           popover-class="help"
                           uib-popover-template="'social-tpl.html'">
                          <i class="no-select fa fa-share-alt"></i>
                           Share
                        </a>
                      </span>
                    </div>
                    <div class="table-cell-sub-content visible-xs">
                      <span class="text-normal"><a ng-click="rewardProgram.openDetail($index)" class="btn-sm btn-primary cursor-pointer no-select">View</a></span>
                    </div>
                    <div class="table-cell-sub-content visible-xs shared-with" ng-if="row.userShare">
                      <span class="text-muted">Shared with</span>
                      <span class="text-normal">
                        <table ng-if="row.userShare">
                          <tr>
                            <td>
                              <table-user-details user="row.userShare"></table-user-details>
                            </td>
                          </tr>
                        </table>
                      </span>
                    </div>
                  </div>
                </div>


              </td>
              <td class="hidden-xs col-sm-3">
                <table class="table-cell-main-content" ng-if="row.userShare">
                  <tr>
                    <td>
                      <table-user-details user="row.userShare"></table-user-details>
                    </td>
                  </tr>
                </table>
                <span ng-if="!row.userShare" class="text-muted">-</span>
              </td>
              <td class="hidden-xs col-sm-2">
                {{row.viewsCounter}}
              </td>
              <td class="hidden-xs col-sm-2">
                {{row.registrationsCounter}}
              </td>
              <td class="hidden-xs col-sm-2 text-right" style="white-space: nowrap;">
                <a ng-click="rewardProgram.setUrl(row.rewardCode);" style="margin-right: 15px; cursor: pointer;"
                   popover-trigger="click, outsideClick"
                   popover-append-to-body="true"
                   popover-class="help"
                   uib-popover-template="'social-tpl.html'">
                  <i class="no-select fa fa-share-alt"></i>
                   Share
                </a>

                <a ng-click="rewardProgram.openDetail($index)" class="cursor-pointer">View</a>
              </td>
            </tr>
          </tbody>
          <tbody ng-if="rewardProgram.list.isLoading()">
          <tr>
            <td colspan="5" class="col-xs-12 text-center">
              <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row" ng-show="!rewardProgram.list.isInitialised()">
      <div class="col-xs-12 text-center">
        <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>
      </div>
    </div>


    <!-- social share template for this page -->
    <script type="text/ng-template" id="social-tpl.html"><span></span>
      <!-- Pending overlay -->
      <div class="pending-overlay pending-overlay-column opacity-slow-if no-animate" ng-if="rewardProgram.isLoadingLink">
        <span class="fa fa-circle-o-notch fa-spin-2x"></span>
        <div class="pending-overlay-description">Preparing your Code Link..</div>
      </div>

      <div class="popover-social-links">
        <a class="reward-share-link" ng-click="rewardProgram.share('facebook')"><i class="fa fa-facebook-square fa-2x"></i></a>
        <a class="reward-share-link" ng-click="rewardProgram.share('linkedin')"><i class="fa fa-linkedin-square fa-2x"></i></a>
        <a class="reward-share-link" ng-click="rewardProgram.share('twitter')"><i class="fa fa-twitter-square fa-2x"></i></a>
        <a class="reward-share-link" ng-click="rewardProgram.share('google')"><i class="fa fa-google-plus-square fa-2x"></i></a>
        <a class="reward-share-link-fake" ng-if="rewardProgram.urlToShare" href="mailto:?subject=Join us at MyLeadPod&body={{rewardProgram.emailBody}}" title="Embed"><i class="fa fa-envelope fa-2x"></i></a>
      </div>
    </script>

  </div>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 text-center">
        <span class="sub-title-description" style="margin-left: 12px;">
          To modify your Rewards Program, go to the <a ui-sref="myMembershipsRewardProgramsSetup" class="cursor-pointer">Rewards Program</a> page.
        </span>
    </div>
  </div>
</div>
