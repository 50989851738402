<div class="row" ng-controller="StatsCtrl as stats">
	<div class="col-lg-3 col-xs-6">
		<stat-block
			data-stat="{{stats.membersCount}}"
			data-subtext="{{stats.membersCount === 1 ? 'person' : 'people'}} receiving my leads"
			data-button-link="/my-pod"
			data-button-text="View All">
		</stat-block>
	</div>
	<div ng-if="stats.isBusinessAccountActive()" class="col-lg-3 col-xs-6">
		<stat-block
			data-stat="{{stats.membershipsCount}}"
			data-subtext="{{stats.membershipsCount === 1 ? 'person' : 'people'}} sending me leads"
			data-button-link="/my-memberships"
			data-button-text="View All">
		</stat-block>
	</div>
  <div ng-if="!stats.isBusinessAccountActive()" class="col-lg-3 col-xs-6">
    <stat-block
      data-stat="{{stats.sentLeadsCount}}"
      data-subtext="sent leads"
      data-button-link="/my-pod/sent-leads"
      data-button-text="View All">
    </stat-block>
  </div>
	<div class="col-lg-3 col-xs-6">
		<stat-block
			data-stat="{{stats.earnings}}"
			data-subtext="last month earnings"
			data-button-link="/account/earnings"
			data-button-text="View Details"
      ng-if="stats.earningsValue > 0 || stats.earningsValue == 0 && stats.donationsValue == 0">
		</stat-block>
    <stat-block
      data-stat="{{stats.donations}}"
      data-subtext="Donations"
      data-button-text="Donate"
      ng-click="stats.openDonate()"
      ng-if="stats.earningsValue == 0 && stats.donationsValue > 0">>
    </stat-block>
	</div>
	<div class="col-lg-3 col-xs-6">
		<stat-block
			data-stat="{{stats.balance}}"
			data-subtext="current balance"
			data-button-link="/account/earnings/withdraw"
			data-button-text="Withdraw">
		</stat-block>
	</div>
</div>
