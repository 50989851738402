<div class="row" ng-if="!recoverCtrl.showMessage">
  <div class="col-md-12 text-center">
    <h1 class="page-header sign-up-page-header">Enter your email and we'll get you back on track</h1>
  </div>
</div>

<div class="row sign-up-page-container" ng-if="!recoverCtrl.showMessage">
  <div class="sign-up-page-content text-center">
    <uib-alert ng-repeat="alert in recoverCtrl.alerts.getList()" type="{{alert.type}}" close="recoverCtrl.alerts.remove($index)">{{alert.message}}</uib-alert>
    <form name="passwordRecoverForm">
      <div class="form-group" ng-class="{'has-error': (passwordRecoverForm.$submitted || passwordRecoverForm.email.$touched) && (passwordRecoverForm.email.$error.required || passwordRecoverForm.email.$error.email)}">
        <label class="sr-only" for="email">Email</label>
        <div ng-show="passwordRecoverForm.$submitted || passwordRecoverForm.email.$touched">
          <div class="input-error" ng-show="passwordRecoverForm.email.$error.required">Tell us your email.</div>
          <div class="input-error" ng-show="passwordRecoverForm.email.$error.email">This is not a valid email.</div>
        </div>
        <div class="left-inner-addon">
          <i class="fa fa-envelope-o inner-addon"></i>
          <input class="form-control input-lg" type="email" name="email" id="email"
                 placeholder="Email" ng-required="true" ng-model="recoverCtrl.data.email" />
        </div>
      </div>

      <div class="form-group">
        <div vc-recaptcha key="recoverCtrl.reCaptchaSiteKey"></div>
      </div>

      <div class="form-group sign-up-page-btn-create">
        <button type="button" class="btn btn-primary btn-lg btn-block"
                ng-disabled="passwordRecoverForm.$invalid || recoverCtrl.isLoading" ng-click="recoverCtrl.recover()">
          {{recoverCtrl.isLoading ? 'Loading ...' : 'Reset Password'}}
        </button>
      </div>
    </form>
  </div>
</div>

<div class="row success-message-container" ng-if="recoverCtrl.showMessage">
  <div class="col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 text-center">
    <div class="panel panel-default">
      <div class="panel-body">
        <h2>Password Reset</h2>
        <p>We have sent {{recoverCtrl.data.email}} an email with reset instructions</p>
        <p>
          If the email does not arrive soon, check your spam folder. The email was sent from confirm@myleadpod.com
        </p>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-12 text-center sign-in-page-footer">
    <a ui-sref="accountSignInUp.accountSignIn">Back to login</a>
  </div>
</div>
