<div class="col-xs-12" ng-controller="BillingCtrl as billing" id="billing-ctrl-container">
  <div class="page-content">

    <div class="row" ng-show="!billing.invoices.isInitialised()">
      <div class="col-xs-12 text-center">
        <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12">
        <!-- Pending overlay -->
        <div class="pending-overlay-white opacity-slow-if" ng-if="billing.invoices.isInitialised() && billing.initialisingCard">
          <span class="fa fa-circle-o-notch fa-spin-2x"></span>
        </div>
        <div class="slide-if" ng-if="billing.invoices.isInitialised() && !billing.initialisingCard">
          <span class="sub-title">Credit card details</span>
          <div class="page-empty-section page-table-section text-center slide-if" ng-if="!billing.initialisingCard && !billing.creditCard">
            <i class="fa fa-credit-card fa-2x text-light-colour"></i>
            <div class="page-empty-section-text">
              Looks like you don't have a credit card on file
            </div>
            <div class="page-empty-section-button">
              <button class="btn btn-primary" ng-click="billing.manageCreditCard('add')">Add a Credit Card</button>
            </div>
          </div>

          <div class="credit-card-detail-container slide-if" ng-if="!billing.initialisingCard && billing.creditCard">
            <div class="row">
              <div class="col-xs-12">
                <table>
                  <tr>
                    <td class="credit-card-logo">
                      <i class="fa fa-credit-card"></i>
                    </td>
                    <td class="credit-card-detail">
                      <p class="credit-card-holder">{{billing.creditCard.holderName}}</p>
                      <p>{{billing.creditCard.number}}</p>
                      <p>Expires {{billing.creditCard.expiryMonth}} / {{billing.creditCard.expiryYear}}</p>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12">
                <button class="btn btn-default credit-card-btn" ng-disabled="billing.cardLoading"
                        ng-click="billing.removeCreditCard()">
                  {{billing.cardLoading ? 'Loading ...' : 'Remove Card'}}
                </button>
                <button class="btn btn-primary credit-card-btn" ng-disabled="billing.cardLoading"
                        ng-click="billing.manageCreditCard('change')">
                  {{billing.cardLoading ? 'Loading ...' : 'Change Card'}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row page-section form-section-title slide-if" ng-if="billing.invoices.isInitialised()">
      <div class="col-xs-12">
        <span class="sub-title">Invoice history</span>
        <div class="page-empty-section page-table-section text-center" ng-hide="billing.invoices.recordsExist()">
          <i class="fa fa-file-text-o fa-2x text-light-colour"></i>
          <div class="page-empty-section-text">
            There are no invoices to show
          </div>
        </div>

        <div class="page-table-section table-container" ng-show="billing.invoices.recordsExist()">
          <table st-table="billing.invoices.getRecords()" st-pagination-scroll st-pipe="billing.invoices.load" class="table">
            <thead class="hidden-xs">
            <tr>
              <th>Invoice date</th>
              <th>Amount</th>
              <th class="text-right">Invoice</th>
            </tr>
            </thead>
            <tbody ng-hide="billing.invoices.isLoading() && !billing.invoices.isLoadingMoreRecords()">
            <tr ng-repeat="invoice in billing.invoices.getRecords()">
              <td class="hidden-xs col-sm-4">{{invoice.dateInvoice | date:'d MMM yyyy'}}</td>
              <td class="col-xs-6 col-sm-4">
                <span class="text-muted visible-xs">{{invoice.dateInvoice | date:'d MMM yyyy'}}<br/></span>
                {{invoice.totalInclTax | currency:"$":2}}
              </td>
              <td class="col-xs-6 col-sm-4 text-right invoice-no">
                <file-download url="invoice.downloadUrl" label="invoice.invoiceNumber"></file-download>
              </td>
            </tr>
            </tbody>
            <tbody ng-if="billing.invoices.isLoading()">
            <tr>
              <td colspan="3" class="col-xs-12 text-center">
                <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
