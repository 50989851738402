(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name components.factory:QueueItem
   *
   * @description
   *
   */
  angular
    .module('components')
    .factory('QueueItem', QueueItem);

  function QueueItem() {
    /**
     * Creates new instance of QueueItem
     *
     * @param {Function} callback Callback function
     * @param {Object} options Options
     * @constructor
     */
    function QueueItemBase(callback, options) {
      if (!angular.isFunction(callback)) {
        throw new Error('Callback has to be a function');
      }

      this.callback = callback;
      this.options = options || {};
    }

    return QueueItemBase;
  }
}());
