(function () {
  'use strict';

  /* @ngdoc object
   * @name auth
   * @description
   *
   */
  angular
    .module('account', [
      'ui.router',
      'account.password',
      'account.profile',
      'account.billing',
      'account.earnings',
      'account.feedback',
      'account.preferences'
    ]);
}());
