(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name dashboard.memberships.directive:receivedLeadsList
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="dashboard.memberships">
       <file name="index.html">
        <received-leads-list></received-leads-list>
       </file>
     </example>
   *
   */
  angular
    .module('dashboard.memberships')
    .directive('receivedLeadsList', receivedLeadsList);

  function receivedLeadsList() {
    return {
      restrict: 'EA',
      scope: {
        list: '=list'
      },
      templateUrl: 'dashboard/memberships/received-leads-list-directive.tpl.html',
      replace: false,
      controllerAs: 'receivedLeadsList',
      controller: function ($scope, $uibModal, Leads) {
        var vm = this;
        vm.name = 'receivedLeadsList';
        vm.list = $scope.list;

        vm.openDetail = function ($index) {
          Leads.openDetail(vm.list.records[$index], 'received');
        };
      }
    };
  }
}());
