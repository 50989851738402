(function () {
  'use strict';

  /* @ngdoc object
   * @name configuration
   * @description
   *
   */
  angular
    .module('configuration', [
    ]);
}());
