<div class="modal-content-container">
  <div class="row">
    <div class="col-xs-12">
        <div class="row">
          <form name="sendLeadForm" class="send-lead-form">
            <ng-include src="'lead/send-lead-form-partial.tpl.html'" />
          </form>
        </div>
    </div>
  </div>
  <div class="row modal-content-buttons">
    <div class="col-xs-12 text-right">
      <button type="button" class="btn btn-default"
              ng-disabled="sendLead.isLoading" ng-click="sendLead.cancel()">
        Cancel
      </button>
      <button ng-if="!sendLead.isRegister" type="button" class="btn btn-primary"
              ng-disabled="sendLeadForm.$invalid || sendLead.isLoading || !sendLead.isValidRecipient() || !sendLead.isValidName() || !sendLead.isValidContact()" ng-click="sendLead.send()">
        {{sendLead.isLoading ? 'Sending ...' : 'Send Lead'}}
      </button>
      <button ng-if="sendLead.isRegister" type="button" class="btn btn-primary"
              ng-disabled="sendLeadForm.$invalid || sendLead.isLoading || !sendLead.isValidRecipient() || !sendLead.isValidName() || !sendLead.isValidContact()" ng-click="sendLead.register()">
        {{sendLead.isLoading ? 'Registering ...' : 'Register Lead'}}
      </button>
    </div>
  </div>
</div>
