(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name myMemberships.joinPod.controller:JoinPodCtrl
   *
   * @description
   *
   */
  angular
    .module('myMemberships.joinPod')
    .controller('JoinPodCtrl', JoinPodCtrl);

  function JoinPodCtrl($state, MyMemberships, userProfile) {
    var vm = this;
    vm.openAskForLeads = function () {
      var modalInstance = MyMemberships.openAskForLeads(userProfile, null);
      modalInstance.then(function () {
        $state.go('myMemberships');
      }, function () {
        $state.go('myMemberships');
      });
    };
    vm.openAskForLeads();
  }
}());
