(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name api.factory:Ff3ApiInterceptor
   *
   * @description
   *
   */
  angular
    .module('api')
    .factory('Ff3ApiInterceptor', Ff3ApiInterceptor)
    .config(moduleConfig);

  function Ff3ApiInterceptor($injector, $q, $rootScope) {
    var Ff3ApiInterceptorBase = {};
    Ff3ApiInterceptorBase.request = function (config) {
      var
          apiParams = $injector.get('Auth').getSecurityParams(),
          baseUrl = $injector.get('configOptions').baseUrl;

      if (config.url.indexOf(baseUrl) === -1) {
        return config;
      }

      if (config.method === 'GET') {
        // We will be appending all the required parameters as query params
        if (!config.params) {
          config.params = apiParams;
        }
        else {
          angular.forEach(apiParams, function (value, key) {
            config.params[key] = value;
          });
        }
      }
      else {
        config.headers.Accept = 'application/json';
        angular.forEach(apiParams, function (value, key) {
          config.headers[key] = value;
        });
      }

      return config;
    };

    Ff3ApiInterceptorBase.responseError = function (rejection) {
      if (rejection.status === 403 || rejection.status === 401) {
        $rootScope.$broadcast('event:auth-loginRequired', rejection);
      }
      // otherwise, default behaviour
      return $q.reject(rejection);
    };

    return Ff3ApiInterceptorBase;
  }

  function moduleConfig($httpProvider) {
    $httpProvider.interceptors.push('Ff3ApiInterceptor');
  }
}());
