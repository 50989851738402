(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name mlpMarketplace.directive:MarketplaceListings
   * @requires $timeout
   * @requires $uibModalInstance
   * @requires userProfile
   * @requires components.ui.PromptDialog
   * @requires components.FormAlerts
   * @requires mlpMarketplace.Marketplace
   * @description
   *
   */
  angular
    .module('mlpMarketplace')
    .directive('marketplaceListings', marketplaceListings);

  function marketplaceListings() {
    return {
      restrict: 'E',
      scope: {
        user: '=',
        items: '=',
        hasUserAvatar: '='
      },

      replace: true,
      transclude: false,
      templateUrl: 'mlp-marketplace/marketplace-listings-directive.tpl.html',
      controllerAs: 'marketplace',
      controller: ['$scope', '$timeout', 'PromptDialog', 'FormAlerts', 'Marketplace', function ($scope, $timeout, PromptDialog, FormAlerts, Marketplace) {
        var vm = this;
        vm.userProfile = $scope.user;
        vm.hasUserAvatar = $scope.hasUserAvatar || false;

        vm.items = $scope.items || vm.userProfile.marketplace;
        vm.isLoading = [];
        vm.isProcessing = false;
        vm.alerts = new FormAlerts();

        vm.switchSubscription = function ($index) {
          if (vm.isLoading[$index]) {
            return;
          }
          if (vm.items[$index].isSubscribed) {
            vm.deactivate($index);
          }
          else {
            vm.activate($index);
          }
        };

        vm.activate = function ($index) {
          var item = vm.items[$index];
          if (!item.isSubscribed) {
            vm.isLoading[$index] = true;
            vm.isProcessing = true;
            Marketplace.initiateActivation(vm.items[$index])
              .then(function (result) {
                if (result.active) {
                  item.isSubscribed = true;
                }
                vm.isLoading[$index] = false;
                vm.isProcessing = false;
              }, function () {
                vm.isLoading[$index] = false;
                vm.isProcessing = false;
              });
          }
        };

        vm.deactivate = function ($index) {
          var item = vm.items[$index];
          if (item.isSubscribed) {
            vm.alerts.reset();
            PromptDialog.open('By clicking Deactivate, you will immediately cancel your subscription', null, 'Deactivate', 'Cancel')
              .then(function () {
                vm.isLoading[$index] = true;
                vm.isProcessing = true;
                Marketplace.deactivate(item)
                  .then(function () {
                    item.isSubscribed = false;
                    vm.isLoading[$index] = false;
                    vm.isProcessing = false;
                  }, function () {
                    vm.alerts.add('danger', 'Unable to deactivate subscription.');
                    vm.isLoading[$index] = false;
                    vm.isProcessing = false;
                  });
              });
          }
        };

        vm.openInfo = function ($index) {
          Marketplace.openInfo(vm.items[$index]);
        };
      }]
    };
  }
}());
