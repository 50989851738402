(function () {
  'use strict';

  angular
    .module('components.ui')
    .directive('contentSlider', contentSlider);

  function contentSlider($rootScope, $window, $timeout) {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint no-unused-vars: [2, {"args": "none", "varsIgnorePattern": "Handler" }]*/

        var contents,
            container,
            headers,
            buttons,
            el,
            parseContents,
            computeSize,
            width,
            currentStep = 0,
            currentContent,
            stepToClickHandler,
            manageHeightAndVisibility,
            updateHeader,
            eventHandler;

        stepToClickHandler = function (btn, stepId) {
          angular.element(btn).click(stepId, scope.stepTo);
        };

        updateHeader = function () {
          angular.forEach(headers, function (header) {
            var headerStep;
            header = angular.element(header);
            headerStep = header.attr('data-step-id');

            if (angular.isUndefined(headerStep)) {
              return;
            }
            headerStep = Number(headerStep);
            if (headerStep <= currentStep) {
              header.addClass('active');
            }
            else {
              header.removeClass('active');
            }
          });
        };

        manageHeightAndVisibility = function (isResize) {
          var lastContent = currentContent;

          angular.forEach(contents, function (content) {
            var targetStep;
            content = angular.element(content);
            targetStep = angular.element(content).attr('data-step-id');
            if (angular.isUndefined(targetStep)) {
              return;
            }
            targetStep = Number(targetStep);

            if (targetStep === currentStep) {
              computeSize(content);
              currentContent = content;

              if (lastContent && !isResize) {
                // The currentContent becomes previous content. Hide it.
                lastContent.css({
                  visibility: 'hidden',
                  height: '0px',
                  opacity: 0
                });
              }
            }
          });
        };

        computeSize = function (content) {
          var contentHeight;
          // Fix the height so it doesn't 'jump' when setting the child content to 'auto'
          container.css({
            height: container[0].clientHeight + 'px'
          });

          // Set the target content to be visible, non-transparent and reset its height
          // Try to calculate the height. Temporarily set it to auto, calculate content and apply.
          content.css('height', 'auto');
          contentHeight = content[0].clientHeight;

          content.css({
            visibility: 'visible',
            opacity: 1,
            height: contentHeight + 'px'
          });
          container.css({
            height: contentHeight + 'px'
          });

          // That will last 0.2s and apply auto height (due to the dynamic content that might be there. You don't
          // want fixed height then
          $timeout(function () {
            content.css('height', 'auto');
            container.css('height', 'auto');
          }, 200);
        };

        parseContents = function (isResize, stepTo) {
          el = element;

          // These are the containers
          contents = angular.element('.step-content', el);
          container = angular.element('.stepped-container', el);
          buttons = angular.element('button[data-step-to], a[data-step-to]', el);
          headers = angular.element('.step[data-step-id]');

          if (contents.length === 0 || container.length === 0) {
            // Not much to do
            return;
          }

          // First, make sure we stretch the container to some width wide enough so the scrollbar doesn't appear as that would
          // wrongly calculate the width with the scrollbar included to our contents.
          container.width('10000px');

          width = el.width();

          // Calculate the width of the individual contents
          contents.css({
            width: width + 'px'
          });

          // Calculate the width of the wrapper
          container.css({
            width: width * contents.length + 'px',
            overflow: 'hidden',
            position: 'relative',
            transform: 'translateX(0px)',
            transition: 'all 0.2s ease-in-out',
            whiteSpace: 'nowrap'
          });

          if (!isResize) {
            // We must set the visibility: hidden so the focus on elements is disabled!
            angular.forEach(contents, function (cnt, idx) {
              if (idx !== 0) {
                angular.element(cnt).css({
                  visibility: 'hidden'
                });
              }
            });

            // Bind the step to to our function
            angular.forEach(buttons, function (btn) {
              var stepId = angular.element(btn).attr('data-step-to');
              if (angular.isUndefined(stepId)) {
                return;
              }
              stepToClickHandler(btn, Number(stepId));
            });

            // Set the first content to
            currentContent = angular.element(contents[0]);
            currentContent.css({
              height: 'auto',
              visibility: 'visible'
            });
            container.css('height', 'auto');
            updateHeader();
          }
          else {
            scope.stepTo({
              data: angular.isDefined(stepTo) ? stepTo : currentStep,
              isResize: true
            });
          }
        };

        scope.stepTo = function (evt) {
          var stepId = evt.data,
              shiftBy = -1 * stepId * width;

          if (stepId === currentStep && !evt.isResize) {
            return;
          }

          container.css('transform', 'translateX(' + shiftBy + 'px)');
          currentStep = stepId;
          manageHeightAndVisibility(evt.isResize);
          updateHeader();
        };

        angular.element($window).on('resize', function () {
          parseContents(true);
        });

        eventHandler = $rootScope.$on('event:slider-do-layout', function (evtName, data) {
          $timeout(function () {
            if (data && data.stepTo >= 0) {
              parseContents(true, data.stepTo);
            }
            else {
              parseContents(true, currentStep ? currentStep : 0);
            }
          }, data && data.delay ? data.delay : 100);
        });
        scope.$on('$destroy', eventHandler);

        $timeout(function () {
          parseContents();
        }, 100);
      }
    };
  }
}());
