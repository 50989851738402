<div class="credit-card">
  <form name="ccForm" id="ccForm">
    <!-- Existing Card Info -->
    <div class="registered-card-info row slide-if" ng-if="!cc.showCCForm(ccForm)">
      <div class="col-xs-12" style="display: flex;">
        <div class="credit-card-details">
          <i class="fa fa-credit-card"></i>
          <span class="credit-card-number">{{cc.scope.creditCard.number}}</span>
          <span class="credit-card-expiry">
                                    ({{cc.scope.creditCard.expiryMonth}}/20{{cc.scope.creditCard.expiryYear}})
                                  </span>
        </div>
        <a ng-click="cc.newCard=true; cc.scope.ccData.method='new';" style="cursor: pointer;">Change</a>
      </div>
    </div>

    <!-- New Card Form -->
    <div class="new-card-form slide-if" ng-if="cc.showCCForm(ccForm)">
      <div style="display: flex; justify-content: space-between; position: relative; width: 100%; flex-direction: column;">
        <div class="" style="position: relative; width: 100%;">

          <!-- CC Prompt -->
          <div class="card-prompt" style="display: flex; justify-content: space-between;">
            <div>
              Provide credit card details:
            </div>
            <a ng-if="cc.scope.creditCard && cc.scope.creditCard.number"
               class="cursor-pointer"
               style="flex-shrink:0;"
               ng-click="cc.closeCCForm(ccForm)">Use Registered Card</a>
          </div>

          <!-- CC Details-->
          <div class="cc-container">
            <div class="">
              <div class="row">
                <div class="col-xs-7" style="margin-bottom: 15px;">
                  <i class="fa fa-cc-visa fa-2x"></i> <i class="fa fa-cc-mastercard fa-2x"></i> <i class="fa fa-cc-amex fa-2x"></i>
                </div>
                <div class="col-xs-5" style="margin-bottom: 15px;">
                  <!-- Begin eWAY Linking Code -->
                  <div id="eWAYBlock">
                    <div style="text-align:right;">
                      <a href="https://www.eway.com.au/secure-site-seal?i=13&s=7&pid=880d42e7-6908-4374-8cfe-19357ae619da&theme=0" title="eWAY Payment Gateway" target="_blank" rel="nofollow">
                        <img alt="eWAY Payment Gateway" src="https://www.eway.com.au/developer/payment-code/verified-seal.ashx?img=13&size=7&pid=880d42e7-6908-4374-8cfe-19357ae619da&theme=0" />
                      </a>
                    </div>
                  </div>
                  <!-- End eWAY Linking Code -->
                </div>
              </div>

              <div class="row">
                <div class="col-xs-12">
                  <div class="form-group" ng-class="{'has-error': (ccForm.$submitted || ccForm.holderName.$touched) && ccForm.holderName.$error.required}">
                    <label class="sr-only" for="holderName">Name on card</label>
                    <div ng-show="ccForm.$submitted || ccForm.holderName.$touched">
                      <div class="input-error" ng-show="ccForm.holderName.$error.required">Missing name on card.</div>
                    </div>
                    <div class="left-inner-addon">
                      <i class="fa fa-user inner-addon"></i>
                      <input class="form-control input-lg" type="text" name="holderName" id="holderName"
                             placeholder="Name on card" ng-required="true" ng-model="cc.scope.ccData.holderName" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xs-12">
                  <div class="form-group" ng-class="{'has-error': (ccForm.$submitted || ccForm.number.$touched) && (ccForm.number.$error.required || ccForm.number.$error.ccNumber)}">
                    <label class="sr-only" for="number">Card number</label>
                    <div ng-show="ccForm.$submitted || ccForm.number.$touched">
                      <div class="input-error" ng-show="ccForm.number.$error.required">Missing card number.</div>
                      <div class="input-error" ng-show="!ccForm.number.$error.required && ccForm.number.$error.ccNumber">Invalid card number.</div>
                    </div>
                    <div class="left-inner-addon">
                      <i class="fa fa-credit-card inner-addon"></i>
                      <input class="form-control input-lg" type="text" name="number" id="number"
                             placeholder="Card number" ng-required="true" ng-model="cc.scope.ccData.number"
                             cc-number cc-type="cc.cards" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" cc-exp>
                <div class="col-xs-12">
                  <div ng-show="ccForm.$submitted || (ccForm.expiryMonth.$touched && ccForm.expiryYear.$touched)">
                    <div class="input-error" ng-show="ccForm.$error.ccExp">Invalid expiry date.</div>
                  </div>
                </div>
                <div class="col-xs-6 col-sm-6">
                  <div class="" ng-class="{'has-error': (ccForm.$submitted || ccForm.expiryMonth.$touched) && ccForm.expiryMonth.$error.required}">
                    <label class="sr-only" for="expiryMonth">Expiry month</label>
                    <div ng-show="ccForm.$submitted || ccForm.expiryMonth.$touched">
                      <div class="input-error" ng-show="ccForm.expiryMonth.$error.required">Missing Expiry month.</div>
                    </div>
                    <div class="left-inner-addon">
                      <i class="fa fa-calendar inner-addon"></i>
                      <select class="form-control input-lg" name="expiryMonth" id="expiryMonth" placeholder="Expiry month" ng-required="true"
                              ng-model="cc.scope.ccData.expiryMonth" cc-exp-month>
                        <option value="" disabled selected>Expiry month</option>
                        <option ng-repeat="month in cc.months" value="{{month}}">{{month}}</option>
                      </select>
                    </div>
                  </div>

                </div>

                <div class="col-xs-6 col-sm-6">
                  <div class="" ng-class="{'has-error': (ccForm.$submitted || ccForm.expiryYear.$touched) && ccForm.expiryYear.$error.required}">
                    <label class="sr-only" for="expiryYear">Expiry year</label>
                    <div ng-show="ccForm.$submitted || ccForm.expiryYear.$touched">
                      <div class="input-error" ng-show="ccForm.expiryYear.$error.required">Missing Expiry year.</div>
                    </div>
                    <div class="left-inner-addon">
                      <i class="fa fa-calendar inner-addon"></i>
                      <select class="form-control input-lg" name="expiryYear" id="expiryYear" placeholder="Expiry year" ng-required="true"
                              ng-model="cc.scope.ccData.expiryYear" cc-exp-year full-year>
                        <option value="" disabled selected>Expiry year</option>
                        <option ng-repeat="year in cc.years" value="{{year}}">{{year}}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <!--<div class="col-xs-12" ng-if="cc.scope.creditCard && cc.scope.creditCard.number">
          <a style="cursor: pointer;"
             ng-click="cc.closeCCForm(ccForm)">Use Registered Card</a>
        </div>-->
      </div>
    </div>
  </form>
</div>
