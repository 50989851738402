(function () {
  'use strict';

  /* @ngdoc object
   * @name myMemberships.rewardProgram
   * @description
   *
   */
  angular
    .module('myMemberships.rewardProgram', [
      'ui.router',
      'ngclipboard'
    ]);
}());
