(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name mlpMarketplace.controller:MarketplaceCtrl
   * @requires $timeout
   * @requires $uibModalInstance
   * @requires userProfile
   * @requires components.ui.PromptDialog
   * @requires components.FormAlerts
   * @requires mlpMarketplace.Marketplace
   * @description
   *
   */
  angular
    .module('mlpMarketplace')
    .controller('MarketplaceCtrl', MarketplaceCtrl);

  function MarketplaceCtrl(Marketplace, userProfile, marketplaceItem, Notification) {
    var vm = this,
        items = userProfile.marketplace,
        mlpFilter;

    vm.isGrid = true;
    vm.viewMLP = true;
    vm.userProfile = userProfile;

    // MLP Items have priority
    mlpFilter = _.filter(items, function (item) {
      return angular.isObject(item.owner) && ((/cs(\+([\s\S]*?))?@myleadpod\.com/).test(item.owner.email) || item.owner.email === 'sales@myleadpod.com');
    });
    vm.mlpItems = _.sortBy(mlpFilter, 'label');

    // Other Marketplace items
    vm.items = _.filter(items, function (item) {
      return !angular.isObject(item.owner) || !(/cs(\+([\s\S]*?))?@myleadpod\.com/).test(item.owner.email) && item.owner.email !== 'sales@myleadpod.com';
    });

    // Check if we are opening the whole marketplace or details of the addon directly
    if (angular.isObject(marketplaceItem)) {
      // Seems like we have the addon object. open the details
      Marketplace.openInfo(marketplaceItem);
    }
    else if (marketplaceItem === false) {
      // Display notification that the marketplace addon doesn't exist
      Notification({message: 'The requested Marketplace Item is invalid or it doesn\'t exist.', duration: 5000}, 'warning');
    }
  }
}());
