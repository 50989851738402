(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name myMemberships.import.controller:ImportCtrl
   *
   * @description
   *
   */
  angular
    .module('myMemberships.import')
    .controller('ImportCtrl', ImportCtrl);

  function ImportCtrl(Upload, Auth, FormAlerts, Notification) {
    var vm = this;
    vm.downloadTemplateUrl = '/Memberships/importTemplate';
    vm.acceptedFiles = 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,*.xls,*.xlsx';
    vm.isUploading = false;
    vm.importProgressPercent = 0;
    vm.alerts = new FormAlerts();
    vm.importFinished = false;
    vm.alertsExpanded = false;

    vm.upload = function (files) {
      if (!vm.isUploading && files && files[0]) {
        vm.isUploading = true;
        vm.importProgressPercent = 0;
        vm.alerts.reset();
        Upload.upload({
          url: Auth.fixApiEndpointUrl('/Memberships/import'),
          data: {file: files[0]},
          method: 'POST'
        })
          .then(function (result) {
            if (result.data.content.status === true) {
              vm.importFinished = true;
              Notification({message: 'Success'}, 'confirmation');
            }
            else {
              angular.forEach(result.data.content.errors, function (item) {
                vm.alerts.add('danger', item.message);
              });
            }
            vm.isUploading = false;
          }, function () {
            vm.alerts.add('danger', 'Unable to import');
            vm.isUploading = false;
          }, function (evt) {
            vm.importProgressPercent = parseInt(100.0 * evt.loaded / evt.total, 10);
          });
      }
    };
  }
}());
