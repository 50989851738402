<div class="social-share-container">
  <span>
    <a class="social-share-button-share" data-options="align:left" data-dropdown="dropdown-share{{socialShare.id}}">
      <i class="fa fa-share-alt"></i> Share
    </a>
  </span>

  <ul id="dropdown-share{{socialShare.id}}" class="f-dropdown drop-left" data-dropdown-content>
    <li><a class="social-share-button" ng-click="socialShare.share('facebook')"><i class="fa fa-facebook-square"></i> Facebook</a></li>
    <li><a class="social-share-button" ng-click="socialShare.share('linkedin')"><i class="fa fa-linkedin"></i> LinkedIn</a></li>
    <li><a class="social-share-button" ng-click="socialShare.share('twitter')"><i class="fa fa-twitter"></i> Twitter</a></li>
    <li><a class="social-share-button" ng-click="socialShare.share('google')"><i class="fa fa-google-plus"></i> Google+</a></li>
  </ul>

  <script type="text/javascript">
    $(document).foundation({
      dropdown: {
        active_class: 'open'
      }
    });
  </script>
</div>
