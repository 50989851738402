<div class="col-xs-12" id="connections-ctrl-container" ng-controller="ConnectionsCtrl as connections">
  <div class="page-content">

    <!-- No connections content -->
    <div class="row" ng-hide="connections.list.recordsExist() || !connections.list.isInitialised()">
      <div class="col-xs-12">
        <div class="page-empty-section text-center">
          <i class="fa fa-users fa-2x text-light-colour"></i>
          <div class="page-empty-section-text">
            {{connections.userProfile.fullName}} doesn't have any connections.
          </div>
        </div>
      </div>
    </div>

    <!-- Search Input box -->
    <div class="row" ng-show="connections.list.recordsExist() && connections.list.isInitialised()">
      <div class="col-xs-12 col-sm-12 col-md-12">
        <span class="sub-title-description">Following is the list of businesses that {{connections.userProfile.firstName}} is sending leads to</span>
      </div>
    </div>

    <div class="row form-section-body" ng-show="connections.list.recordsExist() && connections.list.isInitialised()">
      <div class="col-xs-12 col-sm-5 col-md-4">
        <div class="form-group page-filter">
          <label class="sr-only" for="searchName">Search</label>
          <div class="left-inner-addon">
            <i class="fa fa-search inner-addon"></i>
            <form name="connectionsSearchForm" ng-submit="connections.search()">
              <input class="form-control input-lg"
                     type="text"
                     name="searchName"
                     id="searchName"
                     placeholder="Search"
                     ng-model="connections.searchName"
                     ng-blur="connections.search()"/>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="table-responsive fixed-header" ng-show="connections.list.recordsExist() && connections.list.isInitialised()">
      <div class="table-container">
        <table class="table page-content-table allow-wrap" st-table="connections.list.getRecords()" st-pagination-scroll st-pipe="connections.list.load">

          <thead class="hidden-xs">
            <tr>
              <th class="col-sm-4 col-md-3">Name</th>
              <th class="col-sm-4 col-md-5">Industry</th>
              <th class="col-sm-2 col-md-2">Reward (up to)</th>
              <th class="col-sm-2 col-md-2 text-center" style="padding-right: 10px;">Join</th>
            </tr>
          </thead>

          <tbody ng-hide="connections.list.isLoading() && !connections.list.isLoadingMoreRecords()">
            <tr ng-repeat="row in connections.list.getRecords()" header-check>
              <td class="col-xs-12 col-sm-4 col-md-3">
                  <table class="table-cell-main-content">
                    <tr class="row-center-items row-space-between">
                      <td class="visible-xs" single-expand sub-content="connections.tableSubContentVisible" sub-content-idx="$index"></td>
                    <td>
                      <table-user-details user="row.member"></table-user-details>
                    </td>
                    <td class="visible-xs text-right">
                      <span class="cursor-pointer">
                        <i class="fa fa-ellipsis-h"></i>
                      </span>
                    </td>
                    </tr>
                  </table>

                <!-- This content applies only for the small devices and is driven by the "tableSubContentVisible" property -->
                <div class="table-cell-sub-content-container slide-if" ng-if="connections.tableSubContentVisible[$index]">
                  <div ng-if="row.member.industry.name" class="table-cell-sub-content visible-xs">
                    <span class="text-muted">Industry</span><br/>
                    <span class="text-normal">{{row.member.industry.name}}</span>
                    <span class="text-normal" ng-if="row.member.subIndustry"><br/>{{row.member.subIndustry.name}}</span>
                  </div>
                  <div ng-if="row.member.highestReward" class="table-cell-sub-content visible-xs">
                    <span class="text-muted">Reward (up to)</span><br/>
                    <span ng-if="row.member.highestReward" class="text-normal">{{row.member.highestReward | currency:'$':0}}</span>
                  </div>
                  <div class="table-cell-sub-content visible-xs">
                    <span ng-show="$index == connections.invitePendingFor"><i class="fa fa-1x fa-circle-o-notch fa-spin-2x"></i></span>
                    <button ng-hide="row.member.acceptedSendLeadsContractExists || $index == connections.invitePendingFor" ng-disabled="connections.invitePending || row.member.acceptedSendLeadsContractExists " ng-click="connections.quickJoin($index);" class="btn-sm btn-primary">Join</button>
                    <span ng-show="row.member.acceptedSendLeadsContractExists" class="text-muted">
                      <i class="fa fa-check" aria-hidden="true"></i>
                      <span> (Connected)</span>
                    </span>
                  </div>
                </div>
              </td>
              <td class="col-sm-4 col-md-5 hidden-xs">
                {{row.member.industry.name}}
                <span ng-if="row.member.subIndustry">
                  <br/>{{row.member.subIndustry.name}}
                </span>
              </td>
              <td class="col-sm-2 col-md-2 hidden-xs text-middle">
                <span ng-if="row.member.highestReward" class="text-normal">{{row.member.highestReward | currency:'$':0}}</span>
              </td>
              <td class="col-sm-2 col-md-2 hidden-xs text-center text-middle" style="overflow: hidden;">
                <span ng-show="$index == connections.invitePendingFor"><i class="fa fa-1x fa-circle-o-notch fa-spin-2x"></i></span>
                <button ng-hide="row.member.acceptedSendLeadsContractExists || $index == connections.invitePendingFor" ng-disabled="connections.invitePending || row.member.acceptedSendLeadsContractExists " ng-click="connections.quickJoin($index);" class="btn btn-primary">Join</button>
                <span ng-show="row.member.acceptedSendLeadsContractExists" title="This user is a member of your pod" class="text-muted"><i class="fa fa-check" aria-hidden="true"></i></span>
              </td>
            </tr>
            <!--<tr ng-if="connections.list.isLoadingMoreRecords()">
              <td colspan="5" class="col-xs-12 text-center">
                <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>
              </td>
            </tr>-->
          </tbody>
          <tbody ng-if="connections.list.isLoading() && !connections.list.isLoadingMoreRecords()" class="slide-if" style="display: block; border: 0px solid transparent; height: auto;">
            <tr>
              <td colspan="5" class="col-xs-12 text-center">
                <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>
              </td>
            </tr>
          </tbody>
        </table>
        <div ng-if="connections.list.isLoadingMoreRecords()" class="slide-if" style="position: absolute; top:0px; left: 0px; display: flex; width: 100%; height: 100%; align-items: center; justify-content: center;">
          <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>
        </div>
      </div>
    </div>

    <!-- Spin if the list is not yet initialized -->
    <div class="row" ng-show="!connections.list.isInitialised()">
      <div class="col-xs-12 text-center">
        <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>
      </div>
    </div>

  </div>
</div>
