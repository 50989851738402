(function () {
  'use strict';

  /* @ngdoc object
   * @name mlp-messaging
   * @description
   *
   */
  angular
    .module('mlp-messaging', []);
}());
