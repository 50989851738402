<div class="action-toolbar">
  <div>
    <div class="secondary-container">

    </div>
    <div class="primary-container">

    </div>
    <div id="at-transcluded" class="transcluded-content no-select">
      <div class="container-scroll-shadow" scroll-check with-delay="300" target-element-id="transcluded-{{id}}">
        <div class="scroll-left-indicator"><span style="transform: rotate(180deg);">&#9658;</span></div>
        <div class="scroll-right-indicator"><span>&#9658;</span></div>
      </div>
      <div id="transcluded-{{id}}" class="transcluded-content no-select" ng-transclude>
        <!-- tanscluded contnet -->
      </div>
    </div>
  </div>
</div>
