<div id="{{controlId}}"
    class="search-autocomplete-holder"
    ng-click="checkFocus($event)"
    ng-class="{'search-focused': searchFocused == true, 'filter-active': filterActive, 'search-autocomplete-dropdown-visible': showDropdown}">

    <div class="input-holder">
        <div class="visible-xs back-button" ng-click="backBtnClicked()" ng-if="hasBackButton">
            <i class="fa fa-arrow-left"></i>
        </div>
        <input id="{{controlId}}_value"
            name="{{inputName}}"
            tabindex="{{fieldTabindex}}"
            type="{{inputType}}"
            placeholder="{{placeholder}}"
            maxlength="{{maxlength}}"
            class="{{inputClass}}"

            ng-focus="onFocusHandler()"
            ng-blur="hideResults($event, true)"
            ng-class="{'search-autocomplete-input-not-empty': notEmpty}"
            ng-model="searchStr"
            ng-disabled="disableInput"
            ng-change="inputChangeHandler(searchStr)"

            autocapitalize="off"
            autocorrect="off"
            autocomplete="off" />

        <div ng-class="{'search-autocomplete-searching': searching}" class="search-autocomplete-icons">
            <div class="search-filter" tabindex="1" role="button" ng-click="openFilter()" ng-if="searchCategories && searchCategories.length > 1 && hasCategoryFilter">
                <i class="fa fa-chevron-down" />
            </div>
            <div class="icons">
                <i class="pending fa fa-circle-o-notch fa-spin-2x"></i>
                <i class="search fa fa-search opacity-if" ng-if="checkSearch()"></i>
                <i class="fa fa-times opacity-if" ng-click="clearInput()" ng-if="checkInput()"/>
            </div>
        </div>

    </div>

    <div id="{{controlId}}_filter_dropdown" class="search-autocomplete-dropdown search-filter-dropdown no-select slide-if" ng-if="showFilterDropdown">
        <span class="search-filter-description">Narrow your search by choosing the search categories:</span>
        <nav>
            <ul>
                <li ng-repeat="category in searchCategories">
                    <div ng-class="{'filter-selected': isSelected('{{category.value}}')}" ng-click="changeCategory(category.value, null, false)"><i class="fa fa-check"></i>{{category.name}}</div>
                </li>
            </ul>
        </nav>
    </div>

    <div id="{{controlId}}_history_dropdown"
         class="search-autocomplete-dropdown no-select slide-if"
         ng-if="hasHistory && showHistoryDropdown && historyItems.length > 0"
         style="cursor: inherit;">

      <!-- search history -->
      <div class="results-container">
        <span class="search-filter-description">Your last selected items:</span>
        <!-- result item -->
        <div class="results-container" ng-class="{searching: searching}">
          <div class="search-autocomplete-row no-animate"
               ng-repeat="result in historyItems"
               ng-click="selectHistoryItem(result)"
               ng-mouseenter="hoverRow($index, true)"
               ng-mouseleave="removeHover(true)"
               ng-class="{'search-autocomplete-selected-row': $index == currentHistoryIndex}">

            <div ng-if="!resultTemplateUrl" class="result-content">
              <div ng-if="imageField" class="search-autocomplete-image-holder">
                <img ng-if="result.image && result.image != ''" ng-src="{{result.image}}" class="search-autocomplete-image"/>
                <div ng-if="!result.image && result.image != ''" class="search-autocomplete-image-default"></div>
              </div>
              <div ng-if="matchClass && result.description && result.description != ''" class="search-autocomplete-description" ng-bind-html="result.description"></div>
              <div ng-if="!matchClass && result.description && result.description != ''" class="search-autocomplete-description">{{result.description}}</div>
              <div class="search-autocomplete-title" ng-if="matchClass" ng-bind-html="result.title"></div>
              <div class="search-autocomplete-title" ng-if="!matchClass">{{ result.title }}</div>
              <div ng-if="result.description2 && result.description2 != ''" class="search-autocomplete-description2">{{result.description2}}</div>
            </div>
            <div ng-if="resultTemplateUrl" ng-include src="resultTemplateUrl">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="{{controlId}}_dropdown" class="search-autocomplete-dropdown slide-css" ng-class="{'slide-fast': showDropdown}">
    <!--<div id="{{controlId}}_dropdown" class="search-autocomplete-dropdown">-->

        <!-- category filter -->
        <div class="result-category-filter no-select" ng-if="searchCategories && searchCategories.length > 1 && hasCategoryFilter">
            <div>Filter:</div>
            <div class="category-filters">
                <div ng-repeat="category in searchCategories"
                    ng-class="{'filter-selected': isSelected('{{category.value}}'), 'has-results': category.hasResults}"
                    ng-click="changeCategory(category.value, $event, true)"
                    data-category-value="category.value">
                    <div class="category-badge"></div>{{category.name}}
                </div>
            </div>
        </div>

        <!-- no results found -->
        <div class="search-autocomplete-searching" ng-show="!searching && (!results || results.length == 0)" ng-bind="textNoResults"></div>

        <!-- result item -->
        <div class="results-container" ng-class="{searching: searching}">
            <div class="search-autocomplete-row no-animate"
                ng-repeat="result in results"
                ng-click="selectResult(result)"
                ng-mouseenter="hoverRow($index)"
                ng-mouseleave="removeHover()"
                ng-class="{'search-autocomplete-selected-row': $index == currentIndex}">

                <div ng-if="!resultTemplateUrl" class="result-content">
                    <div ng-if="imageField" class="search-autocomplete-image-holder">
                        <img ng-if="result.image && result.image != ''" ng-src="{{result.image}}" class="search-autocomplete-image"/>
                        <div ng-if="!result.image && result.image != ''" class="search-autocomplete-image-default"></div>
                    </div>
                    <div ng-if="matchClass && result.description && result.description != ''" class="search-autocomplete-description" ng-bind-html="result.description"></div>
                    <div ng-if="!matchClass && result.description && result.description != ''" class="search-autocomplete-description">{{result.description}}</div>
                    <div class="search-autocomplete-title" ng-if="matchClass" ng-bind-html="result.title"></div>
                    <div class="search-autocomplete-title" ng-if="!matchClass">{{ result.title }}</div>
                    <div ng-if="result.description2 && result.description2 != ''" class="search-autocomplete-description2">{{result.description2}}</div>
                </div>
                <div ng-if="resultTemplateUrl" ng-include src="resultTemplateUrl">
                </div>
            </div>
        </div>
    </div>
</div>
