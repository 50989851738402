(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name user.leads.controller:UserSentLeadsCtrl
   *
   * @description
   *
   */
  angular
    .module('user.leads')
    .controller('UserSentLeadsCtrl', UserSentLeadsCtrl);

  function UserSentLeadsCtrl($scope, DataTable, Leads) {
    var vm = this;
    vm.userProfile = $scope.user.userProfile;
    vm.statuses = {
      all: 'All leads',
      open: 'Delivered',
      new: 'Notified',
      successful: 'Successful',
      unsuccessful: 'Unsuccessful'
    };
    vm.list = DataTable.create('Leads', 'index', {}, {
      basicParams: {
        type: 'sent',
        idUser: vm.userProfile.id
      }
    });

    vm.openDetail = function ($index) {
      Leads.openDetail(vm.list.getRecords()[$index], 'sent', 'user-sent-leads-ctrl-container');
    };

    $scope.$on('sentLeads-success', function () {
      if (vm.list) {
        vm.list.reload();
      }
    });
  }
}());
