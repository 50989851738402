(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.ui.tabs.directive:tabsManager
   * @restrict EA
   * @element
   */
  angular
    .module('components.ui.tabs')
    .directive('tabsManager', tabsManager);

  function tabsManager() {
    return {
      restrict: 'EA',
      scope: {
        // Tab id
        tabId: '@',
        // Configuration object with all the tabs that this directive will have
        tabsConfig: '=',
        // Default tab value
        defaultTab: '@',
        // Tab Manager instance. This can be user by the implementing scope to get the instance of this directive in parent controller.
        tabManager: '=',
        // On change callback
        change: '&onChange',
        // Target in action toolbar (primary:default or secondary) that this instance will be relocated to in xs devices
        target: '@',
        // Whether to block the relocation into the action toolbar for xs devices
        blockXsRelocation: '='
      },
      templateUrl: 'components/ui/tabs/tabs-manager-directive.tpl.html',
      replace: true,
      controllerAs: 'tabsManager',
      controller: ['$scope', '$document', '$compile', 'RouterHelper', 'Tabs', function ($scope, $document, $compile, RouterHelper, Tabs) {
        var vm = this,
            lastLabel;

        vm.tabId = angular.isDefined($scope.tabId) ? $scope.tabId : (new Date()).getTime();
        vm.tabsConfig = angular.isDefined($scope.tabsConfig) ? $scope.tabsConfig : [];
        vm.blockXsRelocation = angular.isDefined($scope.blockXsRelocation) ? $scope.blockXsRelocation : false;
        vm.tabs = _.pluck(vm.tabsConfig, 'value');
        vm.isScrolling = false;

        if (!vm.tabs || vm.tabs.length === 0 || !vm.tabId) {
          throw new Error('[tabs-manager-directive]: List of names must not be empty');
        }
        vm.defaultTab = angular.isDefined($scope.defaultTab) ? $scope.defaultTab : vm.tabsConfig[0].value;
        vm.target = $scope.target ? $scope.target : 'primary';

        // Create the tab manager
        vm.tabManager = Tabs.create(vm.tabId, vm.tabs, vm.defaultTab, $scope);

        // Re-assign the value to parent controller so we can show/hide the templated content.
        $scope.tabManager = vm.tabManager;

        // Default value for the dropdown visibility
        vm.isDropDownOpen = false;

        // Set the currently selected tab (default tab value) and then watch the tabs-factory ActiveTab value
        vm.currentTabLabel = _.find(vm.tabsConfig, {value: vm.defaultTab}).label;

        vm.openTab = function (tabName, checkScroll, $event) {
          var el;

          if (checkScroll && $event) {
            // This is the scenario where openTab is invoked through click on the action toolbar's tab. (xs devices)
            el = $event.currentTarget;
            vm.scrollToTab(el);
          }
          else if (checkScroll) {
            // This is the scenario where openTab is invoked through RouterHelper
            // Check for the element. if the element exists, it means we are in the xs size and we need to scroll to the tab
            el = angular.element('[data-tab-name="' + vm.tabId + '-' + tabName + '"] a');
            if (el && el[0] && !vm.isScrolling) {
              vm.scrollToTab(el[0]);
            }
          }

          if (tabName) {
            // Finally set the tab active in any scenrio
            vm.tabManager.openTab(tabName);
          }
        };

        vm.scrollToTab = function (element) {
          var parent,
              vw,
              elWidth,
              elLeft,
              scrollLeft,
              diff;

          if (!element || vm.isScrolling) {
            return;
          }
          parent = element.parentElement;
          if (parent) {
            // Means we are in xs view (very likely). Get the parent Element and its parent and comparet to scroll vals
            vw = $document[0].documentElement.clientWidth;
            elWidth = parent.clientWidth;
            elLeft = angular.element(parent).position().left;
            scrollLeft = parent.parentElement.scrollLeft;
            diff = elLeft + elWidth / 2 - vw / 2;
            vm.isScrolling = true;
            angular.element(parent.parentElement).animate({scrollLeft: scrollLeft + diff}, {
              easing: 'linear',
              duration: 200,
              complete: function () {
                vm.isScrolling = false;
              }
            });
          }
        };

        vm.getActiveLabel = function () {
          var label;

          if (!vm.tabManager.activeTab) {
            return null;
          }

          label = _.find(vm.tabsConfig, {value: vm.tabManager.activeTab}).label;
          if (lastLabel !== label) {
            $scope.change({activeTab: vm.tabManager.activeTab});
            lastLabel = label;
          }
          return label;
        };

        // Subscribe
        angular.forEach(vm.tabs, function (tabName) {
          // Maybe the event has been already triggered
          if (RouterHelper.lastEvent && RouterHelper.lastEvent.id === 'event:' + vm.tabId + '-open-tab-' + tabName) {
            vm.openTab(tabName, true);
          }

          $scope.$on('event:' + vm.tabId + '-open-tab-' + tabName, function () {
            vm.openTab(tabName, true);
          });
        });
      }]
    };
  }
}());
