(function () {
  'use strict';

  /* @ngdoc object
   * @name account.password
   * @description
   *
   */
  angular
    .module('account.password', [
      'vcRecaptcha',
      'validation.match'
    ]);
}());
