(function () {
  'use strict';

  angular
    .module('mlpMarketplace')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('marketplace', {
        url: '/marketplace/:id',
        templateUrl: 'mlp-marketplace/marketplace.tpl.html',
        controller: 'MarketplaceCtrl',
        controllerAs: 'marketplace',
        authenticate: true,
        params: {
          id: {
            value: null,
            squash: true
          }
        },
        resolve: {
          userProfile: ['Session', function (Session) {
            return Session.getUserProfile();
          }],
          marketplaceItem: ['$stateParams', 'Restangular', 'Auth', function ($stateParams, Restangular, Auth) {
            return Auth.isAuthenticated().then(function (isAuthenticated) {
              if (isAuthenticated) {
                // If no ID is provided, we will show all marketplace addons
                if (!$stateParams.id) {
                  return null;
                }
                // If ID is provided, then we will try to load the marketplace item. If not found, display a message
                return Restangular.one('Marketplace').one('index').get({id: $stateParams.id})
                  .then(function (addon) {
                    return addon.plain();
                  }, function () {
                    return false;
                  });
              }
              return null;
            }, function () {
              return null;
            });
          }]
        }
      });
  }
}());
