(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name myPod.service:MyPod
   *
   * @description
   *
   */
  angular
    .module('lead')
    .service('MyPod', MyPod);

  function MyPod(Restangular, $document, $uibModal) {
    var self = this;

    /**
     * Joins the currently logged in user to the other user's pod and his default reward program.
     *
     * @param {string} userId        Id of the user that current user will join (will become member of his pod)
     * @return {Deferred} Deferred promise
     */
    self.quickJoin = function (userId) {
      return Restangular.one('Members').post('joinRewardProgram', {idUser: userId});
    };

    /**
     * Opens the modal dialog and sends invite to the business so the logged-in user can start sending the leads.
     * @param {string} myProfile    Profile of the logged in user
     * @param {string} userProfile  Profile of the targeted user (business)
     * @return {Deferred} Deferred promise
     */
    self.openSendInvite = function (myProfile, userProfile) {
      var slModalInstance = $uibModal.open({
        animation: false,
        appendTo: angular.element($document[0].body),
        templateUrl: 'my-pod/send-invite/send-invite-modal.tpl.html',
        controller: 'SendInviteModalCtrl',
        controllerAs: 'sendInvite',
        size: 'md',
        resolve: {
          myProfile: function () {
            return myProfile;
          },
          userProfile: function () {
            return userProfile;
          }
        }
      });

      return slModalInstance.result;
    };
  }
}());
