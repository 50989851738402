<div class="row">
  <div class="col-xs-12 col-sm-6">
    <h1 class="page-header">My Pod</h1>
  </div>
  <div class="col-sm-6 hidden-xs">
    <div class="page-header-buttons">
      <a ng-click="myPod.sendInvite();" class="btn btn-primary btn-shadow btn-lg-lg">Invite to my Pod</a>
      <a ui-sref="leadSend" class="btn btn-success btn-shadow btn-lg-lg">Send Lead</a>
    </div>
  </div>
</div>


<div class="row">
  <div class="col-xs-12">
    <tabs-manager tab-id="myPod" tabs-config="myPod.tabsConfig" default-tab="members" tab-manager="myPod.tabManager"></tabs-manager>
  </div>
</div>

<div class="row" ng-show="myPod.tabManager.isOpen('members')" ng-include="'my-pod/members/members.tpl.html'">
</div>

<div class="row" ng-show="myPod.tabManager.isOpen('sentLeads')" ng-include="'my-pod/sent-leads/sent-leads.tpl.html'">
</div>

<action-toolbar class="visible-xs" at-target="secondary">
  <a class="no-animate fa fa-envelope" label="Invite to my Pod" ng-click="myPod.sendInvite();"><span class="tool-label">Invite to my Pod</span></a>
  <a class="no-animate fa fa-paper-plane" label="Send Lead" ui-sref="leadSend"><span class="tool-label">Send Lead</span></a>
</action-toolbar>
