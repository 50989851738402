<div class="messaging-directive" ng-class="{'loading': msg.loading || msg.saving}">
  <div class="additional-msg-loader-container" ng-class="{'open': msg.loading && !msg.saving && msg.appendMessages}">
    <div class="additional-msg-loader">
      <i class="fa fa-circle-o-notch fa-spin-2x"></i>
    </div>
  </div>
  <!--<div class="messages-container loading no-animate" ng-if="(msg.loading || msg.saving) && !msg.appendMessages">
    <i class="fa fa-3x fa-circle-o-notch fa-spin-2x"></i>
  </div>-->
  <div class="pending-overlay-white pending-overlay-column opacity-slow-if" ng-if="msg.loading && !msg.appendMessages">
    <span class="fa fa-circle-o-notch fa-spin-2x"></span>
    <div class="pending-overlay-description">Loading Messages</div>
  </div>
  <div class="pending-overlay-white pending-overlay-column opacity-slow-if" ng-if="msg.saving && !msg.appendMessages">
    <span class="fa fa-circle-o-notch fa-spin-2x"></span>
    <div class="pending-overlay-description">Sending...</div>
  </div>
  <div class="messages-container" ng-if="msg.messages.length === 0 && msg.loading"></div>
  <div class="messages-container" ng-if="msg.messages.length === 0 && !msg.hasError && !msg.loading" ng-class="{'empty': msg.messages.length === 0}">
    <!-- No messages -->
    <div class="no-messages">
      No Messages.
    </div>
    <div class="no-messages-prompt">
      Send the first message!
    </div>
  </div>
  <div class="messages-container has-data" ng-show="msg.messages.length > 0 && !msg.error">
    <!-- list of messages -->
    <div ng-repeat="m in msg.messages" class="message-row"
         ng-class="{'incoming': m.usersender.idUser === msg.recipient.idUser}">

         <!--ng-init="msg.itemRender(m, $index)">-->
      <!--<div ng-if="msg.grouping" ng-class="{'group-opening': m.opensGroup, 'group-closing': m.closesGroup, 'no-group': m.noGroup}">
        <div class="group-line"></div>
        <div class="group-container" ng-if="!m.noGroup">
          <div class="group-content">
            <button class="btn-sm btn-primary">info</button>
            <div class="group-item">
              lead: {{m.groupId}}
            </div>
            <button class="btn-sm btn-primary">></button>
          </div>
        </div>
      </div>-->
      <div class="message-container">
        <div class="recipient-image" ng-if="m.usersender.idUser === msg.recipient.idUser">
          <user-avatar size="small" fixed-size="false" user="msg.recipient"></user-avatar>
        </div>
        <div class="message-content" ng-bind-html="m.message"></div>
        <div class="message-time" ng-if="!msg.savingMessages[m.dateCreated]">
          {{m.created}}
        </div>
        <div class="message-pending opacity-slow-if" ng-if="msg.savingMessages[m.dateCreated]">
          <span class="fa fa-circle-o-notch fa-spin-2x"></span>
        </div>
      </div>
      <!--<div ng-if="msg.grouping" ng-class="{'group-closing': m.closesGroup}"></div>-->
    </div>
  </div>
  <div class="messages-container error" ng-if="msg.messages.length === 0 && msg.hasError">
    <!-- No messages -->
    <div class="no-messages">
      Unable to load messages.
    </div>
    <div class="no-messages-prompt">
      <button class="btn" ng-click="msg.reload()">Reload</button>
    </div>
  </div>
  <div class="message-input" ng-if="!msg.hasError">
    <!-- input form for entering the messages -->
    <form name="messagingForm">
      <label class="sr-only" for="textInput">Type your message</label>
      <textarea id="{{msg.id}}-text-input" ng-disabled="vm.hasError" name="textInput" type="text" class="form-control msg-inputbox" placeholder="Write message here.." ng-model="msg.messageStr"></textarea>
      <button name="submitBtn" ng-disabled="vm.hasError" type="button" class="btn btn-primary btn-send" ng-click="msg.send(messagingForm.textInput)">Send</button>
    </form>
  </div>
  <div class="message-notification">
    {{msg.notification}}
  </div>
</div>
