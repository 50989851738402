(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name mlp-search.directive:searchAutocomplete
   * @restrict EA
   * @element
   *
   * @description This is the directory search bar that searches across the user's content
   *
   */
  angular
    .module('mlp-search')
    .directive('searchAutocomplete', searchAutocomplete);

  function searchAutocomplete($q, $parse, $http, $sce, $timeout, $templateCache, $interpolate, $window, $document, Restangular) {
    // keyboard events
    var KEY_DW = 40,
        KEY_RT = 39,
        KEY_UP = 38,
        KEY_LF = 37,
        KEY_ES = 27,
        KEY_EN = 13,
        KEY_TAB = 9,

        MIN_LENGTH = 3,
        MAX_LENGTH = 524288,
        PAUSE = 300,
        BLUR_TIMEOUT = 200,

        REQUIRED_CLASS = 'autocomplete-required',
        TEXT_SEARCHING = 'Searching...',
        TEXT_NORESULTS = 'No results found';

    function link(scope, elem, attrs, ctrl) {
      var inputField = elem.find('input'),
          minlength = MIN_LENGTH,
          searchTimer = null,
          hideTimer,
          requiredClassName = REQUIRED_CLASS,
          responseFormatter,
          validState = null,
          httpCanceller = null,
          httpCallInProgress = false,
          dd = elem[0].querySelector('.search-autocomplete-dropdown'),
          isScrollOn = false,
          mousedownOn = null,
          unbindInitialValue,
          displaySearching,
          displayNoResults,
          lastSearchStr,
          bodyClickHandler,
          onBodyClickListener;

      // For the filter
      scope.showFilterDropdown = false;
      scope.selectedCategories = ['all'];
      scope.filterActive = false;
      scope.searchFocused = false;
      scope.resultsBackup = null;
      scope.showResultsFilter = false;
      scope.hasCategoryFilter = typeof scope.hasCategoryFilter === 'undefined';

      // For the history dropdown
      scope.showHistoryDropdown = false;
      scope.historyItems = [];
      scope.currentHistoryIndex = -1;

      // Check if the dropdown is positioned relatively
      if (scope.isDropdownRelative) {
        angular.element(dd).css('position', 'relative');
      }

      // Setup the selected categories based on search categories
      if (scope.searchCategories && scope.searchCategories.length === 1) {
        scope.selectedCategories = [scope.searchCategories[0].value];
      }

      bodyClickHandler = function (evt) {
        scope.checkFocus(evt, true);
      };

      elem.on('mousedown', function (event) {
        if (event.target.id) {
          mousedownOn = event.target.id;
          if (mousedownOn === scope.id + '_dropdown') {
            $document[0].body.addEventListener('click', clickoutHandlerForDropdown);
          }
        }
        else {
          mousedownOn = event.target.className;
        }
      });

      scope.currentIndex = scope.focusFirst ? 0 : null;
      scope.searching = false;
      unbindInitialValue = scope.$watch('initialValue', function (newval) {
        if (newval) {
          // remove scope listener
          unbindInitialValue();
          // change input
          handleInputChange(newval, true);
        }
      });

      scope.$watch('fieldRequired', function (newval, oldval) {
        if (newval !== oldval) {
          if (!newval) {
            ctrl[scope.inputName].$setValidity(requiredClassName, true);
          }
          else if (!validState || scope.currentIndex === -1) {
            handleRequired(false);
          }
          else {
            handleRequired(true);
          }
        }
      });

      scope.$on('search-autocomplete:clearInput', function (event, elementId) {
        if (!elementId || elementId === scope.id) {
          scope.searchStr = null;
          callOrAssign();
          handleRequired(false);
          clearResults();
        }
      });

      scope.$on('search-autocomplete:changeInput', function (event, elementId, newval) {
        if (!!elementId && elementId === scope.id) {
          handleInputChange(newval);
        }
      });

      function handleInputChange(newval, initial) {
        if (newval) {
          if (angular.isObject(newval)) {
            scope.searchStr = extractTitle(newval);
            callOrAssign({originalObject: newval});
          }
          else if (angular.isString(newval) && newval.length > 0) {
            scope.searchStr = newval;
          }
          else if (console && console.error) {
            console.error('Tried to set ' + (!initial ? 'initial' : '') + ' value of search-autocomplete to', newval, 'which is an invalid value');
          }

          handleRequired(true);
        }
      }

      // #194 dropdown list not consistent in collapsing (bug).
      function clickoutHandlerForDropdown(event) {
        mousedownOn = null;
        scope.hideResults(event, false);
        $document[0].body.removeEventListener('click', clickoutHandlerForDropdown);
      }

      // for IE8 quirkiness about event.which
      function ie8EventNormalizer(event) {
        return event.which ? event.which : event.keyCode;
      }

      function callOrAssign(value) {
        if (value && scope.blurOnSelect) {
          inputField.blur();
          $timeout(function () {
            scope.searchFocused = false;
          }, 100);
        }

        if (angular.isFunction(scope.selectedObject)) {
          scope.selectedObject(value, scope.selectedObjectData);
        }
        else {
          scope.selectedObject = value;
        }

        if (value) {
          handleRequired(true);
        }
        else {
          handleRequired(false);
        }
      }

      function callFunctionOrIdentity(fn) {
        return function (data) {
          return scope[fn] ? scope[fn](data) : data;
        };
      }

      function setInputString(str) {
        callOrAssign({originalObject: str});

        if (scope.clearSelected) {
          scope.searchStr = null;
        }
        clearResults();
      }

      function extractTitle(data) {
        // split title fields and run extractValue for each and join with ' '
        return scope.titleField.split(',')
          .map(function (field) {
            return extractValue(data, field);
          })
          .join(' ');
      }

      function extractValue(obj, key) {
        var keys, result, i;
        if (key) {
          keys = key.split('.');
          result = obj;
          for (i = 0; i < keys.length; i++) {
            result = result[keys[i]];
          }
        }
        else {
          result = obj;
        }
        return result;
      }

      function findMatchString(target, str) {
        var result, matches, re;
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
        // Escape user input to be treated as a literal string within a regular expression
        re = new RegExp(str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i');
        if (!target) {
          return null;
        }
        if (!target.match || !target.replace) {
          target = target.toString();
        }
        matches = target.match(re);
        if (matches) {
          result = target.replace(re, '<span class="' + scope.matchClass + '">' + matches[0] + '</span>');
        }
        else {
          result = target;
        }
        return $sce.trustAsHtml(result);
      }

      function handleRequired(valid) {
        scope.notEmpty = valid;
        validState = scope.searchStr;
        if (scope.fieldRequired && ctrl && scope.inputName) {
          ctrl[scope.inputName].$setValidity(requiredClassName, valid);
        }
      }

      /* eslint complexity: [2, 25]*/
      function keyupHandler(event) {
        var which = ie8EventNormalizer(event);
        if (which === KEY_LF || which === KEY_RT) {
          // do nothing
          return;
        }

        if (which === KEY_UP || which === KEY_EN) {
          event.preventDefault();
        }
        else if (which === KEY_DW) {
          event.preventDefault();

          if (scope.searchStr && scope.searchStr === lastSearchStr && scope.results.length > 0) {
            // So there is a result object, the search string is the same as per the last search string and user pressed DWN button. ->
            // open the dropdown with results then without invoking a new search
            scope.searching = false;
            scope.showDropdown = true;
            scope.showFilterDropdown = false;
            scope.showHistoryDropdown = false;
            scope.$apply();
          }
          else if (!scope.showDropdown && scope.searchStr && scope.searchStr.length >= minlength) {
            initResults();
            scope.searching = true;
            resetCategories();
            searchTimerComplete(scope.searchStr);
          }
          else if (scope.historyItems && scope.hasHistory) {
            if (!scope.showHistoryDropdown) {
              scope.currentHistoryIndex = -1;
            }
            scope.showHistoryDropdown = true;
            scope.$apply();
          }
        }
        else if (which === KEY_ES) {
          if (scope.results && scope.showDropdown) {
            scope.showDropdown = false;
          }
          else if (scope.results && !scope.showDropdown) {
            clearResults();
            scope.searchStr = null;
          }
          scope.showHistoryDropdown = false;
          scope.$apply(function () {
            inputField.val(scope.searchStr);
          });
        }
        else {
          if (minlength === 0 && !scope.searchStr) {
            return;
          }

          lastSearchStr = scope.searchStr;
          if (scope.searchStr && scope.searchStr.length >= minlength) {
            initResults();

            if (searchTimer) {
              $timeout.cancel(searchTimer);
            }

            scope.searching = true;
            resetCategories();
            searchTimer = $timeout(function () {
              searchTimerComplete(scope.searchStr);
            }, scope.pause);
          }
          else if (!scope.searchStr || scope.searchStr === '' || scope.searchStr.length < minlength) {
            scope.showDropdown = false;
            if (searchTimer) {
              $timeout.cancel(searchTimer);
            }
            scope.searching = false;
          }

          if (validState && validState !== scope.searchStr && !scope.clearSelected) {
            scope.$apply(function () {
              callOrAssign();
            });
          }
        }
      }

      function keydownHandler(event) {
        if (scope.results && scope.results.length > 0) {
          handleKeyDown(event, scope.results, scope.currentIndex, scope.showDropdown, false);
        }
        else if (scope.historyItems && scope.historyItems.length > 0) {
          handleKeyDown(event, scope.historyItems, scope.currentHistoryIndex, scope.showHistoryDropdown, true);
        }
      }

      function handleKeyDown(event, results, currentIndex, showDropdown, isHistory) {
        var which = ie8EventNormalizer(event),
            row = null,
            rowTop = null;

        // Straightaway is this is a history, and it's not TAB, KEYDOWN, KEYUP or Enter then just hide it.
        if (isHistory && which !== KEY_EN && which !== KEY_DW && which !== KEY_UP && which !== KEY_TAB) {
          scope.showHistoryDropdown = false;
          return;
        }

        if (which === KEY_EN && results) {
          if (currentIndex >= 0 && currentIndex < results.length) {
            event.preventDefault();
            if (isHistory) {
              scope.selectHistoryItem(results[currentIndex]);
            }
            else {
              scope.selectResult(results[currentIndex]);
            }
          }
          else {
            handleOverrideSuggestions(event);
          }
          scope.$apply();
        }
        else if (which === KEY_DW && results && currentIndex + 1 < results.length && showDropdown) {
          event.preventDefault();
          scope.$apply(function () {
            currentIndex += 1;
            updateCurrentIndexByOne(false, isHistory);
            updateInputField(results, currentIndex);
          });

          if (isScrollOn) {
            row = dropdownRow(currentIndex);
            if (dropdownHeight() < row.getBoundingClientRect().bottom) {
              dropdownScrollTopTo(dropdownRowOffsetHeight(row));
            }
          }
        }
        else if (which === KEY_UP && results) {
          event.preventDefault();
          if (currentIndex >= 1) {
            scope.$apply(function () {
              currentIndex -= 1;
              updateCurrentIndexByOne(true, isHistory);
              updateInputField(results, currentIndex);
            });

            if (isScrollOn) {
              rowTop = dropdownRowTop(isHistory ? scope.currentIndex : scope.currentHistoryIndex);
              if (rowTop < 0) {
                dropdownScrollTopTo(rowTop - 1);
              }
            }
          }
          else if (currentIndex === 0) {
            scope.$apply(function () {
              currentIndex -= 1;
              updateCurrentIndexByOne(true, isHistory);
              inputField.val(scope.searchStr);
            });
          }
        }
        else if (which === KEY_TAB) {
          if (results && results.length > 0 && showDropdown) {
            if (currentIndex === -1 && scope.overrideSuggestions) {
              // intentionally not sending event so that it does not
              // prevent default tab behavior
              handleOverrideSuggestions();
            }
            else {
              if (currentIndex === -1) {
                currentIndex = 0;
                updateCurrentIndexByOne(0, isHistory);
              }
              if (isHistory) {
                scope.selectHistoryItem(results[currentIndex]);
              }
              else {
                scope.selectResult(results[currentIndex]);
              }

              scope.$apply();
            }
          }
          else if (scope.searchStr && scope.searchStr.length > 0) {
            // no results
            // intentionally not sending event so that it does not
            // prevent default tab behavior
            handleOverrideSuggestions();
          }
        }
        else if (which === KEY_ES) {
          // This is very specific to IE10/11 #272
          // without this, IE clears the input text
          event.preventDefault();
        }
      }

      function updateCurrentIndexByOne(negative, isHistory) {
        if (isHistory) {
          if (negative === 0) {
            scope.currentHistoryIndex = 0;
          }
          else if (negative === true) {
            scope.currentHistoryIndex -= 1;
          }
          else {
            scope.currentHistoryIndex += 1;
          }
          return;
        }

        if (negative === 0) {
          scope.currentIndex = 0;
        }
        else if (negative === true) {
          scope.currentIndex -= 1;
        }
        else {
          scope.currentIndex += 1;
        }
      }

      function handleOverrideSuggestions(event) {
        if (scope.overrideSuggestions &&
            !(scope.selectedObject && scope.selectedObject.originalObject === scope.searchStr)) {
          if (event) {
            event.preventDefault();
          }

          // cancel search timer
          $timeout.cancel(searchTimer);
          // cancel http request
          cancelHttpRequest();

          setInputString(scope.searchStr);
        }
      }

      function dropdownRowOffsetHeight(row) {
        var css = $window.getComputedStyle(row);
        return row.offsetHeight +
          parseInt(css.marginTop, 10) + parseInt(css.marginBottom, 10);
      }

      function dropdownHeight() {
        return dd.getBoundingClientRect().top +
          parseInt($window.getComputedStyle(dd).maxHeight, 10);
      }

      function dropdownRow(currentIndex) {
        return elem[0].querySelectorAll('.search-autocomplete-row')[currentIndex];
      }

      function dropdownRowTop(currentIndex) {
        return dropdownRow(currentIndex).getBoundingClientRect().top -
          (dd.getBoundingClientRect().top +
           parseInt($window.getComputedStyle(dd).paddingTop, 10));
      }

      function dropdownScrollTopTo(offset) {
        dd.scrollTop += offset;
      }

      function updateInputField(results, currentIndex) {
        var current = null;
        if (!results && !currentIndex) {
          results = scope.results;
          currentIndex = scope.currentIndex;
        }
        current = results[currentIndex];
        if (scope.matchClass) {
          inputField.val(extractTitle(current.originalObject));
        }
        else {
          inputField.val(current.title);
        }
      }

      function httpSuccessCallbackGen(str) {
        return function (responseData, status, headers, config) {
          // normalize return obejct from promise
          if (!status && !headers && !config && responseData.data) {
            responseData = responseData.data;
          }
          scope.searching = false;
          processResults(
            extractValue(responseFormatter(responseData), scope.remoteUrlDataField),
            str);
        };
      }

      function httpErrorCallback(errorRes, status, headers, config) {
        scope.searching = httpCallInProgress;

        // normalize return obejct from promise
        if (!status && !headers && !config) {
          status = errorRes.status;
        }

        // cancelled/aborted
        if (status === 0 || status === -1) {
          return;
        }
        if (scope.remoteUrlErrorCallback) {
          scope.remoteUrlErrorCallback(errorRes, status, headers, config);
        }
        else if (console && console.error) {
          console.error('http error');
        }
      }

      function cancelHttpRequest() {
        if (httpCanceller) {
          httpCanceller.resolve();
        }
      }

      function getRemoteResults(str) {
        var params = {},
            url = scope.remoteUrl + encodeURIComponent(str);

        if (scope.remoteUrl) {
          if (scope.remoteUrlRequestFormatter) {
            params = {
              params: scope.remoteUrlRequestFormatter(str)
            };
            url = scope.remoteUrl;
          }

          if (!scope.remoteUrlRequestWithCredentials) {
            params.withCredentials = true;
          }
          cancelHttpRequest();
          httpCanceller = $q.defer();
          params.timeout = httpCanceller.promise;
          httpCallInProgress = true;
          $http.get(url, params)
            .then(httpSuccessCallbackGen(str))
            .catch(httpErrorCallback)
            .finally(function () {
              httpCallInProgress = false;
            });
        }
        else if (scope.searchService && scope.searchServiceEndPoint) {
          Restangular.all(scope.searchService).get(scope.searchServiceEndPoint, {
            keyword: str,
            categories: scope.selectedCategories && scope.selectedCategories.length > 0 ? scope.selectedCategories.toString() : 'all'
          }).then(httpSuccessCallbackGen(str), httpErrorCallback).finally(function () {
            httpCallInProgress = false;
          });
        }
      }

      function getRemoteResultsWithCustomHandler(str) {
        cancelHttpRequest();

        httpCanceller = $q.defer();

        scope.remoteApiHandler(str, httpCanceller.promise)
          .then(httpSuccessCallbackGen(str))
          .catch(httpErrorCallback);

        /* IE8 compatible
        scope.remoteApiHandler(str, httpCanceller.promise)
          ['then'](httpSuccessCallbackGen(str))
          ['catch'](httpErrorCallback);
        */
      }

      function clearResults(hideOnly) {
        scope.showDropdown = false;
        if (!hideOnly) {
          lastSearchStr = null;
          scope.results = [];
          scope.showResultsFilter = false;
          if (dd) {
            dd.scrollTop = 0;
          }
        }
      }

      function initResults() {
        scope.showDropdown = displaySearching;
        scope.currentIndex = scope.focusFirst ? 0 : -1;
        /* scope.results = [];*/
      }

      function getLocalResults(str) {
        var i, match, s, value,
            searchFields = scope.searchFields.split(','),
            matches = [];

        if (typeof scope.parseInput() !== 'undefined') {
          str = scope.parseInput()(str);
        }
        for (i = 0; i < scope.localData.length; i++) {
          match = false;

          for (s = 0; s < searchFields.length; s++) {
            value = extractValue(scope.localData[i], searchFields[s]) || '';
            match = match || value.toString().toLowerCase().indexOf(str.toString().toLowerCase()) >= 0;
          }

          if (match) {
            matches[matches.length] = scope.localData[i];
          }
        }
        return matches;
      }

      function checkExactMatch(result, obj, str) {
        var key;

        if (!str) {
          return false;
        }
        for (key in obj) {
          if (obj[key].toLowerCase() === str.toLowerCase()) {
            scope.selectResult(result);
            return true;
          }
        }
        return false;
      }

      function searchTimerComplete(str) {
        // Begin the search
        if (!str || str.length < minlength) {
          return;
        }
        if (scope.localData) {
          scope.$apply(function () {
            var matches;
            if (typeof scope.localSearch() !== 'undefined') {
              matches = scope.localSearch()(str, scope.localData);
            }
            else {
              matches = getLocalResults(str);
            }
            scope.searching = false;
            processResults(matches, str);
          });
        }
        else if (scope.remoteApiHandler) {
          getRemoteResultsWithCustomHandler(str);
        }
        else {
          getRemoteResults(str);
        }
      }

      function resetCategories() {
        // Reset all search categories focusFirst
        if (!scope.searchCategories) {
          return;
        }
        angular.forEach(scope.searchCategories, function (sc) {
          sc.hasResults = false;
        });
        $timeout(function () {
          scope.$apply();
        });
      }

      function processResults(responseData, str, isHistory) {
        var i,
            item,
            description,
            description2,
            image, text,
            formattedText,
            formattedDesc,
            foundCategories = [];

        if (responseData && responseData.length > 0) {
          scope.results = [];

          for (i = 0; i < responseData.length; i++) {
            if (scope.titleField && scope.titleField !== '') {
              text = formattedText = extractTitle(responseData[i]);
            }

            description = '';
            if (scope.descriptionField) {
              description = formattedDesc = extractValue(responseData[i], scope.descriptionField);
            }

            if (scope.descriptionField2) {
              description2 = extractValue(responseData[i], scope.descriptionField2);
            }

            image = '';
            if (scope.imageField) {
              image = extractValue(responseData[i], scope.imageField);
            }

            if (scope.matchClass) {
              formattedText = findMatchString(text, str);
              // formattedDesc = findMatchString(description, str);
            }

            // Now check the category so we can update the badge on the category filter.
            if (scope.categoryField) {
              foundCategories.push(responseData[i][scope.categoryField]);
            }

            item = {
              title: formattedText,
              description: formattedDesc,
              description2: description2,
              image: image,
              originalObject: responseData[i]
            };

            if (!isHistory) {
              scope.results[scope.results.length] = item;
            }
            else {
              scope.historyItems[scope.historyItems.length] = item;
            }
          }

          // Now check the categories
          if (!isHistory && scope.searchCategories && scope.searchCategories.length > 0) {
            // Make the values unique
            foundCategories = _.uniq(foundCategories);
            // Now loop through each category and apply the badge
            angular.forEach(foundCategories, function (category) {
              _.find(scope.searchCategories, {value: category}).hasResults = true;
            });
          }
        }
        else {
          scope.results = [];
        }

        // Update the showResultsFilter value
        if (!isHistory) {
          scope.showResultsFilter = scope.searchCategories && scope.results;

          if (scope.autoMatch && scope.results.length === 1 &&
            checkExactMatch(scope.results[0],
              {title: text, desc: description || ''}, scope.searchStr)) {
            scope.showDropdown = false;
          }
          else if (scope.results.length === 0 && !displayNoResults) {
            scope.showDropdown = false;
          }
          else {
            scope.showDropdown = true;
            scope.showFilterDropdown = false;
            scope.showHistoryDropdown = false;
          }

          // Make the backup
          scope.resultsBackup = {
            categories: _.clone(scope.selectedCategories),
            results: _.clone(scope.results)
          };
        }
      }

      function showAll() {
        if (scope.localData) {
          scope.searching = false;
          processResults(scope.localData, '');
        }
        else if (scope.remoteApiHandler) {
          scope.searching = true;
          resetCategories();
          getRemoteResultsWithCustomHandler('');
        }
        else {
          scope.searching = true;
          resetCategories();
          getRemoteResults('');
        }
      }

      scope.onFocusHandler = function () {
        if (scope.focusIn) {
          scope.focusIn();
        }
        scope.showFilterDropdown = false;
        if (minlength === 0 && (!scope.searchStr || scope.searchStr.length === 0)) {
          scope.currentIndex = scope.focusFirst ? 0 : scope.currentIndex;
          scope.showDropdown = true;
          scope.showFilterDropdown = false;
          showAll();
        }
        if (scope.searchStr && scope.searchStr === lastSearchStr && scope.results.length > 0) {
          // So there is a result object, the search string is the same as per the last search string and user pressed DWN button. ->
          // open the dropdown with results then without invoking a new search
          scope.searching = false;
          scope.showDropdown = true;
        }
        else if (scope.hasHistory && scope.historyItems) {
          scope.showHistoryDropdown = true;
          scope.currentHistoryIndex = -1;
        }
      };

      scope.hideResults = function (event, hideOnly) {
        var element = angular.element(event.relatedTarget);
        if (mousedownOn &&
            (mousedownOn === scope.id + '_dropdown' ||
             mousedownOn.indexOf('search-autocomplete') >= 0)) {
          mousedownOn = null;
        }
        else if (!element.closest('.search-autocomplete-holder')) {
          // If the element is the child of the autocomplete holder, don't lose the focus
          hideTimer = $timeout(function () {
            clearResults(hideOnly);
            scope.$apply(function () {
              if (scope.searchStr && scope.searchStr.length > 0) {
                inputField.val(scope.searchStr);
              }
            });
          }, BLUR_TIMEOUT);
          cancelHttpRequest();

          if (scope.focusOut) {
            scope.focusOut();
          }

          if (scope.overrideSuggestions) {
            if (scope.searchStr && scope.searchStr.length > 0 && scope.currentIndex === -1) {
              handleOverrideSuggestions();
            }
          }
        }
      };

      scope.resetHideResults = function () {
        if (hideTimer) {
          $timeout.cancel(hideTimer);
        }
      };

      scope.hoverRow = function (index, isHistory) {
        if (isHistory) {
          scope.currentHistoryIndex = index;
        }
        else {
          scope.currentIndex = index;
        }
      };

      scope.removeHover = function (isHistory) {
        if (isHistory) {
          scope.currentHistoryIndex = -1;
        }
        else {
          scope.currentIndex = -1;
        }
      };

      scope.selectResult = function (result) {
        // Restore original values
        if (scope.matchClass) {
          result.title = extractTitle(result.originalObject);
          result.description = extractValue(result.originalObject, scope.descriptionField);
        }

        if (scope.clearSelected) {
          scope.searchStr = null;
        }
        else if (scope.updateInputOnSelect) {
          updateInputField();
        }
        else {
          inputField.val(scope.searchStr);
        }
        callOrAssign(result);
        clearResults(!scope.clearSelected);
        saveHistoryItem(result);
      };

      scope.selectHistoryItem = function (item) {
        scope.searchStr = null;
        scope.showHistoryDropdown = false;
        // Restore original values
        if (scope.matchClass) {
          item.title = extractTitle(item.originalObject);
          item.description = extractValue(item.originalObject, scope.descriptionField);
        }
        callOrAssign(item);
      };

      scope.inputChangeHandler = function (str) {
        if (str.length < minlength) {
          cancelHttpRequest();
          clearResults();
        }
        else if (str.length === 0 && minlength === 0) {
          showAll();
        }

        if (scope.inputChanged) {
          str = scope.inputChanged(str);
        }
        return str;
      };

      //
      // Filter
      //
      scope.changeCategory = function (category, evt, isSingleFilter) {
        if (!category || scope.searching) {
          return;
        }

        if (evt) {
          evt.stopImmediatePropagation();
          evt.preventDefault();
        }

        if (category === 'all') {
          scope.selectedCategories = ['all'];
          scope.filterActive = false;
          scope.checkLastSearchedCategories(category);
          return;
        }

        if (isSingleFilter && category) {
          scope.selectedCategories = [category];
          scope.filterActive = true;
          scope.checkLastSearchedCategories(category);
          return;
        }

        if (_.contains(scope.selectedCategories, category)) {
          scope.selectedCategories = _.without(scope.selectedCategories, category);
        }
        else {
          if (_.contains(scope.selectedCategories, 'all')) {
            scope.selectedCategories = [];
          }
          scope.selectedCategories.push(category);
          scope.filterActive = true;
        }

        if (scope.selectedCategories.length === 0) {
          scope.selectedCategories.push('all');
          scope.filterActive = false;
        }

        scope.checkLastSearchedCategories(category);

        // if the evt is null, it's comming from the main filter content, so if there are any results. just pop them out.
        // but do this in a little timeout to give it some time to render.
        if (!evt && scope.results && scope.results.length > 0) {
          $timeout(function () {
            scope.showDropdown = true;
            scope.showFilterDropdown = false;
          }, 100);
        }
      };

      scope.checkLastSearchedCategories = function (category) {
        // Check what was searched last time. If the incoming (clicked) category is not in selectedCategories, then re-run the search.

        // Check if there is anything in the input box (will indicate whether the search was already ran or not)
        if (!scope.searchStr || scope.searchStr.length < minlength || !scope.resultsBackup) {
          return;
        }

        if (scope.resultsBackup.categories[0] === 'all' && scope.selectedCategories.lastIndexOf('all') === -1) {
          // This means that the last search was done using the 'all' categories keyword. Any incoming category was already included in the search. Just alter the results
          scope.filterResults(category);
        }
        else if (scope.resultsBackup.categories[0] === 'all' && scope.selectedCategories.lastIndexOf('all') > -1) {
          // Show all of them again
          scope.results = _.clone(scope.resultsBackup.results);
        }
        else if (_.contains(scope.resultsBackup.categories, category)) {
          // Read from backup
          scope.filterResults(category);
        }
        else {
          // Do the new search!
          scope.searching = true;
          resetCategories();
          searchTimerComplete(scope.searchStr);
        }
      };

      scope.filterResults = function () {
        var fresults = [];
        angular.forEach(scope.resultsBackup.results, function (result) {
          if (_.contains(scope.selectedCategories, result.originalObject.category)) {
            fresults.push(result);
          }
        });
        scope.results = fresults;
      };

      scope.openFilter = function () {
        scope.showHistoryDropdown = false;
        scope.showFilterDropdown = !scope.showFilterDropdown;
        if (scope.showFilterDropdown) {
          scope.showDropdown = false;
        }
        if (scope.focusIn) {
          scope.focusIn();
        }
      };

      scope.clearInput = function () {
        scope.searchStr = null;
        clearResults();
        inputField.focus();
      };

      scope.backBtnClicked = function () {
        scope.searchStr = null;
        cancelHttpRequest();
        clearResults();
        if (scope.focusOut) {
          scope.focusOut();
        }
        $timeout(function () {
          scope.searchFocused = false;
        }, 100);
      };

      scope.checkInput = function () {
        return scope.searchStr && scope.searchStr.length > 0 && !scope.searching;
      };

      scope.checkSearch = function () {
        return !scope.searchStr || scope.searchStr.length === 0;
      };

      scope.isSelected = function (category) {
        return _.contains(scope.selectedCategories, category);
      };

      scope.checkFocus = function (evt, isBodyClick) {
        var el;

        if (isBodyClick) {
          el = $document[0].getElementById(scope.controlId);
          scope.searchFocused = _.contains(evt.path, el);
          if (!scope.searchFocused) {
            // Remove the listener.
            scope.showFilterDropdown = false;
            scope.showDropdown = false;
            scope.showHistoryDropdown = false;
            angular.element($document[0].body).off('click', bodyClickHandler);
            onBodyClickListener = null;
            if (scope.focusOut) {
              scope.focusOut();
            }
            scope.$apply();
          }
        }
        else {
          scope.searchFocused = evt.currentTarget.id === scope.controlId;
          if (scope.searchFocused && !onBodyClickListener) {
            // Create listener
            onBodyClickListener = angular.element($document[0].body).on('click', bodyClickHandler);
          }
          evt.stopImmediatePropagation();
        }
      };

      function checkHistoryItems() {
        // Entry point for the history items. Check with server for this particular input.
        scope.loadingHistory = true;
        Restangular.all(scope.searchService).get('history', {
          searchId: scope.controlId
        }).then(function (results) {
          if (results && results.plain().records) {
            processResults(results.plain().records, '', true);
          }
        }).finally(function () {
          scope.loadingHistory = false;
        });
      }

      function saveHistoryItem(result) {
        // Entry point for saving the selected item into history.
        // We shall provide the input name so we can get the different history items for different instances of this directive
        if (scope.hasHistory && result && result.originalObject) {
          Restangular.one(scope.searchService).customPOST(result.originalObject, 'history?searchId=' + scope.controlId)
            .then(function (results) {
              if (results && results.records) {
                scope.historyItems = [];
                processResults(results.records, '', true);
              }
            });
        }
      }

      // check required
      if (scope.fieldRequiredClass && scope.fieldRequiredClass !== '') {
        requiredClassName = scope.fieldRequiredClass;
      }

      // check min length
      if (scope.minlength && scope.minlength !== '') {
        minlength = parseInt(scope.minlength, 10);
      }

      // check pause time
      if (!scope.pause) {
        scope.pause = PAUSE;
      }

      // check clearSelected
      if (!scope.clearSelected) {
        scope.clearSelected = false;
      }

      // check override suggestions
      if (!scope.overrideSuggestions) {
        scope.overrideSuggestions = false;
      }

      // check required field
      if (scope.fieldRequired && ctrl) {
        // check initial value, if given, set validitity to true
        if (scope.initialValue) {
          handleRequired(true);
        }
        else {
          handleRequired(false);
        }
      }

      scope.inputType = attrs.type ? attrs.type : 'text';

      // set strings for "Searching..." and "No results"
      scope.textSearching = attrs.textSearching ? attrs.textSearching : TEXT_SEARCHING;
      scope.textNoResults = attrs.textNoResults ? attrs.textNoResults : TEXT_NORESULTS;
      displaySearching = scope.textSearching === 'false' ? false : true;
      displayNoResults = scope.textNoResults === 'false' ? false : true;

      // set max length (default to maxlength deault from html
      scope.maxlength = attrs.maxlength ? attrs.maxlength : MAX_LENGTH;

      // register events
      inputField.on('keydown', keydownHandler);
      inputField.on('keyup compositionend', keyupHandler);

      // set response formatter
      responseFormatter = callFunctionOrIdentity('remoteUrlResponseFormatter');

      // set isScrollOn
      $timeout(function () {
        var css = $window.getComputedStyle(dd);
        isScrollOn = css.maxHeight && css.overflowY === 'auto';
      });

      // Process history items. Shall be removed for PILOT and PROD!
      checkHistoryItems();
    }

    return {
      restrict: 'EA',
      require: '^?form',
      replace: true,
      transclude: true,
      scope: {
        selectedObject: '=',
        selectedObjectData: '=',
        disableInput: '=',
        initialValue: '=',
        localData: '=',
        localSearch: '&',
        remoteUrlRequestFormatter: '=',
        remoteUrlRequestWithCredentials: '@',
        remoteUrlResponseFormatter: '=',
        remoteUrlErrorCallback: '=',
        remoteApiHandler: '=',
        controlId: '@',
        type: '@',
        placeholder: '@',
        textSearching: '@',
        textNoResults: '@',
        remoteUrl: '@',
        remoteUrlDataField: '@',
        titleField: '@',
        subtitleField: '@',
        descriptionField: '@',
        descriptionField2: '@',
        categoryField: '@',
        imageField: '@',
        inputClass: '@',
        pause: '@',
        searchFields: '@',
        minlength: '@',
        matchClass: '@',
        clearSelected: '=',
        overrideSuggestions: '@',
        fieldRequired: '=',
        fieldRequiredClass: '@',
        inputChanged: '=',
        autoMatch: '@',
        focusOut: '&',
        focusIn: '&',
        fieldTabindex: '@',
        inputName: '@',
        focusFirst: '@',
        parseInput: '&',
        searchCategories: '=',
        blurOnSelect: '=',
        searchServiceEndPoint: '@',
        searchService: '@',
        resultTemplateUrl: '@',
        hasBackButton: '=',
        updateInputOnSelect: '=',
        isDropdownRelative: '=',
        hasCategoryFilter: '=?',
        hasHistory: '=?'
      },
      templateUrl: 'search/search-autocomplete-directive.tpl.html',
      /* templateUrl: function (element, attrs) {
        return attrs.templateUrl || TEMPLATE_URL;
      }, */
      compile: function (tElement) {
        var startSym = $interpolate.startSymbol(),
            endSym = $interpolate.endSymbol(),
            interpolatedHtml = null;

        if (!(startSym === '{{' && endSym === '}}')) {
          interpolatedHtml = tElement.html()
            .replace(/\{\{/g, startSym)
            .replace(/\}\}/g, endSym);
          tElement.html(interpolatedHtml);
        }
        return link;
      }
    };
  }
}());
