<div class="modal-content-container" id="lead-detail-container">
  <div class="row lead-info">
    <div class="col-xs-12">

      <div class="row modal-content-title">
        <div class="col-xs-6 lead-detail-header">
          <span class="sub-title">
            {{leadDetail.leadType == 'sent' ? 'Sent Lead' : 'Received Lead'}}
          </span>
        </div>
        <div class="col-xs-6 col-sm-6 text-right">
          <div class="lead-detail-date">
            <i class="fa fa-calendar-o"></i>{{leadDetail.lead.dateSent | date:'d MMM yyyy'}}
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12">
          <tabs-manager tab-id="leadDetail"
                        block-xs-relocation="true"
                        on-change="leadDetail.onTabChange(activeTab)"
                        tabs-config="leadDetail.tabsConfig"
                        default-tab="{{leadDetail.tabName}}"
                        tab-manager="leadDetail.tabManager"></tabs-manager>
        </div>
      </div>

      <!-- User details -->
      <div class="row">
        <div class="col-xs-12 tab-content-header">
          <div class="lead-detail-row lead-detail-user-row"
               ng-class="{'w-background': leadDetail.tabManager.isOpen('messages')}">
            <div class="lead-detail-user">
              <div class="lead-detail-user-desc no-animate" ng-if="leadDetail.tabManager.isOpen('details')">
                <!--<i class="fa fa-user-circle-o"></i>-->
                <span>{{leadDetail.leadType === 'sent' ? 'To' : 'From'}}</span>
              </div>
              <div style="width:100%; position: relative;">
                <!-- ng-class="{'table-user-detail-opaque': leadDetail.tabManager.isOpen('messages')}"-->
                <!--class="{{leadDetail.getUserDetailClass()}}"-->
                <table-user-details
                  user="leadDetail.getUser()"
                  name-only="true"
                  add-class="leadDetail.getUserDetailClass()"
                  is-link="true"
                  has-details="true">
                </table-user-details>
              </div>
            </div>
            <div class="lead-detail-reward no-animate" ng-if="leadDetail.tabManager.isOpen('details') && !leadDetail.lead.isCoOwner && leadDetail.leadType === 'received' && leadDetail.lead.rewardCode">
              <div class="lead-detail-user-desc">
                <span>Reward</span>
              </div>
              <div class="lead-detail-val">
                <div>
                  #{{leadDetail.lead.rewardCode.rewardCode}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Lead Details -->
      <div class="row no-animate ng-hide" ng-show="leadDetail.tabManager.isOpen('details')">
        <div class="col-xs-12">
          <div class="tab-content-w-header">
            <div class="lead-detail lead-detail-split lead-detail-row">
              <div class="lead-detail-left" >
                <div class="lead-detail-desc">
                  <!--<i class="fa fa-comment-o"></i>-->
                  <span>Details</span>
                </div>
                <div class="lead-detail-val">
                  {{leadDetail.lead.details}}
                </div>
              </div>

              <div class="lead-detail-right">
                <div class="lead-detail-desc">
                  <!--<i class="fa fa-comment-o"></i>-->
                  <span>Contact</span>
                </div>
                <div class="lead-detail-val">
                  <div class="lead-detail-row">
                    <div class="lead-detail-desc">
                      <i class="fa-lead fa fa-user"></i>
                    </div>
                    <div class="lead-detail-val">
                      <div class="lead-detail-split-top">
                        {{leadDetail.lead.firstName}} {{leadDetail.lead.lastName}}
                      </div>
                    </div>
                  </div>

                  <div class="lead-detail-row" ng-if="leadDetail.lead.phone">
                    <div class="lead-detail-desc">
                      <i class="fa-lead fa fa-phone"></i>
                    </div>
                    <div class="lead-detail-val">
                      <a href="tel:{{leadDetail.lead.phone}}">{{leadDetail.lead.phone}}</a>
                    </div>
                  </div>

                  <div class="lead-detail-row" ng-if="leadDetail.lead.email">
                    <div class="lead-detail-desc">
                      <i class="fa-lead fa fa-envelope-o"></i>
                    </div>
                    <div class="lead-detail-val">
                      <a href="mailto:{{leadDetail.lead.email}}">{{leadDetail.lead.email}}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="lead-detail lead-detail-row reward-code-user"
                 ng-if="!leadDetail.lead.isCoOwner && leadDetail.leadType === 'received'">
              <div class="lead-detail-desc">
                <span>Sharing</span>
              </div>
              <div class="lead-detail-val" style="width:100%;">
                <span ng-if="!leadDetail.lead.coOwner.id" class="">This lead is not shared with another user</span>
                <span ng-if="leadDetail.lead.coOwner.id" class="">
                  This lead is shared with {{leadDetail.lead.coOwner.firstName}} {{leadDetail.lead.coOwner.lastName}}
                </span>
                <span class="no-animate">
                  <a ng-if="leadDetail.lead.coOwner.id"
                     ng-click="leadDetail.userShareSectionVisible = !leadDetail.userShareSectionVisible"
                     class="no-animate"
                     style="width: 50px; display: inline-block; text-align: center;cursor: pointer;">
                    <span ng-if="!leadDetail.userShareSectionVisible"><i class="fa fa-chevron-down" /></span>
                    <span ng-if="leadDetail.userShareSectionVisible"><i class="fa fa-chevron-up" /></span>
                  </a>
                  <a class="action-icon-link cursor-pointer"
                     ng-if="!leadDetail.lead.coOwner.id"
                     ng-click="leadDetail.editUserShareVisible=true; leadDetail.userShareSectionVisible=true;"
                     uib-tooltip="Click to edit"
                     tooltip-placement="bottom">
                    <i class="fa fa-user-plus"></i>
                  </a>
                </span>

                <!-- here is the collapsible content as we don't want to pollute the view immediately -->
                <div class="slide-if" ng-if="leadDetail.userShareSectionVisible">
                  <div class="shared-user" ng-if="leadDetail.lead.coOwner.id">
                    <div class="shared-user-info" >
                      <table-user-details user="leadDetail.lead.coOwner"></table-user-details>
                    </div>

                    <div class="shared-user-controls">
                      <button class="btn-primary btn-sm"
                              ng-click="leadDetail.editUserShareVisible = true;"
                              uib-tooltip="Click to edit"
                              tooltip-placement="top">Edit</button>
                      <button class="btn-sm btn-danger no-animate"
                              ng-click="leadDetail.editUserShareVisible = false; leadDetail.removeSharing();"
                              uib-tooltip="Click to cancel sharing"
                              tooltip-placement="top">
                        <span>Remove</span>
                        <span ng-if="leadDetail.isRemovingShare" class="pending-overlay">
                          <i class="fa fa-spin-2x fa-circle-o-notch" />
                        </span>
                      </button>
                    </div>
                  </div>
                  <div class="row" ng-if="leadDetail.editUserShareVisible" style="margin-top: 15px;">
                    <div class="col-xs-12 form-group">
                      <search-autocomplete
                        control-id="share-with-autocomplete"
                        placeholder="Search Business"
                        minlength="3"
                        clear-selected="false"
                        text-searching="false"
                        text-no-results="false"

                        search-service="Search"
                        search-service-end-point="index"
                        remote-url-response-formatter="leadDetail.searchResponseFormatter"

                        selected-object="leadDetail.onSearchItemSelected"

                        search-fields="search"
                        title-field="value"
                        description-field="categoryDescription"
                        description-field2="timeDescription"
                        category-field="category"

                        input-name="'share-with-control'"
                        input-class="form-control input-lg"
                        match-class="autocomplete-highlight"
                        blur-on-select="true"
                        has-back-button="false"
                        update-input-on-select="true"

                        override-suggestions="true"
                        search-categories="leadDetail.searchCategories"
                        field-required="false"
                        has-history="true" />
                    </div>
                    <div class="col-xs-12 form-group">
                      <div style="position: relative;">
                        <div class="pending-overlay-light" ng-if="leadDetail.isLoadingUserData">
                          <span class="fa fa-circle-o-notch fa-spin-2x"></span>
                        </div>
                        You will share this lead with:
                        <div class="share-with-result">
                          <div>
                            <div ng-if="leadDetail.shareWithProfile" style="flex-grow: 1;">
                              <table-user-details user="leadDetail.shareWithProfile"
                                                  is-link="false"
                                                  has-details="true"
                                                  add-class="'full-width-user-details'"/>
                            </div>
                          </div>
                          <button type="button" class="btn btn-primary"
                                  ng-disabled="leadDetail.isSavingUserShare || !leadDetail.userShareEmail || !leadDetail.shareWithProfile"
                                  ng-click="leadDetail.saveUserShare()">{{leadDetail.isSavingUserShare ? 'Saving ...' : 'Save'}}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="action-bar" ng-if="(leadDetail.lead.status === 'open' || leadDetail.lead.status === 'new') && leadDetail.tabManager.isOpen('details')">

            <div class="action-buttons"
                 ng-class="{'single': leadDetail.leadType !== 'received' && !leadDetail.showAcknowledgeButton() && !leadDetail.showResendButton(), 'multi': leadDetail.leadType !== 'received'}">
              <button class="btn btn-primary no-animate" ng-click="leadDetail.acknowledge()" ng-if="leadDetail.showAcknowledgeButton()" ng-disabled="leadDetail.isAcknowledging">
                {{leadDetail.isAcknowledging ? 'Loading ...' : 'Acknowledge'}}
              </button>
              <button class="btn btn-primary no-animate" ng-click="leadDetail.resend()" ng-if="leadDetail.showResendButton()" ng-disabled="leadDetail.isSending">
                {{leadDetail.isSending ? 'Sending ...' : 'Resend'}}
              </button>
              <button class="btn btn-primary no-animate" ng-click="leadDetail.forward()">
                Forward
              </button>
            </div>

            <div class="btn-group page-filter close-lead-dropdown" uib-dropdown
                 ng-if="leadDetail.leadType === 'received' && !leadDetail.lead.isCoOwner"
                 style>
              <button id="close-lead-button" type="button" class="btn" uib-dropdown-toggle>
                Close lead <span class="caret"></span>
              </button>
              <ul class="dropdown-menu" uib-dropdown-menu role="menu" aria-labelledby="close-lead-button">
                <li role="menuitem"><a ng-click="leadDetail.closeLead('successful')">Successful</a></li>
                <li role="menuitem"><a ng-click="leadDetail.closeLead('unsuccessful')">Unsuccessful</a></li>
              </ul>
            </div>

          </div>

          <div class="row">
            <div class="col-xs-12">
              <uib-alert ng-repeat="alert in leadDetail.alerts.getList()" type="{{alert.type}}" close="leadDetail.alerts.remove($index)">{{alert.message}}</uib-alert>
            </div>
          </div>

        </div>
      </div>

      <!-- Lead Messages -->
      <div class="row messaging-container no-animate ng-hide" ng-show="leadDetail.tabManager.isOpen('messages')">
        <div class="col-xs-12">
          <div class="tab-content-w-header">
            <messaging-form class="row" msg-request-params="{'idLead': leadDetail.lead.id}" msg-recipient="leadDetail.leadType == 'sent' ? leadDetail.lead.recipient : leadDetail.lead.sender"></messaging-form>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="row modal-content-buttons">
    <div class="col-xs-12 text-right">
      <button class="btn btn-default" ng-click="leadDetail.close()">
        Close
      </button>
    </div>
  </div>
</div>
