(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name lead.service:Leads
   *
   * @description
   *
   */
  angular
    .module('lead')
    .service('Leads', Leads);

  function Leads($rootScope, $document, $state, $q, Notification, $uibModal, Restangular) {
    var self = this;

    self.closeLead = function (lead, status) {
      var modalInstance,
          params = {
            animation: false,
            templateUrl: 'lead/close-lead.tpl.html',
            controller: 'CloseLeadCtrl',
            controllerAs: 'closeLead',
            size: 'md',
            resolve: {
              lead: function () {
                return lead;
              },
              status: function () {
                return status;
              },
              contract: function () {
                return Restangular.one('Leads').one('receiveLeadsContract').get({idUserSender: lead.sender.id})
                  .then(function (contract) {
                    return contract.plain();
                  }, function () {
                    return null;
                  });
              },
              userProfile: ['Session', function (Session) {
                return Session.getUserProfile();
              }],
              leadPriceBreakdown: function () {
                if (status === 'successful') {
                  return Restangular.one('Leads').one('calculateRewardTotal').get({
                    idUserSender: lead.sender.id
                  })
                    .then(function (result) {
                      return result.plain();
                    }, function () {
                      return null;
                    });
                }
                return null;
              }
            }
          };
      if (lead.acceptedReceiveLeadsContractExists !== true) {
        $state.go('user.agreements', {
          id: lead.sender.id,
          '#': 'receive-leads-agreements-container'
        });
        Notification({message: 'Lead can be accessed with a valid agreement.'}, 'confirmation');
        return null;
      }

      params.appendTo = angular.element($document[0].body);
      modalInstance = $uibModal.open(params);
      return modalInstance.result;
    };

    self.openDetail = function (lead, type, parentElementId, tabToOpen) {
      var modalInstance,
          params = {
            animation: true,
            templateUrl: 'lead/lead-detail.tpl.html',
            controller: 'LeadDetailCtrl',
            controllerAs: 'leadDetail',
            size: 'lg',
            resolve: {
              lead: function () {
                if (lead && angular.isDefined(lead.id)) {
                  // The incoming lead is an object.
                  return lead;
                }
                else if (angular.isDefined(lead) && angular.isNumber(lead) && angular.isDefined(type)) {
                  // This is the id. Get the lead from the server.
                  return Restangular.one('Leads').one('index').get({id: lead}).then(function (result) {
                    return result.plain();
                  });
                }
              },
              leadType: function () {
                return type;
              },
              userProfile: ['Session', function (Session) {
                return Session.getUserProfile();
              }],
              tabToOpen: function () {
                return tabToOpen;
              }
            }
          };

      if (lead && lead.sender && type === 'received' && lead.acceptedReceiveLeadsContractExists !== true && !lead.isCoOwner) {
        $state.go('user.agreements', {
          id: lead.sender.id,
          '#': 'receive-leads-agreements-container'
        });

        Notification({message: 'Lead can be accessed with a valid agreement.'}, 'confirmation');
      }
      else {
        params.appendTo = angular.element($document[0].body);
        modalInstance = $uibModal.open(params);
        return modalInstance.result;
      }
      return modalInstance;
    };

    self.openDetailById = function (id, type) {
      Restangular.one('Leads').one('index').get({id: id})
        .then(function (lead) {
          self.openDetail(lead.plain(), type);
        });
    };

    self.resend = function (id) {
      return Restangular.one('Leads').post('resend', {id: id});
    };

    self.acknowledge = function (id) {
      return Restangular.one('Leads').post('acknowledge', {id: id});
    };

    self.update = function (id, data) {
      var deferred = $q.defer();
      Restangular.one('Leads').customPUT(data, 'index?id=' + id)
        .then(function (result) {
          deferred.resolve(result.plain());
        }, function (error) {
          deferred.reject(error.data.errorCode);
        });

      return deferred.promise;
    };

    self.openSendLead = function (userProfile, isRegister, onCancelCallback, onSentCallback) {
      var slModalInstance,
          cancelCallback,
          sentCallback;

      cancelCallback = function () {
        if (slModalInstance) {
          slModalInstance.close();
        }
        if (onCancelCallback && angular.isFunction(onCancelCallback)) {
          onCancelCallback.apply();
        }
      };

      sentCallback = function () {
        if (slModalInstance) {
          slModalInstance.close();
        }
        if (onSentCallback && angular.isFunction(onSentCallback)) {
          onSentCallback.apply();
        }
        $rootScope.$broadcast('sentLeads-success');
      };

      slModalInstance = $uibModal.open({
        animation: false,
        appendTo: angular.element($document[0].body),
        templateUrl: 'lead/send-lead-modal.tpl.html',
        controller: 'SendLeadCtrl',
        controllerAs: 'sendLead',
        size: 'lg',
        resolve: {
          userProfile: function () {
            return userProfile;
          },
          isRegister: function () {
            return isRegister;
          },
          membersSubIndustries: null,
          onCancelCallback: function () {
            return cancelCallback;
          },
          onSentCallback: function () {
            return sentCallback;
          }
        }
      });

      return slModalInstance;
    };
  }
}());
