<div class="welcome-modal">
  <div class="wm-header">
    <div class="wm-title">
      Welcome to MyLeadPod.
    </div>
    <div class="wm-close-btn" ng-click="welcome.close();">
      <button class="glyphicon glyphicon-remove"></button>
    </div>
  </div>

  <div class="stepped-wrapper" content-slider>
    <div class="stepped-container">
      <div class="step-content wm-flex" data-step-id="0">
        <div style="width: 100%; position: relative;">

          <div class="wm-sub-header">
            <span class="wm-bolder">Well done!</span>
            <br/>
            You are pretty much set up now to encourage others to send you leads!
          </div>

          <div class="wm-content">
            <div class="wm-row-block">
              The step you have just completed has set up your base <span class="wm-highlight">Rewards Program</span> with its <span class="wm-highlight">Reward Code</span>.
            </div>
            <div class="wm-flex mts">
              <div class="wm-block">
                <div class="wm-block-title">Rewards Program</div>
                <div class="wm-block-content">
                  You can update your Rewards Program to have multiple levels, e.g. different $ rewards for different services.

                  You can also set up multi level Custom Rewards for special referrers or sales channels.
                </div>
              </div>
              <div class="wm-block">
                <div class="wm-block-title">Get Traction!</div>
                <div class="wm-block-content">
                  Once you are happy with your cash reward offers, the key next step is inviting your past clients, friends and family to send you leads.  Also add codes to your website, email signature etc.
                </div>
              </div>
            </div>
          </div>

          <div style="text-align: center">
            <a href data-step-to="1" class="step-button">
              <span>Learn about Rewards Program</span>
              <span class="glyphicon glyphicon-circle-arrow-right"></span>
            </a>
          </div>

        </div>
      </div>

      <div class="step-content wm-flex wm-learn-block" data-step-id="1">
        <div style="width: 100%; position:relative;">
          <div class="wm-sub-header">
            <span class="wm-bolder">Learn</span>
            <br/>
            Learn to work with MyLeadPod. Follow the links below or check out
            <a href="https://www.myleadpod.com/blog/" target="_blank">our blog</a> or
            <a href="https://www.youtube.com/channel/UCrlcHVUWH7BTg9oLLDuv99Q" target="_blank">video tutorials</a>.
          </div>

          <div class="wm-content wm-block-content">
            <div ng-click="welcome.openLink('https://youtu.be/sZj6hd76520');">
              <div class="wm-type">
                <span class="glyphicon glyphicon-play-circle"></span>
              </div>
              <div>
                <div class="wm-learn-title">Reward Program vs Reward Code</div>
                <div class="wm-learn-desc">Learn the difference between your Reward Program and Custom Rewards.</div>
              </div>
            </div>

            <div ng-click="welcome.openLink('https://youtu.be/guxDe3hz4DY');">
              <div class="wm-type">
                <span class="glyphicon glyphicon-play-circle"></span>
              </div>
              <div>
                <div class="wm-learn-title">Bulk invite potential referrers</div>
                <div class="wm-learn-desc">Learn how to import the database of your clients.</div>
              </div>
            </div>

            <div ng-click="welcome.openLink('https://youtu.be/Y7iGJmx_pCE');">
              <div class="wm-type">
                <span class="glyphicon glyphicon-play-circle"></span>
              </div>
              <div>
                <div class="wm-learn-title">Add my codes to digital assets</div>
                <div class="wm-learn-desc">Learn how to share your Reward Codes</div>
              </div>
            </div>
          </div>

          <div style="text-align: center">
            <a href data-step-to="0" class="step-button">
              <span class="glyphicon glyphicon-circle-arrow-left"></span>
              <span>Back</span>
            </a>
          </div>

        </div>


      </div>



    </div>
    <!--<div class="wm-side-content">

    </div>-->
  </div>

  <div class="wm-content">
    <div class="wm-btn-section">
      <div class="text-center">
        <button class="btn btn-primary" ng-click="welcome.close();">Update Your Rewards</button>
        <button class="btn btn-primary" ui-sref="dashboard">Go to Dashboard</button>
      </div>
    </div>
  </div>

  <!--<div class="wm-footer">
    <div class="wm-learn-block">
      <div class="wm-block-title">Learn</div>

      <div class="wm-block-content">
        <div>
          <div class="wm-type">
            <span class="glyphicon glyphicon-play-circle"></span>
          </div>
          <div>
            <div class="wm-learn-title">Reward Program vs Reward Code</div>
            <div class="wm-learn-desc">Learn the difference between your Reward Program and Custom Rewards.</div>
          </div>
        </div>

        <div>
          <div class="wm-type">
            <span class="glyphicon glyphicon-play-circle"></span>
          </div>
          <div>
            <div class="wm-learn-title">Bulk invite potential referrers</div>
            <div class="wm-learn-desc">Learn how to import the database of your clients.</div>
          </div>
        </div>

        <div>
          <div class="wm-type">
            <span class="glyphicon glyphicon-play-circle"></span>
          </div>
          <div>
            <div class="wm-learn-title">Add my codes to digital assets</div>
            <div class="wm-learn-desc">Learn how to share your reward codes</div>
          </div>
        </div>

      </div>

    </div>-->
</div>
