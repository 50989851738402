(function () {
  'use strict';

  angular
    .module('user')
    .config(config);

  function config($stateProvider, configOptions) {
    $stateProvider
      .state('user', {
        url: '/user/{id}',
        templateUrl: 'user/user.tpl.html',
        controller: 'UserCtrl',
        controllerAs: 'user',
        authenticate: true,
        params: {
          isInvite: false
        },
        resolve: {
          userProfile: ['Restangular', '$stateParams', 'Auth', function (Restangular, $stateParams, Auth) {
            return Auth.isAuthenticated().then(function (isAuthenticated) {
              if (isAuthenticated) {
                return Restangular.one('Users').one('profile').get({id: $stateParams.id})
                  .then(function (profile) {
                    return profile.plain();
                  });
              }
              return null;
            }, function () {
              return null;
            });
          }],
          agreementsDetails: ['Restangular', '$stateParams', 'Auth', function (Restangular, $stateParams, Auth) {
            return Auth.isAuthenticated().then(function (isAuthenticated) {
              if (isAuthenticated) {
                return Restangular.one('Contracts').one('index').get({idUser: $stateParams.id})
                  .then(function (agreements) {
                    return agreements.plain();
                  });
              }
              return null;
            }, function () {
              return null;
            });
          }],
          myProfile: ['Session', function (Session) {
            return Session.getUserProfile();
          }]
        },
        onEnter: ['$state', '$stateParams', 'userProfile', 'myProfile', function ($state, $stateParams, userProfile, myProfile) {
          if (userProfile && myProfile && userProfile.id === myProfile.id) {
            $state.go('dashboard');
          }
          else if ($stateParams.isInvite) {
            if (userProfile.marketplace && angular.isArray(userProfile.marketplace) && userProfile.marketplace.length > 0) {
              $state.go('user.marketplace', {id: userProfile.id});
            }
            else {
              $state.go('user.agreements', {id: userProfile.id});
            }
          }
        }]
      })
      .state('user.about', angular.extend({}, configOptions.defaultStateParams, {
        url: '/about',
        templateUrl: 'user/user.tpl.html',
        controller: 'UserCtrl',
        controllerAs: 'user',
        onEnter: ['$state', 'RouterHelper', 'userProfile', function ($state, RouterHelper, userProfile) {
          if (!userProfile.description) {
            $state.go('user.agreements', {id: userProfile.id});
          }
          else {
            RouterHelper.activateTab('user', 'about');
          }
        }]
      }))
      .state('userJoin', {
        url: '/user/{id}/join',
        authenticate: true,
        onEnter: ['$state', '$stateParams', 'MyPod', 'Notification', function ($state, $stateParams, MyPod, Notification) {
          MyPod.quickJoin($stateParams.id).then(function () {
            Notification({message: 'Success'}, 'confirmation');
            $state.go('user.agreements', {id: $stateParams.id});
          }, function (err) {
            Notification({message: 'The registration was unsuccessful.'}, 'error');
            $state.go('dashboard', {id: $stateParams.id});
            throw new Error(err);
          });
        }]
      })
      .state('userInvitation', angular.extend({}, configOptions.defaultStateParams, {
        url: '/invitation/{token}',
        resolve: {
          invitation: ['Restangular', '$state', '$stateParams', 'Auth', '$q', function (Restangular, $state, $stateParams, Auth, $q) {
            var deferred = $q.defer();
            Restangular.one('Invitations').one('detail').get({token: $stateParams.token})
              .then(function (invitation) {
                Auth.isAuthenticated()
                  .then(function (isAuthenticated) {
                    if (isAuthenticated) {
                      deferred.resolve(invitation.plain());
                    }
                    else if (invitation.isRegistered) {
                      $state.go('accountSignInUp.accountSignIn', {invitationToken: $stateParams.token});
                    }
                    else {
                      $state.go('accountSignInUp.accountSignUp', {invitationToken: $stateParams.token});
                    }
                  }, function () {
                    if (invitation.isRegistered) {
                      $state.go('accountSignInUp.accountSignIn', {invitationToken: $stateParams.token});
                    }
                    else {
                      $state.go('accountSignInUp.accountSignUp', {invitationToken: $stateParams.token});
                    }
                  });
              }, function () {
                Auth.signOut();
                $state.go('accountSignInUp.accountSignIn', {invitationToken: $stateParams.token});
              });

            return deferred.promise;
          }]
        },
        onEnter: ['$state', 'invitation', function ($state, invitation) {
          if (angular.isObject(invitation) && angular.isDefined(invitation.idUserSender)) {
            $state.go('user.agreements', {id: invitation.idUserSender});
          }
          else {
            $state.go('dashboard');
          }
        }]
      }));
  }
}());
