<div class="col-xs-12" ng-controller="ImportCtrl as import" id="import-ctrl-container">
  <div class="page-content">

    <div class="row">
      <div class="col-xs-12">
        <span class="sub-title">Contacts Import</span>
      </div>
    </div>

    <div class="row form-section-body">
      <div class="col-xs-12">
        Upload and invite people to join your Reward Program.
        <file-download class="cursor-pointer" url="import.downloadTemplateUrl"
                       label="'Download the import template here.'"></file-download>
      </div>
    </div>

    <div class="row form-section-body">
      <div class="col-xs-12" ng-if="!import.isUploading && !import.importFinished">
        <div class="page-empty-section page-table-section text-center" ngf-drop="import.upload($files)"
             ngf-multiple="false"
             ngf-accept="import.acceptedFiles"
             ngf-pattern="import.acceptedFiles"
             ngf-drag-over-class="{pattern: import.acceptedFiles, accept: 'file-drop-accepted', reject: 'file-drop-rejected', delay: 100}">
          <i class="fa fa-upload fa-2x text-light-colour"></i>
          <div class="page-empty-section-text text-muted">
            Drop file here or use the "Select" button.
          </div>

          <div class="page-empty-section-button">
            <button class="btn btn-primary" ngf-select="import.upload($files)" ngf-multiple="false"
                    ngf-accept="import.acceptedFiles"
                    ngf-pattern="import.acceptedFiles">Select
            </button>
          </div>
        </div>
      </div>

      <div class="col-xs-12" ng-if="!import.isUploading && import.importFinished">
        <div class="page-empty-section page-table-section text-center">
          <i class="fa fa-check fa-2x text-light-colour"></i>
          <div class="page-empty-section-text text-muted">
            Success, you've invited your contacts to join your Reward Program.
          </div>

          <div class="page-empty-section-button">
            <button class="btn btn-primary" ng-click="import.importFinished = false">Import New File</button>
          </div>
        </div>
      </div>

      <div class="col-xs-12" ng-if="import.isUploading">
        <uib-progressbar value="import.importProgressPercent" type="success"><b>Importing:
          {{import.importProgressPercent}}%</b></uib-progressbar>
      </div>
    </div>

    <div class="row form-section-body">
      <uib-alert ng-repeat="alert in import.alerts.getList().slice(0,5)" type="{{alert.type}}"
                 close="import.alerts.remove($index)">{{alert.message}}
      </uib-alert>
      <span ng-if="import.alerts.getList().length > 5 && !import.alertsExpanded">
        <a class="cursor-pointer" ng-click="import.alertsExpanded=true;" title="Expand">{{'and ' + (import.alerts.getList().length - 5) + ' more'}}</a>
      </span>
      <div ng-if="import.alerts.getList().length > 5 && import.alertsExpanded">
        <uib-alert ng-repeat="alert in import.alerts.getList().slice(5)" type="{{alert.type}}"
                   close="import.alerts.remove($index)">{{alert.message}}
        </uib-alert>
      </div>
    </div>


  </div>
</div>
