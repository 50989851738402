(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name socialShare.directive:socialShare
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
   <example module="socialShare">
   <file name="index.html">
   <social-share></social-share>
   </file>
   </example>
   *
   */
  angular
    .module('socialShare')
    .directive('socialShare', socialShare);

  function socialShare() {
    return {
      restrict: 'EA',
      scope: {
        url: '=',
        shortUrl: '=',
        title: '=',
        name: '=',
        description: '=',
        source: '=',
        picture: '=',
        hashTags: '=',
        buttonId: '='
      },
      templateUrl: 'social-share/social-share-directive.tpl.html',
      replace: false,
      controllerAs: 'socialShare',
      controller: ['$scope', 'SocialShare', '$location', 'BitlyUrlShortener', 'configOptions',
        function ($scope, SocialShare, $location, BitlyUrlShortener, configOptions) {
          var vm = this;
          if ($scope.buttonId) {
            vm.id = $scope.buttonId;
          }
          else {
            vm.id = '';
          }
          vm.share = function (socialService) {
            var url = $scope.url || $location.absUrl();
            if ($scope.shortUrl) {
              SocialShare.share(socialService, {
                url: $scope.shortUrl,
                name: $scope.name || configOptions.defaultShareName,
                title: $scope.title,
                description: $scope.description || configOptions.defaultShareMessage,
                source: $scope.source || 'MyLeadPod',
                picture: $scope.picture || configOptions.appUrl + '/images/touch-icon-192x192.png',
                hashTags: $scope.hashTags
              });
            }
            else {
              BitlyUrlShortener.shorten(url).then(function (shortUrl) {
                SocialShare.share(socialService, {
                  url: shortUrl,
                  name: $scope.name || configOptions.defaultShareName,
                  title: $scope.title,
                  description: $scope.description || configOptions.defaultShareMessage,
                  source: $scope.source || 'MyLeadPod',
                  picture: $scope.picture || configOptions.appUrl + '/images/touch-icon-192x192.png',
                  hashTags: $scope.hashTags
                });
              });
            }
          };
        }]
    };
  }
}());
