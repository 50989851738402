(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name myMemberships.rewardCodeCreate.controller:RewardCodeCreateCtrl
   *
   * @description
   *
   */
  angular
    .module('myMemberships.rewardProgram')
    .controller('RewardCodeCreateCtrl', RewardCodeCreateCtrl);

  function RewardCodeCreateCtrl($scope, $document, $timeout, $filter, Notification, FormAlerts, Restangular, Session, RewardCodes, $uibModalInstance) {
    var vm = this;
    vm.alerts = new FormAlerts();
    vm.isLoading = false;
    vm.rewardCodeChecked = false;
    vm.userShareEmailChecked = false;
    vm.rewardCodeValid = false;
    vm.rewardCodeValidating = false;
    vm.rewardsProgramSetup = [];
    vm.isRewardsProgramDetailVisible = false;
    vm.detailsVisible = false;
    vm.sharingVisible = false;
    vm.associatedUsersVisible = false;
    vm.associatedUsers = [];
    vm.sharedUsers = [];
    vm.data = {
      options: [],
      rewardType: 'rewardsProgram',
      userShareEmail: null
    };
    vm.searchCategories = [{
      name: 'Directory',
      value: 'businessUsers',
      stateTarget: 'user',
      iconClass: 'fa-briefcase'
    }];

    // Subscribe to the local update
    $scope.$on('event:user-updateLocalProfile', function () {
      if (vm.user.defaultRewardProgram) {
        vm.renderRewardProgramOptions(vm.user.defaultRewardProgram);
      }
    });

    function getIdForOption() {
      var d = new Date();
      return d.getTime();
    }

    function initData() {
      vm.data.options.push({
        name: null,
        successfulLeadPrice: null,
        uiId: getIdForOption()
      });
    }

    // Render reward program option
    vm.renderRewardProgramOptions = function (options) {
      if (options && options.length > 0) {
        angular.forEach(options, function (option) {
          var uiId = getIdForOption() * Math.random();
          vm.rewardsProgramSetup.push(option);
          vm.data.options.push(angular.extend({}, option, {
            uiId: uiId.toString().replace('.', '')
          }));
        });
        vm.data.options = $filter('orderBy')(vm.data.options, 'successfulLeadPrice');
      }
      else {
        initData();
        vm.isRewardsProgramDetailVisible = true;
      }
    };
    Session.getUserProfile().then(function (user) {
      vm.user = user;
      if (user.defaultRewardProgram) {
        vm.renderRewardProgramOptions(user.defaultRewardProgram);
      }
    });

    vm.rewardCodeHelp = 'You can create your own Reward Codes.\n\nExamples: MYCOMPANYNAME123, CODE123, SUMMERPROMOTION, ...\n\nPlease choose between 5 to 20 characters.';
    vm.userShareEmailHelp = 'Type in the email address of another MyLeadPod user you want to share the Reward Code with.';
    vm.rewardsHelp = 'Change the Reward Program for this Reward Code. Users joining you via this Reward Code will be connected to the assigned Reward Program.';
    vm.shareHelp = 'Any leads that you will receive via this Reward Code will be shared with the designated user.';
    vm.associatedUsersHelp = 'Associated users is the group of businesses that the user registering through this Reward Code will be automatically connected with.';

    vm.addOption = function () {
      var optionId = getIdForOption();
      vm.data.options.push({
        name: null,
        successfulLeadPrice: null,
        uiId: getIdForOption()
      });

      $timeout(function () {
        angular.element($document[0].getElementById('name' + optionId))[0].focus();
      }, 0);
    };

    vm.deleteOption = function ($index) {
      vm.data.options.splice($index, 1);
    };

    vm.onRewardCodeChange = function () {
      vm.rewardCodeChecked = false;
      vm.rewardCodeValidating = false;
      vm.rewardCodeValid = false;
    };

    vm.validateRewardCode = function () {
      var code;
      vm.rewardCodeChecked = false;
      if (vm.data.rewardCode && vm.data.rewardCode.length >= 5 && vm.data.rewardCode.length <= 20) {
        code = vm.data.rewardCode.toUpperCase().replace(/\s+/g, '-');
        vm.data.rewardCode = code.replace(/[-]+/g, '-');
        vm.rewardCodeValidating = true;
        RewardCodes.isRewardCodeUnique(vm.data.rewardCode)
          .then(function (isUnique) {
            vm.rewardCodeChecked = true;
            vm.rewardCodeValidating = false;
            vm.rewardCodeValid = isUnique;
            // Please don't remove. This could be the workaround if the $digest takes too long to apply changes to watchers. _.defer(function(){$scope.$apply();});
          });
      }
    };

    vm.create = function () {
      var associatedUsersIds = _.map(vm.associatedUsers, function (user) {
        return user.id;
      });
      vm.isLoading = true;
      vm.alerts.reset();
      RewardCodes.create(vm.data.rewardCode.toUpperCase(), vm.data.rewardType, vm.data.options, vm.data.description, vm.data.userShareEmail, associatedUsersIds)
        .then(function () {
          // Force the refresh of the reward program and code for the local user
          RewardCodes.updateRewardProgramsAndCodes(true);
          Notification({message: 'Success'}, 'confirmation');
          $uibModalInstance.close('success');
        }, function (errorCode) {
          if (errorCode === 601) {
            vm.alerts.add('danger', 'This Reward Code is already used. Please choose another one.');
          }
          else {
            vm.alerts.add('danger', 'Unable to create');
          }
          vm.isLoading = false;
        });
    };

    vm.toggleRewardsProgramDetailVisibility = function () {
      vm.isRewardsProgramDetailVisible = !vm.isRewardsProgramDetailVisible;
    };

    vm.toggleMoreDetailsVisibility = function () {
      vm.detailsVisible = !vm.detailsVisible;
      vm.associatedUsersVisible = false;
      vm.sharingVisible = false;
      if (!vm.detailsVisible) {
        vm.isRewardsProgramDetailVisible = false;
      }
    };

    vm.toggleSharingVisibility = function () {
      vm.sharingVisible = !vm.sharingVisible;
      vm.associatedUsersVisible = false;
      vm.detailsVisible = false;
      $timeout(function () {
        angular.element('#share-autocomplete_value')[0].focus();
      }, 200);
    };

    vm.toggleAssociatedUsersVisibility = function () {
      vm.associatedUsersVisible = !vm.associatedUsersVisible;
      vm.detailsVisible = false;
      vm.sharingVisible = false;
      $timeout(function () {
        angular.element('#associate-autocomplete_value')[0].focus();
      }, 200);
    };

    vm.closeCreateCodeModal = function () {
      $uibModalInstance.dismiss();
    };

    vm.onSearchSharedItemSelected = function (item) {
      if (!item) {
        return;
      }
      vm.searchingUser = true;
      Restangular.one('Users').one('profile').get({id: item.originalObject.id})
        .then(function (profile) {
          vm.searchingUser = false;
          profile = profile.plain();
          vm.addShareUser(profile);
        });
    };

    vm.onSearchItemSelected = function (item) {
      if (!item) {
        return;
      }
      vm.searchingUser = true;
      Restangular.one('Users').one('profile').get({id: item.originalObject.id})
        .then(function (profile) {
          vm.searchingUser = false;
          if (!profile) {
            return;
          }
          profile = profile.plain();
          vm.addUser(profile);
        });
    };

    vm.addShareUser = function (userProfile) {
      if (vm.sharedUsers.length === 0 && _.where(vm.sharedUsers, {idUser: userProfile.idUser}).length === 0) {
        vm.sharedUsers.push(userProfile);
        vm.data.userShareEmail = userProfile.email;
      }
    };

    vm.removeUserShare = function () {
      vm.sharedUsers = [];
      vm.data.userShareEmail = null;
    };

    vm.searchResponseFormatter = function (response) {
      var r,
          records;

      if (angular.isUndefined(response) || !response) {
        return null;
      }
      vm.emptyResults = false;
      r = response.records;
      if (r.length === 0) {
        vm.emptyResults = true;
      }

      records = _.map(r, function (item) {
        var category = _.find(vm.searchCategories, {value: item.datasource}),
            returnObject = {},
            categoryDescriptionClass,
            value = item.fullname;

        categoryDescriptionClass = category.iconClass || 'fa-user';
        if (item.companyname && item.companyname !== item.fullname) {
          value += ' | ' + item.companyname;
        }

        returnObject = {
          value: value,
          id: item.id,
          category: item.datasource,
          categoryDescription: '<i class="fa ' + categoryDescriptionClass + '"></i>',
          timeDescription: item.datecreatedformatted ? item.datecreatedformatted : '',
          leadtype: item.leadtype
        };

        return returnObject;
      });

      return records;
    };

    vm.addUser = function (userProfile) {
      if (_.where(vm.associatedUsers, {idUser: userProfile.idUser}).length === 0) {
        vm.associatedUsers.push(userProfile);
      }
    };

    vm.showButton = function (index) {
      var r = $document[0].querySelector('[data-delete-btn=\'' + index + '\']');
      if (r) {
        angular.element(r).addClass('btn-flip');
        r.querySelector('button').focus();
      }
    };

    vm.hideButton = function (index, btn, evt) {
      try {
        angular.element(evt.currentTarget.parentElement).removeClass('btn-flip');
      }
      catch (ex) {
        console.error(ex);
      }
    };
  }
}());
