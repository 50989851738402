<div class="unsubscribe-content">
  <div class="unsubscribe-logo">
    <img src="/images/logo.svg" />
  </div>
  <span ng-if="preferences.isSaving" class="no-animate fa fa-medium-size fa-circle-o-notch fa-spin-2x"></span>
  <div ng-if="!preferences.isSaving && !preferences.isError" class="no-animate unsubscribe-success">
    You have been unsubscribed from the weekly mailing list. You can subscribe back anytime using the preferences.
    <div style="margin-top: 20px;">
      <a ui-sref="dashboard" class="btn btn-primary">MyLeadPod</a>
    </div>
  </div>
  <div ng-if="preferences.isError" class="no-animate unsubscribe-error">
    The update in subscription was not successful. Please try again or contact us.
    <div>
      <div style="display: inline-block; margin: 5px 10px;">
        <button class="btn btn-primary" ng-click="preferences.openFeedback()">Contact Us</button>
      </div>
    </div>
  </div>
</div>
