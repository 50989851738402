(function () {
  'use strict';

  angular
    .module('myLeadPod')
    .config(config);

  function config() {

  }
}());
