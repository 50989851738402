<div class="panel panel-default" ng-show="receivedLeadsList.list.records.length">
	<div class="panel-heading">
	    <h4>Newest received leads</h4>
  </div>
  <div class="panel-body">
		<div class="panel-list">
		  <table class="table">
		    <tbody>
			    <tr ng-repeat="row in receivedLeadsList.list.records">
			      <td class="col-xs-12 col-sm-9">
			        <table class="table-cell-main-content">
								<tr>
									<td>
										<div class="single-expand" ng-click="receivedLeadsList.openDetail($index)"></div>
										<table-user-details user="row.sender" is-link="false"></table-user-details>
									</td>
								</tr>
            	</table>
			      </td>
			      <td class="col-sm-3 hidden-xs">
			      	<a class="cursor-pointer" ng-click="receivedLeadsList.openDetail($index)">View lead</a>
			      </td>
			    </tr>
		    </tbody>
		  </table>
	  </div>
	  <p>
			<a ui-sref="myMemberships.receivedLeads" class="btn btn-default btn-block" ng-if="receivedLeadsList.list.recordsInfo.totalRecords > 4">All received leads</a>
		</p>
  </div>
</div>
