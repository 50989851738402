(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name lead.controller:CloseLeadCtrl
   *
   * @description
   *
   */
  angular
    .module('lead')
    .controller('CloseLeadCtrl', CloseLeadCtrl);

  function CloseLeadCtrl($rootScope, Restangular, $uibModalInstance, Notification, FormAlerts,
                         lead, status, contract, userProfile, leadPriceBreakdown) {
    var vm = this;
    vm.lead = lead;
    vm.status = status;
    vm.contract = contract;
    vm.isLoading = false;
    vm.calculating = false;
    vm.calculationRequired = false;
    vm.selectedPricingOption = null;
    vm.user = userProfile;
    if (angular.isObject(leadPriceBreakdown)) {
      vm.processingFee = leadPriceBreakdown.fees;
      vm.tax = leadPriceBreakdown.tax;
      vm.total = leadPriceBreakdown.total;
    }
    else {
      vm.processingFee = 0;
      vm.tax = 0;
      vm.total = 0;
    }

    vm.showTotal = false;
    vm.data = {
      status: status,
      id: lead.id,
      creditCard: {},
      feedback: status === 'successful' ? 'Thank you for your lead!' : 'Thanks for your lead, it was not successful this time'
    };
    vm.alerts = new FormAlerts();

    if (contract && contract.pricingOptions) {
      // Sort the pricing options
      contract.pricingOptions = _.sortBy(contract.pricingOptions, 'successfulLeadPrice');
      if (contract.pricingOptions.length === 1) {
        vm.selectedPricingOption = contract.pricingOptions[0];
        vm.data.idPricingOption = contract.pricingOptions[0].id;
      }
    }

    Restangular.one('Billing').one('creditCard').get()
      .then(function (creditCard) {
        if (angular.isObject(creditCard) && angular.isDefined(creditCard.number)) {
          vm.creditCard = creditCard.plain();
        }
        vm.initialisingCard = false;
      }, function () {
        vm.initialisingCard = false;
      });

    vm.isSuccessful = function () {
      return status === 'successful';
    };

    vm.chargeForSuccessfulLead = function () {
      return angular.isObject(contract) && vm.isSuccessful() && (contract.contractType === 'Pay Per Lead' && contract.successfulLeadPrice > 0 ||
          contract.contractType === 'Rewards Program' || contract.contractType === 'Custom Rewards'
        );
    };

    vm.enableBonusReward = function () {
      return vm.isSuccessful() && angular.isObject(contract);
    };

    vm.showPriceBreakdown = function () {
      if (vm.isSuccessful()) {
        if (vm.chargeForSuccessfulLead()) {
          if (contract.contractType === 'Rewards Program' || contract.contractType === 'Custom Rewards') {
            return angular.isObject(vm.selectedPricingOption);
          }

          return true;
        }

        return vm.chargeForSuccessfulLead() ? true : vm.showTotal;
      }

      return false;
    };

    vm.cancel = function () {
      $uibModalInstance.dismiss();
    };

    vm.close = function () {
      vm.alerts.reset();
      vm.isLoading = true;
      Restangular.one('Leads').post('close', vm.data)
        .then(function () {
          $uibModalInstance.close();
          $rootScope.$broadcast('event:lead-closed');
          Notification({message: 'Success'}, 'confirmation');
        }, function (responseData) {
          vm.isLoading = false;
          if (responseData.data.errorCode === 701 || responseData.data.errorCode === 702 || responseData.data.errorCode === 703) {
            vm.alerts.add('danger', 'Unable to close the lead: ' + responseData.data.errorMessage);
          }
          else {
            vm.alerts.add('danger', 'Unable to close the lead, try again later please');
          }
        });
    };

    vm.calculateTotal = function () {
      if (vm.calculationRequired) {
        vm.calculating = true;
        Restangular.one('Leads').one('calculateRewardTotal').get({
          reward: vm.data.bonusReward,
          idUserSender: lead.sender.id,
          idPricingOption: vm.data.idPricingOption
        })
          .then(function (result) {
            vm.processingFee = result.fees;
            vm.total = result.total;
            vm.tax = result.tax;
            vm.calculationRequired = false;
            vm.calculating = false;
            vm.showTotal = true;
          });
      }
    };

    vm.showCCForm = function () {
      if (!userProfile.isCCSet) {
        return angular.isObject(contract) && vm.isSuccessful() && (contract.contractType === 'Pay Per Lead' && contract.successfulLeadPrice > 0 ||
          contract.contractType === 'Rewards Program' || contract.contractType === 'Custom Rewards') || parseFloat(vm.data.bonusReward) > 0;
      }

      return false;
    };

    vm.onBonusRewardChange = function (form) {
      vm.showTotal = false;
      vm.calculationRequired = true;
      if (!vm.showCCForm()) {
        delete form.$error.ccExp;
        delete form.$error.ccNumber;
        delete form.$error.ccNumberType;
        delete form.$error.ccExpMonth;
        delete form.$error.ccExpYear;
      }
    };

    vm.onPricingOptionChange = function (form, $index) {
      vm.data.idPricingOption = contract.pricingOptions[$index].id;
      vm.selectedPricingOption = contract.pricingOptions[$index];
      vm.onBonusRewardChange(form);
      // vm.calculateTotal();
    };

    vm.getSuccessfulLeadPrice = function () {
      switch (contract.contractType) {
        case 'Pay Per Lead':
          return vm.contract.pricingOptions[0].successfulLeadPrice;
        case 'Rewards Program':
        case 'Custom Rewards':
          return vm.selectedPricingOption.successfulLeadPrice;
        default:
          return 0;
      }
    };
  }
}());
