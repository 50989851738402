(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name account.controller:ActivateUpdatePasswordCtrl
   *
   * @description
   *
   */
  angular
    .module('account')
    .controller('ActivateUpdatePasswordCtrl', ActivateUpdatePasswordCtrl);

  function ActivateUpdatePasswordCtrl($rootScope, $state, Restangular, Auth, Session, FormAlerts, userProfile, OneSignal, configOptions) {
    var vm = this,
        oneSignalPlayerId;

    OneSignal.getUserId().then(function (playerId) {
      oneSignalPlayerId = playerId;
    });
    vm.alerts = new FormAlerts();
    vm.isLoading = false;
    vm.userProfile = userProfile;
    vm.data = {
      password: null,
      confirmPassword: null
    };

    vm.activate = function () {
      vm.alerts.reset();
      vm.isLoading = true;
      Restangular.one('Users').customPUT({
        newPassword: vm.data.password,
        oneSignalPlayerId: oneSignalPlayerId
      }, 'password')
        .then(function (result) {
          configOptions.reportRegistration();
          result = result.plain();
          vm.isLoading = false;
          Auth.saveLoginToken(result.authToken);
          Session.create(result.profile);
          $rootScope.$broadcast('event:user-updateProfile', {profile: result.profile});
          $rootScope.$broadcast('event:auth-loginConfirmed');
          $state.go('dashboard');
        }, function () {
          vm.alerts.add('danger', 'Unable to save');
          vm.isLoading = false;
        });
    };
  }
}());
