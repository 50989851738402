<div class="row user-profile-container">

  <div class="visible-xs mobile-content" style="position: relative; z-index: 2; ">
    <div class="" style="position:relative;z-index: 2;">
      <div class="user-profile-details">
        <div user-avatar size="small" is-link="false" user="user.userProfile"></div>
        <div class="user-profile-user-title">
          <div>
            <span class="sub-title user-name-container">{{user.userProfile.fullName}}</span>
            <span class="sub-title sub-title-regular user-company-container">{{user.userProfile.businessName}}</span>
          </div>
          <div>
            <span class="sub-sub-title">{{user.userProfile.industry.name}}{{user.userProfile.subIndustry ? ' - ' + user.userProfile.subIndustry.name : ''}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="user-details-container">
      <div class="slide-if" ng-if="user.detailsVisible">
        <div ng-if="user.getAddress()"><i class="fa fa-map-marker"></i><a href="http://www.google.com/maps?q={{user.addressLink}}" target="_blank">{{user.getAddress()}}</a></div>
        <div ng-if="user.userProfile.phoneMobile"><i class="fa fa-phone"></i> <a href="tel:{{user.userProfile.phoneMobile}}">{{user.userProfile.phoneMobile}}</a></div>
        <div ng-if="user.userProfile.email"><i class="fa fa-envelope-o"></i> <a href="mailto:{{user.userProfile.email}}">{{user.userProfile.email}}</a></div>
        <div ng-if="user.userProfile.website"><i class="fa fa-desktop"></i> <a href="{{user.formatWebsiteUrl()}}" target="_blank">{{user.userProfile.website}}</a></div>

        <div class="user-details-buttons-container">
          <a ui-sref="user.messages({user: user.userProfile})" class="btn-sm btn-primary" ng-if="user.tabManager.activeTab !== 'messages' && (user.userProfile.acceptedReceiveLeadsContractExists || user.userProfile.acceptedSendLeadsContractExists)">Send Message</a>
          <a ui-sref="myMembershipsSendReward({user: user.userProfile})" class="btn-sm btn-primary" ng-if="user.isBusinessAccountActive() && user.agreementsDetails.receiveLeads && user.agreementsDetails.receiveLeads.isAccepted">Send Reward</a>
          <a ng-click="user.registerLead({user: user.userProfile})" class="btn-sm btn-primary" ng-if="user.isBusinessAccountActive()">Register Lead</a>
          <a ng-click="user.sendLead({user: user.userProfile})" class="btn-sm btn-primary" ng-if="user.agreementsDetails.sendLeads && user.agreementsDetails.sendLeads.isAccepted">Send Lead</a>
          <a ng-click="user.donate({user: user.userProfile})" class="btn-sm btn-success" ng-if="user.userProfile.isCharityAccountActive">Donate</a>
        </div>
      </div>
      <span class="user-profile-more cursor-pointer" ng-click="user.toggleDetails()"><i class="fa" ng-class="{'fa-chevron-down': !user.detailsVisible, 'fa-chevron-up': user.detailsVisible}"></i></span>
    </div>

  </div>
  <div class="hidden-xs">
    <div class="col-xs-12">
      <div class="page-content user-profile-lg" ng-style="user.getCoverStyle()">
        <a class="crediting-link" ng-if="!user.isCustomBackground()" href="https://www.toptal.com/designers/subtlepatterns/gplay/" target="_blank"><i class="fa fa-copyright"></i>Design by Hoekstra</a>
        <div class="user-profile-container-lg">
          <div class="user-avatar-part">
            <!-- User Avatar -->
            <div user-avatar size="large" is-link="false" user="user.userProfile"></div>
          </div>
          <div class="user-info-part">
            <div>
              <div class="user-name-and-company mbs" ng-class="{'mbm': user.userProfile.businessName}" ng-style="user.getHeadlineStyle()">
                <!-- User Info -->
                <span class="sub-title user-name-container">{{user.userProfile.fullName}}</span>
                <span class="sub-title sub-title-regular sub-title-separator hidden-xs" ng-if="user.userProfile.businessName">|</span>
                <span class="sub-title sub-title-regular user-company-container">{{user.userProfile.businessName}}</span>

                <!-- Industry Info -->
                <div class="sub-sub-title" ng-if="user.userProfile.industry">{{user.userProfile.industry.name}}{{user.userProfile.subIndustry ? ' - ' + user.userProfile.subIndustry.name : ''}}</div>
              </div>
              <!-- User Details -->
              <div class="user-details-container">
                <div ng-if="user.getAddress()"><i class="fa fa-map-marker"></i><a href="http://www.google.com/maps?q={{user.addressLink}}" target="_blank">{{user.getAddress()}}</a></div>
                <div ng-if="user.userProfile.phoneMobile"><i class="fa fa-phone"></i> <a href="tel:{{user.userProfile.phoneMobile}}">{{user.userProfile.phoneMobile}}</a></div>
                <div ng-if="user.userProfile.email"><i class="fa fa-envelope-o"></i> <a href="mailto:{{user.userProfile.email}}">{{user.userProfile.email}}</a></div>
                <div ng-if="user.userProfile.website"><i class="fa fa-desktop"></i> <a href="{{user.formatWebsiteUrl()}}" target="_blank">{{user.userProfile.website}}</a></div>
              </div>
              <!-- Commands -->
              <div class="user-details-buttons-container">
                <a ui-sref="user.messages({user: user.userProfile})" class="btn btn-primary" ng-if="user.userProfile.acceptedReceiveLeadsContractExists || user.userProfile.acceptedSendLeadsContractExists">Send Message</a>
                <a ui-sref="myMembershipsSendReward({user: user.userProfile})" class="btn btn-primary" ng-if="user.isBusinessAccountActive() && user.agreementsDetails.receiveLeads && user.agreementsDetails.receiveLeads.isAccepted">Send Reward</a>
                <a ng-click="user.sendLead({user: user.userProfile})" class="btn btn-primary" ng-if="user.userProfile.isBusinessAccountActive && user.agreementsDetails.sendLeads && user.agreementsDetails.sendLeads.isAccepted">Send Lead</a>
                <a ng-click="user.registerLead({user: user.userProfile})" class="btn btn-primary" ng-if="user.isBusinessAccountActive()">Register Lead</a>
                <a ng-click="user.donate({user: user.userProfile})" class="btn btn-success" ng-if="user.userProfile.isCharityAccountActive">Donate</a>
              </div>
            </div>
          </div>
        </div>

        <!-- Commands -->
        <div class="user-details-buttons-container">
          <a ui-sref="user.messages({user: user.userProfile})" class="btn btn-primary" ng-if="user.userProfile.acceptedReceiveLeadsContractExists || user.userProfile.acceptedSendLeadsContractExists">Send Message</a>
          <a ui-sref="myMembershipsSendReward({user: user.userProfile})" class="btn btn-primary" ng-if="user.isBusinessAccountActive() && user.agreementsDetails.receiveLeads && user.agreementsDetails.receiveLeads.isAccepted">Send Reward</a>
          <a ng-click="user.sendLead({user: user.userProfile})" class="btn btn-primary" ng-if="user.userProfile.isBusinessAccountActive && user.agreementsDetails.sendLeads && user.agreementsDetails.sendLeads.isAccepted">Send Lead</a>
          <a ng-click="user.registerLead({user: user.userProfile})" class="btn btn-primary" ng-if="user.isBusinessAccountActive()">Register Lead</a>
          <a ng-click="user.donate({user: user.userProfile})" class="btn btn-success" ng-if="user.userProfile.isCharityAccountActive">Donate</a>
        </div>

        <!-- Tab -->
        <tabs-manager style="background: white;" tab-id="user" tabs-config="user.tabsConfig" default-tab="agreements" tab-manager="user.tabManager"></tabs-manager>

      </div>
    </div>
  </div>
</div>

<div class="row visible-xs">
  <div class="col-xs-12">
    <tabs-manager tab-id="user" tabs-config="user.tabsConfig" default-tab="agreements" tab-manager="user.tabManager"></tabs-manager>
  </div>
</div>

<div ng-click="user.detailsVisible = false;" style="cursor: default;">
  <div class="row" ng-show="user.tabManager.isOpen('about')" ng-include="'user/about.tpl.html'">
  </div>

  <div class="row"  ng-show="user.tabManager.isOpen('sentLeads')" ng-include="'user/leads/sent.tpl.html'">
  </div>

  <div ng-if="user.isBusinessAccountActive()" class="row"  ng-show="user.tabManager.isOpen('receivedLeads')" ng-include="'user/leads/received.tpl.html'">
  </div>
  <div class="row"  ng-show="user.tabManager.isOpen('agreements')" ng-include="'user/agreements/agreements.tpl.html'">
  </div>

  <div class="row"  ng-show="user.tabManager.isOpen('messages')" ng-include="'user/messages/messages.tpl.html'">
  </div>

  <div ng-if="user.userProfile.isBusinessAccountActive" class="row"  ng-show="user.tabManager.isOpen('connections')" ng-include="'user/connections/connections.tpl.html'">
  </div>

  <div ng-if="user.userProfile.isBusinessAccountActive" class="row"  ng-show="user.tabManager.isOpen('marketplace')" ng-include="'user/marketplace/marketplace.tpl.html'">
  </div>
</div>

<action-toolbar class="visible-xs" at-target="secondary">
  <a class="no-animate fa fa-dollar" label="Send Reward" ui-sref="myMembershipsSendReward({user: user.userProfile})" ng-if="user.isBusinessAccountActive() && user.agreementsDetails.receiveLeads && user.agreementsDetails.receiveLeads.isAccepted"><span class="tool-label">Send Reward</span></a>
  <a class="no-animate fa fa-paper-plane" label="Send Lead" ng-click="user.sendLead({user: user.userProfile})" ng-if="user.userProfile.isBusinessAccountActive && user.agreementsDetails.sendLeads && user.agreementsDetails.sendLeads.isAccepted"><span class="tool-label">Send Lead</span></a>
  <a class="no-animate fa fa-arrow-down" label="Register Lead" ng-click="user.registerLead({user: user.userProfile})" ng-if="user.isBusinessAccountActive()"><span class="tool-label">Register Lead</span></a>
  <a class="no-animate fa fa-user-plus" label="Ask for Leads" ui-sref="myMembershipsJoinPod({user: user.userProfile})" ng-if="user.isBusinessAccountActive() && !user.agreementsDetails.receiveLeads"><span class="tool-label">Ask for Leads</span></a>
  <a class="no-animate fa fa-heart" label="Donate" ng-click="user.donate()" ng-if="user.userProfile.isCharityAccountActive"><span class="tool-label">Donate</span></a>
</action-toolbar>
