<div class="panel panel-default">
	<div class="panel-heading">
		<h4>Earnings</h4>
	</div>
	<div class="panel-body">
		<chartist
			class="ct-chart"
			chartist-chart-type="Line"
			chartist-data="podEarningsGraph.chartData"
			chartist-chart-options="podEarningsGraph.chartOptions"
			chartist-events="podEarningsGraph.chartEvents"
			chartist-responsive-options="podEarningsGraph.responsiveOptions">
		</chartist>
	</div>
</div>
