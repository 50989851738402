(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name cache.service:Cache
   *
   * @description
   *
   */
  angular
    .module('cache')
    .service('Cache', Cache);

  function Cache(CacheFactory) {
    var self = this;

    self.create = function (name, options) {
      var cacheInstance = CacheFactory.get(name);
      if (!cacheInstance) {
        cacheInstance = CacheFactory.createCache(name, angular.extend({
          deleteOnExpire: 'aggressive',
          storageMode: 'localStorage'
        }, options));
      }

      return cacheInstance;
    };

    self.get = CacheFactory.get;
  }
}());
