(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name myMemberships.memberships.controller:MembershipsCtrl
   *
   * @description
   *
   */
  angular
    .module('myMemberships.memberships')
    .controller('MembershipsCtrl', MembershipsCtrl);

  function MembershipsCtrl(DataTable) {
    var vm = this;
    vm.searchName = '';
    vm.tableSubContentVisible = null;
    vm.lastSearched = null;
    vm.list = DataTable.create('Memberships', 'index');
    vm.search = function () {
      if (vm.lastSearched === vm.searchName) {
        return;
      }
      vm.list.reload({
        search: vm.searchName
      });
      vm.lastSearched = vm.searchName;
    };
  }
}());
