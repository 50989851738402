<div class="modal-content-container">
  <div class="row" ng-if="!promptDialog.isConfirmed">
    <!-- Title -->
    <!--<div class="col-xs-12 modal-content-title">-->
      <!--<span class="sub-title">Preferences</span>-->
    <!--</div>-->
    <!-- ENDOF: Title-->

    <!-- Content -->
    <div class="col-xs-12">
      <div ng-bind-html="promptDialog.content"></div>
    </div>
    <!-- ENDOF: Content -->

  </div>

  <div class="row modal-content-buttons" ng-if="!promptDialog.isConfirmed">
    <div class="text-right">
      <button type="button" class="btn btn-default" ng-disabled="promptDialog.isLoading" ng-click="promptDialog.cancel()">
        {{promptDialog.cancelButtonLabel}}
      </button>
      <button type="button" class="btn btn-primary"
              ng-disabled="promptDialog.isLoading" ng-click="promptDialog.confirm()">
        {{promptDialog.isLoading ? 'Loading ...' : promptDialog.confirmButtonLabel}}
      </button>
    </div>
  </div>

  <div class="row" ng-if="promptDialog.isConfirmed">
    {{promptDialog.onConfirmMessage}}
  </div>
</div>


